/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import React from "react";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const SpaceValue = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #4caf50;
  width: 100px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2px;
  width: 100%;
`;

interface GridSquareProps {
  isOccupied: boolean;
}

const GridSquare = styled.div<GridSquareProps>`
  width: 100%;
  aspect-ratio: 1;
  background-color: ${(props) => (props.isOccupied ? "#4caf50" : "#e0e0e0")};
  border-radius: 2px;
`;

interface TrailerSpaceVisualizationProps {
  occupiedSpace?: number;
}

const TrailerSpaceVisualization: React.FC<TrailerSpaceVisualizationProps> = ({
  occupiedSpace = 0,
}) => {
  // Total spaces in the trailer (2 rows x 13 columns)
  const columns = 13;
  const rows = 2;
  const totalSpaces = columns * rows;

  const isOccupied = (index: number): boolean => {
    const column = index % columns;
    const row = Math.floor(index / columns);
    const spaceNumber = column * rows + row;
    return spaceNumber < occupiedSpace;
  };

  return (
    <Container>
      <SpaceValue>{occupiedSpace * 16} sq ft</SpaceValue>
      <Grid>
        {[...Array(totalSpaces)].map((_, index) => (
          <GridSquare key={index} isOccupied={isOccupied(index)} />
        ))}
      </Grid>
    </Container>
  );
};

export default TrailerSpaceVisualization;
