/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Loader from "react-loader";

import { useState } from "react";
import { useTranslation } from "react-i18next";

import { withSize } from "react-sizeme";

import Colors from "styles/colors";

import { NoChartData } from "components/molecules/NoChartData.molecule";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { VerticalStackedBarChart } from "components/molecules/VerticalStackedBarChart.molecule";
import { FontSize } from "components/atoms/Text.atom";
import {
  barStyle,
  generateTickFormat,
} from "pages/inventoryview/utils/chart.utils";

import { axisStyle, crossAxisStyle } from "styles/graph-styles";
import { Alert } from "components/atoms/Alert.atom";

export const ShippableNonShippableCountsWidget = ({
  widgetData,
  isLoading = false,
  threshold,
  title,
  bars,
  xAxisTickFormat,
  showTotals = false,
  showAlert = false,
}) => {
  const { t } = useTranslation("inventory-view");
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const onSize = (size) => {
    setDimensions({
      height: size.height,
      width: size.width,
    });
  };

  const xAxisTickFormatValue = generateTickFormat(xAxisTickFormat);

  return (
    <PanelGroup>
      <PanelGroup.Header title={title} />
      <PanelGroup.Content style={{ position: "relative", minHeight: 300 }}>
        {showAlert ? (
          <Alert variant="warning" style={{ margin: 0, padding: "0.5em" }} show>
            {t(
              "inventory-view:This chart counts VINs that have arrived. These VINs may not be considered Ready to Ship.",
            )}
          </Alert>
        ) : null}
        <Loader loaded={!isLoading}>
          {widgetData?.length ? (
            <div
              css={{
                svg: {
                  maxHeight: "23rem",
                },
                display: "flex",
                flexDirection: "row",
              }}
            >
              <ChartComponent
                pagedData={widgetData}
                bars={bars}
                barStyle={barStyle}
                axisStyle={axisStyle}
                crossAxisStyle={crossAxisStyle}
                onSize={onSize}
                dimensions={dimensions}
                showTotals={showTotals}
                threshold={threshold}
                xAxisTickFormatValue={xAxisTickFormatValue}
              />
            </div>
          ) : (
            <div
              css={{
                minHeight: 300,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: Colors.text.GRAY,
                padding: "2em",
                textAlign: "center",
              }}
            >
              <NoChartData
                size={FontSize.size20}
                label={t(
                  "inventory-view:Unable to fetch details. Please try again later.",
                )}
              />
            </div>
          )}
        </Loader>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

const ChartComponent = withSize()(({
  dimensions,
  pagedData,
  bars,
  barStyle,
  axisStyle,
  crossAxisStyle,
  showTotals,
  threshold,
  xAxisTickFormatValue,
}) => {
  return (
    <div css={{ width: "100%" }}>
      <VerticalStackedBarChart
        axisTicksLabelStyle={{ fontFamily: "inherit" }}
        axisStyle={axisStyle}
        bars={bars}
        barStyle={barStyle}
        threshold={threshold}
        crossAxisStyle={crossAxisStyle}
        data={pagedData}
        legendStyle={{ labels: { fontFamily: "inherit" } }}
        onClickLegend={null}
        showTotals={showTotals}
        width={dimensions.width}
        height={dimensions.height}
        xAccessor="name"
        xAxisTickFormat={xAxisTickFormatValue}
      />
    </div>
  );
});

ShippableNonShippableCountsWidget.propTypes = {
  isLoading: PropTypes.bool,
  pushView: PropTypes.func,
  widgetData: PropTypes.array,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  showCapactiy: PropTypes.bool,
  setDwellFilterValue: PropTypes.object,
  title: PropTypes.string,
  threshold: PropTypes.number,
  bars: PropTypes.array,
  xAxisTickFormat: PropTypes.object,
  showTotals: PropTypes.bool,
  showAlert: PropTypes.bool,
};
