import { useEffect, useState } from "react";

interface WriteCache {
  [key: string]: string;
}

const writeCache: WriteCache = {};

export function setSearchParams(newParams: Record<string, any>): void {
  const searchParams = new URLSearchParams(window.location.search);
  for (const [key, value] of Object.entries(newParams)) {
    if (
      value === null ||
      value === undefined ||
      value === "" ||
      value === false
    ) {
      searchParams.delete(key);
    } else {
      searchParams.set(key, JSON.stringify(value));
    }
  }

  window.history.replaceState(
    {},
    "",
    `${window.location.pathname}?${searchParams.toString()}`,
  );

  writeCache[window.location.pathname] = searchParams.toString();
}

type SearchParamsGetter = {
  get: (key: string) => any;
};

function useSearchParams(): [SearchParamsGetter, typeof setSearchParams] {
  const searchParams = new URLSearchParams(window.location.search);

  return [
    { get: (key: string) => JSON.parse(searchParams.get(key) || "null") },
    setSearchParams,
  ];
}

interface UseSearchParamStateOptions {
  // Add any options here if needed
}

function useSearchParamState<T>(
  key: string,
  initialValue: T,
  opts: UseSearchParamStateOptions = {},
): [T, React.Dispatch<React.SetStateAction<T>>] {
  const [localSearchParams] = useState(
    () => new URLSearchParams(writeCache[window.location.pathname] || ""),
  );
  const localStoredValue = JSON.parse(localSearchParams.get(key) || "null");
  const [searchParams, setSearchParams] = useSearchParams();
  const [val, setVal] = useState<T>(
    searchParams.get(key) || localStoredValue || initialValue,
  );

  useEffect(() => {
    setSearchParams({ [key]: val });
  }, [key, val, setSearchParams]);

  return [val, setVal];
}

export { useSearchParams, useSearchParamState };
