import moment from "moment";
import Colors from "styles/colors";
import {
  getDifferenceBetweenTimestamps,
  stringToMoment,
} from "utils/date-time";
import { dataTypes } from "../insights/components/InventoryCharts.Columns";

// Victory Chart style object
export const barStyle = {
  data: {
    cursor: "pointer",
    width: 30,
    strokeWidth: 0,
  },
  labels: { fontSize: 12 },
};

export const getStyle = (bar) => {
  return {
    ...barStyle,
    labels: {
      fill: bar.textColor,
      fontSize: 12,
    },
    data: {
      fill: ({ datum }) => {
        if (
          datum.isTodaysDate &&
          (bar.y === dataTypes.SHIPPABLE || bar.y === dataTypes.REPORTED)
        ) {
          return Colors.graphs.CHART_CYAN_BLUE;
        }
        return bar.color;
      },
    },
  };
};

export const getLabels = (bar) => {
  return ({ datum }) => {
    if (datum[bar.y] > 0) {
      if (
        bar.y === dataTypes.NONSHIPPABLE &&
        datum.shippable !== 0 &&
        datum.nonShippable !== 0
      ) {
        return `${datum.summed}\n \n${datum.nonShippable}`;
      } else if (
        bar.y === dataTypes.FORECASTED &&
        datum.reported !== 0 &&
        datum.forecasted !== 0
      ) {
        return `${datum.summed}\n \n${datum.forecasted}`;
      }
      return datum[bar.y];
    }
    return null;
  };
};

/**
 * Formats the X-axis name based on the provided datum.
 *
 * This function checks if the datum object has a property called `formattedDate`.
 * If it exists, it returns the `formattedDate` value. Otherwise, it returns the `name` property of the datum.
 *
 * @param {object} datum - The data object containing the name or formatted date.
 * @param {string} datum.name - The original name of the data point. (optional)
 * @param {string} datum.formattedDate - A pre-formatted date string for the X-axis. (optional)
 * @returns {string} The formatted name to be used for the X-axis.
 */
export const formatXAxisName = (datum) => {
  return datum.formattedDate ? datum.formattedDate : datum.name;
};

export const calculateLabelPosition = (bar) => {
  return ({ datum }) => {
    return datum[bar.y] < 160 ? -20 : 20;
  };
};
export const generateTickFormat = ({ difference, maxLimit }) => {
  let i = 0;
  const tickFormat = [i];
  while (i < maxLimit) {
    const newValue = i + difference;
    tickFormat.push(newValue);
    i = newValue;
  }
  return tickFormat;
};

const inventoryColorCodes = {
  85: Colors.inventoryView.ORANGE_YELLOW,
  100: Colors.inventoryView.RED,
  default: Colors.inventoryView.GREEN,
};

const daysOnSiteColorCodes = {
  5: Colors.inventoryView.RED,
  default: Colors.inventoryView.BLUE,
};

const forecastedArrivalsColorCodes = {
  default: Colors.inventoryView.BLUE,
};

const getColorTheme = (insightBlock) => {
  switch (insightBlock) {
    case "inventory":
      return inventoryColorCodes;
    case "daysOnSite":
      return daysOnSiteColorCodes;
    case "forecastedArrivals":
      return forecastedArrivalsColorCodes;
    case "departures":
      return inventoryColorCodes;
    default:
      return null;
  }
};

export const getColors = (insightBlock, value, capacity = 0) => {
  const theme = getColorTheme(insightBlock);
  let percentage = 0;
  if (capacity) {
    percentage = Math.trunc((value / capacity) * 100);
  }

  for (const threshold of Object.keys(theme).sort()) {
    if (percentage >= threshold) {
      return theme[threshold];
    }
  }

  // Default case
  return theme.default;
};

export const formattedData = (widgetData) => {
  return widgetData.map((location) => {
    const monthYear = stringToMoment(location?.name);
    const now = moment().format("YYYY-MM-DD");
    const timeDifferenceInDays = parseInt(
      getDifferenceBetweenTimestamps(now, monthYear),
    );
    return {
      locationId: location?.locationId,
      name: location?.name,
      formattedDate: monthYear.isValid()
        ? moment(monthYear).format("MM/DD")
        : false,
      isTodaysDate: timeDifferenceInDays === 0 ? true : false,
      shippable: location?.shippable ?? 0,
      nonShippable: location?.nonShippable ?? 0,
      forecasted: location?.forecasted ?? 0,
      reported: location?.reported ?? 0,
      today: 0,
      capacity: 0,
    };
  });
};
