/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Address,
  AddressBlock,
  AddressSection,
  AddressTitle,
  AddressTitle2,
  AtDock,
  Card,
  DockDeliverLocation,
  DockDeliverTime,
  DockInfo,
  DockNotes,
  DockPickupLocation,
  DockPickupTime,
  MapDot,
  ThreeWords,
  formatISOToCustom,
} from "../Shared";
import {
  Destination,
  Origin,
} from "pages/dynamic-normal/service/orderServices";

interface OriginDestinationCardProps {
  origin: Origin;
  destination: Destination;
}

export const OriginDestinationCard: React.FC<OriginDestinationCardProps> = ({
  origin,
  destination,
}) => (
  <Card css={css({ margin: 10, padding: 10 })}>
    <AddressBlock>
      <AddressTitle>{MapDot} Origin</AddressTitle>
      <AddressSection>
        <AddressTitle2>{origin.name}</AddressTitle2>
        <Address>{origin.address}</Address>
        <Address>
          {origin.city} {origin.state}
        </Address>
        <hr />
        <DockInfo>
          <DockPickupTime>{formatISOToCustom(origin.lateDate)}</DockPickupTime>
          <DockPickupLocation>
            {origin.location}
            {origin.threeWords ? " - " : " "}
            <ThreeWords>{origin.threeWords}</ThreeWords>
          </DockPickupLocation>
          <AtDock atDock={origin.atDock} />
        </DockInfo>
        <hr />
        <DockNotes>{origin.notes}</DockNotes>
      </AddressSection>
    </AddressBlock>
    <AddressBlock>
      <AddressTitle>{MapDot} Destination</AddressTitle>
      <AddressSection>
        <AddressTitle2>{destination.name}</AddressTitle2>
        <Address>{destination.address}</Address>
        <Address>
          {destination.city} {destination.state}
        </Address>
        <hr />
        <DockInfo>
          <DockDeliverTime>
            {formatISOToCustom(destination.lateDate)}
          </DockDeliverTime>
          <DockDeliverLocation>
            {destination.location}
            {destination.threeWords ? " - " : " "}
            <ThreeWords>{destination.threeWords}</ThreeWords>
          </DockDeliverLocation>
          <div css={css({ flex: 1 })} />
        </DockInfo>
        <hr />
        <DockNotes>{destination.notes}</DockNotes>
      </AddressSection>
    </AddressBlock>
  </Card>
);
