/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import { Order } from "pages/rtscao/service/orderServices";
import { formatISOToCustom } from "../Shared";
import { ClockIcon, OrderNumber, ProductInfo } from "./OrderCardComponents";

type Variant = "declined" | "oem" | "offers" | "supplier" | "carrier";

function getTimeSince(date: string) {
  const timeSince = Date.now() - new Date(date).getTime();
  const minutes = Math.floor(timeSince / 60000);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  if (days > 0) {
    return `${days}d`;
  }
  if (hours > 0) {
    return `${hours}h`;
  }
  return `${minutes}m`;
}

export function getRightSectionVariant(variant: Variant) {
  // variants switch (Basic, Offers, Supplier, Carrier)
  switch (variant) {
    case "oem":
      return AdditionalOrderInfo;
    case "offers":
      return AdditionalOrderInfo;
    case "supplier":
      return AdditionalOrderInfo;
    case "carrier":
      return AdditionalOrderInfo;
    default:
      return AdditionalOrderInfo;
  }
}

function AdditionalOrderInfo({ order }: { order: Order }) {
  return (
    <>
      <OrderNumber>Order {order.id}</OrderNumber>
      <ProductInfo>{formatISOToCustom(order.orderDate)}</ProductInfo>
      <ProductInfo>
        {getTimeSince(order.orderDate)} ago {ClockIcon}
      </ProductInfo>
    </>
  );
}
