/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Button, Checkboxes, SortSelect } from "../Shared";
import { SearchBar } from "./SearchBar";
import { CreateOrderModal } from "../CreateOrder/CreateOrderModal";
import { useState } from "react";
import { Order } from "pages/rtscao/service/orderServices";
import { getCurrentUser } from "modules/users/UsersState";
import { useSelector } from "react-redux";
import {
  Privileges,
  UserAuthorizationNamespace,
} from "modules/auth/Authorization";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import { HideGlobalSpinner, ShowGlobalSpinner } from "components/GlobalSpinner";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SearchBarArea = styled.div`
  flex: 1;
  display: flex;
`;

const ViewingInfo = styled.div`
  flex: 1;
`;

const ShowFilters = styled.div`
  display: flex;
  align-items: center;
`;

const SortByArea = styled.div`
  width: 270px;
`;

const SearchButton = styled(Button)`
  background-color: #0d6efd;
  color: white;
`;

const CreateNewOrderButton = styled(Button)`
  background-color: #007c4d;
  color: white;
`;

interface PageHeaderProps {
  variant: "oem" | string;
  orders: Order[];
  plant: string | null;
  setPlant:
    | React.Dispatch<React.SetStateAction<string>>
    | React.Dispatch<React.SetStateAction<null>>;
  filterValue?: string;
  filterType?: string;
  setFilterValue: (value: string) => void;
  setFilterType: (type: string) => void;
  activeChecked: boolean;
  setActiveChecked: (checked: boolean) => void;
  archivedChecked: boolean;
  setArchivedChecked: (checked: boolean) => void;
  sort: string;
  setSort: (sort: string) => void;
  opportunities?: Order[];
  children?: React.ReactNode;
}

const exportOrders = async (orgId: number) => {
  ShowGlobalSpinner("Exporting Data, please wait...");

  const payload = {
    selector: {
      _id: {
        $gt: "orders",
        $lt: "orders\\ufff0",
      },
      orgId: orgId,
    },
    limit: 9999,
    skip: 0,
    sort: [
      {
        orderDate: "desc",
      },
    ],
  };

  const results = await fetch("https://couchdb1.z-wc.net/rtscao/_find", {
    headers: {
      accept: "*/*",
      "accept-language": "en-US,en;q=0.9",
      authorization: "Basic cnRzY2FvOm9BN3BxakNpN3VKTFh5Y0dacTNG",
      "content-type": "application/json",
    },
    body: JSON.stringify(payload),
    method: "POST",
  });

  const { docs } = await results.json();

  downloadCSV(docs);

  HideGlobalSpinner();
};

function convertToCSV(objArray: any[], columns: string[]): string {
  // Helper function to flatten an object
  const flattenObject = (obj: any, prefix = ""): any => {
    return Object.keys(obj).reduce((acc: any, k: string) => {
      const pre = prefix.length ? prefix + "." : "";
      if (
        typeof obj[k] === "object" &&
        obj[k] !== null &&
        !Array.isArray(obj[k])
      ) {
        Object.assign(acc, flattenObject(obj[k], pre + k));
      } else {
        acc[pre + k] = obj[k];
      }
      return acc;
    }, {});
  };

  // Flatten all objects in the array
  const flattenedArray = objArray.map((obj) => flattenObject(obj));

  // Get all unique keys
  const headers = Array.from(new Set(flattenedArray.flatMap(Object.keys)));
  console.log(headers);

  // Create CSV rows
  const csvRows = [
    columns.join("\t"), // Header row
    ...flattenedArray.map((obj) =>
      columns
        .map((header) => {
          let value = obj[header];
          if (header === "uberQuote.price.amount") {
            const valueString = value?.toString() ?? "";
            // get last 2 digits
            const lastTwoDigits = valueString.slice(-2);
            // get all but last 2 digits
            const allButLastTwoDigits = valueString.slice(0, -2);
            value = `${allButLastTwoDigits}.${lastTwoDigits}`;
          }

          if (header === "enteredCost") {
            value = value?.toFixed(2) ?? "0.00";
          }

          return value !== undefined && value !== null
            ? JSON.stringify(value).replaceAll("\t", " ")
            : "";
        })
        .join("\t"),
    ),
  ];

  return csvRows.join("\n");
}

const columnsToExport = [
  // "_id",
  // "_rev",
  "id",
  "orgId",
  // "carrierAwarded",
  // "carrier.id",
  // "carrier.carrierName",
  // "carrier.carrierType",
  // "carrier.milesToSupplier",
  // "carrier.totalMiles",
  // "carrier.offerStatus",
  // "carrier.price",
  // "newlyCreated",
  // "orderType",
  // "supplierName",
  // "companyName",
  // "carrierName",
  // "carrierType",
  // "milesToSupplier",
  "totalMiles",
  // "offerStatus",
  "price",
  // "partNo",
  // "productInfo",
  // "quantity",
  // "carrierAccepted",
  // "offers",
  "orderDate",
  // "timeAgo",
  // "statusItems",
  // "origin.label",
  "origin.name",
  "origin.address",
  "origin.city",
  "origin.state",
  "origin.postal_code",
  "origin.country",
  "origin.lad.id",
  "origin.lad.name",
  "origin.lad.canonical_name",
  "origin.lad.code",
  // "origin.lad.description",
  "origin.lad.lob_name",
  // "origin.oem",
  // "origin.supplier",
  // "origin.location",
  // "origin.threeWords",
  "origin.loadType",
  "origin.readyTime",
  "origin.closeTime",
  "origin.contactName",
  "origin.contactEmail",
  "origin.contactPhone",
  "origin.notes",
  // "destination.label",
  "destination.name",
  "destination.address",
  "destination.city",
  "destination.state",
  "destination.postal_code",
  "destination.country",
  "destination.lad.id",
  "destination.lad.name",
  "destination.lad.canonical_name",
  "destination.lad.code",
  // "destination.lad.description",
  "destination.lad.lob_name",
  // "destination.oem",
  // "destination.supplier",
  // "destination.location",
  // "destination.threeWords",
  "destination.loadType",
  "destination.openTime",
  "destination.mustDeliverBy",
  "destination.contactName",
  "destination.contactEmail",
  "destination.contactPhone",
  "destination.notes",
  // "stops",
  "reasonCode",
  "contact-name",
  "contact-email",
  "contact-phone",
  "shipmentDetails.shippingMode",
  "shipmentDetails.vehicleType",
  "shipmentDetails.grossWt",
  "shipmentDetails.shipUnitQty",
  "shipmentDetails.shipUnit",
  "shipmentDetails.shipUnitDimensions.length",
  "shipmentDetails.shipUnitDimensions.width",
  "shipmentDetails.shipUnitDimensions.height",
  "shipmentDetails.stackable",
  "shipmentDetails.hazmat",
  "shipmentDetails.temp",
  "shipmentDetails.refQualifierValues",
  "shipmentDetails.specialInstructions",
  "shipmentDetails.commodity",
  "route.origin.lat",
  "route.origin.long",
  "route.destination.lat",
  "route.destination.long",
  "route.summary.duration",
  "route.summary.length",
  "route.summary.baseDuration",
  "route.summary.driveTime",
  "route.summary.miles",
  "route.summary.estimatedCost",
  "route.summary.practicalDeliveryTime",
  "route.arrival.time",
  "route.arrival.place.type",
  "route.arrival.place.location.lat",
  "route.arrival.place.location.lng",
  "route.arrival.place.originalLocation.lat",
  "route.arrival.place.originalLocation.lng",
  "route.arrival.newTime",
  "route.departure.time",
  "route.departure.place.type",
  "route.departure.place.location.lat",
  "route.departure.place.location.lng",
  "route.departure.place.originalLocation.lat",
  "route.departure.place.originalLocation.lng",
  "route.departure.newTime",
  "uberQuote.uber_quote_uuid",
  "uberQuote.price.amount",
  "uberQuote.price.currency_code",
  "uberQuote.expiration_time_utc",
  "uberQuote.status",
  "uberQuote.notes",
  "uberQuote.uber_quote_id",
  "enteredCost",
  // "selectedQuote",
];

const downloadCSV = (docs: any[]) => {
  const csvContent = convertToCSV(docs, columnsToExport);

  // Create a Blob with the CSV content
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

  // Create a download link
  const link = document.createElement("a");
  if (link.download !== undefined) {
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `export-r2s-data-${new Date().toISOString().slice(0, 10)}.tsv`,
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export function PageHeader(props: PageHeaderProps) {
  const currentUser = useSelector(getCurrentUser);
  const activeOrg = useSelector(getActiveOrganization);

  const fvAdmin = currentUser[UserAuthorizationNamespace].privileges.includes(
    Privileges.CHANGE_ACTIVE_ORGANIZATION,
  );

  const url = new URL(window.location.href);
  const resume = url.searchParams.get("resume-create-order") === "true";

  const [show, setShow] = useState<boolean>(resume);
  const [filterValue, setFilterValue] = useState<string>(
    props.filterValue ?? "",
  );
  const [filterType, setFilterType] = useState<string>(props.filterType ?? "");

  return (
    <>
      <CreateOrderModal
        key={"create-order-modal"}
        show={show}
        setShow={setShow}
      />
      <Container>
        <TopRow>
          {props.variant !== "oem" && (
            <ViewingInfo css={{ maxWidth: 135 }}>
              Viewing <strong>{props.orders?.length || 0} Orders</strong>
            </ViewingInfo>
          )}
          <SearchBarArea>
            <SearchBar
              onKeyUp={(ev: React.KeyboardEvent<HTMLInputElement>) => {
                if (ev.key === "Enter") {
                  props.setFilterValue(filterValue);
                  props.setFilterType(filterType);
                }
              }}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              filterType={filterType}
              setFilterType={setFilterType}
            />
          </SearchBarArea>
          <SearchButton
            onClick={() => {
              props.setFilterValue(filterValue);
              props.setFilterType(filterType);
            }}
          >
            Search
          </SearchButton>

          {props.variant === "oem" && (
            <CreateNewOrderButton onClick={() => setShow(true)}>
              New Order
            </CreateNewOrderButton>
          )}
          {props.variant !== "oem" && (
            <>
              <ShowFilters>
                <Checkboxes
                  activeChecked={props.activeChecked}
                  setActiveChecked={props.setActiveChecked}
                  archivedChecked={props.archivedChecked}
                  setArchivedChecked={props.setArchivedChecked}
                />
              </ShowFilters>
              <SortByArea>
                <SortSelect sort={props.sort} setSort={props.setSort} />
              </SortByArea>
            </>
          )}
        </TopRow>

        {props.variant === "oem" && (
          <BottomRow>
            <ViewingInfo>
              Viewing <strong>{props.orders?.length || 0} Orders</strong>
            </ViewingInfo>

            <SortByArea>
              <SortSelect sort={props.sort} setSort={props.setSort} />
            </SortByArea>
            {fvAdmin ? (
              <SearchButton
                onClick={() => exportOrders(activeOrg.organization_id)}
                css={{ marginLeft: 10 }}
              >
                Export
              </SearchButton>
            ) : null}
          </BottomRow>
        )}
      </Container>
    </>
  );
}
