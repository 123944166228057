import axios from "axios";
import apiUrl from "api-url";
import { getFeatureName } from "modules/organizations/OrganizationsState";

const STORE_MOUNT_POINT = "containerTrackingLocationAssignment";

// action types
const RETRIEVE_LOCATION_CODE = `${STORE_MOUNT_POINT}/RETRIEVE_LOCATION_CODE`;
const RETRIEVING_LOCATION_CODE = `${STORE_MOUNT_POINT}/RETRIEVING_LOCATION_CODE`;
const UPDATE_BUCKET_LOCATIONS = `${STORE_MOUNT_POINT}/UPDATE_BUCKET_LOCATIONS`;
const REMOVE_UPDATE_BUCKET_LOCATIONS = `${STORE_MOUNT_POINT}/REMOVE_UPDATE_BUCKET_LOCATIONS`;
const RESET_UPDATE_BUCKET_LOCATIONS = `${STORE_MOUNT_POINT}/RESET_UPDATE_BUCKET_LOCATIONS`;
const RETRIEVE_CONTAINER_BUCKET = `${STORE_MOUNT_POINT}/RETRIEVE_CONTAINER_BUCKET`;
const RETRIEVING_CONTAINER_BUCKET = `${STORE_MOUNT_POINT}/RETRIEVING_CONTAINER_BUCKET`;
const FETCHING_BUCKET_LOCATIONS = `${STORE_MOUNT_POINT}/FETCHING_BUCKET_LOCATIONS`;
const RETRIEVE_BUCKET_LOCATIONS = `${STORE_MOUNT_POINT}/RETRIEVE_BUCKET_LOCATIONS`;
const VALIDATING_BUCKET_NAME = `${STORE_MOUNT_POINT}/VALIDATING_BUCKET_NAME`;
const VALIDATE_BUCKET_NAME = `${STORE_MOUNT_POINT}/VALIDATE_BUCKET_NAME`;
const SAVED_LOCATION_CHANGES = `${STORE_MOUNT_POINT}/SAVED_LOCATION_CHANGES`;
const SAVING_LOCATION_CHANGES = `${STORE_MOUNT_POINT}/SAVING_LOCATION_CHANGES`;
const UPDATE_FAILED_LOCATIONS = `${STORE_MOUNT_POINT}/UPDATE_FAILED_LOCATIONS`;
const ERROR_ON_SAVING_BUCKET_NAME = `${STORE_MOUNT_POINT}/ERROR_ON_SAVING_BUCKET_NAME`;
const RESET_FAILED_LOCATIONS = `${STORE_MOUNT_POINT}/RESET_FAILED_LOCATIONS`;
const ERROR_ON_SAVE = `${STORE_MOUNT_POINT}/ERROR_ON_SAVE`;
const RESET_ERROR_ON_SAVE = `${STORE_MOUNT_POINT}/RESET_ERROR_ON_SAVE`;
const RESET_BUCKET_LOCATIONS = `${STORE_MOUNT_POINT}/RESET_BUCKET_LOCATIONS`;
const RECIEVE_STATES = `${STORE_MOUNT_POINT}/RECIEVE_STATES`;
const RECIEVE_COUNTRY = `${STORE_MOUNT_POINT}/RECIEVE_COUNTRY`;
const RECIEVED_STATES = `${STORE_MOUNT_POINT}/RECIEVED_STATES`;
const RECIEVED_COUNTRY = `${STORE_MOUNT_POINT}/RECIEVED_COUNTRY`;
const ERROR_ON_VALIDATION = `${STORE_MOUNT_POINT}/ERROR_ON_VALIDATION`;
const RESET_ERROR_ON_VALIDATION = `${STORE_MOUNT_POINT}/RESET_ERROR_ON_VALIDATION`;
const SAVED_BUCKET_NAME = `${STORE_MOUNT_POINT}/SAVED_BUCKET_NAME`;
const IS_FORM_SAVING = `${STORE_MOUNT_POINT}/IS_FORM_SAVING`;

//URLs
const APPLICATION_BASE_URL = apiUrl("/containertracking/api");
const STATES_URL = apiUrl("/location/subdivisions");
const COUNTRIES_URL = apiUrl("/location/countries");

const axiosConfig = (state) => {
  const featureName = getFeatureName(state);
  return {
    headers: {
      "x-target-feature": featureName,
    },
  };
};

const locationLookupUrl = (code) => {
  const baseURL = apiUrl("/location/locations");
  return baseURL + `?code=${code}`;
};

const fetchLocation = (code) => {
  return async (dispatch, getState) => {
    const config = axiosConfig(getState());
    const url = locationLookupUrl(code);
    try {
      dispatch({
        type: RETRIEVING_LOCATION_CODE,
      });
      const response = await axios.get(url, config);
      dispatch({
        type: RETRIEVE_LOCATION_CODE,
        payload: response.data ?? null,
      });
    } catch (err) {
      console.log(err);
    }
  };
};

const fetchAllBucketsUrl = () => APPLICATION_BASE_URL + `/bucket`;

const fetchBucketDetailsFromName = (newBucketName, validation = false) => {
  return async (dispatch, getState) => {
    const url = fetchAllBucketsUrl() + "?bucket_name=" + newBucketName;
    try {
      dispatch({
        type: validation ? VALIDATING_BUCKET_NAME : FETCHING_BUCKET_LOCATIONS,
      });
      const response = await axios.get(url, {
        headers: {
          Accept: "application/json;version=all",
          "x-target-feature": getFeatureName(getState()),
        },
      });
      dispatch({
        type: validation ? VALIDATE_BUCKET_NAME : RETRIEVE_BUCKET_LOCATIONS,
        payload: validation
          ? response.data.length > 0
            ? Object.keys(response.data[0]).length > 0
              ? false
              : true
            : true
          : response.data && response.data[0]
          ? response.data[0].locations ?? []
          : [],
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: ERROR_ON_VALIDATION,
        payload: err?.response?.data?.error?.message ?? null,
      });
    }
  };
};

const fetchStates = (country) => {
  return async (dispatch, getState) => {
    const config = getFeatureName(getState());
    const url = STATES_URL + `/${country}`;
    try {
      dispatch({
        type: RECIEVE_STATES,
      });
      const response = await axios.get(url, config);
      dispatch({
        type: RECIEVED_STATES,
        payload: response.data ? response.data : [],
      });
    } catch (err) {
      console.log(err);
    }
  };
};

const fetchCountries = () => {
  return async (dispatch, getState) => {
    const config = getFeatureName(getState());
    const url = COUNTRIES_URL;
    try {
      dispatch({
        type: RECIEVE_COUNTRY,
      });
      const response = await axios.get(url, config);
      dispatch({
        type: RECIEVED_COUNTRY,
        payload: response.data ? response.data : [],
      });
    } catch (err) {
      console.log(err);
    }
  };
};

const containerBucketFetchUrl = (id) => APPLICATION_BASE_URL + `/bucket/${id}`;

const fetchBucket = (id) => {
  return async (dispatch, getState) => {
    const url = containerBucketFetchUrl(id);
    try {
      dispatch({
        type: RETRIEVING_CONTAINER_BUCKET,
      });
      const response = await axios.get(url, {
        headers: {
          Accept: "application/json;version=all",
          "x-target-feature": getFeatureName(getState()),
        },
      });
      dispatch({
        type: RETRIEVE_CONTAINER_BUCKET,
        payload: response.data[0] ? response.data[0] : null,
      });
    } catch (err) {
      console.log(err);
    }
  };
};

const updateBucketAndLocationData = (
  oldBucketName,
  bucketName,
  isUpdateBucketName,
) => {
  return async (dispatch, getState) => {
    const state = getState();
    const config = getFeatureName(state);
    const url = APPLICATION_BASE_URL + "/bucket";
    dispatch({
      type: SAVING_LOCATION_CHANGES,
    });
    dispatch({
      type: IS_FORM_SAVING,
      payload: true,
    });
    dispatch({
      type: RESET_ERROR_ON_SAVE,
    });
    let requestBody = { data: state[STORE_MOUNT_POINT].updatedLocations };
    if (isUpdateBucketName && oldBucketName) {
      requestBody.rename = {
        bucket_name: oldBucketName,
        new_bucket_name: bucketName,
      };
    }
    await axios
      .put(url, requestBody, config)

      .then((res) => {
        if (res.data.status === 200) {
          dispatch({
            type: SAVED_LOCATION_CHANGES,
          });
          dispatch({
            type: SAVED_BUCKET_NAME,
            payload: Object.keys(res.data.response.success)[0],
          });
          dispatch({
            type: RESET_UPDATE_BUCKET_LOCATIONS,
          });
          if (res.data.response.success._rename) {
            dispatch({
              type: SAVED_BUCKET_NAME,
              payload: res.data.response.success._rename.body.new_bucket_name,
            });
          }
        } else if (res.data.status === 207 || res.data.status === 500) {
          if (res.data.response?.failure?._rename) {
            // show route name error messages as per API response
            dispatch({
              type: ERROR_ON_SAVE,
              payload: res.data.response.failure._rename.body.error,
            });
            dispatch({
              type: ERROR_ON_SAVING_BUCKET_NAME,
              payload: res.data.response.failure._rename.body.error,
            });
          }

          if (res.data.response?.failure[bucketName]?.length) {
            // show error messages on location  error
            dispatch({
              type: UPDATE_FAILED_LOCATIONS,
              payload: {
                list:
                  res.data && res.data.response
                    ? res.data.response.failure[bucketName]?.map((data) => {
                        return data.location_code;
                      })
                    : null,
                name: bucketName,
              },
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: ERROR_ON_SAVE,
        });
      })
      .finally((res) => {
        dispatch({
          type: IS_FORM_SAVING,
          payload: false,
        });
      });
  };
};

const updateBucketLocations = (
  location,
  bucketName,
  id,
  action,
  locationChangesSavedStatus = false,
) => {
  return (dispatch, getState) => {
    const state = getState();
    if (location.isNew && (action === "DELETE") & !locationChangesSavedStatus) {
      const payloadData = state[STORE_MOUNT_POINT].updatedLocations.filter(
        (availableLocation) => {
          return (
            location.details.location_code !== availableLocation.location_code
          );
        },
      );

      dispatch({
        type: REMOVE_UPDATE_BUCKET_LOCATIONS,
        payload: payloadData,
      });
    } else {
      let payload = { bucket_name: bucketName, action };

      if (action === "ADD") {
        payload.container_id = id;
      }

      dispatch({
        type: UPDATE_BUCKET_LOCATIONS,
        payload: {
          ...location.details,
          ...payload,
        },
      });
    }
  };
};

const updateLocationsOnFail = (failedLocations) => {
  return (dispatch, getState) => {
    const state = getState();
    const payloadData = failedLocations
      ? state[STORE_MOUNT_POINT].updatedLocations.filter(
          (availableLocation) => {
            return failedLocations
              ? failedLocations.indexOf(availableLocation.location_code) !== -1
              : false;
          },
        )
      : [];
    dispatch({
      type: REMOVE_UPDATE_BUCKET_LOCATIONS,
      payload: payloadData,
    });
  };
};

const resetFailedLocationsLists = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_FAILED_LOCATIONS,
    });
  };
};

const resetSavedChanges = () => {
  return (dispatch) => {
    dispatch({
      type: SAVING_LOCATION_CHANGES,
    });
  };
};

const resetError = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_ERROR_ON_SAVE,
    });
  };
};

const resetValidationError = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_ERROR_ON_VALIDATION,
    });
  };
};

const resetBucketLoctions = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_BUCKET_LOCATIONS,
    });
  };
};

const resetUpdatedLocations = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_UPDATE_BUCKET_LOCATIONS,
    });
  };
};

const getIsBucketListLoading = (state) => {
  return state[STORE_MOUNT_POINT].isBucketListsLoading;
};

const getBucketDetails = (state) => {
  return state[STORE_MOUNT_POINT].bucketDetails ?? {};
};

const getBucketDetailsLoading = (state) => {
  return state[STORE_MOUNT_POINT].isBucketDetailsLoading;
};

const getLocationDetails = (state) => {
  return state[STORE_MOUNT_POINT].locationCodeDetails ?? null;
};

const getIsLocationDetailsLoading = (state) => {
  return state[STORE_MOUNT_POINT].isLocationDetailsLoading;
};

const getIsValidNewBucketName = (state) => {
  return state[STORE_MOUNT_POINT].isValidBucketName;
};

const getIsValidatingBucketName = (state) => {
  return state[STORE_MOUNT_POINT].isValidatingBucketName;
};

const getBucketLocations = (state) => {
  return state[STORE_MOUNT_POINT].bucketLocations;
};

const getIsFetchingBucketsLocations = (state) => {
  return state[STORE_MOUNT_POINT].isFetchingBucketsLocations;
};

const getChangesSavedStatus = (state) => {
  return state[STORE_MOUNT_POINT].isChangesSaved;
};

const getInitialRouteName = (state) => {
  return state[STORE_MOUNT_POINT].initialBucketName;
};

const getFailedLocations = (state) => {
  return state[STORE_MOUNT_POINT].failedLocationsLists;
};

const getIsErrorOnSave = (state) => {
  return state[STORE_MOUNT_POINT].isErrorOccured;
};

const getErrorMessageOnRouteName = (state) => {
  return state[STORE_MOUNT_POINT].errorMessageOnRouteName;
};

const getUpdateLocations = (state) => {
  return state[STORE_MOUNT_POINT].updatedLocations;
};

const getCountries = (state) => {
  return state[STORE_MOUNT_POINT].countires;
};

const getStates = (state) => {
  return state[STORE_MOUNT_POINT].states;
};

const getValidationError = (state) => {
  return state[STORE_MOUNT_POINT].validationError;
};

const getIsFormSaving = (state) => {
  return state[STORE_MOUNT_POINT].isFormSaving;
};

const initialState = {
  isBucketDetailsLoading: false,
  bucketDetails: {},
  isBucketListsLoading: false,
  bucketLists: [],
  isLocationDetailsLoading: false,
  locationCodeDetails: null,
  updatedLocations: [],
  isValidatingBucketName: false,
  isValidBucketName: false,
  isFetchingBucketsLocations: false,
  bucketLocations: [],
  isChangesSaved: false,
  failedLocationsLists: null,
  isErrorOccured: false,
  errorMessageOnRouteName: {},
  countires: [],
  states: [],
  validationError: null,
  initialBucketName: "",
  isFormSaving: false,
};

const ContainerTrackingDetailsWidgetReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case RETRIEVING_CONTAINER_BUCKET:
      return {
        ...state,
        bucketDetails: {},
        initialBucketName: "",
        isBucketDetailsLoading: true,
      };
    case RETRIEVE_CONTAINER_BUCKET:
      return {
        ...state,
        bucketDetails: action.payload,
        initialBucketName: action.payload?.name ?? "",
        isBucketDetailsLoading: false,
      };
    case RETRIEVING_LOCATION_CODE:
      return {
        ...state,
        isLocationDetailsLoading: true,
      };
    case RETRIEVE_LOCATION_CODE:
      return {
        ...state,
        isLocationDetailsLoading: false,
        locationCodeDetails: action.payload,
      };
    case UPDATE_BUCKET_LOCATIONS:
      return {
        ...state,
        updatedLocations: [
          ...state.updatedLocations,
          {
            ...action.payload,
          },
        ],
      };
    case SAVED_BUCKET_NAME:
      return {
        ...state,
        isChangesSaved: true,
        initialBucketName: action.payload,
      };
    case REMOVE_UPDATE_BUCKET_LOCATIONS:
      return {
        ...state,
        updatedLocations: action.payload,
      };
    case VALIDATING_BUCKET_NAME:
      return {
        ...state,
        isValidatingBucketName: true,
        isValidBucketName: false,
      };
    case VALIDATE_BUCKET_NAME:
      return {
        ...state,
        isValidBucketName: action.payload,
        isValidatingBucketName: false,
      };
    case FETCHING_BUCKET_LOCATIONS:
      return {
        ...state,
        isFetchingBucketsLocations: true,
        bucketLocations: [],
      };
    case RESET_BUCKET_LOCATIONS:
      return {
        ...state,
        isValidBucketName: false,
        bucketLocations: [],
      };
    case RETRIEVE_BUCKET_LOCATIONS:
      return {
        ...state,
        isFetchingBucketsLocations: false,
        bucketLocations: action.payload,
      };
    case SAVING_LOCATION_CHANGES:
      return {
        ...state,
        isChangesSaved: false,
      };
    case SAVED_LOCATION_CHANGES:
      return {
        ...state,
        isChangesSaved: true,
      };
    case RESET_UPDATE_BUCKET_LOCATIONS:
      return {
        ...state,
        errorMessageOnRouteName: {},
        updatedLocations: [],
      };
    case UPDATE_FAILED_LOCATIONS:
      return {
        ...state,
        failedLocationsLists: action.payload?.list ?? "",
        initialBucketName: action.payload?.name ?? "",
      };
    case ERROR_ON_SAVING_BUCKET_NAME:
      return {
        ...state,
        errorMessageOnRouteName: action.payload,
      };
    case RESET_FAILED_LOCATIONS:
      return {
        ...state,
        failedLocationsLists: null,
      };
    case RESET_ERROR_ON_SAVE:
      return {
        ...state,
        isErrorOccured: false,
      };
    case ERROR_ON_SAVE:
      return {
        ...state,
        isErrorOccured: true,
      };
    case RECIEVED_COUNTRY:
      return {
        ...state,
        countires: action.payload,
      };
    case RECIEVED_STATES:
      return {
        ...state,
        states: action.payload,
      };
    case ERROR_ON_VALIDATION:
      return {
        ...state,
        validationError: action.payload,
        isValidatingBucketName: false,
      };
    case RESET_ERROR_ON_VALIDATION:
      return {
        ...state,
        validationError: null,
      };
    case IS_FORM_SAVING:
      return {
        ...state,
        isFormSaving: action.payload,
      };
    default:
      return state;
  }
};

export const ContainerTrackingLocationManagementState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchLocation,
    fetchCountries,
    fetchStates,
    fetchBucket,
    updateBucketAndLocationData,
    updateLocationsOnFail,
    updateBucketLocations,
    fetchBucketDetailsFromName,
    resetFailedLocationsLists,
    resetSavedChanges,
    resetError,
    resetBucketLoctions,
    resetUpdatedLocations,
    resetValidationError,
  },
  selectors: {
    getCountries,
    getStates,
    getBucketDetails,
    getLocationDetails,
    getFailedLocations,
    getIsLocationDetailsLoading,
    getIsValidNewBucketName,
    getBucketLocations,
    getIsBucketListLoading,
    getBucketDetailsLoading,
    getIsValidatingBucketName,
    getIsFetchingBucketsLocations,
    getChangesSavedStatus,
    getIsErrorOnSave,
    getErrorMessageOnRouteName,
    getUpdateLocations,
    getValidationError,
    getInitialRouteName,
    getIsFormSaving,
  },
  reducer: ContainerTrackingDetailsWidgetReducer,
};
