/** @jsxImportSource @emotion/react */
import { AddressTitle, Card, DockTitle } from "../Shared";
import { ValidatorInput } from "pages/rtscao/formLibrary/Input";
import { SelectReasonCode } from "./SelectReasonCode";

export function BasicInfo({ defaultValue = {} }: { defaultValue?: any }) {
  defaultValue = defaultValue || {};
  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        margin: "15px 10px",
        gap: 20,
      }}
    >
      <Card css={{ flexDirection: "column" }}>
        <AddressTitle css={{ margin: "20px 20px 0" }}>
          Requester Information
        </AddressTitle>
        <div
          css={{
            flex: 1,
            display: "flex",
            flexDirection: "row",
            gap: 20,
            padding: "0 20px 20px",
          }}
        >
          <div css={{ flex: 1, fontSize: 14 }}>
            <ValidatorInput
              defaultValue={defaultValue["contact-name"]}
              css={{ fontSize: 14 }}
              label="Contact Name"
              placeholder="Enter Name"
            />
          </div>
          <div css={{ flex: 1, fontSize: 14 }}>
            <ValidatorInput
              defaultValue={defaultValue["contact-email"]}
              css={{ fontSize: 14 }}
              label="Contact Email"
              placeholder="Enter Email"
              type="email"
            />
          </div>
          <div css={{ flex: 1, fontSize: 14 }}>
            <ValidatorInput
              defaultValue={defaultValue["contact-phone"]}
              css={{ fontSize: 14 }}
              label="Contact Phone"
              mask="(___) ___-____"
              maskSlots="_"
              dataAccept="\d"
              showFullMaskWhileTyping={true}
              placeholder="Enter Phone"
            />
          </div>
          <div css={{ flex: 1, fontSize: 14 }}>
            <DockTitle>Reason Code</DockTitle>
            <SelectReasonCode defaultValue={defaultValue.reasonCode} />
          </div>
        </div>
      </Card>
    </div>
  );
}
