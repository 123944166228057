import { partData } from "./partData";
import { plantData } from "./plantData";
const { faker } = require("@faker-js/faker");

const automakerPlants = {};
plantData.forEach((plant) => {
  if (plant.oemShort !== "Ford") {
    return;
  }
  automakerPlants[plant.oemShort] = automakerPlants[plant.oemShort] || [];
  automakerPlants[plant.oemShort].push({
    name: plant.plantName,
    city: plant.city,
    state: plant.state,
    street: plant.street,
  });
});

const automakers = ["Ford"];

// List of carriers commonly used in the automotive industry
const carriers = [
  "FedEx",
  "UPS",
  "DHL",
  "XPO Logistics",
  "C.H. Robinson",
  "Schneider National",
  "J.B. Hunt",
  "YRC Worldwide",
  "Old Dominion Freight Line",
  "Ryder System",
  "Penske Logistics",
  "Landstar System",
  "Ceva Logistics",
  "DSV Panalpina",
  "Kuehne + Nagel",
  "DB Schenker",
  "Expeditors",
  "Estes Express Lines",
  "ArcBest",
  "Saia",
];

function generateOrder() {
  const orderPart = faker.helpers.arrayElement(partData);
  const companyName = faker.helpers.arrayElement(automakers);
  const carrierName = faker.helpers.arrayElement(carriers);
  const statusItems = generateStatusItems(carrierName);
  const carrierAccepted = statusItems[2].status === "active";
  const addrSplit = orderPart.supplierAddress?.split(",") || [];
  const supplierState = addrSplit[0]?.trim() ?? faker.address.state();
  const supplierCity = addrSplit[1]?.trim() ?? faker.address.city();

  const supplierOrigin = {
    name: orderPart.supplierName,
    city: supplierState,
    state: supplierCity,
  };
  const companyDestination = faker.helpers.arrayElement(
    automakerPlants[companyName],
  );

  // get a date 6 hours ago
  const sixHoursAgo = new Date();
  sixHoursAgo.setHours(sixHoursAgo.getHours() - 6);

  const sixHoursFromNow = new Date();
  sixHoursFromNow.setHours(sixHoursFromNow.getHours() + 6);

  const twelveHoursFromNow = new Date();
  twelveHoursFromNow.setHours(twelveHoursFromNow.getHours() + 12);

  const ogPickupDate = faker.date.between({
    from: new Date(),
    to: sixHoursFromNow,
  });

  const destDropDate = faker.date.between({
    from: sixHoursFromNow,
    to: twelveHoursFromNow,
  });

  return {
    id: faker.string.uuid().slice(0, 6),
    supplierName: orderPart.supplierName,
    companyName: companyName,
    carrierName: carrierName,
    carrierType: faker.helpers.arrayElement(["FTL", "LTL", "Parcel"]),
    milesToSupplier: faker.number.int({ min: 10, max: 1000 }),
    totalMiles: faker.number.int({ min: 100, max: 5000 }),
    offerStatus: faker.helpers.arrayElement([
      "Pending",
      "Accepted",
      "Rejected",
    ]),
    price: faker.number.float({ min: 100, max: 10000, multipleOf: 2 }),
    partNo: orderPart.partNumber,
    productInfo: orderPart.description,
    quantity: faker.number.int({ min: 1, max: 1000 }),
    carrierAccepted,
    offers: Array.from(
      { length: faker.number.int({ min: 1, max: 5 }) },
      generateOffer,
    ),
    shipmentDetails: generateShipmentDetails(),
    orderDate: faker.date
      .between({ from: sixHoursAgo, to: new Date() })
      .toISOString(),
    timeAgo: faker.date.recent().toLocaleString(),
    statusItems,
    origin: generateLocation(true, supplierOrigin, ogPickupDate),
    destination: generateLocation(false, companyDestination, destDropDate),
    stops: Array.from(
      { length: faker.number.int({ min: 1, max: 3 }) },
      generateStop,
    ),
  };
}

function generateOffer() {
  return {
    currentShipment: faker.string.uuid().slice(0, 6),
    currentAssetId: faker.string.uuid().slice(0, 6),
    originalMiles: faker.number.int({ min: 100, max: 1000 }),
    additionalMiles: faker.number.int({ min: 10, max: 200 }),
    originalCost: faker.number.float({ min: 500, max: 5000, multipleOf: 2 }),
    newCost: faker.number.float({ min: 600, max: 6000, multipleOf: 2 }),
    currentOrigin: generateLocation(true),
    currentDestination: generateLocation(false),
  };
}

const generateDates = (earlyDate) => {
  const lateDate = new Date(
    earlyDate.getTime() + faker.number.int({ min: 30, max: 90 }) * 60000,
  );
  return {
    earlyDate: earlyDate.toISOString(),
    lateDate: lateDate.toISOString(),
  };
};

const generateDock = () => {
  const dockNumber = faker.number.int({ min: 1, max: 20 });
  const dockLetter = faker.string.alpha({ casing: "upper", count: 1 });
  return `Dock ${dockNumber}${dockLetter}`;
};

function generateLocation(
  isOrigin,
  { name, address, city, state, location, threeWords, atDock, notes } = {},
  earlyDate = new Date(),
) {
  return {
    name: name ?? faker.company.name(),
    address: address ?? faker.location.streetAddress(),
    city: city ?? faker.location.city(),
    state: state ?? faker.location.state(),
    ...generateDates(earlyDate),
    location: generateDock(location),
    threeWords:
      threeWords ??
      `${faker.word.words(1)}.${faker.word.words(1)}.${faker.word.words(1)}`,
    ...(isOrigin && { atDock: atDock ?? faker.datatype.boolean() }),
    notes: notes ?? faker.lorem.sentence(),
  };
}

function generateShipmentDetails() {
  const shipUnitQty = faker.number.int({
    min: 1,
    max: 20,
  });
  return {
    confirmedQty: faker.number.int({ min: 1, max: 100 }),
    grossWt: faker.number.float({ min: 100, max: 10000, multipleOf: 2 }),
    shipUnitQty,
    shipUnit: faker.helpers.arrayElement(["Pallet", "Box", "Container"]),
    shipUnitDimensions: {
      length: faker.number.float({ min: 1, max: 10, multipleOf: 2 }),
      height: faker.number.float({ min: 1, max: 10, multipleOf: 2 }),
      width: faker.number.float({ min: 1, max: 10, multipleOf: 2 }),
    },
    trailerSpaceRequirement: shipUnitQty,
    stackable: faker.datatype.boolean(),
    hazmat: faker.datatype.boolean(),
    temp: faker.datatype.boolean(),
  };
}

function generateStatusItems(carrierName) {
  // const statusTypes = ["active", "inactive", "pending", "declined"];
  const orderStatusTypes = ["active", "inactive", "declined"];
  const statusTypesWithoutDecline = ["active", "inactive"];
  const statusActiveOrInactive = ["active", "inactive"];
  const orderStatus = faker.helpers.arrayElement(orderStatusTypes);
  const orderLabel =
    orderStatus === "declined" ? "Order Declined" : "Order Confirmed";

  const statusItems = [
    {
      label: orderLabel,
      status: orderStatus,
    },
    {
      label: "Ready to Ship",
      status:
        orderStatus === "active"
          ? faker.helpers.arrayElement(statusActiveOrInactive)
          : "inactive",
    },
    {
      label: "Carrier Awarded",
      status: "inactive",
      additionalInfo: "",
    },
    {
      label: "Shipment Assigned",
      status: "inactive",
    },
    { label: "Delivered", status: "inactive" },
  ];

  if (orderStatus === "declined" || orderStatus === "inactive") {
    statusItems[1].status = "inactive";
    statusItems[2].status = "inactive";
    statusItems[3].status = "inactive";
    statusItems[4].status = "inactive";
    return statusItems;
  }

  const carrierStatus = faker.helpers.arrayElement(statusTypesWithoutDecline);

  statusItems[2].status = carrierStatus;
  statusItems[2].additionalInfo = carrierStatus === "active" ? carrierName : "";

  if (carrierStatus !== "active") {
    return statusItems;
  }

  statusItems[3].status = faker.helpers.arrayElement(statusTypesWithoutDecline);

  if (statusItems[3].status !== "active") {
    return statusItems;
  }

  if (statusItems[1].status !== "active") {
    return statusItems;
  }

  statusItems[4].status = faker.helpers.arrayElement(statusTypesWithoutDecline);

  return statusItems;
}

function generateStop() {
  return {
    type: faker.helpers.arrayElement(["Pickup", "Delivery", "Layover"]),
    icon: faker.system.commonFileName(),
    address: faker.location.streetAddress(),
    time: faker.date.soon().toLocaleTimeString(),
  };
}

export function generateFakeOrders(n) {
  return Array.from({ length: n }, generateOrder);
}
