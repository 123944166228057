import { SelectAsync } from "components/atoms/SelectAsync.atom";
import { ComponentProps } from "react";
import { StylesConfig } from "react-select";

const NoSeparator = () => null;

interface SelectWithLabelProps extends ComponentProps<typeof SelectAsync> {
  label: string;
  required?: boolean;
}

export function SelectWithLabel(props: SelectWithLabelProps) {
  const dot = (color: string = "transparent") => ({
    alignItems: "center",
    display: "flex",
    ":before": {
      color: color,
      content: `"${props.label}"`,
      display: "block",
      marginRight: 8,
      height: 22,
    },
  });

  const styles: StylesConfig = {
    control: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: "white",
      borderRadius: 4,
      border: "2px solid #dedede",
    }),
    menuPortal: (baseStyles) => ({
      ...baseStyles,
      zIndex: 999999999999999,
    }),
    input: (baseStyles) => ({ ...baseStyles, ...dot() }),
    placeholder: (baseStyles) => ({ ...baseStyles, ...dot("#ccc") }),
    singleValue: (baseStyles, { data }) => ({ ...baseStyles, ...dot("#ccc") }),
  };

  return (
    <SelectAsync
      {...props}
      styles={styles}
      components={{ IndicatorSeparator: NoSeparator }}
    />
  );
}
