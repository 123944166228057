import {
  CP_SORT_BY_RUN_OUT_BY,
  CP_SORT_DIRECTION_ASCENDING,
} from "../components/organisms/SortBySelect";

const STORE_MOUNT_POINT = "criticalparts";

const SAVE_SORT_COLUMN = "CriticalParts/SAVE_SORT_COLUMN";
const SAVE_SORT_REVERSE = "CriticalParts/SAVE_SORT_REVERSE";

export const setSortColumn = (sortColumn) => {
  return (dispatch) => {
    dispatch({ type: SAVE_SORT_COLUMN, payload: { sortColumn } });
  };
};

export const setSortReverse = (sortReverse) => {
  return (dispatch) => {
    dispatch({ type: SAVE_SORT_REVERSE, payload: { sortReverse } });
  };
};

export const getSortColumn = (state) =>
  state.criticalparts.data.sortColumn ?? initialState.data.sortColumn;

export const getSortReverse = (state) =>
  state.criticalparts.data.sortReverse ?? initialState.data.sortReverse;

const initialState = {
  data: {
    sortColumn: CP_SORT_BY_RUN_OUT_BY,
    sortReverse: CP_SORT_DIRECTION_ASCENDING,
  },
};

const CriticalPartsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SAVE_SORT_COLUMN:
      return {
        ...state,
        data: {
          ...state.data,
          sortColumn: action.payload.sortColumn,
        },
      };

    case SAVE_SORT_REVERSE:
      return {
        ...state,
        data: {
          ...state.data,
          sortReverse: action.payload.sortReverse,
        },
      };

    default:
      return state;
  }
};

const CriticalPartsState = {
  mountPoint: STORE_MOUNT_POINT,
  actionTypes: {},
  actionCreators: { setSortColumn, setSortReverse },
  selectors: { getSortColumn, getSortReverse },
  reducer: CriticalPartsReducer,
};

export default CriticalPartsState;
