/** @jsxImportSource @emotion/react */
import { Modal } from "components/molecules/Modal.molecule";
import { CancelButton, GreenButton } from "../Shared";

interface YesNoConfirmModalProps {
  show: boolean;
  setShow: (show: boolean) => void;
  confirmAction: () => void;
}

export function YesNoConfirmModal(props: YesNoConfirmModalProps) {
  return (
    <Modal show={props.show} onHide={() => props.setShow(false)}>
      <Modal.Header
        title="Confirm?"
        customCss={{
          backgroundColor: "#282828",
          color: "white",
          borderTopRightRadius: "4px",
          borderTopLeftRadius: "4px",
        }}
        closeVariant="white"
      />

      <Modal.Body>
        <h3>Are you sure you want to perform this operation?</h3>
      </Modal.Body>
      <Modal.Footer
        style={{
          borderBottomRightRadius: "4px",
          borderBottomLeftRadius: "4px",
        }}
      >
        <GreenButton type="button" onClick={() => props.setShow(false)}>
          No
        </GreenButton>
        <CancelButton type="button" onClick={() => props.confirmAction()}>
          Yes
        </CancelButton>
      </Modal.Footer>
    </Modal>
  );
}
