/** @jsxImportSource @emotion/react */

import { Order } from "pages/dynamic-normal/service/orderServices";
import { OfferCard } from "../OfferCard/OfferCard";
import { EditOriginDestinationCard } from "../OriginDestinationCard/EditOriginDestinationCard";
import { OriginDestinationCard } from "../OriginDestinationCard/OriginDestinationCard";
import { Card } from "../Shared";
import EditShipmentDetails from "../ShipmentDetailsCard/EditShipmentDetails";
import ShipmentDetails from "../ShipmentDetailsCard/ShipmentDetails";
import { ExpandedContent } from "./OrderCardComponents";

interface ViewOrEditModeProps {
  editMode: boolean;
  variant: string;
  order: Order;
}

interface StandardViewModeProps {
  variant: string;
  order: Order;
}

interface OemEditModeProps {
  order: Order;
  variant: string;
}

interface SupplierEditModeProps {
  order: Order;
}

interface ExpandedSectionProps {
  fRef: React.RefObject<HTMLDivElement>;
  editMode: boolean;
  variant: string;
  order: Order;
}

function ViewOrEditMode({
  editMode,
  variant,
  order,
}: ViewOrEditModeProps): JSX.Element {
  const inactive = order?.statusItems[0].status === "inactive";
  const supplierViewingUnconfirmedOrder = variant === "supplier" && inactive;
  const supplierModifyingExistingOrder = variant === "supplier" && editMode;
  const showSupplierEdit =
    supplierViewingUnconfirmedOrder || supplierModifyingExistingOrder;

  if (variant === "supplier" && showSupplierEdit) {
    return <SupplierEditMode order={order} />;
  } else if (variant === "oem" && editMode) {
    return <OemEditMode order={order} variant={variant} />;
  } else {
    return <StandardViewMode variant={variant} order={order} />;
  }
}

function StandardViewMode({
  variant,
  order,
}: StandardViewModeProps): JSX.Element {
  return (
    <>
      {variant === "carrier" && <OfferCard order={order} />}
      <OriginDestinationCard
        origin={order?.origin}
        destination={order?.destination}
      />
      <ShipmentDetails shipmentDetails={order?.shipmentDetails} />
    </>
  );
}

function OemEditMode({ order, variant }: OemEditModeProps): JSX.Element {
  return (
    <>
      <Card css={{ margin: 10, padding: 10, flexDirection: "column" }}>
        <EditOriginDestinationCard
          origin={order?.origin}
          destination={order?.destination}
          variant={variant}
          order={order}
        />
      </Card>
      {order?.orderType === "shipping" && (
        <Card css={{ margin: 10, padding: 10, flexDirection: "column" }}>
          <EditShipmentDetails shipmentDetails={order?.shipmentDetails} />
        </Card>
      )}
    </>
  );
}

function SupplierEditMode({ order }: SupplierEditModeProps): JSX.Element {
  return (
    <>
      <Card css={{ margin: 10, padding: 10, flexDirection: "column" }}>
        <EditOriginDestinationCard
          origin={order?.origin}
          destination={order?.destination}
          variant="supplier"
          order={order}
        />
      </Card>
      <Card css={{ margin: 10, padding: 10, flexDirection: "column" }}>
        <EditShipmentDetails shipmentDetails={order?.shipmentDetails} />
      </Card>
    </>
  );
}

export function ExpandedSection({
  fRef,
  editMode,
  variant,
  order,
}: ExpandedSectionProps): JSX.Element {
  return (
    <ExpandedContent ref={fRef}>
      <ViewOrEditMode editMode={editMode} variant={variant} order={order} />
    </ExpandedContent>
  );
}
