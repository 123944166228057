/** @jsxImportSource @emotion/react */
import { useTranslation } from "react-i18next";
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useCriticalPartsDetails } from "../hooks/useCriticalPartsDetails";
import { FaAngleLeft } from "react-icons/fa";
import { Text } from "components/atoms/Text.atom";
import Colors from "styles/colors";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { DownloadDataButton } from "components/molecules/DownloadDataButton.molecule";
import { Button } from "react-bootstrap";
import { back } from "redux-first-router";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import { useDetailsColumns } from "../table/CriticalPartsDetails.columns";
import { fetchLocationById } from "../redux/LocationSelectState";
import { useState, useEffect } from "react";
import PropTypes from "prop-types";

const CriticalPartsDetails = ({ locationId, partNumber }) => {
  const { t } = useTranslation("critical-parts");
  useSetTitleOnMount(t("critical-parts:Part Details"));

  const { isFetchingDetails, criticalPartsDetails } = useCriticalPartsDetails(
    locationId,
    partNumber,
  );

  const columnsInventoryItem = useDetailsColumns();

  let inventoryItemDetails = criticalPartsDetails?.data?.inventoryItem
    ? [criticalPartsDetails?.data?.inventoryItem]
    : [];

  const [plantName, setPlantName] = useState();

  useEffect(() => {
    fetchLocationById(locationId).then((plantLocation) => {
      if (plantLocation?.label) {
        setPlantName(plantLocation.label);
      }
    });
  }, [locationId, t]);

  return (
    <div
      css={{
        display: "flex",
        flexDirection: "column",
        padding: "0 1rem",
        gap: "1rem",
        marginTop: "1.5rem",
      }}
    >
      <div
        css={{
          fontSize: "1.25rem",
        }}
      >
        {inventoryItemDetails?.[0]?.partName ??
          t("critical-parts:Unknown Part Name")}
      </div>
      <div
        css={{
          fontSize: "1rem",
        }}
      >
        {plantName}
      </div>
      <div
        css={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          css={{
            display: "flex",
            padding: 0,
            margin: 0,
            alignItems: "center",
            color: Colors.text.GRAY,
            ":hover": {
              color: Colors.background.DARK_BLUE,
            },
          }}
          variant="link"
          onClick={() => {
            back();
          }}
          dataqa="back-to-parts-list"
        >
          <FaAngleLeft onClick={() => {}} />
          <Text underline>{t("critical-parts:Back to Parts List")}</Text>
        </Button>
        <div
          css={{
            display: "flex",
          }}
        >
          <div
            css={{
              display: "flex",
              alignItems: "center",
              color: Colors.text.GRAY,
              ":hover": {
                color: Colors.background.DARK_BLUE,
              },
            }}
          >
            <WatchToggle
              id={"watch"}
              checked={false}
              onChange={() => {}}
              color="Colors.background.GRAY"
              labelText={t("critical-parts:Watch Part")}
              labelLeftAligned={true}
              dataqa="watch-part"
              underlineLabel={true}
            />
          </div>
          <div
            css={{
              display: "flex",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <DownloadDataButton />
          </div>
        </div>
      </div>
      <div
        css={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <BaseTable
          data={inventoryItemDetails}
          columns={columnsInventoryItem}
          isLoading={isFetchingDetails}
          rowClickHandler={null}
        />
      </div>
    </div>
  );
};

CriticalPartsDetails.propTypes = {
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  partNumber: PropTypes.string,
};

export default CriticalPartsDetails;
