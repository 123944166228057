import { useEffect, useRef, RefObject } from "react";

// Add this declaration at the top of the file
declare global {
  interface Window {
    heightAnimationInterval: NodeJS.Timeout | undefined;
  }
}

if (window.heightAnimationInterval === undefined) {
  window.heightAnimationInterval = setInterval(() => {
    const elms = Array.from(
      document.querySelectorAll(".height-animation-expanded"),
    );
    for (const elm of elms) {
      (elm as HTMLElement).style.height = `${elm.scrollHeight}px`;
    }
  }, 100);
}
export function useHeightExpandAnimation(expanded: boolean): RefObject<any> {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (ref.current === null) {
      return;
    }
    if (expanded) {
      ref.current.classList.add("height-animation-expanded");
      ref.current.style.height = `${ref.current.scrollHeight}px`;
      // ref.current.style.overflow = "auto";
    } else {
      ref.current.classList.remove("height-animation-expanded");
      ref.current.style.overflow = "hidden";
      ref.current.style.height = "0px";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  return ref;
}
