export const partData = [
  {
    _id: "parts-gm-00686186-000243147",
    partNumber: "00686186",
    description: "BAG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-01605396-099051716",
    partNumber: "01605396",
    description: "BLK=X/TREE",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-01999431-829470223",
    partNumber: "01999431",
    description: "RETAINER-C/LTR",
    supplierName: "CASCO PRODUCTS CORP",
    supplierAddress: "MORGANTOWN,KY",
    supplierStreet: "",
    supplierCity: "MORGANTOWN",
    supplierState: "KY",
    supplierZip: "422617361",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-03875313-004913364",
    partNumber: "03875313",
    description: "ATTACHMENT PKG-",
    supplierName: "GOODWILL INDUSTRIES OF GRTR DETROIT",
    supplierAddress: "DETROIT,MI",
    supplierStreet: "",
    supplierCity: "DETROIT",
    supplierState: "MI",
    supplierZip: "482082962",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-09132667-050516009",
    partNumber: "09132667",
    description: "POP RIVET",
    supplierName: "GESIPA FASTENERS USA INC",
    supplierAddress: "MOCKSVILLE,NC",
    supplierStreet: "",
    supplierCity: "MOCKSVILLE",
    supplierState: "NC",
    supplierZip: "270284415",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-09593595-000159178",
    partNumber: "09593595",
    description: "STEM=T/VLV",
    supplierName: "TOPSEAL SHANGHAI AUTOPARTS CO LTD",
    supplierAddress: "CANTON,MI",
    supplierStreet: "",
    supplierCity: "CANTON",
    supplierState: "MI",
    supplierZip: "48187",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-09595174-557463135",
    partNumber: "09595174",
    description: "W/LUG=NUT",
    supplierName: "SAMJIN AMERICA CORP",
    supplierAddress: "ROCHESTER HILLS,MI",
    supplierStreet: "",
    supplierCity: "ROCHESTER HILLS",
    supplierState: "MI",
    supplierZip: "483093808",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-09596070-060414450",
    partNumber: "09596070",
    description: "W/LUG=NUT",
    supplierName: "MACLEAN-FOGG CO",
    supplierAddress: "ROYAL OAK,MI",
    supplierStreet: "",
    supplierCity: "ROYAL OAK",
    supplierState: "MI",
    supplierZip: "480731609",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-09598773-000152496",
    partNumber: "09598773",
    description: "WEIGHT CTL",
    supplierName: "3M CO",
    supplierAddress: "WOODVILLE,WI",
    supplierStreet: "",
    supplierCity: "WOODVILLE",
    supplierState: "WI",
    supplierZip: "54028",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-09599506-249188079",
    partNumber: "09599506",
    description: "NUT PKG-SPL SEC",
    supplierName: "NIFAST CANADA CORP",
    supplierAddress: "INGERSOLL,ON",
    supplierStreet: "",
    supplierCity: "INGERSOLL",
    supplierState: "ON",
    supplierZip: "N5C 3K1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-10134407-615591617",
    partNumber: "10134407",
    description: "RETAINER-CLOSE",
    supplierName: "NATIONAL MOLDING LLC",
    supplierAddress: "MIAMI LAKES,FL",
    supplierStreet: "",
    supplierCity: "MIAMI LAKES",
    supplierState: "FL",
    supplierZip: "330142806",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-10239197-795602551",
    partNumber: "10239197",
    description: "COVER-A/TRNS MA",
    supplierName: "STEPHEN GOULD CORP",
    supplierAddress: "COMMERCE TOWNSHIP,MI",
    supplierStreet: "",
    supplierCity: "COMMERCE TOWNSHIP",
    supplierState: "MI",
    supplierZip: "48390",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-10239198-795602551",
    partNumber: "10239198",
    description: "COVER",
    supplierName: "STEPHEN GOULD CORP",
    supplierAddress: "COMMERCE TOWNSHIP,MI",
    supplierStreet: "",
    supplierCity: "COMMERCE TOWNSHIP",
    supplierState: "MI",
    supplierZip: "48390",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-10271003-621472831",
    partNumber: "10271003",
    description: "STUD-SUSPN",
    supplierName: "PENN ENGINEERING & MANUFACTURING CO",
    supplierAddress: "SHELBY TOWNSHIP,MI",
    supplierStreet: "",
    supplierCity: "SHELBY TOWNSHIP",
    supplierState: "MI",
    supplierZip: "483153128",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-10372326-243459851",
    partNumber: "10372326",
    description: "W/HOIST=GDE",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-10372327-243459851",
    partNumber: "10372327",
    description: "W/HOIST=GDE",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-10385879-004913364",
    partNumber: "10385879",
    description: "L/PLT=PKG",
    supplierName: "GOODWILL INDUSTRIES OF GRTR DETROIT",
    supplierAddress: "DETROIT,MI",
    supplierStreet: "",
    supplierCity: "DETROIT",
    supplierState: "MI",
    supplierZip: "482082962",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11104191-315960245",
    partNumber: "11104191",
    description: "HEXAGON NUT ON2",
    supplierName: "NEDSCHROEF PLETTENBERG GMBH",
    supplierAddress: "PLETTENBERG,NW",
    supplierStreet: "",
    supplierCity: "PLETTENBERG",
    supplierState: "NW",
    supplierZip: "58840",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-11507067-808532071",
    partNumber: "11507067",
    description: "NUT",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11509086-604974600",
    partNumber: "11509086",
    description: "STRAP - 200MM T",
    supplierName: "HELLERMANNTYTON CORP",
    supplierAddress: "ROMEOVILLE,IL",
    supplierStreet: "",
    supplierCity: "ROMEOVILLE",
    supplierState: "IL",
    supplierZip: "604463500",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11514596-243799157",
    partNumber: "11514596",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11514597-243799157",
    partNumber: "11514597",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11515638-256873100",
    partNumber: "11515638",
    description: "NUT",
    supplierName: "A RAYMOND TINNERMAN MFG HAMILTON IN",
    supplierAddress: "HAMILTON,ON",
    supplierStreet: "",
    supplierCity: "HAMILTON",
    supplierState: "ON",
    supplierZip: "L8H 5Z4",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11515767-080681453",
    partNumber: "11515767",
    description: "BOLT/SCREW",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11515768-081259198",
    partNumber: "11515768",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11515842-256873100",
    partNumber: "11515842",
    description: "NUT-MET TYPE DL",
    supplierName: "A RAYMOND TINNERMAN MFG HAMILTON IN",
    supplierAddress: "HAMILTON,ON",
    supplierStreet: "",
    supplierCity: "HAMILTON",
    supplierState: "ON",
    supplierZip: "L8H 5Z4",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11515892-097274534",
    partNumber: "11515892",
    description: "SCREW-METRIC RN",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11516133-243799157",
    partNumber: "11516133",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11516383-243799157",
    partNumber: "11516383",
    description: "HD NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11516594-081259198",
    partNumber: "11516594",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11516611-081259198",
    partNumber: "11516611",
    description: "SCR ASM MACH",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11516755-243799157",
    partNumber: "11516755",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11517079-058961707",
    partNumber: "11517079",
    description: "NUT- MET TY C W",
    supplierName: "A RAYMOND TINNERMAN AUTOMOTIVE INC",
    supplierAddress: "LOGANSPORT,IN",
    supplierStreet: "",
    supplierCity: "LOGANSPORT",
    supplierState: "IN",
    supplierZip: "469478269",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11517769-808532071",
    partNumber: "11517769",
    description: "NUT-PUSH IN",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11517806-256873100",
    partNumber: "11517806",
    description: "F/TK=NUT",
    supplierName: "A RAYMOND TINNERMAN MFG HAMILTON IN",
    supplierAddress: "HAMILTON,ON",
    supplierStreet: "",
    supplierCity: "HAMILTON",
    supplierState: "ON",
    supplierZip: "L8H 5Z4",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11517827-081259198",
    partNumber: "11517827",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11517901-081259198",
    partNumber: "11517901",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11518294-097274534",
    partNumber: "11518294",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11518935-081259198",
    partNumber: "11518935",
    description: "BALL=STUD",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519325-835469453",
    partNumber: "11519325",
    description: "WELD=STUD",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519374-835469453",
    partNumber: "11519374",
    description: "BOLT ASM - HX H",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519375-055908586",
    partNumber: "11519375",
    description: "BOLT/SCREW",
    supplierName: "WHITESELL CORP",
    supplierAddress: "MUSCLE SHOALS,AL",
    supplierStreet: "",
    supplierCity: "MUSCLE SHOALS",
    supplierState: "AL",
    supplierZip: "356612705",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519376-055908586",
    partNumber: "11519376",
    description: "BOLT ASM - HX H",
    supplierName: "WHITESELL CORP",
    supplierAddress: "MUSCLE SHOALS,AL",
    supplierStreet: "",
    supplierCity: "MUSCLE SHOALS",
    supplierState: "AL",
    supplierZip: "356612705",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519377-055908586",
    partNumber: "11519377",
    description: "BOLT/SCREW",
    supplierName: "WHITESELL CORP",
    supplierAddress: "MUSCLE SHOALS,AL",
    supplierStreet: "",
    supplierCity: "MUSCLE SHOALS",
    supplierState: "AL",
    supplierZip: "356612705",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519381-097274534",
    partNumber: "11519381",
    description: "BOLT ASM-HX HD",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519382-835469453",
    partNumber: "11519382",
    description: "BOLT/SCREW",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519383-835469453",
    partNumber: "11519383",
    description: "BOLT/SCREW",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519384-097274534",
    partNumber: "11519384",
    description: "BOLT ASM-HX HD",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519387-835469453",
    partNumber: "11519387",
    description: "BOLT ASM - HX H",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519388-835469453",
    partNumber: "11519388",
    description: "BOLT/SCREW",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519389-081259198",
    partNumber: "11519389",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519391-080681453",
    partNumber: "11519391",
    description: "BOLT ASM-HX HD",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519508-006553036",
    partNumber: "11519508",
    description: "BOLT ASM-HX HD",
    supplierName: "ALL STATE FASTENER CORP",
    supplierAddress: "ROSEVILLE,MI",
    supplierStreet: "",
    supplierCity: "ROSEVILLE",
    supplierState: "MI",
    supplierZip: "480661839",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519527-941717472",
    partNumber: "11519527",
    description: "BOLT/SCREW",
    supplierName: "SEMBLEX CORP",
    supplierAddress: "ELMHURST,IL",
    supplierStreet: "",
    supplierCity: "ELMHURST",
    supplierState: "IL",
    supplierZip: "601261003",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519537-080681453",
    partNumber: "11519537",
    description: "BOLT ASM-HX HD",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11519894-081259198",
    partNumber: "11519894",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546340-808532071",
    partNumber: "11546340",
    description: "NUT",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546364-243799157",
    partNumber: "11546364",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546365-243799157",
    partNumber: "11546365",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546367-243799157",
    partNumber: "11546367",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546377-243799157",
    partNumber: "11546377",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546378-243799157",
    partNumber: "11546378",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546389-243799157",
    partNumber: "11546389",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546396-000240218",
    partNumber: "11546396",
    description: "BOLT/SCREW",
    supplierName: "SUMEEKO INDUSTRIES CO LTD",
    supplierAddress: "HARRISON TOWNSHIP,MI",
    supplierStreet: "",
    supplierCity: "HARRISON TOWNSHIP",
    supplierState: "MI",
    supplierZip: "480451307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546404-080681453",
    partNumber: "11546404",
    description: "BOLT/SCREW",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546405-000240218",
    partNumber: "11546405",
    description: "BOLT/SCREW",
    supplierName: "SUMEEKO INDUSTRIES CO LTD",
    supplierAddress: "HARRISON TOWNSHIP,MI",
    supplierStreet: "",
    supplierCity: "HARRISON TOWNSHIP",
    supplierState: "MI",
    supplierZip: "480451307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546437-243799157",
    partNumber: "11546437",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546439-243799157",
    partNumber: "11546439",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546441-249188079",
    partNumber: "11546441",
    description: "NUT",
    supplierName: "NIFAST CANADA CORP",
    supplierAddress: "INGERSOLL,ON",
    supplierStreet: "",
    supplierCity: "INGERSOLL",
    supplierState: "ON",
    supplierZip: "N5C 3K1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546448-243799157",
    partNumber: "11546448",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546449-243799157",
    partNumber: "11546449",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546454-249188079",
    partNumber: "11546454",
    description: "NUT",
    supplierName: "NIFAST CANADA CORP",
    supplierAddress: "INGERSOLL,ON",
    supplierStreet: "",
    supplierCity: "INGERSOLL",
    supplierState: "ON",
    supplierZip: "N5C 3K1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546456-243799157",
    partNumber: "11546456",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546459-835469453",
    partNumber: "11546459",
    description: "NUT",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546460-249188079",
    partNumber: "11546460",
    description: "NUT ASM",
    supplierName: "NIFAST CANADA CORP",
    supplierAddress: "INGERSOLL,ON",
    supplierStreet: "",
    supplierCity: "INGERSOLL",
    supplierState: "ON",
    supplierZip: "N5C 3K1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546467-240773507",
    partNumber: "11546467",
    description: "NUT",
    supplierName: "RB&W CORP OF CANADA",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6S 4R5",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546469-005354907",
    partNumber: "11546469",
    description: "BOLT/SCREW",
    supplierName: "KAMAX LP",
    supplierAddress: "LAPEER,MI",
    supplierStreet: "",
    supplierCity: "LAPEER",
    supplierState: "MI",
    supplierZip: "484463303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546493-097274534",
    partNumber: "11546493",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546545-243799157",
    partNumber: "11546545",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546566-243799157",
    partNumber: "11546566",
    description: "BOLT/SCREW",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546571-243799157",
    partNumber: "11546571",
    description: "BOLT/SCREW",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546583-808532071",
    partNumber: "11546583",
    description: "NUT-U/SPRING",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546703-243799157",
    partNumber: "11546703",
    description: "BOLT/SCREW",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11546773-006553036",
    partNumber: "11546773",
    description: "NUT",
    supplierName: "ALL STATE FASTENER CORP",
    supplierAddress: "ROSEVILLE,MI",
    supplierStreet: "",
    supplierCity: "ROSEVILLE",
    supplierState: "MI",
    supplierZip: "480661839",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546791-080681453",
    partNumber: "11546791",
    description: "BOLT/SCREW",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546843-059897736",
    partNumber: "11546843",
    description: "STUD",
    supplierName: "EMHART TEKNOLOGIES LLC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512521",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546868-808532071",
    partNumber: "11546868",
    description: "NUT-TYPE-PUSH I",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546890-081259198",
    partNumber: "11546890",
    description: "STUD-DOUBLE END",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546968-623860249",
    partNumber: "11546968",
    description: "BOLT/SCREW",
    supplierName: "TAEYANG AMERICA CORP",
    supplierAddress: "TROY,MI",
    supplierStreet: "",
    supplierCity: "TROY",
    supplierState: "MI",
    supplierZip: "480847144",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546969-941717472",
    partNumber: "11546969",
    description: "BOLT/SCREW",
    supplierName: "SEMBLEX CORP",
    supplierAddress: "ELMHURST,IL",
    supplierStreet: "",
    supplierCity: "ELMHURST",
    supplierState: "IL",
    supplierZip: "601261003",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11546970-941717472",
    partNumber: "11546970",
    description: "BOLT/SCREW",
    supplierName: "SEMBLEX CORP",
    supplierAddress: "ELMHURST,IL",
    supplierStreet: "",
    supplierCity: "ELMHURST",
    supplierState: "IL",
    supplierZip: "601261003",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11547060-081259198",
    partNumber: "11547060",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11547070-080681453",
    partNumber: "11547070",
    description: "BOLT-HEX FLANGE",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11547090-243799157",
    partNumber: "11547090",
    description: "NUT ASM FREE SP",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11547139-243799157",
    partNumber: "11547139",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11547342-080681453",
    partNumber: "11547342",
    description: "BOLT/SCREW",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11547349-243799157",
    partNumber: "11547349",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11547366-835469453",
    partNumber: "11547366",
    description: "BOLT/SCREW",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11547369-243799157",
    partNumber: "11547369",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11547412-623860249",
    partNumber: "11547412",
    description: "BOLT/SCREW",
    supplierName: "TAEYANG AMERICA CORP",
    supplierAddress: "TROY,MI",
    supplierStreet: "",
    supplierCity: "TROY",
    supplierState: "MI",
    supplierZip: "480847144",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11547421-097274534",
    partNumber: "11547421",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11547446-057856247",
    partNumber: "11547446",
    description: "CLIP",
    supplierName: "TERMAX LLC",
    supplierAddress: "LAKE ZURICH,IL",
    supplierStreet: "",
    supplierCity: "LAKE ZURICH",
    supplierState: "IL",
    supplierZip: "600471525",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11547582-256873100",
    partNumber: "11547582",
    description: "NUT-PUSH IN",
    supplierName: "A RAYMOND TINNERMAN MFG HAMILTON IN",
    supplierAddress: "HAMILTON,ON",
    supplierStreet: "",
    supplierCity: "HAMILTON",
    supplierState: "ON",
    supplierZip: "L8H 5Z4",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11547627-081259198",
    partNumber: "11547627",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11547669-081259198",
    partNumber: "11547669",
    description: "STUD",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11547755-097274534",
    partNumber: "11547755",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11547936-840618722",
    partNumber: "11547936",
    description: "BOLT/SCREW",
    supplierName: "EXCO AUTOMOTIVE SOLUTIONS LP",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "78520",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548191-941717472",
    partNumber: "11548191",
    description: "BOLT/SCREW",
    supplierName: "SEMBLEX CORP",
    supplierAddress: "ELMHURST,IL",
    supplierStreet: "",
    supplierCity: "ELMHURST",
    supplierState: "IL",
    supplierZip: "601261003",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548199-081259198",
    partNumber: "11548199",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548270-097274534",
    partNumber: "11548270",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548274-005354907",
    partNumber: "11548274",
    description: "BOLT/SCREW",
    supplierName: "KAMAX LP",
    supplierAddress: "LAPEER,MI",
    supplierStreet: "",
    supplierCity: "LAPEER",
    supplierState: "MI",
    supplierZip: "484463303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548298-808532071",
    partNumber: "11548298",
    description: "NUT-U/SPRING",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548414-243799157",
    partNumber: "11548414",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11548472-005354907",
    partNumber: "11548472",
    description: "BOLT/SCREW",
    supplierName: "KAMAX LP",
    supplierAddress: "LAPEER,MI",
    supplierStreet: "",
    supplierCity: "LAPEER",
    supplierState: "MI",
    supplierZip: "484463303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548564-097274534",
    partNumber: "11548564",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548565-081259198",
    partNumber: "11548565",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548579-256873100",
    partNumber: "11548579",
    description: "NUT-U/SPRING",
    supplierName: "A RAYMOND TINNERMAN MFG HAMILTON IN",
    supplierAddress: "HAMILTON,ON",
    supplierStreet: "",
    supplierCity: "HAMILTON",
    supplierState: "ON",
    supplierZip: "L8H 5Z4",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11548581-808532071",
    partNumber: "11548581",
    description: "NUT-U/SPRING",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548583-808532071",
    partNumber: "11548583",
    description: "NUT-U/SPRING",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548585-808532071",
    partNumber: "11548585",
    description: "NUT-U/SPRING",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548587-058961707",
    partNumber: "11548587",
    description: "NUT-U/SPRING",
    supplierName: "A RAYMOND TINNERMAN AUTOMOTIVE INC",
    supplierAddress: "LOGANSPORT,IN",
    supplierStreet: "",
    supplierCity: "LOGANSPORT",
    supplierState: "IN",
    supplierZip: "469478269",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548588-808532071",
    partNumber: "11548588",
    description: "NUT-U/SPRING",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548590-808532071",
    partNumber: "11548590",
    description: "NUT-U/SPRING",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548673-256873100",
    partNumber: "11548673",
    description: "NUT-PUSH IN",
    supplierName: "A RAYMOND TINNERMAN MFG HAMILTON IN",
    supplierAddress: "HAMILTON,ON",
    supplierStreet: "",
    supplierCity: "HAMILTON",
    supplierState: "ON",
    supplierZip: "L8H 5Z4",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11548870-080681453",
    partNumber: "11548870",
    description: "BOLT/SCREW",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11548998-080681453",
    partNumber: "11548998",
    description: "BOLT/SCREW",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11549052-024953291",
    partNumber: "11549052",
    description: "BOLT/SCREW",
    supplierName: "CAMCAR LLC",
    supplierAddress: "BELVIDERE,IL",
    supplierStreet: "",
    supplierCity: "BELVIDERE",
    supplierState: "IL",
    supplierZip: "610082364",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11549092-081259198",
    partNumber: "11549092",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11549110-080681453",
    partNumber: "11549110",
    description: "BOLT/SCREW",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11549121-081259198",
    partNumber: "11549121",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11549126-059897736",
    partNumber: "11549126",
    description: "RETAINER NUT PU",
    supplierName: "EMHART TEKNOLOGIES LLC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512521",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11549168-243799157",
    partNumber: "11549168",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11549169-243799157",
    partNumber: "11549169",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11549170-243799157",
    partNumber: "11549170",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11549171-243799157",
    partNumber: "11549171",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11549180-081259198",
    partNumber: "11549180",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11549199-143630924",
    partNumber: "11549199",
    description: "BOLT/SCREW",
    supplierName: "AGRATI MEDINA LLC",
    supplierAddress: "MEDINA,OH",
    supplierStreet: "",
    supplierCity: "MEDINA",
    supplierState: "OH",
    supplierZip: "442562453",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11549204-243799157",
    partNumber: "11549204",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11549258-049816044",
    partNumber: "11549258",
    description: "CLIP",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "FRANKFORT,IL",
    supplierStreet: "",
    supplierCity: "FRANKFORT",
    supplierState: "IL",
    supplierZip: "604236017",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11549262-315960245",
    partNumber: "11549262",
    description: "NUT",
    supplierName: "NEDSCHROEF PLETTENBERG GMBH",
    supplierAddress: "PLETTENBERG,NW",
    supplierStreet: "",
    supplierCity: "PLETTENBERG",
    supplierState: "NW",
    supplierZip: "58840",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-11549288-557463135",
    partNumber: "11549288",
    description: "NUT",
    supplierName: "SAMJIN AMERICA CORP",
    supplierAddress: "ROCHESTER HILLS,MI",
    supplierStreet: "",
    supplierCity: "ROCHESTER HILLS",
    supplierState: "MI",
    supplierZip: "483093808",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11549310-249188079",
    partNumber: "11549310",
    description: "NUT",
    supplierName: "NIFAST CANADA CORP",
    supplierAddress: "INGERSOLL,ON",
    supplierStreet: "",
    supplierCity: "INGERSOLL",
    supplierState: "ON",
    supplierZip: "N5C 3K1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11549315-835469453",
    partNumber: "11549315",
    description: "BOLT/SCREW",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11549316-249188079",
    partNumber: "11549316",
    description: "NUT",
    supplierName: "NIFAST CANADA CORP",
    supplierAddress: "INGERSOLL,ON",
    supplierStreet: "",
    supplierCity: "INGERSOLL",
    supplierState: "ON",
    supplierZip: "N5C 3K1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11549359-623860249",
    partNumber: "11549359",
    description: "BOLT/SCREW",
    supplierName: "TAEYANG AMERICA CORP",
    supplierAddress: "TROY,MI",
    supplierStreet: "",
    supplierCity: "TROY",
    supplierState: "MI",
    supplierZip: "480847144",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11549370-143630924",
    partNumber: "11549370",
    description: "BOLT/SCREW",
    supplierName: "AGRATI MEDINA LLC",
    supplierAddress: "MEDINA,OH",
    supplierStreet: "",
    supplierCity: "MEDINA",
    supplierState: "OH",
    supplierZip: "442562453",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11549425-000240218",
    partNumber: "11549425",
    description: "NUT",
    supplierName: "SUMEEKO INDUSTRIES CO LTD",
    supplierAddress: "HARRISON TOWNSHIP,MI",
    supplierStreet: "",
    supplierCity: "HARRISON TOWNSHIP",
    supplierState: "MI",
    supplierZip: "480451307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11561075-080681453",
    partNumber: "11561075",
    description: "BOLT/SCREW",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11561233-097274534",
    partNumber: "11561233",
    description: "HD T/BAR=NUT",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11561299-081259198",
    partNumber: "11561299",
    description: "BOLT-HVY HX FLG",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11561300-081259198",
    partNumber: "11561300",
    description: "BOLT-HVY HX FLG",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11561302-835469453",
    partNumber: "11561302",
    description: "BOLT-HVY HX FLG",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11561306-080681453",
    partNumber: "11561306",
    description: "BOLT - HVY HX F",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11561435-240773507",
    partNumber: "11561435",
    description: "NUT",
    supplierName: "RB&W CORP OF CANADA",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6S 4R5",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11561629-256873100",
    partNumber: "11561629",
    description: "NUT - PUSH IN",
    supplierName: "A RAYMOND TINNERMAN MFG HAMILTON IN",
    supplierAddress: "HAMILTON,ON",
    supplierStreet: "",
    supplierCity: "HAMILTON",
    supplierState: "ON",
    supplierZip: "L8H 5Z4",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11561645-243799157",
    partNumber: "11561645",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11561763-081259198",
    partNumber: "11561763",
    description: "SCR ASM-HX HD C",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11561855-019883003",
    partNumber: "11561855",
    description: "NUT ASM-HX AND",
    supplierName: "JMA LOGISTICS LLC",
    supplierAddress: "LIVONIA,MI",
    supplierStreet: "",
    supplierCity: "LIVONIA",
    supplierState: "MI",
    supplierZip: "481502272",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11561936-621472831",
    partNumber: "11561936",
    description: "STUD-ALTERNATOR",
    supplierName: "PENN ENGINEERING & MANUFACTURING CO",
    supplierAddress: "SHELBY TOWNSHIP,MI",
    supplierStreet: "",
    supplierCity: "SHELBY TOWNSHIP",
    supplierState: "MI",
    supplierZip: "483153128",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11561969-080681453",
    partNumber: "11561969",
    description: "BOLT ASM-HX HD",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11562061-835469453",
    partNumber: "11562061",
    description: "BOLT ASM - HEX",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11562133-835469453",
    partNumber: "11562133",
    description: "BOLT ASM - HEX",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11562464-059897736",
    partNumber: "11562464",
    description: "STUD ASM-WS RND",
    supplierName: "EMHART TEKNOLOGIES LLC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512521",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11562485-835469453",
    partNumber: "11562485",
    description: "NUT",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11562507-143631294",
    partNumber: "11562507",
    description: "BOLT-HEX FLG HD",
    supplierName: "AGRATI - PARK FOREST LLC",
    supplierAddress: "PARK FOREST,IL",
    supplierStreet: "",
    supplierCity: "PARK FOREST",
    supplierState: "IL",
    supplierZip: "604663428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11562584-621472831",
    partNumber: "11562584",
    description: "BOLT/SCREW",
    supplierName: "PENN ENGINEERING & MANUFACTURING CO",
    supplierAddress: "SHELBY TOWNSHIP,MI",
    supplierStreet: "",
    supplierCity: "SHELBY TOWNSHIP",
    supplierState: "MI",
    supplierZip: "483153128",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11562587-081259198",
    partNumber: "11562587",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11569600-081259198",
    partNumber: "11569600",
    description: "BOLT ASM-HEX HD",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11569789-835469453",
    partNumber: "11569789",
    description: "L/GT=BOLT",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11569839-059897736",
    partNumber: "11569839",
    description: "NUT",
    supplierName: "EMHART TEKNOLOGIES LLC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512521",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11569886-081259198",
    partNumber: "11569886",
    description: "SCREW-HEX FLANG",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11569919-249188079",
    partNumber: "11569919",
    description: "BOLT/SCREW",
    supplierName: "NIFAST CANADA CORP",
    supplierAddress: "INGERSOLL,ON",
    supplierStreet: "",
    supplierCity: "INGERSOLL",
    supplierState: "ON",
    supplierZip: "N5C 3K1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11569939-080681453",
    partNumber: "11569939",
    description: "BOLT/SCREW",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11570007-081259198",
    partNumber: "11570007",
    description: "BOLT ASM-HEX HD",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11570092-005320304",
    partNumber: "11570092",
    description: "BOLT-HEX FLANGE",
    supplierName: "COLD HEADING CO",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480892544",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11570273-315960245",
    partNumber: "11570273",
    description: "NUT-HEX FLANGE",
    supplierName: "NEDSCHROEF PLETTENBERG GMBH",
    supplierAddress: "PLETTENBERG,NW",
    supplierStreet: "",
    supplierCity: "PLETTENBERG",
    supplierState: "NW",
    supplierZip: "58840",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-11570298-835469453",
    partNumber: "11570298",
    description: "BOLT/SCREW",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11570299-097274534",
    partNumber: "11570299",
    description: "BOLT ASM-HEX HE",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11570364-059897736",
    partNumber: "11570364",
    description: "STUD ASM-WELD W",
    supplierName: "EMHART TEKNOLOGIES LLC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512521",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11570498-097274534",
    partNumber: "11570498",
    description: "SCREW ASM-HI HE",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11570499-097274534",
    partNumber: "11570499",
    description: "SCREW ASM-HI HE",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11570500-081259198",
    partNumber: "11570500",
    description: "BOLT ASM-HVY HE",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11570516-080681453",
    partNumber: "11570516",
    description: "BOLT ASM-W/CONI",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11570584-143631294",
    partNumber: "11570584",
    description: "SCREW ASM-TAP H",
    supplierName: "AGRATI - PARK FOREST LLC",
    supplierAddress: "PARK FOREST,IL",
    supplierStreet: "",
    supplierCity: "PARK FOREST",
    supplierState: "IL",
    supplierZip: "604663428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11570637-097274534",
    partNumber: "11570637",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11570742-049816044",
    partNumber: "11570742",
    description: "NUT-OPEN ENDED",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "FRANKFORT,IL",
    supplierStreet: "",
    supplierCity: "FRANKFORT",
    supplierState: "IL",
    supplierZip: "604236017",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11570934-097274534",
    partNumber: "11570934",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11570961-024953291",
    partNumber: "11570961",
    description: "SCREW ASM-HEX H",
    supplierName: "CAMCAR LLC",
    supplierAddress: "BELVIDERE,IL",
    supplierStreet: "",
    supplierCity: "BELVIDERE",
    supplierState: "IL",
    supplierZip: "610082364",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11571019-080681453",
    partNumber: "11571019",
    description: "BOLT ASM-HEX HD",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11571069-080681453",
    partNumber: "11571069",
    description: "BOLT ASM-HEX HE",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11571162-055908586",
    partNumber: "11571162",
    description: "SCREW-FLT 90 DE",
    supplierName: "WHITESELL CORP",
    supplierAddress: "MUSCLE SHOALS,AL",
    supplierStreet: "",
    supplierCity: "MUSCLE SHOALS",
    supplierState: "AL",
    supplierZip: "356612705",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11571265-097274534",
    partNumber: "11571265",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588273-835469453",
    partNumber: "11588273",
    description: "BOLT/SCREW",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588293-240773507",
    partNumber: "11588293",
    description: "NUT-HX FLG W/NY",
    supplierName: "RB&W CORP OF CANADA",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6S 4R5",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11588316-097274534",
    partNumber: "11588316",
    description: "STUD-METRIC TYP",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588317-006553036",
    partNumber: "11588317",
    description: "FDR=STUD",
    supplierName: "ALL STATE FASTENER CORP",
    supplierAddress: "ROSEVILLE,MI",
    supplierStreet: "",
    supplierCity: "ROSEVILLE",
    supplierState: "MI",
    supplierZip: "480661839",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588323-052203171",
    partNumber: "11588323",
    description: "NUT",
    supplierName: "P&R FASTENERS INC",
    supplierAddress: "SOMERSET,NJ",
    supplierStreet: "",
    supplierCity: "SOMERSET",
    supplierState: "NJ",
    supplierZip: "088731229",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588468-623860249",
    partNumber: "11588468",
    description: "BOLT-HEX HEAD",
    supplierName: "TAEYANG AMERICA CORP",
    supplierAddress: "TROY,MI",
    supplierStreet: "",
    supplierCity: "TROY",
    supplierState: "MI",
    supplierZip: "480847144",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588564-081259198",
    partNumber: "11588564",
    description: "BOLT ASM - HX H",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588685-097274534",
    partNumber: "11588685",
    description: "STUD - METRIC T",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588709-243799157",
    partNumber: "11588709",
    description: "BOLT - HVY HX A",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11588711-097274534",
    partNumber: "11588711",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588712-097274534",
    partNumber: "11588712",
    description: "BOLT-HVY HEX AC",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588713-081259198",
    partNumber: "11588713",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588714-081259198",
    partNumber: "11588714",
    description: "BOLT - HVY HX A",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588715-835469453",
    partNumber: "11588715",
    description: "BOLT/SCREW-TMG",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588716-623860249",
    partNumber: "11588716",
    description: "BOLT - HVY HX A",
    supplierName: "TAEYANG AMERICA CORP",
    supplierAddress: "TROY,MI",
    supplierStreet: "",
    supplierCity: "TROY",
    supplierState: "MI",
    supplierZip: "480847144",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588722-097274534",
    partNumber: "11588722",
    description: "BOLT - HVY HX A",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588723-005354907",
    partNumber: "11588723",
    description: "BOLT/SCREW",
    supplierName: "KAMAX LP",
    supplierAddress: "LAPEER,MI",
    supplierStreet: "",
    supplierCity: "LAPEER",
    supplierState: "MI",
    supplierZip: "484463303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588724-097274534",
    partNumber: "11588724",
    description: "BOLT/SCREW-O/PM",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588726-097274534",
    partNumber: "11588726",
    description: "BOLT/SCREW-OIL",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588727-097274534",
    partNumber: "11588727",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588728-005354907",
    partNumber: "11588728",
    description: "BOLT/SCREW",
    supplierName: "KAMAX LP",
    supplierAddress: "LAPEER,MI",
    supplierStreet: "",
    supplierCity: "LAPEER",
    supplierState: "MI",
    supplierZip: "484463303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588733-835469453",
    partNumber: "11588733",
    description: "BOLT - HVY HX A",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588739-097274534",
    partNumber: "11588739",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588740-081259198",
    partNumber: "11588740",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588744-081259198",
    partNumber: "11588744",
    description: "BOLT-HVY HX ACO",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588749-081259198",
    partNumber: "11588749",
    description: "BOLT-HVY HX ACO",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588751-080681453",
    partNumber: "11588751",
    description: "BOLT-HVY HX ACO",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588757-080681453",
    partNumber: "11588757",
    description: "BOLT-ALTER",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588835-000240218",
    partNumber: "11588835",
    description: "NUT-HX FLG HD",
    supplierName: "SUMEEKO INDUSTRIES CO LTD",
    supplierAddress: "HARRISON TOWNSHIP,MI",
    supplierStreet: "",
    supplierCity: "HARRISON TOWNSHIP",
    supplierState: "MI",
    supplierZip: "480451307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11588998-835469453",
    partNumber: "11588998",
    description: "BOLT ASM-HEX HE",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589012-097274534",
    partNumber: "11589012",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589013-097274534",
    partNumber: "11589013",
    description: "BOLT ASM-HXHD W",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589015-097274534",
    partNumber: "11589015",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589031-097274534",
    partNumber: "11589031",
    description: "SCREW ASM - HX",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589164-835469453",
    partNumber: "11589164",
    description: "BOLT ASM - HX H",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589165-080681453",
    partNumber: "11589165",
    description: "BOLT ASM - HX H",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589181-099051716",
    partNumber: "11589181",
    description: "RETAINER - PLAS",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589273-080681453",
    partNumber: "11589273",
    description: "BOLT - HVY HX F",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589276-623860249",
    partNumber: "11589276",
    description: "BOLT - HVY HX F",
    supplierName: "TAEYANG AMERICA CORP",
    supplierAddress: "TROY,MI",
    supplierStreet: "",
    supplierCity: "TROY",
    supplierState: "MI",
    supplierZip: "480847144",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589284-080681453",
    partNumber: "11589284",
    description: "BOLT - HVY HX F",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589289-099051716",
    partNumber: "11589289",
    description: "PIN-PUSH",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589290-099051716",
    partNumber: "11589290",
    description: "RETAINER",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589293-099051716",
    partNumber: "11589293",
    description: "PUSH=PIN",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589295-099051716",
    partNumber: "11589295",
    description: "PIN - PUSH",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589297-099051716",
    partNumber: "11589297",
    description: "PIN - PUSH",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11589351-835469453",
    partNumber: "11589351",
    description: "BOLT - HVY HX F",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11601736-005346754",
    partNumber: "11601736",
    description: "BOLT/SCREW",
    supplierName: "VICO PRODUCTS CO",
    supplierAddress: "PLYMOUTH,MI",
    supplierStreet: "",
    supplierCity: "PLYMOUTH",
    supplierState: "MI",
    supplierZip: "481704300",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11601737-005346754",
    partNumber: "11601737",
    description: "BOLT/SCREW",
    supplierName: "VICO PRODUCTS CO",
    supplierAddress: "PLYMOUTH,MI",
    supplierStreet: "",
    supplierCity: "PLYMOUTH",
    supplierState: "MI",
    supplierZip: "481704300",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11601744-081259198",
    partNumber: "11601744",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11601786-005354907",
    partNumber: "11601786",
    description: "BOLT/SCREW-.",
    supplierName: "KAMAX LP",
    supplierAddress: "LAPEER,MI",
    supplierStreet: "",
    supplierCity: "LAPEER",
    supplierState: "MI",
    supplierZip: "484463303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11601862-081259198",
    partNumber: "11601862",
    description: "STUD-DOUBLE END",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11601939-143631294",
    partNumber: "11601939",
    description: "BOLT/SCREW-.",
    supplierName: "AGRATI - PARK FOREST LLC",
    supplierAddress: "PARK FOREST,IL",
    supplierStreet: "",
    supplierCity: "PARK FOREST",
    supplierState: "IL",
    supplierZip: "604663428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11601999-080681453",
    partNumber: "11601999",
    description: "STUD-DOUBLE END",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602000-623860249",
    partNumber: "11602000",
    description: "BOLT/SCREW-.",
    supplierName: "TAEYANG AMERICA CORP",
    supplierAddress: "TROY,MI",
    supplierStreet: "",
    supplierCity: "TROY",
    supplierState: "MI",
    supplierZip: "480847144",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602015-080681453",
    partNumber: "11602015",
    description: "BOLT/SCREW-.",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602057-081259198",
    partNumber: "11602057",
    description: "BOLT/SCREW-.",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602085-808532071",
    partNumber: "11602085",
    description: "NUT-U/SPRING",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602092-835469453",
    partNumber: "11602092",
    description: "BOLT/SCREW-.",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602126-941717472",
    partNumber: "11602126",
    description: "BOLT/SCREW-.",
    supplierName: "SEMBLEX CORP",
    supplierAddress: "ELMHURST,IL",
    supplierStreet: "",
    supplierCity: "ELMHURST",
    supplierState: "IL",
    supplierZip: "601261003",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602130-941717472",
    partNumber: "11602130",
    description: "BOLT/SCREW-.",
    supplierName: "SEMBLEX CORP",
    supplierAddress: "ELMHURST,IL",
    supplierStreet: "",
    supplierCity: "ELMHURST",
    supplierState: "IL",
    supplierZip: "601261003",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602140-835469453",
    partNumber: "11602140",
    description: "BOLT/SCREW-.",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602270-081259198",
    partNumber: "11602270",
    description: "BOLT/SCREW-.",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602326-243799157",
    partNumber: "11602326",
    description: "NUT-.",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11602374-941717472",
    partNumber: "11602374",
    description: "BOLT/SCREW-.",
    supplierName: "SEMBLEX CORP",
    supplierAddress: "ELMHURST,IL",
    supplierStreet: "",
    supplierCity: "ELMHURST",
    supplierState: "IL",
    supplierZip: "601261003",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602389-097274534",
    partNumber: "11602389",
    description: "NUT-.",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602468-057856247",
    partNumber: "11602468",
    description: "NUT-PUSH IN",
    supplierName: "TERMAX LLC",
    supplierAddress: "LAKE ZURICH,IL",
    supplierStreet: "",
    supplierCity: "LAKE ZURICH",
    supplierState: "IL",
    supplierZip: "600471525",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602490-099051716",
    partNumber: "11602490",
    description: "PIN-PUSH",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602523-080681453",
    partNumber: "11602523",
    description: "BOLT/SCREW-.",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602556-081259198",
    partNumber: "11602556",
    description: "BOLT/SCREW-.",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602557-081259198",
    partNumber: "11602557",
    description: "BOLT/SCREW-.",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602558-081259198",
    partNumber: "11602558",
    description: "WASHER-.",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602559-081259198",
    partNumber: "11602559",
    description: "WASHER-.",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602575-081259198",
    partNumber: "11602575",
    description: "BOLT/SCREW-.",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602587-000240218",
    partNumber: "11602587",
    description: "STUD-.",
    supplierName: "SUMEEKO INDUSTRIES CO LTD",
    supplierAddress: "HARRISON TOWNSHIP,MI",
    supplierStreet: "",
    supplierCity: "HARRISON TOWNSHIP",
    supplierState: "MI",
    supplierZip: "480451307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602601-249188079",
    partNumber: "11602601",
    description: "NUT PKG-SPL SEC",
    supplierName: "NIFAST CANADA CORP",
    supplierAddress: "INGERSOLL,ON",
    supplierStreet: "",
    supplierCity: "INGERSOLL",
    supplierState: "ON",
    supplierZip: "N5C 3K1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11602624-052959525",
    partNumber: "11602624",
    description: "RETAINER-.",
    supplierName: "EMHART TEKNOLOGIES LLC",
    supplierAddress: "MONTPELIER,IN",
    supplierStreet: "",
    supplierCity: "MONTPELIER",
    supplierState: "IN",
    supplierZip: "473599646",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602631-143631294",
    partNumber: "11602631",
    description: "BOLT/SCREW-.",
    supplierName: "AGRATI - PARK FOREST LLC",
    supplierAddress: "PARK FOREST,IL",
    supplierStreet: "",
    supplierCity: "PARK FOREST",
    supplierState: "IL",
    supplierZip: "604663428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602696-081259198",
    partNumber: "11602696",
    description: "BOLT/SCREW-.",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602731-000240218",
    partNumber: "11602731",
    description: "STUD-DOUBLE END",
    supplierName: "SUMEEKO INDUSTRIES CO LTD",
    supplierAddress: "HARRISON TOWNSHIP,MI",
    supplierStreet: "",
    supplierCity: "HARRISON TOWNSHIP",
    supplierState: "MI",
    supplierZip: "480451307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602755-941717472",
    partNumber: "11602755",
    description: "BOLT/SCREW-.",
    supplierName: "SEMBLEX CORP",
    supplierAddress: "ELMHURST,IL",
    supplierStreet: "",
    supplierCity: "ELMHURST",
    supplierState: "IL",
    supplierZip: "601261003",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602853-143631294",
    partNumber: "11602853",
    description: "BOLT/SCREW-.",
    supplierName: "AGRATI - PARK FOREST LLC",
    supplierAddress: "PARK FOREST,IL",
    supplierStreet: "",
    supplierCity: "PARK FOREST",
    supplierState: "IL",
    supplierZip: "604663428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602855-623860249",
    partNumber: "11602855",
    description: "BOLT/SCREW-.",
    supplierName: "TAEYANG AMERICA CORP",
    supplierAddress: "TROY,MI",
    supplierStreet: "",
    supplierCity: "TROY",
    supplierState: "MI",
    supplierZip: "480847144",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11602912-143631294",
    partNumber: "11602912",
    description: "BOLT/SCREW-.",
    supplierName: "AGRATI - PARK FOREST LLC",
    supplierAddress: "PARK FOREST,IL",
    supplierStreet: "",
    supplierCity: "PARK FOREST",
    supplierState: "IL",
    supplierZip: "604663428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603030-108171489",
    partNumber: "11603030",
    description: "RIVET-.",
    supplierName: "HENROB CORP",
    supplierAddress: "NEW HUDSON,MI",
    supplierStreet: "",
    supplierCity: "NEW HUDSON",
    supplierState: "MI",
    supplierZip: "481651203",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603070-081259198",
    partNumber: "11603070",
    description: "BOLT/SCREW-.",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603090-080681453",
    partNumber: "11603090",
    description: "BOLT/SCREW-.",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603091-081259198",
    partNumber: "11603091",
    description: "WASHER-.",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603092-005346754",
    partNumber: "11603092",
    description: "BOLT/SCREW-.",
    supplierName: "VICO PRODUCTS CO",
    supplierAddress: "PLYMOUTH,MI",
    supplierStreet: "",
    supplierCity: "PLYMOUTH",
    supplierState: "MI",
    supplierZip: "481704300",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603192-005349444",
    partNumber: "11603192",
    description: "NUT-U SPRING",
    supplierName: "WASHERS INC",
    supplierAddress: "LIVONIA,MI",
    supplierStreet: "",
    supplierCity: "LIVONIA",
    supplierState: "MI",
    supplierZip: "481501615",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603196-021836754",
    partNumber: "11603196",
    description: "NUT-STAMP",
    supplierName: "PRODUCTION SPRING LLC",
    supplierAddress: "TROY,MI",
    supplierStreet: "",
    supplierCity: "TROY",
    supplierState: "MI",
    supplierZip: "480834002",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603208-099051716",
    partNumber: "11603208",
    description: "RETAINER-.",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603232-097274534",
    partNumber: "11603232",
    description: "BOLT/SCREW-.",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603254-057856247",
    partNumber: "11603254",
    description: "RETAINER-.",
    supplierName: "TERMAX LLC",
    supplierAddress: "LAKE ZURICH,IL",
    supplierStreet: "",
    supplierCity: "LAKE ZURICH",
    supplierState: "IL",
    supplierZip: "600471525",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603271-143631294",
    partNumber: "11603271",
    description: "BOLT/SCREW-.",
    supplierName: "AGRATI - PARK FOREST LLC",
    supplierAddress: "PARK FOREST,IL",
    supplierStreet: "",
    supplierCity: "PARK FOREST",
    supplierState: "IL",
    supplierZip: "604663428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603283-080681453",
    partNumber: "11603283",
    description: "BOLT/SCREW-.",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603317-835469453",
    partNumber: "11603317",
    description: "BOLT/SCREW-.",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603391-057856247",
    partNumber: "11603391",
    description: "NUT-WING",
    supplierName: "TERMAX LLC",
    supplierAddress: "LAKE ZURICH,IL",
    supplierStreet: "",
    supplierCity: "LAKE ZURICH",
    supplierState: "IL",
    supplierZip: "600471525",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603411-005349444",
    partNumber: "11603411",
    description: "NUT-.",
    supplierName: "WASHERS INC",
    supplierAddress: "LIVONIA,MI",
    supplierStreet: "",
    supplierCity: "LIVONIA",
    supplierState: "MI",
    supplierZip: "481501615",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603432-080681453",
    partNumber: "11603432",
    description: "BOLT/SCREW-.",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603508-052959525",
    partNumber: "11603508",
    description: "NUT-STAMP",
    supplierName: "EMHART TEKNOLOGIES LLC",
    supplierAddress: "MONTPELIER,IN",
    supplierStreet: "",
    supplierCity: "MONTPELIER",
    supplierState: "IN",
    supplierZip: "473599646",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603677-081259198",
    partNumber: "11603677",
    description: "BOLT U-.",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603678-081259198",
    partNumber: "11603678",
    description: "BOLT U-.",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603681-081259198",
    partNumber: "11603681",
    description: "BOLT/SCREW-.",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603682-808532071",
    partNumber: "11603682",
    description: "NUT-PUSH IN",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603685-812888539",
    partNumber: "11603685",
    description: "BOLT/SCREW-.",
    supplierName: "KAMAX MEXICO S DE RL DE CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-11603851-057856247",
    partNumber: "11603851",
    description: "RETAINER-.",
    supplierName: "TERMAX LLC",
    supplierAddress: "LAKE ZURICH,IL",
    supplierStreet: "",
    supplierCity: "LAKE ZURICH",
    supplierState: "IL",
    supplierZip: "600471525",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11603859-005346754",
    partNumber: "11603859",
    description: "BOLT/SCREW-.",
    supplierName: "VICO PRODUCTS CO",
    supplierAddress: "PLYMOUTH,MI",
    supplierStreet: "",
    supplierCity: "PLYMOUTH",
    supplierState: "MI",
    supplierZip: "481704300",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609229-005354907",
    partNumber: "11609229",
    description: "BOLT/SCREW",
    supplierName: "KAMAX LP",
    supplierAddress: "LAPEER,MI",
    supplierStreet: "",
    supplierCity: "LAPEER",
    supplierState: "MI",
    supplierZip: "484463303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609271-623860249",
    partNumber: "11609271",
    description: "SCREW - FLAT HE",
    supplierName: "TAEYANG AMERICA CORP",
    supplierAddress: "TROY,MI",
    supplierStreet: "",
    supplierCity: "TROY",
    supplierState: "MI",
    supplierZip: "480847144",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609282-087709226",
    partNumber: "11609282",
    description: "NUT-TYPE A WA I",
    supplierName: "RAMCO SPECIALTIES INC",
    supplierAddress: "HUDSON,OH",
    supplierStreet: "",
    supplierCity: "HUDSON",
    supplierState: "OH",
    supplierZip: "442365040",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609283-006553036",
    partNumber: "11609283",
    description: "NUT - TYPE A WA",
    supplierName: "ALL STATE FASTENER CORP",
    supplierAddress: "ROSEVILLE,MI",
    supplierStreet: "",
    supplierCity: "ROSEVILLE",
    supplierState: "MI",
    supplierZip: "480661839",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609284-006553036",
    partNumber: "11609284",
    description: "NUT - TYPE A WA",
    supplierName: "ALL STATE FASTENER CORP",
    supplierAddress: "ROSEVILLE,MI",
    supplierStreet: "",
    supplierCity: "ROSEVILLE",
    supplierState: "MI",
    supplierZip: "480661839",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609411-243799157",
    partNumber: "11609411",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11609585-097274534",
    partNumber: "11609585",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609605-835469453",
    partNumber: "11609605",
    description: "BOLT - HVY HX F",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609652-005354907",
    partNumber: "11609652",
    description: "RETAINER",
    supplierName: "KAMAX LP",
    supplierAddress: "LAPEER,MI",
    supplierStreet: "",
    supplierCity: "LAPEER",
    supplierState: "MI",
    supplierZip: "484463303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609656-098063683",
    partNumber: "11609656",
    description: "NUT - HEX",
    supplierName: "A RAYMOND TINNERMAN MFG INC",
    supplierAddress: "BRUNSWICK,OH",
    supplierStreet: "",
    supplierCity: "BRUNSWICK",
    supplierState: "OH",
    supplierZip: "442122316",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609686-143631294",
    partNumber: "11609686",
    description: "SCR ASM - HX HD",
    supplierName: "AGRATI - PARK FOREST LLC",
    supplierAddress: "PARK FOREST,IL",
    supplierStreet: "",
    supplierCity: "PARK FOREST",
    supplierState: "IL",
    supplierZip: "604663428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609716-557463135",
    partNumber: "11609716",
    description: "NUT",
    supplierName: "SAMJIN AMERICA CORP",
    supplierAddress: "ROCHESTER HILLS,MI",
    supplierStreet: "",
    supplierCity: "ROCHESTER HILLS",
    supplierState: "MI",
    supplierZip: "483093808",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609732-081259198",
    partNumber: "11609732",
    description: "BOLT - PAN HD 6",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609746-243799157",
    partNumber: "11609746",
    description: "NUT - METRIC AL",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11609759-808532071",
    partNumber: "11609759",
    description: "NUT - TYPE DL W",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609826-243799157",
    partNumber: "11609826",
    description: "NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11609908-005354907",
    partNumber: "11609908",
    description: "STUD - BALL",
    supplierName: "KAMAX LP",
    supplierAddress: "LAPEER,MI",
    supplierStreet: "",
    supplierCity: "LAPEER",
    supplierState: "MI",
    supplierZip: "484463303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609939-097274534",
    partNumber: "11609939",
    description: "SCREW ASM - TAP",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609952-808532071",
    partNumber: "11609952",
    description: "NUT",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11609989-081259198",
    partNumber: "11609989",
    description: "SCR ASM - TAP H",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610068-941717472",
    partNumber: "11610068",
    description: "BOLT/SCREW",
    supplierName: "SEMBLEX CORP",
    supplierAddress: "ELMHURST,IL",
    supplierStreet: "",
    supplierCity: "ELMHURST",
    supplierState: "IL",
    supplierZip: "601261003",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610075-001527464",
    partNumber: "11610075",
    description: "RETNR=RING",
    supplierName: "ROTOR CLIP CO INC",
    supplierAddress: "SOMERSET,NJ",
    supplierStreet: "",
    supplierCity: "SOMERSET",
    supplierState: "NJ",
    supplierZip: "088734192",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610091-243799157",
    partNumber: "11610091",
    description: "NUT-METRIC HX F",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11610124-941717472",
    partNumber: "11610124",
    description: "BOLT/SCREW",
    supplierName: "SEMBLEX CORP",
    supplierAddress: "ELMHURST,IL",
    supplierStreet: "",
    supplierCity: "ELMHURST",
    supplierState: "IL",
    supplierZip: "601261003",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610128-243799157",
    partNumber: "11610128",
    description: "STUD-DBL ENDED",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11610164-081259198",
    partNumber: "11610164",
    description: "BOLT-ROUND HEAD",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610271-941717472",
    partNumber: "11610271",
    description: "SCREW - METRIC",
    supplierName: "SEMBLEX CORP",
    supplierAddress: "ELMHURST,IL",
    supplierStreet: "",
    supplierCity: "ELMHURST",
    supplierState: "IL",
    supplierZip: "601261003",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610404-081259198",
    partNumber: "11610404",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610524-808532071",
    partNumber: "11610524",
    description: "NUT",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610545-143631294",
    partNumber: "11610545",
    description: "BOLT/SCREW",
    supplierName: "AGRATI - PARK FOREST LLC",
    supplierAddress: "PARK FOREST,IL",
    supplierStreet: "",
    supplierCity: "PARK FOREST",
    supplierState: "IL",
    supplierZip: "604663428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610584-097274534",
    partNumber: "11610584",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610622-099051716",
    partNumber: "11610622",
    description: "RETAINER",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610633-081259198",
    partNumber: "11610633",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610665-808532071",
    partNumber: "11610665",
    description: "RETAINER",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610693-058961707",
    partNumber: "11610693",
    description: "RETAINER",
    supplierName: "A RAYMOND TINNERMAN AUTOMOTIVE INC",
    supplierAddress: "LOGANSPORT,IN",
    supplierStreet: "",
    supplierCity: "LOGANSPORT",
    supplierState: "IN",
    supplierZip: "469478269",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610753-835469453",
    partNumber: "11610753",
    description: "BOLT/SCREW",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610915-080681453",
    partNumber: "11610915",
    description: "BOLT/SCREW",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11610966-689286011",
    partNumber: "11610966",
    description: "BOLT/SCREW",
    supplierName: "YOUNGSIN METAL INDUSTRIAL CO LTD",
    supplierAddress: "PYEONGTAEK,41",
    supplierStreet: "",
    supplierCity: "PYEONGTAEK",
    supplierState: "41",
    supplierZip: "17960",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-11611007-058961707",
    partNumber: "11611007",
    description: "NUT-U/SPRING",
    supplierName: "A RAYMOND TINNERMAN AUTOMOTIVE INC",
    supplierAddress: "LOGANSPORT,IN",
    supplierStreet: "",
    supplierCity: "LOGANSPORT",
    supplierState: "IN",
    supplierZip: "469478269",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611035-080681453",
    partNumber: "11611035",
    description: "BOLT/SCREW",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611046-081259198",
    partNumber: "11611046",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611049-087709226",
    partNumber: "11611049",
    description: "NUT",
    supplierName: "RAMCO SPECIALTIES INC",
    supplierAddress: "HUDSON,OH",
    supplierStreet: "",
    supplierCity: "HUDSON",
    supplierState: "OH",
    supplierZip: "442365040",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611050-256873100",
    partNumber: "11611050",
    description: "NUT-PUSH IN",
    supplierName: "A RAYMOND TINNERMAN MFG HAMILTON IN",
    supplierAddress: "HAMILTON,ON",
    supplierStreet: "",
    supplierCity: "HAMILTON",
    supplierState: "ON",
    supplierZip: "L8H 5Z4",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11611072-059897736",
    partNumber: "11611072",
    description: "STUD",
    supplierName: "EMHART TEKNOLOGIES LLC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512521",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611102-081259198",
    partNumber: "11611102",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611223-941717472",
    partNumber: "11611223",
    description: "BOLT/SCREW",
    supplierName: "SEMBLEX CORP",
    supplierAddress: "ELMHURST,IL",
    supplierStreet: "",
    supplierCity: "ELMHURST",
    supplierState: "IL",
    supplierZip: "601261003",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611224-941717472",
    partNumber: "11611224",
    description: "BOLT/SCREW",
    supplierName: "SEMBLEX CORP",
    supplierAddress: "ELMHURST,IL",
    supplierStreet: "",
    supplierCity: "ELMHURST",
    supplierState: "IL",
    supplierZip: "601261003",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611249-005354907",
    partNumber: "11611249",
    description: "BOLT/SCREW",
    supplierName: "KAMAX LP",
    supplierAddress: "LAPEER,MI",
    supplierStreet: "",
    supplierCity: "LAPEER",
    supplierState: "MI",
    supplierZip: "484463303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611313-087709226",
    partNumber: "11611313",
    description: "NUT",
    supplierName: "RAMCO SPECIALTIES INC",
    supplierAddress: "HUDSON,OH",
    supplierStreet: "",
    supplierCity: "HUDSON",
    supplierState: "OH",
    supplierZip: "442365040",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611321-808532071",
    partNumber: "11611321",
    description: "NUT-U/SPRING",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663559",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611334-835469453",
    partNumber: "11611334",
    description: "BOLT/SCREW",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611384-081259198",
    partNumber: "11611384",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611535-143630924",
    partNumber: "11611535",
    description: "STUD-DOUBLE END",
    supplierName: "AGRATI MEDINA LLC",
    supplierAddress: "MEDINA,OH",
    supplierStreet: "",
    supplierCity: "MEDINA",
    supplierState: "OH",
    supplierZip: "442562453",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611677-099051716",
    partNumber: "11611677",
    description: "RETAINER-PLASTI",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611776-143631294",
    partNumber: "11611776",
    description: "BOLT/SCREW",
    supplierName: "AGRATI - PARK FOREST LLC",
    supplierAddress: "PARK FOREST,IL",
    supplierStreet: "",
    supplierCity: "PARK FOREST",
    supplierState: "IL",
    supplierZip: "604663428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611803-059897736",
    partNumber: "11611803",
    description: "STUD",
    supplierName: "EMHART TEKNOLOGIES LLC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512521",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611882-097274534",
    partNumber: "11611882",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611883-243799157",
    partNumber: "11611883",
    description: "BOLT/SCREW",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-11611884-097274534",
    partNumber: "11611884",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611959-835469453",
    partNumber: "11611959",
    description: "BOLT/SCREW",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611975-835469453",
    partNumber: "11611975",
    description: "BOLT/SCREW",
    supplierName: "RING SCREW LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429328",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11611978-081259198",
    partNumber: "11611978",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11612035-099051716",
    partNumber: "11612035",
    description: "PIN-PUSH",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11612112-005340591",
    partNumber: "11612112",
    description: "NUT-WELD/CLINCH",
    supplierName: "PENN AUTOMOTIVE INC",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "481742174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11612145-097274534",
    partNumber: "11612145",
    description: "BOLT/SCREW",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11612148-080681453",
    partNumber: "11612148",
    description: "BOLT/SCREW",
    supplierName: "SHANNON PRECISION FASTENER LLC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484420016",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11612278-087709226",
    partNumber: "11612278",
    description: "HD NUT",
    supplierName: "RAMCO SPECIALTIES INC",
    supplierAddress: "HUDSON,OH",
    supplierStreet: "",
    supplierCity: "HUDSON",
    supplierState: "OH",
    supplierZip: "442365040",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-11612295-557463135",
    partNumber: "11612295",
    description: "NUT",
    supplierName: "SAMJIN AMERICA CORP",
    supplierAddress: "ROCHESTER HILLS,MI",
    supplierStreet: "",
    supplierCity: "ROCHESTER HILLS",
    supplierState: "MI",
    supplierZip: "483093808",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12191503-000217372",
    partNumber: "12191503",
    description: "TOW=RECPTE",
    supplierName: "DELPHI-P - EL PASO",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065268",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12637349-809576882",
    partNumber: "12637349",
    description: "BELT-A/C CMPR",
    supplierName: "HUTCHINSON ANTIVIBRATION SYSTEMS IN",
    supplierAddress: "LIVINGSTON,TN",
    supplierStreet: "",
    supplierCity: "LIVINGSTON",
    supplierState: "TN",
    supplierZip: "385706042",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12657188-878516160",
    partNumber: "12657188",
    description: "SENSOR ASM-HTD",
    supplierName: "NGK SPARK PLUGS USA INC",
    supplierAddress: "SISSONVILLE,WV",
    supplierStreet: "",
    supplierCity: "SISSONVILLE",
    supplierState: "WV",
    supplierZip: "253209546",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12659516-964879613",
    partNumber: "12659516",
    description: "SENSOR ASM-HTD",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12664931-080203455",
    partNumber: "12664931",
    description: "TUBE ASM-PCV",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12665065-080203455",
    partNumber: "12665065",
    description: "TUBE ASM-PCV",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12666673-964879613",
    partNumber: "12666673",
    description: "SENSOR ASM-HTD",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12668435-964879613",
    partNumber: "12668435",
    description: "SENSOR ASM-HTD",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12669858-809576882",
    partNumber: "12669858",
    description: "BELT-W/PMP & GE",
    supplierName: "HUTCHINSON ANTIVIBRATION SYSTEMS IN",
    supplierAddress: "LIVINGSTON,TN",
    supplierStreet: "",
    supplierCity: "LIVINGSTON",
    supplierState: "TN",
    supplierZip: "385706042",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12669866-545275919",
    partNumber: "12669866",
    description: "BRACKET ASM-BEL",
    supplierName: "DONGGUAN MODERN METAL PRECISION DIE",
    supplierAddress: "DONGGUAN,44",
    supplierStreet: "",
    supplierCity: "DONGGUAN",
    supplierState: "44",
    supplierZip: "523868",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-12670253-878516160",
    partNumber: "12670253",
    description: "SENSOR ASM-HTD",
    supplierName: "NGK SPARK PLUGS USA INC",
    supplierAddress: "SISSONVILLE,WV",
    supplierStreet: "",
    supplierCity: "SISSONVILLE",
    supplierState: "WV",
    supplierZip: "253209546",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12670791-080203455",
    partNumber: "12670791",
    description: "TUBE ASM-PCV",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12670793-080203455",
    partNumber: "12670793",
    description: "TUBE ASM-PCV",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12679086-812822457",
    partNumber: "12679086",
    description: "BRACKET-CTLTC C",
    supplierName: "FAURECIA SISTEMAS AUTOMOTRICES",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-12683694-249196973",
    partNumber: "12683694",
    description: "ENGINE CSK",
    supplierName: "GM CANADA CO-ST CATHERINES ENGINE",
    supplierAddress: "SAINT CATHARINES,ON",
    supplierStreet: "",
    supplierCity: "SAINT CATHARINES",
    supplierState: "ON",
    supplierZip: "L2R 7B3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-12685254-812040194",
    partNumber: "12685254",
    description: "ENGINE CU7",
    supplierName: "GM DE MEXICO-RAMOS ARIZPE ENGINE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25000",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-12685255-830107152",
    partNumber: "12685255",
    description: "ENGINE CU8",
    supplierName: "GENERAL MOTORS LLC-SH MFG POWRTRAIN",
    supplierAddress: "SPRING HILL,TN",
    supplierStreet: "",
    supplierCity: "SPRING HILL",
    supplierState: "TN",
    supplierZip: "37174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12686784-340806311",
    partNumber: "12686784",
    description: "SENSOR ASM-NOX",
    supplierName: "CONTINENTAL AUTOMOTIVE GMBH",
    supplierAddress: "REGENSBURG,BY",
    supplierStreet: "",
    supplierCity: "REGENSBURG",
    supplierState: "BY",
    supplierZip: "93055",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-12686785-340806311",
    partNumber: "12686785",
    description: "SENSOR ASM-NOX",
    supplierName: "CONTINENTAL AUTOMOTIVE GMBH",
    supplierAddress: "REGENSBURG,BY",
    supplierStreet: "",
    supplierCity: "REGENSBURG",
    supplierState: "BY",
    supplierZip: "93055",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-12686786-340806311",
    partNumber: "12686786",
    description: "SENSOR ASM-NOX",
    supplierName: "CONTINENTAL AUTOMOTIVE GMBH",
    supplierAddress: "REGENSBURG,BY",
    supplierStreet: "",
    supplierCity: "REGENSBURG",
    supplierState: "BY",
    supplierZip: "93055",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-12687606-964879613",
    partNumber: "12687606",
    description: "SENSOR ASM-HTD",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12689541-079877724",
    partNumber: "12689541",
    description: "STARTER ASM-.",
    supplierName: "DENSO INTERNATIONAL AMERICA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481111848",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12690554-961973638",
    partNumber: "12690554",
    description: "SHIELD-EXH MANI",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12690817-961973638",
    partNumber: "12690817",
    description: "SHIELD-EXH MANI",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12691783-043352038",
    partNumber: "12691783",
    description: "TUBE ASM-OIL FI",
    supplierName: "GRAND TRAVERSE PLASTICS CORP",
    supplierAddress: "WILLIAMSBURG,MI",
    supplierStreet: "",
    supplierCity: "WILLIAMSBURG",
    supplierState: "MI",
    supplierZip: "496909741",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12692523-961973638",
    partNumber: "12692523",
    description: "SHIELD-STRTR HT",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12693981-043352038",
    partNumber: "12693981",
    description: "INSULATOR ASM-V",
    supplierName: "GRAND TRAVERSE PLASTICS CORP",
    supplierAddress: "WILLIAMSBURG,MI",
    supplierStreet: "",
    supplierCity: "WILLIAMSBURG",
    supplierState: "MI",
    supplierZip: "496909741",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12694625-183117860",
    partNumber: "12694625",
    description: "STARTER ASM-.",
    supplierName: "MITSUBISHI ELECTRIC AUTOMOTIVE AMER",
    supplierAddress: "MASON,OH",
    supplierStreet: "",
    supplierCity: "MASON",
    supplierState: "OH",
    supplierZip: "450408344",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12695338-812822457",
    partNumber: "12695338",
    description: "BRACKET-CTLTC C",
    supplierName: "FAURECIA SISTEMAS AUTOMOTRICES",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-12695949-830107152",
    partNumber: "12695949",
    description: "ENGINE ASM-GASO",
    supplierName: "GENERAL MOTORS LLC-SH MFG POWRTRAIN",
    supplierAddress: "SPRING HILL,TN",
    supplierStreet: "",
    supplierCity: "SPRING HILL",
    supplierState: "TN",
    supplierZip: "37174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12695951-812040194",
    partNumber: "12695951",
    description: "ENGINE ASM-GASO",
    supplierName: "GM DE MEXICO-RAMOS ARIZPE ENGINE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25000",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-12696096-428704068",
    partNumber: "12696096",
    description: "BRACKET-CTLTC C",
    supplierName: "STAMET SPA STAMPAGGI METALLICI",
    supplierAddress: "FELETTO,TO",
    supplierStreet: "",
    supplierCity: "FELETTO",
    supplierState: "TO",
    supplierZip: "10080",
    supplierCountry: "IT",
  },
  {
    _id: "parts-gm-12700122-201547189",
    partNumber: "12700122",
    description: "PULLEY ASM-BELT",
    supplierName: "SCHAEFFLER CANADA INC",
    supplierAddress: "STRATFORD,ON",
    supplierStreet: "",
    supplierCity: "STRATFORD",
    supplierState: "ON",
    supplierZip: "N5A 6T2",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-12700664-080203455",
    partNumber: "12700664",
    description: "TUBE ASM-PCV FR",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12703336-043352038",
    partNumber: "12703336",
    description: "INSULATOR ASM-V",
    supplierName: "GRAND TRAVERSE PLASTICS CORP",
    supplierAddress: "WILLIAMSBURG,MI",
    supplierStreet: "",
    supplierCity: "WILLIAMSBURG",
    supplierState: "MI",
    supplierZip: "496909741",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12703337-043352038",
    partNumber: "12703337",
    description: "INSULATOR ASM-V",
    supplierName: "GRAND TRAVERSE PLASTICS CORP",
    supplierAddress: "WILLIAMSBURG,MI",
    supplierStreet: "",
    supplierCity: "WILLIAMSBURG",
    supplierState: "MI",
    supplierZip: "496909741",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12703618-021093638",
    partNumber: "12703618",
    description: "BRACKET-NOX SEN",
    supplierName: "BOPP-BUSCH MANUFACTURING CO INC",
    supplierAddress: "AU GRES,MI",
    supplierStreet: "",
    supplierCity: "AU GRES",
    supplierState: "MI",
    supplierZip: "487039748",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12705320-116833270",
    partNumber: "12705320",
    description: "MODULE ASM-ENG",
    supplierName: "VITESCO TECHNOLOGIES USA LLC",
    supplierAddress: "SEGUIN,TX",
    supplierStreet: "",
    supplierCity: "SEGUIN",
    supplierState: "TX",
    supplierZip: "781557359",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12705559-080203455",
    partNumber: "12705559",
    description: "TUBE ASM-EVAP E",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12708011-812040194",
    partNumber: "12708011",
    description: "ENGINE MJF",
    supplierName: "GM DE MEXICO-RAMOS ARIZPE ENGINE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25000",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-12708520-080203455",
    partNumber: "12708520",
    description: "TUBE ASM-PCV FO",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12708521-080203455",
    partNumber: "12708521",
    description: "TUBE ASM-PCV FR",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12708531-080203455",
    partNumber: "12708531",
    description: "TUBE ASM-PCV FO",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12708532-080203455",
    partNumber: "12708532",
    description: "TUBE ASM-PCV FO",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12710193-116833270",
    partNumber: "12710193",
    description: "MODULE ASM-ENG",
    supplierName: "VITESCO TECHNOLOGIES USA LLC",
    supplierAddress: "SEGUIN,TX",
    supplierStreet: "",
    supplierCity: "SEGUIN",
    supplierState: "TX",
    supplierZip: "781557359",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12710307-080203455",
    partNumber: "12710307",
    description: "TUBE ASM-PCV FO",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12710308-080203455",
    partNumber: "12710308",
    description: "TUBE ASM-PCV FO",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12713091-830107152",
    partNumber: "12713091",
    description: "ENGINE ASM-GASO",
    supplierName: "GENERAL MOTORS LLC-SH MFG POWRTRAIN",
    supplierAddress: "SPRING HILL,TN",
    supplierStreet: "",
    supplierCity: "SPRING HILL",
    supplierState: "TN",
    supplierZip: "37174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-12713093-249196973",
    partNumber: "12713093",
    description: "ENGINE ASM-GASO",
    supplierName: "GM CANADA CO-ST CATHERINES ENGINE",
    supplierAddress: "SAINT CATHARINES,ON",
    supplierStreet: "",
    supplierCity: "SAINT CATHARINES",
    supplierState: "ON",
    supplierZip: "L2R 7B3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-12713278-043352038",
    partNumber: "12713278",
    description: "INSULATOR ASM-V",
    supplierName: "GRAND TRAVERSE PLASTICS CORP",
    supplierAddress: "WILLIAMSBURG,MI",
    supplierStreet: "",
    supplierCity: "WILLIAMSBURG",
    supplierState: "MI",
    supplierZip: "496909741",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13232779-249188079",
    partNumber: "13232779",
    description: "RETAINER-H/SHLD",
    supplierName: "NIFAST CANADA CORP",
    supplierAddress: "INGERSOLL,ON",
    supplierStreet: "",
    supplierCity: "INGERSOLL",
    supplierState: "ON",
    supplierZip: "N5C 3K1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13397387-831622258",
    partNumber: "13397387",
    description: "BRACKET-BODY WR",
    supplierName: "GNS HOLLAND INC",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "494249460",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13500936-099051716",
    partNumber: "13500936",
    description: "PLUG-BODY H/PLR",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13502353-815336284",
    partNumber: "13502353",
    description: "CAP ASM-RAD SUR",
    supplierName: "REUTTER FG S DE RL DE CV",
    supplierAddress: "CUAUTLA,MR",
    supplierStreet: "",
    supplierCity: "CUAUTLA",
    supplierState: "MR",
    supplierZip: "62757",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13502354-815336284",
    partNumber: "13502354",
    description: "CAP ASM-RAD FIL",
    supplierName: "REUTTER FG S DE RL DE CV",
    supplierAddress: "CUAUTLA,MR",
    supplierStreet: "",
    supplierCity: "CUAUTLA",
    supplierState: "MR",
    supplierZip: "62757",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13502787-256873100",
    partNumber: "13502787",
    description: "RETAINER-S/S SU",
    supplierName: "A RAYMOND TINNERMAN MFG HAMILTON IN",
    supplierAddress: "HAMILTON,ON",
    supplierStreet: "",
    supplierCity: "HAMILTON",
    supplierState: "ON",
    supplierZip: "L8H 5Z4",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13507539-000198002",
    partNumber: "13507539",
    description: "PUMP ASM-AUX WA",
    supplierName: "BUEHLER MOTOR DE MEXICO S DE RL DE",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367899",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13507543-000198002",
    partNumber: "13507543",
    description: "PUMP ASM-AUX WA",
    supplierName: "BUEHLER MOTOR DE MEXICO S DE RL DE",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367899",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13508283-080587458",
    partNumber: "13508283",
    description: "TRANSMITTER ASM",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "MARYVILLE,TN",
    supplierStreet: "",
    supplierCity: "MARYVILLE",
    supplierState: "TN",
    supplierZip: "378011754",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13510242-080587458",
    partNumber: "13510242",
    description: "TRANSMITTER ASM",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "MARYVILLE,TN",
    supplierStreet: "",
    supplierCity: "MARYVILLE",
    supplierState: "TN",
    supplierZip: "378011754",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13514522-812555407",
    partNumber: "13514522",
    description: "ROTOR-FRT BRK (",
    supplierName: "RASSINI FRENOS SA DE CV",
    supplierAddress: "SAN MARTIN TEXMELUCAN,PU",
    supplierStreet: "",
    supplierCity: "SAN MARTIN TEXMELUCAN",
    supplierState: "PU",
    supplierZip: "74129",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13516165-080462172",
    partNumber: "13516165",
    description: "SENSOR ASM",
    supplierName: "SCHRADER ELECTRONICS LTD",
    supplierAddress: "FORT WORTH,TX",
    supplierStreet: "",
    supplierCity: "FORT WORTH",
    supplierState: "TX",
    supplierZip: "761774001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13519102-079948011",
    partNumber: "13519102",
    description: "LAMP ASM-F/FLR",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13519424-079948011",
    partNumber: "13519424",
    description: "LAMP ASM-FRT S/",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13519530-695687626",
    partNumber: "13519530",
    description: "HUB ASM-RR WHL",
    supplierName: "ILJIN GLOBAL JECHEON 1PLANT",
    supplierAddress: "JECHEON,43",
    supplierStreet: "",
    supplierCity: "JECHEON",
    supplierState: "43",
    supplierZip: "27159",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-13519683-687786459",
    partNumber: "13519683",
    description: "ADJUSTER ASM-F/",
    supplierName: "WOOSHIN SAFETY SYSTEMS CO LTD",
    supplierAddress: "HWASEONG,41",
    supplierStreet: "",
    supplierCity: "HWASEONG",
    supplierState: "41",
    supplierZip: "18589",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-13522677-544908429",
    partNumber: "13522677",
    description: "RETAINER-S/S",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-13522678-544908429",
    partNumber: "13522678",
    description: "RETAINER-S/S",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-13525283-201189391",
    partNumber: "13525283",
    description: "SENSOR ASM-AIRB",
    supplierName: "VEONEER CANADA INC",
    supplierAddress: "MARKHAM,ON",
    supplierStreet: "",
    supplierCity: "MARKHAM",
    supplierState: "ON",
    supplierZip: "L3R 5C2",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13525285-201189391",
    partNumber: "13525285",
    description: "SENSOR ASM-AIRB",
    supplierName: "VEONEER CANADA INC",
    supplierAddress: "MARKHAM,ON",
    supplierStreet: "",
    supplierCity: "MARKHAM",
    supplierState: "ON",
    supplierZip: "L3R 5C2",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13525287-201189391",
    partNumber: "13525287",
    description: "SENSOR ASM-AIRB",
    supplierName: "VEONEER CANADA INC",
    supplierAddress: "MARKHAM,ON",
    supplierStreet: "",
    supplierCity: "MARKHAM",
    supplierState: "ON",
    supplierZip: "L3R 5C2",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13526856-000217372",
    partNumber: "13526856",
    description: "CONNECTOR ASM-S",
    supplierName: "DELPHI-P - EL PASO",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065268",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13529163-080587458",
    partNumber: "13529163",
    description: "ANTENNA ASM-LOW",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "MARYVILLE,TN",
    supplierStreet: "",
    supplierCity: "MARYVILLE",
    supplierState: "TN",
    supplierZip: "378011754",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13529378-367109928",
    partNumber: "13529378",
    description: "SENSOR ASM-SUN",
    supplierName: "LITTELFUSE LT UAB",
    supplierAddress: "KAUNAS,KU",
    supplierStreet: "",
    supplierCity: "KAUNAS",
    supplierState: "KU",
    supplierZip: "50131",
    supplierCountry: "LT",
  },
  {
    _id: "parts-gm-13529633-080587458",
    partNumber: "13529633",
    description: "TRANSMITTER ASM",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "MARYVILLE,TN",
    supplierStreet: "",
    supplierCity: "MARYVILLE",
    supplierState: "TN",
    supplierZip: "378011754",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13529751-080462172",
    partNumber: "13529751",
    description: "SENSOR ASM-TIRE",
    supplierName: "SCHRADER ELECTRONICS LTD",
    supplierAddress: "FORT WORTH,TX",
    supplierStreet: "",
    supplierCity: "FORT WORTH",
    supplierState: "TX",
    supplierZip: "761774001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13529869-018414292",
    partNumber: "13529869",
    description: "RECEPTACLE ASM-",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "WARREN,OH",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "OH",
    supplierZip: "444832352",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13529873-018414292",
    partNumber: "13529873",
    description: "RECEPTACLE ASM-",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "WARREN,OH",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "OH",
    supplierZip: "444832352",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13530679-687914069",
    partNumber: "13530679",
    description: "STRIKER-L/GATE",
    supplierName: "KDS CO LTD",
    supplierAddress: "GYEONGSAN,47",
    supplierStreet: "",
    supplierCity: "GYEONGSAN",
    supplierState: "47",
    supplierZip: "38461",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-13531275-000202290",
    partNumber: "13531275",
    description: "MODULE ASM-PASS",
    supplierName: "LG ELECTRONICS USA INC",
    supplierAddress: "CHANGWON,48",
    supplierStreet: "",
    supplierCity: "CHANGWON",
    supplierState: "48",
    supplierZip: "51554",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-13531325-816143630",
    partNumber: "13531325",
    description: "SWITCH ASM-ON/O",
    supplierName: "MARQUARDT MEXICO S DE RL DE CV",
    supplierAddress: "IRAPUATO,GJ",
    supplierStreet: "",
    supplierCity: "IRAPUATO",
    supplierState: "GJ",
    supplierZip: "36810",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13531447-627272024",
    partNumber: "13531447",
    description: "INSULATOR-FRT S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13531739-687786459",
    partNumber: "13531739",
    description: "ADJUSTER ASM-F/",
    supplierName: "WOOSHIN SAFETY SYSTEMS CO LTD",
    supplierAddress: "HWASEONG,41",
    supplierStreet: "",
    supplierCity: "HWASEONG",
    supplierState: "41",
    supplierZip: "18589",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-13532974-812555407",
    partNumber: "13532974",
    description: "ROTOR-RR BRK (C",
    supplierName: "RASSINI FRENOS SA DE CV",
    supplierAddress: "SAN MARTIN TEXMELUCAN,PU",
    supplierStreet: "",
    supplierCity: "SAN MARTIN TEXMELUCAN",
    supplierState: "PU",
    supplierZip: "74129",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13533166-080462172",
    partNumber: "13533166",
    description: "SENSOR ASM-TIRE",
    supplierName: "SCHRADER ELECTRONICS LTD",
    supplierAddress: "FORT WORTH,TX",
    supplierStreet: "",
    supplierCity: "FORT WORTH",
    supplierState: "TX",
    supplierZip: "761774001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13533361-000202279",
    partNumber: "13533361",
    description: "SWITCH ASM-WSW",
    supplierName: "BCS AUTOMOTIVE INTERFACE SOLUTIONS",
    supplierAddress: "PHARR,TX",
    supplierStreet: "",
    supplierCity: "PHARR",
    supplierState: "TX",
    supplierZip: "785777294",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13533606-244795589",
    partNumber: "13533606",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13533607-244795589",
    partNumber: "13533607",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13533617-244795589",
    partNumber: "13533617",
    description: "LATCH ASM-FRT S",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13533618-244795589",
    partNumber: "13533618",
    description: "LATCH ASM-FRT S",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13533690-244795589",
    partNumber: "13533690",
    description: "LATCH ASM-FRT S",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13534071-827836214",
    partNumber: "13534071",
    description: "MODULE ASM-TRLR",
    supplierName: "HELLA ELECTRONICS CORP",
    supplierAddress: "FLORA,IL",
    supplierStreet: "",
    supplierCity: "FLORA",
    supplierState: "IL",
    supplierZip: "628393440",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13534119-183117860",
    partNumber: "13534119",
    description: "GENERATOR ASM-.",
    supplierName: "MITSUBISHI ELECTRIC AUTOMOTIVE AMER",
    supplierAddress: "MASON,OH",
    supplierStreet: "",
    supplierCity: "MASON",
    supplierState: "OH",
    supplierZip: "450408344",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13534128-183117860",
    partNumber: "13534128",
    description: "GENERATOR ASM-.",
    supplierName: "MITSUBISHI ELECTRIC AUTOMOTIVE AMER",
    supplierAddress: "MASON,OH",
    supplierStreet: "",
    supplierCity: "MASON",
    supplierState: "OH",
    supplierZip: "450408344",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13534520-816143630",
    partNumber: "13534520",
    description: "SWITCH ASM-ON/O",
    supplierName: "MARQUARDT MEXICO S DE RL DE CV",
    supplierAddress: "IRAPUATO,GJ",
    supplierStreet: "",
    supplierCity: "IRAPUATO",
    supplierState: "GJ",
    supplierZip: "36810",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13534568-421176950",
    partNumber: "13534568",
    description: "MODULE ASM-WIRE",
    supplierName: "HITACHI-LG DATA STORAGE HUIZHOU LTD",
    supplierAddress: "HUIZHOU,44",
    supplierStreet: "",
    supplierCity: "HUIZHOU",
    supplierState: "44",
    supplierZip: "516000",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-13535097-813179439",
    partNumber: "13535097",
    description: "RECEIVER ASM-R/",
    supplierName: "HUF MEXICO S DE RL DE CV",
    supplierAddress: "CORONANGO,PU",
    supplierStreet: "",
    supplierCity: "CORONANGO",
    supplierState: "PU",
    supplierZip: "72680",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13535098-813179439",
    partNumber: "13535098",
    description: "RECEIVER ASM-R/",
    supplierName: "HUF MEXICO S DE RL DE CV",
    supplierAddress: "CORONANGO,PU",
    supplierStreet: "",
    supplierCity: "CORONANGO",
    supplierState: "PU",
    supplierZip: "72680",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13535195-080587458",
    partNumber: "13535195",
    description: "ANTENNA ASM-LOW",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "MARYVILLE,TN",
    supplierStreet: "",
    supplierCity: "MARYVILLE",
    supplierState: "TN",
    supplierZip: "378011754",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13535354-000202279",
    partNumber: "13535354",
    description: "SWITCH ASM-T/SI",
    supplierName: "BCS AUTOMOTIVE INTERFACE SOLUTIONS",
    supplierAddress: "PHARR,TX",
    supplierStreet: "",
    supplierCity: "PHARR",
    supplierState: "TX",
    supplierZip: "785777294",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13536045-695687626",
    partNumber: "13536045",
    description: "HUB ASM-FRT WHL",
    supplierName: "ILJIN GLOBAL JECHEON 1PLANT",
    supplierAddress: "JECHEON,43",
    supplierStreet: "",
    supplierCity: "JECHEON",
    supplierState: "43",
    supplierZip: "27159",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-13536046-695687626",
    partNumber: "13536046",
    description: "HUB ASM-FRT WHL",
    supplierName: "ILJIN GLOBAL JECHEON 1PLANT",
    supplierAddress: "JECHEON,43",
    supplierStreet: "",
    supplierCity: "JECHEON",
    supplierState: "43",
    supplierZip: "27159",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-13536121-695687626",
    partNumber: "13536121",
    description: "HUB ASM-RR WHL",
    supplierName: "ILJIN GLOBAL JECHEON 1PLANT",
    supplierAddress: "JECHEON,43",
    supplierStreet: "",
    supplierCity: "JECHEON",
    supplierState: "43",
    supplierZip: "27159",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-13536531-000202279",
    partNumber: "13536531",
    description: "SWITCH ASM-T/SI",
    supplierName: "BCS AUTOMOTIVE INTERFACE SOLUTIONS",
    supplierAddress: "PHARR,TX",
    supplierStreet: "",
    supplierCity: "PHARR",
    supplierState: "TX",
    supplierZip: "785777294",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13536536-000202279",
    partNumber: "13536536",
    description: "SWITCH ASM-WSW",
    supplierName: "BCS AUTOMOTIVE INTERFACE SOLUTIONS",
    supplierAddress: "PHARR,TX",
    supplierStreet: "",
    supplierCity: "PHARR",
    supplierState: "TX",
    supplierZip: "785777294",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13536552-183117860",
    partNumber: "13536552",
    description: "GENERATOR ASM-.",
    supplierName: "MITSUBISHI ELECTRIC AUTOMOTIVE AMER",
    supplierAddress: "MASON,OH",
    supplierStreet: "",
    supplierCity: "MASON",
    supplierState: "OH",
    supplierZip: "450408344",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13536722-827836214",
    partNumber: "13536722",
    description: "SENSOR ASM-BAT",
    supplierName: "HELLA ELECTRONICS CORP",
    supplierAddress: "FLORA,IL",
    supplierStreet: "",
    supplierCity: "FLORA",
    supplierState: "IL",
    supplierZip: "628393440",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13536918-790603224",
    partNumber: "13536918",
    description: "BRACKET ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367223",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13536919-790603224",
    partNumber: "13536919",
    description: "BRACKET ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367223",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13536920-790603224",
    partNumber: "13536920",
    description: "BRACKET ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367223",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13536921-790603224",
    partNumber: "13536921",
    description: "BRACKET ASM-RR",
    supplierName: "ADAC-STRATTEC DE MEXICO LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367223",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13536922-790603224",
    partNumber: "13536922",
    description: "BRACKET ASM-RR",
    supplierName: "ADAC-STRATTEC DE MEXICO LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367223",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13536995-080010898",
    partNumber: "13536995",
    description: "MIRROR ASM-I/S",
    supplierName: "GENTEX CORPORATION",
    supplierAddress: "ZEELAND,MI",
    supplierStreet: "",
    supplierCity: "ZEELAND",
    supplierState: "MI",
    supplierZip: "494649717",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13537007-244795589",
    partNumber: "13537007",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13537049-421263696",
    partNumber: "13537049",
    description: "LATCH ASM-L/GAT",
    supplierName: "VAST CHINA CO LTD",
    supplierAddress: "TAICANG,32",
    supplierStreet: "",
    supplierCity: "TAICANG",
    supplierState: "32",
    supplierZip: "215400",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-13537053-039045703",
    partNumber: "13537053",
    description: "MODULE ASM-F/PM",
    supplierName: "CONTINENTAL AUTOMOTIVE SYSTEMS INC",
    supplierAddress: "SEGUIN,TX",
    supplierStreet: "",
    supplierCity: "SEGUIN",
    supplierState: "TX",
    supplierZip: "781557359",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13537143-421176950",
    partNumber: "13537143",
    description: "MODULE ASM-WIRE",
    supplierName: "HITACHI-LG DATA STORAGE HUIZHOU LTD",
    supplierAddress: "HUIZHOU,44",
    supplierStreet: "",
    supplierCity: "HUIZHOU",
    supplierState: "44",
    supplierZip: "516000",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-13537290-080587458",
    partNumber: "13537290",
    description: "MODULE ASM-BODY",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "MARYVILLE,TN",
    supplierStreet: "",
    supplierCity: "MARYVILLE",
    supplierState: "TN",
    supplierZip: "378011754",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13537956-813179439",
    partNumber: "13537956",
    description: "TRANSMITTER ASM",
    supplierName: "HUF MEXICO S DE RL DE CV",
    supplierAddress: "CORONANGO,PU",
    supplierStreet: "",
    supplierCity: "CORONANGO",
    supplierState: "PU",
    supplierZip: "72680",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13538607-201189391",
    partNumber: "13538607",
    description: "MODULE ASM-AIRB",
    supplierName: "VEONEER CANADA INC",
    supplierAddress: "MARKHAM,ON",
    supplierStreet: "",
    supplierCity: "MARKHAM",
    supplierState: "ON",
    supplierZip: "L3R 5C2",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13538611-201189391",
    partNumber: "13538611",
    description: "MODULE ASM-AIRB",
    supplierName: "VEONEER CANADA INC",
    supplierAddress: "MARKHAM,ON",
    supplierStreet: "",
    supplierCity: "MARKHAM",
    supplierState: "ON",
    supplierZip: "L3R 5C2",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13538714-077496289",
    partNumber: "13538714",
    description: "PROTECTOR ASM-F",
    supplierName: "ADDEV WALCO INC",
    supplierAddress: "GLENSHAW,PA",
    supplierStreet: "",
    supplierCity: "GLENSHAW",
    supplierState: "PA",
    supplierZip: "151161700",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13538759-080462172",
    partNumber: "13538759",
    description: "SENSOR ASM-TIRE",
    supplierName: "SCHRADER ELECTRONICS LTD",
    supplierAddress: "FORT WORTH,TX",
    supplierStreet: "",
    supplierCity: "FORT WORTH",
    supplierState: "TX",
    supplierZip: "761774001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13538864-813179439",
    partNumber: "13538864",
    description: "TRANSMITTER ASM",
    supplierName: "HUF MEXICO S DE RL DE CV",
    supplierAddress: "CORONANGO,PU",
    supplierStreet: "",
    supplierCity: "CORONANGO",
    supplierState: "PU",
    supplierZip: "72680",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13539317-812012912",
    partNumber: "13539317",
    description: "CONNECTOR ASM-S",
    supplierName: "ARNESES ELECTRICOS AUTOMOTRICES",
    supplierAddress: "SANTIAGO DE QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "SANTIAGO DE QUERETARO",
    supplierState: "QA",
    supplierZip: "76100",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13539318-000202279",
    partNumber: "13539318",
    description: "SWITCH ASM-T/SI",
    supplierName: "BCS AUTOMOTIVE INTERFACE SOLUTIONS",
    supplierAddress: "PHARR,TX",
    supplierStreet: "",
    supplierCity: "PHARR",
    supplierState: "TX",
    supplierZip: "785777294",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13539319-000202279",
    partNumber: "13539319",
    description: "SWITCH ASM-T/SI",
    supplierName: "BCS AUTOMOTIVE INTERFACE SOLUTIONS",
    supplierAddress: "PHARR,TX",
    supplierStreet: "",
    supplierCity: "PHARR",
    supplierState: "TX",
    supplierZip: "785777294",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13539392-244795589",
    partNumber: "13539392",
    description: "LATCH ASM-FRT S",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13539393-244795589",
    partNumber: "13539393",
    description: "LATCH ASM-FRT S",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13539396-244795589",
    partNumber: "13539396",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13539397-244795589",
    partNumber: "13539397",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13539406-244795589",
    partNumber: "13539406",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13539407-244795589",
    partNumber: "13539407",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13539587-826223968",
    partNumber: "13539587",
    description: "MODULE ASM-STRG",
    supplierName: "STRATTEC SECURITY CORP",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367723",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13539610-790603224",
    partNumber: "13539610",
    description: "BRACKET ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367223",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13539611-790603224",
    partNumber: "13539611",
    description: "BRACKET ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367223",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13539612-790603224",
    partNumber: "13539612",
    description: "BRACKET ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367223",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13539729-183117860",
    partNumber: "13539729",
    description: "GENERATOR ASM-.",
    supplierName: "MITSUBISHI ELECTRIC AUTOMOTIVE AMER",
    supplierAddress: "MASON,OH",
    supplierStreet: "",
    supplierCity: "MASON",
    supplierState: "OH",
    supplierZip: "450408344",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13539795-826223968",
    partNumber: "13539795",
    description: "MODULE ASM-STRG",
    supplierName: "STRATTEC SECURITY CORP",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367723",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13540312-080010898",
    partNumber: "13540312",
    description: "MIRROR ASM-I/S",
    supplierName: "GENTEX CORPORATION",
    supplierAddress: "ZEELAND,MI",
    supplierStreet: "",
    supplierCity: "ZEELAND",
    supplierState: "MI",
    supplierZip: "494649717",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13540313-080010898",
    partNumber: "13540313",
    description: "MIRROR ASM-I/S",
    supplierName: "GENTEX CORPORATION",
    supplierAddress: "ZEELAND,MI",
    supplierStreet: "",
    supplierCity: "ZEELAND",
    supplierState: "MI",
    supplierZip: "494649717",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13540542-827836214",
    partNumber: "13540542",
    description: "MODULE ASM-TRLR",
    supplierName: "HELLA ELECTRONICS CORP",
    supplierAddress: "FLORA,IL",
    supplierStreet: "",
    supplierCity: "FLORA",
    supplierState: "IL",
    supplierZip: "628393440",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13540971-244795589",
    partNumber: "13540971",
    description: "LATCH ASM-FRT S",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13540972-244795589",
    partNumber: "13540972",
    description: "LATCH ASM-FRT S",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13540973-244795589",
    partNumber: "13540973",
    description: "LATCH ASM-FRT S",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13540974-244795589",
    partNumber: "13540974",
    description: "LATCH ASM-FRT S",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13540975-244795589",
    partNumber: "13540975",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13540976-244795589",
    partNumber: "13540976",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13540977-244795589",
    partNumber: "13540977",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13540978-244795589",
    partNumber: "13540978",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13540979-244795589",
    partNumber: "13540979",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13540980-244795589",
    partNumber: "13540980",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13541137-826223968",
    partNumber: "13541137",
    description: "LATCH ASM-L/GAT",
    supplierName: "STRATTEC SECURITY CORP",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367723",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13541216-826223968",
    partNumber: "13541216",
    description: "MODULE ASM-STRG",
    supplierName: "STRATTEC SECURITY CORP",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367723",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13541383-826223968",
    partNumber: "13541383",
    description: "MODULE ASM-STRG",
    supplierName: "STRATTEC SECURITY CORP",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367723",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13541559-813179439",
    partNumber: "13541559",
    description: "TRANSMITTER ASM",
    supplierName: "HUF MEXICO S DE RL DE CV",
    supplierAddress: "CORONANGO,PU",
    supplierStreet: "",
    supplierCity: "CORONANGO",
    supplierState: "PU",
    supplierZip: "72680",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13541561-813179439",
    partNumber: "13541561",
    description: "TRANSMITTER ASM",
    supplierName: "HUF MEXICO S DE RL DE CV",
    supplierAddress: "CORONANGO,PU",
    supplierStreet: "",
    supplierCity: "CORONANGO",
    supplierState: "PU",
    supplierZip: "72680",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13541563-813179439",
    partNumber: "13541563",
    description: "TRANSMITTER ASM",
    supplierName: "HUF MEXICO S DE RL DE CV",
    supplierAddress: "CORONANGO,PU",
    supplierStreet: "",
    supplierCity: "CORONANGO",
    supplierState: "PU",
    supplierZip: "72680",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13541565-813179439",
    partNumber: "13541565",
    description: "TRANSMITTER ASM",
    supplierName: "HUF MEXICO S DE RL DE CV",
    supplierAddress: "CORONANGO,PU",
    supplierStreet: "",
    supplierCity: "CORONANGO",
    supplierState: "PU",
    supplierZip: "72680",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13541567-813179439",
    partNumber: "13541567",
    description: "TRANSMITTER ASM",
    supplierName: "HUF MEXICO S DE RL DE CV",
    supplierAddress: "CORONANGO,PU",
    supplierStreet: "",
    supplierCity: "CORONANGO",
    supplierState: "PU",
    supplierZip: "72680",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13541569-813179439",
    partNumber: "13541569",
    description: "TRANSMITTER ASM",
    supplierName: "HUF MEXICO S DE RL DE CV",
    supplierAddress: "CORONANGO,PU",
    supplierStreet: "",
    supplierCity: "CORONANGO",
    supplierState: "PU",
    supplierZip: "72680",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13541571-813179439",
    partNumber: "13541571",
    description: "TRANSMITTER ASM",
    supplierName: "HUF MEXICO S DE RL DE CV",
    supplierAddress: "CORONANGO,PU",
    supplierStreet: "",
    supplierCity: "CORONANGO",
    supplierState: "PU",
    supplierZip: "72680",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13541716-244795589",
    partNumber: "13541716",
    description: "LATCH ASM-FRT S",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13541793-080587458",
    partNumber: "13541793",
    description: "MODULE ASM-EXTR",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "MARYVILLE,TN",
    supplierStreet: "",
    supplierCity: "MARYVILLE",
    supplierState: "TN",
    supplierZip: "378011754",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13541806-080587458",
    partNumber: "13541806",
    description: "MODULE ASM-BODY",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "MARYVILLE,TN",
    supplierStreet: "",
    supplierCity: "MARYVILLE",
    supplierState: "TN",
    supplierZip: "378011754",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13541809-080587458",
    partNumber: "13541809",
    description: "MODULE ASM-BODY",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "MARYVILLE,TN",
    supplierStreet: "",
    supplierCity: "MARYVILLE",
    supplierState: "TN",
    supplierZip: "378011754",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13542112-812555407",
    partNumber: "13542112",
    description: "ROTOR-FRT BRK (",
    supplierName: "RASSINI FRENOS SA DE CV",
    supplierAddress: "SAN MARTIN TEXMELUCAN,PU",
    supplierStreet: "",
    supplierCity: "SAN MARTIN TEXMELUCAN",
    supplierState: "PU",
    supplierZip: "74129",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13542117-812555407",
    partNumber: "13542117",
    description: "ROTOR-RR BRK (C",
    supplierName: "RASSINI FRENOS SA DE CV",
    supplierAddress: "SAN MARTIN TEXMELUCAN,PU",
    supplierStreet: "",
    supplierCity: "SAN MARTIN TEXMELUCAN",
    supplierState: "PU",
    supplierZip: "74129",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-13542151-080587458",
    partNumber: "13542151",
    description: "MODULE ASM-BODY",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "MARYVILLE,TN",
    supplierStreet: "",
    supplierCity: "MARYVILLE",
    supplierState: "TN",
    supplierZip: "378011754",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13542204-000195578",
    partNumber: "13542204",
    description: "CONVERTER ASM-M",
    supplierName: "CONTINENTAL AUTOMOTIVE NOGALES SA",
    supplierAddress: "NOGALES,AZ",
    supplierStreet: "",
    supplierCity: "NOGALES",
    supplierState: "AZ",
    supplierZip: "856214505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13542211-133272877",
    partNumber: "13542211",
    description: "MODULE ASM-SERI",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13542413-790603224",
    partNumber: "13542413",
    description: "BRACKET ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367223",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13542414-790603224",
    partNumber: "13542414",
    description: "BRACKET ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367223",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13542415-790603224",
    partNumber: "13542415",
    description: "BRACKET ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367223",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13542464-080587458",
    partNumber: "13542464",
    description: "MODULE ASM-EXTR",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "MARYVILLE,TN",
    supplierStreet: "",
    supplierCity: "MARYVILLE",
    supplierState: "TN",
    supplierZip: "378011754",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13542523-080462172",
    partNumber: "13542523",
    description: "SENSOR ASM-TIRE",
    supplierName: "SCHRADER ELECTRONICS LTD",
    supplierAddress: "FORT WORTH,TX",
    supplierStreet: "",
    supplierCity: "FORT WORTH",
    supplierState: "TX",
    supplierZip: "761774001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13542820-827836214",
    partNumber: "13542820",
    description: "SENSOR ASM-BAT",
    supplierName: "HELLA ELECTRONICS CORP",
    supplierAddress: "FLORA,IL",
    supplierStreet: "",
    supplierCity: "FLORA",
    supplierState: "IL",
    supplierZip: "628393440",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13542847-827836214",
    partNumber: "13542847",
    description: "MODULE ASM-TRLR",
    supplierName: "HELLA ELECTRONICS CORP",
    supplierAddress: "FLORA,IL",
    supplierStreet: "",
    supplierCity: "FLORA",
    supplierState: "IL",
    supplierZip: "628393440",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13542851-827836214",
    partNumber: "13542851",
    description: "MODULE ASM-TRLR",
    supplierName: "HELLA ELECTRONICS CORP",
    supplierAddress: "FLORA,IL",
    supplierStreet: "",
    supplierCity: "FLORA",
    supplierState: "IL",
    supplierZip: "628393440",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13542862-244795589",
    partNumber: "13542862",
    description: "LATCH ASM-FRT S",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542863-244795589",
    partNumber: "13542863",
    description: "LATCH ASM-FRT S",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542864-244795589",
    partNumber: "13542864",
    description: "LATCH ASM-FRT S",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542865-244795589",
    partNumber: "13542865",
    description: "LATCH ASM-FRT S",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542866-244795589",
    partNumber: "13542866",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542867-244795589",
    partNumber: "13542867",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542868-244795589",
    partNumber: "13542868",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542869-244795589",
    partNumber: "13542869",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542870-244795589",
    partNumber: "13542870",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542871-244795589",
    partNumber: "13542871",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542872-244795589",
    partNumber: "13542872",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542873-244795589",
    partNumber: "13542873",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542874-244795589",
    partNumber: "13542874",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542875-244795589",
    partNumber: "13542875",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542876-244795589",
    partNumber: "13542876",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542877-244795589",
    partNumber: "13542877",
    description: "LATCH ASM-RR S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "NEWMARKET,ON",
    supplierStreet: "",
    supplierCity: "NEWMARKET",
    supplierState: "ON",
    supplierZip: "L3Y 4X7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13542878-133272877",
    partNumber: "13542878",
    description: "MODULE ASM-SERI",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13543124-133272877",
    partNumber: "13543124",
    description: "MODULE ASM-SERI",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13544248-826223968",
    partNumber: "13544248",
    description: "MODULE ASM-STRG",
    supplierName: "STRATTEC SECURITY CORP",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367723",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13578681-839434800",
    partNumber: "13578681",
    description: "INSULATOR-RR S/",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13579477-688732884",
    partNumber: "13579477",
    description: "ASHTRAY PKG-F/F",
    supplierName: "SH-INT CO LTD",
    supplierAddress: "GIMHAE,48",
    supplierStreet: "",
    supplierCity: "GIMHAE",
    supplierState: "48",
    supplierZip: "50870",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-13580787-080587458",
    partNumber: "13580787",
    description: "ANTENNA PEPS",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "MARYVILLE,TN",
    supplierStreet: "",
    supplierCity: "MARYVILLE",
    supplierState: "TN",
    supplierZip: "378011754",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13581557-119076131",
    partNumber: "13581557",
    description: "NUT-TIRE PRESS",
    supplierName: "SCHRADER-BRIDGEPORT INTL INC",
    supplierAddress: "ALTAVISTA,VA",
    supplierStreet: "",
    supplierCity: "ALTAVISTA",
    supplierState: "VA",
    supplierZip: "245171020",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13581570-783350853",
    partNumber: "13581570",
    description: "HINGE ASM-FR S/",
    supplierName: "TEKNIA NASHVILLE LLC",
    supplierAddress: "MOUNT JULIET,TN",
    supplierStreet: "",
    supplierCity: "MOUNT JULIET",
    supplierState: "TN",
    supplierZip: "371223600",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13581571-783350853",
    partNumber: "13581571",
    description: "HINGE ASM-FR S/",
    supplierName: "TEKNIA NASHVILLE LLC",
    supplierAddress: "MOUNT JULIET,TN",
    supplierStreet: "",
    supplierCity: "MOUNT JULIET",
    supplierState: "TN",
    supplierZip: "371223600",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13583411-662073477",
    partNumber: "13583411",
    description: "SENSOR ASM-AMB",
    supplierName: "EMERSON ELECTRIC LTD",
    supplierAddress: "HONG KONG,HK",
    supplierStreet: "",
    supplierCity: "HONG KONG",
    supplierState: "HK",
    supplierZip: "0",
    supplierCountry: "HK",
  },
  {
    _id: "parts-gm-13584893-080010898",
    partNumber: "13584893",
    description: "MIRROR ASM-I/S",
    supplierName: "GENTEX CORPORATION",
    supplierAddress: "ZEELAND,MI",
    supplierStreet: "",
    supplierCity: "ZEELAND",
    supplierState: "MI",
    supplierZip: "494649717",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13585948-883459596",
    partNumber: "13585948",
    description: "MIRROR ASM-I/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "494249263",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13586495-020837233",
    partNumber: "13586495",
    description: "SPACER",
    supplierName: "DAJACO INDUSTRIES INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512478",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13588034-080210967",
    partNumber: "13588034",
    description: "VALVE ASM-BODY",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "WESTMINSTER,MA",
    supplierStreet: "",
    supplierCity: "WESTMINSTER",
    supplierState: "MA",
    supplierZip: "014731208",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13591436-687914069",
    partNumber: "13591436",
    description: "STRIKER ASM-FRT",
    supplierName: "KDS CO LTD",
    supplierAddress: "GYEONGSAN,47",
    supplierStreet: "",
    supplierCity: "GYEONGSAN",
    supplierState: "47",
    supplierZip: "38461",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-13592451-080587458",
    partNumber: "13592451",
    description: "ANTENNA ASM-LOW",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "MARYVILLE,TN",
    supplierStreet: "",
    supplierCity: "MARYVILLE",
    supplierState: "TN",
    supplierZip: "378011754",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13592452-080587458",
    partNumber: "13592452",
    description: "ANTENNA ASM-LOW",
    supplierName: "DENSO MANUFACTURING TENNESSEE INC",
    supplierAddress: "MARYVILLE,TN",
    supplierStreet: "",
    supplierCity: "MARYVILLE",
    supplierState: "TN",
    supplierZip: "378011754",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13592460-839434800",
    partNumber: "13592460",
    description: "BRACKET ASM-RR",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13592461-839434800",
    partNumber: "13592461",
    description: "BRACKET ASM-RR",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13592465-839434800",
    partNumber: "13592465",
    description: "BRACKET ASM-FRT",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13592466-839434800",
    partNumber: "13592466",
    description: "BRACKET ASM-FRT",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13592467-839434800",
    partNumber: "13592467",
    description: "BRACKET ASM-FRT",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13594445-604974600",
    partNumber: "13594445",
    description: "CLIP-WRG HARN",
    supplierName: "HELLERMANNTYTON CORP",
    supplierAddress: "ROMEOVILLE,IL",
    supplierStreet: "",
    supplierCity: "ROMEOVILLE",
    supplierState: "IL",
    supplierZip: "604463500",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13595368-080010898",
    partNumber: "13595368",
    description: "MIRROR ASM-I/S",
    supplierName: "GENTEX CORPORATION",
    supplierAddress: "ZEELAND,MI",
    supplierStreet: "",
    supplierCity: "ZEELAND",
    supplierState: "MI",
    supplierZip: "494649717",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13597327-006016810",
    partNumber: "13597327",
    description: "LABEL-VIN",
    supplierName: "GRAND RAPIDS LABEL CO",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "49505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-13597925-243459851",
    partNumber: "13597925",
    description: "PLATE-HDLNG T/P",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-13599209-815336284",
    partNumber: "13599209",
    description: "CAP-DRV MOT BAT",
    supplierName: "REUTTER FG S DE RL DE CV",
    supplierAddress: "CUAUTLA,MR",
    supplierStreet: "",
    supplierCity: "CUAUTLA",
    supplierState: "MR",
    supplierZip: "62757",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-15017238-243799157",
    partNumber: "15017238",
    description: "S/GR=NUT",
    supplierName: "INFASCO NUT LP",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1P8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-15017725-052959525",
    partNumber: "15017725",
    description: "NUT-F/SEAT",
    supplierName: "EMHART TEKNOLOGIES LLC",
    supplierAddress: "MONTPELIER,IN",
    supplierStreet: "",
    supplierCity: "MONTPELIER",
    supplierState: "IN",
    supplierZip: "473599646",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15032594-000240218",
    partNumber: "15032594",
    description: "NUT",
    supplierName: "SUMEEKO INDUSTRIES CO LTD",
    supplierAddress: "HARRISON TOWNSHIP,MI",
    supplierStreet: "",
    supplierCity: "HARRISON TOWNSHIP",
    supplierState: "MI",
    supplierZip: "480451307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15035747-812536662",
    partNumber: "15035747",
    description: "E/PIPE=SEAL",
    supplierName: "NORTH AMERICAN PRODUCTION SHARING",
    supplierAddress: "TIJUANA,BJ",
    supplierStreet: "",
    supplierCity: "TIJUANA",
    supplierState: "BJ",
    supplierZip: "22660",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-15038811-812967438",
    partNumber: "15038811",
    description: "PROTV=CVR",
    supplierName: "GRUPO TEXTIL CORPORACION DE",
    supplierAddress: "PUEBLA,PU",
    supplierStreet: "",
    supplierCity: "PUEBLA",
    supplierState: "PU",
    supplierZip: "72070",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-15061343-005509385",
    partNumber: "15061343",
    description: "MIR=LABEL",
    supplierName: "WHITLAM GROUP INC",
    supplierAddress: "CENTER LINE,MI",
    supplierStreet: "",
    supplierCity: "CENTER LINE",
    supplierState: "MI",
    supplierZip: "480151059",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15061946-051366094",
    partNumber: "15061946",
    description: "RETAINER",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "NEW LEXINGTON,OH",
    supplierStreet: "",
    supplierCity: "NEW LEXINGTON",
    supplierState: "OH",
    supplierZip: "437649617",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15077362-812536662",
    partNumber: "15077362",
    description: "E/PIPE=SEAL",
    supplierName: "NORTH AMERICAN PRODUCTION SHARING",
    supplierAddress: "TIJUANA,BJ",
    supplierStreet: "",
    supplierCity: "TIJUANA",
    supplierState: "BJ",
    supplierZip: "22660",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-15092802-050614114",
    partNumber: "15092802",
    description: "HNGR=INSULTR",
    supplierName: "AIRBOSS FLEXIBLE PRODUCTS CO",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15112320-057852626",
    partNumber: "15112320",
    description: "L/PRTS=CNTR",
    supplierName: "VERITIV OPERATING CO",
    supplierAddress: "AURORA,IL",
    supplierStreet: "",
    supplierCity: "AURORA",
    supplierState: "IL",
    supplierZip: "605024717",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15124415-810248898",
    partNumber: "15124415",
    description: "CAP-WSW ARM FIN",
    supplierName: "ROBERT BOSCH S DE RL DE CV",
    supplierAddress: "TOLUCA,EM",
    supplierStreet: "",
    supplierCity: "TOLUCA",
    supplierState: "EM",
    supplierZip: "50071",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-15133409-205631570",
    partNumber: "15133409",
    description: "SEN=BRKT",
    supplierName: "REA INTERNATIONAL INC",
    supplierAddress: "VAUGHAN,ON",
    supplierStreet: "",
    supplierCity: "VAUGHAN",
    supplierState: "ON",
    supplierZip: "L4L 9C7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-15157936-049816044",
    partNumber: "15157936",
    description: "CLIP-R/AXL VENT",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "FRANKFORT,IL",
    supplierStreet: "",
    supplierCity: "FRANKFORT",
    supplierState: "IL",
    supplierZip: "604236017",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15184051-800221918",
    partNumber: "15184051",
    description: "PLUG-S/D OPG FR",
    supplierName: "PROTECTIVE INDUSTRIES INC",
    supplierAddress: "BUFFALO,NY",
    supplierStreet: "",
    supplierCity: "BUFFALO",
    supplierState: "NY",
    supplierZip: "142071910",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15211490-004913364",
    partNumber: "15211490",
    description: "ATTACHMENT KIT",
    supplierName: "GOODWILL INDUSTRIES OF GRTR DETROIT",
    supplierAddress: "DETROIT,MI",
    supplierStreet: "",
    supplierCity: "DETROIT",
    supplierState: "MI",
    supplierZip: "482082962",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15219425-189658198",
    partNumber: "15219425",
    description: "BARRIER-RR S/D",
    supplierName: "SIKA CORP",
    supplierAddress: "GRANDVIEW,MO",
    supplierStreet: "",
    supplierCity: "GRANDVIEW",
    supplierState: "MO",
    supplierZip: "64030",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15234822-604974600",
    partNumber: "15234822",
    description: "B/PIPE=STRAP",
    supplierName: "HELLERMANNTYTON CORP",
    supplierAddress: "ROMEOVILLE,IL",
    supplierStreet: "",
    supplierCity: "ROMEOVILLE",
    supplierState: "IL",
    supplierZip: "604463500",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15257472-966350811",
    partNumber: "15257472",
    description: "S/SHFT=LINK",
    supplierName: "RHYTHM NORTH AMERICA CORP",
    supplierAddress: "SPARTA,TN",
    supplierStreet: "",
    supplierCity: "SPARTA",
    supplierState: "TN",
    supplierZip: "385831360",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15259076-800221918",
    partNumber: "15259076",
    description: "PLUG-FRT & RR S",
    supplierName: "PROTECTIVE INDUSTRIES INC",
    supplierAddress: "BUFFALO,NY",
    supplierStreet: "",
    supplierCity: "BUFFALO",
    supplierState: "NY",
    supplierZip: "142071910",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15261447-933877334",
    partNumber: "15261447",
    description: "TIRE LOAD INFO",
    supplierName: "CCL LABEL INC",
    supplierAddress: "STRONGSVILLE,OH",
    supplierStreet: "",
    supplierCity: "STRONGSVILLE",
    supplierState: "OH",
    supplierZip: "441495536",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15265199-609729947",
    partNumber: "15265199",
    description: "GROMMET-RR BPR",
    supplierName: "NOVARES US ENGINE COMPONENTS INC",
    supplierAddress: "JANESVILLE,WI",
    supplierStreet: "",
    supplierCity: "JANESVILLE",
    supplierState: "WI",
    supplierZip: "535469169",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15326410-099051716",
    partNumber: "15326410",
    description: "RETAINER-",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15567836-097274534",
    partNumber: "15567836",
    description: "WASHER",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "ELGIN,IL",
    supplierStreet: "",
    supplierCity: "ELGIN",
    supplierState: "IL",
    supplierZip: "601208444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15698443-049816044",
    partNumber: "15698443",
    description: "PIPE=CLIP",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "FRANKFORT,IL",
    supplierStreet: "",
    supplierCity: "FRANKFORT",
    supplierState: "IL",
    supplierZip: "604236017",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15717626-049816044",
    partNumber: "15717626",
    description: "WASHER-FLR FRT",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "FRANKFORT,IL",
    supplierStreet: "",
    supplierCity: "FRANKFORT",
    supplierState: "IL",
    supplierZip: "604236017",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15733192-604014548",
    partNumber: "15733192",
    description: "E/PIPE=STUD",
    supplierName: "MACLEAN MAYNARD LLC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512457",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15733533-081259198",
    partNumber: "15733533",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15733534-081259198",
    partNumber: "15733534",
    description: "BOLT/SCREW",
    supplierName: "MNP CORPORATION",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483141411",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15745097-240929703",
    partNumber: "15745097",
    description: "HD F/TNK=STRAP",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 5P1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-15785317-005509385",
    partNumber: "15785317",
    description: "LABEL-DSL FUEL",
    supplierName: "WHITLAM GROUP INC",
    supplierAddress: "CENTER LINE,MI",
    supplierStreet: "",
    supplierCity: "CENTER LINE",
    supplierState: "MI",
    supplierZip: "480151059",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15788083-796778889",
    partNumber: "15788083",
    description: "PLUG-BODY L/PLR",
    supplierName: "PROTECTIVE INDUSTRIES INC",
    supplierAddress: "ERIE,PA",
    supplierStreet: "",
    supplierCity: "ERIE",
    supplierState: "PA",
    supplierZip: "165094447",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15825689-545237257",
    partNumber: "15825689",
    description: "E/GT=PLATE",
    supplierName: "SRG GLOBAL (SUZHOU) CO LTD",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215021",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-15825690-545237257",
    partNumber: "15825690",
    description: "E/GT=PLATE",
    supplierName: "SRG GLOBAL (SUZHOU) CO LTD",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215021",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-15825691-545237257",
    partNumber: "15825691",
    description: "DR/NA=PLT",
    supplierName: "SRG GLOBAL (SUZHOU) CO LTD",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215021",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-15827206-243459851",
    partNumber: "15827206",
    description: "S/WHL=LK",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-15860871-545237257",
    partNumber: "15860871",
    description: "E/GT=PLATE",
    supplierName: "SRG GLOBAL (SUZHOU) CO LTD",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215021",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-15885613-807849687",
    partNumber: "15885613",
    description: "BUMPER-F/FDR RR",
    supplierName: "HENKEL CORP",
    supplierAddress: "RICHMOND,MO",
    supplierStreet: "",
    supplierCity: "RICHMOND",
    supplierState: "MO",
    supplierZip: "640852374",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15922078-243459851",
    partNumber: "15922078",
    description: "LK/CYL=CASE",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-15924986-053585626",
    partNumber: "15924986",
    description: "RETAINER",
    supplierName: "CHAMPION PLASTICS INC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483261584",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15973108-795602551",
    partNumber: "15973108",
    description: "SHPG=BAG",
    supplierName: "STEPHEN GOULD CORP",
    supplierAddress: "COMMERCE TOWNSHIP,MI",
    supplierStreet: "",
    supplierCity: "COMMERCE TOWNSHIP",
    supplierState: "MI",
    supplierZip: "48390",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-15984314-057852626",
    partNumber: "15984314",
    description: "L/PRTS=CNTR",
    supplierName: "VERITIV OPERATING CO",
    supplierAddress: "AURORA,IL",
    supplierStreet: "",
    supplierCity: "AURORA",
    supplierState: "IL",
    supplierZip: "605024717",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-20064875-184861805",
    partNumber: "20064875",
    description: "RETAINER-DASH S",
    supplierName: "MOHR ENGINEERING INC",
    supplierAddress: "BRIGHTON,MI",
    supplierStreet: "",
    supplierCity: "BRIGHTON",
    supplierState: "MI",
    supplierZip: "481162227",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-20756715-005356878",
    partNumber: "20756715",
    description: "HD PUMP ASM-P/S",
    supplierName: "NEXTEER AUTOMOTIVE CORP-GENL OFFICE",
    supplierAddress: "SAGINAW,MI",
    supplierStreet: "",
    supplierCity: "SAGINAW",
    supplierState: "MI",
    supplierZip: "486019494",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-20814889-313687353",
    partNumber: "20814889",
    description: "PLUG-SUN RF HSG",
    supplierName: "ITW FASTENER PRODUCTS GMBH",
    supplierAddress: "ENKENBACH ALSENBORN,RP",
    supplierStreet: "",
    supplierCity: "ENKENBACH ALSENBORN",
    supplierState: "RP",
    supplierZip: "67677",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-20848733-800221918",
    partNumber: "20848733",
    description: "PLUG-BODY SI OT",
    supplierName: "PROTECTIVE INDUSTRIES INC",
    supplierAddress: "BUFFALO,NY",
    supplierStreet: "",
    supplierCity: "BUFFALO",
    supplierState: "NY",
    supplierZip: "142071910",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-20931028-966669645",
    partNumber: "20931028",
    description: "DUCT ASM-A/CL O",
    supplierName: "UNDERCAR PRODUCTS GROUP INC",
    supplierAddress: "WYOMING,MI",
    supplierStreet: "",
    supplierCity: "WYOMING",
    supplierState: "MI",
    supplierZip: "49508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-20937765-544634264",
    partNumber: "20937765",
    description: "AAJM WHEEL",
    supplierName: "CITIC DICASTAL CO LTD PRODUCTION FA",
    supplierAddress: "QINHUANGDAO,13",
    supplierStreet: "",
    supplierCity: "QINHUANGDAO",
    supplierState: "13",
    supplierZip: "66009",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-20937769-812366540",
    partNumber: "20937769",
    description: "AAJF WHEEL",
    supplierName: "SUPERIOR INDUSTRIES DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31136",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-20937770-812780081",
    partNumber: "20937770",
    description: "AAJG WHEEL",
    supplierName: "SUPERIOR INDUSTRIES DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31136",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-20941994-545264020",
    partNumber: "20941994",
    description: "CAP",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-20941999-545264020",
    partNumber: "20941999",
    description: "CAP",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-20942000-545264020",
    partNumber: "20942000",
    description: "CAP",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-20942002-545264020",
    partNumber: "20942002",
    description: "CAP",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-20963245-009971289",
    partNumber: "20963245",
    description: "L/G GLS W/X",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-20963560-096141098",
    partNumber: "20963560",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE OKLAHOMA INC",
    supplierAddress: "FREDERICK,OK",
    supplierStreet: "",
    supplierCity: "FREDERICK",
    supplierState: "OK",
    supplierZip: "735427000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-20970408-021093638",
    partNumber: "20970408",
    description: "BRACKET-RAD OTL",
    supplierName: "BOPP-BUSCH MANUFACTURING CO INC",
    supplierAddress: "AU GRES,MI",
    supplierStreet: "",
    supplierCity: "AU GRES",
    supplierState: "MI",
    supplierZip: "487039748",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-20971966-019106121",
    partNumber: "20971966",
    description: "AAMD WHEEL",
    supplierName: "HOLLINGSWORTH LOGISTICS GRP LLC",
    supplierAddress: "SCHERTZ,TX",
    supplierStreet: "",
    supplierCity: "SCHERTZ",
    supplierState: "TX",
    supplierZip: "78154",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-20973103-080203455",
    partNumber: "20973103",
    description: "HOSE-SUN RF DRN",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-20973104-080203455",
    partNumber: "20973104",
    description: "HOSE-SUN RF DRN",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-20978734-050150481",
    partNumber: "20978734",
    description: "REINFORCEMENT-R",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-20978735-050150481",
    partNumber: "20978735",
    description: "REINFORCEMENT-R",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-20978780-050150481",
    partNumber: "20978780",
    description: "REINFORCEMENT-F",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-20978781-050150481",
    partNumber: "20978781",
    description: "REINFORCEMENT-F",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22189414-099051716",
    partNumber: "22189414",
    description: "PLUG-INFLR AIR",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22547243-099051716",
    partNumber: "22547243",
    description: "NUT ASM",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22581725-049816044",
    partNumber: "22581725",
    description: "RETAINER-EVAP E",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "FRANKFORT,IL",
    supplierStreet: "",
    supplierCity: "FRANKFORT",
    supplierState: "IL",
    supplierZip: "604236017",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22624524-040584963",
    partNumber: "22624524",
    description: "INSULATOR-RF FR",
    supplierName: "UNIQUE FABRICATING NA INC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483261415",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22695614-051366094",
    partNumber: "22695614",
    description: "PIPE=CLIP",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "NEW LEXINGTON,OH",
    supplierStreet: "",
    supplierCity: "NEW LEXINGTON",
    supplierState: "OH",
    supplierZip: "437649617",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22738661-966669645",
    partNumber: "22738661",
    description: "DUCT ASM-A/CL O",
    supplierName: "UNDERCAR PRODUCTS GROUP INC",
    supplierAddress: "WYOMING,MI",
    supplierStreet: "",
    supplierCity: "WYOMING",
    supplierState: "MI",
    supplierZip: "49508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22753373-050476923",
    partNumber: "22753373",
    description: "SPEAKER ASM-RDO",
    supplierName: "BOSE CORP",
    supplierAddress: "SAN DIEGO,CA",
    supplierStreet: "",
    supplierCity: "SAN DIEGO",
    supplierState: "CA",
    supplierZip: "921546216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22755638-050150481",
    partNumber: "22755638",
    description: "BRACKET-F/FDR F",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22755704-136995656",
    partNumber: "22755704",
    description: "BUMPER-HOOD FRT",
    supplierName: "ZHONGDING USA INC",
    supplierAddress: "MONROE,MI",
    supplierStreet: "",
    supplierCity: "MONROE",
    supplierState: "MI",
    supplierZip: "481622783",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22755721-136995656",
    partNumber: "22755721",
    description: "BUMPER-HOOD SI",
    supplierName: "ZHONGDING USA INC",
    supplierAddress: "MONROE,MI",
    supplierStreet: "",
    supplierCity: "MONROE",
    supplierState: "MI",
    supplierZip: "481622783",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22756357-077472228",
    partNumber: "22756357",
    description: "BRACKET-F/FDR F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22756358-077472228",
    partNumber: "22756358",
    description: "BRACKET-F/FDR F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22756477-077472228",
    partNumber: "22756477",
    description: "BRACKET-F/FDR F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22756478-077472228",
    partNumber: "22756478",
    description: "BRACKET-F/FDR F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22756479-077472228",
    partNumber: "22756479",
    description: "BRACKET-F/FDR F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22756480-077472228",
    partNumber: "22756480",
    description: "BRACKET-F/FDR F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22756481-077472228",
    partNumber: "22756481",
    description: "BRACKET-F/FDR F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22756482-077472228",
    partNumber: "22756482",
    description: "BRACKET-F/FDR F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22756657-144331493",
    partNumber: "22756657",
    description: "REINFORCEMENT-H",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22756658-144331493",
    partNumber: "22756658",
    description: "REINFORCEMENT-H",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22756659-144331493",
    partNumber: "22756659",
    description: "REINFORCEMENT-H",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22756662-144331493",
    partNumber: "22756662",
    description: "PANEL-HOOD INR",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22756674-545288953",
    partNumber: "22756674",
    description: "BUMPER-HOOD SI",
    supplierName: "QINGDAO DONGMING AUTOFITTING CO LTD",
    supplierAddress: "QINGDAO,37",
    supplierStreet: "",
    supplierCity: "QINGDAO",
    supplierState: "37",
    supplierZip: "266706",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-22756675-121584338",
    partNumber: "22756675",
    description: "PANEL-FRT W/H",
    supplierName: "GM METAL FAB-MARION",
    supplierAddress: "MARION,IN",
    supplierStreet: "",
    supplierCity: "MARION",
    supplierState: "IN",
    supplierZip: "46952",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22756676-121584338",
    partNumber: "22756676",
    description: "PANEL-FRT W/H",
    supplierName: "GM METAL FAB-MARION",
    supplierAddress: "MARION,IN",
    supplierStreet: "",
    supplierCity: "MARION",
    supplierState: "IN",
    supplierZip: "46952",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22756814-050150481",
    partNumber: "22756814",
    description: "REINFORCEMENT A",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22766299-812416683",
    partNumber: "22766299",
    description: "DUCT ASM-FLR FR",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-22772904-969789283",
    partNumber: "22772904",
    description: "GARN WDO RL",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22772905-969789283",
    partNumber: "22772905",
    description: "GARN WDO RR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22772910-969789283",
    partNumber: "22772910",
    description: "SHARK FIN FL",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22785891-144331493",
    partNumber: "22785891",
    description: "REINFORCEMENT-F",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22785892-144331493",
    partNumber: "22785892",
    description: "REINFORCEMENT-F",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22785905-144331493",
    partNumber: "22785905",
    description: "REINFORCEMENT-F",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22785906-144331493",
    partNumber: "22785906",
    description: "REINFORCEMENT-F",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22785910-144331493",
    partNumber: "22785910",
    description: "REINFORCEMENT-R",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22785914-144331493",
    partNumber: "22785914",
    description: "REINFORCEMENT-R",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22785916-050150481",
    partNumber: "22785916",
    description: "REINFORCEMENT A",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22785919-144331493",
    partNumber: "22785919",
    description: "REINFORCEMENT-R",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22785923-144331493",
    partNumber: "22785923",
    description: "REINFORCEMENT-R",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22785931-086663101",
    partNumber: "22785931",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22785939-086663101",
    partNumber: "22785939",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22789595-142199376",
    partNumber: "22789595",
    description: "PANEL-BODY SI I",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22790250-189658198",
    partNumber: "22790250",
    description: "SEAL-F/FDR FIL",
    supplierName: "SIKA CORP",
    supplierAddress: "GRANDVIEW,MO",
    supplierStreet: "",
    supplierCity: "GRANDVIEW",
    supplierState: "MO",
    supplierZip: "64030",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22791460-005356878",
    partNumber: "22791460",
    description: "SHAFT ASM-FRT W",
    supplierName: "NEXTEER AUTOMOTIVE CORP-GENL OFFICE",
    supplierAddress: "SAGINAW,MI",
    supplierStreet: "",
    supplierCity: "SAGINAW",
    supplierState: "MI",
    supplierZip: "486019494",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22792151-077472228",
    partNumber: "22792151",
    description: "BRACKET-F/FDR R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22792158-077472228",
    partNumber: "22792158",
    description: "BRACKET-F/FDR R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22792759-067392746",
    partNumber: "22792759",
    description: "STRUT ASM-L/GAT",
    supplierName: "STABILUS INC",
    supplierAddress: "GASTONIA,NC",
    supplierStreet: "",
    supplierCity: "GASTONIA",
    supplierState: "NC",
    supplierZip: "280521842",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22792760-067392746",
    partNumber: "22792760",
    description: "STRUT ASM-L/GAT",
    supplierName: "STABILUS INC",
    supplierAddress: "GASTONIA,NC",
    supplierStreet: "",
    supplierCity: "GASTONIA",
    supplierState: "NC",
    supplierZip: "280521842",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22796082-544634264",
    partNumber: "22796082",
    description: "AARC WHEEL",
    supplierName: "CITIC DICASTAL CO LTD PRODUCTION FA",
    supplierAddress: "QINHUANGDAO,13",
    supplierStreet: "",
    supplierCity: "QINHUANGDAO",
    supplierState: "13",
    supplierZip: "66009",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-22799549-969789283",
    partNumber: "22799549",
    description: "EXTENSION-AIR I",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22799550-969789283",
    partNumber: "22799550",
    description: "EXTENSION-AIR I",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22799553-969789283",
    partNumber: "22799553",
    description: "EXTENSION-AIR I",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22799554-969789283",
    partNumber: "22799554",
    description: "EXTENSION-AIR I",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22799917-933877334",
    partNumber: "22799917",
    description: "LABEL-FUEL RECO",
    supplierName: "CCL LABEL INC",
    supplierAddress: "STRONGSVILLE,OH",
    supplierStreet: "",
    supplierCity: "STRONGSVILLE",
    supplierState: "OH",
    supplierZip: "441495536",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806316-961973638",
    partNumber: "22806316",
    description: "LINER-FRT W/H",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806317-961973638",
    partNumber: "22806317",
    description: "LINER-FRT W/H",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806318-961973638",
    partNumber: "22806318",
    description: "LINER-FRT W/H",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806319-961973638",
    partNumber: "22806319",
    description: "LINER-FRT W/H",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806320-961973638",
    partNumber: "22806320",
    description: "LINER-FRT W/H",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806322-969789283",
    partNumber: "22806322",
    description: "BRACKET ASM-FRT",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806323-969789283",
    partNumber: "22806323",
    description: "BRACKET ASM-FRT",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806324-969789283",
    partNumber: "22806324",
    description: "BRACKET ASM-FRT",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806325-969789283",
    partNumber: "22806325",
    description: "BRACKET ASM-FRT",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806381-808141266",
    partNumber: "22806381",
    description: "BAR ASM-RR BPR",
    supplierName: "SHAPE CORP",
    supplierAddress: "GRAND HAVEN,MI",
    supplierStreet: "",
    supplierCity: "GRAND HAVEN",
    supplierState: "MI",
    supplierZip: "494179428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806425-969789283",
    partNumber: "22806425",
    description: "BRACKET-RR BPR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806426-969789283",
    partNumber: "22806426",
    description: "BRACKET-RR BPR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806431-969789283",
    partNumber: "22806431",
    description: "BRACKET-RR BPR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806432-969789283",
    partNumber: "22806432",
    description: "BRACKET-RR BPR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806438-961973638",
    partNumber: "22806438",
    description: "WHLINR-RR CH920",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806440-961973638",
    partNumber: "22806440",
    description: "WHLINR-RR GM920",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806442-961973638",
    partNumber: "22806442",
    description: "WHLINR-RR CA920",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22806446-961973638",
    partNumber: "22806446",
    description: "WHLINR-RR GM930",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22813698-812416683",
    partNumber: "22813698",
    description: "STEP ASM-ASST",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-22813699-812416683",
    partNumber: "22813699",
    description: "STEP ASM-ASST",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-22813701-247524416",
    partNumber: "22813701",
    description: "STEP ASM-ASST",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-22813702-247524416",
    partNumber: "22813702",
    description: "STEP ASM-ASST",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-22813703-247524416",
    partNumber: "22813703",
    description: "STEP ASM-ASST",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-22813704-247524416",
    partNumber: "22813704",
    description: "STEP ASM-ASST",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-22813705-247524416",
    partNumber: "22813705",
    description: "STEP ASM-ASST",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-22814344-078849132",
    partNumber: "22814344",
    description: "SHIELD-ENG LWR",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22814345-078849132",
    partNumber: "22814345",
    description: "SHIELD-ENG LWR",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22814875-077472228",
    partNumber: "22814875",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22814876-077472228",
    partNumber: "22814876",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22816741-077472228",
    partNumber: "22816741",
    description: "BRACKET-F/FDR F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22816742-077472228",
    partNumber: "22816742",
    description: "BRACKET-F/FDR F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22818981-050476923",
    partNumber: "22818981",
    description: "SPEAKER ASM-RDO",
    supplierName: "BOSE CORP",
    supplierAddress: "SAN DIEGO,CA",
    supplierStreet: "",
    supplierCity: "SAN DIEGO",
    supplierState: "CA",
    supplierZip: "921546216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22819597-078849132",
    partNumber: "22819597",
    description: "FUNNEL KIT",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22821827-000241018",
    partNumber: "22821827",
    description: "COVER ASM-D/SEA",
    supplierName: "LEAR OPERATIONS CORP",
    supplierAddress: "WESTLAND,MI",
    supplierStreet: "",
    supplierCity: "WESTLAND",
    supplierState: "MI",
    supplierZip: "48185",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22821829-000241018",
    partNumber: "22821829",
    description: "COVER ASM-D/SEA",
    supplierName: "LEAR OPERATIONS CORP",
    supplierAddress: "WESTLAND,MI",
    supplierStreet: "",
    supplierCity: "WESTLAND",
    supplierState: "MI",
    supplierZip: "48185",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22821880-000241018",
    partNumber: "22821880",
    description: "COVER ASM-P/SEA",
    supplierName: "LEAR OPERATIONS CORP",
    supplierAddress: "WESTLAND,MI",
    supplierStreet: "",
    supplierCity: "WESTLAND",
    supplierState: "MI",
    supplierZip: "48185",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22821882-000241018",
    partNumber: "22821882",
    description: "COVER ASM-P/SEA",
    supplierName: "LEAR OPERATIONS CORP",
    supplierAddress: "WESTLAND,MI",
    supplierStreet: "",
    supplierCity: "WESTLAND",
    supplierState: "MI",
    supplierZip: "48185",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22828377-243459851",
    partNumber: "22828377",
    description: "BRACKET-COMN IN",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-22829663-808141266",
    partNumber: "22829663",
    description: "BRACE ASM-FRT B",
    supplierName: "SHAPE CORP",
    supplierAddress: "GRAND HAVEN,MI",
    supplierStreet: "",
    supplierCity: "GRAND HAVEN",
    supplierState: "MI",
    supplierZip: "494179428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22829664-808141266",
    partNumber: "22829664",
    description: "BRACE ASM-FRT B",
    supplierName: "SHAPE CORP",
    supplierAddress: "GRAND HAVEN,MI",
    supplierStreet: "",
    supplierCity: "GRAND HAVEN",
    supplierState: "MI",
    supplierZip: "494179428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22832436-544979545",
    partNumber: "22832436",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-22832437-544979545",
    partNumber: "22832437",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-22832438-544979545",
    partNumber: "22832438",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-22832439-544979545",
    partNumber: "22832439",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-22832440-544979545",
    partNumber: "22832440",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-22832441-544979545",
    partNumber: "22832441",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-22832918-243459851",
    partNumber: "22832918",
    description: "BRACKET-HMI CON",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-22840748-050150481",
    partNumber: "22840748",
    description: "REINFORCEMENT-F",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22840749-050150481",
    partNumber: "22840749",
    description: "REINFORCEMENT-F",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22852682-053585626",
    partNumber: "22852682",
    description: "COVER-FRT S/D C",
    supplierName: "CHAMPION PLASTICS INC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483261584",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22854651-240929703",
    partNumber: "22854651",
    description: "REINFORCEMENT A",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 5P1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-22870669-077472228",
    partNumber: "22870669",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22880346-812416683",
    partNumber: "22880346",
    description: "CAP-CTR PLR UPR",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-22880347-812416683",
    partNumber: "22880347",
    description: "CAP-CTR PLR UPR",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-22880348-038605341",
    partNumber: "22880348",
    description: "CAP-CTR PLR UPR",
    supplierName: "NYX INC",
    supplierAddress: "LIVONIA,MI",
    supplierStreet: "",
    supplierCity: "LIVONIA",
    supplierState: "MI",
    supplierZip: "481501216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22885235-813016276",
    partNumber: "22885235",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-22885239-813016276",
    partNumber: "22885239",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-22885607-808263656",
    partNumber: "22885607",
    description: "CARPET-R/FLR PN",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22886691-689201478",
    partNumber: "22886691",
    description: "CAP-FRT S/D I/S",
    supplierName: "SAMWON PLASTIC CO LTD",
    supplierAddress: "SHIHEUNG,41",
    supplierStreet: "",
    supplierCity: "SHIHEUNG",
    supplierState: "41",
    supplierZip: "15084",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-22886692-689201478",
    partNumber: "22886692",
    description: "CAP-FRT S/D I/S",
    supplierName: "SAMWON PLASTIC CO LTD",
    supplierAddress: "SHIHEUNG,41",
    supplierStreet: "",
    supplierCity: "SHIHEUNG",
    supplierState: "41",
    supplierZip: "15084",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-22887209-078852206",
    partNumber: "22887209",
    description: "BRACE ASM-F/FDR",
    supplierName: "NITTO AUTOMOTIVE INC",
    supplierAddress: "RIVERSIDE,MO",
    supplierStreet: "",
    supplierCity: "RIVERSIDE",
    supplierState: "MO",
    supplierZip: "641509539",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22892617-056894041",
    partNumber: "22892617",
    description: "R WPR CVR LG KT",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22894407-654129907",
    partNumber: "22894407",
    description: "HANDLE ASM-R/RL",
    supplierName: "NINGBO JOYSON TECHNOLOGY CO LTD",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200444",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-22894577-813016276",
    partNumber: "22894577",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-22894578-813016276",
    partNumber: "22894578",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-22900139-808141266",
    partNumber: "22900139",
    description: "BAR ASM-RR BPR",
    supplierName: "SHAPE CORP",
    supplierAddress: "GRAND HAVEN,MI",
    supplierStreet: "",
    supplierCity: "GRAND HAVEN",
    supplierState: "MI",
    supplierZip: "494179428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22903513-080646147",
    partNumber: "22903513",
    description: "HOOK-FRT TOW",
    supplierName: "MID WEST FABRICATING CO",
    supplierAddress: "LANCASTER,OH",
    supplierStreet: "",
    supplierCity: "LANCASTER",
    supplierState: "OH",
    supplierZip: "431308061",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22905550-544634264",
    partNumber: "22905550",
    description: "AAYS WHEEL",
    supplierName: "CITIC DICASTAL CO LTD PRODUCTION FA",
    supplierAddress: "QINHUANGDAO,13",
    supplierStreet: "",
    supplierCity: "QINHUANGDAO",
    supplierState: "13",
    supplierZip: "66009",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-22916987-545288953",
    partNumber: "22916987",
    description: "BUMPER-HOOD SI",
    supplierName: "QINGDAO DONGMING AUTOFITTING CO LTD",
    supplierAddress: "QINGDAO,37",
    supplierStreet: "",
    supplierCity: "QINGDAO",
    supplierState: "37",
    supplierZip: "266706",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-22924049-808408285",
    partNumber: "22924049",
    description: "BAG-TOOL STOW",
    supplierName: "CJ AUTOMOTIVE INDIANA LLC",
    supplierAddress: "BUTLER,IN",
    supplierStreet: "",
    supplierCity: "BUTLER",
    supplierState: "IN",
    supplierZip: "46721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22928006-240929703",
    partNumber: "22928006",
    description: "STRAP-F/TNK",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 5P1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-22928007-240929703",
    partNumber: "22928007",
    description: "STRAP-F/TNK",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 5P1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-22929239-839434800",
    partNumber: "22929239",
    description: "COVER-FRT S/D L",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22934643-243459851",
    partNumber: "22934643",
    description: "BAG UNIT-SI DR",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-22935934-042439265",
    partNumber: "22935934",
    description: "REINFORCEMENT A",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22944388-050150481",
    partNumber: "22944388",
    description: "REINFORCEMENT-F",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22944389-050150481",
    partNumber: "22944389",
    description: "REINFORCEMENT-F",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22948151-243459851",
    partNumber: "22948151",
    description: "ATTACHMENT PKG-",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-22953072-544908429",
    partNumber: "22953072",
    description: "COVER",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-22953073-544908429",
    partNumber: "22953073",
    description: "COVER",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-22967915-077472228",
    partNumber: "22967915",
    description: "BRACE-FRT S/D O",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22967916-077472228",
    partNumber: "22967916",
    description: "BRACE-FRT S/D O",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22967917-077472228",
    partNumber: "22967917",
    description: "BRACE-RR S/D OT",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22967918-077472228",
    partNumber: "22967918",
    description: "BRACE-RR S/D OT",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22968178-101180995",
    partNumber: "22968178",
    description: "HOIST ASM-SPA W",
    supplierName: "MAG-USA INC",
    supplierAddress: "CLINTON,TN",
    supplierStreet: "",
    supplierCity: "CLINTON",
    supplierState: "TN",
    supplierZip: "377166587",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22969207-961973638",
    partNumber: "22969207",
    description: "LINER",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22969370-000190694",
    partNumber: "22969370",
    description: "TOOL KIT",
    supplierName: "VENTRAMEX SA DE C V",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22969377-000190694",
    partNumber: "22969377",
    description: "TOOL KIT",
    supplierName: "VENTRAMEX SA DE C V",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22985886-202888707",
    partNumber: "22985886",
    description: "SHIELD",
    supplierName: "MARWOOD INTERNATIONAL INC",
    supplierAddress: "WOODSTOCK,ON",
    supplierStreet: "",
    supplierCity: "WOODSTOCK",
    supplierState: "ON",
    supplierZip: "N4S 7L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-22989203-813016276",
    partNumber: "22989203",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-22992039-050150481",
    partNumber: "22992039",
    description: "BRACKET-F/FDR R",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-22993295-812885674",
    partNumber: "22993295",
    description: "CABLE ASM-RDO A",
    supplierName: "CEMM MEX SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67205",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-22998955-000241018",
    partNumber: "22998955",
    description: "COVER-F/SEAT IN",
    supplierName: "LEAR OPERATIONS CORP",
    supplierAddress: "WESTLAND,MI",
    supplierStreet: "",
    supplierCity: "WESTLAND",
    supplierState: "MI",
    supplierZip: "48185",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23101020-814180139",
    partNumber: "23101020",
    description: "COVER ASM-F/SEA",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23101021-814180139",
    partNumber: "23101021",
    description: "COVER ASM-F/SEA",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23104708-813016276",
    partNumber: "23104708",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23104709-813016276",
    partNumber: "23104709",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23105866-800786642",
    partNumber: "23105866",
    description: "ADHESIVE-L/GATE",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23106126-086663101",
    partNumber: "23106126",
    description: "REINFORCEMENT A",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23106127-086663101",
    partNumber: "23106127",
    description: "REINFORCEMENT A",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23107857-021093638",
    partNumber: "23107857",
    description: "RETAINER-FRT PR",
    supplierName: "BOPP-BUSCH MANUFACTURING CO INC",
    supplierAddress: "AU GRES,MI",
    supplierStreet: "",
    supplierCity: "AU GRES",
    supplierState: "MI",
    supplierZip: "487039748",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23107858-005356878",
    partNumber: "23107858",
    description: "RETAINER-F/AXL",
    supplierName: "NEXTEER AUTOMOTIVE CORP-GENL OFFICE",
    supplierAddress: "SAGINAW,MI",
    supplierStreet: "",
    supplierCity: "SAGINAW",
    supplierState: "MI",
    supplierZip: "486019494",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23108051-621632046",
    partNumber: "23108051",
    description: "INSULATOR-RF PN",
    supplierName: "CADILLAC PRODUCTS INC",
    supplierAddress: "SCHERTZ,TX",
    supplierStreet: "",
    supplierCity: "SCHERTZ",
    supplierState: "TX",
    supplierZip: "78154",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23109284-689201478",
    partNumber: "23109284",
    description: "CAP-FRT S/D I/S",
    supplierName: "SAMWON PLASTIC CO LTD",
    supplierAddress: "SHIHEUNG,41",
    supplierStreet: "",
    supplierCity: "SHIHEUNG",
    supplierState: "41",
    supplierZip: "15084",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-23109285-689201478",
    partNumber: "23109285",
    description: "CAP-FRT S/D I/S",
    supplierName: "SAMWON PLASTIC CO LTD",
    supplierAddress: "SHIHEUNG,41",
    supplierStreet: "",
    supplierCity: "SHIHEUNG",
    supplierState: "41",
    supplierZip: "15084",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-23114339-050150481",
    partNumber: "23114339",
    description: "BRACKET-A/CL",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23115486-000243147",
    partNumber: "23115486",
    description: "FABRIC-VEH CLNG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23117487-000243147",
    partNumber: "23117487",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23118851-800786642",
    partNumber: "23118851",
    description: "ADHESIVE-L/GATE",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23118852-800786642",
    partNumber: "23118852",
    description: "ADHESIVE-L/GATE",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23120632-000243147",
    partNumber: "23120632",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23129686-812885674",
    partNumber: "23129686",
    description: "CABLE ASM-RDO A",
    supplierName: "CEMM MEX SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67205",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23133712-050150481",
    partNumber: "23133712",
    description: "BRACKET-HTR INL",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23135908-040584963",
    partNumber: "23135908",
    description: "TAPE-BODY L/PLR",
    supplierName: "UNIQUE FABRICATING NA INC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483261415",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23138858-202679841",
    partNumber: "23138858",
    description: "KNOB ASM-FRT S/",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "PENETANGUISHENE,ON",
    supplierStreet: "",
    supplierCity: "PENETANGUISHENE",
    supplierState: "ON",
    supplierZip: "L9M 1G8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23140712-544925969",
    partNumber: "23140712",
    description: "CABLE ASM-RR S/",
    supplierName: "L&C CHANGSHA CABLE INDUSTRIES LTD",
    supplierAddress: "CHANGSHA,43",
    supplierStreet: "",
    supplierCity: "CHANGSHA",
    supplierState: "43",
    supplierZip: "410119",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23140717-544925969",
    partNumber: "23140717",
    description: "CABLE ASM-RR S/",
    supplierName: "L&C CHANGSHA CABLE INDUSTRIES LTD",
    supplierAddress: "CHANGSHA,43",
    supplierStreet: "",
    supplierCity: "CHANGSHA",
    supplierState: "43",
    supplierZip: "410119",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23149246-050150481",
    partNumber: "23149246",
    description: "SHIELD-SPA WHL",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23149392-144331493",
    partNumber: "23149392",
    description: "REINFORCEMENT-R",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23149393-144331493",
    partNumber: "23149393",
    description: "REINFORCEMENT-R",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23156966-189658198",
    partNumber: "23156966",
    description: "BAFFLE-QTR INR",
    supplierName: "SIKA CORP",
    supplierAddress: "GRANDVIEW,MO",
    supplierStreet: "",
    supplierCity: "GRANDVIEW",
    supplierState: "MO",
    supplierZip: "64030",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23167845-144331493",
    partNumber: "23167845",
    description: "INR GMC",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23170499-058961707",
    partNumber: "23170499",
    description: "HDRETAINER-RR P",
    supplierName: "A RAYMOND TINNERMAN AUTOMOTIVE INC",
    supplierAddress: "LOGANSPORT,IN",
    supplierStreet: "",
    supplierCity: "LOGANSPORT",
    supplierState: "IN",
    supplierZip: "469478269",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23179659-020837233",
    partNumber: "23179659",
    description: "BRACKET-FWD RAN",
    supplierName: "DAJACO INDUSTRIES INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512478",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23188756-030993351",
    partNumber: "23188756",
    description: "PLUG-RKR INR PN",
    supplierName: "NIFCO AMERICA CORP",
    supplierAddress: "CANAL WINCHESTER,OH",
    supplierStreet: "",
    supplierCity: "CANAL WINCHESTER",
    supplierState: "OH",
    supplierZip: "43110",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23195058-808141266",
    partNumber: "23195058",
    description: "BAR ASM-FRT BPR",
    supplierName: "SHAPE CORP",
    supplierAddress: "GRAND HAVEN,MI",
    supplierStreet: "",
    supplierCity: "GRAND HAVEN",
    supplierState: "MI",
    supplierZip: "494179428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23196407-812885674",
    partNumber: "23196407",
    description: "CABLE-HDLNR",
    supplierName: "CEMM MEX SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67205",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23200895-545337164",
    partNumber: "23200895",
    description: "OUTLET ASM-I/P",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23200897-545337164",
    partNumber: "23200897",
    description: "OUTLET ASM-I/P",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23200898-545337164",
    partNumber: "23200898",
    description: "OUTLET ASM-I/P",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23200899-545337164",
    partNumber: "23200899",
    description: "OUTLET ASM-I/P",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23203896-812416683",
    partNumber: "23203896",
    description: "CAP-CTR PLR UPR",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23203897-812416683",
    partNumber: "23203897",
    description: "CAP-CTR PLR UPR",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23203899-812416683",
    partNumber: "23203899",
    description: "CAP-CTR PLR UPR",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23203900-812416683",
    partNumber: "23203900",
    description: "CAP-CTR PLR UPR",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23203930-688379886",
    partNumber: "23203930",
    description: "PLATE ASM-FRT S",
    supplierName: "SAMSHIN CHEMICAL CO LTD",
    supplierAddress: "ANSAN,41",
    supplierStreet: "",
    supplierCity: "ANSAN",
    supplierState: "41",
    supplierZip: "15606",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-23204162-812416683",
    partNumber: "23204162",
    description: "COVER-F/SEAT AD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23204164-812416683",
    partNumber: "23204164",
    description: "COVER-F/SEAT AD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23204167-812416683",
    partNumber: "23204167",
    description: "COVER-F/SEAT AD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23204168-812416683",
    partNumber: "23204168",
    description: "COVER-F/SEAT AD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23204169-812416683",
    partNumber: "23204169",
    description: "COVER-F/SEAT AD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23207334-812947059",
    partNumber: "23207334",
    description: "CONSOLE ASM-RF",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23217243-812888154",
    partNumber: "23217243",
    description: "WHEEL-ABKW",
    supplierName: "SUPERIOR INDUSTRIES DE MEXICO SA DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31090",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23218769-812416683",
    partNumber: "23218769",
    description: "COVER ASM-F/SEA",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23218770-812416683",
    partNumber: "23218770",
    description: "COVER ASM-F/SEA",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23218771-812416683",
    partNumber: "23218771",
    description: "COVER ASM-F/SEA",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23218776-812416683",
    partNumber: "23218776",
    description: "COVER ASM-F/SEA",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23218777-812416683",
    partNumber: "23218777",
    description: "COVER ASM-F/SEA",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23229386-005346762",
    partNumber: "23229386",
    description: "SEALING STRIP A",
    supplierName: "COOPER STANDARD AUTOMOTIVE INC",
    supplierAddress: "GAYLORD,MI",
    supplierStreet: "",
    supplierCity: "GAYLORD",
    supplierState: "MI",
    supplierZip: "497359531",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23229397-005346762",
    partNumber: "23229397",
    description: "SEALING STRIP A",
    supplierName: "COOPER STANDARD AUTOMOTIVE INC",
    supplierAddress: "GAYLORD,MI",
    supplierStreet: "",
    supplierCity: "GAYLORD",
    supplierState: "MI",
    supplierZip: "497359531",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23229398-005346762",
    partNumber: "23229398",
    description: "SEALING STRIP A",
    supplierName: "COOPER STANDARD AUTOMOTIVE INC",
    supplierAddress: "GAYLORD,MI",
    supplierStreet: "",
    supplierCity: "GAYLORD",
    supplierState: "MI",
    supplierZip: "497359531",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23229399-005346762",
    partNumber: "23229399",
    description: "SEALING STRIP A",
    supplierName: "COOPER STANDARD AUTOMOTIVE INC",
    supplierAddress: "GAYLORD,MI",
    supplierStreet: "",
    supplierCity: "GAYLORD",
    supplierState: "MI",
    supplierZip: "497359531",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23231841-050150481",
    partNumber: "23231841",
    description: "TRAY ASM-BODY B",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23231888-254039282",
    partNumber: "23231888",
    description: "LINK ASM-RR S/D",
    supplierName: "MULTIMATIC INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4Y9",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23233999-184861805",
    partNumber: "23233999",
    description: "COVER-AIRBAG SE",
    supplierName: "MOHR ENGINEERING INC",
    supplierAddress: "BRIGHTON,MI",
    supplierStreet: "",
    supplierCity: "BRIGHTON",
    supplierState: "MI",
    supplierZip: "481162227",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23234435-812416683",
    partNumber: "23234435",
    description: "PANEL ASM-CTR P",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23234436-812416683",
    partNumber: "23234436",
    description: "PANEL ASM-CTR P",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23234438-812416683",
    partNumber: "23234438",
    description: "PANEL ASM-CTR P",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23234439-812416683",
    partNumber: "23234439",
    description: "PANEL ASM-CTR P",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23235037-020837233",
    partNumber: "23235037",
    description: "BRACKET ASM-I/P",
    supplierName: "DAJACO INDUSTRIES INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512478",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23235997-243459851",
    partNumber: "23235997",
    description: "ATTACHMENT PKG-",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23236458-243459851",
    partNumber: "23236458",
    description: "ATTACHMENT PKG-",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23236459-243459851",
    partNumber: "23236459",
    description: "ATTACHMENT PKG-",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23236798-243459851",
    partNumber: "23236798",
    description: "BRACKET-SERIAL",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23238319-969789283",
    partNumber: "23238319",
    description: "GUIDE ASM-FRT B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23238320-969789283",
    partNumber: "23238320",
    description: "GUIDE ASM-FRT B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23240226-544979545",
    partNumber: "23240226",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23240227-544979545",
    partNumber: "23240227",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23243143-812416683",
    partNumber: "23243143",
    description: "DUCT-FLR RR AIR",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23253586-053495482",
    partNumber: "23253586",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23253587-053495482",
    partNumber: "23253587",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23253589-053495482",
    partNumber: "23253589",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23253591-053495482",
    partNumber: "23253591",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23253592-053495482",
    partNumber: "23253592",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23253593-053495482",
    partNumber: "23253593",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23253594-053495482",
    partNumber: "23253594",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23253597-053495482",
    partNumber: "23253597",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23255369-009971289",
    partNumber: "23255369",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23256178-545237257",
    partNumber: "23256178",
    description: "PLATE ASM-C-PIL",
    supplierName: "SRG GLOBAL (SUZHOU) CO LTD",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215021",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23256180-688379886",
    partNumber: "23256180",
    description: "PLATE ASM-C-PIL",
    supplierName: "SAMSHIN CHEMICAL CO LTD",
    supplierAddress: "ANSAN,41",
    supplierStreet: "",
    supplierCity: "ANSAN",
    supplierState: "41",
    supplierZip: "15606",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-23256181-545237257",
    partNumber: "23256181",
    description: "PLATE ASM-C-PIL",
    supplierName: "SRG GLOBAL (SUZHOU) CO LTD",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215021",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23264122-544979545",
    partNumber: "23264122",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23264123-544979545",
    partNumber: "23264123",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23264124-544979545",
    partNumber: "23264124",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23264125-544979545",
    partNumber: "23264125",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23264142-961973638",
    partNumber: "23264142",
    description: "LINER-RR W/H",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23264143-961973638",
    partNumber: "23264143",
    description: "LINER-RR W/H",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23277123-053495482",
    partNumber: "23277123",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23277124-053495482",
    partNumber: "23277124",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23283540-078849132",
    partNumber: "23283540",
    description: "BRACKET-ECM",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23284502-078746685",
    partNumber: "23284502",
    description: "LOOP ASM-CARGO",
    supplierName: "SODECIA AUTOMOTIVE AUBURN LP",
    supplierAddress: "AUBURN,AL",
    supplierStreet: "",
    supplierCity: "AUBURN",
    supplierState: "AL",
    supplierZip: "368328063",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23287648-545264020",
    partNumber: "23287648",
    description: "CAP-WHL TR",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23291785-080203455",
    partNumber: "23291785",
    description: "HOSE ASM-SUN RF",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23299837-654129907",
    partNumber: "23299837",
    description: "HOOK ASM-COAT",
    supplierName: "NINGBO JOYSON TECHNOLOGY CO LTD",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200444",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23299838-654129907",
    partNumber: "23299838",
    description: "HOOK ASM-COAT",
    supplierName: "NINGBO JOYSON TECHNOLOGY CO LTD",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200444",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23299839-654129907",
    partNumber: "23299839",
    description: "HOOK ASM-COAT",
    supplierName: "NINGBO JOYSON TECHNOLOGY CO LTD",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200444",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23304392-785629952",
    partNumber: "23304392",
    description: "HEADLAMP ASM",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23304393-785629952",
    partNumber: "23304393",
    description: "HEADLAMP ASM",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23317134-812298885",
    partNumber: "23317134",
    description: "BOOT ASM-INTER",
    supplierName: "CIKAUTXO DE MEXICO S DE RL DE CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37490",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23318742-800221918",
    partNumber: "23318742",
    description: "PLUG-RKR INR PN",
    supplierName: "PROTECTIVE INDUSTRIES INC",
    supplierAddress: "BUFFALO,NY",
    supplierStreet: "",
    supplierCity: "BUFFALO",
    supplierState: "NY",
    supplierZip: "142071910",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23320899-078488161",
    partNumber: "23320899",
    description: "SPEAKER ASM-RDO",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23329703-081348326",
    partNumber: "23329703",
    description: "COLUMN ASM-STRG",
    supplierName: "AUTOMOTIVE STEERING COLUMN LLC",
    supplierAddress: "FLORENCE,KY",
    supplierStreet: "",
    supplierCity: "FLORENCE",
    supplierState: "KY",
    supplierZip: "410420000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23331915-240929703",
    partNumber: "23331915",
    description: "REINFORCEMENT-F",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 5P1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23331916-240929703",
    partNumber: "23331916",
    description: "REINFORCEMENT-F",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 5P1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23337334-689201478",
    partNumber: "23337334",
    description: "CAP-RR S/D I/S",
    supplierName: "SAMWON PLASTIC CO LTD",
    supplierAddress: "SHIHEUNG,41",
    supplierStreet: "",
    supplierCity: "SHIHEUNG",
    supplierState: "41",
    supplierZip: "15084",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-23337337-689201478",
    partNumber: "23337337",
    description: "CAP-RR S/D I/S",
    supplierName: "SAMWON PLASTIC CO LTD",
    supplierAddress: "SHIHEUNG,41",
    supplierStreet: "",
    supplierCity: "SHIHEUNG",
    supplierState: "41",
    supplierZip: "15084",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-23337721-152890224",
    partNumber: "23337721",
    description: "CANISTER ASM-EV",
    supplierName: "DELPHI POWERTRAIN SYSTEMS LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23337722-152890224",
    partNumber: "23337722",
    description: "CANISTER ASM-EV",
    supplierName: "DELPHI POWERTRAIN SYSTEMS LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23342401-812537363",
    partNumber: "23342401",
    description: "PIPE ASM-EMIS R",
    supplierName: "AUTOMOTIVE VERITAS DE",
    supplierAddress: "CUAUTLANCINGO,PU",
    supplierStreet: "",
    supplierCity: "CUAUTLANCINGO",
    supplierState: "PU",
    supplierZip: "72730",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23342402-812537363",
    partNumber: "23342402",
    description: "PIPE ASM-EMIS R",
    supplierName: "AUTOMOTIVE VERITAS DE",
    supplierAddress: "CUAUTLANCINGO,PU",
    supplierStreet: "",
    supplierCity: "CUAUTLANCINGO",
    supplierState: "PU",
    supplierZip: "72730",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23349245-000145425",
    partNumber: "23349245",
    description: "CROSSMEMBER ASM",
    supplierName: "NORTHERN STAMPING",
    supplierAddress: "CLEVELAND,OH",
    supplierStreet: "",
    supplierCity: "CLEVELAND",
    supplierState: "OH",
    supplierZip: "44111",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23353344-813016276",
    partNumber: "23353344",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23353346-813016276",
    partNumber: "23353346",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23353347-813016276",
    partNumber: "23353347",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23356336-000243147",
    partNumber: "23356336",
    description: "FABRIC-VEH CLNG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23356394-932662133",
    partNumber: "23356394",
    description: "PLATE-I/P ACSRY",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23356396-932662133",
    partNumber: "23356396",
    description: "PLATE-I/P ACSRY",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23356399-932662133",
    partNumber: "23356399",
    description: "PLATE-I/P ACSRY",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23356425-042439265",
    partNumber: "23356425",
    description: "BRACKET-A/TRNS",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23358842-000145425",
    partNumber: "23358842",
    description: "CROSSMEMBER ASM",
    supplierName: "NORTHERN STAMPING",
    supplierAddress: "CLEVELAND,OH",
    supplierStreet: "",
    supplierCity: "CLEVELAND",
    supplierState: "OH",
    supplierZip: "44111",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23358844-000145425",
    partNumber: "23358844",
    description: "CROSSMEMBER ASM",
    supplierName: "NORTHERN STAMPING",
    supplierAddress: "CLEVELAND,OH",
    supplierStreet: "",
    supplierCity: "CLEVELAND",
    supplierState: "OH",
    supplierZip: "44111",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23363946-142199376",
    partNumber: "23363946",
    description: "SHIELD-FRT BRK",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23363947-142199376",
    partNumber: "23363947",
    description: "SHIELD-FRT BRK",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23364488-050476923",
    partNumber: "23364488",
    description: "SPEAKER ASM-RDO",
    supplierName: "BOSE CORP",
    supplierAddress: "SAN DIEGO,CA",
    supplierStreet: "",
    supplierCity: "SAN DIEGO",
    supplierState: "CA",
    supplierZip: "921546216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23364498-050476923",
    partNumber: "23364498",
    description: "SPEAKER ASM-RDO",
    supplierName: "BOSE CORP",
    supplierAddress: "SAN DIEGO,CA",
    supplierStreet: "",
    supplierCity: "SAN DIEGO",
    supplierState: "CA",
    supplierZip: "921546216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23370093-867066805",
    partNumber: "23370093",
    description: "SHAFT ASM-F/AXL",
    supplierName: "GKN DRIVELINE NORTH AMERICA INC",
    supplierAddress: "TIMBERLAKE,NC",
    supplierStreet: "",
    supplierCity: "TIMBERLAKE",
    supplierState: "NC",
    supplierZip: "27583",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23372882-544979545",
    partNumber: "23372882",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23372883-544979545",
    partNumber: "23372883",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23372884-544979545",
    partNumber: "23372884",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23376214-545264020",
    partNumber: "23376214",
    description: "CAP-WHL TR",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23376217-544634264",
    partNumber: "23376217",
    description: "WHEEL-18X8.5,J,",
    supplierName: "CITIC DICASTAL CO LTD PRODUCTION FA",
    supplierAddress: "QINHUANGDAO,13",
    supplierStreet: "",
    supplierCity: "QINHUANGDAO",
    supplierState: "13",
    supplierZip: "66009",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23376219-812780081",
    partNumber: "23376219",
    description: "WHEEL-18X8.5J A",
    supplierName: "SUPERIOR INDUSTRIES DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31136",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23376222-812888154",
    partNumber: "23376222",
    description: "WHEEL-20X9.0J A",
    supplierName: "SUPERIOR INDUSTRIES DE MEXICO SA DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31090",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23376228-812888154",
    partNumber: "23376228",
    description: "WHEEL-20X9.0J,A",
    supplierName: "SUPERIOR INDUSTRIES DE MEXICO SA DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31090",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23376234-672044871",
    partNumber: "23376234",
    description: "WHEEL-22X9.0J A",
    supplierName: "NEW THAI WHEEL MANUFACTURING CO LTD",
    supplierAddress: "PLUAK DAENG RAYONG,21",
    supplierStreet: "",
    supplierCity: "PLUAK DAENG RAYONG",
    supplierState: "21",
    supplierZip: "21140",
    supplierCountry: "TH",
  },
  {
    _id: "parts-gm-23376235-816660435",
    partNumber: "23376235",
    description: "WHEEL-20X9.0JST",
    supplierName: "MAXION WHEELS DE",
    supplierAddress: "SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "78395",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23376238-544634264",
    partNumber: "23376238",
    description: "WHEEL-22X9.0J A",
    supplierName: "CITIC DICASTAL CO LTD PRODUCTION FA",
    supplierAddress: "QINHUANGDAO,13",
    supplierStreet: "",
    supplierCity: "QINHUANGDAO",
    supplierState: "13",
    supplierZip: "66009",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23376239-672044871",
    partNumber: "23376239",
    description: "WHEEL-22X9.0J A",
    supplierName: "NEW THAI WHEEL MANUFACTURING CO LTD",
    supplierAddress: "PLUAK DAENG RAYONG,21",
    supplierStreet: "",
    supplierCity: "PLUAK DAENG RAYONG",
    supplierState: "21",
    supplierZip: "21140",
    supplierCountry: "TH",
  },
  {
    _id: "parts-gm-23376253-000782569",
    partNumber: "23376253",
    description: "WHEEL-17X7.0J,A",
    supplierName: "CITIC DICASTAL CO LTD",
    supplierAddress: "COLOMA,MI",
    supplierStreet: "",
    supplierCity: "COLOMA",
    supplierState: "MI",
    supplierZip: "490389471",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23376689-065052011",
    partNumber: "23376689",
    description: "TIRE",
    supplierName: "MICHELIN NORTH AMERICA INC",
    supplierAddress: "GREENVILLE,SC",
    supplierStreet: "",
    supplierCity: "GREENVILLE",
    supplierState: "SC",
    supplierZip: "296056107",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23376697-121793137",
    partNumber: "23376697",
    description: "TIRE 275/60R20",
    supplierName: "CONTINENTAL TIRE THE AMERICAS LLC",
    supplierAddress: "FORT MILL,SC",
    supplierStreet: "",
    supplierCity: "FORT MILL",
    supplierState: "SC",
    supplierZip: "297077712",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23376706-003644262",
    partNumber: "23376706",
    description: "TIRE- 265/70R17",
    supplierName: "CHENG SHIN RUBBER USA INC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750512302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23378303-545264020",
    partNumber: "23378303",
    description: "CAP ASM-FRT & R",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23378305-545264020",
    partNumber: "23378305",
    description: "CAP ASM-FRT & R",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23378306-131002078",
    partNumber: "23378306",
    description: "CAP ASM-FRT & R",
    supplierName: "ZANINI TENNESSEE INC",
    supplierAddress: "WINCHESTER,TN",
    supplierStreet: "",
    supplierCity: "WINCHESTER",
    supplierState: "TN",
    supplierZip: "373981246",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23379115-800221918",
    partNumber: "23379115",
    description: "PLUG-L/GATE ACT",
    supplierName: "PROTECTIVE INDUSTRIES INC",
    supplierAddress: "BUFFALO,NY",
    supplierStreet: "",
    supplierCity: "BUFFALO",
    supplierState: "NY",
    supplierZip: "142071910",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23380290-086663101",
    partNumber: "23380290",
    description: "PANEL-PLNM UPR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23380293-050150481",
    partNumber: "23380293",
    description: "EXTENSION-DA PN",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23380294-050150481",
    partNumber: "23380294",
    description: "EXTENSION-DA PN",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23380295-050150481",
    partNumber: "23380295",
    description: "EXTENSION-DA PN",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23380296-050150481",
    partNumber: "23380296",
    description: "EXTENSION-DA PN",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23380297-050150481",
    partNumber: "23380297",
    description: "BRACKET ASM-DA",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23380298-101908965",
    partNumber: "23380298",
    description: "BRACKET ASM-DA",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "49544",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23380300-101908965",
    partNumber: "23380300",
    description: "BRACKET ASM-DA",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "49544",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23380301-101908965",
    partNumber: "23380301",
    description: "BRACKET ASM-DA",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "49544",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23380306-812539617",
    partNumber: "23380306",
    description: "SILL-U/B #1 CR",
    supplierName: "KIRCHHOFF AUTOMOTIVE MEXICO",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23380312-812539617",
    partNumber: "23380312",
    description: "EXTENSION ASM-P",
    supplierName: "KIRCHHOFF AUTOMOTIVE MEXICO",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23380316-812765504",
    partNumber: "23380316",
    description: "BRACKET ASM-BOD",
    supplierName: "SAN LUIS DASUNG SA DE CV",
    supplierAddress: "VILLA DE REYES SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "VILLA DE REYES SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "79525",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23380435-205631570",
    partNumber: "23380435",
    description: "BRACKET-U/B #2",
    supplierName: "REA INTERNATIONAL INC",
    supplierAddress: "VAUGHAN,ON",
    supplierStreet: "",
    supplierCity: "VAUGHAN",
    supplierState: "ON",
    supplierZip: "L4L 9C7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23380445-816523799",
    partNumber: "23380445",
    description: "BAR ASM-FLR PNL",
    supplierName: "SAN LUIS METAL FORMING SA DE CV SUC",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36275",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23380446-816523799",
    partNumber: "23380446",
    description: "SILL BAR ASM U/",
    supplierName: "SAN LUIS METAL FORMING SA DE CV SUC",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36275",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23382215-000236455",
    partNumber: "23382215",
    description: "MODULE ASM-F/PM",
    supplierName: "NIDEC MOBILITY AMERICA CORP",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36270",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23384819-078852206",
    partNumber: "23384819",
    description: "BAFFLE ASM-RR S",
    supplierName: "NITTO AUTOMOTIVE INC",
    supplierAddress: "RIVERSIDE,MO",
    supplierStreet: "",
    supplierCity: "RIVERSIDE",
    supplierState: "MO",
    supplierZip: "641509539",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23384820-078852206",
    partNumber: "23384820",
    description: "BAFFLE ASM-RR S",
    supplierName: "NITTO AUTOMOTIVE INC",
    supplierAddress: "RIVERSIDE,MO",
    supplierStreet: "",
    supplierCity: "RIVERSIDE",
    supplierState: "MO",
    supplierZip: "641509539",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23384823-078852206",
    partNumber: "23384823",
    description: "BAFFLE ASM-RR S",
    supplierName: "NITTO AUTOMOTIVE INC",
    supplierAddress: "RIVERSIDE,MO",
    supplierStreet: "",
    supplierCity: "RIVERSIDE",
    supplierState: "MO",
    supplierZip: "641509539",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23384824-078852206",
    partNumber: "23384824",
    description: "BAFFLE ASM-RR S",
    supplierName: "NITTO AUTOMOTIVE INC",
    supplierAddress: "RIVERSIDE,MO",
    supplierStreet: "",
    supplierCity: "RIVERSIDE",
    supplierState: "MO",
    supplierZip: "641509539",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23387335-812895704",
    partNumber: "23387335",
    description: "DEFLECTOR-I/P K",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23388784-050614114",
    partNumber: "23388784",
    description: "HANGER ASM-EXH",
    supplierName: "AIRBOSS FLEXIBLE PRODUCTS CO",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23388785-050614114",
    partNumber: "23388785",
    description: "HANGER ASM-EXH",
    supplierName: "AIRBOSS FLEXIBLE PRODUCTS CO",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23389705-969789283",
    partNumber: "23389705",
    description: "DEFLECTOR ASM-A",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23390126-018422779",
    partNumber: "23390126",
    description: "HOSE ASM-A/C CM",
    supplierName: "CONTITECH NORTH AMERICA INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785038924",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23390358-021093638",
    partNumber: "23390358",
    description: "SHIELD-FRT BRK",
    supplierName: "BOPP-BUSCH MANUFACTURING CO INC",
    supplierAddress: "AU GRES,MI",
    supplierStreet: "",
    supplierCity: "AU GRES",
    supplierState: "MI",
    supplierZip: "487039748",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23390381-020774709",
    partNumber: "23390381",
    description: "SEAL-FRT WHL HU",
    supplierName: "UCHIYAMA MANUFACTURING AMERICA LLC",
    supplierAddress: "GOLDSBORO,NC",
    supplierStreet: "",
    supplierCity: "GOLDSBORO",
    supplierState: "NC",
    supplierZip: "275308538",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23391143-812416683",
    partNumber: "23391143",
    description: "DUCT ASM-FLR FR",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23392756-021093638",
    partNumber: "23392756",
    description: "BRACKET-TRANS F",
    supplierName: "BOPP-BUSCH MANUFACTURING CO INC",
    supplierAddress: "AU GRES,MI",
    supplierStreet: "",
    supplierCity: "AU GRES",
    supplierState: "MI",
    supplierZip: "487039748",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23393173-006016810",
    partNumber: "23393173",
    description: "LABEL-VEH EMIS",
    supplierName: "GRAND RAPIDS LABEL CO",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "49505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23393219-005509385",
    partNumber: "23393219",
    description: "LABEL-PRICE & F",
    supplierName: "WHITLAM GROUP INC",
    supplierAddress: "CENTER LINE,MI",
    supplierStreet: "",
    supplierCity: "CENTER LINE",
    supplierState: "MI",
    supplierZip: "480151059",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23393996-812765504",
    partNumber: "23393996",
    description: "BRACKET-HTR WAT",
    supplierName: "SAN LUIS DASUNG SA DE CV",
    supplierAddress: "VILLA DE REYES SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "VILLA DE REYES SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "79525",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23393999-812765504",
    partNumber: "23393999",
    description: "BRACKET ASM-CHR",
    supplierName: "SAN LUIS DASUNG SA DE CV",
    supplierAddress: "VILLA DE REYES SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "VILLA DE REYES SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "79525",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23394012-812435311",
    partNumber: "23394012",
    description: "TANK ASM-SPCHG",
    supplierName: "NOVARES MEXICO NORTH S DE RL DE CV",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31109",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23395749-000187815",
    partNumber: "23395749",
    description: "COMPRESSOR ASM-",
    supplierName: "DENSO INTERNATIONAL AMERICA",
    supplierAddress: "PARMA,MI",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "MI",
    supplierZip: "49269",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23396435-254039282",
    partNumber: "23396435",
    description: "HINGE ASM-RR S/",
    supplierName: "MULTIMATIC INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4Y9",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23396436-254039282",
    partNumber: "23396436",
    description: "HINGE ASM-RR S/",
    supplierName: "MULTIMATIC INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4Y9",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23396437-254039282",
    partNumber: "23396437",
    description: "HINGE ASM-RR S/",
    supplierName: "MULTIMATIC INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4Y9",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23396438-254039282",
    partNumber: "23396438",
    description: "HINGE ASM-RR S/",
    supplierName: "MULTIMATIC INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4Y9",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23398605-812539617",
    partNumber: "23398605",
    description: "EXTENSION-PLNM",
    supplierName: "KIRCHHOFF AUTOMOTIVE MEXICO",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23398606-812539617",
    partNumber: "23398606",
    description: "EXTENSION-PLNM",
    supplierName: "KIRCHHOFF AUTOMOTIVE MEXICO",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23400140-050150481",
    partNumber: "23400140",
    description: "BRACKET-DA PNL",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23400141-050150481",
    partNumber: "23400141",
    description: "BRACKET-DA PNL",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23400376-654593565",
    partNumber: "23400376",
    description: "PLATE ASM-FRT S",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23400413-545237257",
    partNumber: "23400413",
    description: "PLATE ASM-E/GAT",
    supplierName: "SRG GLOBAL (SUZHOU) CO LTD",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215021",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23400414-688379886",
    partNumber: "23400414",
    description: "PLATE ASM-E/GAT",
    supplierName: "SAMSHIN CHEMICAL CO LTD",
    supplierAddress: "ANSAN,41",
    supplierStreet: "",
    supplierCity: "ANSAN",
    supplierState: "41",
    supplierZip: "15606",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-23407003-018422779",
    partNumber: "23407003",
    description: "EXCHANGER ASM-A",
    supplierName: "CONTITECH NORTH AMERICA INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785038924",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23407004-965355311",
    partNumber: "23407004",
    description: "HOSE ASM-AUX A/",
    supplierName: "HANON SYSTEMS NETHERLANDS COOP UA",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799360405",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23407005-965355311",
    partNumber: "23407005",
    description: "HOSE ASM-AUX A/",
    supplierName: "HANON SYSTEMS NETHERLANDS COOP UA",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799360405",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23407367-812548816",
    partNumber: "23407367",
    description: "BRACKET ASM-HEA",
    supplierName: "FLEX-N-GATE MEXICO S DE RL DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23408613-078849132",
    partNumber: "23408613",
    description: "BRACKET ASM-RDO",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23408755-184861805",
    partNumber: "23408755",
    description: "BRACKET ASM-FRT",
    supplierName: "MOHR ENGINEERING INC",
    supplierAddress: "BRIGHTON,MI",
    supplierStreet: "",
    supplierCity: "BRIGHTON",
    supplierState: "MI",
    supplierZip: "481162227",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23409061-012839643",
    partNumber: "23409061",
    description: "CONDENSER ASM-A",
    supplierName: "GM COMPONENT HOLDINGS LLC-LKPT PTC",
    supplierAddress: "LOCKPORT,NY",
    supplierStreet: "",
    supplierCity: "LOCKPORT",
    supplierState: "NY",
    supplierZip: "140941819",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23409063-012839643",
    partNumber: "23409063",
    description: "CONDENSER ASM-A",
    supplierName: "GM COMPONENT HOLDINGS LLC-LKPT PTC",
    supplierAddress: "LOCKPORT,NY",
    supplierStreet: "",
    supplierCity: "LOCKPORT",
    supplierState: "NY",
    supplierZip: "140941819",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23409525-000145425",
    partNumber: "23409525",
    description: "CROSSMEMBER ASM",
    supplierName: "NORTHERN STAMPING",
    supplierAddress: "CLEVELAND,OH",
    supplierStreet: "",
    supplierCity: "CLEVELAND",
    supplierState: "OH",
    supplierZip: "44111",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23410847-827836214",
    partNumber: "23410847",
    description: "SENSOR ASM-W/S",
    supplierName: "HELLA ELECTRONICS CORP",
    supplierAddress: "FLORA,IL",
    supplierStreet: "",
    supplierCity: "FLORA",
    supplierState: "IL",
    supplierZip: "628393440",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23413051-078852206",
    partNumber: "23413051",
    description: "BAFFLE ASM-FRT",
    supplierName: "NITTO AUTOMOTIVE INC",
    supplierAddress: "RIVERSIDE,MO",
    supplierStreet: "",
    supplierCity: "RIVERSIDE",
    supplierState: "MO",
    supplierZip: "641509539",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23413052-078852206",
    partNumber: "23413052",
    description: "BAFFLE ASM-FRT",
    supplierName: "NITTO AUTOMOTIVE INC",
    supplierAddress: "RIVERSIDE,MO",
    supplierStreet: "",
    supplierCity: "RIVERSIDE",
    supplierState: "MO",
    supplierZip: "641509539",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23413053-078852206",
    partNumber: "23413053",
    description: "BAFFLE ASM-RR S",
    supplierName: "NITTO AUTOMOTIVE INC",
    supplierAddress: "RIVERSIDE,MO",
    supplierStreet: "",
    supplierCity: "RIVERSIDE",
    supplierState: "MO",
    supplierZip: "641509539",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23413054-078852206",
    partNumber: "23413054",
    description: "BAFFLE ASM-RR S",
    supplierName: "NITTO AUTOMOTIVE INC",
    supplierAddress: "RIVERSIDE,MO",
    supplierStreet: "",
    supplierCity: "RIVERSIDE",
    supplierState: "MO",
    supplierZip: "641509539",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23413611-042439265",
    partNumber: "23413611",
    description: "PLATE-RR S/D H",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23423785-038709341",
    partNumber: "23423785",
    description: "BATTERY ASM",
    supplierName: "CLARIOS LLC",
    supplierAddress: "HOLLAND,OH",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "OH",
    supplierZip: "435289791",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23423786-038709341",
    partNumber: "23423786",
    description: "BATTERY AS",
    supplierName: "CLARIOS LLC",
    supplierAddress: "HOLLAND,OH",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "OH",
    supplierZip: "435289791",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23423787-038709341",
    partNumber: "23423787",
    description: "BATTERY AS",
    supplierName: "CLARIOS LLC",
    supplierAddress: "HOLLAND,OH",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "OH",
    supplierZip: "435289791",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23425528-240929703",
    partNumber: "23425528",
    description: "REINFORCEMENT-R",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 5P1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23425529-240929703",
    partNumber: "23425529",
    description: "REINFORCEMENT-R",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 5P1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23431458-202679841",
    partNumber: "23431458",
    description: "KNOB ASM-RR S/D",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "PENETANGUISHENE,ON",
    supplierStreet: "",
    supplierCity: "PENETANGUISHENE",
    supplierState: "ON",
    supplierZip: "L9M 1G8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23431462-202679841",
    partNumber: "23431462",
    description: "KNOB ASM-RR S/D",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "PENETANGUISHENE,ON",
    supplierStreet: "",
    supplierCity: "PENETANGUISHENE",
    supplierState: "ON",
    supplierZip: "L9M 1G8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23431475-202679841",
    partNumber: "23431475",
    description: "KNOB ASM-RR S/D",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "PENETANGUISHENE,ON",
    supplierStreet: "",
    supplierCity: "PENETANGUISHENE",
    supplierState: "ON",
    supplierZip: "L9M 1G8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23431477-202679841",
    partNumber: "23431477",
    description: "KNOB ASM-RR S/D",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "PENETANGUISHENE,ON",
    supplierStreet: "",
    supplierCity: "PENETANGUISHENE",
    supplierState: "ON",
    supplierZip: "L9M 1G8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23431481-202679841",
    partNumber: "23431481",
    description: "KNOB ASM-RR S/D",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "PENETANGUISHENE,ON",
    supplierStreet: "",
    supplierCity: "PENETANGUISHENE",
    supplierState: "ON",
    supplierZip: "L9M 1G8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23433416-812416683",
    partNumber: "23433416",
    description: "MOLDING ASM-FRT",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23433433-812416683",
    partNumber: "23433433",
    description: "MOLDING ASM-FRT",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23433581-812416683",
    partNumber: "23433581",
    description: "MOLDING ASM-FRT",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23433582-812416683",
    partNumber: "23433582",
    description: "MOLDING ASM-FRT",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23436893-021093638",
    partNumber: "23436893",
    description: "SHIELD-FRT BRK",
    supplierName: "BOPP-BUSCH MANUFACTURING CO INC",
    supplierAddress: "AU GRES,MI",
    supplierStreet: "",
    supplierCity: "AU GRES",
    supplierState: "MI",
    supplierZip: "487039748",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23438109-042439265",
    partNumber: "23438109",
    description: "BRACKET-A/TRNS",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23440294-654593565",
    partNumber: "23440294",
    description: "PLATE ASM-L/GAT",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23447342-969789283",
    partNumber: "23447342",
    description: "SHARK FIN FL",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23454229-022687246",
    partNumber: "23454229",
    description: "SENSOR ASM-L/GA",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23454230-022687246",
    partNumber: "23454230",
    description: "SENSOR ASM-L/GA",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23454604-969789283",
    partNumber: "23454604",
    description: "PANEL ASM-AIR I",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23454605-969789283",
    partNumber: "23454605",
    description: "PANEL ASM-AIR I",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23454606-969789283",
    partNumber: "23454606",
    description: "PANEL ASM-AIR I",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23455219-240929703",
    partNumber: "23455219",
    description: "BRACKET ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 5P1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23455220-240929703",
    partNumber: "23455220",
    description: "BRACKET ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 5P1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23455221-240929703",
    partNumber: "23455221",
    description: "BRACKET ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 5P1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23455224-240929703",
    partNumber: "23455224",
    description: "BRACKET ASM-FRT",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 5P1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23455370-816473789",
    partNumber: "23455370",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE MEXICO SA DE CV",
    supplierAddress: "TORREON,CZ",
    supplierStreet: "",
    supplierCity: "TORREON",
    supplierState: "CZ",
    supplierZip: "27019",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23455371-816473789",
    partNumber: "23455371",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE MEXICO SA DE CV",
    supplierAddress: "TORREON,CZ",
    supplierStreet: "",
    supplierCity: "TORREON",
    supplierState: "CZ",
    supplierZip: "27019",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23455372-816473789",
    partNumber: "23455372",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE MEXICO SA DE CV",
    supplierAddress: "TORREON,CZ",
    supplierStreet: "",
    supplierCity: "TORREON",
    supplierState: "CZ",
    supplierZip: "27019",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23463924-969789283",
    partNumber: "23463924",
    description: "PROTECTOR ASM-F",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23463925-969789283",
    partNumber: "23463925",
    description: "PROTECTOR ASM-F",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23469339-000243147",
    partNumber: "23469339",
    description: "SUPPLEMENT-OWNE",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23469850-050614114",
    partNumber: "23469850",
    description: "INSULATOR-EXH S",
    supplierName: "AIRBOSS FLEXIBLE PRODUCTS CO",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23470084-800786642",
    partNumber: "23470084",
    description: "BAFFLE ASM-QTR",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23477220-189658198",
    partNumber: "23477220",
    description: "BAFFLE ASM-BODY",
    supplierName: "SIKA CORP",
    supplierAddress: "GRANDVIEW,MO",
    supplierStreet: "",
    supplierCity: "GRANDVIEW",
    supplierState: "MO",
    supplierZip: "64030",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23477221-189658198",
    partNumber: "23477221",
    description: "BAFFLE ASM-BODY",
    supplierName: "SIKA CORP",
    supplierAddress: "GRANDVIEW,MO",
    supplierStreet: "",
    supplierCity: "GRANDVIEW",
    supplierState: "MO",
    supplierZip: "64030",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23477580-077472228",
    partNumber: "23477580",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23477581-077472228",
    partNumber: "23477581",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23482522-099051716",
    partNumber: "23482522",
    description: "RETAINER-BK BOD",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23482523-099051716",
    partNumber: "23482523",
    description: "RETAINER-BK BOD",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23486744-932662133",
    partNumber: "23486744",
    description: "ZPLATE ASM-I/P",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23489434-243459851",
    partNumber: "23489434",
    description: "BRACKET ASM-KEY",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23494834-545288953",
    partNumber: "23494834",
    description: "BUMPER-HOOD FRT",
    supplierName: "QINGDAO DONGMING AUTOFITTING CO LTD",
    supplierAddress: "QINGDAO,37",
    supplierStreet: "",
    supplierCity: "QINGDAO",
    supplierState: "37",
    supplierZip: "266706",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23496502-009971289",
    partNumber: "23496502",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23496503-009971289",
    partNumber: "23496503",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23497087-144331493",
    partNumber: "23497087",
    description: "REINFORCEMENT-R",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23497088-144331493",
    partNumber: "23497088",
    description: "REINFORCEMENT-R",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23497089-086663101",
    partNumber: "23497089",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23497090-086663101",
    partNumber: "23497090",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23499914-969789283",
    partNumber: "23499914",
    description: "EXTENSION-AIR I",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23499915-969789283",
    partNumber: "23499915",
    description: "EXTENSION-AIR I",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23505783-621632046",
    partNumber: "23505783",
    description: "DEFLECTOR ASM-A",
    supplierName: "CADILLAC PRODUCTS INC",
    supplierAddress: "SCHERTZ,TX",
    supplierStreet: "",
    supplierCity: "SCHERTZ",
    supplierState: "TX",
    supplierZip: "78154",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23505784-621632046",
    partNumber: "23505784",
    description: "DEFLECTOR ASM-A",
    supplierName: "CADILLAC PRODUCTS INC",
    supplierAddress: "SCHERTZ,TX",
    supplierStreet: "",
    supplierCity: "SCHERTZ",
    supplierState: "TX",
    supplierZip: "78154",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23505785-621632046",
    partNumber: "23505785",
    description: "DEFLECTOR ASM-A",
    supplierName: "CADILLAC PRODUCTS INC",
    supplierAddress: "SCHERTZ,TX",
    supplierStreet: "",
    supplierCity: "SCHERTZ",
    supplierState: "TX",
    supplierZip: "78154",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23505786-621632046",
    partNumber: "23505786",
    description: "DEFLECTOR ASM-A",
    supplierName: "CADILLAC PRODUCTS INC",
    supplierAddress: "SCHERTZ,TX",
    supplierStreet: "",
    supplierCity: "SCHERTZ",
    supplierState: "TX",
    supplierZip: "78154",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23505787-621632046",
    partNumber: "23505787",
    description: "DEFLECTOR ASM-F",
    supplierName: "CADILLAC PRODUCTS INC",
    supplierAddress: "SCHERTZ,TX",
    supplierStreet: "",
    supplierCity: "SCHERTZ",
    supplierState: "TX",
    supplierZip: "78154",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23505788-621632046",
    partNumber: "23505788",
    description: "DEFLECTOR ASM-F",
    supplierName: "CADILLAC PRODUCTS INC",
    supplierAddress: "SCHERTZ,TX",
    supplierStreet: "",
    supplierCity: "SCHERTZ",
    supplierState: "TX",
    supplierZip: "78154",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23506061-202679841",
    partNumber: "23506061",
    description: "ROD-FRT S/D LK",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "PENETANGUISHENE,ON",
    supplierStreet: "",
    supplierCity: "PENETANGUISHENE",
    supplierState: "ON",
    supplierZip: "L9M 1G8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-23506094-544925969",
    partNumber: "23506094",
    description: "CABLE ASM-FRT S",
    supplierName: "L&C CHANGSHA CABLE INDUSTRIES LTD",
    supplierAddress: "CHANGSHA,43",
    supplierStreet: "",
    supplierCity: "CHANGSHA",
    supplierState: "43",
    supplierZip: "410119",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23506095-544925969",
    partNumber: "23506095",
    description: "CABLE ASM-FRT S",
    supplierName: "L&C CHANGSHA CABLE INDUSTRIES LTD",
    supplierAddress: "CHANGSHA,43",
    supplierStreet: "",
    supplierCity: "CHANGSHA",
    supplierState: "43",
    supplierZip: "410119",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23506106-544925969",
    partNumber: "23506106",
    description: "CABLE ASM-FRT S",
    supplierName: "L&C CHANGSHA CABLE INDUSTRIES LTD",
    supplierAddress: "CHANGSHA,43",
    supplierStreet: "",
    supplierCity: "CHANGSHA",
    supplierState: "43",
    supplierZip: "410119",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23506174-544925969",
    partNumber: "23506174",
    description: "CABLE ASM-RR S/",
    supplierName: "L&C CHANGSHA CABLE INDUSTRIES LTD",
    supplierAddress: "CHANGSHA,43",
    supplierStreet: "",
    supplierCity: "CHANGSHA",
    supplierState: "43",
    supplierZip: "410119",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23506175-544925969",
    partNumber: "23506175",
    description: "CABLE ASM-RR S/",
    supplierName: "L&C CHANGSHA CABLE INDUSTRIES LTD",
    supplierAddress: "CHANGSHA,43",
    supplierStreet: "",
    supplierCity: "CHANGSHA",
    supplierState: "43",
    supplierZip: "410119",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23506187-544925969",
    partNumber: "23506187",
    description: "CABLE ASM-RR S/",
    supplierName: "L&C CHANGSHA CABLE INDUSTRIES LTD",
    supplierAddress: "CHANGSHA,43",
    supplierStreet: "",
    supplierCity: "CHANGSHA",
    supplierState: "43",
    supplierZip: "410119",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23506190-544925969",
    partNumber: "23506190",
    description: "CABLE ASM-RR S/",
    supplierName: "L&C CHANGSHA CABLE INDUSTRIES LTD",
    supplierAddress: "CHANGSHA,43",
    supplierStreet: "",
    supplierCity: "CHANGSHA",
    supplierState: "43",
    supplierZip: "410119",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-23506679-800786642",
    partNumber: "23506679",
    description: "SEALER-BODY SI",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23506695-800786642",
    partNumber: "23506695",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23508180-065052011",
    partNumber: "23508180",
    description: "TIRE-265/65R18",
    supplierName: "MICHELIN NORTH AMERICA INC",
    supplierAddress: "GREENVILLE,SC",
    supplierStreet: "",
    supplierCity: "GREENVILLE",
    supplierState: "SC",
    supplierZip: "296056107",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-23509123-815047527",
    partNumber: "23509123",
    description: "BRACE-F/CMPT UP",
    supplierName: "MARTINREA DEVELOPMENTS DE MEXICO SA",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36275",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-23509124-815047527",
    partNumber: "23509124",
    description: "BRACE-F/CMPT UP",
    supplierName: "MARTINREA DEVELOPMENTS DE MEXICO SA",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36275",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-24000169-043352038",
    partNumber: "24000169",
    description: "COVER ASM-INT M",
    supplierName: "GRAND TRAVERSE PLASTICS CORP",
    supplierAddress: "WILLIAMSBURG,MI",
    supplierStreet: "",
    supplierCity: "WILLIAMSBURG",
    supplierState: "MI",
    supplierZip: "496909741",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-24000178-116833270",
    partNumber: "24000178",
    description: "MODULE ASM-ENG",
    supplierName: "VITESCO TECHNOLOGIES USA LLC",
    supplierAddress: "SEGUIN,TX",
    supplierStreet: "",
    supplierCity: "SEGUIN",
    supplierState: "TX",
    supplierZip: "781557359",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-24000464-340806311",
    partNumber: "24000464",
    description: "SENSOR ASM-NOX",
    supplierName: "CONTINENTAL AUTOMOTIVE GMBH",
    supplierAddress: "REGENSBURG,BY",
    supplierStreet: "",
    supplierCity: "REGENSBURG",
    supplierState: "BY",
    supplierZip: "93055",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-24001923-116833270",
    partNumber: "24001923",
    description: "MODULE ASM-ENG",
    supplierName: "VITESCO TECHNOLOGIES USA LLC",
    supplierAddress: "SEGUIN,TX",
    supplierStreet: "",
    supplierCity: "SEGUIN",
    supplierState: "TX",
    supplierZip: "781557359",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-24041480-812246809",
    partNumber: "24041480",
    description: "HOSE ASM-TRANS",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-24043406-196138536",
    partNumber: "24043406",
    description: "MODULE ASM-TRAN",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-24044454-000257527",
    partNumber: "24044454",
    description: "TRANSMN-1CPJ",
    supplierName: "GENERAL MOTORS DE MEX S DE RL DE CV",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36100",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-24044455-000257527",
    partNumber: "24044455",
    description: "TRANSMN-1CQJ",
    supplierName: "GENERAL MOTORS DE MEX S DE RL DE CV",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36100",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-24045232-000257527",
    partNumber: "24045232",
    description: "TRANSMISSION AS",
    supplierName: "GENERAL MOTORS DE MEX S DE RL DE CV",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36100",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-24045233-000257527",
    partNumber: "24045233",
    description: "TRANS-1C4J",
    supplierName: "GENERAL MOTORS DE MEX S DE RL DE CV",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36100",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-24205899-796672926",
    partNumber: "24205899",
    description: "A/HOLE=PLUG",
    supplierName: "BURNSIDE ACQUISITION LLC",
    supplierAddress: "NORTON SHORES,MI",
    supplierStreet: "",
    supplierCity: "NORTON SHORES",
    supplierState: "MI",
    supplierZip: "494569616",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-24205900-796672926",
    partNumber: "24205900",
    description: "COVER",
    supplierName: "BURNSIDE ACQUISITION LLC",
    supplierAddress: "NORTON SHORES,MI",
    supplierStreet: "",
    supplierCity: "NORTON SHORES",
    supplierState: "MI",
    supplierZip: "494569616",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-24245110-006414353",
    partNumber: "24245110",
    description: "GASKET",
    supplierName: "HOOSIER GASKET CORP",
    supplierAddress: "INDIANAPOLIS,IN",
    supplierStreet: "",
    supplierCity: "INDIANAPOLIS",
    supplierState: "IN",
    supplierZip: "46218",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-24263130-043352038",
    partNumber: "24263130",
    description: "COVER-TRANS",
    supplierName: "GRAND TRAVERSE PLASTICS CORP",
    supplierAddress: "WILLIAMSBURG,MI",
    supplierStreet: "",
    supplierCity: "WILLIAMSBURG",
    supplierState: "MI",
    supplierZip: "496909741",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-24265164-931588177",
    partNumber: "24265164",
    description: "BRACKET-TRANS V",
    supplierName: "SHILOH MANUFACTURING LLC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480894162",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-24268098-812246809",
    partNumber: "24268098",
    description: "HOSE ASM-TRANS",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-24278745-000257527",
    partNumber: "24278745",
    description: "TRANSMN-1CCJ AS",
    supplierName: "GENERAL MOTORS DE MEX S DE RL DE CV",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36100",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-24278746-000257527",
    partNumber: "24278746",
    description: "TRANSMN-1CKJ AS",
    supplierName: "GENERAL MOTORS DE MEX S DE RL DE CV",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36100",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-24279367-136995656",
    partNumber: "24279367",
    description: "HOSE ASM-A/TRNS",
    supplierName: "ZHONGDING USA INC",
    supplierAddress: "MONROE,MI",
    supplierStreet: "",
    supplierCity: "MONROE",
    supplierState: "MI",
    supplierZip: "481622783",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-24281530-000257527",
    partNumber: "24281530",
    description: "TRANSMN-1CMJ",
    supplierName: "GENERAL MOTORS DE MEX S DE RL DE CV",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36100",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-24281531-000257527",
    partNumber: "24281531",
    description: "TRANSMN-1CNJ",
    supplierName: "GENERAL MOTORS DE MEX S DE RL DE CV",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36100",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-24282999-043352038",
    partNumber: "24282999",
    description: "COVER-TRANS",
    supplierName: "GRAND TRAVERSE PLASTICS CORP",
    supplierAddress: "WILLIAMSBURG,MI",
    supplierStreet: "",
    supplierCity: "WILLIAMSBURG",
    supplierState: "MI",
    supplierZip: "496909741",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-24283000-043352038",
    partNumber: "24283000",
    description: "COVER-TRANS",
    supplierName: "GRAND TRAVERSE PLASTICS CORP",
    supplierAddress: "WILLIAMSBURG,MI",
    supplierStreet: "",
    supplierCity: "WILLIAMSBURG",
    supplierState: "MI",
    supplierZip: "496909741",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-24284765-043352038",
    partNumber: "24284765",
    description: "COVER-TRANS",
    supplierName: "GRAND TRAVERSE PLASTICS CORP",
    supplierAddress: "WILLIAMSBURG,MI",
    supplierStreet: "",
    supplierCity: "WILLIAMSBURG",
    supplierState: "MI",
    supplierZip: "496909741",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-24288515-812246809",
    partNumber: "24288515",
    description: "HOSE ASM-TRANS",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-24290250-812246809",
    partNumber: "24290250",
    description: "HOSE ASM-TRANS",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-24293211-961973638",
    partNumber: "24293211",
    description: "SHIELD ASM-TRAN",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-24299558-196138536",
    partNumber: "24299558",
    description: "MODULE ASM-TRAN",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-25652432-796778889",
    partNumber: "25652432",
    description: "HOLE=PLUG",
    supplierName: "PROTECTIVE INDUSTRIES INC",
    supplierAddress: "ERIE,PA",
    supplierStreet: "",
    supplierCity: "ERIE",
    supplierState: "PA",
    supplierZip: "165094447",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-25776667-829470223",
    partNumber: "25776667",
    description: "RECEPTACLE ASM-",
    supplierName: "CASCO PRODUCTS CORP",
    supplierAddress: "MORGANTOWN,KY",
    supplierStreet: "",
    supplierCity: "MORGANTOWN",
    supplierState: "KY",
    supplierZip: "422617361",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-25777560-078852206",
    partNumber: "25777560",
    description: "DEADENER-FRT S/",
    supplierName: "NITTO AUTOMOTIVE INC",
    supplierAddress: "RIVERSIDE,MO",
    supplierStreet: "",
    supplierCity: "RIVERSIDE",
    supplierState: "MO",
    supplierZip: "641509539",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-25808256-933877334",
    partNumber: "25808256",
    description: "LABEL",
    supplierName: "CCL LABEL INC",
    supplierAddress: "STRONGSVILLE,OH",
    supplierStreet: "",
    supplierCity: "STRONGSVILLE",
    supplierState: "OH",
    supplierZip: "441495536",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-25832258-099051716",
    partNumber: "25832258",
    description: "NUT-RR LIC PLT",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-25872391-784440104",
    partNumber: "25872391",
    description: "CAP ASM",
    supplierName: "VPI ACQUISITION CORP",
    supplierAddress: "CORRY,PA",
    supplierStreet: "",
    supplierCity: "CORRY",
    supplierState: "PA",
    supplierZip: "164072079",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-25883487-796778889",
    partNumber: "25883487",
    description: "PLUG-BODY H/PLR",
    supplierName: "PROTECTIVE INDUSTRIES INC",
    supplierAddress: "ERIE,PA",
    supplierStreet: "",
    supplierCity: "ERIE",
    supplierState: "PA",
    supplierZip: "165094447",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-25888200-099051716",
    partNumber: "25888200",
    description: "PLUG-E/GATE DRN",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-25915587-049816044",
    partNumber: "25915587",
    description: "PLUG ASM-DA HOL",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "FRANKFORT,IL",
    supplierStreet: "",
    supplierCity: "FRANKFORT",
    supplierState: "IL",
    supplierZip: "604236017",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-25980339-050150481",
    partNumber: "25980339",
    description: "SHIELD-TRANS HT",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-39031259-315012963",
    partNumber: "39031259",
    description: "STUD-BODY H/PLR",
    supplierName: "NEDSCHROEF FRAULAUTERN GMBH",
    supplierAddress: "SAARLOUIS,SL",
    supplierStreet: "",
    supplierCity: "SAARLOUIS",
    supplierState: "SL",
    supplierZip: "66740",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-39076927-243459851",
    partNumber: "39076927",
    description: "GRILLE-ACTIVE N",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-39119104-432578912",
    partNumber: "39119104",
    description: "ALARM ASM-THEFT",
    supplierName: "VODAFONE AUTOMOTIVE SPA",
    supplierAddress: "VARESE,VA",
    supplierStreet: "",
    supplierCity: "VARESE",
    supplierState: "VA",
    supplierZip: "21100",
    supplierCountry: "IT",
  },
  {
    _id: "parts-gm-39239420-006016810",
    partNumber: "39239420",
    description: "LABEL-TIRE COMP",
    supplierName: "GRAND RAPIDS LABEL CO",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "49505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-55490609-812619245",
    partNumber: "55490609",
    description: "TENSIONER ASM-D",
    supplierName: "MUBEA DE MEXICO S DE RL DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-55490626-644777716",
    partNumber: "55490626",
    description: "PIPE-EGR",
    supplierName: "WITZENMANN OPAVA SPOL SRO",
    supplierAddress: "OPAVA,CZ",
    supplierStreet: "",
    supplierCity: "OPAVA",
    supplierState: "CZ",
    supplierZip: "746 01",
    supplierCountry: "CZ",
  },
  {
    _id: "parts-gm-55491112-878516160",
    partNumber: "55491112",
    description: "SENSOR ASM-EXH",
    supplierName: "NGK SPARK PLUGS USA INC",
    supplierAddress: "SISSONVILLE,WV",
    supplierStreet: "",
    supplierCity: "SISSONVILLE",
    supplierState: "WV",
    supplierZip: "253209546",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-55492188-878516160",
    partNumber: "55492188",
    description: "SENSOR ASM-EXH",
    supplierName: "NGK SPARK PLUGS USA INC",
    supplierAddress: "SISSONVILLE,WV",
    supplierStreet: "",
    supplierCity: "SISSONVILLE",
    supplierState: "WV",
    supplierZip: "253209546",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-55492649-545270555",
    partNumber: "55492649",
    description: "PIPE ASM-TURBO",
    supplierName: "DYNAMIC TECHNOLOGIES CHINA LTD",
    supplierAddress: "CHANGZHOU,32",
    supplierStreet: "",
    supplierCity: "CHANGZHOU",
    supplierState: "32",
    supplierZip: "213031",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-55496412-961973638",
    partNumber: "55496412",
    description: "SHIELD-STRTR HT",
    supplierName: "LYDALL THERMAL/ACOUSTICAL INC",
    supplierAddress: "YADKINVILLE,NC",
    supplierStreet: "",
    supplierCity: "YADKINVILLE",
    supplierState: "NC",
    supplierZip: "27055",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-55496727-315025692",
    partNumber: "55496727",
    description: "PIPE ASM-EGR",
    supplierName: "VERITAS AG",
    supplierAddress: "GELNHAUSEN,HE",
    supplierStreet: "",
    supplierCity: "GELNHAUSEN",
    supplierState: "HE",
    supplierZip: "63571",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-55496882-043352038",
    partNumber: "55496882",
    description: "TUBE ASM-OIL FI",
    supplierName: "GRAND TRAVERSE PLASTICS CORP",
    supplierAddress: "WILLIAMSBURG,MI",
    supplierStreet: "",
    supplierCity: "WILLIAMSBURG",
    supplierState: "MI",
    supplierZip: "496909741",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-55496942-812246809",
    partNumber: "55496942",
    description: "PIPE ASM-EXH PR",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-55496995-000240150",
    partNumber: "55496995",
    description: "GASKET-EGR CLR",
    supplierName: "DANA SEALING MANUFACTURING LLC",
    supplierAddress: "DANVILLE,KY",
    supplierStreet: "",
    supplierCity: "DANVILLE",
    supplierState: "KY",
    supplierZip: "404229499",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-55496996-813193786",
    partNumber: "55496996",
    description: "GASKET-EGR CLR",
    supplierName: "BORGWARNER TURBO & EMISSIONS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25903",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-55497097-545270555",
    partNumber: "55497097",
    description: "HOSE-THERM BYPA",
    supplierName: "DYNAMIC TECHNOLOGIES CHINA LTD",
    supplierAddress: "CHANGZHOU,32",
    supplierStreet: "",
    supplierCity: "CHANGZHOU",
    supplierState: "32",
    supplierZip: "213031",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-55497103-812246809",
    partNumber: "55497103",
    description: "PIPE ASM-EGR CL",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-55497227-545270555",
    partNumber: "55497227",
    description: "HOSE ASM-EGR VL",
    supplierName: "DYNAMIC TECHNOLOGIES CHINA LTD",
    supplierAddress: "CHANGZHOU,32",
    supplierStreet: "",
    supplierCity: "CHANGZHOU",
    supplierState: "32",
    supplierZip: "213031",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-55497359-812246809",
    partNumber: "55497359",
    description: "PIPE ASM-EGR CL",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-55500388-812619245",
    partNumber: "55500388",
    description: "PULLEY ASM-BELT",
    supplierName: "MUBEA DE MEXICO S DE RL DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-55503171-291158210",
    partNumber: "55503171",
    description: "CLAMP ASM-CTLTC",
    supplierName: "NORMA UK LTD",
    supplierAddress: "NEWBURY BERKSHIRE,BK",
    supplierStreet: "",
    supplierCity: "NEWBURY BERKSHIRE",
    supplierState: "BK",
    supplierZip: "RG19 6HW",
    supplierCountry: "GB",
  },
  {
    _id: "parts-gm-55503873-070628511",
    partNumber: "55503873",
    description: "ENGINEDSL-CVC",
    supplierName: "GM POWERTRAIN-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "48502",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-55505907-043352038",
    partNumber: "55505907",
    description: "BRACKET ASM-UPR",
    supplierName: "GRAND TRAVERSE PLASTICS CORP",
    supplierAddress: "WILLIAMSBURG,MI",
    supplierStreet: "",
    supplierCity: "WILLIAMSBURG",
    supplierState: "MI",
    supplierZip: "496909741",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-55506078-042439265",
    partNumber: "55506078",
    description: "BRACKET-TMG IND",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-55506825-315029009",
    partNumber: "55506825",
    description: "CLAMP-EMIS RDCN",
    supplierName: "NORMA GERMANY GMBH",
    supplierAddress: "MAINTAL,HE",
    supplierStreet: "",
    supplierCity: "MAINTAL",
    supplierState: "HE",
    supplierZip: "63477",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-55507643-545275919",
    partNumber: "55507643",
    description: "BRACKET-GEN & D",
    supplierName: "DONGGUAN MODERN METAL PRECISION DIE",
    supplierAddress: "DONGGUAN,44",
    supplierStreet: "",
    supplierCity: "DONGGUAN",
    supplierState: "44",
    supplierZip: "523868",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-55509156-812312411",
    partNumber: "55509156",
    description: "SHIELD-TURBO HT",
    supplierName: "MCCORD PAYEN DE",
    supplierAddress: "TEPOTZOTLAN,EM",
    supplierStreet: "",
    supplierCity: "TEPOTZOTLAN",
    supplierState: "EM",
    supplierZip: "54600",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-55509767-079777903",
    partNumber: "55509767",
    description: "INJECTOR ASM-EM",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "SUMMERVILLE,SC",
    supplierStreet: "",
    supplierCity: "SUMMERVILLE",
    supplierState: "SC",
    supplierZip: "294837415",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-55509960-544407017",
    partNumber: "55509960",
    description: "STARTER ASM-.",
    supplierName: "SEG AUTOMOTIVE PRODUCTS (CHINA) CO",
    supplierAddress: "CHANGSHA,43",
    supplierStreet: "",
    supplierCity: "CHANGSHA",
    supplierState: "43",
    supplierZip: "410129",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-55510096-878516160",
    partNumber: "55510096",
    description: "SENSOR ASM-EGR",
    supplierName: "NGK SPARK PLUGS USA INC",
    supplierAddress: "SISSONVILLE,WV",
    supplierStreet: "",
    supplierCity: "SISSONVILLE",
    supplierState: "WV",
    supplierZip: "253209546",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-55510505-545270555",
    partNumber: "55510505",
    description: "PIPE ASM-EGR CL",
    supplierName: "DYNAMIC TECHNOLOGIES CHINA LTD",
    supplierAddress: "CHANGZHOU,32",
    supplierStreet: "",
    supplierCity: "CHANGZHOU",
    supplierState: "32",
    supplierZip: "213031",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-55512356-168546252",
    partNumber: "55512356",
    description: "COVER-ENG FRT",
    supplierName: "EAGLE INDUSTRIES INC",
    supplierAddress: "WIXOM,MI",
    supplierStreet: "",
    supplierCity: "WIXOM",
    supplierState: "MI",
    supplierZip: "483932075",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-55513243-196138536",
    partNumber: "55513243",
    description: "SENSOR ASM-EXH",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-55513539-812246809",
    partNumber: "55513539",
    description: "PIPE ASM-EXH PR",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-55514599-000240150",
    partNumber: "55514599",
    description: "GASKET-EGR VLV",
    supplierName: "DANA SEALING MANUFACTURING LLC",
    supplierAddress: "DANVILLE,KY",
    supplierStreet: "",
    supplierCity: "DANVILLE",
    supplierState: "KY",
    supplierZip: "404229499",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-55514720-813193786",
    partNumber: "55514720",
    description: "COOLER ASM-EGR",
    supplierName: "BORGWARNER TURBO & EMISSIONS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25903",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-55514983-813193786",
    partNumber: "55514983",
    description: "VALVE ASM-EXH B",
    supplierName: "BORGWARNER TURBO & EMISSIONS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25903",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-55514990-644777716",
    partNumber: "55514990",
    description: "PIPE-EGR",
    supplierName: "WITZENMANN OPAVA SPOL SRO",
    supplierAddress: "OPAVA,CZ",
    supplierStreet: "",
    supplierCity: "OPAVA",
    supplierState: "CZ",
    supplierZip: "746 01",
    supplierCountry: "CZ",
  },
  {
    _id: "parts-gm-55515114-079777903",
    partNumber: "55515114",
    description: "INJECTOR ASM-EM",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "SUMMERVILLE,SC",
    supplierStreet: "",
    supplierCity: "SUMMERVILLE",
    supplierState: "SC",
    supplierZip: "294837415",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-55515472-813193786",
    partNumber: "55515472",
    description: "COOLER ASM-EGR",
    supplierName: "BORGWARNER TURBO & EMISSIONS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25903",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-55515780-812246809",
    partNumber: "55515780",
    description: "PIPE ASM-EGR CL",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-55515782-812246809",
    partNumber: "55515782",
    description: "PIPE ASM-EGR CL",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84012685-000187815",
    partNumber: "84012685",
    description: "COMPRESSOR ASM-",
    supplierName: "DENSO INTERNATIONAL AMERICA",
    supplierAddress: "PARMA,MI",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "MI",
    supplierZip: "49269",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84017677-005509385",
    partNumber: "84017677",
    description: "MATERIAL-THERMA",
    supplierName: "WHITLAM GROUP INC",
    supplierAddress: "CENTER LINE,MI",
    supplierStreet: "",
    supplierCity: "CENTER LINE",
    supplierState: "MI",
    supplierZip: "480151059",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84019237-050476923",
    partNumber: "84019237",
    description: "SPEAKER ASM-RDO",
    supplierName: "BOSE CORP",
    supplierAddress: "SAN DIEGO,CA",
    supplierStreet: "",
    supplierCity: "SAN DIEGO",
    supplierState: "CA",
    supplierZip: "921546216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84024059-197084635",
    partNumber: "84024059",
    description: "CHANNEL-FRT S/D",
    supplierName: "SHIROKI NORTH AMERICA INC",
    supplierAddress: "SMITHVILLE,TN",
    supplierStreet: "",
    supplierCity: "SMITHVILLE",
    supplierState: "TN",
    supplierZip: "371662504",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84024060-197084635",
    partNumber: "84024060",
    description: "CHANNEL-FRT S/D",
    supplierName: "SHIROKI NORTH AMERICA INC",
    supplierAddress: "SMITHVILLE,TN",
    supplierStreet: "",
    supplierCity: "SMITHVILLE",
    supplierState: "TN",
    supplierZip: "371662504",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84024061-197084635",
    partNumber: "84024061",
    description: "CHANNEL-RR S/D",
    supplierName: "SHIROKI NORTH AMERICA INC",
    supplierAddress: "SMITHVILLE,TN",
    supplierStreet: "",
    supplierCity: "SMITHVILLE",
    supplierState: "TN",
    supplierZip: "371662504",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84024062-197084635",
    partNumber: "84024062",
    description: "CHANNEL-RR S/D",
    supplierName: "SHIROKI NORTH AMERICA INC",
    supplierAddress: "SMITHVILLE,TN",
    supplierStreet: "",
    supplierCity: "SMITHVILLE",
    supplierState: "TN",
    supplierZip: "371662504",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84024063-197084635",
    partNumber: "84024063",
    description: "CHANNEL-RR S/D",
    supplierName: "SHIROKI NORTH AMERICA INC",
    supplierAddress: "SMITHVILLE,TN",
    supplierStreet: "",
    supplierCity: "SMITHVILLE",
    supplierState: "TN",
    supplierZip: "371662504",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84024064-197084635",
    partNumber: "84024064",
    description: "CHANNEL-RR S/D",
    supplierName: "SHIROKI NORTH AMERICA INC",
    supplierAddress: "SMITHVILLE,TN",
    supplierStreet: "",
    supplierCity: "SMITHVILLE",
    supplierState: "TN",
    supplierZip: "371662504",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84024307-816474175",
    partNumber: "84024307",
    description: "PEDAL ASM-ACCEL",
    supplierName: "HELLA AUTOMOTIVE MEXICO SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84037337-050150481",
    partNumber: "84037337",
    description: "PLATE ASM-F/SEA",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84042455-254039282",
    partNumber: "84042455",
    description: "LINK ASM-FRT S/",
    supplierName: "MULTIMATIC INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4Y9",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84046092-012839643",
    partNumber: "84046092",
    description: "BRACKET ASM-CHR",
    supplierName: "GM COMPONENT HOLDINGS LLC-LKPT PTC",
    supplierAddress: "LOCKPORT,NY",
    supplierStreet: "",
    supplierCity: "LOCKPORT",
    supplierState: "NY",
    supplierZip: "140941819",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84046093-012839643",
    partNumber: "84046093",
    description: "BRACKET ASM-CHR",
    supplierName: "GM COMPONENT HOLDINGS LLC-LKPT PTC",
    supplierAddress: "LOCKPORT,NY",
    supplierStreet: "",
    supplierCity: "LOCKPORT",
    supplierState: "NY",
    supplierZip: "140941819",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84049173-038605341",
    partNumber: "84049173",
    description: "COVER-W/S M/FUN",
    supplierName: "NYX INC",
    supplierAddress: "LIVONIA,MI",
    supplierStreet: "",
    supplierCity: "LIVONIA",
    supplierState: "MI",
    supplierZip: "481501216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84049174-038605341",
    partNumber: "84049174",
    description: "COVER-W/S M/FUN",
    supplierName: "NYX INC",
    supplierAddress: "LIVONIA,MI",
    supplierStreet: "",
    supplierCity: "LIVONIA",
    supplierState: "MI",
    supplierZip: "481501216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84049175-038605341",
    partNumber: "84049175",
    description: "INSERT-W/S M/FU",
    supplierName: "NYX INC",
    supplierAddress: "LIVONIA,MI",
    supplierStreet: "",
    supplierCity: "LIVONIA",
    supplierState: "MI",
    supplierZip: "481501216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84049176-038605341",
    partNumber: "84049176",
    description: "INSERT-W/S M/FU",
    supplierName: "NYX INC",
    supplierAddress: "LIVONIA,MI",
    supplierStreet: "",
    supplierCity: "LIVONIA",
    supplierState: "MI",
    supplierZip: "481501216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84056018-000217372",
    partNumber: "84056018",
    description: "RECEPTACLE ASM-",
    supplierName: "DELPHI-P - EL PASO",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065268",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84060390-051780094",
    partNumber: "84060390",
    description: "BAR ASM-FRT S/D",
    supplierName: "MERIT TECHNOLOGIES WORLDWIDE LLC",
    supplierAddress: "CANTON,MI",
    supplierStreet: "",
    supplierCity: "CANTON",
    supplierState: "MI",
    supplierZip: "48187",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84060391-051780094",
    partNumber: "84060391",
    description: "BAR ASM-FRT S/D",
    supplierName: "MERIT TECHNOLOGIES WORLDWIDE LLC",
    supplierAddress: "CANTON,MI",
    supplierStreet: "",
    supplierCity: "CANTON",
    supplierState: "MI",
    supplierZip: "48187",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84060392-051780094",
    partNumber: "84060392",
    description: "BAR ASM-RR S/D",
    supplierName: "MERIT TECHNOLOGIES WORLDWIDE LLC",
    supplierAddress: "CANTON,MI",
    supplierStreet: "",
    supplierCity: "CANTON",
    supplierState: "MI",
    supplierZip: "48187",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84060393-051780094",
    partNumber: "84060393",
    description: "BAR ASM-RR S/D",
    supplierName: "MERIT TECHNOLOGIES WORLDWIDE LLC",
    supplierAddress: "CANTON,MI",
    supplierStreet: "",
    supplierCity: "CANTON",
    supplierState: "MI",
    supplierZip: "48187",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84060394-051780094",
    partNumber: "84060394",
    description: "BAR ASM-RR S/D",
    supplierName: "MERIT TECHNOLOGIES WORLDWIDE LLC",
    supplierAddress: "CANTON,MI",
    supplierStreet: "",
    supplierCity: "CANTON",
    supplierState: "MI",
    supplierZip: "48187",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84060395-051780094",
    partNumber: "84060395",
    description: "BAR ASM-RR S/D",
    supplierName: "MERIT TECHNOLOGIES WORLDWIDE LLC",
    supplierAddress: "CANTON,MI",
    supplierStreet: "",
    supplierCity: "CANTON",
    supplierState: "MI",
    supplierZip: "48187",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84089678-005509385",
    partNumber: "84089678",
    description: "LABEL-FUEL RECO",
    supplierName: "WHITLAM GROUP INC",
    supplierAddress: "CENTER LINE,MI",
    supplierStreet: "",
    supplierCity: "CENTER LINE",
    supplierState: "MI",
    supplierZip: "480151059",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84104101-688732884",
    partNumber: "84104101",
    description: "ASHTRAY ASM-F/F",
    supplierName: "SH-INT CO LTD",
    supplierAddress: "GIMHAE,48",
    supplierStreet: "",
    supplierCity: "GIMHAE",
    supplierState: "48",
    supplierZip: "50870",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84104198-000243147",
    partNumber: "84104198",
    description: "BAG-DIGITAL RDO",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84109286-070850102",
    partNumber: "84109286",
    description: "SWITCH ASM-PARK",
    supplierName: "MARQUARDT SWITCHES INC",
    supplierAddress: "CAZENOVIA,NY",
    supplierStreet: "",
    supplierCity: "CAZENOVIA",
    supplierState: "NY",
    supplierZip: "130358444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84109393-049816044",
    partNumber: "84109393",
    description: "RETAINER-BAT HO",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "FRANKFORT,IL",
    supplierStreet: "",
    supplierCity: "FRANKFORT",
    supplierState: "IL",
    supplierZip: "604236017",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84110489-789519808",
    partNumber: "84110489",
    description: "INSULATOR ASM-H",
    supplierName: "CTA ACOUSTICS INC",
    supplierAddress: "CORBIN,KY",
    supplierStreet: "",
    supplierCity: "CORBIN",
    supplierState: "KY",
    supplierZip: "407018061",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84110490-789519808",
    partNumber: "84110490",
    description: "INSULATOR ASM-H",
    supplierName: "CTA ACOUSTICS INC",
    supplierAddress: "CORBIN,KY",
    supplierStreet: "",
    supplierCity: "CORBIN",
    supplierState: "KY",
    supplierZip: "407018061",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84110491-789519808",
    partNumber: "84110491",
    description: "INSULATOR ASM-H",
    supplierName: "CTA ACOUSTICS INC",
    supplierAddress: "CORBIN,KY",
    supplierStreet: "",
    supplierCity: "CORBIN",
    supplierState: "KY",
    supplierZip: "407018061",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84112194-042439265",
    partNumber: "84112194",
    description: "CLAMP-FRT STAB",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84114505-812895704",
    partNumber: "84114505",
    description: "ARM ASM-FRT LW",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84114506-812895704",
    partNumber: "84114506",
    description: "ARM ASM-FRT LW",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84114507-812895704",
    partNumber: "84114507",
    description: "ARM ASM-FRT LWR",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84114508-812895704",
    partNumber: "84114508",
    description: "ARM ASM-FRT LWR",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84115927-808263656",
    partNumber: "84115927",
    description: "CARPET ASM-INTE",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84115928-808263656",
    partNumber: "84115928",
    description: "CARPET ASM-INTE",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84115930-808263656",
    partNumber: "84115930",
    description: "CARPET ASM-INTE",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84118394-808263656",
    partNumber: "84118394",
    description: "CARPET ASM-F/FL",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84118402-808263656",
    partNumber: "84118402",
    description: "CARPET ASM-F/FL",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84118720-800221918",
    partNumber: "84118720",
    description: "BUMPER-HOOD FRT",
    supplierName: "PROTECTIVE INDUSTRIES INC",
    supplierAddress: "BUFFALO,NY",
    supplierStreet: "",
    supplierCity: "BUFFALO",
    supplierState: "NY",
    supplierZip: "142071910",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84128157-812802230",
    partNumber: "84128157",
    description: "RETAINER-RR S/D",
    supplierName: "MANUFACTURERA EL JARUDO S DE RL DE",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32695",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84128158-812802230",
    partNumber: "84128158",
    description: "RETAINER-RR S/D",
    supplierName: "MANUFACTURERA EL JARUDO S DE RL DE",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32695",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84128177-812802230",
    partNumber: "84128177",
    description: "RETAINER-RR S/D",
    supplierName: "MANUFACTURERA EL JARUDO S DE RL DE",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32695",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84128178-812802230",
    partNumber: "84128178",
    description: "RETAINER-RR S/D",
    supplierName: "MANUFACTURERA EL JARUDO S DE RL DE",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32695",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84128184-812802230",
    partNumber: "84128184",
    description: "RETAINER ASM-FR",
    supplierName: "MANUFACTURERA EL JARUDO S DE RL DE",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32695",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84128185-812802230",
    partNumber: "84128185",
    description: "RETAINER ASM-FR",
    supplierName: "MANUFACTURERA EL JARUDO S DE RL DE",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32695",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84128186-005346762",
    partNumber: "84128186",
    description: "SEALING STRIP A",
    supplierName: "COOPER STANDARD AUTOMOTIVE INC",
    supplierAddress: "GAYLORD,MI",
    supplierStreet: "",
    supplierCity: "GAYLORD",
    supplierState: "MI",
    supplierZip: "497359531",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84128187-005346762",
    partNumber: "84128187",
    description: "SEALING STRIP A",
    supplierName: "COOPER STANDARD AUTOMOTIVE INC",
    supplierAddress: "GAYLORD,MI",
    supplierStreet: "",
    supplierCity: "GAYLORD",
    supplierState: "MI",
    supplierZip: "497359531",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84128188-005346762",
    partNumber: "84128188",
    description: "SEALING STRIP A",
    supplierName: "COOPER STANDARD AUTOMOTIVE INC",
    supplierAddress: "GAYLORD,MI",
    supplierStreet: "",
    supplierCity: "GAYLORD",
    supplierState: "MI",
    supplierZip: "497359531",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84128189-005346762",
    partNumber: "84128189",
    description: "SEALING STRIP A",
    supplierName: "COOPER STANDARD AUTOMOTIVE INC",
    supplierAddress: "GAYLORD,MI",
    supplierStreet: "",
    supplierCity: "GAYLORD",
    supplierState: "MI",
    supplierZip: "497359531",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84128190-005346762",
    partNumber: "84128190",
    description: "SEALING STRIP A",
    supplierName: "COOPER STANDARD AUTOMOTIVE INC",
    supplierAddress: "GAYLORD,MI",
    supplierStreet: "",
    supplierCity: "GAYLORD",
    supplierState: "MI",
    supplierZip: "497359531",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84128191-005346762",
    partNumber: "84128191",
    description: "SEALING STRIP A",
    supplierName: "COOPER STANDARD AUTOMOTIVE INC",
    supplierAddress: "GAYLORD,MI",
    supplierStreet: "",
    supplierCity: "GAYLORD",
    supplierState: "MI",
    supplierZip: "497359531",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84138217-802850763",
    partNumber: "84138217",
    description: "SENSOR ASM-RR S",
    supplierName: "CTS AUTOMOTIVE LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799360000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84138218-802850763",
    partNumber: "84138218",
    description: "SENSOR ASM-RR S",
    supplierName: "CTS AUTOMOTIVE LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799360000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84138452-247524416",
    partNumber: "84138452",
    description: "STEP ASM-ASST",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84138453-247524416",
    partNumber: "84138453",
    description: "STEP ASM-ASST",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84138454-247524416",
    partNumber: "84138454",
    description: "STEP ASM-ASST",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84138455-247524416",
    partNumber: "84138455",
    description: "STEP ASM-ASST",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84138741-802850763",
    partNumber: "84138741",
    description: "SENSOR ASM-FRT",
    supplierName: "CTS AUTOMOTIVE LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799360000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84138742-802850763",
    partNumber: "84138742",
    description: "SENSOR ASM-FRT",
    supplierName: "CTS AUTOMOTIVE LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799360000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84142968-932662133",
    partNumber: "84142968",
    description: "PLATE-I/P ACSRY",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84144292-021093638",
    partNumber: "84144292",
    description: "BRACKET-HTR OTL",
    supplierName: "BOPP-BUSCH MANUFACTURING CO INC",
    supplierAddress: "AU GRES,MI",
    supplierStreet: "",
    supplierCity: "AU GRES",
    supplierState: "MI",
    supplierZip: "487039748",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84144294-021093638",
    partNumber: "84144294",
    description: "BRACKET-HTR COO",
    supplierName: "BOPP-BUSCH MANUFACTURING CO INC",
    supplierAddress: "AU GRES,MI",
    supplierStreet: "",
    supplierCity: "AU GRES",
    supplierState: "MI",
    supplierZip: "487039748",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84148578-253988547",
    partNumber: "84148578",
    description: "APPLIQUE ASM-FR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84148579-253988547",
    partNumber: "84148579",
    description: "APPLIQUE ASM-FR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84152079-812765504",
    partNumber: "84152079",
    description: "BRACKET ASM-CHR",
    supplierName: "SAN LUIS DASUNG SA DE CV",
    supplierAddress: "VILLA DE REYES SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "VILLA DE REYES SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "79525",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84152501-080462172",
    partNumber: "84152501",
    description: "SENSOR ASM-TIRE",
    supplierName: "SCHRADER ELECTRONICS LTD",
    supplierAddress: "FORT WORTH,TX",
    supplierStreet: "",
    supplierCity: "FORT WORTH",
    supplierState: "TX",
    supplierZip: "761774001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84157202-545249849",
    partNumber: "84157202",
    description: "HANDLE ASM-R/RL",
    supplierName: "NINGBO JOYSON TECHNOLOGY CO LTD",
    supplierAddress: "ZHEJIANG,32",
    supplierStreet: "",
    supplierCity: "ZHEJIANG",
    supplierState: "32",
    supplierZip: "315040",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84157205-654129907",
    partNumber: "84157205",
    description: "HANDLE ASM-R/RL",
    supplierName: "NINGBO JOYSON TECHNOLOGY CO LTD",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200444",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84157206-654129907",
    partNumber: "84157206",
    description: "HANDLE ASM-R/RL",
    supplierName: "NINGBO JOYSON TECHNOLOGY CO LTD",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200444",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84157207-654129907",
    partNumber: "84157207",
    description: "HANDLE ASM-R/RL",
    supplierName: "NINGBO JOYSON TECHNOLOGY CO LTD",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200444",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84163661-969789283",
    partNumber: "84163661",
    description: "SHIELD ASM-F/CM",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84163662-969789283",
    partNumber: "84163662",
    description: "SHIELD ASM-F/CM",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84164380-544908429",
    partNumber: "84164380",
    description: "SUPPORT-S/S",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84166735-000241018",
    partNumber: "84166735",
    description: "COVER ASM-F/SEA",
    supplierName: "LEAR OPERATIONS CORP",
    supplierAddress: "WESTLAND,MI",
    supplierStreet: "",
    supplierCity: "WESTLAND",
    supplierState: "MI",
    supplierZip: "48185",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84167718-006016810",
    partNumber: "84167718",
    description: "LABEL-A/C REFRI",
    supplierName: "GRAND RAPIDS LABEL CO",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "49505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84175135-000251157",
    partNumber: "84175135",
    description: "SPEAKER ASM-REG",
    supplierName: "SOUND SOLUTIONS HOLDING BV",
    supplierAddress: "NINGHAI,33",
    supplierStreet: "",
    supplierCity: "NINGHAI",
    supplierState: "33",
    supplierZip: "315600",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84175582-186318176",
    partNumber: "84175582",
    description: "MOUNT ASM-ENG",
    supplierName: "HUTCHINSON ANTIVIBRATION SYSTEMS IN",
    supplierAddress: "CADILLAC,MI",
    supplierStreet: "",
    supplierCity: "CADILLAC",
    supplierState: "MI",
    supplierZip: "496011345",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84176635-000259622",
    partNumber: "84176635",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84176636-000259622",
    partNumber: "84176636",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84176638-000259622",
    partNumber: "84176638",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84176675-000259622",
    partNumber: "84176675",
    description: "ABSORBER ASM-RR",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84181077-000236455",
    partNumber: "84181077",
    description: "MODULE ASM-ACSR",
    supplierName: "NIDEC MOBILITY AMERICA CORP",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36270",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84181078-000236455",
    partNumber: "84181078",
    description: "MODULE ASM-ACSR",
    supplierName: "NIDEC MOBILITY AMERICA CORP",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36270",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84183515-080445381",
    partNumber: "84183515",
    description: "ACTUATOR ASM-L/",
    supplierName: "EDSCHA AUTOMOTIVE MICHIGAN INC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483264315",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84183794-078849132",
    partNumber: "84183794",
    description: "RETAINER-L/GATE",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84184368-000202427",
    partNumber: "84184368",
    description: "LINK ASM-RR SUS",
    supplierName: "SUPER AUTO FORGE LTD",
    supplierAddress: "WIXOM,MI",
    supplierStreet: "",
    supplierCity: "WIXOM",
    supplierState: "MI",
    supplierZip: "483932425",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84186138-254039282",
    partNumber: "84186138",
    description: "LINK ASM-FRT S/",
    supplierName: "MULTIMATIC INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4Y9",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84186652-829470223",
    partNumber: "84186652",
    description: "RECEPTACLE ASM-",
    supplierName: "CASCO PRODUCTS CORP",
    supplierAddress: "MORGANTOWN,KY",
    supplierStreet: "",
    supplierCity: "MORGANTOWN",
    supplierState: "KY",
    supplierZip: "422617361",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84190293-160931085",
    partNumber: "84190293",
    description: "HOUSING-REGULAT",
    supplierName: "SALGA PLASTICS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663715",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84199335-096141098",
    partNumber: "84199335",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE OKLAHOMA INC",
    supplierAddress: "FREDERICK,OK",
    supplierStreet: "",
    supplierCity: "FREDERICK",
    supplierState: "OK",
    supplierZip: "735427000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84199414-009971289",
    partNumber: "84199414",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84199415-009971289",
    partNumber: "84199415",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84199416-009971289",
    partNumber: "84199416",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84206594-078849132",
    partNumber: "84206594",
    description: "BRACKET-BCM",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84208369-814592697",
    partNumber: "84208369",
    description: "DISPLAY ASM-FWD",
    supplierName: "NIPPON SEIKI DE MEXICO SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67114",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84209169-000243147",
    partNumber: "84209169",
    description: "BAG-DIGITAL RDO",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84209354-040584963",
    partNumber: "84209354",
    description: "INSULATOR-M/TEL",
    supplierName: "UNIQUE FABRICATING NA INC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483261415",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84211920-785629952",
    partNumber: "84211920",
    description: "LAMP ASM-RR BOD",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84211921-785629952",
    partNumber: "84211921",
    description: "LAMP ASM-RR BOD",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84211922-785629952",
    partNumber: "84211922",
    description: "LAMP ASM-RR BOD",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84211923-785629952",
    partNumber: "84211923",
    description: "LAMP ASM-RR BOD",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84213107-812888154",
    partNumber: "84213107",
    description: "WHEEL-22X9J ALU",
    supplierName: "SUPERIOR INDUSTRIES DE MEXICO SA DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31090",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84214130-545237257",
    partNumber: "84214130",
    description: "PLATE ASM-FRT S",
    supplierName: "SRG GLOBAL (SUZHOU) CO LTD",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215021",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84214132-545237257",
    partNumber: "84214132",
    description: "PLATE ASM-L/GAT",
    supplierName: "SRG GLOBAL (SUZHOU) CO LTD",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215021",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84216908-800786642",
    partNumber: "84216908",
    description: "SEAL-F/FDR FIL",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84221534-099051716",
    partNumber: "84221534",
    description: "RETAINER-RR BOD",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84225775-969789283",
    partNumber: "84225775",
    description: "MOLDING ASM-RR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84225776-969789283",
    partNumber: "84225776",
    description: "MOLDING ASM-RR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84225777-969789283",
    partNumber: "84225777",
    description: "MOLDING ASM-RR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84225778-969789283",
    partNumber: "84225778",
    description: "MOLDING ASM-RR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84225781-969789283",
    partNumber: "84225781",
    description: "MOLDING ASM-FRT",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84225782-969789283",
    partNumber: "84225782",
    description: "MOLDING ASM-FRT",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84227090-812366540",
    partNumber: "84227090",
    description: "WHEEL-22X9.0,J,",
    supplierName: "SUPERIOR INDUSTRIES DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31136",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84227871-812888154",
    partNumber: "84227871",
    description: "WHEEL-ACFT",
    supplierName: "SUPERIOR INDUSTRIES DE MEXICO SA DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31090",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84229013-077472228",
    partNumber: "84229013",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229014-077472228",
    partNumber: "84229014",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229018-005356662",
    partNumber: "84229018",
    description: "REINFORCEMENT-F",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229020-005356662",
    partNumber: "84229020",
    description: "REINFORCEMENT-F",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229021-077472228",
    partNumber: "84229021",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229022-077472228",
    partNumber: "84229022",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229023-077472228",
    partNumber: "84229023",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229024-077472228",
    partNumber: "84229024",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229027-077472228",
    partNumber: "84229027",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229028-077472228",
    partNumber: "84229028",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229288-077472228",
    partNumber: "84229288",
    description: "REINFORCEMENT A",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229291-005356662",
    partNumber: "84229291",
    description: "PLATE-RR S/D H",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229298-077472228",
    partNumber: "84229298",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229299-077472228",
    partNumber: "84229299",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229300-077472228",
    partNumber: "84229300",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229301-077472228",
    partNumber: "84229301",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229303-005356662",
    partNumber: "84229303",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229305-005356662",
    partNumber: "84229305",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229308-005356662",
    partNumber: "84229308",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229310-005356662",
    partNumber: "84229310",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229312-005356662",
    partNumber: "84229312",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229313-077472228",
    partNumber: "84229313",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229314-077472228",
    partNumber: "84229314",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229315-077472228",
    partNumber: "84229315",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229316-077472228",
    partNumber: "84229316",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229324-077472228",
    partNumber: "84229324",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229325-077472228",
    partNumber: "84229325",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229326-077472228",
    partNumber: "84229326",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84229327-077472228",
    partNumber: "84229327",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84241506-969789283",
    partNumber: "84241506",
    description: "SEAL-RAD AIR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84243748-012839643",
    partNumber: "84243748",
    description: "RADIATOR ASM-EN",
    supplierName: "GM COMPONENT HOLDINGS LLC-LKPT PTC",
    supplierAddress: "LOCKPORT,NY",
    supplierStreet: "",
    supplierCity: "LOCKPORT",
    supplierState: "NY",
    supplierZip: "140941819",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84245212-000241018",
    partNumber: "84245212",
    description: "COVER ASM-F/SEA",
    supplierName: "LEAR OPERATIONS CORP",
    supplierAddress: "WESTLAND,MI",
    supplierStreet: "",
    supplierCity: "WESTLAND",
    supplierState: "MI",
    supplierZip: "48185",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84247140-544908429",
    partNumber: "84247140",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84247141-544908429",
    partNumber: "84247141",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84247151-544908429",
    partNumber: "84247151",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84247192-544908429",
    partNumber: "84247192",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84247200-544908429",
    partNumber: "84247200",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84247210-544908429",
    partNumber: "84247210",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84247211-544908429",
    partNumber: "84247211",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84247212-544908429",
    partNumber: "84247212",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84248380-694894524",
    partNumber: "84248380",
    description: "SWITCH ASM-HAZA",
    supplierName: "LS AUTOMOTIVE TECHNOLOGIES CO LTD",
    supplierAddress: "ANSAN,41",
    supplierStreet: "",
    supplierCity: "ANSAN",
    supplierState: "41",
    supplierZip: "15421",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84248714-012839643",
    partNumber: "84248714",
    description: "RADIATOR ASM-EN",
    supplierName: "GM COMPONENT HOLDINGS LLC-LKPT PTC",
    supplierAddress: "LOCKPORT,NY",
    supplierStreet: "",
    supplierCity: "LOCKPORT",
    supplierState: "NY",
    supplierZip: "140941819",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84248793-966669645",
    partNumber: "84248793",
    description: "DUCT ASM-INT AI",
    supplierName: "UNDERCAR PRODUCTS GROUP INC",
    supplierAddress: "WYOMING,MI",
    supplierStreet: "",
    supplierCity: "WYOMING",
    supplierState: "MI",
    supplierZip: "49508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84252200-020837233",
    partNumber: "84252200",
    description: "SHIELD-RR BRK",
    supplierName: "DAJACO INDUSTRIES INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512478",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84252201-020837233",
    partNumber: "84252201",
    description: "SHIELD-RR BRK",
    supplierName: "DAJACO INDUSTRIES INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512478",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84252883-969789283",
    partNumber: "84252883",
    description: "BAFFLE-RAD AIR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84252884-969789283",
    partNumber: "84252884",
    description: "BAFFLE-RAD AIR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84252885-969789283",
    partNumber: "84252885",
    description: "BAFFLE-RAD AIR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84252887-969789283",
    partNumber: "84252887",
    description: "BAFFLE-RAD AIR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84252889-969789283",
    partNumber: "84252889",
    description: "BAFFLE-RAD AIR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84252890-969789283",
    partNumber: "84252890",
    description: "BAFFLE-RAD AIR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84252892-969789283",
    partNumber: "84252892",
    description: "BAFFLE-RAD AIR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84252893-969789283",
    partNumber: "84252893",
    description: "BAFFLE-RAD AIR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84253578-144331493",
    partNumber: "84253578",
    description: "PANEL-RR S/D IN",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84253583-144331493",
    partNumber: "84253583",
    description: "PANEL-RR S/D IN",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84253595-144331493",
    partNumber: "84253595",
    description: "PANEL-RR S/D IN",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84253597-144331493",
    partNumber: "84253597",
    description: "PANEL-RR S/D IN",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84254038-812331239",
    partNumber: "84254038",
    description: "WINDOW ASM-QTR",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84254039-812331239",
    partNumber: "84254039",
    description: "WINDOW ASM-QTR",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84254042-812331239",
    partNumber: "84254042",
    description: "WINDOW ASM-QTR",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84254043-812331239",
    partNumber: "84254043",
    description: "WINDOW ASM-QTR",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84254902-000246553",
    partNumber: "84254902",
    description: "CARD-NAVN DATA",
    supplierName: "TELENAV INC",
    supplierAddress: "FREMONT,CA",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "CA",
    supplierZip: "945397498",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84254903-000246553",
    partNumber: "84254903",
    description: "CARD-NAVN DATA",
    supplierName: "TELENAV INC",
    supplierAddress: "FREMONT,CA",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "CA",
    supplierZip: "945397498",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257462-086663101",
    partNumber: "84257462",
    description: "PANEL-BODY H/PL",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257463-086663101",
    partNumber: "84257463",
    description: "PANEL-BODY H/PL",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257465-108630731",
    partNumber: "84257465",
    description: "BRACE-DA PNL",
    supplierName: "KIRCHHOFF AUTOMOTIVE DALLAS INC",
    supplierAddress: "GARLAND,TX",
    supplierStreet: "",
    supplierCity: "GARLAND",
    supplierState: "TX",
    supplierZip: "750416051",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257466-108630731",
    partNumber: "84257466",
    description: "BRACE-DA PNL",
    supplierName: "KIRCHHOFF AUTOMOTIVE DALLAS INC",
    supplierAddress: "GARLAND,TX",
    supplierStreet: "",
    supplierCity: "GARLAND",
    supplierState: "TX",
    supplierZip: "750416051",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257472-086663101",
    partNumber: "84257472",
    description: "RAIL-U/B INR FR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257476-812905909",
    partNumber: "84257476",
    description: "SILL-CR",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84257477-812905909",
    partNumber: "84257477",
    description: "SILL-CR",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84257484-812905909",
    partNumber: "84257484",
    description: "BRACE ASM-F/END",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84257485-812905909",
    partNumber: "84257485",
    description: "BRACE ASM-F/END",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84257486-812905909",
    partNumber: "84257486",
    description: "BRACE-F/END SH",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84257692-086663101",
    partNumber: "84257692",
    description: "RAIL-U/B INR FR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257694-050150481",
    partNumber: "84257694",
    description: "RAIL-U/B INTER",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257706-142199376",
    partNumber: "84257706",
    description: "RAIL-U/B RR SI",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257707-142199376",
    partNumber: "84257707",
    description: "RAIL-U/B RR SI",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257708-812905909",
    partNumber: "84257708",
    description: "RAIL-U/B RR SI",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84257712-078881928",
    partNumber: "84257712",
    description: "RAIL ASM-U/B RR",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257713-078881928",
    partNumber: "84257713",
    description: "RAIL ASM-U/B RR",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257737-142199376",
    partNumber: "84257737",
    description: "RAIL-U/B RR SI",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257738-142199376",
    partNumber: "84257738",
    description: "RAIL-U/B RR SI",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257739-812905909",
    partNumber: "84257739",
    description: "RAIL-U/B RR SI",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84257741-078881928",
    partNumber: "84257741",
    description: "RAIL ASM-U/B RR",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257742-078881928",
    partNumber: "84257742",
    description: "RAIL ASM-U/B RR",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84257836-808587047",
    partNumber: "84257836",
    description: "TANK ASM-RAD SU",
    supplierName: "NOVARES US LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129730",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84258184-078881928",
    partNumber: "84258184",
    description: "BAR ASM-R/CMPT",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84258185-142199376",
    partNumber: "84258185",
    description: "FILLER-R/FLR PN",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84258203-078881928",
    partNumber: "84258203",
    description: "SILL ASM-U/B #4",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84258205-086663101",
    partNumber: "84258205",
    description: "RAIL-R/CMPT FLR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84258212-078881928",
    partNumber: "84258212",
    description: "BAR ASM-R/CMPT",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84258216-078881928",
    partNumber: "84258216",
    description: "OUTRIGGER ASM-U",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84258217-078881928",
    partNumber: "84258217",
    description: "OUTRIGGER ASM-U",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84258218-078881928",
    partNumber: "84258218",
    description: "OUTRIGGER ASM-U",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84258219-078881928",
    partNumber: "84258219",
    description: "OUTRIGGER ASM-U",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84258222-078881928",
    partNumber: "84258222",
    description: "SILL ASM-U/B RR",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84258411-544908429",
    partNumber: "84258411",
    description: "SUPPORT-S/S",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84258724-544634264",
    partNumber: "84258724",
    description: "WHEEL-22X9.0J A",
    supplierName: "CITIC DICASTAL CO LTD PRODUCTION FA",
    supplierAddress: "QINHUANGDAO,13",
    supplierStreet: "",
    supplierCity: "QINHUANGDAO",
    supplierState: "13",
    supplierZip: "66009",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84263711-000271932",
    partNumber: "84263711",
    description: "BRACKET ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "MANSFIELD,OH",
    supplierStreet: "",
    supplierCity: "MANSFIELD",
    supplierState: "OH",
    supplierZip: "449031106",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84263712-000271932",
    partNumber: "84263712",
    description: "BRACKET ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "MANSFIELD,OH",
    supplierStreet: "",
    supplierCity: "MANSFIELD",
    supplierState: "OH",
    supplierZip: "449031106",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84263728-080786860",
    partNumber: "84263728",
    description: "PIN-F/FLR CNSL",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84265812-086663101",
    partNumber: "84265812",
    description: "FRAME ASM-W/S I",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84265814-086663101",
    partNumber: "84265814",
    description: "PANEL ASM-CTR P",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84265828-077472228",
    partNumber: "84265828",
    description: "PANEL ASM-QTR I",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84265829-077472228",
    partNumber: "84265829",
    description: "PANEL ASM-QTR I",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84265931-812947059",
    partNumber: "84265931",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84265932-812947059",
    partNumber: "84265932",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84265934-812947059",
    partNumber: "84265934",
    description: "LINER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84265938-080786860",
    partNumber: "84265938",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84265939-080786860",
    partNumber: "84265939",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84265940-813016276",
    partNumber: "84265940",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84265942-812947059",
    partNumber: "84265942",
    description: "LINER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84265951-812947059",
    partNumber: "84265951",
    description: "LINER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84265955-812905909",
    partNumber: "84265955",
    description: "PLATE ASM-BODY",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84265957-812905909",
    partNumber: "84265957",
    description: "PLATE ASM-BODY",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84265965-812905909",
    partNumber: "84265965",
    description: "PLATE ASM-BODY",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84265967-812905909",
    partNumber: "84265967",
    description: "PLATE ASM-BODY",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84265987-077472228",
    partNumber: "84265987",
    description: "REINFORCEMENT A",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266123-086663101",
    partNumber: "84266123",
    description: "FRAME ASM-W/S I",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266125-086663101",
    partNumber: "84266125",
    description: "PANEL ASM-CTR P",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266131-077472228",
    partNumber: "84266131",
    description: "PANEL ASM-QTR I",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266132-077472228",
    partNumber: "84266132",
    description: "PANEL ASM-QTR I",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266159-812905909",
    partNumber: "84266159",
    description: "PLATE ASM-BODY",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266161-812905909",
    partNumber: "84266161",
    description: "PLATE ASM-BODY",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266198-812905909",
    partNumber: "84266198",
    description: "PLATE ASM-BODY",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266203-117409100",
    partNumber: "84266203",
    description: "COVER-W/S PLR A",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266204-117409100",
    partNumber: "84266204",
    description: "COVER-W/S PLR A",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266216-077472228",
    partNumber: "84266216",
    description: "REINFORCEMENT A",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266243-117409100",
    partNumber: "84266243",
    description: "CAP-CTR PLR UPR",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266244-117409100",
    partNumber: "84266244",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266246-117409100",
    partNumber: "84266246",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266248-117409100",
    partNumber: "84266248",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266250-117409100",
    partNumber: "84266250",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266278-078488161",
    partNumber: "84266278",
    description: "SPEAKER ASM-RDO",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266353-080786860",
    partNumber: "84266353",
    description: "PLATE ASM-I/P C",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266378-812888257",
    partNumber: "84266378",
    description: "COVER ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266380-812888257",
    partNumber: "84266380",
    description: "COVER ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266382-812888257",
    partNumber: "84266382",
    description: "COVER ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266466-000202272",
    partNumber: "84266466",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266468-000202272",
    partNumber: "84266468",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266470-000202272",
    partNumber: "84266470",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266534-812947059",
    partNumber: "84266534",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266535-812947059",
    partNumber: "84266535",
    description: "TRAY ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266563-812888257",
    partNumber: "84266563",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266568-812888257",
    partNumber: "84266568",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266569-812888257",
    partNumber: "84266569",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266707-812905909",
    partNumber: "84266707",
    description: "BRACKET ASM-BOD",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266708-812772112",
    partNumber: "84266708",
    description: "FRAME ASM-W/S O",
    supplierName: "FORMEX MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25903",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266714-812772112",
    partNumber: "84266714",
    description: "REINFORCEMENT A",
    supplierName: "FORMEX MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25903",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266781-812905909",
    partNumber: "84266781",
    description: "BRACKET ASM-BOD",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266786-812772112",
    partNumber: "84266786",
    description: "FRAME ASM-W/S O",
    supplierName: "FORMEX MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25903",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266793-812772112",
    partNumber: "84266793",
    description: "REINFORCEMENT A",
    supplierName: "FORMEX MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25903",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266849-812905909",
    partNumber: "84266849",
    description: "BRACKET ASM-BOD",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266850-812905909",
    partNumber: "84266850",
    description: "BRACKET ASM-BOD",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266852-812905909",
    partNumber: "84266852",
    description: "REINFORCEMENT-R",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266853-812905909",
    partNumber: "84266853",
    description: "REINFORCEMENT-R",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266857-086663101",
    partNumber: "84266857",
    description: "GUTTER-BODY SI",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266860-077472228",
    partNumber: "84266860",
    description: "REINFORCEMENT A",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266941-000243147",
    partNumber: "84266941",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266943-000243147",
    partNumber: "84266943",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266945-000243147",
    partNumber: "84266945",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266982-812905909",
    partNumber: "84266982",
    description: "REINFORCEMENT-R",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266983-812905909",
    partNumber: "84266983",
    description: "REINFORCEMENT-R",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84266991-086663101",
    partNumber: "84266991",
    description: "GUTTER-BODY SI",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84266994-077472228",
    partNumber: "84266994",
    description: "REINFORCEMENT A",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84267338-932662133",
    partNumber: "84267338",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84267339-932662133",
    partNumber: "84267339",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84267384-544321108",
    partNumber: "84267384",
    description: "PLATE ASM-F/FLR",
    supplierName: "XUANCHENG TOP SUN SURFACE TECHNOLOG",
    supplierAddress: "XUANCHENG,34",
    supplierStreet: "",
    supplierCity: "XUANCHENG",
    supplierState: "34",
    supplierZip: "242100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267628-812905909",
    partNumber: "84267628",
    description: "REINFORCEMENT-R",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84267629-812905909",
    partNumber: "84267629",
    description: "REINFORCEMENT-R",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84267648-086663101",
    partNumber: "84267648",
    description: "BOW-RF PNL #1",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84267653-050150481",
    partNumber: "84267653",
    description: "BOW-RF PNL LGTD",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84267654-050150481",
    partNumber: "84267654",
    description: "BOW-RF PNL LGTD",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84267751-812905909",
    partNumber: "84267751",
    description: "REINFORCEMENT-F",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84267759-812905909",
    partNumber: "84267759",
    description: "BRACKET-BODY SI",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84267760-042439265",
    partNumber: "84267760",
    description: "BRACKET-BODY SI",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84267761-812905909",
    partNumber: "84267761",
    description: "BRACKET-BODY SI",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84267765-812905909",
    partNumber: "84267765",
    description: "BRACKET ASM-HOO",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84267767-812905909",
    partNumber: "84267767",
    description: "REINFORCEMENT-F",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84267776-812905909",
    partNumber: "84267776",
    description: "BRACKET-BODY SI",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84267777-042439265",
    partNumber: "84267777",
    description: "BRACKET-BODY SI",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84267778-812905909",
    partNumber: "84267778",
    description: "BRACKET-BODY SI",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84267782-812905909",
    partNumber: "84267782",
    description: "BRACKET ASM-HOO",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84267885-654593565",
    partNumber: "84267885",
    description: "PLATE ASM-C-PIL",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267886-654593565",
    partNumber: "84267886",
    description: "PLATE ASM-C-PIL",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267895-654593565",
    partNumber: "84267895",
    description: "PLATE ASM-FRT S",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267898-654593565",
    partNumber: "84267898",
    description: "PLATE ASM-FRT S",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267899-654593565",
    partNumber: "84267899",
    description: "PLATE ASM-FRT S",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267906-654593565",
    partNumber: "84267906",
    description: "PLATE ASM-L/GAT",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267912-654593565",
    partNumber: "84267912",
    description: "PLATE ASM-L/GAT",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267920-654593565",
    partNumber: "84267920",
    description: "PLATE ASM-L/GAT",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267933-078488161",
    partNumber: "84267933",
    description: "AMPLIFIER ASM-R",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84267936-544979545",
    partNumber: "84267936",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267937-544979545",
    partNumber: "84267937",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267940-544979545",
    partNumber: "84267940",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267941-544979545",
    partNumber: "84267941",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267952-544979545",
    partNumber: "84267952",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267953-544979545",
    partNumber: "84267953",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267956-544979545",
    partNumber: "84267956",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267957-544979545",
    partNumber: "84267957",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267962-544979545",
    partNumber: "84267962",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267963-544979545",
    partNumber: "84267963",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267966-544979545",
    partNumber: "84267966",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84267967-544979545",
    partNumber: "84267967",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84268041-969789283",
    partNumber: "84268041",
    description: "GUTTER ASM-BK B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84268179-056894041",
    partNumber: "84268179",
    description: "COVER-R/END SPL",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84269916-812947059",
    partNumber: "84269916",
    description: "REINFORCEMENT-H",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84269921-812947059",
    partNumber: "84269921",
    description: "REINFORCEMENT-H",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84269941-942885633",
    partNumber: "84269941",
    description: "SPACER-HDLNG T/",
    supplierName: "UNIQUE FABRICATING INC",
    supplierAddress: "LAFAYETTE,GA",
    supplierStreet: "",
    supplierCity: "LAFAYETTE",
    supplierState: "GA",
    supplierZip: "307286489",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84269942-942885633",
    partNumber: "84269942",
    description: "SPACER-HDLNG T/",
    supplierName: "UNIQUE FABRICATING INC",
    supplierAddress: "LAFAYETTE,GA",
    supplierStreet: "",
    supplierCity: "LAFAYETTE",
    supplierState: "GA",
    supplierZip: "307286489",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84269943-942885633",
    partNumber: "84269943",
    description: "SPACER-HDLNG T/",
    supplierName: "UNIQUE FABRICATING INC",
    supplierAddress: "LAFAYETTE,GA",
    supplierStreet: "",
    supplierCity: "LAFAYETTE",
    supplierState: "GA",
    supplierZip: "307286489",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84269944-942885633",
    partNumber: "84269944",
    description: "SPACER-HDLNG T/",
    supplierName: "UNIQUE FABRICATING INC",
    supplierAddress: "LAFAYETTE,GA",
    supplierStreet: "",
    supplierCity: "LAFAYETTE",
    supplierState: "GA",
    supplierZip: "307286489",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84270177-117409100",
    partNumber: "84270177",
    description: "PLATE-HDLNG T/P",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84270178-117409100",
    partNumber: "84270178",
    description: "PLATE-HDLNG T/P",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84270179-117409100",
    partNumber: "84270179",
    description: "PLATE-HDLNG T/P",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84270180-117409100",
    partNumber: "84270180",
    description: "PLATE-HDLNG T/P",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84270181-117409100",
    partNumber: "84270181",
    description: "PLATE-HDLNG T/P",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84270192-117409100",
    partNumber: "84270192",
    description: "BRACKET-FRT S/D",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84270193-117409100",
    partNumber: "84270193",
    description: "BRACKET-FRT S/D",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84270194-117409100",
    partNumber: "84270194",
    description: "BRACKET-RR S/D",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84270195-117409100",
    partNumber: "84270195",
    description: "BRACKET-RR S/D",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84270356-080779273",
    partNumber: "84270356",
    description: "CABLE ASM-AUX B",
    supplierName: "TIANHAI ELECTRIC NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065240",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84271622-117409100",
    partNumber: "84271622",
    description: "BEZEL-R/SEAT BE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84271624-117409100",
    partNumber: "84271624",
    description: "BEZEL-R/SEAT BE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84272706-080786860",
    partNumber: "84272706",
    description: "CAP-FRT S/D A/R",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84272708-080786860",
    partNumber: "84272708",
    description: "CAP-FRT S/D A/R",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84272710-080786860",
    partNumber: "84272710",
    description: "CAP-FRT S/D A/R",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84272712-080786860",
    partNumber: "84272712",
    description: "CAP-FRT S/D A/R",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84272715-080786860",
    partNumber: "84272715",
    description: "CAP-RR S/D A/RS",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84272716-080786860",
    partNumber: "84272716",
    description: "CAP-RR S/D A/RS",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84272718-080786860",
    partNumber: "84272718",
    description: "CAP-RR S/D A/RS",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84272720-080786860",
    partNumber: "84272720",
    description: "CAP-RR S/D A/RS",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84272721-202888707",
    partNumber: "84272721",
    description: "BRACKET ASM-RR",
    supplierName: "MARWOOD INTERNATIONAL INC",
    supplierAddress: "WOODSTOCK,ON",
    supplierStreet: "",
    supplierCity: "WOODSTOCK",
    supplierState: "ON",
    supplierZip: "N4S 7L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84272722-202888707",
    partNumber: "84272722",
    description: "BRACKET ASM-RR",
    supplierName: "MARWOOD INTERNATIONAL INC",
    supplierAddress: "WOODSTOCK,ON",
    supplierStreet: "",
    supplierCity: "WOODSTOCK",
    supplierState: "ON",
    supplierZip: "N4S 7L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84272723-202888707",
    partNumber: "84272723",
    description: "BRACKET ASM-RR",
    supplierName: "MARWOOD INTERNATIONAL INC",
    supplierAddress: "WOODSTOCK,ON",
    supplierStreet: "",
    supplierCity: "WOODSTOCK",
    supplierState: "ON",
    supplierZip: "N4S 7L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84272724-202888707",
    partNumber: "84272724",
    description: "BRACKET ASM-RR",
    supplierName: "MARWOOD INTERNATIONAL INC",
    supplierAddress: "WOODSTOCK,ON",
    supplierStreet: "",
    supplierCity: "WOODSTOCK",
    supplierState: "ON",
    supplierZip: "N4S 7L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84272725-202888707",
    partNumber: "84272725",
    description: "BRACKET ASM-FRT",
    supplierName: "MARWOOD INTERNATIONAL INC",
    supplierAddress: "WOODSTOCK,ON",
    supplierStreet: "",
    supplierCity: "WOODSTOCK",
    supplierState: "ON",
    supplierZip: "N4S 7L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84272726-202888707",
    partNumber: "84272726",
    description: "BRACKET ASM-FRT",
    supplierName: "MARWOOD INTERNATIONAL INC",
    supplierAddress: "WOODSTOCK,ON",
    supplierStreet: "",
    supplierCity: "WOODSTOCK",
    supplierState: "ON",
    supplierZip: "N4S 7L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84272986-080924263",
    partNumber: "84272986",
    description: "INSULATOR-HOOD",
    supplierName: "MIDWEST ACOUST A FIBER INC",
    supplierAddress: "SAN ANTONIO,TX",
    supplierStreet: "",
    supplierCity: "SAN ANTONIO",
    supplierState: "TX",
    supplierZip: "782192302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84272987-080924263",
    partNumber: "84272987",
    description: "INSULATOR-HOOD",
    supplierName: "MIDWEST ACOUST A FIBER INC",
    supplierAddress: "SAN ANTONIO,TX",
    supplierStreet: "",
    supplierCity: "SAN ANTONIO",
    supplierState: "TX",
    supplierZip: "782192302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84272988-080924263",
    partNumber: "84272988",
    description: "INSULATOR-HOOD",
    supplierName: "MIDWEST ACOUST A FIBER INC",
    supplierAddress: "SAN ANTONIO,TX",
    supplierStreet: "",
    supplierCity: "SAN ANTONIO",
    supplierState: "TX",
    supplierZip: "782192302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84272989-951568959",
    partNumber: "84272989",
    description: "INSULATOR-DA P",
    supplierName: "ACOUST-A-FIBER DE",
    supplierAddress: "ROMITA,GJ",
    supplierStreet: "",
    supplierCity: "ROMITA",
    supplierState: "GJ",
    supplierZip: "36200",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84272998-134583202",
    partNumber: "84272998",
    description: "INSULATOR-U/B",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "FENTON,MI",
    supplierStreet: "",
    supplierCity: "FENTON",
    supplierState: "MI",
    supplierZip: "48430",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84272999-141148051",
    partNumber: "84272999",
    description: "BARRIER-BODY H/",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84273000-141148051",
    partNumber: "84273000",
    description: "BARRIER-BODY H/",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84273086-080786860",
    partNumber: "84273086",
    description: "CARRIER ASM-I/P",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84273087-080786860",
    partNumber: "84273087",
    description: "CARRIER ASM-I/P",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84273095-812888257",
    partNumber: "84273095",
    description: "HOOD ASM-I/P IN",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84273105-812888257",
    partNumber: "84273105",
    description: "BEZEL ASM-HEAD",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84273107-812947059",
    partNumber: "84273107",
    description: "COVER-I/P UPR T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84273109-812947059",
    partNumber: "84273109",
    description: "BEZEL ASM-FWD C",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84273125-812888257",
    partNumber: "84273125",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84273139-812947059",
    partNumber: "84273139",
    description: "GRILLE ASM-RDO",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84273141-122564896",
    partNumber: "84273141",
    description: "GRILLE ASM-RDO",
    supplierName: "OAKWOOD METAL FABRICATING CO",
    supplierAddress: "TAYLOR,MI",
    supplierStreet: "",
    supplierCity: "TAYLOR",
    supplierState: "MI",
    supplierZip: "48180",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84273143-812947059",
    partNumber: "84273143",
    description: "GRILLE ASM-RDO",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84273145-122564896",
    partNumber: "84273145",
    description: "GRILLE ASM-RDO",
    supplierName: "OAKWOOD METAL FABRICATING CO",
    supplierAddress: "TAYLOR,MI",
    supplierStreet: "",
    supplierCity: "TAYLOR",
    supplierState: "MI",
    supplierZip: "48180",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84273163-080786860",
    partNumber: "84273163",
    description: "COVER ASM-I/P S",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84273165-080786860",
    partNumber: "84273165",
    description: "COVER ASM-I/P S",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84273177-812888257",
    partNumber: "84273177",
    description: "BEZEL ASM-I/P A",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84273179-812888257",
    partNumber: "84273179",
    description: "BEZEL ASM-I/P A",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84273180-020774709",
    partNumber: "84273180",
    description: "SEAL-RR WHL HUB",
    supplierName: "UCHIYAMA MANUFACTURING AMERICA LLC",
    supplierAddress: "GOLDSBORO,NC",
    supplierStreet: "",
    supplierCity: "GOLDSBORO",
    supplierState: "NC",
    supplierZip: "275308538",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84273586-969789283",
    partNumber: "84273586",
    description: "GUTTER ASM-BK B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84273587-969789283",
    partNumber: "84273587",
    description: "GUTTER ASM-BK B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84273588-969789283",
    partNumber: "84273588",
    description: "GUTTER ASM-BK B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84273589-969789283",
    partNumber: "84273589",
    description: "GUTTER ASM-BK B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84275151-942885633",
    partNumber: "84275151",
    description: "INSULATOR-RF PN",
    supplierName: "UNIQUE FABRICATING INC",
    supplierAddress: "LAFAYETTE,GA",
    supplierStreet: "",
    supplierCity: "LAFAYETTE",
    supplierState: "GA",
    supplierZip: "307286489",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84275152-942885633",
    partNumber: "84275152",
    description: "INSULATOR-RF PN",
    supplierName: "UNIQUE FABRICATING INC",
    supplierAddress: "LAFAYETTE,GA",
    supplierStreet: "",
    supplierCity: "LAFAYETTE",
    supplierState: "GA",
    supplierZip: "307286489",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84275153-942885633",
    partNumber: "84275153",
    description: "INSULATOR-RF PN",
    supplierName: "UNIQUE FABRICATING INC",
    supplierAddress: "LAFAYETTE,GA",
    supplierStreet: "",
    supplierCity: "LAFAYETTE",
    supplierState: "GA",
    supplierZip: "307286489",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84275154-942885633",
    partNumber: "84275154",
    description: "INSULATOR-RF PN",
    supplierName: "UNIQUE FABRICATING INC",
    supplierAddress: "LAFAYETTE,GA",
    supplierStreet: "",
    supplierCity: "LAFAYETTE",
    supplierState: "GA",
    supplierZip: "307286489",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84275155-942885633",
    partNumber: "84275155",
    description: "INSULATOR-RF PN",
    supplierName: "UNIQUE FABRICATING INC",
    supplierAddress: "LAFAYETTE,GA",
    supplierStreet: "",
    supplierCity: "LAFAYETTE",
    supplierState: "GA",
    supplierZip: "307286489",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84275185-243459851",
    partNumber: "84275185",
    description: "CASE ASM-SPA WH",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84275187-243459851",
    partNumber: "84275187",
    description: "GUIDE-SPA WHL H",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84275188-790480847",
    partNumber: "84275188",
    description: "STRAP ASM-SPA W",
    supplierName: "TORIN INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "48111",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84275226-080399938",
    partNumber: "84275226",
    description: "ABSORBER-RR RF",
    supplierName: "COASTAL AUTOMOTIVE LLC",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "494235337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84275418-160931085",
    partNumber: "84275418",
    description: "DISTRIBUTOR-AUX",
    supplierName: "SALGA PLASTICS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663715",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84275426-812416683",
    partNumber: "84275426",
    description: "DUCT-SI WDO DEF",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84275427-812416683",
    partNumber: "84275427",
    description: "DUCT-SI WDO DEF",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84275448-812416683",
    partNumber: "84275448",
    description: "DUCT-SI WDO DEF",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84275455-812416683",
    partNumber: "84275455",
    description: "DUCT-SI WDO DEF",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84275462-812416683",
    partNumber: "84275462",
    description: "DUCT-F/FLR CNSL",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84275463-812416683",
    partNumber: "84275463",
    description: "DUCT-F/FLR CNSL",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84276447-544979545",
    partNumber: "84276447",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84276448-544979545",
    partNumber: "84276448",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84276449-544979545",
    partNumber: "84276449",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84276450-544979545",
    partNumber: "84276450",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84276451-544979545",
    partNumber: "84276451",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84276452-544979545",
    partNumber: "84276452",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84277343-049816044",
    partNumber: "84277343",
    description: "PLUG-O/S RR VIE",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "FRANKFORT,IL",
    supplierStreet: "",
    supplierCity: "FRANKFORT",
    supplierState: "IL",
    supplierZip: "604236017",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84277346-243459851",
    partNumber: "84277346",
    description: "COVER ASM-FRT S",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84277525-146257907",
    partNumber: "84277525",
    description: "REINFORCEMENT A",
    supplierName: "RIDGEVIEW INDUSTRY INC",
    supplierAddress: "HUNTSVILLE,AL",
    supplierStreet: "",
    supplierCity: "HUNTSVILLE",
    supplierState: "AL",
    supplierZip: "358033938",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84277591-688328749",
    partNumber: "84277591",
    description: "HINGE ASM-L/GAT",
    supplierName: "MA TECH CO LTD",
    supplierAddress: "DAEGU,27",
    supplierStreet: "",
    supplierCity: "DAEGU",
    supplierState: "27",
    supplierZip: "42721",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84277992-042439265",
    partNumber: "84277992",
    description: "BRACKET ASM-BOD",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84277993-042439265",
    partNumber: "84277993",
    description: "BRACKET ASM-BOD",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84277994-042439265",
    partNumber: "84277994",
    description: "BRACKET ASM-BOD",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84277995-042439265",
    partNumber: "84277995",
    description: "BRACKET ASM-BOD",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84278012-042439265",
    partNumber: "84278012",
    description: "REINFORCEMENT-F",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84278013-042439265",
    partNumber: "84278013",
    description: "REINFORCEMENT-F",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84278015-005356662",
    partNumber: "84278015",
    description: "REINFORCEMENT-H",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84278016-005356662",
    partNumber: "84278016",
    description: "REINFORCEMENT-H",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84278023-042439265",
    partNumber: "84278023",
    description: "REINFORCEMENT A",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84278024-042439265",
    partNumber: "84278024",
    description: "REINFORCEMENT A",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84278025-042439265",
    partNumber: "84278025",
    description: "REINFORCEMENT A",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84278026-077472228",
    partNumber: "84278026",
    description: "REINFORCEMENT A",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84278032-042439265",
    partNumber: "84278032",
    description: "BRACKET ASM-HDL",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84278033-042439265",
    partNumber: "84278033",
    description: "BRACKET ASM-HDL",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84278336-810248898",
    partNumber: "84278336",
    description: "ARM ASM-WSW",
    supplierName: "ROBERT BOSCH S DE RL DE CV",
    supplierAddress: "TOLUCA,EM",
    supplierStreet: "",
    supplierCity: "TOLUCA",
    supplierState: "EM",
    supplierZip: "50071",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84278337-810248898",
    partNumber: "84278337",
    description: "ARM ASM-WSW",
    supplierName: "ROBERT BOSCH S DE RL DE CV",
    supplierAddress: "TOLUCA,EM",
    supplierStreet: "",
    supplierCity: "TOLUCA",
    supplierState: "EM",
    supplierZip: "50071",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84278338-079777903",
    partNumber: "84278338",
    description: "BLADE ASM-WSW",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "SUMMERVILLE,SC",
    supplierStreet: "",
    supplierCity: "SUMMERVILLE",
    supplierState: "SC",
    supplierZip: "294837415",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84278389-129136698",
    partNumber: "84278389",
    description: "BLADE ASM-R/WDO",
    supplierName: "DY AMERICA INC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483143209",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84278809-042439265",
    partNumber: "84278809",
    description: "REINFORCEMENT A",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84279471-544925969",
    partNumber: "84279471",
    description: "CABLE ASM-HOOD",
    supplierName: "L&C CHANGSHA CABLE INDUSTRIES LTD",
    supplierAddress: "CHANGSHA,43",
    supplierStreet: "",
    supplierCity: "CHANGSHA",
    supplierState: "43",
    supplierZip: "410119",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84279559-042439265",
    partNumber: "84279559",
    description: "REINFORCEMENT A",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84279560-042439265",
    partNumber: "84279560",
    description: "REINFORCEMENT A",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84281391-812905909",
    partNumber: "84281391",
    description: "PLATE ASM-BODY",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84286486-254039282",
    partNumber: "84286486",
    description: "LINK ASM-RR S/D",
    supplierName: "MULTIMATIC INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4Y9",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84288988-000261487",
    partNumber: "84288988",
    description: "MODULE ASM-ACSR",
    supplierName: "TOYOTA INDUSTRIES ELECTRIC SYSTEMS",
    supplierAddress: "GEORGETOWN,KY",
    supplierStreet: "",
    supplierCity: "GEORGETOWN",
    supplierState: "KY",
    supplierZip: "403248974",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84288989-000261487",
    partNumber: "84288989",
    description: "MODULE ASM-ACSR",
    supplierName: "TOYOTA INDUSTRIES ELECTRIC SYSTEMS",
    supplierAddress: "GEORGETOWN,KY",
    supplierStreet: "",
    supplierCity: "GEORGETOWN",
    supplierState: "KY",
    supplierZip: "403248974",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84289334-812905909",
    partNumber: "84289334",
    description: "BRACKET ASM-HEA",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84291405-545288953",
    partNumber: "84291405",
    description: "BUMPER-L/GATE W",
    supplierName: "QINGDAO DONGMING AUTOFITTING CO LTD",
    supplierAddress: "QINGDAO,37",
    supplierStreet: "",
    supplierCity: "QINGDAO",
    supplierState: "37",
    supplierZip: "266706",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84292118-932662133",
    partNumber: "84292118",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84292119-932662133",
    partNumber: "84292119",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84292121-932662133",
    partNumber: "84292121",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84292124-932662133",
    partNumber: "84292124",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84293174-242059041",
    partNumber: "84293174",
    description: "MOLDING ASM-RF",
    supplierName: "ROYAL AUTOMOTIVE GROUP LTD",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84293175-242059041",
    partNumber: "84293175",
    description: "MOLDING ASM-RF",
    supplierName: "ROYAL AUTOMOTIVE GROUP LTD",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84293176-242059041",
    partNumber: "84293176",
    description: "MOLDING ASM-RF",
    supplierName: "ROYAL AUTOMOTIVE GROUP LTD",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84293177-242059041",
    partNumber: "84293177",
    description: "MOLDING ASM-RF",
    supplierName: "ROYAL AUTOMOTIVE GROUP LTD",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84294002-969789283",
    partNumber: "84294002",
    description: "BRACKET ASM-ECM",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84295007-969789283",
    partNumber: "84295007",
    description: "SHIELD ASM-F/CM",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84299566-968663109",
    partNumber: "84299566",
    description: "HANDLE-HOOD SEC",
    supplierName: "PYEONGHWA AUTOMOTIVE USA LLC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84300486-101180995",
    partNumber: "84300486",
    description: "HOIST ASM-SPA W",
    supplierName: "MAG-USA INC",
    supplierAddress: "CLINTON,TN",
    supplierStreet: "",
    supplierCity: "CLINTON",
    supplierState: "TN",
    supplierZip: "377166587",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84301268-800786642",
    partNumber: "84301268",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84301269-800786642",
    partNumber: "84301269",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84304893-607039732",
    partNumber: "84304893",
    description: "HARNESS ASM-TRF",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84305347-607039732",
    partNumber: "84305347",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84305348-607039732",
    partNumber: "84305348",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84305353-607039732",
    partNumber: "84305353",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84305354-607039732",
    partNumber: "84305354",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84305355-607039732",
    partNumber: "84305355",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84305357-607039732",
    partNumber: "84305357",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84305359-607039732",
    partNumber: "84305359",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84305360-607039732",
    partNumber: "84305360",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84305361-607039732",
    partNumber: "84305361",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84306929-080445381",
    partNumber: "84306929",
    description: "ACTUATOR ASM-L/",
    supplierName: "EDSCHA AUTOMOTIVE MICHIGAN INC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483264315",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84308400-544634264",
    partNumber: "84308400",
    description: "WHEEL-20X9.0,J,",
    supplierName: "CITIC DICASTAL CO LTD PRODUCTION FA",
    supplierAddress: "QINHUANGDAO,13",
    supplierStreet: "",
    supplierCity: "QINHUANGDAO",
    supplierState: "13",
    supplierZip: "66009",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84312137-812947059",
    partNumber: "84312137",
    description: "PANEL ASM-I/P L",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84312139-812947059",
    partNumber: "84312139",
    description: "PANEL ASM-I/P L",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84312141-812947059",
    partNumber: "84312141",
    description: "PANEL ASM-I/P L",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84312143-812947059",
    partNumber: "84312143",
    description: "PANEL ASM-I/P L",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84312283-969789283",
    partNumber: "84312283",
    description: "BRACKET-DRVR MO",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84316302-042439265",
    partNumber: "84316302",
    description: "BRACKET-BODY SI",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84316316-042439265",
    partNumber: "84316316",
    description: "BRACKET-BODY SI",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84316439-050476923",
    partNumber: "84316439",
    description: "SPEAKER ASM-RDO",
    supplierName: "BOSE CORP",
    supplierAddress: "SAN DIEGO,CA",
    supplierStreet: "",
    supplierCity: "SAN DIEGO",
    supplierState: "CA",
    supplierZip: "921546216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84316898-544908429",
    partNumber: "84316898",
    description: "COVER-S/S RET B",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84316900-544908429",
    partNumber: "84316900",
    description: "COVER-S/S RET B",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84316901-544908429",
    partNumber: "84316901",
    description: "COVER-S/S RET B",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84316903-544908429",
    partNumber: "84316903",
    description: "COVER-S/S RET B",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84319588-812947059",
    partNumber: "84319588",
    description: "PLATE ASM-RF CN",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84319995-654593565",
    partNumber: "84319995",
    description: "PLATE ASM-E/GAT",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84320597-607039732",
    partNumber: "84320597",
    description: "HARNESS ASM-RF",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84320606-607039732",
    partNumber: "84320606",
    description: "HARNESS ASM-RF",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84321748-000167981",
    partNumber: "84321748",
    description: "SHAFT ASM-INTER",
    supplierName: "NEXTEER AUTOMOTIVE CORP-PLT 40",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79928",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84324904-814180139",
    partNumber: "84324904",
    description: "COVER ASM-F/SEA",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84324905-814180139",
    partNumber: "84324905",
    description: "COVER ASM-F/SEA",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84324910-078488161",
    partNumber: "84324910",
    description: "SPEAKER ASM-RDO",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84331227-814604781",
    partNumber: "84331227",
    description: "HOSE ASM-FRT BR",
    supplierName: "FLEXITECH AUTOMOTIVE MEXICO S DE RL",
    supplierAddress: "SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "78395",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84333485-001288109",
    partNumber: "84333485",
    description: "TIRE 275/50R22",
    supplierName: "BRIDGESTONE AMERICAS TIRE OPERATION",
    supplierAddress: "NASHVILLE,TN",
    supplierStreet: "",
    supplierCity: "NASHVILLE",
    supplierState: "TN",
    supplierZip: "372012255",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84335831-545264020",
    partNumber: "84335831",
    description: "CAP-WHL TR",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84335832-545264020",
    partNumber: "84335832",
    description: "CAP-WHL TR",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84336357-607039732",
    partNumber: "84336357",
    description: "COVER-F/CMPT FU",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84338533-812905909",
    partNumber: "84338533",
    description: "RAIL-R/CMPT FLR",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84338710-080646147",
    partNumber: "84338710",
    description: "HOOK-FRT TOW",
    supplierName: "MID WEST FABRICATING CO",
    supplierAddress: "LANCASTER,OH",
    supplierStreet: "",
    supplierCity: "LANCASTER",
    supplierState: "OH",
    supplierZip: "431308061",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84338714-080646147",
    partNumber: "84338714",
    description: "HOOK-FRT TOW",
    supplierName: "MID WEST FABRICATING CO",
    supplierAddress: "LANCASTER,OH",
    supplierStreet: "",
    supplierCity: "LANCASTER",
    supplierState: "OH",
    supplierZip: "431308061",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84339419-042439265",
    partNumber: "84339419",
    description: "BRACKET-REGULAT",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84340584-117409100",
    partNumber: "84340584",
    description: "BEZEL-R/SEAT BE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84340586-117409100",
    partNumber: "84340586",
    description: "BEZEL-R/SEAT BE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84341122-080316510",
    partNumber: "84341122",
    description: "MOLDING ASM-HOO",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84341123-080316510",
    partNumber: "84341123",
    description: "MOLDING ASM-HOO",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84341124-080316510",
    partNumber: "84341124",
    description: "MOLDING ASM-HOO",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84342286-969789283",
    partNumber: "84342286",
    description: "ATTACHMENT PKG-",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84342288-969789283",
    partNumber: "84342288",
    description: "ATTACHMENT PKG-",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84342291-969789283",
    partNumber: "84342291",
    description: "ATTACHMENT PKG-",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84342292-969789283",
    partNumber: "84342292",
    description: "ATTACHMENT PKG-",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84342481-688328749",
    partNumber: "84342481",
    description: "HINGE ASM-HOOD",
    supplierName: "MA TECH CO LTD",
    supplierAddress: "DAEGU,27",
    supplierStreet: "",
    supplierCity: "DAEGU",
    supplierState: "27",
    supplierZip: "42721",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84342482-688328749",
    partNumber: "84342482",
    description: "HINGE ASM-HOOD",
    supplierName: "MA TECH CO LTD",
    supplierAddress: "DAEGU,27",
    supplierStreet: "",
    supplierCity: "DAEGU",
    supplierState: "27",
    supplierZip: "42721",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84342483-688197008",
    partNumber: "84342483",
    description: "STRUT ASM-HOOD",
    supplierName: "HANIL PRECISION CO LTD",
    supplierAddress: "GIMHAE,48",
    supplierStreet: "",
    supplierCity: "GIMHAE",
    supplierState: "48",
    supplierZip: "50805",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84342541-012839643",
    partNumber: "84342541",
    description: "COOLER ASM-TRAN",
    supplierName: "GM COMPONENT HOLDINGS LLC-LKPT PTC",
    supplierAddress: "LOCKPORT,NY",
    supplierStreet: "",
    supplierCity: "LOCKPORT",
    supplierState: "NY",
    supplierZip: "140941819",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84342551-141148051",
    partNumber: "84342551",
    description: "INSULATOR-F/FDR",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84342552-141148051",
    partNumber: "84342552",
    description: "INSULATOR-F/FDR",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84347679-932662133",
    partNumber: "84347679",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84347682-932662133",
    partNumber: "84347682",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84347686-932662133",
    partNumber: "84347686",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84348425-142199376",
    partNumber: "84348425",
    description: "REINFORCEMENT A",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84350227-001288109",
    partNumber: "84350227",
    description: "TIRE 275/55R20",
    supplierName: "BRIDGESTONE AMERICAS TIRE OPERATION",
    supplierAddress: "NASHVILLE,TN",
    supplierStreet: "",
    supplierCity: "NASHVILLE",
    supplierState: "TN",
    supplierZip: "372012255",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84351340-050150481",
    partNumber: "84351340",
    description: "BRACKET ASM-SEN",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84353707-146257907",
    partNumber: "84353707",
    description: "REINFORCEMENT A",
    supplierName: "RIDGEVIEW INDUSTRY INC",
    supplierAddress: "HUNTSVILLE,AL",
    supplierStreet: "",
    supplierCity: "HUNTSVILLE",
    supplierState: "AL",
    supplierZip: "358033938",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84353708-146257907",
    partNumber: "84353708",
    description: "REINFORCEMENT A",
    supplierName: "RIDGEVIEW INDUSTRY INC",
    supplierAddress: "HUNTSVILLE,AL",
    supplierStreet: "",
    supplierCity: "HUNTSVILLE",
    supplierState: "AL",
    supplierZip: "358033938",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84353998-812895704",
    partNumber: "84353998",
    description: "BAR ASM-I/P TIE",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84353999-812895704",
    partNumber: "84353999",
    description: "BAR ASM-I/P TIE",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84354411-800786642",
    partNumber: "84354411",
    description: "BAFFLE ASM-F/CM",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84355023-808263656",
    partNumber: "84355023",
    description: "CARPET ASM-INTE",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84355025-808263656",
    partNumber: "84355025",
    description: "CARPET ASM-INTE",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84359164-607039732",
    partNumber: "84359164",
    description: "HARNESS ASM-RF",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84359165-607039732",
    partNumber: "84359165",
    description: "HARNESS ASM-RF",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84359904-800786642",
    partNumber: "84359904",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84359907-800786642",
    partNumber: "84359907",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84359908-800786642",
    partNumber: "84359908",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84359909-800786642",
    partNumber: "84359909",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84359917-800786642",
    partNumber: "84359917",
    description: "BAFFLE ASM-QTR",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84359918-800786642",
    partNumber: "84359918",
    description: "BAFFLE ASM-QTR",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84359919-800786642",
    partNumber: "84359919",
    description: "BAFFLE ASM-QTR",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84359920-800786642",
    partNumber: "84359920",
    description: "BAFFLE ASM-QTR",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84359926-800786642",
    partNumber: "84359926",
    description: "SEALER-BODY SI",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84362518-243459851",
    partNumber: "84362518",
    description: "BRACKET ASM-FV",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84364849-077472228",
    partNumber: "84364849",
    description: "RETAINER-RR S/D",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84364850-077472228",
    partNumber: "84364850",
    description: "RETAINER-RR S/D",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84366964-812807729",
    partNumber: "84366964",
    description: "MAT PKG-FLR AUX",
    supplierName: "AGM DURMONT MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76295",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84366966-812807729",
    partNumber: "84366966",
    description: "MAT PKG-FLR AUX",
    supplierName: "AGM DURMONT MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76295",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84366968-812807729",
    partNumber: "84366968",
    description: "MAT PKG-FLR AUX",
    supplierName: "AGM DURMONT MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76295",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84366972-812807729",
    partNumber: "84366972",
    description: "MAT PKG-FLR AUX",
    supplierName: "AGM DURMONT MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76295",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84366974-813018728",
    partNumber: "84366974",
    description: "MAT ASM-LOAD FL",
    supplierName: "MEXAURIA S DE RL DE CV",
    supplierAddress: "EL MARQUES,QA",
    supplierStreet: "",
    supplierCity: "EL MARQUES",
    supplierState: "QA",
    supplierZip: "76246",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84367398-243459851",
    partNumber: "84367398",
    description: "COVER ASM-FRT S",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84367400-141148051",
    partNumber: "84367400",
    description: "INSULATOR-RR S/",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84367401-141148051",
    partNumber: "84367401",
    description: "INSULATOR-RR S/",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84367402-141148051",
    partNumber: "84367402",
    description: "INSULATOR-FRT S",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84368458-086663101",
    partNumber: "84368458",
    description: "REINFORCEMENT A",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84368459-086663101",
    partNumber: "84368459",
    description: "REINFORCEMENT A",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84369072-812895704",
    partNumber: "84369072",
    description: "BRACE ASM-FRT L",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84374106-621632046",
    partNumber: "84374106",
    description: "DEFLECTOR ASM-F",
    supplierName: "CADILLAC PRODUCTS INC",
    supplierAddress: "SCHERTZ,TX",
    supplierStreet: "",
    supplierCity: "SCHERTZ",
    supplierState: "TX",
    supplierZip: "78154",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84374107-621632046",
    partNumber: "84374107",
    description: "DEFLECTOR ASM-F",
    supplierName: "CADILLAC PRODUCTS INC",
    supplierAddress: "SCHERTZ,TX",
    supplierStreet: "",
    supplierCity: "SCHERTZ",
    supplierState: "TX",
    supplierZip: "78154",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84374113-621632046",
    partNumber: "84374113",
    description: "DEFLECTOR ASM-A",
    supplierName: "CADILLAC PRODUCTS INC",
    supplierAddress: "SCHERTZ,TX",
    supplierStreet: "",
    supplierCity: "SCHERTZ",
    supplierState: "TX",
    supplierZip: "78154",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84374114-621632046",
    partNumber: "84374114",
    description: "DEFLECTOR ASM-A",
    supplierName: "CADILLAC PRODUCTS INC",
    supplierAddress: "SCHERTZ,TX",
    supplierStreet: "",
    supplierCity: "SCHERTZ",
    supplierState: "TX",
    supplierZip: "78154",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84374115-621632046",
    partNumber: "84374115",
    description: "DEFLECTOR ASM-A",
    supplierName: "CADILLAC PRODUCTS INC",
    supplierAddress: "SCHERTZ,TX",
    supplierStreet: "",
    supplierCity: "SCHERTZ",
    supplierState: "TX",
    supplierZip: "78154",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84374132-812548816",
    partNumber: "84374132",
    description: "INSULATOR ASM-B",
    supplierName: "FLEX-N-GATE MEXICO S DE RL DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84375121-078849132",
    partNumber: "84375121",
    description: "BRACKET-WIRELES",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84375789-146257907",
    partNumber: "84375789",
    description: "REINFORCEMENT A",
    supplierName: "RIDGEVIEW INDUSTRY INC",
    supplierAddress: "HUNTSVILLE,AL",
    supplierStreet: "",
    supplierCity: "HUNTSVILLE",
    supplierState: "AL",
    supplierZip: "358033938",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84377019-009971289",
    partNumber: "84377019",
    description: "WEATHERSTR",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84377125-694894524",
    partNumber: "84377125",
    description: "SWITCH ASM-HAZA",
    supplierName: "LS AUTOMOTIVE TECHNOLOGIES CO LTD",
    supplierAddress: "ANSAN,41",
    supplierStreet: "",
    supplierCity: "ANSAN",
    supplierState: "41",
    supplierZip: "15421",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84378081-006016810",
    partNumber: "84378081",
    description: "LABEL-MOBILE WI",
    supplierName: "GRAND RAPIDS LABEL CO",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "49505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84378163-809149292",
    partNumber: "84378163",
    description: "SHUTTER ASM-FRT",
    supplierName: "MAGNA MODULAR SYSTEMS INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891476",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84379367-969789283",
    partNumber: "84379367",
    description: "GUTTER ASM-BK B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84379368-969789283",
    partNumber: "84379368",
    description: "GUTTER ASM-BK B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84379369-969789283",
    partNumber: "84379369",
    description: "GUTTER ASM-BK B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84379370-969789283",
    partNumber: "84379370",
    description: "GUTTER ASM-BK B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84389425-144331493",
    partNumber: "84389425",
    description: "PANEL-DA",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84393382-814814042",
    partNumber: "84393382",
    description: "HOSE ASM-HTR WA",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84393383-814814042",
    partNumber: "84393383",
    description: "HOSE ASM-SPCHG",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84393389-814814042",
    partNumber: "84393389",
    description: "HOSE ASM-HTR WA",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84393397-814814042",
    partNumber: "84393397",
    description: "HOSE ASM-HTR IN",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84393399-814814042",
    partNumber: "84393399",
    description: "HOSE ASM-HTR IN",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84393400-814814042",
    partNumber: "84393400",
    description: "HOSE ASM-HTR OT",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84393934-814814042",
    partNumber: "84393934",
    description: "HOSE ASM-RAD OT",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84393935-814814042",
    partNumber: "84393935",
    description: "HOSE ASM-RAD IN",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84399890-607039732",
    partNumber: "84399890",
    description: "HARNESS ASM-TRL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84400490-800786642",
    partNumber: "84400490",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84400491-800786642",
    partNumber: "84400491",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84400492-800786642",
    partNumber: "84400492",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84400493-800786642",
    partNumber: "84400493",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84400991-603014643",
    partNumber: "84400991",
    description: "INSULATOR-RR W/",
    supplierName: "ALLIANCE INTERIORS LLC",
    supplierAddress: "LANSING,MI",
    supplierStreet: "",
    supplierCity: "LANSING",
    supplierState: "MI",
    supplierZip: "489179501",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84400992-603014643",
    partNumber: "84400992",
    description: "INSULATOR-RR W/",
    supplierName: "ALLIANCE INTERIORS LLC",
    supplierAddress: "LANSING,MI",
    supplierStreet: "",
    supplierCity: "LANSING",
    supplierState: "MI",
    supplierZip: "489179501",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84400993-603014643",
    partNumber: "84400993",
    description: "INSULATOR-RR W/",
    supplierName: "ALLIANCE INTERIORS LLC",
    supplierAddress: "LANSING,MI",
    supplierStreet: "",
    supplierCity: "LANSING",
    supplierState: "MI",
    supplierZip: "489179501",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84400994-141148051",
    partNumber: "84400994",
    description: "INSULATOR-R/CMP",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84400995-141148051",
    partNumber: "84400995",
    description: "INSULATOR-R/CMP",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84404201-078488161",
    partNumber: "84404201",
    description: "SPEAKER ASM-RDO",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84406504-814814042",
    partNumber: "84406504",
    description: "HOSE ASM-ENG CO",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84407003-050150481",
    partNumber: "84407003",
    description: "BRACKET ASM-FRT",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84407371-000240150",
    partNumber: "84407371",
    description: "GASKET-EXH SYS",
    supplierName: "DANA SEALING MANUFACTURING LLC",
    supplierAddress: "DANVILLE,KY",
    supplierStreet: "",
    supplierCity: "DANVILLE",
    supplierState: "KY",
    supplierZip: "404229499",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84407372-000240150",
    partNumber: "84407372",
    description: "GASKET-EXH SYS",
    supplierName: "DANA SEALING MANUFACTURING LLC",
    supplierAddress: "DANVILLE,KY",
    supplierStreet: "",
    supplierCity: "DANVILLE",
    supplierState: "KY",
    supplierZip: "404229499",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84407373-000240150",
    partNumber: "84407373",
    description: "GASKET-EXH SYS",
    supplierName: "DANA SEALING MANUFACTURING LLC",
    supplierAddress: "DANVILLE,KY",
    supplierStreet: "",
    supplierCity: "DANVILLE",
    supplierState: "KY",
    supplierZip: "404229499",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84409064-607039732",
    partNumber: "84409064",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84409065-607039732",
    partNumber: "84409065",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84412393-966669645",
    partNumber: "84412393",
    description: "CLEANER ASM-AIR",
    supplierName: "UNDERCAR PRODUCTS GROUP INC",
    supplierAddress: "WYOMING,MI",
    supplierStreet: "",
    supplierCity: "WYOMING",
    supplierState: "MI",
    supplierZip: "49508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84412395-966669645",
    partNumber: "84412395",
    description: "CLEANER ASM-AIR",
    supplierName: "UNDERCAR PRODUCTS GROUP INC",
    supplierAddress: "WYOMING,MI",
    supplierStreet: "",
    supplierCity: "WYOMING",
    supplierState: "MI",
    supplierZip: "49508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84416147-544321108",
    partNumber: "84416147",
    description: "PLATE-F/FLR CNS",
    supplierName: "XUANCHENG TOP SUN SURFACE TECHNOLOG",
    supplierAddress: "XUANCHENG,34",
    supplierStreet: "",
    supplierCity: "XUANCHENG",
    supplierState: "34",
    supplierZip: "242100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84416151-544321108",
    partNumber: "84416151",
    description: "BEZEL-F/FLR CNS",
    supplierName: "XUANCHENG TOP SUN SURFACE TECHNOLOG",
    supplierAddress: "XUANCHENG,34",
    supplierStreet: "",
    supplierCity: "XUANCHENG",
    supplierState: "34",
    supplierZip: "242100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84416481-078849132",
    partNumber: "84416481",
    description: "BRACKET ASM-FV",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84417101-840208362",
    partNumber: "84417101",
    description: "PROTECTOR-EMIS",
    supplierName: "PLASTIC OMINIUM AUTO INERGY LLC",
    supplierAddress: "ADRIAN,MI",
    supplierStreet: "",
    supplierCity: "ADRIAN",
    supplierState: "MI",
    supplierZip: "492218754",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84419749-812133478",
    partNumber: "84419749",
    description: "STOP-BAT TRAY",
    supplierName: "VENTRAMEX S DE RL DE CV",
    supplierAddress: "EL MARQUES,QA",
    supplierStreet: "",
    supplierCity: "EL MARQUES",
    supplierState: "QA",
    supplierZip: "76249",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84421163-607039732",
    partNumber: "84421163",
    description: "HARNESS ASM-FWD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84421164-607039732",
    partNumber: "84421164",
    description: "HARNESS ASM-FWD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84421165-607039732",
    partNumber: "84421165",
    description: "HARNESS ASM-FWD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84421167-607039732",
    partNumber: "84421167",
    description: "HARNESS ASM-FWD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84421169-607039732",
    partNumber: "84421169",
    description: "HARNESS ASM-FWD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84421170-607039732",
    partNumber: "84421170",
    description: "HARNESS ASM-FWD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84421171-607039732",
    partNumber: "84421171",
    description: "HARNESS ASM-FWD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84421174-607039732",
    partNumber: "84421174",
    description: "HARNESS ASM-FWD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84422218-814814042",
    partNumber: "84422218",
    description: "HOSE ASM-DRV MO",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84423415-812888154",
    partNumber: "84423415",
    description: "WHEEL-FRT & RR",
    supplierName: "SUPERIOR INDUSTRIES DE MEXICO SA DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31090",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84423416-812888154",
    partNumber: "84423416",
    description: "WHEEL-FRT & RR",
    supplierName: "SUPERIOR INDUSTRIES DE MEXICO SA DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31090",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84424593-086663101",
    partNumber: "84424593",
    description: "SILL ASM-U/B RR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84430010-080779273",
    partNumber: "84430010",
    description: "STRAP-BAT NEG C",
    supplierName: "TIANHAI ELECTRIC NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065240",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84431082-096141098",
    partNumber: "84431082",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE OKLAHOMA INC",
    supplierAddress: "FREDERICK,OK",
    supplierStreet: "",
    supplierCity: "FREDERICK",
    supplierState: "OK",
    supplierZip: "735427000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84431084-096141098",
    partNumber: "84431084",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE OKLAHOMA INC",
    supplierAddress: "FREDERICK,OK",
    supplierStreet: "",
    supplierCity: "FREDERICK",
    supplierState: "OK",
    supplierZip: "735427000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84431086-096141098",
    partNumber: "84431086",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE OKLAHOMA INC",
    supplierAddress: "FREDERICK,OK",
    supplierStreet: "",
    supplierCity: "FREDERICK",
    supplierState: "OK",
    supplierZip: "735427000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84433053-829470223",
    partNumber: "84433053",
    description: "RETAINER-C/LTR",
    supplierName: "CASCO PRODUCTS CORP",
    supplierAddress: "MORGANTOWN,KY",
    supplierStreet: "",
    supplierCity: "MORGANTOWN",
    supplierState: "KY",
    supplierZip: "422617361",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84433895-000167981",
    partNumber: "84433895",
    description: "SHAFT ASM-INTER",
    supplierName: "NEXTEER AUTOMOTIVE CORP-PLT 40",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79928",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84433913-086663101",
    partNumber: "84433913",
    description: "RAIL ASM-RF OTR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84433914-086663101",
    partNumber: "84433914",
    description: "RAIL ASM-RF OTR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84433917-086663101",
    partNumber: "84433917",
    description: "RAIL ASM-RF OTR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84433918-086663101",
    partNumber: "84433918",
    description: "RAIL ASM-RF OTR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84434133-243459851",
    partNumber: "84434133",
    description: "GRILLE-ACTIVE N",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84434758-000261487",
    partNumber: "84434758",
    description: "MODULE ASM-ACSR",
    supplierName: "TOYOTA INDUSTRIES ELECTRIC SYSTEMS",
    supplierAddress: "GEORGETOWN,KY",
    supplierStreet: "",
    supplierCity: "GEORGETOWN",
    supplierState: "KY",
    supplierZip: "403248974",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84435770-812537363",
    partNumber: "84435770",
    description: "HOSE ASM-EMIS R",
    supplierName: "AUTOMOTIVE VERITAS DE",
    supplierAddress: "CUAUTLANCINGO,PU",
    supplierStreet: "",
    supplierCity: "CUAUTLANCINGO",
    supplierState: "PU",
    supplierZip: "72730",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84436404-117409100",
    partNumber: "84436404",
    description: "COVER ASM-CTR P",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84438721-812416683",
    partNumber: "84438721",
    description: "DUCT-F/FLR CNSL",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84438722-812416683",
    partNumber: "84438722",
    description: "DUCT-F/FLR CNSL",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84438723-812416683",
    partNumber: "84438723",
    description: "DUCT-F/FLR CNSL",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84440446-117409100",
    partNumber: "84440446",
    description: "STIFFENER-RF PN",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84440447-117409100",
    partNumber: "84440447",
    description: "STIFFENER-RF PN",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84440448-117409100",
    partNumber: "84440448",
    description: "PLATE-HDLNG T/P",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84440454-117409100",
    partNumber: "84440454",
    description: "BEZEL-R/SEAT BE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84440456-117409100",
    partNumber: "84440456",
    description: "BEZEL-R/SEAT BE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84442676-077472228",
    partNumber: "84442676",
    description: "BRACE-FRT S/D O",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84442677-077472228",
    partNumber: "84442677",
    description: "BRACE-FRT S/D O",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84442678-077472228",
    partNumber: "84442678",
    description: "BRACE-RR S/D OT",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84442679-077472228",
    partNumber: "84442679",
    description: "BRACE-RR S/D OT",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84442680-077472228",
    partNumber: "84442680",
    description: "BRACE-RR S/D OT",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84442681-077472228",
    partNumber: "84442681",
    description: "BRACE-RR S/D OT",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84445165-209407907",
    partNumber: "84445165",
    description: "CAP-FRT S/D I/S",
    supplierName: "ARMADA TOOLWORKS LTD",
    supplierAddress: "LINDSAY,ON",
    supplierStreet: "",
    supplierCity: "LINDSAY",
    supplierState: "ON",
    supplierZip: "K9V 4S5",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84445166-209407907",
    partNumber: "84445166",
    description: "CAP-FRT S/D I/S",
    supplierName: "ARMADA TOOLWORKS LTD",
    supplierAddress: "LINDSAY,ON",
    supplierStreet: "",
    supplierCity: "LINDSAY",
    supplierState: "ON",
    supplierZip: "K9V 4S5",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84446339-021093638",
    partNumber: "84446339",
    description: "BRACKET-FUEL FE",
    supplierName: "BOPP-BUSCH MANUFACTURING CO INC",
    supplierAddress: "AU GRES,MI",
    supplierStreet: "",
    supplierCity: "AU GRES",
    supplierState: "MI",
    supplierZip: "487039748",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84447715-133501655",
    partNumber: "84447715",
    description: "HORN ASM-DUAL",
    supplierName: "FIAMM TECHNOLOGIES LLC",
    supplierAddress: "CADILLAC,MI",
    supplierStreet: "",
    supplierCity: "CADILLAC",
    supplierState: "MI",
    supplierZip: "496018975",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84449166-117409100",
    partNumber: "84449166",
    description: "PLUG-L/GATE LAT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84449170-117409100",
    partNumber: "84449170",
    description: "COVER-L/GATE LK",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84449172-117409100",
    partNumber: "84449172",
    description: "PLUG-R/CMPT LID",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84449307-831831438",
    partNumber: "84449307",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84449310-831831438",
    partNumber: "84449310",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84449311-831831438",
    partNumber: "84449311",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84449807-812807729",
    partNumber: "84449807",
    description: "MAT PKG-FLR AUX",
    supplierName: "AGM DURMONT MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76295",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84451820-099051716",
    partNumber: "84451820",
    description: "RETAINER-T/LP",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84452205-607039732",
    partNumber: "84452205",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84452207-607039732",
    partNumber: "84452207",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84452971-688328004",
    partNumber: "84452971",
    description: "EYE-FRT TOW",
    supplierName: "SHINIL PRECISION IND CO LTD",
    supplierAddress: "ANSAN,41",
    supplierStreet: "",
    supplierCity: "ANSAN",
    supplierState: "41",
    supplierZip: "15609",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84453668-554484040",
    partNumber: "84453668",
    description: "KNUCKLE-STRG",
    supplierName: "BETHEL AUTOMOTIVE SAFETY SYSTEMS CO",
    supplierAddress: "RUSHAN,37",
    supplierStreet: "",
    supplierCity: "RUSHAN",
    supplierState: "37",
    supplierZip: "264500",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84453669-554484040",
    partNumber: "84453669",
    description: "KNUCKLE-STRG",
    supplierName: "BETHEL AUTOMOTIVE SAFETY SYSTEMS CO",
    supplierAddress: "RUSHAN,37",
    supplierStreet: "",
    supplierCity: "RUSHAN",
    supplierState: "37",
    supplierZip: "264500",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84454090-607039732",
    partNumber: "84454090",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84454722-812416683",
    partNumber: "84454722",
    description: "DUCT-FLR RR AIR",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84454723-812416683",
    partNumber: "84454723",
    description: "DUCT-FLR RR AIR",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84455679-812947059",
    partNumber: "84455679",
    description: "LINER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84455711-812947059",
    partNumber: "84455711",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84455713-812947059",
    partNumber: "84455713",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84455715-812947059",
    partNumber: "84455715",
    description: "PANEL ASM-R/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84458155-000196717",
    partNumber: "84458155",
    description: "SPEAKER ASM-RDO",
    supplierName: "PSS HONG KONG LTD",
    supplierAddress: "DONGGUAN,44",
    supplierStreet: "",
    supplierCity: "DONGGUAN",
    supplierState: "44",
    supplierZip: "523861",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84458844-005356662",
    partNumber: "84458844",
    description: "REINFORCEMENT-F",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84459406-018422779",
    partNumber: "84459406",
    description: "HOSE ASM-A/C CN",
    supplierName: "CONTITECH NORTH AMERICA INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785038924",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84459768-243459851",
    partNumber: "84459768",
    description: "BRACKET ASM-ACT",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84459799-545264020",
    partNumber: "84459799",
    description: "CAP ASM-FRT & R",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84459829-607039732",
    partNumber: "84459829",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84459867-018422779",
    partNumber: "84459867",
    description: "HOSE ASM-A/C CM",
    supplierName: "CONTITECH NORTH AMERICA INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785038924",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84460080-050476923",
    partNumber: "84460080",
    description: "SPEAKER ASM-RDO",
    supplierName: "BOSE CORP",
    supplierAddress: "SAN DIEGO,CA",
    supplierStreet: "",
    supplierCity: "SAN DIEGO",
    supplierState: "CA",
    supplierZip: "921546216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84460109-545264020",
    partNumber: "84460109",
    description: "CAP ASM-FRT & R",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84460879-812888257",
    partNumber: "84460879",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84460885-812888257",
    partNumber: "84460885",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84460887-812888257",
    partNumber: "84460887",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84461658-812888257",
    partNumber: "84461658",
    description: "BEZEL ASM-I/P A",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84462156-188277016",
    partNumber: "84462156",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84462157-188277016",
    partNumber: "84462157",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84462161-188277016",
    partNumber: "84462161",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84462163-188277016",
    partNumber: "84462163",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84462164-188277016",
    partNumber: "84462164",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84462165-188277016",
    partNumber: "84462165",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84462166-188277016",
    partNumber: "84462166",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84462954-812815730",
    partNumber: "84462954",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84462958-812815730",
    partNumber: "84462958",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84462959-812815730",
    partNumber: "84462959",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84462962-812815730",
    partNumber: "84462962",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84462964-812815730",
    partNumber: "84462964",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84462965-812815730",
    partNumber: "84462965",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84463770-000202427",
    partNumber: "84463770",
    description: "LINK ASM-RR SUS",
    supplierName: "SUPER AUTO FORGE LTD",
    supplierAddress: "WIXOM,MI",
    supplierStreet: "",
    supplierCity: "WIXOM",
    supplierState: "MI",
    supplierZip: "483932425",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84463771-000202427",
    partNumber: "84463771",
    description: "LINK ASM-RR SUS",
    supplierName: "SUPER AUTO FORGE LTD",
    supplierAddress: "WIXOM,MI",
    supplierStreet: "",
    supplierCity: "WIXOM",
    supplierState: "MI",
    supplierZip: "483932425",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84466390-018414292",
    partNumber: "84466390",
    description: "RECEPTACLE ASM-",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "WARREN,OH",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "OH",
    supplierZip: "444832352",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84467119-814814042",
    partNumber: "84467119",
    description: "HOSE ASM-RAD SU",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84467638-966669645",
    partNumber: "84467638",
    description: "DUCT ASM-A/CL O",
    supplierName: "UNDERCAR PRODUCTS GROUP INC",
    supplierAddress: "WYOMING,MI",
    supplierStreet: "",
    supplierCity: "WYOMING",
    supplierState: "MI",
    supplierZip: "49508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84467639-966669645",
    partNumber: "84467639",
    description: "DUCT ASM-A/CL O",
    supplierName: "UNDERCAR PRODUCTS GROUP INC",
    supplierAddress: "WYOMING,MI",
    supplierStreet: "",
    supplierCity: "WYOMING",
    supplierState: "MI",
    supplierZip: "49508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84467933-141148051",
    partNumber: "84467933",
    description: "INSULATOR-FRT S",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84467934-141148051",
    partNumber: "84467934",
    description: "INSULATOR-FRT S",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84467936-141148051",
    partNumber: "84467936",
    description: "INSULATOR-RR S/",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84467937-141148051",
    partNumber: "84467937",
    description: "INSULATOR-RR S/",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84467938-141148051",
    partNumber: "84467938",
    description: "INSULATOR-RR S/",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84467939-141148051",
    partNumber: "84467939",
    description: "INSULATOR-RR S/",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84467982-812802230",
    partNumber: "84467982",
    description: "RETAINER-FRT S/",
    supplierName: "MANUFACTURERA EL JARUDO S DE RL DE",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32695",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84467983-812802230",
    partNumber: "84467983",
    description: "RETAINER-FRT S/",
    supplierName: "MANUFACTURERA EL JARUDO S DE RL DE",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32695",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84469963-607039732",
    partNumber: "84469963",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84470959-654593565",
    partNumber: "84470959",
    description: "PLATE ASM-FRT S",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84472003-000202272",
    partNumber: "84472003",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84472005-000202272",
    partNumber: "84472005",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84472007-000202272",
    partNumber: "84472007",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84477079-000272310",
    partNumber: "84477079",
    description: "LINER ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483262613",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84477509-000104992",
    partNumber: "84477509",
    description: "SWITCH ASM-STRG",
    supplierName: "ALCOM ELECTRONICOS DE MEXICO",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84478461-018422779",
    partNumber: "84478461",
    description: "HOSE ASM-A/C CM",
    supplierName: "CONTITECH NORTH AMERICA INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785038924",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84482352-246852862",
    partNumber: "84482352",
    description: "HANGER ASM-EXH",
    supplierName: "MARWOOD INTERNATIONAL INC",
    supplierAddress: "TILLSONBURG,ON",
    supplierStreet: "",
    supplierCity: "TILLSONBURG",
    supplierState: "ON",
    supplierZip: "N4G 4H3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84483746-814526588",
    partNumber: "84483746",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84483757-814526588",
    partNumber: "84483757",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84483758-814526588",
    partNumber: "84483758",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84483765-814526588",
    partNumber: "84483765",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84483788-814526588",
    partNumber: "84483788",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84483796-814526588",
    partNumber: "84483796",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84486530-800786642",
    partNumber: "84486530",
    description: "TAPE-RR S/D STA",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84488135-000167981",
    partNumber: "84488135",
    description: "SHAFT ASM-UPR I",
    supplierName: "NEXTEER AUTOMOTIVE CORP-PLT 40",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79928",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84488395-812816935",
    partNumber: "84488395",
    description: "SWITCH ASM-TRLR",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84490357-545249849",
    partNumber: "84490357",
    description: "HANDLE ASM-R/RL",
    supplierName: "NINGBO JOYSON TECHNOLOGY CO LTD",
    supplierAddress: "ZHEJIANG,32",
    supplierStreet: "",
    supplierCity: "ZHEJIANG",
    supplierState: "32",
    supplierZip: "315040",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84492877-152890224",
    partNumber: "84492877",
    description: "CANISTER ASM-AU",
    supplierName: "DELPHI POWERTRAIN SYSTEMS LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84495980-005050406",
    partNumber: "84495980",
    description: "BEZEL-R/D I/S H",
    supplierName: "PILKINGTON NORTH AMERICA INC",
    supplierAddress: "ROSSFORD,OH",
    supplierStreet: "",
    supplierCity: "ROSSFORD",
    supplierState: "OH",
    supplierZip: "434601215",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84496064-203049994",
    partNumber: "84496064",
    description: "RETAINER ASM-BA",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "SCARBOROUGH,ON",
    supplierStreet: "",
    supplierCity: "SCARBOROUGH",
    supplierState: "ON",
    supplierZip: "M1P 2P2",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84496181-812815730",
    partNumber: "84496181",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84496182-812815730",
    partNumber: "84496182",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84496184-812815730",
    partNumber: "84496184",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84496187-812815730",
    partNumber: "84496187",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84496188-812815730",
    partNumber: "84496188",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84496192-812815730",
    partNumber: "84496192",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84496212-812815730",
    partNumber: "84496212",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84496213-812815730",
    partNumber: "84496213",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84496215-812815730",
    partNumber: "84496215",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84496218-812815730",
    partNumber: "84496218",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84496219-812815730",
    partNumber: "84496219",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84496223-812815730",
    partNumber: "84496223",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84496775-812331239",
    partNumber: "84496775",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84496776-812331239",
    partNumber: "84496776",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84500517-807849687",
    partNumber: "84500517",
    description: "SEALER-FRT STRU",
    supplierName: "HENKEL CORP",
    supplierAddress: "RICHMOND,MO",
    supplierStreet: "",
    supplierCity: "RICHMOND",
    supplierState: "MO",
    supplierZip: "640852374",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84501693-050476923",
    partNumber: "84501693",
    description: "SPEAKER ASM-RDO",
    supplierName: "BOSE CORP",
    supplierAddress: "SAN DIEGO,CA",
    supplierStreet: "",
    supplierCity: "SAN DIEGO",
    supplierState: "CA",
    supplierZip: "921546216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84506288-969789283",
    partNumber: "84506288",
    description: "GUTTER ASM-BK B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84509568-812133478",
    partNumber: "84509568",
    description: "STRAP ASM-BAT H",
    supplierName: "VENTRAMEX S DE RL DE CV",
    supplierAddress: "EL MARQUES,QA",
    supplierStreet: "",
    supplierCity: "EL MARQUES",
    supplierState: "QA",
    supplierZip: "76249",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84509569-812133478",
    partNumber: "84509569",
    description: "STRAP ASM-BAT H",
    supplierName: "VENTRAMEX S DE RL DE CV",
    supplierAddress: "EL MARQUES,QA",
    supplierStreet: "",
    supplierCity: "EL MARQUES",
    supplierState: "QA",
    supplierZip: "76249",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84511724-812765504",
    partNumber: "84511724",
    description: "BRACKET ASM-BOD",
    supplierName: "SAN LUIS DASUNG SA DE CV",
    supplierAddress: "VILLA DE REYES SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "VILLA DE REYES SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "79525",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84512048-789519808",
    partNumber: "84512048",
    description: "INSULATOR-DA P",
    supplierName: "CTA ACOUSTICS INC",
    supplierAddress: "CORBIN,KY",
    supplierStreet: "",
    supplierCity: "CORBIN",
    supplierState: "KY",
    supplierZip: "407018061",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84513500-588212576",
    partNumber: "84513500",
    description: "REINFORCEMENT A",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36275",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84515470-084668367",
    partNumber: "84515470",
    description: "BRACKET-ENG WRG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "BROOKHAVEN,MS",
    supplierStreet: "",
    supplierCity: "BROOKHAVEN",
    supplierState: "MS",
    supplierZip: "396018951",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84516157-812928414",
    partNumber: "84516157",
    description: "CASE ASM-TRFER",
    supplierName: "MAGNA POWERTRAIN DE MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84520281-812765504",
    partNumber: "84520281",
    description: "BRACKET-HTR INL",
    supplierName: "SAN LUIS DASUNG SA DE CV",
    supplierAddress: "VILLA DE REYES SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "VILLA DE REYES SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "79525",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84520997-812905909",
    partNumber: "84520997",
    description: "STRAP ASM-BAT H",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84523965-009971289",
    partNumber: "84523965",
    description: "WEATHERSTR",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84523966-009971289",
    partNumber: "84523966",
    description: "WEATHERSTR",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84523967-009971289",
    partNumber: "84523967",
    description: "WEATHERSTR",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84524282-078849132",
    partNumber: "84524282",
    description: "BRACKET-WIRELES",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84525244-142199376",
    partNumber: "84525244",
    description: "SHIELD-EXH HT",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84525839-812947059",
    partNumber: "84525839",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84526453-814180139",
    partNumber: "84526453",
    description: "COVER ASM-F/SEA",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84526486-814180139",
    partNumber: "84526486",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84526487-814180139",
    partNumber: "84526487",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84527323-812947059",
    partNumber: "84527323",
    description: "BEZEL ASM-FWD C",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84527324-812947059",
    partNumber: "84527324",
    description: "BEZEL ASM-FWD C",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84527337-812947059",
    partNumber: "84527337",
    description: "GRILLE ASM-RDO",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84527338-812947059",
    partNumber: "84527338",
    description: "GRILLE ASM-RDO",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84527339-812947059",
    partNumber: "84527339",
    description: "GRILLE ASM-RDO",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84527340-812947059",
    partNumber: "84527340",
    description: "GRILLE ASM-RDO",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84527341-812947059",
    partNumber: "84527341",
    description: "GRILLE ASM-RDO",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84527342-812947059",
    partNumber: "84527342",
    description: "GRILLE ASM-RDO",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84533152-966350811",
    partNumber: "84533152",
    description: "LINK ASM-RR STA",
    supplierName: "RHYTHM NORTH AMERICA CORP",
    supplierAddress: "SPARTA,TN",
    supplierStreet: "",
    supplierCity: "SPARTA",
    supplierState: "TN",
    supplierZip: "385831360",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84533927-812888257",
    partNumber: "84533927",
    description: "HOOD ASM-I/P IN",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84533928-812888257",
    partNumber: "84533928",
    description: "HOOD ASM-I/P IN",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84533929-812888257",
    partNumber: "84533929",
    description: "HOOD ASM-I/P IN",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84533933-812888257",
    partNumber: "84533933",
    description: "BEZEL ASM-HD UP",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84533934-812888257",
    partNumber: "84533934",
    description: "BEZEL ASM-HD UP",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84533935-812888257",
    partNumber: "84533935",
    description: "BEZEL ASM-HD UP",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84533939-812888257",
    partNumber: "84533939",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84533940-812888257",
    partNumber: "84533940",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84533941-812888257",
    partNumber: "84533941",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84533944-122564896",
    partNumber: "84533944",
    description: "GRILLE ASM-RDO",
    supplierName: "OAKWOOD METAL FABRICATING CO",
    supplierAddress: "TAYLOR,MI",
    supplierStreet: "",
    supplierCity: "TAYLOR",
    supplierState: "MI",
    supplierZip: "48180",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84533945-122564896",
    partNumber: "84533945",
    description: "GRILLE ASM-RDO",
    supplierName: "OAKWOOD METAL FABRICATING CO",
    supplierAddress: "TAYLOR,MI",
    supplierStreet: "",
    supplierCity: "TAYLOR",
    supplierState: "MI",
    supplierZip: "48180",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84533946-122564896",
    partNumber: "84533946",
    description: "GRILLE ASM-RDO",
    supplierName: "OAKWOOD METAL FABRICATING CO",
    supplierAddress: "TAYLOR,MI",
    supplierStreet: "",
    supplierCity: "TAYLOR",
    supplierState: "MI",
    supplierZip: "48180",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84533947-122564896",
    partNumber: "84533947",
    description: "GRILLE ASM-RDO",
    supplierName: "OAKWOOD METAL FABRICATING CO",
    supplierAddress: "TAYLOR,MI",
    supplierStreet: "",
    supplierCity: "TAYLOR",
    supplierState: "MI",
    supplierZip: "48180",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84533948-122564896",
    partNumber: "84533948",
    description: "GRILLE ASM-RDO",
    supplierName: "OAKWOOD METAL FABRICATING CO",
    supplierAddress: "TAYLOR,MI",
    supplierStreet: "",
    supplierCity: "TAYLOR",
    supplierState: "MI",
    supplierZip: "48180",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84533949-122564896",
    partNumber: "84533949",
    description: "GRILLE ASM-RDO",
    supplierName: "OAKWOOD METAL FABRICATING CO",
    supplierAddress: "TAYLOR,MI",
    supplierStreet: "",
    supplierCity: "TAYLOR",
    supplierState: "MI",
    supplierZip: "48180",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84534437-000236455",
    partNumber: "84534437",
    description: "SWITCH ASM-DIMR",
    supplierName: "NIDEC MOBILITY AMERICA CORP",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36270",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84534701-812548816",
    partNumber: "84534701",
    description: "RETAINER ASM-BA",
    supplierName: "FLEX-N-GATE MEXICO S DE RL DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84535442-812416683",
    partNumber: "84535442",
    description: "ADAPTER-FLR RR",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84535702-831831438",
    partNumber: "84535702",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84535703-831831438",
    partNumber: "84535703",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84535747-000202272",
    partNumber: "84535747",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84535748-000202272",
    partNumber: "84535748",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84535749-000202272",
    partNumber: "84535749",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84535750-000202272",
    partNumber: "84535750",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84535751-000202272",
    partNumber: "84535751",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84535752-000202272",
    partNumber: "84535752",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84535753-000202272",
    partNumber: "84535753",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84535754-000202272",
    partNumber: "84535754",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84535755-000202272",
    partNumber: "84535755",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84535756-000202272",
    partNumber: "84535756",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84535757-000202272",
    partNumber: "84535757",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84535758-000202272",
    partNumber: "84535758",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84537982-000246553",
    partNumber: "84537982",
    description: "CARD-NAVN DATA",
    supplierName: "TELENAV INC",
    supplierAddress: "FREMONT,CA",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "CA",
    supplierZip: "945397498",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84540790-080786860",
    partNumber: "84540790",
    description: "COVER ASM-I/P S",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84540791-080786860",
    partNumber: "84540791",
    description: "COVER ASM-I/P S",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84540792-080786860",
    partNumber: "84540792",
    description: "COVER ASM-I/P S",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84540799-812947059",
    partNumber: "84540799",
    description: "PANEL ASM-I/P L",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84540800-812947059",
    partNumber: "84540800",
    description: "PANEL ASM-I/P L",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84540801-812947059",
    partNumber: "84540801",
    description: "PANEL ASM-I/P L",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84540802-812947059",
    partNumber: "84540802",
    description: "PANEL ASM-I/P L",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84540803-812947059",
    partNumber: "84540803",
    description: "PANEL ASM-I/P L",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84540804-812947059",
    partNumber: "84540804",
    description: "PANEL ASM-I/P L",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84541217-808263656",
    partNumber: "84541217",
    description: "CARPET ASM-F/FL",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84541221-808263656",
    partNumber: "84541221",
    description: "CARPET ASM-F/FL",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84541222-808263656",
    partNumber: "84541222",
    description: "CARPET ASM-F/FL",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84541227-808263656",
    partNumber: "84541227",
    description: "CARPET ASM-F/FL",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84541230-808263656",
    partNumber: "84541230",
    description: "CARPET ASM-F/FL",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84541233-808263656",
    partNumber: "84541233",
    description: "CARPET ASM-F/FL",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84541236-808263656",
    partNumber: "84541236",
    description: "CARPET ASM-F/FL",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84541242-808263656",
    partNumber: "84541242",
    description: "CARPET ASM-F/FL",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84541243-808263656",
    partNumber: "84541243",
    description: "CARPET ASM-F/FL",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545036-117409100",
    partNumber: "84545036",
    description: "CAP-CTR PLR UPR",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545037-117409100",
    partNumber: "84545037",
    description: "CAP-CTR PLR UPR",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545038-117409100",
    partNumber: "84545038",
    description: "CAP-CTR PLR UPR",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545039-117409100",
    partNumber: "84545039",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545040-117409100",
    partNumber: "84545040",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545041-117409100",
    partNumber: "84545041",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545042-117409100",
    partNumber: "84545042",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545043-117409100",
    partNumber: "84545043",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545044-117409100",
    partNumber: "84545044",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545045-117409100",
    partNumber: "84545045",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545046-117409100",
    partNumber: "84545046",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545047-117409100",
    partNumber: "84545047",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545048-117409100",
    partNumber: "84545048",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545049-117409100",
    partNumber: "84545049",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545050-117409100",
    partNumber: "84545050",
    description: "CAP-Q/WDO TR FI",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545065-117409100",
    partNumber: "84545065",
    description: "COVER-W/S PLR A",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84545066-117409100",
    partNumber: "84545066",
    description: "COVER-W/S PLR A",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84546290-812947059",
    partNumber: "84546290",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84546291-812947059",
    partNumber: "84546291",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84546292-812947059",
    partNumber: "84546292",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84546293-812947059",
    partNumber: "84546293",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84546294-812947059",
    partNumber: "84546294",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84546295-812947059",
    partNumber: "84546295",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84546296-080786860",
    partNumber: "84546296",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84546297-080786860",
    partNumber: "84546297",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84546298-080786860",
    partNumber: "84546298",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84546299-080786860",
    partNumber: "84546299",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84546300-080786860",
    partNumber: "84546300",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84546301-080786860",
    partNumber: "84546301",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84546302-813016276",
    partNumber: "84546302",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84546303-813016276",
    partNumber: "84546303",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84546304-813016276",
    partNumber: "84546304",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84546312-812947059",
    partNumber: "84546312",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84546313-812947059",
    partNumber: "84546313",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84546357-000202241",
    partNumber: "84546357",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84546358-000202241",
    partNumber: "84546358",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84546373-000202241",
    partNumber: "84546373",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84546374-000202241",
    partNumber: "84546374",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84546544-117409100",
    partNumber: "84546544",
    description: "COVER ASM-CTR P",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84546545-117409100",
    partNumber: "84546545",
    description: "COVER ASM-CTR P",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84546546-117409100",
    partNumber: "84546546",
    description: "COVER ASM-CTR P",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84546547-117409100",
    partNumber: "84546547",
    description: "COVER ASM-CTR P",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84548815-812947059",
    partNumber: "84548815",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84550787-117409100",
    partNumber: "84550787",
    description: "PLUG-L/GATE LAT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550788-117409100",
    partNumber: "84550788",
    description: "PLUG-L/GATE LAT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550789-117409100",
    partNumber: "84550789",
    description: "PLUG-L/GATE LAT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550790-117409100",
    partNumber: "84550790",
    description: "PLUG-L/GATE LAT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550795-117409100",
    partNumber: "84550795",
    description: "COVER-L/GATE LK",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550796-117409100",
    partNumber: "84550796",
    description: "COVER-L/GATE LK",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550797-117409100",
    partNumber: "84550797",
    description: "COVER-L/GATE LK",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550798-117409100",
    partNumber: "84550798",
    description: "COVER-L/GATE LK",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550799-117409100",
    partNumber: "84550799",
    description: "PLUG-R/CMPT LID",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550800-117409100",
    partNumber: "84550800",
    description: "PLUG-R/CMPT LID",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550801-117409100",
    partNumber: "84550801",
    description: "PLUG-R/CMPT LID",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550802-117409100",
    partNumber: "84550802",
    description: "PLUG-R/CMPT LID",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550905-117409100",
    partNumber: "84550905",
    description: "BEZEL-R/SEAT CT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550924-544908429",
    partNumber: "84550924",
    description: "SUPPORT-S/S",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84550925-544908429",
    partNumber: "84550925",
    description: "SUPPORT-S/S",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84550928-544908429",
    partNumber: "84550928",
    description: "COVER-S/S RET B",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84550929-544908429",
    partNumber: "84550929",
    description: "COVER-S/S RET B",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84550930-544908429",
    partNumber: "84550930",
    description: "COVER-S/S RET B",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84550931-544908429",
    partNumber: "84550931",
    description: "COVER-S/S RET B",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84550964-243459851",
    partNumber: "84550964",
    description: "GRILLE-ACTIVE N",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84550973-117409100",
    partNumber: "84550973",
    description: "STRAP ASM-R/SEA",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550974-117409100",
    partNumber: "84550974",
    description: "STRAP ASM-R/SEA",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550975-117409100",
    partNumber: "84550975",
    description: "STRAP ASM-R/SEA",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550976-117409100",
    partNumber: "84550976",
    description: "STRAP ASM-R/SEA",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550977-117409100",
    partNumber: "84550977",
    description: "STRAP ASM-R/SEA",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550978-117409100",
    partNumber: "84550978",
    description: "STRAP ASM-R/SEA",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550979-117409100",
    partNumber: "84550979",
    description: "STRAP ASM-R/SEA",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550980-117409100",
    partNumber: "84550980",
    description: "STRAP ASM-R/SEA",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550981-117409100",
    partNumber: "84550981",
    description: "STRAP ASM-R/SEA",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550982-117409100",
    partNumber: "84550982",
    description: "STRAP ASM-R/SEA",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550983-117409100",
    partNumber: "84550983",
    description: "STRAP ASM-R/SEA",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84550984-117409100",
    partNumber: "84550984",
    description: "STRAP ASM-R/SEA",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84553816-004467924",
    partNumber: "84553816",
    description: "TIRE-FRT & RR",
    supplierName: "GOODYEAR TIRE & RUBBER CO, THE",
    supplierAddress: "AKRON,OH",
    supplierStreet: "",
    supplierCity: "AKRON",
    supplierState: "OH",
    supplierZip: "303847348",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84554623-812928414",
    partNumber: "84554623",
    description: "CASE ASM-TRFER",
    supplierName: "MAGNA POWERTRAIN DE MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84554924-099051716",
    partNumber: "84554924",
    description: "RETAINER-T/LP",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84554925-099051716",
    partNumber: "84554925",
    description: "RETAINER-T/LP",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84554926-099051716",
    partNumber: "84554926",
    description: "RETAINER-T/LP",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84554927-099051716",
    partNumber: "84554927",
    description: "RETAINER-T/LP",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84555012-368135153",
    partNumber: "84555012",
    description: "MODULE ASM-ELEK",
    supplierName: "BWI POLAND TECHNOLOGIES SP ZOO",
    supplierAddress: "KROSNO,PD",
    supplierStreet: "",
    supplierCity: "KROSNO",
    supplierState: "PD",
    supplierZip: "38-400",
    supplierCountry: "PL",
  },
  {
    _id: "parts-gm-84557030-000243147",
    partNumber: "84557030",
    description: "DOCUMENT-INCOMP",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84557031-000243147",
    partNumber: "84557031",
    description: "DOCUMENT-INCOMP",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84557033-000243147",
    partNumber: "84557033",
    description: "SUPPLEMENT-OWNE",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84557549-545237257",
    partNumber: "84557549",
    description: "PLATE ASM-E/GAT",
    supplierName: "SRG GLOBAL (SUZHOU) CO LTD",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215021",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84560156-607039732",
    partNumber: "84560156",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84560164-607039732",
    partNumber: "84560164",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84560169-607039732",
    partNumber: "84560169",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84560203-607039732",
    partNumber: "84560203",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84560210-607039732",
    partNumber: "84560210",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84560239-607039732",
    partNumber: "84560239",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84560243-607039732",
    partNumber: "84560243",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84560564-079948011",
    partNumber: "84560564",
    description: "LAMP ASM-F/FLR",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84560565-079948011",
    partNumber: "84560565",
    description: "LAMP ASM-F/FLR",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84561254-108630731",
    partNumber: "84561254",
    description: "PANEL ASM-PLNM",
    supplierName: "KIRCHHOFF AUTOMOTIVE DALLAS INC",
    supplierAddress: "GARLAND,TX",
    supplierStreet: "",
    supplierCity: "GARLAND",
    supplierState: "TX",
    supplierZip: "750416051",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84561255-108630731",
    partNumber: "84561255",
    description: "PANEL ASM-PLNM",
    supplierName: "KIRCHHOFF AUTOMOTIVE DALLAS INC",
    supplierAddress: "GARLAND,TX",
    supplierStreet: "",
    supplierCity: "GARLAND",
    supplierState: "TX",
    supplierZip: "750416051",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84561258-812816935",
    partNumber: "84561258",
    description: "SWITCH ASM-HDLP",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84561260-812816935",
    partNumber: "84561260",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84561261-812816935",
    partNumber: "84561261",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84561262-812816935",
    partNumber: "84561262",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84561263-812816935",
    partNumber: "84561263",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84561264-812816935",
    partNumber: "84561264",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84561265-812816935",
    partNumber: "84561265",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84561266-812816935",
    partNumber: "84561266",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84561267-812816935",
    partNumber: "84561267",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84561268-812816935",
    partNumber: "84561268",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84561269-812816935",
    partNumber: "84561269",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84561514-812905909",
    partNumber: "84561514",
    description: "BRACKET ASM-FWD",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84564089-080010898",
    partNumber: "84564089",
    description: "MIRROR ASM-I/S",
    supplierName: "GENTEX CORPORATION",
    supplierAddress: "ZEELAND,MI",
    supplierStreet: "",
    supplierCity: "ZEELAND",
    supplierState: "MI",
    supplierZip: "494649717",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84564094-129617036",
    partNumber: "84564094",
    description: "HEADLAMP ASM-FR",
    supplierName: "DECOFINMEX SA DE CV",
    supplierAddress: "SAN BENITO,TX",
    supplierStreet: "",
    supplierCity: "SAN BENITO",
    supplierState: "TX",
    supplierZip: "78586",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84564095-129617036",
    partNumber: "84564095",
    description: "HEADLAMP ASM-FR",
    supplierName: "DECOFINMEX SA DE CV",
    supplierAddress: "SAN BENITO,TX",
    supplierStreet: "",
    supplierCity: "SAN BENITO",
    supplierState: "TX",
    supplierZip: "78586",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84564096-129617036",
    partNumber: "84564096",
    description: "HEADLAMP ASM-FR",
    supplierName: "DECOFINMEX SA DE CV",
    supplierAddress: "SAN BENITO,TX",
    supplierStreet: "",
    supplierCity: "SAN BENITO",
    supplierState: "TX",
    supplierZip: "78586",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84564686-545324639",
    partNumber: "84564686",
    description: "CORD ASM-ENG CO",
    supplierName: "HEBI THB INTERNATIONAL ELECTRIC CO",
    supplierAddress: "HEBI,41",
    supplierStreet: "",
    supplierCity: "HEBI",
    supplierState: "41",
    supplierZip: "458030",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84564795-005426853",
    partNumber: "84564795",
    description: "APPLIQUE ASM-R/",
    supplierName: "NORTHERN ENGRAVING CORP",
    supplierAddress: "WEST SALEM,WI",
    supplierStreet: "",
    supplierCity: "WEST SALEM",
    supplierState: "WI",
    supplierZip: "546691158",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84564796-005426853",
    partNumber: "84564796",
    description: "APPLIQUE ASM-R/",
    supplierName: "NORTHERN ENGRAVING CORP",
    supplierAddress: "WEST SALEM,WI",
    supplierStreet: "",
    supplierCity: "WEST SALEM",
    supplierState: "WI",
    supplierZip: "546691158",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84564937-529131017",
    partNumber: "84564937",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84564938-529131017",
    partNumber: "84564938",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84564939-529131017",
    partNumber: "84564939",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84564940-529131017",
    partNumber: "84564940",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84564941-529131017",
    partNumber: "84564941",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84564942-529131017",
    partNumber: "84564942",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84564943-005426853",
    partNumber: "84564943",
    description: "APPLIQUE ASM-I/",
    supplierName: "NORTHERN ENGRAVING CORP",
    supplierAddress: "WEST SALEM,WI",
    supplierStreet: "",
    supplierCity: "WEST SALEM",
    supplierState: "WI",
    supplierZip: "546691158",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84564944-005426853",
    partNumber: "84564944",
    description: "APPLIQUE ASM-I/",
    supplierName: "NORTHERN ENGRAVING CORP",
    supplierAddress: "WEST SALEM,WI",
    supplierStreet: "",
    supplierCity: "WEST SALEM",
    supplierState: "WI",
    supplierZip: "546691158",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84564945-005426853",
    partNumber: "84564945",
    description: "APPLIQUE ASM-I/",
    supplierName: "NORTHERN ENGRAVING CORP",
    supplierAddress: "WEST SALEM,WI",
    supplierStreet: "",
    supplierCity: "WEST SALEM",
    supplierState: "WI",
    supplierZip: "546691158",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84564946-005426853",
    partNumber: "84564946",
    description: "APPLIQUE ASM-I/",
    supplierName: "NORTHERN ENGRAVING CORP",
    supplierAddress: "WEST SALEM,WI",
    supplierStreet: "",
    supplierCity: "WEST SALEM",
    supplierState: "WI",
    supplierZip: "546691158",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84564949-544321108",
    partNumber: "84564949",
    description: "BEZEL ASM-I/P A",
    supplierName: "XUANCHENG TOP SUN SURFACE TECHNOLOG",
    supplierAddress: "XUANCHENG,34",
    supplierStreet: "",
    supplierCity: "XUANCHENG",
    supplierState: "34",
    supplierZip: "242100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84564952-932662133",
    partNumber: "84564952",
    description: "BEZEL-I/P ACSRY",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84564953-932662133",
    partNumber: "84564953",
    description: "BEZEL-I/P ACSRY",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84564954-932662133",
    partNumber: "84564954",
    description: "BEZEL-I/P ACSRY",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84564955-932662133",
    partNumber: "84564955",
    description: "BEZEL-I/P ACSRY",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84567924-420720559",
    partNumber: "84567924",
    description: "BUMPER-HOOD FRT",
    supplierName: "ANHUI ZHONGDING SEALING PARTS CO LT",
    supplierAddress: "NINGGUO,34",
    supplierStreet: "",
    supplierCity: "NINGGUO",
    supplierState: "34",
    supplierZip: "242300",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84570309-812366540",
    partNumber: "84570309",
    description: "WHEEL-FRT & RR",
    supplierName: "SUPERIOR INDUSTRIES DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31136",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84573264-812947059",
    partNumber: "84573264",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84574848-020837233",
    partNumber: "84574848",
    description: "BRACKET-ENG WRG",
    supplierName: "DAJACO INDUSTRIES INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512478",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84576918-086663101",
    partNumber: "84576918",
    description: "RAIL-F/CMPT UPR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84576919-086663101",
    partNumber: "84576919",
    description: "RAIL-F/CMPT UPR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84577728-969789283",
    partNumber: "84577728",
    description: "BRACKET-IGN LK",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84577729-969789283",
    partNumber: "84577729",
    description: "BRACKET-IGN LK",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84578672-081232109",
    partNumber: "84578672",
    description: "MODULE ASM-STRG",
    supplierName: "BCS AUTOMOTIVE INTERFACE SOLUTIONS",
    supplierAddress: "PHARR,TX",
    supplierStreet: "",
    supplierCity: "PHARR",
    supplierState: "TX",
    supplierZip: "785777294",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84578675-081232109",
    partNumber: "84578675",
    description: "MODULE ASM-STRG",
    supplierName: "BCS AUTOMOTIVE INTERFACE SOLUTIONS",
    supplierAddress: "PHARR,TX",
    supplierStreet: "",
    supplierCity: "PHARR",
    supplierState: "TX",
    supplierZip: "785777294",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84580182-785629952",
    partNumber: "84580182",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84580183-785629952",
    partNumber: "84580183",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84580186-785629952",
    partNumber: "84580186",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84580187-785629952",
    partNumber: "84580187",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84580312-812905909",
    partNumber: "84580312",
    description: "REINFORCEMENT-R",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84580313-812905909",
    partNumber: "84580313",
    description: "REINFORCEMENT-R",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84580314-812905909",
    partNumber: "84580314",
    description: "REINFORCEMENT-R",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84580315-812905909",
    partNumber: "84580315",
    description: "REINFORCEMENT-R",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84580360-812803176",
    partNumber: "84580360",
    description: "FAN ASM-ENG CLR",
    supplierName: "ROBERT BOSCH MEXICO SISTEMAS AUTOM",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76246",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84580361-812803176",
    partNumber: "84580361",
    description: "FAN ASM-ENG CLR",
    supplierName: "ROBERT BOSCH MEXICO SISTEMAS AUTOM",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76246",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84580363-812803176",
    partNumber: "84580363",
    description: "FAN ASM-ENG CLR",
    supplierName: "ROBERT BOSCH MEXICO SISTEMAS AUTOM",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76246",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84582015-812555407",
    partNumber: "84582015",
    description: "ROTOR-RR BRK (C",
    supplierName: "RASSINI FRENOS SA DE CV",
    supplierAddress: "SAN MARTIN TEXMELUCAN,PU",
    supplierStreet: "",
    supplierCity: "SAN MARTIN TEXMELUCAN",
    supplierState: "PU",
    supplierZip: "74129",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84582569-129617036",
    partNumber: "84582569",
    description: "HEADLAMP ASM-FR",
    supplierName: "DECOFINMEX SA DE CV",
    supplierAddress: "SAN BENITO,TX",
    supplierStreet: "",
    supplierCity: "SAN BENITO",
    supplierState: "TX",
    supplierZip: "78586",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84582570-129617036",
    partNumber: "84582570",
    description: "HEADLAMP ASM-FR",
    supplierName: "DECOFINMEX SA DE CV",
    supplierAddress: "SAN BENITO,TX",
    supplierStreet: "",
    supplierCity: "SAN BENITO",
    supplierState: "TX",
    supplierZip: "78586",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84582571-129617036",
    partNumber: "84582571",
    description: "HEADLAMP ASM-FR",
    supplierName: "DECOFINMEX SA DE CV",
    supplierAddress: "SAN BENITO,TX",
    supplierStreet: "",
    supplierCity: "SAN BENITO",
    supplierState: "TX",
    supplierZip: "78586",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84582572-129617036",
    partNumber: "84582572",
    description: "HEADLAMP ASM-FR",
    supplierName: "DECOFINMEX SA DE CV",
    supplierAddress: "SAN BENITO,TX",
    supplierStreet: "",
    supplierCity: "SAN BENITO",
    supplierState: "TX",
    supplierZip: "78586",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84582598-129617036",
    partNumber: "84582598",
    description: "HEADLAMP ASM-FR",
    supplierName: "DECOFINMEX SA DE CV",
    supplierAddress: "SAN BENITO,TX",
    supplierStreet: "",
    supplierCity: "SAN BENITO",
    supplierState: "TX",
    supplierZip: "78586",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84582675-545337164",
    partNumber: "84582675",
    description: "OUTLET ASM-AUX",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84582676-545337164",
    partNumber: "84582676",
    description: "OUTLET ASM-AUX",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84582677-545337164",
    partNumber: "84582677",
    description: "OUTLET ASM-AUX",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84582678-545337164",
    partNumber: "84582678",
    description: "BEZEL-AUX A/C A",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84582679-545337164",
    partNumber: "84582679",
    description: "BEZEL-AUX A/C A",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84582680-545337164",
    partNumber: "84582680",
    description: "BEZEL-AUX A/C A",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84582681-545337164",
    partNumber: "84582681",
    description: "OUTLET ASM-AUX",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84582682-545337164",
    partNumber: "84582682",
    description: "OUTLET ASM-AUX",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84582683-545337164",
    partNumber: "84582683",
    description: "OUTLET ASM-AUX",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84585712-796778889",
    partNumber: "84585712",
    description: "PLUG-RR S/ABS A",
    supplierName: "PROTECTIVE INDUSTRIES INC",
    supplierAddress: "ERIE,PA",
    supplierStreet: "",
    supplierCity: "ERIE",
    supplierState: "PA",
    supplierZip: "165094447",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587349-808263656",
    partNumber: "84587349",
    description: "CARPET ASM-INTE",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587350-808263656",
    partNumber: "84587350",
    description: "CARPET ASM-INTE",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587352-808263656",
    partNumber: "84587352",
    description: "CARPET ASM-INTE",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587353-808263656",
    partNumber: "84587353",
    description: "CARPET ASM-INTE",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587355-808263656",
    partNumber: "84587355",
    description: "CARPET ASM-INTE",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587356-808263656",
    partNumber: "84587356",
    description: "CARPET ASM-INTE",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587358-808263656",
    partNumber: "84587358",
    description: "CARPET ASM-INTE",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587361-808263656",
    partNumber: "84587361",
    description: "CARPET ASM-R/FL",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587363-808263656",
    partNumber: "84587363",
    description: "CARPET-R/FLR PN",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587364-808263656",
    partNumber: "84587364",
    description: "CARPET-R/FLR PN",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587365-808263656",
    partNumber: "84587365",
    description: "CARPET-R/FLR PN",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587366-808263656",
    partNumber: "84587366",
    description: "CARPET-R/FLR PN",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587367-808263656",
    partNumber: "84587367",
    description: "CARPET-R/FLR PN",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587986-080786860",
    partNumber: "84587986",
    description: "PLATE ASM-I/P C",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587990-080786860",
    partNumber: "84587990",
    description: "PLATE ASM-I/P C",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84587995-080786860",
    partNumber: "84587995",
    description: "PLATE ASM-I/P C",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84588000-812888257",
    partNumber: "84588000",
    description: "COVER ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84588006-812888257",
    partNumber: "84588006",
    description: "COVER ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84588010-812888257",
    partNumber: "84588010",
    description: "COVER ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84588025-812888257",
    partNumber: "84588025",
    description: "COVER ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84588026-812888257",
    partNumber: "84588026",
    description: "COVER ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84588027-812888257",
    partNumber: "84588027",
    description: "COVER ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84590447-829470223",
    partNumber: "84590447",
    description: "RECEPTACLE ASM-",
    supplierName: "CASCO PRODUCTS CORP",
    supplierAddress: "MORGANTOWN,KY",
    supplierStreet: "",
    supplierCity: "MORGANTOWN",
    supplierState: "KY",
    supplierZip: "422617361",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84591014-042321828",
    partNumber: "84591014",
    description: "ARM ASM-RR SUSP",
    supplierName: "IROQUOIS INDUSTRIES INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891425",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84591015-042321828",
    partNumber: "84591015",
    description: "ARM ASM-RR SUSP",
    supplierName: "IROQUOIS INDUSTRIES INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891425",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84591666-000202272",
    partNumber: "84591666",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84591667-000202272",
    partNumber: "84591667",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84591668-000202272",
    partNumber: "84591668",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84591669-000202272",
    partNumber: "84591669",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84591670-000202272",
    partNumber: "84591670",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84591671-000202272",
    partNumber: "84591671",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84591672-000202272",
    partNumber: "84591672",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84591673-000202272",
    partNumber: "84591673",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84591674-000202272",
    partNumber: "84591674",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84591675-000202272",
    partNumber: "84591675",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84591676-000202272",
    partNumber: "84591676",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84591677-000202272",
    partNumber: "84591677",
    description: "COVER-3RD ROW S",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84594002-814180139",
    partNumber: "84594002",
    description: "COVER ASM-F/SEA",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84602081-121584338",
    partNumber: "84602081",
    description: "FENDER ASM-FRT",
    supplierName: "GM METAL FAB-MARION",
    supplierAddress: "MARION,IN",
    supplierStreet: "",
    supplierCity: "MARION",
    supplierState: "IN",
    supplierZip: "46952",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84602082-121584338",
    partNumber: "84602082",
    description: "FENDER ASM-FRT",
    supplierName: "GM METAL FAB-MARION",
    supplierAddress: "MARION,IN",
    supplierStreet: "",
    supplierCity: "MARION",
    supplierState: "IN",
    supplierZip: "46952",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84603029-202679841",
    partNumber: "84603029",
    description: "ROD-FRT S/D LK",
    supplierName: "MAGNA CLOSURES INC",
    supplierAddress: "PENETANGUISHENE,ON",
    supplierStreet: "",
    supplierCity: "PENETANGUISHENE",
    supplierState: "ON",
    supplierZip: "L9M 1G8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84604843-080786860",
    partNumber: "84604843",
    description: "SLEEVE-F/FLR CN",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84605188-812816935",
    partNumber: "84605188",
    description: "SWITCH ASM-HDLP",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84605189-812816935",
    partNumber: "84605189",
    description: "SWITCH ASM-HDLP",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84605731-042439265",
    partNumber: "84605731",
    description: "CLAMP-FRT STAB",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84605821-812928414",
    partNumber: "84605821",
    description: "CASE ASM-TRFER",
    supplierName: "MAGNA POWERTRAIN DE MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84605824-812928414",
    partNumber: "84605824",
    description: "CASE ASM-TRFER",
    supplierName: "MAGNA POWERTRAIN DE MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84605825-812928414",
    partNumber: "84605825",
    description: "CASE ASM-TRFER",
    supplierName: "MAGNA POWERTRAIN DE MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84605935-117409100",
    partNumber: "84605935",
    description: "REINFORCEMENT A",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84605942-545337164",
    partNumber: "84605942",
    description: "OUTLET ASM-AUX",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84605943-545337164",
    partNumber: "84605943",
    description: "BEZEL-AUX A/C A",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84606009-812947059",
    partNumber: "84606009",
    description: "LINER ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84606011-812947059",
    partNumber: "84606011",
    description: "LINER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84607470-814814042",
    partNumber: "84607470",
    description: "HOSE ASM-RAD OT",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84607560-814814042",
    partNumber: "84607560",
    description: "HOSE ASM-ENG CO",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84607818-969789283",
    partNumber: "84607818",
    description: "EXTENSION ASM-A",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84607819-969789283",
    partNumber: "84607819",
    description: "EXTENSION ASM-A",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84607820-969789283",
    partNumber: "84607820",
    description: "EXTENSION ASM-A",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84607821-969789283",
    partNumber: "84607821",
    description: "EXTENSION ASM-A",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84607822-969789283",
    partNumber: "84607822",
    description: "EXTENSION ASM-A",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84607823-969789283",
    partNumber: "84607823",
    description: "EXTENSION ASM-A",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84608640-050150481",
    partNumber: "84608640",
    description: "SHIELD-EXH FRT",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84608641-050150481",
    partNumber: "84608641",
    description: "SHIELD-EXH FRT",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84608863-202888707",
    partNumber: "84608863",
    description: "BRACKET -CONTEN",
    supplierName: "MARWOOD INTERNATIONAL INC",
    supplierAddress: "WOODSTOCK,ON",
    supplierStreet: "",
    supplierCity: "WOODSTOCK",
    supplierState: "ON",
    supplierZip: "N4S 7L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84609542-122505659",
    partNumber: "84609542",
    description: "MODULE ASM-ELEK",
    supplierName: "ZF ACTIVE SAFETY US INC",
    supplierAddress: "FOWLERVILLE,MI",
    supplierStreet: "",
    supplierCity: "FOWLERVILLE",
    supplierState: "MI",
    supplierZip: "488367908",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84610506-000202236",
    partNumber: "84610506",
    description: "ANTENNA ASM-AUX",
    supplierName: "TE CONNECTIVITY HK LTD",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215000",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84612179-812888154",
    partNumber: "84612179",
    description: "WHEEL-FRT & RR",
    supplierName: "SUPERIOR INDUSTRIES DE MEXICO SA DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31090",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84612180-812888154",
    partNumber: "84612180",
    description: "WHEEL-FRT & RR",
    supplierName: "SUPERIOR INDUSTRIES DE MEXICO SA DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31090",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84619868-080203455",
    partNumber: "84619868",
    description: "HOSE ASM-SUN RF",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84619870-080203455",
    partNumber: "84619870",
    description: "HOSE ASM-SUN RF",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84619872-080203455",
    partNumber: "84619872",
    description: "HOSE ASM-SUN RF",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84623057-142199376",
    partNumber: "84623057",
    description: "SHIELD-U/B SKID",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84624327-000246272",
    partNumber: "84624327",
    description: "MICROPHONE ASM-",
    supplierName: "HOSIDEN AMERICA CORP",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "48174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84624329-000246272",
    partNumber: "84624329",
    description: "MICROPHONE ASM-",
    supplierName: "HOSIDEN AMERICA CORP",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "48174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84624330-000246272",
    partNumber: "84624330",
    description: "MICROPHONE ASM-",
    supplierName: "HOSIDEN AMERICA CORP",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "48174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84624331-000246272",
    partNumber: "84624331",
    description: "MICROPHONE ASM-",
    supplierName: "HOSIDEN AMERICA CORP",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "48174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84624333-000246272",
    partNumber: "84624333",
    description: "MICROPHONE ASM-",
    supplierName: "HOSIDEN AMERICA CORP",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "48174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84624334-000246272",
    partNumber: "84624334",
    description: "MICROPHONE ASM-",
    supplierName: "HOSIDEN AMERICA CORP",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "48174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84625280-000243147",
    partNumber: "84625280",
    description: "BROCHURE-VEH DL",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84625310-654593565",
    partNumber: "84625310",
    description: "PLATE ASM-L/GAT",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84625330-086663101",
    partNumber: "84625330",
    description: "RAIL ASM-RF INR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84625331-086663101",
    partNumber: "84625331",
    description: "RAIL ASM-RF INR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84625752-812807729",
    partNumber: "84625752",
    description: "MAT PKG-FLR AUX",
    supplierName: "AGM DURMONT MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76295",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84626104-812807729",
    partNumber: "84626104",
    description: "MAT PKG-FLR AUX",
    supplierName: "AGM DURMONT MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76295",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84626106-812807729",
    partNumber: "84626106",
    description: "MAT PKG-FLR AUX",
    supplierName: "AGM DURMONT MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76295",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84626112-812807729",
    partNumber: "84626112",
    description: "MAT PKG-FLR AUX",
    supplierName: "AGM DURMONT MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76295",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84627080-000190694",
    partNumber: "84627080",
    description: "TOOL KIT-.",
    supplierName: "VENTRAMEX SA DE C V",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84627753-812246809",
    partNumber: "84627753",
    description: "HOSE ASM-TRFER",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84628487-812895704",
    partNumber: "84628487",
    description: "ARM ASM-FRT UPR",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84630194-814180139",
    partNumber: "84630194",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84630334-544908429",
    partNumber: "84630334",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84630340-544908429",
    partNumber: "84630340",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84631636-688001481",
    partNumber: "84631636",
    description: "MOUNT ASM-ENG",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84631637-688001481",
    partNumber: "84631637",
    description: "MOUNT ASM-ENG",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84631650-108630731",
    partNumber: "84631650",
    description: "PANEL ASM-F/END",
    supplierName: "KIRCHHOFF AUTOMOTIVE DALLAS INC",
    supplierAddress: "GARLAND,TX",
    supplierStreet: "",
    supplierCity: "GARLAND",
    supplierState: "TX",
    supplierZip: "750416051",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84631651-108630731",
    partNumber: "84631651",
    description: "PANEL ASM-F/END",
    supplierName: "KIRCHHOFF AUTOMOTIVE DALLAS INC",
    supplierAddress: "GARLAND,TX",
    supplierStreet: "",
    supplierCity: "GARLAND",
    supplierState: "TX",
    supplierZip: "750416051",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84632363-080203455",
    partNumber: "84632363",
    description: "HOSE ASM-SUN RF",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84632822-808587047",
    partNumber: "84632822",
    description: "TANK ASM-RAD SU",
    supplierName: "NOVARES US LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129730",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84636168-812246809",
    partNumber: "84636168",
    description: "HOSE ASM-R/AXL",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84636169-812246809",
    partNumber: "84636169",
    description: "HOSE ASM-R/AXL",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84636203-086663101",
    partNumber: "84636203",
    description: "REINFORCEMENT-U",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84638590-588159637",
    partNumber: "84638590",
    description: "SENSOR ASM-FRT",
    supplierName: "CONTINENTAL AUTOMOTIVE",
    supplierAddress: "GUANAJUATO,GJ",
    supplierStreet: "",
    supplierCity: "GUANAJUATO",
    supplierState: "GJ",
    supplierZip: "36270",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84638600-202888707",
    partNumber: "84638600",
    description: "BRACKET ASM-RR",
    supplierName: "MARWOOD INTERNATIONAL INC",
    supplierAddress: "WOODSTOCK,ON",
    supplierStreet: "",
    supplierCity: "WOODSTOCK",
    supplierState: "ON",
    supplierZip: "N4S 7L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84638601-202888707",
    partNumber: "84638601",
    description: "BRACKET ASM-RR",
    supplierName: "MARWOOD INTERNATIONAL INC",
    supplierAddress: "WOODSTOCK,ON",
    supplierStreet: "",
    supplierCity: "WOODSTOCK",
    supplierState: "ON",
    supplierZip: "N4S 7L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84640392-042439265",
    partNumber: "84640392",
    description: "REINFORCEMENT A",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84640393-042439265",
    partNumber: "84640393",
    description: "REINFORCEMENT A",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84640394-042439265",
    partNumber: "84640394",
    description: "REINFORCEMENT A",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84640395-042439265",
    partNumber: "84640395",
    description: "REINFORCEMENT A",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84640413-065052011",
    partNumber: "84640413",
    description: "TIRE-265/65R18",
    supplierName: "MICHELIN NORTH AMERICA INC",
    supplierAddress: "GREENVILLE,SC",
    supplierStreet: "",
    supplierCity: "GREENVILLE",
    supplierState: "SC",
    supplierZip: "296056107",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84640866-146257907",
    partNumber: "84640866",
    description: "REINFORCEMENT A",
    supplierName: "RIDGEVIEW INDUSTRY INC",
    supplierAddress: "HUNTSVILLE,AL",
    supplierStreet: "",
    supplierCity: "HUNTSVILLE",
    supplierState: "AL",
    supplierZip: "358033938",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84641758-077472228",
    partNumber: "84641758",
    description: "GUTTER-BODY SI",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84641759-077472228",
    partNumber: "84641759",
    description: "GUTTER-BODY SI",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84641760-077472228",
    partNumber: "84641760",
    description: "POCKET-BODY SI",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84641761-077472228",
    partNumber: "84641761",
    description: "POCKET-BODY SI",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84642281-812537363",
    partNumber: "84642281",
    description: "BUMPER ASM-HOOD",
    supplierName: "AUTOMOTIVE VERITAS DE",
    supplierAddress: "CUAUTLANCINGO,PU",
    supplierStreet: "",
    supplierCity: "CUAUTLANCINGO",
    supplierState: "PU",
    supplierZip: "72730",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84642305-000246272",
    partNumber: "84642305",
    description: "MICROPHONE ASM-",
    supplierName: "HOSIDEN AMERICA CORP",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "48174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84642724-042439265",
    partNumber: "84642724",
    description: "WASHER-BODY MT",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84644006-607039732",
    partNumber: "84644006",
    description: "HARNESS ASM-TRF",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84645616-808587047",
    partNumber: "84645616",
    description: "TANK ASM-RAD SU",
    supplierName: "NOVARES US LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129730",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84646022-812905909",
    partNumber: "84646022",
    description: "BRACKET ASM-F/P",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84646133-607039732",
    partNumber: "84646133",
    description: "BLOCK ASM-BAT D",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84646343-654129907",
    partNumber: "84646343",
    description: "HANDLE ASM-R/RL",
    supplierName: "NINGBO JOYSON TECHNOLOGY CO LTD",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200444",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84646683-654129907",
    partNumber: "84646683",
    description: "HANDLE ASM-R/RL",
    supplierName: "NINGBO JOYSON TECHNOLOGY CO LTD",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200444",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84646684-654129907",
    partNumber: "84646684",
    description: "HANDLE ASM-R/RL",
    supplierName: "NINGBO JOYSON TECHNOLOGY CO LTD",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200444",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84646843-831831438",
    partNumber: "84646843",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84646844-831831438",
    partNumber: "84646844",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84646948-812813283",
    partNumber: "84646948",
    description: "ARM ASM-FRT UPR",
    supplierName: "NEUMAN ALUMINIUM RAUFOSS",
    supplierAddress: "LAGOS DE MORENO,JA",
    supplierStreet: "",
    supplierCity: "LAGOS DE MORENO",
    supplierState: "JA",
    supplierZip: "47515",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84646950-812813283",
    partNumber: "84646950",
    description: "ARM ASM-FRT UPR",
    supplierName: "NEUMAN ALUMINIUM RAUFOSS",
    supplierAddress: "LAGOS DE MORENO,JA",
    supplierStreet: "",
    supplierCity: "LAGOS DE MORENO",
    supplierState: "JA",
    supplierZip: "47515",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84647039-812366540",
    partNumber: "84647039",
    description: "WHEEL-FRT & RR",
    supplierName: "SUPERIOR INDUSTRIES DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31136",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84648154-607039732",
    partNumber: "84648154",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84648309-086663101",
    partNumber: "84648309",
    description: "BRACE-QTR INR P",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84648310-086663101",
    partNumber: "84648310",
    description: "BRACE-QTR INR P",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84649157-137355322",
    partNumber: "84649157",
    description: "SWITCH ASM-VEH",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84649158-137355322",
    partNumber: "84649158",
    description: "SWITCH ASM-VEH",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84649159-137355322",
    partNumber: "84649159",
    description: "SWITCH ASM-VEH",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84649160-137355322",
    partNumber: "84649160",
    description: "SWITCH ASM-VEH",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84649161-137355322",
    partNumber: "84649161",
    description: "SWITCH ASM-VEH",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84649162-137355322",
    partNumber: "84649162",
    description: "SWITCH ASM-VEH",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84649163-137355322",
    partNumber: "84649163",
    description: "SWITCH ASM-VEH",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84649164-137355322",
    partNumber: "84649164",
    description: "SWITCH ASM-VEH",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84649165-137355322",
    partNumber: "84649165",
    description: "SWITCH ASM-VEH",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84649166-137355322",
    partNumber: "84649166",
    description: "SWITCH ASM-VEH",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84649214-080203455",
    partNumber: "84649214",
    description: "HOSE ASM-SUN RF",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84649215-080203455",
    partNumber: "84649215",
    description: "HOSE ASM-SUN RF",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84649763-175597137",
    partNumber: "84649763",
    description: "DISPLAY ASM-HD",
    supplierName: "NEW SABINA INDUSTRIES INC",
    supplierAddress: "SABINA,OH",
    supplierStreet: "",
    supplierCity: "SABINA",
    supplierState: "OH",
    supplierZip: "451699463",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84649863-814814042",
    partNumber: "84649863",
    description: "HOSE ASM-RAD SU",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84649864-814814042",
    partNumber: "84649864",
    description: "HOSE ASM-RAD SU",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84651038-003432510",
    partNumber: "84651038",
    description: "BRACKET-FUEL &",
    supplierName: "DANA THERMAL PRODUCTS LLC",
    supplierAddress: "SAINT CLAIR,MI",
    supplierStreet: "",
    supplierCity: "SAINT CLAIR",
    supplierState: "MI",
    supplierZip: "480795701",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84651487-829470223",
    partNumber: "84651487",
    description: "RETAINER ASM-F/",
    supplierName: "CASCO PRODUCTS CORP",
    supplierAddress: "MORGANTOWN,KY",
    supplierStreet: "",
    supplierCity: "MORGANTOWN",
    supplierState: "KY",
    supplierZip: "422617361",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84651488-829470223",
    partNumber: "84651488",
    description: "RETAINER ASM-F/",
    supplierName: "CASCO PRODUCTS CORP",
    supplierAddress: "MORGANTOWN,KY",
    supplierStreet: "",
    supplierCity: "MORGANTOWN",
    supplierState: "KY",
    supplierZip: "422617361",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84651510-607039732",
    partNumber: "84651510",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84651519-607039732",
    partNumber: "84651519",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84653466-969789283",
    partNumber: "84653466",
    description: "BAFFLE-RAD AIR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84654588-000197855",
    partNumber: "84654588",
    description: "PLATE-M/TEL MIC",
    supplierName: "HARMAN EMBEDDED AUDIO LLC",
    supplierAddress: "INDIANAPOLIS,IN",
    supplierStreet: "",
    supplierCity: "INDIANAPOLIS",
    supplierState: "IN",
    supplierZip: "46268",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84655312-146257907",
    partNumber: "84655312",
    description: "REINFORCEMENT A",
    supplierName: "RIDGEVIEW INDUSTRY INC",
    supplierAddress: "HUNTSVILLE,AL",
    supplierStreet: "",
    supplierCity: "HUNTSVILLE",
    supplierState: "AL",
    supplierZip: "358033938",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84655313-146257907",
    partNumber: "84655313",
    description: "REINFORCEMENT A",
    supplierName: "RIDGEVIEW INDUSTRY INC",
    supplierAddress: "HUNTSVILLE,AL",
    supplierStreet: "",
    supplierCity: "HUNTSVILLE",
    supplierState: "AL",
    supplierZip: "358033938",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84655466-812947059",
    partNumber: "84655466",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84655467-812947059",
    partNumber: "84655467",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84655468-812947059",
    partNumber: "84655468",
    description: "COVER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84655478-816638795",
    partNumber: "84655478",
    description: "BRACKET-FRT BPR",
    supplierName: "LAMTEC MEXICO S DE RL DE CV",
    supplierAddress: "EL MARQUES,QA",
    supplierStreet: "",
    supplierCity: "EL MARQUES",
    supplierState: "QA",
    supplierZip: "76246",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84655838-545288953",
    partNumber: "84655838",
    description: "BUMPER-L/GATE L",
    supplierName: "QINGDAO DONGMING AUTOFITTING CO LTD",
    supplierAddress: "QINGDAO,37",
    supplierStreet: "",
    supplierCity: "QINGDAO",
    supplierState: "37",
    supplierZip: "266706",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84655839-420720559",
    partNumber: "84655839",
    description: "BUMPER-HOOD FRT",
    supplierName: "ANHUI ZHONGDING SEALING PARTS CO LT",
    supplierAddress: "NINGGUO,34",
    supplierStreet: "",
    supplierCity: "NINGGUO",
    supplierState: "34",
    supplierZip: "242300",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84655873-050476923",
    partNumber: "84655873",
    description: "SPEAKER ASM-RDO",
    supplierName: "BOSE CORP",
    supplierAddress: "SAN DIEGO,CA",
    supplierStreet: "",
    supplierCity: "SAN DIEGO",
    supplierState: "CA",
    supplierZip: "921546216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84656774-080786860",
    partNumber: "84656774",
    description: "PANEL ASM-I/P L",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84656775-080786860",
    partNumber: "84656775",
    description: "PANEL ASM-I/P L",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84657523-086663101",
    partNumber: "84657523",
    description: "RAIL ASM-RF INR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84657524-086663101",
    partNumber: "84657524",
    description: "RAIL ASM-RF INR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84658904-005356662",
    partNumber: "84658904",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84658905-005356662",
    partNumber: "84658905",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84658906-005356662",
    partNumber: "84658906",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84658907-005356662",
    partNumber: "84658907",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84658908-005356662",
    partNumber: "84658908",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84658909-005356662",
    partNumber: "84658909",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84658910-005356662",
    partNumber: "84658910",
    description: "REINFORCEMENT-F",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84658911-005356662",
    partNumber: "84658911",
    description: "REINFORCEMENT-F",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84658912-005356662",
    partNumber: "84658912",
    description: "REINFORCEMENT-F",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84660788-544908429",
    partNumber: "84660788",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660789-544908429",
    partNumber: "84660789",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660790-544908429",
    partNumber: "84660790",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660791-544908429",
    partNumber: "84660791",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660792-544908429",
    partNumber: "84660792",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660793-544908429",
    partNumber: "84660793",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660794-544908429",
    partNumber: "84660794",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660795-544908429",
    partNumber: "84660795",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660798-544908429",
    partNumber: "84660798",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660799-544908429",
    partNumber: "84660799",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660800-544908429",
    partNumber: "84660800",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660801-544908429",
    partNumber: "84660801",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660802-544908429",
    partNumber: "84660802",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660803-544908429",
    partNumber: "84660803",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660804-544908429",
    partNumber: "84660804",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660805-544908429",
    partNumber: "84660805",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660806-544908429",
    partNumber: "84660806",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660807-544908429",
    partNumber: "84660807",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660808-544908429",
    partNumber: "84660808",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660809-544908429",
    partNumber: "84660809",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660810-544908429",
    partNumber: "84660810",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660811-544908429",
    partNumber: "84660811",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660816-544908429",
    partNumber: "84660816",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660817-544908429",
    partNumber: "84660817",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660818-544908429",
    partNumber: "84660818",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660819-544908429",
    partNumber: "84660819",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660820-544908429",
    partNumber: "84660820",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660821-544908429",
    partNumber: "84660821",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660822-544908429",
    partNumber: "84660822",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660823-544908429",
    partNumber: "84660823",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660824-544908429",
    partNumber: "84660824",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660825-544908429",
    partNumber: "84660825",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660826-544908429",
    partNumber: "84660826",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660827-544908429",
    partNumber: "84660827",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660828-544908429",
    partNumber: "84660828",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660829-544908429",
    partNumber: "84660829",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660830-544908429",
    partNumber: "84660830",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660831-544908429",
    partNumber: "84660831",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660835-544908429",
    partNumber: "84660835",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660836-544908429",
    partNumber: "84660836",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660837-544908429",
    partNumber: "84660837",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660838-544908429",
    partNumber: "84660838",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84660901-544908429",
    partNumber: "84660901",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84663099-812888257",
    partNumber: "84663099",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84663100-812888257",
    partNumber: "84663100",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84663101-812888257",
    partNumber: "84663101",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84663102-812888257",
    partNumber: "84663102",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84663388-544979545",
    partNumber: "84663388",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84663715-688197008",
    partNumber: "84663715",
    description: "STRUT ASM-L/GAT",
    supplierName: "HANIL PRECISION CO LTD",
    supplierAddress: "GIMHAE,48",
    supplierStreet: "",
    supplierCity: "GIMHAE",
    supplierState: "48",
    supplierZip: "50805",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84663716-688197008",
    partNumber: "84663716",
    description: "STRUT ASM-L/GAT",
    supplierName: "HANIL PRECISION CO LTD",
    supplierAddress: "GIMHAE,48",
    supplierStreet: "",
    supplierCity: "GIMHAE",
    supplierState: "48",
    supplierZip: "50805",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84664057-117409100",
    partNumber: "84664057",
    description: "REINFORCEMENT A",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84664255-005245873",
    partNumber: "84664255",
    description: "SHIELD ASM-WSW",
    supplierName: "GDC INC",
    supplierAddress: "GOSHEN,IN",
    supplierStreet: "",
    supplierCity: "GOSHEN",
    supplierState: "IN",
    supplierZip: "465283508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84664452-808141266",
    partNumber: "84664452",
    description: "BAR ASM-RR BPR",
    supplierName: "SHAPE CORP",
    supplierAddress: "GRAND HAVEN,MI",
    supplierStreet: "",
    supplierCity: "GRAND HAVEN",
    supplierState: "MI",
    supplierZip: "494179428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84664454-808141266",
    partNumber: "84664454",
    description: "STIFFENER-FRT B",
    supplierName: "SHAPE CORP",
    supplierAddress: "GRAND HAVEN,MI",
    supplierStreet: "",
    supplierCity: "GRAND HAVEN",
    supplierState: "MI",
    supplierZip: "494179428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84664455-808141266",
    partNumber: "84664455",
    description: "STIFFENER-FRT B",
    supplierName: "SHAPE CORP",
    supplierAddress: "GRAND HAVEN,MI",
    supplierStreet: "",
    supplierCity: "GRAND HAVEN",
    supplierState: "MI",
    supplierZip: "494179428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84664529-096141098",
    partNumber: "84664529",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE OKLAHOMA INC",
    supplierAddress: "FREDERICK,OK",
    supplierStreet: "",
    supplierCity: "FREDERICK",
    supplierState: "OK",
    supplierZip: "735427000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84664530-096141098",
    partNumber: "84664530",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE OKLAHOMA INC",
    supplierAddress: "FREDERICK,OK",
    supplierStreet: "",
    supplierCity: "FREDERICK",
    supplierState: "OK",
    supplierZip: "735427000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84664540-654558089",
    partNumber: "84664540",
    description: "SPEAKER ASM-RDO",
    supplierName: "SUZHOU SONAVOX ELECTRONICS CO LTD",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215133",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84664769-814814042",
    partNumber: "84664769",
    description: "HOSE ASM-CHRG A",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84664811-117409100",
    partNumber: "84664811",
    description: "BRACKET-RR S/D",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84664812-117409100",
    partNumber: "84664812",
    description: "BRACKET-RR S/D",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84664826-080399938",
    partNumber: "84664826",
    description: "ABSORBER-RR HDR",
    supplierName: "COASTAL AUTOMOTIVE LLC",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "494235337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84664890-812947059",
    partNumber: "84664890",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84664891-812947059",
    partNumber: "84664891",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84664892-812947059",
    partNumber: "84664892",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84664894-812947059",
    partNumber: "84664894",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84664895-812947059",
    partNumber: "84664895",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84664896-812947059",
    partNumber: "84664896",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84664897-812947059",
    partNumber: "84664897",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84664899-812947059",
    partNumber: "84664899",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84664900-812947059",
    partNumber: "84664900",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84665093-654129907",
    partNumber: "84665093",
    description: "HOOK ASM-COAT",
    supplierName: "NINGBO JOYSON TECHNOLOGY CO LTD",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200444",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84665094-654129907",
    partNumber: "84665094",
    description: "HOOK ASM-COAT",
    supplierName: "NINGBO JOYSON TECHNOLOGY CO LTD",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200444",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84665095-654129907",
    partNumber: "84665095",
    description: "HOOK ASM-COAT",
    supplierName: "NINGBO JOYSON TECHNOLOGY CO LTD",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200444",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84665294-812888257",
    partNumber: "84665294",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84665295-812888257",
    partNumber: "84665295",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84665296-812888257",
    partNumber: "84665296",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84665861-827836214",
    partNumber: "84665861",
    description: "SENSOR ASM-W/S",
    supplierName: "HELLA ELECTRONICS CORP",
    supplierAddress: "FLORA,IL",
    supplierStreet: "",
    supplierCity: "FLORA",
    supplierState: "IL",
    supplierZip: "628393440",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84666010-078881928",
    partNumber: "84666010",
    description: "SILL ASM-U/B #3",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84666345-545337164",
    partNumber: "84666345",
    description: "OUTLET ASM-I/P",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84666653-812888257",
    partNumber: "84666653",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84666654-812888257",
    partNumber: "84666654",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84666655-812888257",
    partNumber: "84666655",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84666656-812888257",
    partNumber: "84666656",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84666657-812888257",
    partNumber: "84666657",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84666658-812888257",
    partNumber: "84666658",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84668577-812947059",
    partNumber: "84668577",
    description: "REINFORCEMENT-H",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84668578-812947059",
    partNumber: "84668578",
    description: "REINFORCEMENT-H",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84668836-042439265",
    partNumber: "84668836",
    description: "BRACKET ASM-F/F",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84668837-042439265",
    partNumber: "84668837",
    description: "BRACKET ASM-F/F",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84668838-042439265",
    partNumber: "84668838",
    description: "BRACKET ASM-F/F",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84668839-042439265",
    partNumber: "84668839",
    description: "BRACKET ASM-F/F",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84669232-544908429",
    partNumber: "84669232",
    description: "SUNSHADE ASM-.",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84669246-812895704",
    partNumber: "84669246",
    description: "BRACKET ASM-I/P",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84670205-545337164",
    partNumber: "84670205",
    description: "OUTLET ASM-I/P",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84670206-545337164",
    partNumber: "84670206",
    description: "OUTLET ASM-I/P",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84670207-545337164",
    partNumber: "84670207",
    description: "OUTLET ASM-I/P",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84670208-545337164",
    partNumber: "84670208",
    description: "OUTLET ASM-I/P",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84670209-545337164",
    partNumber: "84670209",
    description: "OUTLET ASM-I/P",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84670210-545337164",
    partNumber: "84670210",
    description: "OUTLET ASM-I/P",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84670215-812888257",
    partNumber: "84670215",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84670216-812888257",
    partNumber: "84670216",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84670217-812888257",
    partNumber: "84670217",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84670218-812888257",
    partNumber: "84670218",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84670219-812888257",
    partNumber: "84670219",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84670220-812888257",
    partNumber: "84670220",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84670221-812888257",
    partNumber: "84670221",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84670222-812888257",
    partNumber: "84670222",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84672504-315960245",
    partNumber: "84672504",
    description: "PROTECTOR ASM-L",
    supplierName: "NEDSCHROEF PLETTENBERG GMBH",
    supplierAddress: "PLETTENBERG,NW",
    supplierStreet: "",
    supplierCity: "PLETTENBERG",
    supplierState: "NW",
    supplierZip: "58840",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-84673164-078881928",
    partNumber: "84673164",
    description: "BOW ASM-RF PNL",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84673165-050150481",
    partNumber: "84673165",
    description: "BOW-RF PNL #5",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84673171-078881928",
    partNumber: "84673171",
    description: "BOW ASM-RF PNL",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84674408-545238347",
    partNumber: "84674408",
    description: "GRILLE ASM-RDO",
    supplierName: "SHANGHAI YANFENG JINQIAO AUTOMOTIVE",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201206",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84674592-812416683",
    partNumber: "84674592",
    description: "DUCT-AUX A/C AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84675918-800786642",
    partNumber: "84675918",
    description: "REINFORCEMENT-F",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84675919-800786642",
    partNumber: "84675919",
    description: "REINFORCEMENT-F",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84675920-800786642",
    partNumber: "84675920",
    description: "REINFORCEMENT-R",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84675921-800786642",
    partNumber: "84675921",
    description: "REINFORCEMENT-R",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84675922-800786642",
    partNumber: "84675922",
    description: "REINFORCEMENT-R",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84675923-800786642",
    partNumber: "84675923",
    description: "REINFORCEMENT-R",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84675924-800786642",
    partNumber: "84675924",
    description: "REINFORCEMENT-R",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84675925-800786642",
    partNumber: "84675925",
    description: "REINFORCEMENT-R",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84675926-078794027",
    partNumber: "84675926",
    description: "REINFORCEMENT-F",
    supplierName: "DIEOMATIC INC",
    supplierAddress: "BATTLE CREEK,MI",
    supplierStreet: "",
    supplierCity: "BATTLE CREEK",
    supplierState: "MI",
    supplierZip: "490377302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84675927-078794027",
    partNumber: "84675927",
    description: "REINFORCEMENT-F",
    supplierName: "DIEOMATIC INC",
    supplierAddress: "BATTLE CREEK,MI",
    supplierStreet: "",
    supplierCity: "BATTLE CREEK",
    supplierState: "MI",
    supplierZip: "490377302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84676183-129136698",
    partNumber: "84676183",
    description: "SPACER-R/WDO WP",
    supplierName: "DY AMERICA INC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483143209",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84676887-812905909",
    partNumber: "84676887",
    description: "BRACKET ASM-FWD",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84677446-086663101",
    partNumber: "84677446",
    description: "REINFORCEMENT-F",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84677447-086663101",
    partNumber: "84677447",
    description: "REINFORCEMENT-F",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84677768-000288993",
    partNumber: "84677768",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31109",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84677896-183117860",
    partNumber: "84677896",
    description: "GENERATOR ASM-.",
    supplierName: "MITSUBISHI ELECTRIC AUTOMOTIVE AMER",
    supplierAddress: "MASON,OH",
    supplierStreet: "",
    supplierCity: "MASON",
    supplierState: "OH",
    supplierZip: "450408344",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84678126-240929703",
    partNumber: "84678126",
    description: "STRAP-F/TNK",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 5P1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84678127-240929703",
    partNumber: "84678127",
    description: "STRAP-F/TNK",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 5P1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84678459-789519808",
    partNumber: "84678459",
    description: "INSULATOR-DA PN",
    supplierName: "CTA ACOUSTICS INC",
    supplierAddress: "CORBIN,KY",
    supplierStreet: "",
    supplierCity: "CORBIN",
    supplierState: "KY",
    supplierZip: "407018061",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84678963-812331239",
    partNumber: "84678963",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84678964-812331239",
    partNumber: "84678964",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84678965-812331239",
    partNumber: "84678965",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84678966-812331239",
    partNumber: "84678966",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84678967-812331239",
    partNumber: "84678967",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84678968-812331239",
    partNumber: "84678968",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84678969-812331239",
    partNumber: "84678969",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84678970-812331239",
    partNumber: "84678970",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84678971-812331239",
    partNumber: "84678971",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84678972-812331239",
    partNumber: "84678972",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84678973-812331239",
    partNumber: "84678973",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84678974-812331239",
    partNumber: "84678974",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84678975-812331239",
    partNumber: "84678975",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84678976-812331239",
    partNumber: "84678976",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84678977-812331239",
    partNumber: "84678977",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84678978-812331239",
    partNumber: "84678978",
    description: "WINDOW-RR S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84679422-078849132",
    partNumber: "84679422",
    description: "BRACKET-WIRELES",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84680940-042439265",
    partNumber: "84680940",
    description: "BRACKET-FRT TIE",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84680941-042439265",
    partNumber: "84680941",
    description: "BRACKET-FRT TIE",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84680984-812905909",
    partNumber: "84680984",
    description: "BRACKET ASM-BOD",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84680985-812905909",
    partNumber: "84680985",
    description: "BRACKET ASM-BOD",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84680986-812905909",
    partNumber: "84680986",
    description: "BRACKET ASM-BOD",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84680987-812905909",
    partNumber: "84680987",
    description: "BRACKET ASM-BOD",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84682635-654593565",
    partNumber: "84682635",
    description: "PLATE ASM-FRT S",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84682683-145193053",
    partNumber: "84682683",
    description: "SHIELD-SPA WHL",
    supplierName: "WINDSOR MOLD USA INC",
    supplierAddress: "PULASKI,TN",
    supplierStreet: "",
    supplierCity: "PULASKI",
    supplierState: "TN",
    supplierZip: "38478",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84682684-145193053",
    partNumber: "84682684",
    description: "SHIELD-SPA WHL",
    supplierName: "WINDSOR MOLD USA INC",
    supplierAddress: "PULASKI,TN",
    supplierStreet: "",
    supplierCity: "PULASKI",
    supplierState: "TN",
    supplierZip: "38478",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84682900-814180139",
    partNumber: "84682900",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84682901-814180139",
    partNumber: "84682901",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84682904-814180139",
    partNumber: "84682904",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84682905-814180139",
    partNumber: "84682905",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84682908-814180139",
    partNumber: "84682908",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84682909-814180139",
    partNumber: "84682909",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84682910-814180139",
    partNumber: "84682910",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84682913-814180139",
    partNumber: "84682913",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84682914-814180139",
    partNumber: "84682914",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84682915-814180139",
    partNumber: "84682915",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84683427-051053866",
    partNumber: "84683427",
    description: "VALVE-PLNM DRN",
    supplierName: "ACCURATE PRODUCTS INC",
    supplierAddress: "CHICAGO,IL",
    supplierStreet: "",
    supplierCity: "CHICAGO",
    supplierState: "IL",
    supplierZip: "606404509",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84683466-078881928",
    partNumber: "84683466",
    description: "PANEL ASM-RF FR",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84684633-814180139",
    partNumber: "84684633",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84684634-814180139",
    partNumber: "84684634",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84684635-814180139",
    partNumber: "84684635",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84684637-814180139",
    partNumber: "84684637",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84684638-814180139",
    partNumber: "84684638",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84684639-814180139",
    partNumber: "84684639",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84684641-814180139",
    partNumber: "84684641",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84684642-814180139",
    partNumber: "84684642",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84684644-814180139",
    partNumber: "84684644",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84684645-814180139",
    partNumber: "84684645",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84685549-142199376",
    partNumber: "84685549",
    description: "SHIELD-EXH FRT",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84685655-142199376",
    partNumber: "84685655",
    description: "SHIELD-EXH INTE",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84685663-086663101",
    partNumber: "84685663",
    description: "BOW-RF PNL #2",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84685665-086663101",
    partNumber: "84685665",
    description: "BOW-RF PNL #3",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84685666-086663101",
    partNumber: "84685666",
    description: "BOW-RF PNL #4",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84685667-086663101",
    partNumber: "84685667",
    description: "BOW-RF PNL #6",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84686105-588101217",
    partNumber: "84686105",
    description: "LATCH ASM-L/GAT",
    supplierName: "KIEKERT DE MEXICO SA DE CV",
    supplierAddress: "AMOZOC,PU",
    supplierStreet: "",
    supplierCity: "AMOZOC",
    supplierState: "PU",
    supplierZip: "72990",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84686654-042439265",
    partNumber: "84686654",
    description: "BRACKET ASM-F/F",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84686655-042439265",
    partNumber: "84686655",
    description: "BRACKET ASM-F/F",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84686957-117409100",
    partNumber: "84686957",
    description: "COVER-W/S M/FUN",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84687023-688001481",
    partNumber: "84687023",
    description: "MOUNT ASM-ENG",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84687024-688001481",
    partNumber: "84687024",
    description: "MOUNT ASM-ENG",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84687184-142199376",
    partNumber: "84687184",
    description: "SHIELD-EXH RR U",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84687185-142199376",
    partNumber: "84687185",
    description: "SHIELD-EXH RR U",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84687462-812888257",
    partNumber: "84687462",
    description: "PLATE ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84687463-812888257",
    partNumber: "84687463",
    description: "PLATE ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84687464-812888257",
    partNumber: "84687464",
    description: "PLATE ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84687465-812888257",
    partNumber: "84687465",
    description: "PLATE ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84687467-812888257",
    partNumber: "84687467",
    description: "PLATE ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84687468-812888257",
    partNumber: "84687468",
    description: "PLATE ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84687469-812888257",
    partNumber: "84687469",
    description: "PLATE ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84687470-812888257",
    partNumber: "84687470",
    description: "PLATE ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84687477-080786860",
    partNumber: "84687477",
    description: "BOLSTER ASM-I/P",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84687478-080786860",
    partNumber: "84687478",
    description: "BOLSTER ASM-I/P",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84687480-080786860",
    partNumber: "84687480",
    description: "BOLSTER ASM-I/P",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84687481-080786860",
    partNumber: "84687481",
    description: "BOLSTER ASM-I/P",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84687482-080786860",
    partNumber: "84687482",
    description: "BOLSTER ASM-I/P",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84687483-080786860",
    partNumber: "84687483",
    description: "BOLSTER ASM-I/P",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84688101-000196717",
    partNumber: "84688101",
    description: "SPEAKER ASM-RDO",
    supplierName: "PSS HONG KONG LTD",
    supplierAddress: "DONGGUAN,44",
    supplierStreet: "",
    supplierCity: "DONGGUAN",
    supplierState: "44",
    supplierZip: "523861",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84688297-009971289",
    partNumber: "84688297",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84688298-009971289",
    partNumber: "84688298",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84688299-009971289",
    partNumber: "84688299",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84688300-009971289",
    partNumber: "84688300",
    description: "WEATHERSTR",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84688301-009971289",
    partNumber: "84688301",
    description: "WEATHERSTR",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84688302-009971289",
    partNumber: "84688302",
    description: "WEATHERSTR",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84688688-050150481",
    partNumber: "84688688",
    description: "SHIELD-SPA WHL",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84689537-816508899",
    partNumber: "84689537",
    description: "PIPE ASM-EMIS R",
    supplierName: "VOSS AUTOMOTIVE MX S DE RL DE CV",
    supplierAddress: "ARTEAGA,CZ",
    supplierStreet: "",
    supplierCity: "ARTEAGA",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84689538-816508899",
    partNumber: "84689538",
    description: "PIPE ASM-EMIS R",
    supplierName: "VOSS AUTOMOTIVE MX S DE RL DE CV",
    supplierAddress: "ARTEAGA,CZ",
    supplierStreet: "",
    supplierCity: "ARTEAGA",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690111-812947059",
    partNumber: "84690111",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690112-812947059",
    partNumber: "84690112",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690113-812947059",
    partNumber: "84690113",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690114-812947059",
    partNumber: "84690114",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690115-812947059",
    partNumber: "84690115",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690116-812947059",
    partNumber: "84690116",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690117-812947059",
    partNumber: "84690117",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690118-812947059",
    partNumber: "84690118",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690119-812947059",
    partNumber: "84690119",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690138-812947059",
    partNumber: "84690138",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690139-812947059",
    partNumber: "84690139",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690140-812947059",
    partNumber: "84690140",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690209-968663109",
    partNumber: "84690209",
    description: "LATCH ASM-HOOD",
    supplierName: "PYEONGHWA AUTOMOTIVE USA LLC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84690210-968663109",
    partNumber: "84690210",
    description: "LATCH ASM-HOOD",
    supplierName: "PYEONGHWA AUTOMOTIVE USA LLC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84690291-688379886",
    partNumber: "84690291",
    description: "EMBLEM ASM-L/GA",
    supplierName: "SAMSHIN CHEMICAL CO LTD",
    supplierAddress: "ANSAN,41",
    supplierStreet: "",
    supplierCity: "ANSAN",
    supplierState: "41",
    supplierZip: "15606",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84690293-688379886",
    partNumber: "84690293",
    description: "EMBLEM ASM-L/GA",
    supplierName: "SAMSHIN CHEMICAL CO LTD",
    supplierAddress: "ANSAN,41",
    supplierStreet: "",
    supplierCity: "ANSAN",
    supplierState: "41",
    supplierZip: "15606",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84690892-812807729",
    partNumber: "84690892",
    description: "MAT PKG-FLR AUX",
    supplierName: "AGM DURMONT MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76295",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690901-812807729",
    partNumber: "84690901",
    description: "MAT PKG-FLR AUX",
    supplierName: "AGM DURMONT MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76295",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690909-812807729",
    partNumber: "84690909",
    description: "MAT PKG-FLR AUX",
    supplierName: "AGM DURMONT MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76295",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84690976-080786860",
    partNumber: "84690976",
    description: "PANEL ASM-I/P L",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84690977-080786860",
    partNumber: "84690977",
    description: "PANEL ASM-I/P L",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84691982-050150481",
    partNumber: "84691982",
    description: "RAIL ASM-U/B IN",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84692244-969789283",
    partNumber: "84692244",
    description: "BRACKET ASM-COM",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84692397-812416683",
    partNumber: "84692397",
    description: "DUCT ASM-W/S DE",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84692398-812416683",
    partNumber: "84692398",
    description: "DUCT-SI WDO DEF",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84692399-812416683",
    partNumber: "84692399",
    description: "DUCT ASM-W/S DE",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84692820-812015766",
    partNumber: "84692820",
    description: "PIPE ASM-F/TNK",
    supplierName: "INDUSTRIAS MARTINREA DE MX SA DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25298",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84692821-812015766",
    partNumber: "84692821",
    description: "PIPE ASM-F/TNK",
    supplierName: "INDUSTRIAS MARTINREA DE MX SA DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25298",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84693040-012839643",
    partNumber: "84693040",
    description: "RADIATOR ASM-EN",
    supplierName: "GM COMPONENT HOLDINGS LLC-LKPT PTC",
    supplierAddress: "LOCKPORT,NY",
    supplierStreet: "",
    supplierCity: "LOCKPORT",
    supplierState: "NY",
    supplierZip: "140941819",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84693243-117409100",
    partNumber: "84693243",
    description: "BEZEL-RR CTR SE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84693245-117409100",
    partNumber: "84693245",
    description: "BEZEL-R/SEAT CT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84693248-117409100",
    partNumber: "84693248",
    description: "BEZEL-R/SEAT CT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84693253-117409100",
    partNumber: "84693253",
    description: "BEZEL-RR CTR SE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84693613-965355311",
    partNumber: "84693613",
    description: "HOSE ASM-AUX HT",
    supplierName: "HANON SYSTEMS NETHERLANDS COOP UA",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799360405",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84693614-965355311",
    partNumber: "84693614",
    description: "HOSE ASM-AUX HT",
    supplierName: "HANON SYSTEMS NETHERLANDS COOP UA",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799360405",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84693615-969789283",
    partNumber: "84693615",
    description: "MOLDING ASM-FRT",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84693616-969789283",
    partNumber: "84693616",
    description: "MOLDING ASM-FRT",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84694241-607039732",
    partNumber: "84694241",
    description: "BLOCK ASM-BAT D",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84694243-607039732",
    partNumber: "84694243",
    description: "BLOCK ASM-BAT D",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84694244-084668367",
    partNumber: "84694244",
    description: "BLOCK ASM-BAT D",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "BROOKHAVEN,MS",
    supplierStreet: "",
    supplierCity: "BROOKHAVEN",
    supplierState: "MS",
    supplierZip: "396018951",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84695998-812015766",
    partNumber: "84695998",
    description: "PIPE ASM-F/TNK",
    supplierName: "INDUSTRIAS MARTINREA DE MX SA DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25298",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84696012-812015766",
    partNumber: "84696012",
    description: "PIPE ASM-F/TNK",
    supplierName: "INDUSTRIAS MARTINREA DE MX SA DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25298",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84696025-812015766",
    partNumber: "84696025",
    description: "PIPE ASM-F/TNK",
    supplierName: "INDUSTRIAS MARTINREA DE MX SA DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25298",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84696034-812015766",
    partNumber: "84696034",
    description: "PIPE ASM-F/TNK",
    supplierName: "INDUSTRIAS MARTINREA DE MX SA DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25298",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84696492-099051716",
    partNumber: "84696492",
    description: "RETAINER ASM-R/",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84696493-099051716",
    partNumber: "84696493",
    description: "RETAINER ASM-R/",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84696494-099051716",
    partNumber: "84696494",
    description: "RETAINER ASM-R/",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84696495-099051716",
    partNumber: "84696495",
    description: "RETAINER ASM-R/",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84696711-812947059",
    partNumber: "84696711",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84697696-812947059",
    partNumber: "84697696",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84697767-603014643",
    partNumber: "84697767",
    description: "INSULATOR-RR W/",
    supplierName: "ALLIANCE INTERIORS LLC",
    supplierAddress: "LANSING,MI",
    supplierStreet: "",
    supplierCity: "LANSING",
    supplierState: "MI",
    supplierZip: "489179501",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84698498-086663101",
    partNumber: "84698498",
    description: "REINFORCEMENT-R",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84698828-816657977",
    partNumber: "84698828",
    description: "PIPE ASM-FRT BR",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84699074-079948011",
    partNumber: "84699074",
    description: "LAMP ASM-R/RL R",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84699075-079948011",
    partNumber: "84699075",
    description: "LAMP ASM-R/RL R",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84699076-079948011",
    partNumber: "84699076",
    description: "LAMP ASM-R/RL R",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84699077-079948011",
    partNumber: "84699077",
    description: "LAMP ASM-R/RL R",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84699078-079948011",
    partNumber: "84699078",
    description: "LAMP ASM-R/RL R",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84699079-079948011",
    partNumber: "84699079",
    description: "LAMP ASM-R/RL R",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84699080-079948011",
    partNumber: "84699080",
    description: "LAMP ASM-R/RL R",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84699081-079948011",
    partNumber: "84699081",
    description: "LAMP ASM-R/RL R",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84699160-006016810",
    partNumber: "84699160",
    description: "LABEL-CHEM EXPO",
    supplierName: "GRAND RAPIDS LABEL CO",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "49505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84699162-812947059",
    partNumber: "84699162",
    description: "LINER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84699207-000202241",
    partNumber: "84699207",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84699209-000202241",
    partNumber: "84699209",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84699398-969789283",
    partNumber: "84699398",
    description: "ATTACHMENT PKG-",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84699399-969789283",
    partNumber: "84699399",
    description: "ATTACHMENT PKG-",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84699400-969789283",
    partNumber: "84699400",
    description: "ATTACHMENT PKG-",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84699401-969789283",
    partNumber: "84699401",
    description: "ATTACHMENT PKG-",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84699522-966669645",
    partNumber: "84699522",
    description: "CLEANER ASM-AIR",
    supplierName: "UNDERCAR PRODUCTS GROUP INC",
    supplierAddress: "WYOMING,MI",
    supplierStreet: "",
    supplierCity: "WYOMING",
    supplierState: "MI",
    supplierZip: "49508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84699698-529131017",
    partNumber: "84699698",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84699699-529131017",
    partNumber: "84699699",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84699700-529131017",
    partNumber: "84699700",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84699701-529131017",
    partNumber: "84699701",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84699702-529131017",
    partNumber: "84699702",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84699703-529131017",
    partNumber: "84699703",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84699704-529131017",
    partNumber: "84699704",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84699760-000288993",
    partNumber: "84699760",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31109",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84701517-070850102",
    partNumber: "84701517",
    description: "SWITCH ASM-PARK",
    supplierName: "MARQUARDT SWITCHES INC",
    supplierAddress: "CAZENOVIA,NY",
    supplierStreet: "",
    supplierCity: "CAZENOVIA",
    supplierState: "NY",
    supplierZip: "130358444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84702210-021093638",
    partNumber: "84702210",
    description: "BRACKET-RAD INL",
    supplierName: "BOPP-BUSCH MANUFACTURING CO INC",
    supplierAddress: "AU GRES,MI",
    supplierStreet: "",
    supplierCity: "AU GRES",
    supplierState: "MI",
    supplierZip: "487039748",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84703717-080203455",
    partNumber: "84703717",
    description: "HOSE ASM-R/WDO",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84703735-000271932",
    partNumber: "84703735",
    description: "BRACKET ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "MANSFIELD,OH",
    supplierStreet: "",
    supplierCity: "MANSFIELD",
    supplierState: "OH",
    supplierZip: "449031106",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84703736-000271932",
    partNumber: "84703736",
    description: "BRACKET ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "MANSFIELD,OH",
    supplierStreet: "",
    supplierCity: "MANSFIELD",
    supplierState: "OH",
    supplierZip: "449031106",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84703930-080779273",
    partNumber: "84703930",
    description: "CABLE ASM-GEN B",
    supplierName: "TIANHAI ELECTRIC NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065240",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84704779-544979545",
    partNumber: "84704779",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84704780-544979545",
    partNumber: "84704780",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84704781-544979545",
    partNumber: "84704781",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84704782-544979545",
    partNumber: "84704782",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84704783-544979545",
    partNumber: "84704783",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84704784-544979545",
    partNumber: "84704784",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84705050-000202272",
    partNumber: "84705050",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84705051-000202272",
    partNumber: "84705051",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84705052-000202272",
    partNumber: "84705052",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84705053-000202272",
    partNumber: "84705053",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84705054-000202272",
    partNumber: "84705054",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84705364-022687246",
    partNumber: "84705364",
    description: "COVER-L/GATE IN",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84705365-022687246",
    partNumber: "84705365",
    description: "COVER-L/GATE IN",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84705366-022687246",
    partNumber: "84705366",
    description: "COVER-L/GATE IN",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84705367-022687246",
    partNumber: "84705367",
    description: "COVER-L/GATE IN",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84705865-544586013",
    partNumber: "84705865",
    description: "SWITCH ASM-RIDE",
    supplierName: "LS AUTOMOTIVE TECH WUXI CORP",
    supplierAddress: "WUXI,32",
    supplierStreet: "",
    supplierCity: "WUXI",
    supplierState: "32",
    supplierZip: "214101",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84705866-544586013",
    partNumber: "84705866",
    description: "SWITCH ASM-RIDE",
    supplierName: "LS AUTOMOTIVE TECH WUXI CORP",
    supplierAddress: "WUXI,32",
    supplierStreet: "",
    supplierCity: "WUXI",
    supplierState: "32",
    supplierZip: "214101",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84705867-544586013",
    partNumber: "84705867",
    description: "SWITCH ASM-RIDE",
    supplierName: "LS AUTOMOTIVE TECH WUXI CORP",
    supplierAddress: "WUXI,32",
    supplierStreet: "",
    supplierCity: "WUXI",
    supplierState: "32",
    supplierZip: "214101",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84705868-544586013",
    partNumber: "84705868",
    description: "SWITCH ASM-RIDE",
    supplierName: "LS AUTOMOTIVE TECH WUXI CORP",
    supplierAddress: "WUXI,32",
    supplierStreet: "",
    supplierCity: "WUXI",
    supplierState: "32",
    supplierZip: "214101",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84705869-544586013",
    partNumber: "84705869",
    description: "SWITCH ASM-RIDE",
    supplierName: "LS AUTOMOTIVE TECH WUXI CORP",
    supplierAddress: "WUXI,32",
    supplierStreet: "",
    supplierCity: "WUXI",
    supplierState: "32",
    supplierZip: "214101",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84705870-544586013",
    partNumber: "84705870",
    description: "SWITCH ASM-RIDE",
    supplierName: "LS AUTOMOTIVE TECH WUXI CORP",
    supplierAddress: "WUXI,32",
    supplierStreet: "",
    supplierCity: "WUXI",
    supplierState: "32",
    supplierZip: "214101",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84707393-654593565",
    partNumber: "84707393",
    description: "PLATE ASM-FRT S",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84707394-544586013",
    partNumber: "84707394",
    description: "SWITCH ASM-ACSR",
    supplierName: "LS AUTOMOTIVE TECH WUXI CORP",
    supplierAddress: "WUXI,32",
    supplierStreet: "",
    supplierCity: "WUXI",
    supplierState: "32",
    supplierZip: "214101",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84707397-544586013",
    partNumber: "84707397",
    description: "SWITCH ASM-BEVE",
    supplierName: "LS AUTOMOTIVE TECH WUXI CORP",
    supplierAddress: "WUXI,32",
    supplierStreet: "",
    supplierCity: "WUXI",
    supplierState: "32",
    supplierZip: "214101",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84707398-544586013",
    partNumber: "84707398",
    description: "SWITCH ASM-BEVE",
    supplierName: "LS AUTOMOTIVE TECH WUXI CORP",
    supplierAddress: "WUXI,32",
    supplierStreet: "",
    supplierCity: "WUXI",
    supplierState: "32",
    supplierZip: "214101",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84707399-544586013",
    partNumber: "84707399",
    description: "SWITCH ASM-HAZA",
    supplierName: "LS AUTOMOTIVE TECH WUXI CORP",
    supplierAddress: "WUXI,32",
    supplierStreet: "",
    supplierCity: "WUXI",
    supplierState: "32",
    supplierZip: "214101",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84707746-544979545",
    partNumber: "84707746",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84707747-544979545",
    partNumber: "84707747",
    description: "MOLDING ASM-FRT",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84707748-544979545",
    partNumber: "84707748",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84707749-544979545",
    partNumber: "84707749",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84707750-544979545",
    partNumber: "84707750",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84707751-544979545",
    partNumber: "84707751",
    description: "MOLDING ASM-RR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84707760-816154785",
    partNumber: "84707760",
    description: "PIPE ASM-TRANS",
    supplierName: "AUTOMOTIVE BEND DE MEXICO S DE RL D",
    supplierAddress: "VILLA DE REYES,SL",
    supplierStreet: "",
    supplierCity: "VILLA DE REYES",
    supplierState: "SL",
    supplierZip: "79526",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84708175-545324639",
    partNumber: "84708175",
    description: "CORD ASM-ENG CO",
    supplierName: "HEBI THB INTERNATIONAL ELECTRIC CO",
    supplierAddress: "HEBI,41",
    supplierStreet: "",
    supplierCity: "HEBI",
    supplierState: "41",
    supplierZip: "458030",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84708908-050150481",
    partNumber: "84708908",
    description: "EXTENSION ASM-R",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84708909-050150481",
    partNumber: "84708909",
    description: "EXTENSION ASM-R",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84709179-812905909",
    partNumber: "84709179",
    description: "REINFORCEMENT-D",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84709180-812905909",
    partNumber: "84709180",
    description: "REINFORCEMENT-D",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84709229-175597137",
    partNumber: "84709229",
    description: "DISPLAY ASM-HD",
    supplierName: "NEW SABINA INDUSTRIES INC",
    supplierAddress: "SABINA,OH",
    supplierStreet: "",
    supplierCity: "SABINA",
    supplierState: "OH",
    supplierZip: "451699463",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84709306-050476923",
    partNumber: "84709306",
    description: "SPEAKER ASM-RDO",
    supplierName: "BOSE CORP",
    supplierAddress: "SAN DIEGO,CA",
    supplierStreet: "",
    supplierCity: "SAN DIEGO",
    supplierState: "CA",
    supplierZip: "921546216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84709413-108630731",
    partNumber: "84709413",
    description: "SILL ASM-U/B #1",
    supplierName: "KIRCHHOFF AUTOMOTIVE DALLAS INC",
    supplierAddress: "GARLAND,TX",
    supplierStreet: "",
    supplierCity: "GARLAND",
    supplierState: "TX",
    supplierZip: "750416051",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84709414-108630731",
    partNumber: "84709414",
    description: "SILL ASM-U/B #1",
    supplierName: "KIRCHHOFF AUTOMOTIVE DALLAS INC",
    supplierAddress: "GARLAND,TX",
    supplierStreet: "",
    supplierCity: "GARLAND",
    supplierState: "TX",
    supplierZip: "750416051",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84710282-242059041",
    partNumber: "84710282",
    description: "REINFORCEMENT A",
    supplierName: "ROYAL AUTOMOTIVE GROUP LTD",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84710283-242059041",
    partNumber: "84710283",
    description: "REINFORCEMENT A",
    supplierName: "ROYAL AUTOMOTIVE GROUP LTD",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84710308-078881928",
    partNumber: "84710308",
    description: "SILL ASM-U/B RR",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84711512-080786860",
    partNumber: "84711512",
    description: "COVER-AUDIO PLY",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84711942-812015766",
    partNumber: "84711942",
    description: "PIPE ASM-F/TNK",
    supplierName: "INDUSTRIAS MARTINREA DE MX SA DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25298",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84711987-012839643",
    partNumber: "84711987",
    description: "RADIATOR ASM-EN",
    supplierName: "GM COMPONENT HOLDINGS LLC-LKPT PTC",
    supplierAddress: "LOCKPORT,NY",
    supplierStreet: "",
    supplierCity: "LOCKPORT",
    supplierState: "NY",
    supplierZip: "140941819",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84712237-016422106",
    partNumber: "84712237",
    description: "SHAFT ASM-PROP",
    supplierName: "NEAPCO DRIVELINES LLC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481115271",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84712239-016422106",
    partNumber: "84712239",
    description: "SHAFT ASM-PROP",
    supplierName: "NEAPCO DRIVELINES LLC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481115271",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84712240-016422106",
    partNumber: "84712240",
    description: "SHAFT ASM-PROP",
    supplierName: "NEAPCO DRIVELINES LLC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481115271",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84714163-544634264",
    partNumber: "84714163",
    description: "WHEEL-FRT & RR",
    supplierName: "CITIC DICASTAL CO LTD PRODUCTION FA",
    supplierAddress: "QINHUANGDAO,13",
    supplierStreet: "",
    supplierCity: "QINHUANGDAO",
    supplierState: "13",
    supplierZip: "66009",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84716374-813018728",
    partNumber: "84716374",
    description: "MAT ASM-R/FLR",
    supplierName: "MEXAURIA S DE RL DE CV",
    supplierAddress: "EL MARQUES,QA",
    supplierStreet: "",
    supplierCity: "EL MARQUES",
    supplierState: "QA",
    supplierZip: "76246",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84716376-813018728",
    partNumber: "84716376",
    description: "MAT ASM-R/FLR",
    supplierName: "MEXAURIA S DE RL DE CV",
    supplierAddress: "EL MARQUES,QA",
    supplierStreet: "",
    supplierCity: "EL MARQUES",
    supplierState: "QA",
    supplierZip: "76246",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84716377-813018728",
    partNumber: "84716377",
    description: "MAT ASM-F/FLR P",
    supplierName: "MEXAURIA S DE RL DE CV",
    supplierAddress: "EL MARQUES,QA",
    supplierStreet: "",
    supplierCity: "EL MARQUES",
    supplierState: "QA",
    supplierZip: "76246",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84716487-808141266",
    partNumber: "84716487",
    description: "STIFFENER-FRT B",
    supplierName: "SHAPE CORP",
    supplierAddress: "GRAND HAVEN,MI",
    supplierStreet: "",
    supplierCity: "GRAND HAVEN",
    supplierState: "MI",
    supplierZip: "494179428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84717845-117409100",
    partNumber: "84717845",
    description: "INSERT-W/S M/FU",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84718528-000271957",
    partNumber: "84718528",
    description: "RESERVOIR ASM-A",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "TLALNEPANTLA DE BAZ,EM",
    supplierStreet: "",
    supplierCity: "TLALNEPANTLA DE BAZ",
    supplierState: "EM",
    supplierZip: "54030",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84718750-688001481",
    partNumber: "84718750",
    description: "MOUNT-TRANS",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84718751-688001481",
    partNumber: "84718751",
    description: "MOUNT-TRANS",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84718752-688001481",
    partNumber: "84718752",
    description: "MOUNT ASM-ENG",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84718753-688001481",
    partNumber: "84718753",
    description: "MOUNT ASM-ENG",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84718832-969789283",
    partNumber: "84718832",
    description: "GUTTER ASM-BK B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84718833-969789283",
    partNumber: "84718833",
    description: "GUTTER ASM-BK B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84718843-654593565",
    partNumber: "84718843",
    description: "PLATE ASM-L/GAT",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84719048-544586013",
    partNumber: "84719048",
    description: "SWITCH ASM-ENG",
    supplierName: "LS AUTOMOTIVE TECH WUXI CORP",
    supplierAddress: "WUXI,32",
    supplierStreet: "",
    supplierCity: "WUXI",
    supplierState: "32",
    supplierZip: "214101",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84719049-544586013",
    partNumber: "84719049",
    description: "SWITCH ASM-ENG",
    supplierName: "LS AUTOMOTIVE TECH WUXI CORP",
    supplierAddress: "WUXI,32",
    supplierStreet: "",
    supplierCity: "WUXI",
    supplierState: "32",
    supplierZip: "214101",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84719083-812947059",
    partNumber: "84719083",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84719085-812947059",
    partNumber: "84719085",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84719096-020239591",
    partNumber: "84719096",
    description: "REINFORCEMENT A",
    supplierName: "MARTINREA INDUSTRIES INC",
    supplierAddress: "HOPKINSVILLE,KY",
    supplierStreet: "",
    supplierCity: "HOPKINSVILLE",
    supplierState: "KY",
    supplierZip: "422406818",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84719097-020239591",
    partNumber: "84719097",
    description: "REINFORCEMENT A",
    supplierName: "MARTINREA INDUSTRIES INC",
    supplierAddress: "HOPKINSVILLE,KY",
    supplierStreet: "",
    supplierCity: "HOPKINSVILLE",
    supplierState: "KY",
    supplierZip: "422406818",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84719098-020239591",
    partNumber: "84719098",
    description: "REINFORCEMENT A",
    supplierName: "MARTINREA INDUSTRIES INC",
    supplierAddress: "HOPKINSVILLE,KY",
    supplierStreet: "",
    supplierCity: "HOPKINSVILLE",
    supplierState: "KY",
    supplierZip: "422406818",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84719099-020239591",
    partNumber: "84719099",
    description: "REINFORCEMENT A",
    supplierName: "MARTINREA INDUSTRIES INC",
    supplierAddress: "HOPKINSVILLE,KY",
    supplierStreet: "",
    supplierCity: "HOPKINSVILLE",
    supplierState: "KY",
    supplierZip: "422406818",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84719357-812947059",
    partNumber: "84719357",
    description: "FILLER ASM-I/P",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84719358-812947059",
    partNumber: "84719358",
    description: "FILLER ASM-I/P",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84719359-812947059",
    partNumber: "84719359",
    description: "FILLER ASM-I/P",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84719360-812947059",
    partNumber: "84719360",
    description: "FILLER ASM-I/P",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84719362-812947059",
    partNumber: "84719362",
    description: "FILLER ASM-I/P",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84719363-812947059",
    partNumber: "84719363",
    description: "FILLER ASM-I/P",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84719364-812947059",
    partNumber: "84719364",
    description: "FILLER ASM-I/P",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84719365-812947059",
    partNumber: "84719365",
    description: "FILLER ASM-I/P",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84719437-544979545",
    partNumber: "84719437",
    description: "VENT ASM-F/FDR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84719438-544979545",
    partNumber: "84719438",
    description: "VENT ASM-F/FDR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84719439-544979545",
    partNumber: "84719439",
    description: "VENT ASM-F/FDR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84719440-544979545",
    partNumber: "84719440",
    description: "VENT ASM-F/FDR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84719441-544979545",
    partNumber: "84719441",
    description: "VENT ASM-F/FDR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84719442-544979545",
    partNumber: "84719442",
    description: "VENT ASM-F/FDR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84719445-544979545",
    partNumber: "84719445",
    description: "VENT ASM-F/FDR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84719446-544979545",
    partNumber: "84719446",
    description: "VENT ASM-F/FDR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84719447-544979545",
    partNumber: "84719447",
    description: "VENT ASM-F/FDR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84719448-544979545",
    partNumber: "84719448",
    description: "VENT ASM-F/FDR",
    supplierName: "JIAXING MINHUI AUTOMOTIVE PARTS CO",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314006",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84719640-000243147",
    partNumber: "84719640",
    description: "SUPPLEMENT-OWNE",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84719643-000243147",
    partNumber: "84719643",
    description: "BROCHURE-VEH DL",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84721074-242800241",
    partNumber: "84721074",
    description: "JACK PKG-.",
    supplierName: "VENTRA GROUP INC",
    supplierAddress: "TOTTENHAM,ON",
    supplierStreet: "",
    supplierCity: "TOTTENHAM",
    supplierState: "ON",
    supplierZip: "L0G 1W0",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84721104-814814042",
    partNumber: "84721104",
    description: "HOSE ASM-RAD SU",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84722263-080786860",
    partNumber: "84722263",
    description: "SPRING-F/FLR CN",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84722264-080786860",
    partNumber: "84722264",
    description: "SPRING-F/FLR CN",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84722756-544652613",
    partNumber: "84722756",
    description: "SWITCH ASM-INFO",
    supplierName: "DALIAN ALPS ELECTRONICS CO LTD",
    supplierAddress: "DALIAN,21",
    supplierStreet: "",
    supplierCity: "DALIAN",
    supplierState: "21",
    supplierZip: "116100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84722879-588212576",
    partNumber: "84722879",
    description: "REINFORCEMENT A",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36275",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84723156-812905909",
    partNumber: "84723156",
    description: "REINFORCEMENT A",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84723157-812905909",
    partNumber: "84723157",
    description: "REINFORCEMENT A",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84725238-814180139",
    partNumber: "84725238",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84725239-814180139",
    partNumber: "84725239",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84725246-814180139",
    partNumber: "84725246",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84725247-814180139",
    partNumber: "84725247",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84725307-144331493",
    partNumber: "84725307",
    description: "PANEL-QTR INR L",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84725308-144331493",
    partNumber: "84725308",
    description: "PANEL-QTR INR L",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84725407-969789283",
    partNumber: "84725407",
    description: "BRACKET ASM-RR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84727450-812815730",
    partNumber: "84727450",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84727454-812815730",
    partNumber: "84727454",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84727458-812815730",
    partNumber: "84727458",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84728042-421251063",
    partNumber: "84728042",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84728048-421251063",
    partNumber: "84728048",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84728065-421251063",
    partNumber: "84728065",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84728083-421251063",
    partNumber: "84728083",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84728095-421251063",
    partNumber: "84728095",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84728100-421251063",
    partNumber: "84728100",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84728103-421251063",
    partNumber: "84728103",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84728108-421251063",
    partNumber: "84728108",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84728113-421251063",
    partNumber: "84728113",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84728117-421251063",
    partNumber: "84728117",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84728122-421251063",
    partNumber: "84728122",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84728127-421251063",
    partNumber: "84728127",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84728131-421251063",
    partNumber: "84728131",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84728685-042321828",
    partNumber: "84728685",
    description: "ARM ASM-RR SUSP",
    supplierName: "IROQUOIS INDUSTRIES INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891425",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84728686-042321828",
    partNumber: "84728686",
    description: "ARM ASM-RR SUSP",
    supplierName: "IROQUOIS INDUSTRIES INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891425",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84729251-812947059",
    partNumber: "84729251",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84729252-812947059",
    partNumber: "84729252",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84729253-812947059",
    partNumber: "84729253",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84729254-812947059",
    partNumber: "84729254",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84729256-812947059",
    partNumber: "84729256",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84729257-812947059",
    partNumber: "84729257",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84729258-812947059",
    partNumber: "84729258",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84729259-812947059",
    partNumber: "84729259",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84729335-812947059",
    partNumber: "84729335",
    description: "LINER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84729618-812895704",
    partNumber: "84729618",
    description: "ARM ASM-FRT LWR",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84729619-812895704",
    partNumber: "84729619",
    description: "ARM ASM-FRT LWR",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84731701-969789283",
    partNumber: "84731701",
    description: "ATTACHMENT PKG-",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84731702-969789283",
    partNumber: "84731702",
    description: "ATTACHMENT PKG-",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84731785-812905909",
    partNumber: "84731785",
    description: "BRACKET-BODY SI",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84731786-812905909",
    partNumber: "84731786",
    description: "BRACKET-BODY SI",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84731887-080316510",
    partNumber: "84731887",
    description: "MOLDING ASM-HOO",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84731888-080316510",
    partNumber: "84731888",
    description: "MOLDING ASM-HOO",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84731889-080316510",
    partNumber: "84731889",
    description: "MOLDING ASM-HOO",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84732036-831831438",
    partNumber: "84732036",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84732037-831831438",
    partNumber: "84732037",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84732038-831831438",
    partNumber: "84732038",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84732040-831831438",
    partNumber: "84732040",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84732041-831831438",
    partNumber: "84732041",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84732042-831831438",
    partNumber: "84732042",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84733377-144331493",
    partNumber: "84733377",
    description: "PANEL-F/FLR",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84733378-816523799",
    partNumber: "84733378",
    description: "SILL ASM-U/B #2",
    supplierName: "SAN LUIS METAL FORMING SA DE CV SUC",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36275",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84733772-080779273",
    partNumber: "84733772",
    description: "CABLE ASM-STRTR",
    supplierName: "TIANHAI ELECTRIC NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065240",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84734328-966606340",
    partNumber: "84734328",
    description: "WINDSHIELD ASM-",
    supplierName: "PILKINGTON NORTH AMERICA",
    supplierAddress: "LEXINGTON,KY",
    supplierStreet: "",
    supplierCity: "LEXINGTON",
    supplierState: "KY",
    supplierZip: "405053112",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84734329-966606340",
    partNumber: "84734329",
    description: "WINDSHIELD ASM-",
    supplierName: "PILKINGTON NORTH AMERICA",
    supplierAddress: "LEXINGTON,KY",
    supplierStreet: "",
    supplierCity: "LEXINGTON",
    supplierState: "KY",
    supplierZip: "405053112",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84734330-966606340",
    partNumber: "84734330",
    description: "WINDSHIELD ASM-",
    supplierName: "PILKINGTON NORTH AMERICA",
    supplierAddress: "LEXINGTON,KY",
    supplierStreet: "",
    supplierCity: "LEXINGTON",
    supplierState: "KY",
    supplierZip: "405053112",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84734331-966606340",
    partNumber: "84734331",
    description: "WINDSHIELD ASM-",
    supplierName: "PILKINGTON NORTH AMERICA",
    supplierAddress: "LEXINGTON,KY",
    supplierStreet: "",
    supplierCity: "LEXINGTON",
    supplierState: "KY",
    supplierZip: "405053112",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84735516-800144433",
    partNumber: "84735516",
    description: "KNUCKLE ASM-RR",
    supplierName: "ALUDYNE MONTAGUE LLC",
    supplierAddress: "MONTAGUE,MI",
    supplierStreet: "",
    supplierCity: "MONTAGUE",
    supplierState: "MI",
    supplierZip: "494371566",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84735517-800144433",
    partNumber: "84735517",
    description: "KNUCKLE ASM-RR",
    supplierName: "ALUDYNE MONTAGUE LLC",
    supplierAddress: "MONTAGUE,MI",
    supplierStreet: "",
    supplierCity: "MONTAGUE",
    supplierState: "MI",
    supplierZip: "494371566",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84735819-812888257",
    partNumber: "84735819",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735820-812888257",
    partNumber: "84735820",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735821-812888257",
    partNumber: "84735821",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735823-812888257",
    partNumber: "84735823",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735824-812888257",
    partNumber: "84735824",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735825-812888257",
    partNumber: "84735825",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735827-812888257",
    partNumber: "84735827",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735828-812888257",
    partNumber: "84735828",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735830-812888257",
    partNumber: "84735830",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735831-812888257",
    partNumber: "84735831",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735833-812888257",
    partNumber: "84735833",
    description: "APPLIQUE ASM-F/",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735834-812888257",
    partNumber: "84735834",
    description: "APPLIQUE ASM-F/",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735835-812888257",
    partNumber: "84735835",
    description: "APPLIQUE ASM-F/",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735837-812888257",
    partNumber: "84735837",
    description: "APPLIQUE ASM-F/",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735838-812888257",
    partNumber: "84735838",
    description: "APPLIQUE ASM-F/",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735839-812888257",
    partNumber: "84735839",
    description: "APPLIQUE ASM-F/",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735841-812888257",
    partNumber: "84735841",
    description: "APPLIQUE ASM-F/",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735843-812888257",
    partNumber: "84735843",
    description: "APPLIQUE ASM-F/",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735845-812888257",
    partNumber: "84735845",
    description: "APPLIQUE ASM-F/",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84735847-812888257",
    partNumber: "84735847",
    description: "APPLIQUE ASM-F/",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84736417-253988547",
    partNumber: "84736417",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84736418-253988547",
    partNumber: "84736418",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84736419-253988547",
    partNumber: "84736419",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84736420-253988547",
    partNumber: "84736420",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84736421-253988547",
    partNumber: "84736421",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84736422-253988547",
    partNumber: "84736422",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84736423-253988547",
    partNumber: "84736423",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84736424-253988547",
    partNumber: "84736424",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84736941-080786860",
    partNumber: "84736941",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84736942-080786860",
    partNumber: "84736942",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84736943-080786860",
    partNumber: "84736943",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84736944-080786860",
    partNumber: "84736944",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84736946-080786860",
    partNumber: "84736946",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84736947-080786860",
    partNumber: "84736947",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84736948-080786860",
    partNumber: "84736948",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84736949-080786860",
    partNumber: "84736949",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84737215-812815730",
    partNumber: "84737215",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84737216-812815730",
    partNumber: "84737216",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84737217-812815730",
    partNumber: "84737217",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84737218-812815730",
    partNumber: "84737218",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84737222-812815730",
    partNumber: "84737222",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84737223-812815730",
    partNumber: "84737223",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84737720-800221918",
    partNumber: "84737720",
    description: "PLUG-RR S/ABS A",
    supplierName: "PROTECTIVE INDUSTRIES INC",
    supplierAddress: "BUFFALO,NY",
    supplierStreet: "",
    supplierCity: "BUFFALO",
    supplierState: "NY",
    supplierZip: "142071910",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84738011-694893901",
    partNumber: "84738011",
    description: "LINK ASM-FRT ST",
    supplierName: "CENTRAL YEONGSAN PLANT",
    supplierAddress: "CHANGNYEONGGUN,48",
    supplierStreet: "",
    supplierCity: "CHANGNYEONGGUN",
    supplierState: "48",
    supplierZip: "50342",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84738075-050476923",
    partNumber: "84738075",
    description: "SPEAKER ASM-RDO",
    supplierName: "BOSE CORP",
    supplierAddress: "SAN DIEGO,CA",
    supplierStreet: "",
    supplierCity: "SAN DIEGO",
    supplierState: "CA",
    supplierZip: "921546216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84738643-544925969",
    partNumber: "84738643",
    description: "CABLE ASM-HOOD",
    supplierName: "L&C CHANGSHA CABLE INDUSTRIES LTD",
    supplierAddress: "CHANGSHA,43",
    supplierStreet: "",
    supplierCity: "CHANGSHA",
    supplierState: "43",
    supplierZip: "410119",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84738644-544925969",
    partNumber: "84738644",
    description: "CABLE ASM-HOOD",
    supplierName: "L&C CHANGSHA CABLE INDUSTRIES LTD",
    supplierAddress: "CHANGSHA,43",
    supplierStreet: "",
    supplierCity: "CHANGSHA",
    supplierState: "43",
    supplierZip: "410119",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84738995-000164582",
    partNumber: "84738995",
    description: "HEADPHONE ASM-E",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "KOWLOON,HK",
    supplierStreet: "",
    supplierCity: "KOWLOON",
    supplierState: "HK",
    supplierZip: "00000",
    supplierCountry: "HK",
  },
  {
    _id: "parts-gm-84738996-000164582",
    partNumber: "84738996",
    description: "HEADPHONE ASM-E",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "KOWLOON,HK",
    supplierStreet: "",
    supplierCity: "KOWLOON",
    supplierState: "HK",
    supplierZip: "00000",
    supplierCountry: "HK",
  },
  {
    _id: "parts-gm-84738997-000164582",
    partNumber: "84738997",
    description: "HEADPHONE ASM-E",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "KOWLOON,HK",
    supplierStreet: "",
    supplierCity: "KOWLOON",
    supplierState: "HK",
    supplierZip: "00000",
    supplierCountry: "HK",
  },
  {
    _id: "parts-gm-84739862-099051716",
    partNumber: "84739862",
    description: "RETAINER ASM-R/",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "CHIPPEWA FALLS,WI",
    supplierStreet: "",
    supplierCity: "CHIPPEWA FALLS",
    supplierState: "WI",
    supplierZip: "547291417",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84739897-000202272",
    partNumber: "84739897",
    description: "COVER ASM-R/SEA",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84739898-000202272",
    partNumber: "84739898",
    description: "COVER ASM-R/SEA",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84739899-000202272",
    partNumber: "84739899",
    description: "COVER ASM-R/SEA",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84739900-000202272",
    partNumber: "84739900",
    description: "COVER ASM-R/SEA",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84739901-000202272",
    partNumber: "84739901",
    description: "COVER ASM-R/SEA",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84739933-050614114",
    partNumber: "84739933",
    description: "INSULATOR-FRT S",
    supplierName: "AIRBOSS FLEXIBLE PRODUCTS CO",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84739934-050614114",
    partNumber: "84739934",
    description: "INSULATOR-FRT S",
    supplierName: "AIRBOSS FLEXIBLE PRODUCTS CO",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84740917-654593565",
    partNumber: "84740917",
    description: "PLATE ASM-L/GAT",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84740919-654593565",
    partNumber: "84740919",
    description: "PLATE ASM-L/GAT",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84741494-962364647",
    partNumber: "84741494",
    description: "MOLDING ASM-BK",
    supplierName: "PLASMAN CORP LLC",
    supplierAddress: "FORT PAYNE,AL",
    supplierStreet: "",
    supplierCity: "FORT PAYNE",
    supplierState: "AL",
    supplierZip: "359683387",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84741495-962364647",
    partNumber: "84741495",
    description: "MOLDING ASM-BK",
    supplierName: "PLASMAN CORP LLC",
    supplierAddress: "FORT PAYNE,AL",
    supplierStreet: "",
    supplierCity: "FORT PAYNE",
    supplierState: "AL",
    supplierZip: "359683387",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84741544-053495482",
    partNumber: "84741544",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84741545-053495482",
    partNumber: "84741545",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84741546-053495482",
    partNumber: "84741546",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84741547-053495482",
    partNumber: "84741547",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84741550-053495482",
    partNumber: "84741550",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84741551-053495482",
    partNumber: "84741551",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84741566-053495482",
    partNumber: "84741566",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84741567-053495482",
    partNumber: "84741567",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84741598-588352398",
    partNumber: "84741598",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84742913-812133478",
    partNumber: "84742913",
    description: "PEDAL ASM-BRK",
    supplierName: "VENTRAMEX S DE RL DE CV",
    supplierAddress: "EL MARQUES,QA",
    supplierStreet: "",
    supplierCity: "EL MARQUES",
    supplierState: "QA",
    supplierZip: "76249",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84745091-831831438",
    partNumber: "84745091",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84745092-831831438",
    partNumber: "84745092",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84745548-812947059",
    partNumber: "84745548",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84745549-812947059",
    partNumber: "84745549",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84745550-812947059",
    partNumber: "84745550",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84745552-812947059",
    partNumber: "84745552",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84745553-812947059",
    partNumber: "84745553",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84745554-812947059",
    partNumber: "84745554",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84745581-005476700",
    partNumber: "84745581",
    description: "MODULE ASM-ACTI",
    supplierName: "ZF ACTIVE SAFETY AND ELECTRONICS US",
    supplierAddress: "MARSHALL,IL",
    supplierStreet: "",
    supplierCity: "MARSHALL",
    supplierState: "IL",
    supplierZip: "624411854",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747161-839434800",
    partNumber: "84747161",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747162-839434800",
    partNumber: "84747162",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747163-839434800",
    partNumber: "84747163",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747164-839434800",
    partNumber: "84747164",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747165-839434800",
    partNumber: "84747165",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747166-839434800",
    partNumber: "84747166",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747175-839434800",
    partNumber: "84747175",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747176-839434800",
    partNumber: "84747176",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747177-839434800",
    partNumber: "84747177",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747178-839434800",
    partNumber: "84747178",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747323-608167276",
    partNumber: "84747323",
    description: "SHAFT ASM-RR WH",
    supplierName: "NTN DRIVESHAFT INC",
    supplierAddress: "COLUMBUS,IN",
    supplierStreet: "",
    supplierCity: "COLUMBUS",
    supplierState: "IN",
    supplierZip: "47201",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747324-608167276",
    partNumber: "84747324",
    description: "SHAFT ASM-RR WH",
    supplierName: "NTN DRIVESHAFT INC",
    supplierAddress: "COLUMBUS,IN",
    supplierStreet: "",
    supplierCity: "COLUMBUS",
    supplierState: "IN",
    supplierZip: "47201",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747325-608167276",
    partNumber: "84747325",
    description: "SHAFT ASM-RR WH",
    supplierName: "NTN DRIVESHAFT INC",
    supplierAddress: "COLUMBUS,IN",
    supplierStreet: "",
    supplierCity: "COLUMBUS",
    supplierState: "IN",
    supplierZip: "47201",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747326-608167276",
    partNumber: "84747326",
    description: "SHAFT ASM-RR WH",
    supplierName: "NTN DRIVESHAFT INC",
    supplierAddress: "COLUMBUS,IN",
    supplierStreet: "",
    supplierCity: "COLUMBUS",
    supplierState: "IN",
    supplierZip: "47201",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747371-839434800",
    partNumber: "84747371",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747372-839434800",
    partNumber: "84747372",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747374-839434800",
    partNumber: "84747374",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747375-839434800",
    partNumber: "84747375",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747474-839434800",
    partNumber: "84747474",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84747475-839434800",
    partNumber: "84747475",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84748145-160931085",
    partNumber: "84748145",
    description: "EXTENSION-AUX A",
    supplierName: "SALGA PLASTICS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663715",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84748146-160931085",
    partNumber: "84748146",
    description: "EXTENSION-AUX A",
    supplierName: "SALGA PLASTICS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663715",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84748147-023256705",
    partNumber: "84748147",
    description: "SEAL-AIR DISTR",
    supplierName: "NITTO INC",
    supplierAddress: "LAKEWOOD,NJ",
    supplierStreet: "",
    supplierCity: "LAKEWOOD",
    supplierState: "NJ",
    supplierZip: "087014537",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84748610-814180139",
    partNumber: "84748610",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748611-814180139",
    partNumber: "84748611",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748612-814180139",
    partNumber: "84748612",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748614-814180139",
    partNumber: "84748614",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748615-814180139",
    partNumber: "84748615",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748616-814180139",
    partNumber: "84748616",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748618-814180139",
    partNumber: "84748618",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748619-814180139",
    partNumber: "84748619",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748620-814180139",
    partNumber: "84748620",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748621-814180139",
    partNumber: "84748621",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748623-814180139",
    partNumber: "84748623",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748624-814180139",
    partNumber: "84748624",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748625-814180139",
    partNumber: "84748625",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748626-814180139",
    partNumber: "84748626",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748627-814180139",
    partNumber: "84748627",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748628-814180139",
    partNumber: "84748628",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748629-814180139",
    partNumber: "84748629",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84748630-814180139",
    partNumber: "84748630",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84749560-816657977",
    partNumber: "84749560",
    description: "PIPE ASM-RR BRK",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84749621-078488161",
    partNumber: "84749621",
    description: "SPEAKER ASM-RDO",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84749622-078488161",
    partNumber: "84749622",
    description: "SPEAKER ASM-RDO",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84749681-086663101",
    partNumber: "84749681",
    description: "PANEL-DA LWR EX",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84749958-812246809",
    partNumber: "84749958",
    description: "TUBE ASM-BAT VE",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84750158-654593565",
    partNumber: "84750158",
    description: "PLATE ASM-L/GAT",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84751350-607039732",
    partNumber: "84751350",
    description: "HARNESS ASM-RF",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751444-070850102",
    partNumber: "84751444",
    description: "SWITCH ASM-HD U",
    supplierName: "MARQUARDT SWITCHES INC",
    supplierAddress: "CAZENOVIA,NY",
    supplierStreet: "",
    supplierCity: "CAZENOVIA",
    supplierState: "NY",
    supplierZip: "130358444",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751772-839434800",
    partNumber: "84751772",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751773-839434800",
    partNumber: "84751773",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751774-839434800",
    partNumber: "84751774",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751775-839434800",
    partNumber: "84751775",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751776-839434800",
    partNumber: "84751776",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751777-839434800",
    partNumber: "84751777",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751778-839434800",
    partNumber: "84751778",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751779-839434800",
    partNumber: "84751779",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751789-839434800",
    partNumber: "84751789",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751790-839434800",
    partNumber: "84751790",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751791-839434800",
    partNumber: "84751791",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751792-839434800",
    partNumber: "84751792",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751793-839434800",
    partNumber: "84751793",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751794-839434800",
    partNumber: "84751794",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751795-839434800",
    partNumber: "84751795",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751796-839434800",
    partNumber: "84751796",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751798-839434800",
    partNumber: "84751798",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751801-839434800",
    partNumber: "84751801",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751803-839434800",
    partNumber: "84751803",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751804-839434800",
    partNumber: "84751804",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751805-839434800",
    partNumber: "84751805",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751806-839434800",
    partNumber: "84751806",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751808-839434800",
    partNumber: "84751808",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751809-839434800",
    partNumber: "84751809",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751810-839434800",
    partNumber: "84751810",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751811-839434800",
    partNumber: "84751811",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751812-839434800",
    partNumber: "84751812",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751813-839434800",
    partNumber: "84751813",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751814-839434800",
    partNumber: "84751814",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751816-839434800",
    partNumber: "84751816",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751817-839434800",
    partNumber: "84751817",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751818-839434800",
    partNumber: "84751818",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751819-839434800",
    partNumber: "84751819",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751820-839434800",
    partNumber: "84751820",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751829-839434800",
    partNumber: "84751829",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751837-839434800",
    partNumber: "84751837",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751867-839434800",
    partNumber: "84751867",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84751875-839434800",
    partNumber: "84751875",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84753151-812331239",
    partNumber: "84753151",
    description: "WINDOW-FRT S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84753152-812331239",
    partNumber: "84753152",
    description: "WINDOW-FRT S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84753153-812331239",
    partNumber: "84753153",
    description: "WINDOW-FRT S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84753154-812331239",
    partNumber: "84753154",
    description: "WINDOW-FRT S/D",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84754391-867066805",
    partNumber: "84754391",
    description: "SHAFT ASM-FRT W",
    supplierName: "GKN DRIVELINE NORTH AMERICA INC",
    supplierAddress: "TIMBERLAKE,NC",
    supplierStreet: "",
    supplierCity: "TIMBERLAKE",
    supplierState: "NC",
    supplierZip: "27583",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84755160-121584338",
    partNumber: "84755160",
    description: "PANEL-R/FLR RR",
    supplierName: "GM METAL FAB-MARION",
    supplierAddress: "MARION,IN",
    supplierStreet: "",
    supplierCity: "MARION",
    supplierState: "IN",
    supplierZip: "46952",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84755162-812833912",
    partNumber: "84755162",
    description: "AIRBAG ASM-I/P",
    supplierName: "TRW OCCUPANT RESTRAINTS DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31137",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84755216-812888257",
    partNumber: "84755216",
    description: "BOLSTER ASM-I/P",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84755217-812888257",
    partNumber: "84755217",
    description: "BOLSTER ASM-I/P",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84755218-812888257",
    partNumber: "84755218",
    description: "BOLSTER ASM-I/P",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84755220-812888257",
    partNumber: "84755220",
    description: "BOLSTER ASM-I/P",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84755222-812888257",
    partNumber: "84755222",
    description: "BOLSTER ASM-I/P",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84755858-810248898",
    partNumber: "84755858",
    description: "MODULE ASM-WSW",
    supplierName: "ROBERT BOSCH S DE RL DE CV",
    supplierAddress: "TOLUCA,EM",
    supplierStreet: "",
    supplierCity: "TOLUCA",
    supplierState: "EM",
    supplierZip: "50071",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84755859-042439265",
    partNumber: "84755859",
    description: "BRACKET ASM-L/G",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84755860-042439265",
    partNumber: "84755860",
    description: "BRACKET ASM-L/G",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84755861-042439265",
    partNumber: "84755861",
    description: "BRACKET ASM-L/G",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84755862-042439265",
    partNumber: "84755862",
    description: "BRACKET ASM-L/G",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84757044-814180139",
    partNumber: "84757044",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757045-814180139",
    partNumber: "84757045",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757046-814180139",
    partNumber: "84757046",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757048-814180139",
    partNumber: "84757048",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757049-814180139",
    partNumber: "84757049",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757050-814180139",
    partNumber: "84757050",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757052-814180139",
    partNumber: "84757052",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757053-814180139",
    partNumber: "84757053",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757054-814180139",
    partNumber: "84757054",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757056-814180139",
    partNumber: "84757056",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757057-814180139",
    partNumber: "84757057",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757058-814180139",
    partNumber: "84757058",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757665-812888257",
    partNumber: "84757665",
    description: "PANEL ASM-I/P L",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757666-812888257",
    partNumber: "84757666",
    description: "PANEL ASM-I/P L",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757667-812888257",
    partNumber: "84757667",
    description: "PANEL ASM-I/P L",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757669-812888257",
    partNumber: "84757669",
    description: "PANEL ASM-I/P L",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757671-812888257",
    partNumber: "84757671",
    description: "PANEL ASM-I/P L",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84757672-078849132",
    partNumber: "84757672",
    description: "COVER-AIRBAG SE",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84759205-086663101",
    partNumber: "84759205",
    description: "RAIL ASM-RF INR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84759206-086663101",
    partNumber: "84759206",
    description: "RAIL ASM-RF INR",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84759307-814526588",
    partNumber: "84759307",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84759308-814526588",
    partNumber: "84759308",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84759310-814526588",
    partNumber: "84759310",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84759321-814526588",
    partNumber: "84759321",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84759323-814526588",
    partNumber: "84759323",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84759324-814526588",
    partNumber: "84759324",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84759325-814526588",
    partNumber: "84759325",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84759327-814526588",
    partNumber: "84759327",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84759334-814526588",
    partNumber: "84759334",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84759503-812831062",
    partNumber: "84759503",
    description: "SHAFT-FRT STAB",
    supplierName: "MUBEA DE MEXICO S DE RL DE CV",
    supplierAddress: "APASEO EL GRANDE,GJ",
    supplierStreet: "",
    supplierCity: "APASEO EL GRANDE",
    supplierState: "GJ",
    supplierZip: "38191",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84759504-812831062",
    partNumber: "84759504",
    description: "SHAFT-FRT STAB",
    supplierName: "MUBEA DE MEXICO S DE RL DE CV",
    supplierAddress: "APASEO EL GRANDE,GJ",
    supplierStreet: "",
    supplierCity: "APASEO EL GRANDE",
    supplierState: "GJ",
    supplierZip: "38191",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84759529-078439732",
    partNumber: "84759529",
    description: "SHAFT-RR STAB",
    supplierName: "DAEWON AMERICA INC",
    supplierAddress: "LAGRANGE,GA",
    supplierStreet: "",
    supplierCity: "LAGRANGE",
    supplierState: "GA",
    supplierZip: "302405822",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84759530-078439732",
    partNumber: "84759530",
    description: "SHAFT-RR STAB",
    supplierName: "DAEWON AMERICA INC",
    supplierAddress: "LAGRANGE,GA",
    supplierStreet: "",
    supplierCity: "LAGRANGE",
    supplierState: "GA",
    supplierZip: "302405822",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84760131-078488161",
    partNumber: "84760131",
    description: "AMPLIFIER ASM-R",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84760954-932662133",
    partNumber: "84760954",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84761191-814180139",
    partNumber: "84761191",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84761195-814180139",
    partNumber: "84761195",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84762897-969789283",
    partNumber: "84762897",
    description: "BRACKET ASM-RAD",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84762898-969789283",
    partNumber: "84762898",
    description: "BRACKET ASM-RAD",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84763235-021093638",
    partNumber: "84763235",
    description: "SHIELD-FRT BRK",
    supplierName: "BOPP-BUSCH MANUFACTURING CO INC",
    supplierAddress: "AU GRES,MI",
    supplierStreet: "",
    supplierCity: "AU GRES",
    supplierState: "MI",
    supplierZip: "487039748",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84763236-021093638",
    partNumber: "84763236",
    description: "SHIELD-FRT BRK",
    supplierName: "BOPP-BUSCH MANUFACTURING CO INC",
    supplierAddress: "AU GRES,MI",
    supplierStreet: "",
    supplierCity: "AU GRES",
    supplierState: "MI",
    supplierZip: "487039748",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84763642-000240150",
    partNumber: "84763642",
    description: "GASKET-EXH SYS",
    supplierName: "DANA SEALING MANUFACTURING LLC",
    supplierAddress: "DANVILLE,KY",
    supplierStreet: "",
    supplierCity: "DANVILLE",
    supplierState: "KY",
    supplierZip: "404229499",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84764051-812879120",
    partNumber: "84764051",
    description: "CAP-R/FLR PNL S",
    supplierName: "US FARATHANE SA DE CV",
    supplierAddress: "APODACA,NL",
    supplierStreet: "",
    supplierCity: "APODACA",
    supplierState: "NL",
    supplierZip: "66634",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84765006-812816935",
    partNumber: "84765006",
    description: "SWITCH ASM-TRLR",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84765077-816657977",
    partNumber: "84765077",
    description: "PIPE ASM-FRT &",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84765081-243459851",
    partNumber: "84765081",
    description: "BRACKET ASM-ACT",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84766790-005245873",
    partNumber: "84766790",
    description: "SEAL ASM-HOOD R",
    supplierName: "GDC INC",
    supplierAddress: "GOSHEN,IN",
    supplierStreet: "",
    supplierCity: "GOSHEN",
    supplierState: "IN",
    supplierZip: "465283508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84766791-005245873",
    partNumber: "84766791",
    description: "SEAL ASM-HOOD R",
    supplierName: "GDC INC",
    supplierAddress: "GOSHEN,IN",
    supplierStreet: "",
    supplierCity: "GOSHEN",
    supplierState: "IN",
    supplierZip: "465283508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84766792-005245873",
    partNumber: "84766792",
    description: "SEAL ASM-HOOD R",
    supplierName: "GDC INC",
    supplierAddress: "GOSHEN,IN",
    supplierStreet: "",
    supplierCity: "GOSHEN",
    supplierState: "IN",
    supplierZip: "465283508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84766793-005245873",
    partNumber: "84766793",
    description: "SEAL ASM-HOOD R",
    supplierName: "GDC INC",
    supplierAddress: "GOSHEN,IN",
    supplierStreet: "",
    supplierCity: "GOSHEN",
    supplierState: "IN",
    supplierZip: "465283508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84770023-607039732",
    partNumber: "84770023",
    description: "HARNESS ASM-TRL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84771358-812416683",
    partNumber: "84771358",
    description: "DUCT-AUX A/C AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84771359-812416683",
    partNumber: "84771359",
    description: "DUCT-AUX A/C AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84771361-080445381",
    partNumber: "84771361",
    description: "STRUT ASM-L/GAT",
    supplierName: "EDSCHA AUTOMOTIVE MICHIGAN INC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483264315",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84771365-080786860",
    partNumber: "84771365",
    description: "CLIP-F/FLR CNSL",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84771380-080786860",
    partNumber: "84771380",
    description: "PIN-F/FLR CNSL",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84771975-254064116",
    partNumber: "84771975",
    description: "REINFORCEMENT A",
    supplierName: "KIRCHHOFF AUTOMOTIVE CANADA INC",
    supplierAddress: "NORTH YORK,ON",
    supplierStreet: "",
    supplierCity: "NORTH YORK",
    supplierState: "ON",
    supplierZip: "M9M 2H2",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84772348-021093638",
    partNumber: "84772348",
    description: "BRACKET-HTR WAT",
    supplierName: "BOPP-BUSCH MANUFACTURING CO INC",
    supplierAddress: "AU GRES,MI",
    supplierStreet: "",
    supplierCity: "AU GRES",
    supplierState: "MI",
    supplierZip: "487039748",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84772855-826223968",
    partNumber: "84772855",
    description: "CYLINDER UNIT-L",
    supplierName: "STRATTEC SECURITY CORP",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367723",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84773164-000202272",
    partNumber: "84773164",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84773165-000202272",
    partNumber: "84773165",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84773166-000202272",
    partNumber: "84773166",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84773167-000202272",
    partNumber: "84773167",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84773168-000202272",
    partNumber: "84773168",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84773170-000202272",
    partNumber: "84773170",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84773171-000202272",
    partNumber: "84773171",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84773172-000202272",
    partNumber: "84773172",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84773173-000202272",
    partNumber: "84773173",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84773174-000202272",
    partNumber: "84773174",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84773313-812308799",
    partNumber: "84773313",
    description: "MODULE ASM-CHAS",
    supplierName: "CONTINENTAL AUTOMOTIVE MEXICANA SA",
    supplierAddress: "CUAUTLA,MR",
    supplierStreet: "",
    supplierCity: "CUAUTLA",
    supplierState: "MR",
    supplierZip: "62743",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84774801-969789283",
    partNumber: "84774801",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774802-969789283",
    partNumber: "84774802",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774803-969789283",
    partNumber: "84774803",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774804-969789283",
    partNumber: "84774804",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774805-969789283",
    partNumber: "84774805",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774807-969789283",
    partNumber: "84774807",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774808-969789283",
    partNumber: "84774808",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774809-969789283",
    partNumber: "84774809",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774810-969789283",
    partNumber: "84774810",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774811-969789283",
    partNumber: "84774811",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774813-969789283",
    partNumber: "84774813",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774814-969789283",
    partNumber: "84774814",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774815-969789283",
    partNumber: "84774815",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774816-969789283",
    partNumber: "84774816",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774817-969789283",
    partNumber: "84774817",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774819-969789283",
    partNumber: "84774819",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774820-969789283",
    partNumber: "84774820",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774821-969789283",
    partNumber: "84774821",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774822-969789283",
    partNumber: "84774822",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774823-969789283",
    partNumber: "84774823",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774825-969789283",
    partNumber: "84774825",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774826-969789283",
    partNumber: "84774826",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774827-969789283",
    partNumber: "84774827",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774828-969789283",
    partNumber: "84774828",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774829-969789283",
    partNumber: "84774829",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774831-969789283",
    partNumber: "84774831",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774832-969789283",
    partNumber: "84774832",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774834-969789283",
    partNumber: "84774834",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774835-969789283",
    partNumber: "84774835",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84774836-969789283",
    partNumber: "84774836",
    description: "PANEL ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84775135-050614114",
    partNumber: "84775135",
    description: "INSULATOR-RR ST",
    supplierName: "AIRBOSS FLEXIBLE PRODUCTS CO",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84775136-050614114",
    partNumber: "84775136",
    description: "INSULATOR-RR ST",
    supplierName: "AIRBOSS FLEXIBLE PRODUCTS CO",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84775412-966669645",
    partNumber: "84775412",
    description: "SHIELD-INT AIR",
    supplierName: "UNDERCAR PRODUCTS GROUP INC",
    supplierAddress: "WYOMING,MI",
    supplierStreet: "",
    supplierCity: "WYOMING",
    supplierState: "MI",
    supplierZip: "49508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84775643-117409100",
    partNumber: "84775643",
    description: "PANEL ASM-RF RR",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84776049-000202241",
    partNumber: "84776049",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84776050-000202241",
    partNumber: "84776050",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84776051-000202241",
    partNumber: "84776051",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84776053-000202241",
    partNumber: "84776053",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84776054-000202241",
    partNumber: "84776054",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84776055-000202241",
    partNumber: "84776055",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84776589-079948011",
    partNumber: "84776589",
    description: "LAMP ASM-R/SEAT",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84776590-079948011",
    partNumber: "84776590",
    description: "LAMP ASM-R/SEAT",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84776591-079948011",
    partNumber: "84776591",
    description: "LAMP ASM-R/SEAT",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84776592-079948011",
    partNumber: "84776592",
    description: "LAMP ASM-R/SEAT",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84776627-000187856",
    partNumber: "84776627",
    description: "SPEAKER ASM-RDO",
    supplierName: "BOSE CORP",
    supplierAddress: "HUDSONVILLE,MI",
    supplierStreet: "",
    supplierCity: "HUDSONVILLE",
    supplierState: "MI",
    supplierZip: "49426",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84777048-259986636",
    partNumber: "84777048",
    description: "SHUTTER ASM-FRT",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5S8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84777692-814814042",
    partNumber: "84777692",
    description: "HOSE ASM-HTR OT",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84778267-812308799",
    partNumber: "84778267",
    description: "MODULE ASM-CHAS",
    supplierName: "CONTINENTAL AUTOMOTIVE MEXICANA SA",
    supplierAddress: "CUAUTLA,MR",
    supplierStreet: "",
    supplierCity: "CUAUTLA",
    supplierState: "MR",
    supplierZip: "62743",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84778816-812234458",
    partNumber: "84778816",
    description: "REGULATOR ASM-F",
    supplierName: "BROSE MEXICO SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76120",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84778817-812234458",
    partNumber: "84778817",
    description: "REGULATOR ASM-F",
    supplierName: "BROSE MEXICO SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76120",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84778818-812234458",
    partNumber: "84778818",
    description: "REGULATOR ASM-R",
    supplierName: "BROSE MEXICO SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76120",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84778819-812234458",
    partNumber: "84778819",
    description: "REGULATOR ASM-R",
    supplierName: "BROSE MEXICO SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76120",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84778820-812234458",
    partNumber: "84778820",
    description: "REGULATOR ASM-R",
    supplierName: "BROSE MEXICO SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76120",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84778821-812234458",
    partNumber: "84778821",
    description: "REGULATOR ASM-R",
    supplierName: "BROSE MEXICO SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76120",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84778927-969789283",
    partNumber: "84778927",
    description: "COVER-F/SEAT AD",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84778928-969789283",
    partNumber: "84778928",
    description: "COVER-F/SEAT AD",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84778929-969789283",
    partNumber: "84778929",
    description: "COVER-F/SEAT AD",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84778930-969789283",
    partNumber: "84778930",
    description: "COVER-F/SEAT AD",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84778931-969789283",
    partNumber: "84778931",
    description: "COVER-F/SEAT AD",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84778933-969789283",
    partNumber: "84778933",
    description: "COVER-F/SEAT AD",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84778934-969789283",
    partNumber: "84778934",
    description: "COVER-F/SEAT AD",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84778935-969789283",
    partNumber: "84778935",
    description: "COVER-F/SEAT AD",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84778936-969789283",
    partNumber: "84778936",
    description: "COVER-F/SEAT AD",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84778937-969789283",
    partNumber: "84778937",
    description: "COVER-F/SEAT AD",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84779818-129617036",
    partNumber: "84779818",
    description: "LAMP ASM-RR CLS",
    supplierName: "DECOFINMEX SA DE CV",
    supplierAddress: "SAN BENITO,TX",
    supplierStreet: "",
    supplierCity: "SAN BENITO",
    supplierState: "TX",
    supplierZip: "78586",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84779819-129617036",
    partNumber: "84779819",
    description: "LAMP ASM-RR CLS",
    supplierName: "DECOFINMEX SA DE CV",
    supplierAddress: "SAN BENITO,TX",
    supplierStreet: "",
    supplierCity: "SAN BENITO",
    supplierState: "TX",
    supplierZip: "78586",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84780506-078488161",
    partNumber: "84780506",
    description: "SPEAKER ASM-RDO",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84780739-117409100",
    partNumber: "84780739",
    description: "PANEL ASM-RKR I",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84780740-117409100",
    partNumber: "84780740",
    description: "PANEL ASM-RKR I",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84780741-117409100",
    partNumber: "84780741",
    description: "PANEL ASM-RKR I",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84782654-009971289",
    partNumber: "84782654",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84782655-009971289",
    partNumber: "84782655",
    description: "WEATHERSTR",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84783437-812816935",
    partNumber: "84783437",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84783441-812816935",
    partNumber: "84783441",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84783443-812816935",
    partNumber: "84783443",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84783444-812816935",
    partNumber: "84783444",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84783445-812816935",
    partNumber: "84783445",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84783446-812816935",
    partNumber: "84783446",
    description: "SWITCH ASM-RIDE",
    supplierName: "KOSTAL MEXICANA SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84785031-000782587",
    partNumber: "84785031",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32557",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84785032-000782587",
    partNumber: "84785032",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32557",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84786103-829470223",
    partNumber: "84786103",
    description: "RECEPTACLE ASM-",
    supplierName: "CASCO PRODUCTS CORP",
    supplierAddress: "MORGANTOWN,KY",
    supplierStreet: "",
    supplierCity: "MORGANTOWN",
    supplierState: "KY",
    supplierZip: "422617361",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786104-829470223",
    partNumber: "84786104",
    description: "RECEPTACLE ASM-",
    supplierName: "CASCO PRODUCTS CORP",
    supplierAddress: "MORGANTOWN,KY",
    supplierStreet: "",
    supplierCity: "MORGANTOWN",
    supplierState: "KY",
    supplierZip: "422617361",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786496-137355322",
    partNumber: "84786496",
    description: "SWITCH ASM-VEH",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786497-137355322",
    partNumber: "84786497",
    description: "SWITCH ASM-VEH",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786528-607039732",
    partNumber: "84786528",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786530-607039732",
    partNumber: "84786530",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786531-607039732",
    partNumber: "84786531",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786532-607039732",
    partNumber: "84786532",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786533-607039732",
    partNumber: "84786533",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786538-607039732",
    partNumber: "84786538",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786547-607039732",
    partNumber: "84786547",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786548-607039732",
    partNumber: "84786548",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786562-607039732",
    partNumber: "84786562",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786563-607039732",
    partNumber: "84786563",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786941-839434800",
    partNumber: "84786941",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786949-839434800",
    partNumber: "84786949",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786950-839434800",
    partNumber: "84786950",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786952-839434800",
    partNumber: "84786952",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84786953-839434800",
    partNumber: "84786953",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787247-814180139",
    partNumber: "84787247",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84787248-814180139",
    partNumber: "84787248",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84787249-814180139",
    partNumber: "84787249",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84787251-814180139",
    partNumber: "84787251",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84787252-814180139",
    partNumber: "84787252",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84787253-814180139",
    partNumber: "84787253",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84787701-839434800",
    partNumber: "84787701",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787702-839434800",
    partNumber: "84787702",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787703-839434800",
    partNumber: "84787703",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787704-839434800",
    partNumber: "84787704",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787705-839434800",
    partNumber: "84787705",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787706-839434800",
    partNumber: "84787706",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787707-839434800",
    partNumber: "84787707",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787708-839434800",
    partNumber: "84787708",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787709-839434800",
    partNumber: "84787709",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787710-839434800",
    partNumber: "84787710",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787711-839434800",
    partNumber: "84787711",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787712-839434800",
    partNumber: "84787712",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787713-839434800",
    partNumber: "84787713",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787714-839434800",
    partNumber: "84787714",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787715-839434800",
    partNumber: "84787715",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787716-839434800",
    partNumber: "84787716",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787717-839434800",
    partNumber: "84787717",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787718-839434800",
    partNumber: "84787718",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787719-839434800",
    partNumber: "84787719",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787720-839434800",
    partNumber: "84787720",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787721-839434800",
    partNumber: "84787721",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787722-839434800",
    partNumber: "84787722",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787723-839434800",
    partNumber: "84787723",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787724-839434800",
    partNumber: "84787724",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787725-839434800",
    partNumber: "84787725",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787726-839434800",
    partNumber: "84787726",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787727-839434800",
    partNumber: "84787727",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787728-839434800",
    partNumber: "84787728",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787729-839434800",
    partNumber: "84787729",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787730-839434800",
    partNumber: "84787730",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787731-839434800",
    partNumber: "84787731",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787732-839434800",
    partNumber: "84787732",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787733-839434800",
    partNumber: "84787733",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84787836-968663109",
    partNumber: "84787836",
    description: "LATCH ASM-HOOD",
    supplierName: "PYEONGHWA AUTOMOTIVE USA LLC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788010-839434800",
    partNumber: "84788010",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788011-839434800",
    partNumber: "84788011",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788014-839434800",
    partNumber: "84788014",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788015-839434800",
    partNumber: "84788015",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788016-839434800",
    partNumber: "84788016",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788017-839434800",
    partNumber: "84788017",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788018-839434800",
    partNumber: "84788018",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788019-839434800",
    partNumber: "84788019",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788020-839434800",
    partNumber: "84788020",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788021-839434800",
    partNumber: "84788021",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788022-839434800",
    partNumber: "84788022",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788023-839434800",
    partNumber: "84788023",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788024-839434800",
    partNumber: "84788024",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788025-839434800",
    partNumber: "84788025",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788027-839434800",
    partNumber: "84788027",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788133-080786860",
    partNumber: "84788133",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788134-080786860",
    partNumber: "84788134",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788135-080786860",
    partNumber: "84788135",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788136-080786860",
    partNumber: "84788136",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788503-000289025",
    partNumber: "84788503",
    description: "INSULATOR ASM-I",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "SAN ANTONIO,TX",
    supplierStreet: "",
    supplierCity: "SAN ANTONIO",
    supplierState: "TX",
    supplierZip: "782192313",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84788839-078849132",
    partNumber: "84788839",
    description: "BRACKET-M/FUNC",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84789710-826223968",
    partNumber: "84789710",
    description: "CYLINDER UNIT-L",
    supplierName: "STRATTEC SECURITY CORP",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367723",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84789711-826223968",
    partNumber: "84789711",
    description: "CYLINDER UNIT-L",
    supplierName: "STRATTEC SECURITY CORP",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367723",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84789714-826223968",
    partNumber: "84789714",
    description: "CYLINDER UNIT-L",
    supplierName: "STRATTEC SECURITY CORP",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367723",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84789715-826223968",
    partNumber: "84789715",
    description: "CYLINDER UNIT-L",
    supplierName: "STRATTEC SECURITY CORP",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367723",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84789716-826223968",
    partNumber: "84789716",
    description: "CYLINDER UNIT-L",
    supplierName: "STRATTEC SECURITY CORP",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367723",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84789717-826223968",
    partNumber: "84789717",
    description: "CYLINDER UNIT-L",
    supplierName: "STRATTEC SECURITY CORP",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367723",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84789745-966669645",
    partNumber: "84789745",
    description: "DUCT ASM-A/CL O",
    supplierName: "UNDERCAR PRODUCTS GROUP INC",
    supplierAddress: "WYOMING,MI",
    supplierStreet: "",
    supplierCity: "WYOMING",
    supplierState: "MI",
    supplierZip: "49508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84790035-084668367",
    partNumber: "84790035",
    description: "COVER ASM-ENG W",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "BROOKHAVEN,MS",
    supplierStreet: "",
    supplierCity: "BROOKHAVEN",
    supplierState: "MS",
    supplierZip: "396018951",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84790310-812888257",
    partNumber: "84790310",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84790311-812888257",
    partNumber: "84790311",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84790312-812888257",
    partNumber: "84790312",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84790313-812888257",
    partNumber: "84790313",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84791115-812947059",
    partNumber: "84791115",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84791116-812947059",
    partNumber: "84791116",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84791117-812947059",
    partNumber: "84791117",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84791118-812947059",
    partNumber: "84791118",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84791756-607039732",
    partNumber: "84791756",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84791760-607039732",
    partNumber: "84791760",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84791761-607039732",
    partNumber: "84791761",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84791764-607039732",
    partNumber: "84791764",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84791773-607039732",
    partNumber: "84791773",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84791972-000243147",
    partNumber: "84791972",
    description: "SUPPLEMENT-OWNE",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84792379-026064985",
    partNumber: "84792379",
    description: "PIPE ASM-FUEL H",
    supplierName: "HUTCHINSON FTS INC",
    supplierAddress: "LIVINGSTON,TN",
    supplierStreet: "",
    supplierCity: "LIVINGSTON",
    supplierState: "TN",
    supplierZip: "385701267",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84793091-000246272",
    partNumber: "84793091",
    description: "MICROPHONE ASM-",
    supplierName: "HOSIDEN AMERICA CORP",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "48174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84793092-000246272",
    partNumber: "84793092",
    description: "MICROPHONE ASM-",
    supplierName: "HOSIDEN AMERICA CORP",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "48174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84793093-000246272",
    partNumber: "84793093",
    description: "MICROPHONE ASM-",
    supplierName: "HOSIDEN AMERICA CORP",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "48174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84793094-000246272",
    partNumber: "84793094",
    description: "MICROPHONE ASM-",
    supplierName: "HOSIDEN AMERICA CORP",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "48174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84793852-000243147",
    partNumber: "84793852",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84793854-000243147",
    partNumber: "84793854",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84793856-000243147",
    partNumber: "84793856",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84793858-000243147",
    partNumber: "84793858",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84793866-000243147",
    partNumber: "84793866",
    description: "SUPPLEMENT-OWNE",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84794030-117409100",
    partNumber: "84794030",
    description: "REINFORCEMENT A",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84795337-607039732",
    partNumber: "84795337",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84795339-607039732",
    partNumber: "84795339",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84795345-607039732",
    partNumber: "84795345",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84795348-607039732",
    partNumber: "84795348",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84795354-607039732",
    partNumber: "84795354",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84795361-607039732",
    partNumber: "84795361",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84795371-607039732",
    partNumber: "84795371",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84795372-607039732",
    partNumber: "84795372",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84795393-607039732",
    partNumber: "84795393",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84795394-607039732",
    partNumber: "84795394",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84795521-800786642",
    partNumber: "84795521",
    description: "BRACE-CTR PLR P",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84795522-800786642",
    partNumber: "84795522",
    description: "BRACE-CTR PLR P",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84796263-812416683",
    partNumber: "84796263",
    description: "DUCT ASM-W/S DE",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84796264-003432510",
    partNumber: "84796264",
    description: "COOLER ASM-FUEL",
    supplierName: "DANA THERMAL PRODUCTS LLC",
    supplierAddress: "SAINT CLAIR,MI",
    supplierStreet: "",
    supplierCity: "SAINT CLAIR",
    supplierState: "MI",
    supplierZip: "480795701",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84796279-000246553",
    partNumber: "84796279",
    description: "CARD-NAVN DATA",
    supplierName: "TELENAV INC",
    supplierAddress: "FREMONT,CA",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "CA",
    supplierZip: "945397498",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84796371-042439265",
    partNumber: "84796371",
    description: "BRACKET ASM-FRT",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84796372-042439265",
    partNumber: "84796372",
    description: "BRACKET ASM-FRT",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84796373-042439265",
    partNumber: "84796373",
    description: "BRACKET ASM-FRT",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84796374-042439265",
    partNumber: "84796374",
    description: "BRACKET ASM-FRT",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84796375-042439265",
    partNumber: "84796375",
    description: "BRACKET ASM-FRT",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84796376-042439265",
    partNumber: "84796376",
    description: "BRACKET ASM-FRT",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84796459-808141266",
    partNumber: "84796459",
    description: "BAR ASM-FRT BPR",
    supplierName: "SHAPE CORP",
    supplierAddress: "GRAND HAVEN,MI",
    supplierStreet: "",
    supplierCity: "GRAND HAVEN",
    supplierState: "MI",
    supplierZip: "494179428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84796623-812765504",
    partNumber: "84796623",
    description: "BRACKET-TRANS F",
    supplierName: "SAN LUIS DASUNG SA DE CV",
    supplierAddress: "VILLA DE REYES SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "VILLA DE REYES SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "79525",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84797702-627272024",
    partNumber: "84797702",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84797703-627272024",
    partNumber: "84797703",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84797704-627272024",
    partNumber: "84797704",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84797705-627272024",
    partNumber: "84797705",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84797706-627272024",
    partNumber: "84797706",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84797707-627272024",
    partNumber: "84797707",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84797708-627272024",
    partNumber: "84797708",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84797709-627272024",
    partNumber: "84797709",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84797710-627272024",
    partNumber: "84797710",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84797711-627272024",
    partNumber: "84797711",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84797942-421275621",
    partNumber: "84797942",
    description: "SOCKET-JK/WHL W",
    supplierName: "NANTONG TONGRUN AUTO ACCESSORY CO L",
    supplierAddress: "NANTONG,32",
    supplierStreet: "",
    supplierCity: "NANTONG",
    supplierState: "32",
    supplierZip: "226600",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84798058-000197855",
    partNumber: "84798058",
    description: "MICROPHONE ASM-",
    supplierName: "HARMAN EMBEDDED AUDIO LLC",
    supplierAddress: "INDIANAPOLIS,IN",
    supplierStreet: "",
    supplierCity: "INDIANAPOLIS",
    supplierState: "IN",
    supplierZip: "46268",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84798334-795124908",
    partNumber: "84798334",
    description: "MOUNT PKG-BODY",
    supplierName: "VIBRACOUSTIC USA INC",
    supplierAddress: "MORGANFIELD,KY",
    supplierStreet: "",
    supplierCity: "MORGANFIELD",
    supplierState: "KY",
    supplierZip: "424376622",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84798335-795124908",
    partNumber: "84798335",
    description: "MOUNT PKG-BODY",
    supplierName: "VIBRACOUSTIC USA INC",
    supplierAddress: "MORGANFIELD,KY",
    supplierStreet: "",
    supplierCity: "MORGANFIELD",
    supplierState: "KY",
    supplierZip: "424376622",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84798336-795124908",
    partNumber: "84798336",
    description: "CUSHION ASM-BOD",
    supplierName: "VIBRACOUSTIC USA INC",
    supplierAddress: "MORGANFIELD,KY",
    supplierStreet: "",
    supplierCity: "MORGANFIELD",
    supplierState: "KY",
    supplierZip: "424376622",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84798337-795124908",
    partNumber: "84798337",
    description: "CUSHION ASM-BOD",
    supplierName: "VIBRACOUSTIC USA INC",
    supplierAddress: "MORGANFIELD,KY",
    supplierStreet: "",
    supplierCity: "MORGANFIELD",
    supplierState: "KY",
    supplierZip: "424376622",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84798338-795124908",
    partNumber: "84798338",
    description: "CUSHION ASM-BOD",
    supplierName: "VIBRACOUSTIC USA INC",
    supplierAddress: "MORGANFIELD,KY",
    supplierStreet: "",
    supplierCity: "MORGANFIELD",
    supplierState: "KY",
    supplierZip: "424376622",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84798339-795124908",
    partNumber: "84798339",
    description: "CUSHION ASM-BOD",
    supplierName: "VIBRACOUSTIC USA INC",
    supplierAddress: "MORGANFIELD,KY",
    supplierStreet: "",
    supplierCity: "MORGANFIELD",
    supplierState: "KY",
    supplierZip: "424376622",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84798340-795124908",
    partNumber: "84798340",
    description: "CUSHION ASM-BOD",
    supplierName: "VIBRACOUSTIC USA INC",
    supplierAddress: "MORGANFIELD,KY",
    supplierStreet: "",
    supplierCity: "MORGANFIELD",
    supplierState: "KY",
    supplierZip: "424376622",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84798408-005356662",
    partNumber: "84798408",
    description: "REINFORCEMENT-H",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84798434-022687246",
    partNumber: "84798434",
    description: "SENSOR ASM-L/GA",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84798435-022687246",
    partNumber: "84798435",
    description: "SENSOR ASM-L/GA",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84798951-796778889",
    partNumber: "84798951",
    description: "PLUG-BODY H/PLR",
    supplierName: "PROTECTIVE INDUSTRIES INC",
    supplierAddress: "ERIE,PA",
    supplierStreet: "",
    supplierCity: "ERIE",
    supplierState: "PA",
    supplierZip: "165094447",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84799199-133272877",
    partNumber: "84799199",
    description: "MODULE ASM-PARK",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84799200-133272877",
    partNumber: "84799200",
    description: "MODULE ASM-PARK",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84800897-421251063",
    partNumber: "84800897",
    description: "MODULE ASM-RDO",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84801212-117409100",
    partNumber: "84801212",
    description: "REINFORCEMENT A",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84803140-000202266",
    partNumber: "84803140",
    description: "RECEPTACLE ASM-",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84803141-000202266",
    partNumber: "84803141",
    description: "RECEPTACLE ASM-",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84803476-544634264",
    partNumber: "84803476",
    description: "WHEEL ASM-FRT &",
    supplierName: "CITIC DICASTAL CO LTD PRODUCTION FA",
    supplierAddress: "QINHUANGDAO,13",
    supplierStreet: "",
    supplierCity: "QINHUANGDAO",
    supplierState: "13",
    supplierZip: "66009",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84803606-052507980",
    partNumber: "84803606",
    description: "CONTROL ASM-HTR",
    supplierName: "ALPINE ELECTRONICS OF AMERICA INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037454",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84803611-052507980",
    partNumber: "84803611",
    description: "CONTROL ASM-HTR",
    supplierName: "ALPINE ELECTRONICS OF AMERICA INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037454",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805156-080786860",
    partNumber: "84805156",
    description: "SPRING-F/FLR CN",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805157-080786860",
    partNumber: "84805157",
    description: "SPRING-F/FLR CN",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805425-137355322",
    partNumber: "84805425",
    description: "CONTROL ASM-AM/",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805426-137355322",
    partNumber: "84805426",
    description: "CONTROL ASM-AM/",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805427-137355322",
    partNumber: "84805427",
    description: "CONTROL ASM-AM/",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805428-137355322",
    partNumber: "84805428",
    description: "CONTROL ASM-AM/",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805444-968663109",
    partNumber: "84805444",
    description: "LATCH ASM-HOOD",
    supplierName: "PYEONGHWA AUTOMOTIVE USA LLC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805757-609623566",
    partNumber: "84805757",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805758-609623566",
    partNumber: "84805758",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805759-609623566",
    partNumber: "84805759",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805760-609623566",
    partNumber: "84805760",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805761-609623566",
    partNumber: "84805761",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805762-609623566",
    partNumber: "84805762",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805763-609623566",
    partNumber: "84805763",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805764-609623566",
    partNumber: "84805764",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805765-609623566",
    partNumber: "84805765",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805766-609623566",
    partNumber: "84805766",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805767-609623566",
    partNumber: "84805767",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805768-609623566",
    partNumber: "84805768",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805769-609623566",
    partNumber: "84805769",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805770-609623566",
    partNumber: "84805770",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805771-609623566",
    partNumber: "84805771",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805772-609623566",
    partNumber: "84805772",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805774-609623566",
    partNumber: "84805774",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805775-609623566",
    partNumber: "84805775",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805859-800146693",
    partNumber: "84805859",
    description: "CALIPER ASM-RR",
    supplierName: "MANDO AMERICA CORP",
    supplierAddress: "OPELIKA,AL",
    supplierStreet: "",
    supplierCity: "OPELIKA",
    supplierState: "AL",
    supplierZip: "368019667",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805860-800146693",
    partNumber: "84805860",
    description: "CALIPER ASM-RR",
    supplierName: "MANDO AMERICA CORP",
    supplierAddress: "OPELIKA,AL",
    supplierStreet: "",
    supplierCity: "OPELIKA",
    supplierState: "AL",
    supplierZip: "368019667",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805861-800146693",
    partNumber: "84805861",
    description: "CALIPER ASM-RR",
    supplierName: "MANDO AMERICA CORP",
    supplierAddress: "OPELIKA,AL",
    supplierStreet: "",
    supplierCity: "OPELIKA",
    supplierState: "AL",
    supplierZip: "368019667",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84805862-800146693",
    partNumber: "84805862",
    description: "CALIPER ASM-RR",
    supplierName: "MANDO AMERICA CORP",
    supplierAddress: "OPELIKA,AL",
    supplierStreet: "",
    supplierCity: "OPELIKA",
    supplierState: "AL",
    supplierZip: "368019667",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806051-049816044",
    partNumber: "84806051",
    description: "PLUG-F/FLR PNL",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "FRANKFORT,IL",
    supplierStreet: "",
    supplierCity: "FRANKFORT",
    supplierState: "IL",
    supplierZip: "604236017",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806425-607039732",
    partNumber: "84806425",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806426-607039732",
    partNumber: "84806426",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806427-607039732",
    partNumber: "84806427",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806428-607039732",
    partNumber: "84806428",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806429-607039732",
    partNumber: "84806429",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806431-607039732",
    partNumber: "84806431",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806432-607039732",
    partNumber: "84806432",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806433-607039732",
    partNumber: "84806433",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806434-607039732",
    partNumber: "84806434",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806435-607039732",
    partNumber: "84806435",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806436-607039732",
    partNumber: "84806436",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806437-607039732",
    partNumber: "84806437",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806439-607039732",
    partNumber: "84806439",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806440-609623566",
    partNumber: "84806440",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806441-609623566",
    partNumber: "84806441",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806442-609623566",
    partNumber: "84806442",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806443-609623566",
    partNumber: "84806443",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806444-609623566",
    partNumber: "84806444",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806445-609623566",
    partNumber: "84806445",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806446-609623566",
    partNumber: "84806446",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806447-609623566",
    partNumber: "84806447",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806448-609623566",
    partNumber: "84806448",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806449-609623566",
    partNumber: "84806449",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806450-609623566",
    partNumber: "84806450",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806451-609623566",
    partNumber: "84806451",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806452-609623566",
    partNumber: "84806452",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806453-609623566",
    partNumber: "84806453",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806618-607039732",
    partNumber: "84806618",
    description: "HARNESS ASM-L/G",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806619-607039732",
    partNumber: "84806619",
    description: "HARNESS ASM-L/G",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806620-607039732",
    partNumber: "84806620",
    description: "HARNESS ASM-L/G",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806621-607039732",
    partNumber: "84806621",
    description: "HARNESS ASM-L/G",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806622-607039732",
    partNumber: "84806622",
    description: "HARNESS ASM-L/G",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806623-607039732",
    partNumber: "84806623",
    description: "HARNESS ASM-L/G",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84806679-080445381",
    partNumber: "84806679",
    description: "ACTUATOR ASM-L/",
    supplierName: "EDSCHA AUTOMOTIVE MICHIGAN INC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483264315",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84807718-137355322",
    partNumber: "84807718",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84807769-812815730",
    partNumber: "84807769",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84807770-812815730",
    partNumber: "84807770",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84807771-812815730",
    partNumber: "84807771",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84807772-812815730",
    partNumber: "84807772",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84807773-812815730",
    partNumber: "84807773",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84807774-812815730",
    partNumber: "84807774",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84807775-812815730",
    partNumber: "84807775",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84807776-812815730",
    partNumber: "84807776",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84807777-812815730",
    partNumber: "84807777",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84807778-812815730",
    partNumber: "84807778",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84807862-814604781",
    partNumber: "84807862",
    description: "HOSE ASM-RR BRK",
    supplierName: "FLEXITECH AUTOMOTIVE MEXICO S DE RL",
    supplierAddress: "SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "78395",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84807863-814604781",
    partNumber: "84807863",
    description: "HOSE ASM-RR BRK",
    supplierName: "FLEXITECH AUTOMOTIVE MEXICO S DE RL",
    supplierAddress: "SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "78395",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84808105-000259622",
    partNumber: "84808105",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84808106-000259622",
    partNumber: "84808106",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84808217-253988547",
    partNumber: "84808217",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84808218-253988547",
    partNumber: "84808218",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84808830-080786860",
    partNumber: "84808830",
    description: "CONSOLE ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84809401-813018728",
    partNumber: "84809401",
    description: "MAT ASM-R/FLR",
    supplierName: "MEXAURIA S DE RL DE CV",
    supplierAddress: "EL MARQUES,QA",
    supplierStreet: "",
    supplierCity: "EL MARQUES",
    supplierState: "QA",
    supplierZip: "76246",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84809493-000240150",
    partNumber: "84809493",
    description: "GASKET-EXH SYS",
    supplierName: "DANA SEALING MANUFACTURING LLC",
    supplierAddress: "DANVILLE,KY",
    supplierStreet: "",
    supplierCity: "DANVILLE",
    supplierState: "KY",
    supplierZip: "404229499",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84809512-247524416",
    partNumber: "84809512",
    description: "STEP ASM-ASST",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84809513-247524416",
    partNumber: "84809513",
    description: "STEP ASM-ASST",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84809514-812416683",
    partNumber: "84809514",
    description: "STEP ASM-ASST",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84809515-812416683",
    partNumber: "84809515",
    description: "STEP ASM-ASST",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84809516-247524416",
    partNumber: "84809516",
    description: "STEP ASM-ASST",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84809517-247524416",
    partNumber: "84809517",
    description: "STEP ASM-ASST",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84809518-812416683",
    partNumber: "84809518",
    description: "STEP ASM-ASST",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84809519-812416683",
    partNumber: "84809519",
    description: "STEP ASM-ASST",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84809605-800786642",
    partNumber: "84809605",
    description: "BAFFLE-BODY L/P",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84809606-800786642",
    partNumber: "84809606",
    description: "BAFFLE-BODY L/P",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84810967-000246272",
    partNumber: "84810967",
    description: "MICROPHONE ASM-",
    supplierName: "HOSIDEN AMERICA CORP",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "48174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811000-000246272",
    partNumber: "84811000",
    description: "MICROPHONE ASM-",
    supplierName: "HOSIDEN AMERICA CORP",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "48174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811001-000246272",
    partNumber: "84811001",
    description: "MICROPHONE ASM-",
    supplierName: "HOSIDEN AMERICA CORP",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "48174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811002-000246272",
    partNumber: "84811002",
    description: "MICROPHONE ASM-",
    supplierName: "HOSIDEN AMERICA CORP",
    supplierAddress: "ROMULUS,MI",
    supplierStreet: "",
    supplierCity: "ROMULUS",
    supplierState: "MI",
    supplierZip: "48174",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811616-607039732",
    partNumber: "84811616",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811617-607039732",
    partNumber: "84811617",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811618-607039732",
    partNumber: "84811618",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811621-607039732",
    partNumber: "84811621",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811622-607039732",
    partNumber: "84811622",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811623-607039732",
    partNumber: "84811623",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811624-607039732",
    partNumber: "84811624",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811626-607039732",
    partNumber: "84811626",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811627-607039732",
    partNumber: "84811627",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811631-607039732",
    partNumber: "84811631",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811633-607039732",
    partNumber: "84811633",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811634-607039732",
    partNumber: "84811634",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811635-607039732",
    partNumber: "84811635",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811636-607039732",
    partNumber: "84811636",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811637-607039732",
    partNumber: "84811637",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811638-607039732",
    partNumber: "84811638",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811639-607039732",
    partNumber: "84811639",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811640-607039732",
    partNumber: "84811640",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811641-607039732",
    partNumber: "84811641",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811642-607039732",
    partNumber: "84811642",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811643-607039732",
    partNumber: "84811643",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811644-607039732",
    partNumber: "84811644",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811645-607039732",
    partNumber: "84811645",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811646-607039732",
    partNumber: "84811646",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84811647-607039732",
    partNumber: "84811647",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84812884-022687246",
    partNumber: "84812884",
    description: "SENSOR ASM-L/GA",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84812885-022687246",
    partNumber: "84812885",
    description: "SENSOR ASM-L/GA",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84812886-022687246",
    partNumber: "84812886",
    description: "SENSOR ASM-L/GA",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84812887-022687246",
    partNumber: "84812887",
    description: "SENSOR ASM-L/GA",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84812888-022687246",
    partNumber: "84812888",
    description: "SENSOR ASM-L/GA",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84812889-022687246",
    partNumber: "84812889",
    description: "SENSOR ASM-L/GA",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84813213-654593565",
    partNumber: "84813213",
    description: "PLATE ASM-L/GAT",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84813223-160931085",
    partNumber: "84813223",
    description: "DISTRIBUTOR-AUX",
    supplierName: "SALGA PLASTICS INC",
    supplierAddress: "GALLATIN,TN",
    supplierStreet: "",
    supplierCity: "GALLATIN",
    supplierState: "TN",
    supplierZip: "370663715",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84813409-086663101",
    partNumber: "84813409",
    description: "STIFFENER-RF PN",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84813742-812861466",
    partNumber: "84813742",
    description: "FRAME 2S",
    supplierName: "FORMEX MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25903",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84813743-812861466",
    partNumber: "84813743",
    description: "FRAME 4S",
    supplierName: "FORMEX MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25903",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84813744-812861466",
    partNumber: "84813744",
    description: "FRAME 2L",
    supplierName: "FORMEX MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25903",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84813745-812861466",
    partNumber: "84813745",
    description: "FRAME 4L",
    supplierName: "FORMEX MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25903",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84813752-814180139",
    partNumber: "84813752",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84813753-814180139",
    partNumber: "84813753",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84813754-814180139",
    partNumber: "84813754",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84813756-814180139",
    partNumber: "84813756",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84813757-814180139",
    partNumber: "84813757",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84813758-814180139",
    partNumber: "84813758",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84813760-814180139",
    partNumber: "84813760",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84813761-814180139",
    partNumber: "84813761",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84813762-814180139",
    partNumber: "84813762",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84814008-080786860",
    partNumber: "84814008",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814018-080786860",
    partNumber: "84814018",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814023-080786860",
    partNumber: "84814023",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814688-607039732",
    partNumber: "84814688",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814689-607039732",
    partNumber: "84814689",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814690-607039732",
    partNumber: "84814690",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814691-607039732",
    partNumber: "84814691",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814692-607039732",
    partNumber: "84814692",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814693-607039732",
    partNumber: "84814693",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814694-607039732",
    partNumber: "84814694",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814695-607039732",
    partNumber: "84814695",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814696-607039732",
    partNumber: "84814696",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814697-607039732",
    partNumber: "84814697",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814699-607039732",
    partNumber: "84814699",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814700-607039732",
    partNumber: "84814700",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814701-607039732",
    partNumber: "84814701",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814703-607039732",
    partNumber: "84814703",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814704-607039732",
    partNumber: "84814704",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814705-607039732",
    partNumber: "84814705",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814706-607039732",
    partNumber: "84814706",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814707-607039732",
    partNumber: "84814707",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814709-607039732",
    partNumber: "84814709",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814710-607039732",
    partNumber: "84814710",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814713-607039732",
    partNumber: "84814713",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814714-607039732",
    partNumber: "84814714",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814715-607039732",
    partNumber: "84814715",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814716-607039732",
    partNumber: "84814716",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814717-607039732",
    partNumber: "84814717",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814718-607039732",
    partNumber: "84814718",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814721-607039732",
    partNumber: "84814721",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814722-607039732",
    partNumber: "84814722",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814723-607039732",
    partNumber: "84814723",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814724-607039732",
    partNumber: "84814724",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814726-607039732",
    partNumber: "84814726",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814727-607039732",
    partNumber: "84814727",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814728-607039732",
    partNumber: "84814728",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814729-607039732",
    partNumber: "84814729",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814730-607039732",
    partNumber: "84814730",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814731-607039732",
    partNumber: "84814731",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814732-607039732",
    partNumber: "84814732",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814733-607039732",
    partNumber: "84814733",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814734-607039732",
    partNumber: "84814734",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814735-607039732",
    partNumber: "84814735",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814736-607039732",
    partNumber: "84814736",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814738-607039732",
    partNumber: "84814738",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814739-607039732",
    partNumber: "84814739",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814740-607039732",
    partNumber: "84814740",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814741-607039732",
    partNumber: "84814741",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814742-607039732",
    partNumber: "84814742",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814743-607039732",
    partNumber: "84814743",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814744-607039732",
    partNumber: "84814744",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814745-607039732",
    partNumber: "84814745",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814749-607039732",
    partNumber: "84814749",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814750-607039732",
    partNumber: "84814750",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814751-607039732",
    partNumber: "84814751",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814752-607039732",
    partNumber: "84814752",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814753-607039732",
    partNumber: "84814753",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84814754-607039732",
    partNumber: "84814754",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815303-607039732",
    partNumber: "84815303",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815304-607039732",
    partNumber: "84815304",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815305-607039732",
    partNumber: "84815305",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815306-607039732",
    partNumber: "84815306",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815309-607039732",
    partNumber: "84815309",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815310-607039732",
    partNumber: "84815310",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815311-607039732",
    partNumber: "84815311",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815312-607039732",
    partNumber: "84815312",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815313-607039732",
    partNumber: "84815313",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815314-607039732",
    partNumber: "84815314",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815315-607039732",
    partNumber: "84815315",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815318-607039732",
    partNumber: "84815318",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815319-607039732",
    partNumber: "84815319",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815320-607039732",
    partNumber: "84815320",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815321-607039732",
    partNumber: "84815321",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815322-607039732",
    partNumber: "84815322",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815323-607039732",
    partNumber: "84815323",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815324-607039732",
    partNumber: "84815324",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815325-607039732",
    partNumber: "84815325",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815326-607039732",
    partNumber: "84815326",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815327-607039732",
    partNumber: "84815327",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815328-607039732",
    partNumber: "84815328",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815332-607039732",
    partNumber: "84815332",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815333-607039732",
    partNumber: "84815333",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815334-607039732",
    partNumber: "84815334",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815337-607039732",
    partNumber: "84815337",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815340-607039732",
    partNumber: "84815340",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815341-607039732",
    partNumber: "84815341",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815343-607039732",
    partNumber: "84815343",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815344-607039732",
    partNumber: "84815344",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815353-607039732",
    partNumber: "84815353",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815355-607039732",
    partNumber: "84815355",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815356-607039732",
    partNumber: "84815356",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815357-607039732",
    partNumber: "84815357",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815358-607039732",
    partNumber: "84815358",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815359-607039732",
    partNumber: "84815359",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815360-607039732",
    partNumber: "84815360",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815361-607039732",
    partNumber: "84815361",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815362-607039732",
    partNumber: "84815362",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815363-607039732",
    partNumber: "84815363",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815364-607039732",
    partNumber: "84815364",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815365-607039732",
    partNumber: "84815365",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815366-607039732",
    partNumber: "84815366",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815367-607039732",
    partNumber: "84815367",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815368-607039732",
    partNumber: "84815368",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815369-607039732",
    partNumber: "84815369",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815370-607039732",
    partNumber: "84815370",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815371-607039732",
    partNumber: "84815371",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815372-607039732",
    partNumber: "84815372",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815373-607039732",
    partNumber: "84815373",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815374-607039732",
    partNumber: "84815374",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815375-607039732",
    partNumber: "84815375",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815376-607039732",
    partNumber: "84815376",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815377-607039732",
    partNumber: "84815377",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815378-607039732",
    partNumber: "84815378",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815379-607039732",
    partNumber: "84815379",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815380-607039732",
    partNumber: "84815380",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815381-607039732",
    partNumber: "84815381",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815382-607039732",
    partNumber: "84815382",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815383-607039732",
    partNumber: "84815383",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815384-607039732",
    partNumber: "84815384",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815385-607039732",
    partNumber: "84815385",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815386-607039732",
    partNumber: "84815386",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815387-607039732",
    partNumber: "84815387",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815388-607039732",
    partNumber: "84815388",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815389-607039732",
    partNumber: "84815389",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815396-607039732",
    partNumber: "84815396",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815399-607039732",
    partNumber: "84815399",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815400-607039732",
    partNumber: "84815400",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815401-607039732",
    partNumber: "84815401",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815402-607039732",
    partNumber: "84815402",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815403-607039732",
    partNumber: "84815403",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815404-607039732",
    partNumber: "84815404",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815405-607039732",
    partNumber: "84815405",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815406-607039732",
    partNumber: "84815406",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815407-607039732",
    partNumber: "84815407",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815408-607039732",
    partNumber: "84815408",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815409-607039732",
    partNumber: "84815409",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815410-607039732",
    partNumber: "84815410",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815411-607039732",
    partNumber: "84815411",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815412-607039732",
    partNumber: "84815412",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815413-607039732",
    partNumber: "84815413",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815414-607039732",
    partNumber: "84815414",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815415-607039732",
    partNumber: "84815415",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815416-607039732",
    partNumber: "84815416",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815417-607039732",
    partNumber: "84815417",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815418-607039732",
    partNumber: "84815418",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815419-607039732",
    partNumber: "84815419",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815420-607039732",
    partNumber: "84815420",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815421-607039732",
    partNumber: "84815421",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815422-607039732",
    partNumber: "84815422",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815423-607039732",
    partNumber: "84815423",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815424-607039732",
    partNumber: "84815424",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815425-607039732",
    partNumber: "84815425",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815426-607039732",
    partNumber: "84815426",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815427-607039732",
    partNumber: "84815427",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815428-607039732",
    partNumber: "84815428",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815429-607039732",
    partNumber: "84815429",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815430-607039732",
    partNumber: "84815430",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815431-607039732",
    partNumber: "84815431",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815432-607039732",
    partNumber: "84815432",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815433-607039732",
    partNumber: "84815433",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815434-607039732",
    partNumber: "84815434",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84815443-607039732",
    partNumber: "84815443",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84816193-812416683",
    partNumber: "84816193",
    description: "DUCT-SI WDO DEF",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816355-000202241",
    partNumber: "84816355",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816356-000202241",
    partNumber: "84816356",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816357-000202241",
    partNumber: "84816357",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816358-000202241",
    partNumber: "84816358",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816359-000202241",
    partNumber: "84816359",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816360-000202241",
    partNumber: "84816360",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816361-000202241",
    partNumber: "84816361",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816362-000202241",
    partNumber: "84816362",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816364-000202241",
    partNumber: "84816364",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816365-000202241",
    partNumber: "84816365",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816366-000202241",
    partNumber: "84816366",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816367-000202241",
    partNumber: "84816367",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816368-000202241",
    partNumber: "84816368",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816369-000202241",
    partNumber: "84816369",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816370-000202241",
    partNumber: "84816370",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816371-000202241",
    partNumber: "84816371",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816392-129617036",
    partNumber: "84816392",
    description: "LAMP ASM-RR BOD",
    supplierName: "DECOFINMEX SA DE CV",
    supplierAddress: "SAN BENITO,TX",
    supplierStreet: "",
    supplierCity: "SAN BENITO",
    supplierState: "TX",
    supplierZip: "78586",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84816393-129617036",
    partNumber: "84816393",
    description: "LAMP ASM-RR BOD",
    supplierName: "DECOFINMEX SA DE CV",
    supplierAddress: "SAN BENITO,TX",
    supplierStreet: "",
    supplierCity: "SAN BENITO",
    supplierState: "TX",
    supplierZip: "78586",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84816586-607039732",
    partNumber: "84816586",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84816587-607039732",
    partNumber: "84816587",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84816588-607039732",
    partNumber: "84816588",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84816589-607039732",
    partNumber: "84816589",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84816761-016422106",
    partNumber: "84816761",
    description: "SHAFT ASM-PROP",
    supplierName: "NEAPCO DRIVELINES LLC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481115271",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84816822-812416683",
    partNumber: "84816822",
    description: "CONTAINER ASM-W",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816823-812416683",
    partNumber: "84816823",
    description: "CONTAINER ASM-W",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84816827-809149292",
    partNumber: "84816827",
    description: "SHUTTER ASM-FRT",
    supplierName: "MAGNA MODULAR SYSTEMS INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891476",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84816828-809149292",
    partNumber: "84816828",
    description: "SHUTTER ASM-FRT",
    supplierName: "MAGNA MODULAR SYSTEMS INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891476",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84816829-809149292",
    partNumber: "84816829",
    description: "SHUTTER ASM-FRT",
    supplierName: "MAGNA MODULAR SYSTEMS INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891476",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84816830-809149292",
    partNumber: "84816830",
    description: "SHUTTER ASM-FRT",
    supplierName: "MAGNA MODULAR SYSTEMS INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891476",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84816831-809149292",
    partNumber: "84816831",
    description: "SHUTTER ASM-FRT",
    supplierName: "MAGNA MODULAR SYSTEMS INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891476",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84816832-809149292",
    partNumber: "84816832",
    description: "SHUTTER ASM-FRT",
    supplierName: "MAGNA MODULAR SYSTEMS INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891476",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84816833-809149292",
    partNumber: "84816833",
    description: "SHUTTER ASM-FRT",
    supplierName: "MAGNA MODULAR SYSTEMS INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891476",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84817040-421279673",
    partNumber: "84817040",
    description: "TRAY ASM-BAT AU",
    supplierName: "WUUSHIANG AUTOMOTIVE (TAICANG) CO",
    supplierAddress: "TAICANG,32",
    supplierStreet: "",
    supplierCity: "TAICANG",
    supplierState: "32",
    supplierZip: "215400",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84817238-000289025",
    partNumber: "84817238",
    description: "INSULATOR ASM-I",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "SAN ANTONIO,TX",
    supplierStreet: "",
    supplierCity: "SAN ANTONIO",
    supplierState: "TX",
    supplierZip: "782192313",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84818413-812815730",
    partNumber: "84818413",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818414-812815730",
    partNumber: "84818414",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818415-812815730",
    partNumber: "84818415",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818416-812815730",
    partNumber: "84818416",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818417-812815730",
    partNumber: "84818417",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818418-812815730",
    partNumber: "84818418",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818419-812815730",
    partNumber: "84818419",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818420-812815730",
    partNumber: "84818420",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818421-812815730",
    partNumber: "84818421",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818422-812815730",
    partNumber: "84818422",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818423-812815730",
    partNumber: "84818423",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818424-812815730",
    partNumber: "84818424",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818425-812815730",
    partNumber: "84818425",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818426-812815730",
    partNumber: "84818426",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818427-812815730",
    partNumber: "84818427",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818428-812815730",
    partNumber: "84818428",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818429-812815730",
    partNumber: "84818429",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818430-812815730",
    partNumber: "84818430",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818431-812815730",
    partNumber: "84818431",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818432-812815730",
    partNumber: "84818432",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818433-812815730",
    partNumber: "84818433",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818434-812815730",
    partNumber: "84818434",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818435-812815730",
    partNumber: "84818435",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818436-812815730",
    partNumber: "84818436",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818437-812815730",
    partNumber: "84818437",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818438-812815730",
    partNumber: "84818438",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818439-812815730",
    partNumber: "84818439",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818440-812815730",
    partNumber: "84818440",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818441-812815730",
    partNumber: "84818441",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84818442-812815730",
    partNumber: "84818442",
    description: "COVER ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84819428-812947059",
    partNumber: "84819428",
    description: "LINER-F/FLR CNS",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84819520-101180995",
    partNumber: "84819520",
    description: "HOIST ASM-SPA W",
    supplierName: "MAG-USA INC",
    supplierAddress: "CLINTON,TN",
    supplierStreet: "",
    supplierCity: "CLINTON",
    supplierState: "TN",
    supplierZip: "377166587",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84819723-812888257",
    partNumber: "84819723",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84819724-812888257",
    partNumber: "84819724",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84819725-812888257",
    partNumber: "84819725",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84819727-812888257",
    partNumber: "84819727",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84819729-812888257",
    partNumber: "84819729",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84820466-005050406",
    partNumber: "84820466",
    description: "WINDOW ASM-R/CM",
    supplierName: "PILKINGTON NORTH AMERICA INC",
    supplierAddress: "ROSSFORD,OH",
    supplierStreet: "",
    supplierCity: "ROSSFORD",
    supplierState: "OH",
    supplierZip: "434601215",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84820467-005050406",
    partNumber: "84820467",
    description: "WINDOW ASM-R/CM",
    supplierName: "PILKINGTON NORTH AMERICA INC",
    supplierAddress: "ROSSFORD,OH",
    supplierStreet: "",
    supplierCity: "ROSSFORD",
    supplierState: "OH",
    supplierZip: "434601215",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84820920-141148051",
    partNumber: "84820920",
    description: "INSULATOR-F/FDR",
    supplierName: "CREATIVE FOAM CORP",
    supplierAddress: "ATHENS,TN",
    supplierStreet: "",
    supplierCity: "ATHENS",
    supplierState: "TN",
    supplierZip: "37371",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84821079-000202241",
    partNumber: "84821079",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821080-000202241",
    partNumber: "84821080",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821081-000202241",
    partNumber: "84821081",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821082-000202241",
    partNumber: "84821082",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821083-000202241",
    partNumber: "84821083",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821084-000202241",
    partNumber: "84821084",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821085-000202241",
    partNumber: "84821085",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821086-000202241",
    partNumber: "84821086",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821088-000202241",
    partNumber: "84821088",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821089-000202241",
    partNumber: "84821089",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821090-000202241",
    partNumber: "84821090",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821091-000202241",
    partNumber: "84821091",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821092-000202241",
    partNumber: "84821092",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821093-000202241",
    partNumber: "84821093",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821094-000202241",
    partNumber: "84821094",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821095-000202241",
    partNumber: "84821095",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84821142-050476923",
    partNumber: "84821142",
    description: "AMPLIFIER ASM-R",
    supplierName: "BOSE CORP",
    supplierAddress: "SAN DIEGO,CA",
    supplierStreet: "",
    supplierCity: "SAN DIEGO",
    supplierState: "CA",
    supplierZip: "921546216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84822048-006016810",
    partNumber: "84822048",
    description: "TIRE CERT LABEL",
    supplierName: "GRAND RAPIDS LABEL CO",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "49505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823327-607039732",
    partNumber: "84823327",
    description: "BLOCK ASM-BODY",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823455-828836937",
    partNumber: "84823455",
    description: "AIRBAG ASM-STRG",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "OGDEN,UT",
    supplierStreet: "",
    supplierCity: "OGDEN",
    supplierState: "UT",
    supplierZip: "844041384",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823456-828836937",
    partNumber: "84823456",
    description: "AIRBAG ASM-STRG",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "OGDEN,UT",
    supplierStreet: "",
    supplierCity: "OGDEN",
    supplierState: "UT",
    supplierZip: "844041384",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823457-828836937",
    partNumber: "84823457",
    description: "AIRBAG ASM-STRG",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "OGDEN,UT",
    supplierStreet: "",
    supplierCity: "OGDEN",
    supplierState: "UT",
    supplierZip: "844041384",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823459-828836937",
    partNumber: "84823459",
    description: "AIRBAG ASM-STRG",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "OGDEN,UT",
    supplierStreet: "",
    supplierCity: "OGDEN",
    supplierState: "UT",
    supplierZip: "844041384",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823460-828836937",
    partNumber: "84823460",
    description: "AIRBAG ASM-STRG",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "OGDEN,UT",
    supplierStreet: "",
    supplierCity: "OGDEN",
    supplierState: "UT",
    supplierZip: "844041384",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823462-828836937",
    partNumber: "84823462",
    description: "AIRBAG ASM-STRG",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "OGDEN,UT",
    supplierStreet: "",
    supplierCity: "OGDEN",
    supplierState: "UT",
    supplierZip: "844041384",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823463-828836937",
    partNumber: "84823463",
    description: "AIRBAG ASM-STRG",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "OGDEN,UT",
    supplierStreet: "",
    supplierCity: "OGDEN",
    supplierState: "UT",
    supplierZip: "844041384",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823464-828836937",
    partNumber: "84823464",
    description: "AIRBAG ASM-STRG",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "OGDEN,UT",
    supplierStreet: "",
    supplierCity: "OGDEN",
    supplierState: "UT",
    supplierZip: "844041384",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823465-828836937",
    partNumber: "84823465",
    description: "AIRBAG ASM-STRG",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "OGDEN,UT",
    supplierStreet: "",
    supplierCity: "OGDEN",
    supplierState: "UT",
    supplierZip: "844041384",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823467-828836937",
    partNumber: "84823467",
    description: "AIRBAG ASM-STRG",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "OGDEN,UT",
    supplierStreet: "",
    supplierCity: "OGDEN",
    supplierState: "UT",
    supplierZip: "844041384",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823468-828836937",
    partNumber: "84823468",
    description: "AIRBAG ASM-STRG",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "OGDEN,UT",
    supplierStreet: "",
    supplierCity: "OGDEN",
    supplierState: "UT",
    supplierZip: "844041384",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823470-828836937",
    partNumber: "84823470",
    description: "AIRBAG ASM-STRG",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "OGDEN,UT",
    supplierStreet: "",
    supplierCity: "OGDEN",
    supplierState: "UT",
    supplierZip: "844041384",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823471-828836937",
    partNumber: "84823471",
    description: "AIRBAG ASM-STRG",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "OGDEN,UT",
    supplierStreet: "",
    supplierCity: "OGDEN",
    supplierState: "UT",
    supplierZip: "844041384",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823472-828836937",
    partNumber: "84823472",
    description: "AIRBAG ASM-STRG",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "OGDEN,UT",
    supplierStreet: "",
    supplierCity: "OGDEN",
    supplierState: "UT",
    supplierZip: "844041384",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823499-080786860",
    partNumber: "84823499",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823500-080786860",
    partNumber: "84823500",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823502-080786860",
    partNumber: "84823502",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823503-080786860",
    partNumber: "84823503",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823505-080786860",
    partNumber: "84823505",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823506-080786860",
    partNumber: "84823506",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823507-080786860",
    partNumber: "84823507",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823508-080786860",
    partNumber: "84823508",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823509-080786860",
    partNumber: "84823509",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823510-080786860",
    partNumber: "84823510",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823512-080786860",
    partNumber: "84823512",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823513-080786860",
    partNumber: "84823513",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823515-080786860",
    partNumber: "84823515",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823516-080786860",
    partNumber: "84823516",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823517-080786860",
    partNumber: "84823517",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823518-080786860",
    partNumber: "84823518",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823520-080786860",
    partNumber: "84823520",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823521-080786860",
    partNumber: "84823521",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823523-080786860",
    partNumber: "84823523",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823524-080786860",
    partNumber: "84823524",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823525-080786860",
    partNumber: "84823525",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823526-080786860",
    partNumber: "84823526",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823527-080786860",
    partNumber: "84823527",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823528-080786860",
    partNumber: "84823528",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823530-080786860",
    partNumber: "84823530",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823531-080786860",
    partNumber: "84823531",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823533-080786860",
    partNumber: "84823533",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823534-080786860",
    partNumber: "84823534",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823535-080786860",
    partNumber: "84823535",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823536-080786860",
    partNumber: "84823536",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823538-080786860",
    partNumber: "84823538",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823539-080786860",
    partNumber: "84823539",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823540-080786860",
    partNumber: "84823540",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823541-080786860",
    partNumber: "84823541",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823542-080786860",
    partNumber: "84823542",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823543-080786860",
    partNumber: "84823543",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823545-080786860",
    partNumber: "84823545",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823546-080786860",
    partNumber: "84823546",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823547-080786860",
    partNumber: "84823547",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823548-080786860",
    partNumber: "84823548",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823550-080786860",
    partNumber: "84823550",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823551-080786860",
    partNumber: "84823551",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823553-080786860",
    partNumber: "84823553",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823554-080786860",
    partNumber: "84823554",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823556-080786860",
    partNumber: "84823556",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823557-080786860",
    partNumber: "84823557",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823558-080786860",
    partNumber: "84823558",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823559-080786860",
    partNumber: "84823559",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823560-080786860",
    partNumber: "84823560",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823561-080786860",
    partNumber: "84823561",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823563-080786860",
    partNumber: "84823563",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823564-080786860",
    partNumber: "84823564",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823565-080786860",
    partNumber: "84823565",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823566-080786860",
    partNumber: "84823566",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823568-080786860",
    partNumber: "84823568",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84823569-080786860",
    partNumber: "84823569",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824211-203254292",
    partNumber: "84824211",
    description: "STEP ASM-ASST",
    supplierName: "MAGNA CLOSURES",
    supplierAddress: "WOODBRIDGE,ON",
    supplierStreet: "",
    supplierCity: "WOODBRIDGE",
    supplierState: "ON",
    supplierZip: "L4L 9C7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84824212-203254292",
    partNumber: "84824212",
    description: "STEP ASM-ASST",
    supplierName: "MAGNA CLOSURES",
    supplierAddress: "WOODBRIDGE,ON",
    supplierStreet: "",
    supplierCity: "WOODBRIDGE",
    supplierState: "ON",
    supplierZip: "L4L 9C7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84824213-203254292",
    partNumber: "84824213",
    description: "STEP ASM-ASST",
    supplierName: "MAGNA CLOSURES",
    supplierAddress: "WOODBRIDGE,ON",
    supplierStreet: "",
    supplierCity: "WOODBRIDGE",
    supplierState: "ON",
    supplierZip: "L4L 9C7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84824214-203254292",
    partNumber: "84824214",
    description: "STEP ASM-ASST",
    supplierName: "MAGNA CLOSURES",
    supplierAddress: "WOODBRIDGE,ON",
    supplierStreet: "",
    supplierCity: "WOODBRIDGE",
    supplierState: "ON",
    supplierZip: "L4L 9C7",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84824308-814180139",
    partNumber: "84824308",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824310-814180139",
    partNumber: "84824310",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824311-814180139",
    partNumber: "84824311",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824313-814180139",
    partNumber: "84824313",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824314-814180139",
    partNumber: "84824314",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824315-814180139",
    partNumber: "84824315",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824316-814180139",
    partNumber: "84824316",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824317-814180139",
    partNumber: "84824317",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824319-814180139",
    partNumber: "84824319",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824320-814180139",
    partNumber: "84824320",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824321-814180139",
    partNumber: "84824321",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824322-814180139",
    partNumber: "84824322",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824323-814180139",
    partNumber: "84824323",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824325-814180139",
    partNumber: "84824325",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824326-814180139",
    partNumber: "84824326",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824327-814180139",
    partNumber: "84824327",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824328-814180139",
    partNumber: "84824328",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824330-814180139",
    partNumber: "84824330",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824335-814180139",
    partNumber: "84824335",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824336-814180139",
    partNumber: "84824336",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824337-814180139",
    partNumber: "84824337",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824338-814180139",
    partNumber: "84824338",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824339-814180139",
    partNumber: "84824339",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824341-814180139",
    partNumber: "84824341",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824342-814180139",
    partNumber: "84824342",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824343-814180139",
    partNumber: "84824343",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824344-814180139",
    partNumber: "84824344",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824345-814180139",
    partNumber: "84824345",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824347-814180139",
    partNumber: "84824347",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824348-814180139",
    partNumber: "84824348",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824349-814180139",
    partNumber: "84824349",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824350-814180139",
    partNumber: "84824350",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824438-808141266",
    partNumber: "84824438",
    description: "BAR ASM-RR BPR",
    supplierName: "SHAPE CORP",
    supplierAddress: "GRAND HAVEN,MI",
    supplierStreet: "",
    supplierCity: "GRAND HAVEN",
    supplierState: "MI",
    supplierZip: "494179428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824505-812947059",
    partNumber: "84824505",
    description: "REINFORCEMENT-H",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824506-812947059",
    partNumber: "84824506",
    description: "REINFORCEMENT-H",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84824518-080786860",
    partNumber: "84824518",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824519-080786860",
    partNumber: "84824519",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824521-080786860",
    partNumber: "84824521",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824522-080786860",
    partNumber: "84824522",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824523-080786860",
    partNumber: "84824523",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824527-080786860",
    partNumber: "84824527",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824531-080786860",
    partNumber: "84824531",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824532-080786860",
    partNumber: "84824532",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824534-080786860",
    partNumber: "84824534",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824535-080786860",
    partNumber: "84824535",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824537-080786860",
    partNumber: "84824537",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824538-080786860",
    partNumber: "84824538",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824550-080786860",
    partNumber: "84824550",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824551-080786860",
    partNumber: "84824551",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824553-080786860",
    partNumber: "84824553",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824554-080786860",
    partNumber: "84824554",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824555-080786860",
    partNumber: "84824555",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824559-080786860",
    partNumber: "84824559",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824563-080786860",
    partNumber: "84824563",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824564-080786860",
    partNumber: "84824564",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824566-080786860",
    partNumber: "84824566",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824567-080786860",
    partNumber: "84824567",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824569-080786860",
    partNumber: "84824569",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824570-080786860",
    partNumber: "84824570",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84824704-368135153",
    partNumber: "84824704",
    description: "MODULE ASM-ELEK",
    supplierName: "BWI POLAND TECHNOLOGIES SP ZOO",
    supplierAddress: "KROSNO,PD",
    supplierStreet: "",
    supplierCity: "KROSNO",
    supplierState: "PD",
    supplierZip: "38-400",
    supplierCountry: "PL",
  },
  {
    _id: "parts-gm-84825986-006016810",
    partNumber: "84825986",
    description: "TAG-I/P AIRBAG",
    supplierName: "GRAND RAPIDS LABEL CO",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "49505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84826157-812947059",
    partNumber: "84826157",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84826158-812947059",
    partNumber: "84826158",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84826159-812947059",
    partNumber: "84826159",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84826160-812947059",
    partNumber: "84826160",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84826162-812947059",
    partNumber: "84826162",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84826163-812947059",
    partNumber: "84826163",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84826164-812947059",
    partNumber: "84826164",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84826165-812947059",
    partNumber: "84826165",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84827331-186318176",
    partNumber: "84827331",
    description: "REINFORCEMENT-R",
    supplierName: "HUTCHINSON ANTIVIBRATION SYSTEMS IN",
    supplierAddress: "CADILLAC,MI",
    supplierStreet: "",
    supplierCity: "CADILLAC",
    supplierState: "MI",
    supplierZip: "496011345",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84827797-067392746",
    partNumber: "84827797",
    description: "STRUT ASM-L/GAT",
    supplierName: "STABILUS INC",
    supplierAddress: "GASTONIA,NC",
    supplierStreet: "",
    supplierCity: "GASTONIA",
    supplierState: "NC",
    supplierZip: "280521842",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84827798-067392746",
    partNumber: "84827798",
    description: "STRUT ASM-L/GAT",
    supplierName: "STABILUS INC",
    supplierAddress: "GASTONIA,NC",
    supplierStreet: "",
    supplierCity: "GASTONIA",
    supplierState: "NC",
    supplierZip: "280521842",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84827799-067392746",
    partNumber: "84827799",
    description: "STRUT ASM-L/GAT",
    supplierName: "STABILUS INC",
    supplierAddress: "GASTONIA,NC",
    supplierStreet: "",
    supplierCity: "GASTONIA",
    supplierState: "NC",
    supplierZip: "280521842",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84827800-067392746",
    partNumber: "84827800",
    description: "STRUT ASM-L/GAT",
    supplierName: "STABILUS INC",
    supplierAddress: "GASTONIA,NC",
    supplierStreet: "",
    supplierCity: "GASTONIA",
    supplierState: "NC",
    supplierZip: "280521842",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84827981-688001481",
    partNumber: "84827981",
    description: "MOUNT-TRANS",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84827982-688001481",
    partNumber: "84827982",
    description: "MOUNT-TRANS",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84827995-242059041",
    partNumber: "84827995",
    description: "MOLDING ASM-RF",
    supplierName: "ROYAL AUTOMOTIVE GROUP LTD",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84827996-242059041",
    partNumber: "84827996",
    description: "MOLDING ASM-RF",
    supplierName: "ROYAL AUTOMOTIVE GROUP LTD",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84827997-242059041",
    partNumber: "84827997",
    description: "MOLDING ASM-RF",
    supplierName: "ROYAL AUTOMOTIVE GROUP LTD",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84827998-242059041",
    partNumber: "84827998",
    description: "MOLDING ASM-RF",
    supplierName: "ROYAL AUTOMOTIVE GROUP LTD",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84828332-000259622",
    partNumber: "84828332",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84828333-000259622",
    partNumber: "84828333",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84828335-000259622",
    partNumber: "84828335",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84828336-000259622",
    partNumber: "84828336",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84828343-042439265",
    partNumber: "84828343",
    description: "REINFORCEMENT A",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84828435-812761886",
    partNumber: "84828435",
    description: "TANK ASM-FUEL",
    supplierName: "PLASTIC OMNIUM AUTO INERGY",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84828437-812761886",
    partNumber: "84828437",
    description: "TANK ASM-FUEL",
    supplierName: "PLASTIC OMNIUM AUTO INERGY",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84828543-050150481",
    partNumber: "84828543",
    description: "SHIELD-U/B SPH",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84828862-080786860",
    partNumber: "84828862",
    description: "PANEL ASM-HDLNG",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84828863-080786860",
    partNumber: "84828863",
    description: "PANEL ASM-HDLNG",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84828865-080786860",
    partNumber: "84828865",
    description: "PANEL ASM-HDLNG",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84828866-080786860",
    partNumber: "84828866",
    description: "PANEL ASM-HDLNG",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84828867-080786860",
    partNumber: "84828867",
    description: "PANEL ASM-HDLNG",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84828869-080786860",
    partNumber: "84828869",
    description: "PANEL ASM-HDLNG",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84828870-080786860",
    partNumber: "84828870",
    description: "PANEL ASM-HDLNG",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84829188-812806857",
    partNumber: "84829188",
    description: "AXLE ASM-FRT",
    supplierName: "AMERICAN AXLE & MFG DE MEX S DE RL",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36100",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84829207-969789283",
    partNumber: "84829207",
    description: "MOLDING ASM-RKR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84829326-814604781",
    partNumber: "84829326",
    description: "HOSE ASM-FRT BR",
    supplierName: "FLEXITECH AUTOMOTIVE MEXICO S DE RL",
    supplierAddress: "SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "78395",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84829328-814604781",
    partNumber: "84829328",
    description: "HOSE ASM-FRT BR",
    supplierName: "FLEXITECH AUTOMOTIVE MEXICO S DE RL",
    supplierAddress: "SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "78395",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84829329-814604781",
    partNumber: "84829329",
    description: "HOSE ASM-FRT BR",
    supplierName: "FLEXITECH AUTOMOTIVE MEXICO S DE RL",
    supplierAddress: "SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "78395",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84830103-080203455",
    partNumber: "84830103",
    description: "HOSE ASM-R/WDO",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84830104-080203455",
    partNumber: "84830104",
    description: "HOSE ASM-R/WDO",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84830105-080203455",
    partNumber: "84830105",
    description: "HOSE ASM-R/WDO",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84830106-969789283",
    partNumber: "84830106",
    description: "PANEL ASM-AIR I",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84830107-969789283",
    partNumber: "84830107",
    description: "PANEL ASM-AIR I",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84830110-800786642",
    partNumber: "84830110",
    description: "BAFFLE ASM-FRT",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84830111-800786642",
    partNumber: "84830111",
    description: "BAFFLE ASM-FRT",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84830179-000241752",
    partNumber: "84830179",
    description: "CONTROL ASM-HTR",
    supplierName: "PREH INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780416852",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84830180-000241752",
    partNumber: "84830180",
    description: "CONTROL ASM-HTR",
    supplierName: "PREH INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780416852",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84830181-000241752",
    partNumber: "84830181",
    description: "CONTROL ASM-HTR",
    supplierName: "PREH INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780416852",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84830182-000241752",
    partNumber: "84830182",
    description: "CONTROL ASM-HTR",
    supplierName: "PREH INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780416852",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84830183-000241752",
    partNumber: "84830183",
    description: "CONTROL ASM-HTR",
    supplierName: "PREH INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780416852",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84830620-812133478",
    partNumber: "84830620",
    description: "TRAY ASM-BAT",
    supplierName: "VENTRAMEX S DE RL DE CV",
    supplierAddress: "EL MARQUES,QA",
    supplierStreet: "",
    supplierCity: "EL MARQUES",
    supplierState: "QA",
    supplierZip: "76249",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84830621-812548816",
    partNumber: "84830621",
    description: "INSULATOR ASM-B",
    supplierName: "FLEX-N-GATE MEXICO S DE RL DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84830622-943793091",
    partNumber: "84830622",
    description: "PLATE ASM-FRT S",
    supplierName: "TRIBAR MANUFACTURING LLC",
    supplierAddress: "HOWELL,MI",
    supplierStreet: "",
    supplierCity: "HOWELL",
    supplierState: "MI",
    supplierZip: "488557320",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84831487-783658883",
    partNumber: "84831487",
    description: "RAIL ASM-LUGG C",
    supplierName: "FRANKLIN ALUMINUM COMPANY INC",
    supplierAddress: "FRANKLIN,GA",
    supplierStreet: "",
    supplierCity: "FRANKLIN",
    supplierState: "GA",
    supplierZip: "302176303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84831488-783658883",
    partNumber: "84831488",
    description: "RAIL ASM-LUGG C",
    supplierName: "FRANKLIN ALUMINUM COMPANY INC",
    supplierAddress: "FRANKLIN,GA",
    supplierStreet: "",
    supplierCity: "FRANKLIN",
    supplierState: "GA",
    supplierZip: "302176303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84831489-783658883",
    partNumber: "84831489",
    description: "RAIL ASM-LUGG C",
    supplierName: "FRANKLIN ALUMINUM COMPANY INC",
    supplierAddress: "FRANKLIN,GA",
    supplierStreet: "",
    supplierCity: "FRANKLIN",
    supplierState: "GA",
    supplierZip: "302176303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84831490-783658883",
    partNumber: "84831490",
    description: "RAIL ASM-LUGG C",
    supplierName: "FRANKLIN ALUMINUM COMPANY INC",
    supplierAddress: "FRANKLIN,GA",
    supplierStreet: "",
    supplierCity: "FRANKLIN",
    supplierState: "GA",
    supplierZip: "302176303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84831493-783658883",
    partNumber: "84831493",
    description: "RAIL ASM-LUGG C",
    supplierName: "FRANKLIN ALUMINUM COMPANY INC",
    supplierAddress: "FRANKLIN,GA",
    supplierStreet: "",
    supplierCity: "FRANKLIN",
    supplierState: "GA",
    supplierZip: "302176303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84831494-783658883",
    partNumber: "84831494",
    description: "RAIL ASM-LUGG C",
    supplierName: "FRANKLIN ALUMINUM COMPANY INC",
    supplierAddress: "FRANKLIN,GA",
    supplierStreet: "",
    supplierCity: "FRANKLIN",
    supplierState: "GA",
    supplierZip: "302176303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84831495-783658883",
    partNumber: "84831495",
    description: "RAIL ASM-LUGG C",
    supplierName: "FRANKLIN ALUMINUM COMPANY INC",
    supplierAddress: "FRANKLIN,GA",
    supplierStreet: "",
    supplierCity: "FRANKLIN",
    supplierState: "GA",
    supplierZip: "302176303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84831496-783658883",
    partNumber: "84831496",
    description: "RAIL ASM-LUGG C",
    supplierName: "FRANKLIN ALUMINUM COMPANY INC",
    supplierAddress: "FRANKLIN,GA",
    supplierStreet: "",
    supplierCity: "FRANKLIN",
    supplierState: "GA",
    supplierZip: "302176303",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84832664-202888707",
    partNumber: "84832664",
    description: "BRACE-FRT BPR F",
    supplierName: "MARWOOD INTERNATIONAL INC",
    supplierAddress: "WOODSTOCK,ON",
    supplierStreet: "",
    supplierCity: "WOODSTOCK",
    supplierState: "ON",
    supplierZip: "N4S 7L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84832665-202888707",
    partNumber: "84832665",
    description: "BRACE-FRT BPR F",
    supplierName: "MARWOOD INTERNATIONAL INC",
    supplierAddress: "WOODSTOCK,ON",
    supplierStreet: "",
    supplierCity: "WOODSTOCK",
    supplierState: "ON",
    supplierZip: "N4S 7L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84832932-121584338",
    partNumber: "84832932",
    description: "PANEL-R/FLR RR",
    supplierName: "GM METAL FAB-MARION",
    supplierAddress: "MARION,IN",
    supplierStreet: "",
    supplierCity: "MARION",
    supplierState: "IN",
    supplierZip: "46952",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84832933-607039732",
    partNumber: "84832933",
    description: "HARNESS ASM-COM",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84833114-607039732",
    partNumber: "84833114",
    description: "HARNESS ASM-F/T",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84833168-062616218",
    partNumber: "84833168",
    description: "HARNESS ASM-CHA",
    supplierName: "AUTOELECTRIC OF AMERICA INC",
    supplierAddress: "EAGLE PASS,TX",
    supplierStreet: "",
    supplierCity: "EAGLE PASS",
    supplierState: "TX",
    supplierZip: "788525749",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84833881-800786642",
    partNumber: "84833881",
    description: "BAFFLE ASM-QTR",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84833882-800786642",
    partNumber: "84833882",
    description: "BAFFLE ASM-QTR",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84833883-800786642",
    partNumber: "84833883",
    description: "BAFFLE ASM-QTR",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84833884-800786642",
    partNumber: "84833884",
    description: "BAFFLE ASM-QTR",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84833885-800786642",
    partNumber: "84833885",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84833886-800786642",
    partNumber: "84833886",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84833887-800786642",
    partNumber: "84833887",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84833888-800786642",
    partNumber: "84833888",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84833889-800786642",
    partNumber: "84833889",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84833890-800786642",
    partNumber: "84833890",
    description: "BAFFLE ASM-BODY",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84833891-800786642",
    partNumber: "84833891",
    description: "BAFFLE ASM-F/CM",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84835804-969789283",
    partNumber: "84835804",
    description: "BRACKET ASM-CHA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84836409-812760162",
    partNumber: "84836409",
    description: "MODULE ASM-HTR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "TOLUCA,EM",
    supplierStreet: "",
    supplierCity: "TOLUCA",
    supplierState: "EM",
    supplierZip: "50200",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84836410-812760162",
    partNumber: "84836410",
    description: "MODULE ASM-HTR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "TOLUCA,EM",
    supplierStreet: "",
    supplierCity: "TOLUCA",
    supplierState: "EM",
    supplierZip: "50200",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84836519-253988547",
    partNumber: "84836519",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84836520-253988547",
    partNumber: "84836520",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84836521-253988547",
    partNumber: "84836521",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84836522-253988547",
    partNumber: "84836522",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84836523-253988547",
    partNumber: "84836523",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84836524-253988547",
    partNumber: "84836524",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84836525-253988547",
    partNumber: "84836525",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84836526-253988547",
    partNumber: "84836526",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84837045-812833912",
    partNumber: "84837045",
    description: "AIRBAG ASM-FRT",
    supplierName: "TRW OCCUPANT RESTRAINTS DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31137",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84837046-812833912",
    partNumber: "84837046",
    description: "AIRBAG ASM-FRT",
    supplierName: "TRW OCCUPANT RESTRAINTS DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31137",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84838104-814526588",
    partNumber: "84838104",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84838105-814526588",
    partNumber: "84838105",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84838106-814526588",
    partNumber: "84838106",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84838108-814526588",
    partNumber: "84838108",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84838110-814526588",
    partNumber: "84838110",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84838112-814526588",
    partNumber: "84838112",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84838116-814526588",
    partNumber: "84838116",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84838117-814526588",
    partNumber: "84838117",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84838119-814526588",
    partNumber: "84838119",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84838121-814526588",
    partNumber: "84838121",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84838411-144331493",
    partNumber: "84838411",
    description: "PANEL-QTR INR L",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84838412-144331493",
    partNumber: "84838412",
    description: "PANEL-QTR INR L",
    supplierName: "GM PONTIAC METAL CENTRE",
    supplierAddress: "PONTIAC,MI",
    supplierStreet: "",
    supplierCity: "PONTIAC",
    supplierState: "MI",
    supplierZip: "48340",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84838554-077472228",
    partNumber: "84838554",
    description: "FILLER ASM-BODY",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84838555-077472228",
    partNumber: "84838555",
    description: "FILLER ASM-BODY",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84838556-077472228",
    partNumber: "84838556",
    description: "FILLER ASM-BODY",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84838557-077472228",
    partNumber: "84838557",
    description: "FILLER ASM-BODY",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839040-800786642",
    partNumber: "84839040",
    description: "BAFFLE ASM-FRT",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839041-800786642",
    partNumber: "84839041",
    description: "BAFFLE ASM-FRT",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839159-545324639",
    partNumber: "84839159",
    description: "CORD ASM-ENG CO",
    supplierName: "HEBI THB INTERNATIONAL ELECTRIC CO",
    supplierAddress: "HEBI,41",
    supplierStreet: "",
    supplierCity: "HEBI",
    supplierState: "41",
    supplierZip: "458030",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84839447-142199376",
    partNumber: "84839447",
    description: "SHIELD-SPA WHL",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839451-812246809",
    partNumber: "84839451",
    description: "HOSE ASM-TRFER",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84839547-969789283",
    partNumber: "84839547",
    description: "DEFLECTOR ASM-F",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839548-969789283",
    partNumber: "84839548",
    description: "DEFLECTOR ASM-F",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839549-969789283",
    partNumber: "84839549",
    description: "DEFLECTOR ASM-F",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839550-969789283",
    partNumber: "84839550",
    description: "DEFLECTOR ASM-F",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839551-969789283",
    partNumber: "84839551",
    description: "DEFLECTOR ASM-F",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839552-969789283",
    partNumber: "84839552",
    description: "DEFLECTOR ASM-F",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839653-000202272",
    partNumber: "84839653",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839654-000202272",
    partNumber: "84839654",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839655-000202272",
    partNumber: "84839655",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839656-000202272",
    partNumber: "84839656",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839657-000202272",
    partNumber: "84839657",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839659-000202272",
    partNumber: "84839659",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839660-000202272",
    partNumber: "84839660",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839661-000202272",
    partNumber: "84839661",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839662-000202272",
    partNumber: "84839662",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84839663-000202272",
    partNumber: "84839663",
    description: "COVER-R/SEAT TR",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84842144-816657977",
    partNumber: "84842144",
    description: "HOSE ASM-FUEL F",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84842146-816657977",
    partNumber: "84842146",
    description: "HOSE ASM-FUEL F",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84842148-816657977",
    partNumber: "84842148",
    description: "HOSE ASM-FUEL F",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84842149-816657977",
    partNumber: "84842149",
    description: "HOSE ASM-FUEL F",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84842599-062616218",
    partNumber: "84842599",
    description: "HARNESS ASM-CHA",
    supplierName: "AUTOELECTRIC OF AMERICA INC",
    supplierAddress: "EAGLE PASS,TX",
    supplierStreet: "",
    supplierCity: "EAGLE PASS",
    supplierState: "TX",
    supplierZip: "788525749",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84842600-588159637",
    partNumber: "84842600",
    description: "SENSOR ASM-FRT",
    supplierName: "CONTINENTAL AUTOMOTIVE",
    supplierAddress: "GUANAJUATO,GJ",
    supplierStreet: "",
    supplierCity: "GUANAJUATO",
    supplierState: "GJ",
    supplierZip: "36270",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84842853-137355322",
    partNumber: "84842853",
    description: "CONTROL ASM-HTR",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84842854-137355322",
    partNumber: "84842854",
    description: "CONTROL ASM-HTR",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84842855-137355322",
    partNumber: "84842855",
    description: "CONTROL ASM-HTR",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84842856-137355322",
    partNumber: "84842856",
    description: "CONTROL ASM-HTR",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84842857-137355322",
    partNumber: "84842857",
    description: "CONTROL ASM-HTR",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84843035-812015766",
    partNumber: "84843035",
    description: "PIPE ASM-F/TNK",
    supplierName: "INDUSTRIAS MARTINREA DE MX SA DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25298",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84843036-812015766",
    partNumber: "84843036",
    description: "PIPE ASM-F/TNK",
    supplierName: "INDUSTRIAS MARTINREA DE MX SA DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25298",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84843462-053495482",
    partNumber: "84843462",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84843463-053495482",
    partNumber: "84843463",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84843531-000202266",
    partNumber: "84843531",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84843726-808263656",
    partNumber: "84843726",
    description: "INSULATOR ASM-D",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84843727-808263656",
    partNumber: "84843727",
    description: "INSULATOR ASM-D",
    supplierName: "AURIA OLD FORT LLC",
    supplierAddress: "OLD FORT,NC",
    supplierStreet: "",
    supplierCity: "OLD FORT",
    supplierState: "NC",
    supplierZip: "28762",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84843737-816657977",
    partNumber: "84843737",
    description: "PIPE ASM-RR BRK",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84843738-816657977",
    partNumber: "84843738",
    description: "PIPE ASM-RR BRK",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84843808-812885674",
    partNumber: "84843808",
    description: "CABLE ASM-ANT C",
    supplierName: "CEMM MEX SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67205",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84843809-812885674",
    partNumber: "84843809",
    description: "CABLE ASM-ANT C",
    supplierName: "CEMM MEX SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67205",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84843810-812885674",
    partNumber: "84843810",
    description: "CABLE ASM-ANT C",
    supplierName: "CEMM MEX SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67205",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84843811-812885674",
    partNumber: "84843811",
    description: "CABLE ASM-ANT C",
    supplierName: "CEMM MEX SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67205",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84843812-812885674",
    partNumber: "84843812",
    description: "CABLE ASM-ANT C",
    supplierName: "CEMM MEX SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67205",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84843813-812885674",
    partNumber: "84843813",
    description: "CABLE ASM-ANT C",
    supplierName: "CEMM MEX SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67205",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84843814-812885674",
    partNumber: "84843814",
    description: "CABLE ASM-ANT C",
    supplierName: "CEMM MEX SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67205",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84843816-812885674",
    partNumber: "84843816",
    description: "CABLE ASM-ANT C",
    supplierName: "CEMM MEX SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67205",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84843817-812885674",
    partNumber: "84843817",
    description: "CABLE ASM-ANT C",
    supplierName: "CEMM MEX SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67205",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84843818-812885674",
    partNumber: "84843818",
    description: "CABLE ASM-ANT C",
    supplierName: "CEMM MEX SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67205",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84843824-077472228",
    partNumber: "84843824",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84843825-077472228",
    partNumber: "84843825",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84843984-831831438",
    partNumber: "84843984",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84843985-831831438",
    partNumber: "84843985",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84843986-831831438",
    partNumber: "84843986",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84843987-831831438",
    partNumber: "84843987",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84843988-831831438",
    partNumber: "84843988",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84843989-831831438",
    partNumber: "84843989",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844216-554469633",
    partNumber: "84844216",
    description: "AMPLIFIER ASM-R",
    supplierName: "AMPHENOL AIRWAVE (HAIYAN)",
    supplierAddress: "JIAXING,33",
    supplierStreet: "",
    supplierCity: "JIAXING",
    supplierState: "33",
    supplierZip: "314305",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84844340-000246553",
    partNumber: "84844340",
    description: "CARD-NAVN DATA",
    supplierName: "TELENAV INC",
    supplierAddress: "FREMONT,CA",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "CA",
    supplierZip: "945397498",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844342-000246553",
    partNumber: "84844342",
    description: "CARD-NAVN DATA",
    supplierName: "TELENAV INC",
    supplierAddress: "FREMONT,CA",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "CA",
    supplierZip: "945397498",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844343-000246553",
    partNumber: "84844343",
    description: "CARD-NAVN DATA",
    supplierName: "TELENAV INC",
    supplierAddress: "FREMONT,CA",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "CA",
    supplierZip: "945397498",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844387-253988547",
    partNumber: "84844387",
    description: "MOLDING ASM-FRT",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84844388-253988547",
    partNumber: "84844388",
    description: "MOLDING ASM-FRT",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84844389-253988547",
    partNumber: "84844389",
    description: "MOLDING ASM-FRT",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84844390-253988547",
    partNumber: "84844390",
    description: "MOLDING ASM-FRT",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84844395-253988547",
    partNumber: "84844395",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84844396-253988547",
    partNumber: "84844396",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84844397-253988547",
    partNumber: "84844397",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84844398-253988547",
    partNumber: "84844398",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84844675-943793091",
    partNumber: "84844675",
    description: "PLATE ASM-F/FDR",
    supplierName: "TRIBAR MANUFACTURING LLC",
    supplierAddress: "HOWELL,MI",
    supplierStreet: "",
    supplierCity: "HOWELL",
    supplierState: "MI",
    supplierZip: "488557320",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844677-943793091",
    partNumber: "84844677",
    description: "PLATE ASM-F/FDR",
    supplierName: "TRIBAR MANUFACTURING LLC",
    supplierAddress: "HOWELL,MI",
    supplierStreet: "",
    supplierCity: "HOWELL",
    supplierState: "MI",
    supplierZip: "488557320",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844789-000259622",
    partNumber: "84844789",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844790-000259622",
    partNumber: "84844790",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844791-000259622",
    partNumber: "84844791",
    description: "SPRING ASM-RR A",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844810-000259622",
    partNumber: "84844810",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844811-000259622",
    partNumber: "84844811",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844812-000259622",
    partNumber: "84844812",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844813-000259622",
    partNumber: "84844813",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844814-000259622",
    partNumber: "84844814",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844815-000259622",
    partNumber: "84844815",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844818-000259622",
    partNumber: "84844818",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844819-000259622",
    partNumber: "84844819",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844820-000259622",
    partNumber: "84844820",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844822-000259622",
    partNumber: "84844822",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844823-000259622",
    partNumber: "84844823",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844824-000259622",
    partNumber: "84844824",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844827-000259622",
    partNumber: "84844827",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844828-000259622",
    partNumber: "84844828",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844829-000259622",
    partNumber: "84844829",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844830-000259622",
    partNumber: "84844830",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844832-000259622",
    partNumber: "84844832",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844833-000259622",
    partNumber: "84844833",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844835-000259622",
    partNumber: "84844835",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844836-000259622",
    partNumber: "84844836",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844840-000259622",
    partNumber: "84844840",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844841-000259622",
    partNumber: "84844841",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844845-000259622",
    partNumber: "84844845",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844847-000259622",
    partNumber: "84844847",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844849-000259622",
    partNumber: "84844849",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844850-000259622",
    partNumber: "84844850",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844851-000259622",
    partNumber: "84844851",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844856-000259622",
    partNumber: "84844856",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844857-000259622",
    partNumber: "84844857",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844861-000259622",
    partNumber: "84844861",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844862-000259622",
    partNumber: "84844862",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844863-000259622",
    partNumber: "84844863",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844878-000259622",
    partNumber: "84844878",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844879-000259622",
    partNumber: "84844879",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844880-000259622",
    partNumber: "84844880",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844881-000259622",
    partNumber: "84844881",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844882-000259622",
    partNumber: "84844882",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844884-000259622",
    partNumber: "84844884",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844885-000259622",
    partNumber: "84844885",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844886-000259622",
    partNumber: "84844886",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844887-000259622",
    partNumber: "84844887",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844888-000259622",
    partNumber: "84844888",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844890-000259622",
    partNumber: "84844890",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844891-000259622",
    partNumber: "84844891",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844892-000259622",
    partNumber: "84844892",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844893-000259622",
    partNumber: "84844893",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844896-000259622",
    partNumber: "84844896",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844898-000259622",
    partNumber: "84844898",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844899-000259622",
    partNumber: "84844899",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844901-000259622",
    partNumber: "84844901",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844902-000259622",
    partNumber: "84844902",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844904-000259622",
    partNumber: "84844904",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844905-000259622",
    partNumber: "84844905",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844907-000259622",
    partNumber: "84844907",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844908-000259622",
    partNumber: "84844908",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844910-000259622",
    partNumber: "84844910",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844911-000259622",
    partNumber: "84844911",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844915-000259622",
    partNumber: "84844915",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844916-000259622",
    partNumber: "84844916",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844923-038605341",
    partNumber: "84844923",
    description: "INSERT-W/S M/FU",
    supplierName: "NYX INC",
    supplierAddress: "LIVONIA,MI",
    supplierStreet: "",
    supplierCity: "LIVONIA",
    supplierState: "MI",
    supplierZip: "481501216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84844925-038605341",
    partNumber: "84844925",
    description: "COVER-W/S M/FUN",
    supplierName: "NYX INC",
    supplierAddress: "LIVONIA,MI",
    supplierStreet: "",
    supplierCity: "LIVONIA",
    supplierState: "MI",
    supplierZip: "481501216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845461-121584338",
    partNumber: "84845461",
    description: "PANEL-R/FLR FRT",
    supplierName: "GM METAL FAB-MARION",
    supplierAddress: "MARION,IN",
    supplierStreet: "",
    supplierCity: "MARION",
    supplierState: "IN",
    supplierZip: "46952",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845462-121584338",
    partNumber: "84845462",
    description: "PANEL-R/FLR FRT",
    supplierName: "GM METAL FAB-MARION",
    supplierAddress: "MARION,IN",
    supplierStreet: "",
    supplierCity: "MARION",
    supplierState: "IN",
    supplierZip: "46952",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845678-839434800",
    partNumber: "84845678",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845679-839434800",
    partNumber: "84845679",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845680-839434800",
    partNumber: "84845680",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845684-839434800",
    partNumber: "84845684",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845685-839434800",
    partNumber: "84845685",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845686-839434800",
    partNumber: "84845686",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845687-839434800",
    partNumber: "84845687",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845688-839434800",
    partNumber: "84845688",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845690-839434800",
    partNumber: "84845690",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845699-839434800",
    partNumber: "84845699",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845701-839434800",
    partNumber: "84845701",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845702-839434800",
    partNumber: "84845702",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845731-839434800",
    partNumber: "84845731",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845732-839434800",
    partNumber: "84845732",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845737-839434800",
    partNumber: "84845737",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845738-839434800",
    partNumber: "84845738",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845740-839434800",
    partNumber: "84845740",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845741-839434800",
    partNumber: "84845741",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845743-839434800",
    partNumber: "84845743",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845744-839434800",
    partNumber: "84845744",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845746-839434800",
    partNumber: "84845746",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845747-839434800",
    partNumber: "84845747",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845749-839434800",
    partNumber: "84845749",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845750-839434800",
    partNumber: "84845750",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845752-839434800",
    partNumber: "84845752",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845753-839434800",
    partNumber: "84845753",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845755-839434800",
    partNumber: "84845755",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845756-839434800",
    partNumber: "84845756",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84845759-812947059",
    partNumber: "84845759",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84845760-812947059",
    partNumber: "84845760",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84845762-812947059",
    partNumber: "84845762",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84845763-812947059",
    partNumber: "84845763",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84847719-086663101",
    partNumber: "84847719",
    description: "PANEL-RR W/H IN",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84847720-086663101",
    partNumber: "84847720",
    description: "PANEL-RR W/H IN",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84847721-086663101",
    partNumber: "84847721",
    description: "PANEL-RR W/H IN",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84847722-086663101",
    partNumber: "84847722",
    description: "PANEL-RR W/H IN",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84847747-811560853",
    partNumber: "84847747",
    description: "CALIPER ASM-FRT",
    supplierName: "FRENOS Y MECANISMOS SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84847748-811560853",
    partNumber: "84847748",
    description: "CALIPER ASM-FRT",
    supplierName: "FRENOS Y MECANISMOS SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84847758-812905909",
    partNumber: "84847758",
    description: "FILLER-BODY L/P",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84847759-812905909",
    partNumber: "84847759",
    description: "FILLER-BODY L/P",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84847764-812905909",
    partNumber: "84847764",
    description: "FILLER-BODY L/P",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84847765-812905909",
    partNumber: "84847765",
    description: "FILLER-BODY L/P",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84848136-688001481",
    partNumber: "84848136",
    description: "MOUNT ASM-ENG",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84848137-688001481",
    partNumber: "84848137",
    description: "MOUNT ASM-ENG",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84848138-688001481",
    partNumber: "84848138",
    description: "MOUNT ASM-ENG",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84848139-688001481",
    partNumber: "84848139",
    description: "MOUNT ASM-ENG",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84848401-000187815",
    partNumber: "84848401",
    description: "COMPRESSOR ASM-",
    supplierName: "DENSO INTERNATIONAL AMERICA",
    supplierAddress: "PARMA,MI",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "MI",
    supplierZip: "49269",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84848403-000187815",
    partNumber: "84848403",
    description: "COMPRESSOR ASM-",
    supplierName: "DENSO INTERNATIONAL AMERICA",
    supplierAddress: "PARMA,MI",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "MI",
    supplierZip: "49269",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84848436-812885674",
    partNumber: "84848436",
    description: "CABLE ASM-RR VI",
    supplierName: "CEMM MEX SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67205",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84848437-812885674",
    partNumber: "84848437",
    description: "CABLE ASM-RR VI",
    supplierName: "CEMM MEX SA DE CV",
    supplierAddress: "GUADALUPE,NL",
    supplierStreet: "",
    supplierCity: "GUADALUPE",
    supplierState: "NL",
    supplierZip: "67205",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84848730-812803176",
    partNumber: "84848730",
    description: "FAN ASM-ENG CLR",
    supplierName: "ROBERT BOSCH MEXICO SISTEMAS AUTOM",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76246",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84848843-814180139",
    partNumber: "84848843",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84848844-814180139",
    partNumber: "84848844",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84848846-814180139",
    partNumber: "84848846",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84848847-814180139",
    partNumber: "84848847",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84848848-814180139",
    partNumber: "84848848",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84848849-814180139",
    partNumber: "84848849",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84848850-814180139",
    partNumber: "84848850",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84848851-814180139",
    partNumber: "84848851",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84848880-932662133",
    partNumber: "84848880",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849416-129617036",
    partNumber: "84849416",
    description: "LAMP ASM-RR BOD",
    supplierName: "DECOFINMEX SA DE CV",
    supplierAddress: "SAN BENITO,TX",
    supplierStreet: "",
    supplierCity: "SAN BENITO",
    supplierState: "TX",
    supplierZip: "78586",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849417-129617036",
    partNumber: "84849417",
    description: "LAMP ASM-RR BOD",
    supplierName: "DECOFINMEX SA DE CV",
    supplierAddress: "SAN BENITO,TX",
    supplierStreet: "",
    supplierCity: "SAN BENITO",
    supplierState: "TX",
    supplierZip: "78586",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849466-032020088",
    partNumber: "84849466",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849468-032020088",
    partNumber: "84849468",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849469-032020088",
    partNumber: "84849469",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849470-032020088",
    partNumber: "84849470",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849471-032020088",
    partNumber: "84849471",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849472-032020088",
    partNumber: "84849472",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849473-032020088",
    partNumber: "84849473",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849474-032020088",
    partNumber: "84849474",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849476-032020088",
    partNumber: "84849476",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849478-032020088",
    partNumber: "84849478",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849479-032020088",
    partNumber: "84849479",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849480-032020088",
    partNumber: "84849480",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849481-032020088",
    partNumber: "84849481",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849482-032020088",
    partNumber: "84849482",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849483-032020088",
    partNumber: "84849483",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849484-032020088",
    partNumber: "84849484",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849486-032020088",
    partNumber: "84849486",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849488-032020088",
    partNumber: "84849488",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849489-032020088",
    partNumber: "84849489",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849490-032020088",
    partNumber: "84849490",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849491-032020088",
    partNumber: "84849491",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849492-032020088",
    partNumber: "84849492",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849493-032020088",
    partNumber: "84849493",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849494-032020088",
    partNumber: "84849494",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849496-032020088",
    partNumber: "84849496",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849498-032020088",
    partNumber: "84849498",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849499-032020088",
    partNumber: "84849499",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849500-032020088",
    partNumber: "84849500",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849501-032020088",
    partNumber: "84849501",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849502-032020088",
    partNumber: "84849502",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849503-032020088",
    partNumber: "84849503",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849504-032020088",
    partNumber: "84849504",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849506-032020088",
    partNumber: "84849506",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849508-032020088",
    partNumber: "84849508",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849509-032020088",
    partNumber: "84849509",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849510-032020088",
    partNumber: "84849510",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849511-032020088",
    partNumber: "84849511",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849512-032020088",
    partNumber: "84849512",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849513-032020088",
    partNumber: "84849513",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849514-032020088",
    partNumber: "84849514",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84849667-607039732",
    partNumber: "84849667",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84850192-078881928",
    partNumber: "84850192",
    description: "SILL ASM-U/B RR",
    supplierName: "CHALLENGE MFG COMPANY",
    supplierAddress: "IRVING,TX",
    supplierStreet: "",
    supplierCity: "IRVING",
    supplierState: "TX",
    supplierZip: "750632416",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84853173-000166991",
    partNumber: "84853173",
    description: "SENSOR ASM-FWD",
    supplierName: "CONTI TEMIC MICROELECTRONIC GMBH",
    supplierAddress: "DETROIT,MI",
    supplierStreet: "",
    supplierCity: "DETROIT",
    supplierState: "MI",
    supplierZip: "48234",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84853177-785629952",
    partNumber: "84853177",
    description: "LAMP ASM-RR BOD",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84853178-785629952",
    partNumber: "84853178",
    description: "LAMP ASM-RR BOD",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84853181-785629952",
    partNumber: "84853181",
    description: "LAMP ASM-RR BOD",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84853182-785629952",
    partNumber: "84853182",
    description: "LAMP ASM-RR BOD",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84853409-078488161",
    partNumber: "84853409",
    description: "SPEAKER ASM-RDO",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84853410-078488161",
    partNumber: "84853410",
    description: "SPEAKER ASM-RDO",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84853411-078488161",
    partNumber: "84853411",
    description: "SPEAKER ASM-RDO",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84854055-809870855",
    partNumber: "84854055",
    description: "BASE ASM-F/FLR",
    supplierName: "BROSE NEW BOSTON INC",
    supplierAddress: "NEW BOSTON,MI",
    supplierStreet: "",
    supplierCity: "NEW BOSTON",
    supplierState: "MI",
    supplierZip: "481649183",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84854198-813185634",
    partNumber: "84854198",
    description: "MODULE ASM-SUN",
    supplierName: "WRSI DE MEXICO S DE RL DE CV",
    supplierAddress: "IRAPUATO,GJ",
    supplierStreet: "",
    supplierCity: "IRAPUATO",
    supplierState: "GJ",
    supplierZip: "36810",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84854199-813185634",
    partNumber: "84854199",
    description: "MODULE ASM-SUN",
    supplierName: "WRSI DE MEXICO S DE RL DE CV",
    supplierAddress: "IRAPUATO,GJ",
    supplierStreet: "",
    supplierCity: "IRAPUATO",
    supplierState: "GJ",
    supplierZip: "36810",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84854200-813185634",
    partNumber: "84854200",
    description: "MODULE ASM-SUN",
    supplierName: "WRSI DE MEXICO S DE RL DE CV",
    supplierAddress: "IRAPUATO,GJ",
    supplierStreet: "",
    supplierCity: "IRAPUATO",
    supplierState: "GJ",
    supplierZip: "36810",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84854201-813185634",
    partNumber: "84854201",
    description: "MODULE ASM-SUN",
    supplierName: "WRSI DE MEXICO S DE RL DE CV",
    supplierAddress: "IRAPUATO,GJ",
    supplierStreet: "",
    supplierCity: "IRAPUATO",
    supplierState: "GJ",
    supplierZip: "36810",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84854202-813185634",
    partNumber: "84854202",
    description: "MODULE ASM-SUN",
    supplierName: "WRSI DE MEXICO S DE RL DE CV",
    supplierAddress: "IRAPUATO,GJ",
    supplierStreet: "",
    supplierCity: "IRAPUATO",
    supplierState: "GJ",
    supplierZip: "36810",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84854203-813185634",
    partNumber: "84854203",
    description: "MODULE ASM-SUN",
    supplierName: "WRSI DE MEXICO S DE RL DE CV",
    supplierAddress: "IRAPUATO,GJ",
    supplierStreet: "",
    supplierCity: "IRAPUATO",
    supplierState: "GJ",
    supplierZip: "36810",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84854204-813185634",
    partNumber: "84854204",
    description: "MODULE ASM-SUN",
    supplierName: "WRSI DE MEXICO S DE RL DE CV",
    supplierAddress: "IRAPUATO,GJ",
    supplierStreet: "",
    supplierCity: "IRAPUATO",
    supplierState: "GJ",
    supplierZip: "36810",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84854258-969789283",
    partNumber: "84854258",
    description: "GUTTER ASM-BK B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84854259-969789283",
    partNumber: "84854259",
    description: "GUTTER ASM-BK B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84854466-814814042",
    partNumber: "84854466",
    description: "HOSE ASM-CHRG A",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84854726-078849132",
    partNumber: "84854726",
    description: "BRACKET-ECM",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84855021-000202266",
    partNumber: "84855021",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856224-969789283",
    partNumber: "84856224",
    description: "GUIDE ASM-RR BP",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856225-969789283",
    partNumber: "84856225",
    description: "GUIDE ASM-RR BP",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856226-969789283",
    partNumber: "84856226",
    description: "GUIDE ASM-RR BP",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856227-969789283",
    partNumber: "84856227",
    description: "GUIDE ASM-RR BP",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856266-000246553",
    partNumber: "84856266",
    description: "CARD-NAVN DATA",
    supplierName: "TELENAV INC",
    supplierAddress: "FREMONT,CA",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "CA",
    supplierZip: "945397498",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856280-253988547",
    partNumber: "84856280",
    description: "MOLDING ASM-FRT",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84856281-253988547",
    partNumber: "84856281",
    description: "MOLDING ASM-FRT",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84856282-253988547",
    partNumber: "84856282",
    description: "MOLDING ASM-FRT",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84856283-253988547",
    partNumber: "84856283",
    description: "MOLDING ASM-FRT",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84856597-142199376",
    partNumber: "84856597",
    description: "SHIELD-EXH INTE",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856698-969789283",
    partNumber: "84856698",
    description: "GUIDE ASM-RR BP",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856699-969789283",
    partNumber: "84856699",
    description: "GUIDE ASM-RR BP",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856700-969789283",
    partNumber: "84856700",
    description: "GUIDE ASM-RR BP",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856701-969789283",
    partNumber: "84856701",
    description: "GUIDE ASM-RR BP",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856751-544908429",
    partNumber: "84856751",
    description: "SUPPORT-S/S",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84856752-544908429",
    partNumber: "84856752",
    description: "SUPPORT-S/S",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84856753-544908429",
    partNumber: "84856753",
    description: "SUPPORT-S/S",
    supplierName: "SHANGHAI DAIMAY AUTOMOTIVE INTERIOR",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "201318",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84856823-969789283",
    partNumber: "84856823",
    description: "LINER ASM-FRT W",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856824-969789283",
    partNumber: "84856824",
    description: "LINER ASM-FRT W",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856825-969789283",
    partNumber: "84856825",
    description: "LINER ASM-FRT W",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856826-969789283",
    partNumber: "84856826",
    description: "LINER ASM-FRT W",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856827-969789283",
    partNumber: "84856827",
    description: "LINER ASM-FRT W",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856828-969789283",
    partNumber: "84856828",
    description: "LINER ASM-FRT W",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856829-969789283",
    partNumber: "84856829",
    description: "LINER ASM-FRT W",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856830-969789283",
    partNumber: "84856830",
    description: "LINER ASM-FRT W",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856831-969789283",
    partNumber: "84856831",
    description: "LINER ASM-FRT W",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856832-969789283",
    partNumber: "84856832",
    description: "LINER ASM-FRT W",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856835-969789283",
    partNumber: "84856835",
    description: "LINER-RR W/H",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856836-969789283",
    partNumber: "84856836",
    description: "LINER-RR W/H",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856837-969789283",
    partNumber: "84856837",
    description: "LINER-RR W/H",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856838-969789283",
    partNumber: "84856838",
    description: "LINER-RR W/H",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856839-969789283",
    partNumber: "84856839",
    description: "LINER-RR W/H",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856840-969789283",
    partNumber: "84856840",
    description: "LINER-RR W/H",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856841-969789283",
    partNumber: "84856841",
    description: "LINER-RR W/H",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84856842-969789283",
    partNumber: "84856842",
    description: "LINER-RR W/H",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857588-000236455",
    partNumber: "84857588",
    description: "MODULE ASM-TRLR",
    supplierName: "NIDEC MOBILITY AMERICA CORP",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36270",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84857715-081099446",
    partNumber: "84857715",
    description: "CLUSTER ASM-INS",
    supplierName: "YAZAKI NORTH AMERICA INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780459526",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857716-081099446",
    partNumber: "84857716",
    description: "CLUSTER ASM-INS",
    supplierName: "YAZAKI NORTH AMERICA INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780459526",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857717-081099446",
    partNumber: "84857717",
    description: "CLUSTER ASM-INS",
    supplierName: "YAZAKI NORTH AMERICA INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780459526",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857719-081099446",
    partNumber: "84857719",
    description: "CLUSTER ASM-INS",
    supplierName: "YAZAKI NORTH AMERICA INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780459526",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857720-081099446",
    partNumber: "84857720",
    description: "CLUSTER ASM-INS",
    supplierName: "YAZAKI NORTH AMERICA INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780459526",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857951-080786860",
    partNumber: "84857951",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857952-080786860",
    partNumber: "84857952",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857954-080786860",
    partNumber: "84857954",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857955-080786860",
    partNumber: "84857955",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857957-080786860",
    partNumber: "84857957",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857958-080786860",
    partNumber: "84857958",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857959-080786860",
    partNumber: "84857959",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857961-080786860",
    partNumber: "84857961",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857963-080786860",
    partNumber: "84857963",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857965-080786860",
    partNumber: "84857965",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857966-080786860",
    partNumber: "84857966",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857968-080786860",
    partNumber: "84857968",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857969-080786860",
    partNumber: "84857969",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857970-080786860",
    partNumber: "84857970",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857972-080786860",
    partNumber: "84857972",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84857974-080786860",
    partNumber: "84857974",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84858688-814526588",
    partNumber: "84858688",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84858690-814526588",
    partNumber: "84858690",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84859262-000241752",
    partNumber: "84859262",
    description: "CONTROL ASM-HTR",
    supplierName: "PREH INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780416852",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859363-607039732",
    partNumber: "84859363",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859364-607039732",
    partNumber: "84859364",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859365-607039732",
    partNumber: "84859365",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859366-607039732",
    partNumber: "84859366",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859764-000782615",
    partNumber: "84859764",
    description: "TRIM ASM-R/CMPT",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859765-000782615",
    partNumber: "84859765",
    description: "TRIM ASM-R/CMPT",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859766-000782615",
    partNumber: "84859766",
    description: "TRIM ASM-R/CMPT",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859767-000782615",
    partNumber: "84859767",
    description: "TRIM ASM-R/CMPT",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859768-000782615",
    partNumber: "84859768",
    description: "TRIM ASM-R/CMPT",
    supplierName: "ABC TECHNOLOGIES INC",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859772-000782613",
    partNumber: "84859772",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859773-000782613",
    partNumber: "84859773",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859774-000782613",
    partNumber: "84859774",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859775-000782613",
    partNumber: "84859775",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859778-000782613",
    partNumber: "84859778",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859779-000782613",
    partNumber: "84859779",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859782-000782613",
    partNumber: "84859782",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859784-000782613",
    partNumber: "84859784",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84859785-000782613",
    partNumber: "84859785",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84860009-080786860",
    partNumber: "84860009",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84860010-080786860",
    partNumber: "84860010",
    description: "PANEL-HDLNG TR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84860315-812905909",
    partNumber: "84860315",
    description: "BRACKET ASM-ELE",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84860715-259986636",
    partNumber: "84860715",
    description: "EXTENSION ASM-F",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5S8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84861036-812416683",
    partNumber: "84861036",
    description: "DUCT-I/P OTR AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84861037-812416683",
    partNumber: "84861037",
    description: "DUCT-I/P OTR AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84861038-812416683",
    partNumber: "84861038",
    description: "DUCT-I/P OTR AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84861039-812416683",
    partNumber: "84861039",
    description: "DUCT-I/P OTR AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84861040-812416683",
    partNumber: "84861040",
    description: "DUCT-I/P OTR AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84861041-812416683",
    partNumber: "84861041",
    description: "DUCT-I/P OTR AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84861042-812416683",
    partNumber: "84861042",
    description: "DUCT-I/P CTR AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84861043-812416683",
    partNumber: "84861043",
    description: "DUCT-I/P CTR AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84861044-812416683",
    partNumber: "84861044",
    description: "DUCT-I/P CTR AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84861045-812416683",
    partNumber: "84861045",
    description: "DUCT-I/P CTR AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84861046-812416683",
    partNumber: "84861046",
    description: "DUCT-I/P CTR AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84861047-812416683",
    partNumber: "84861047",
    description: "DUCT-I/P CTR AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84861048-812416683",
    partNumber: "84861048",
    description: "DUCT-I/P OTR AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84861268-052507980",
    partNumber: "84861268",
    description: "CONTROL ASM-HTR",
    supplierName: "ALPINE ELECTRONICS OF AMERICA INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037454",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84861476-816473789",
    partNumber: "84861476",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE MEXICO SA DE CV",
    supplierAddress: "TORREON,CZ",
    supplierStreet: "",
    supplierCity: "TORREON",
    supplierState: "CZ",
    supplierZip: "27019",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84861477-816473789",
    partNumber: "84861477",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE MEXICO SA DE CV",
    supplierAddress: "TORREON,CZ",
    supplierStreet: "",
    supplierCity: "TORREON",
    supplierState: "CZ",
    supplierZip: "27019",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84861478-965355311",
    partNumber: "84861478",
    description: "MODULE ASM-AUX",
    supplierName: "HANON SYSTEMS NETHERLANDS COOP UA",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799360405",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84861514-000168013",
    partNumber: "84861514",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84861515-000168013",
    partNumber: "84861515",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84861955-689048813",
    partNumber: "84861955",
    description: "DAMPENER ASM-L/",
    supplierName: "DTR CORPORATION JINJU PLANT",
    supplierAddress: "JINJU GYEONGNAM,48",
    supplierStreet: "",
    supplierCity: "JINJU GYEONGNAM",
    supplierState: "48",
    supplierZip: "52615",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84862520-053495482",
    partNumber: "84862520",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84862521-053495482",
    partNumber: "84862521",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84862522-053495482",
    partNumber: "84862522",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84862523-053495482",
    partNumber: "84862523",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "NEW HAVEN,MO",
    supplierStreet: "",
    supplierCity: "NEW HAVEN",
    supplierState: "MO",
    supplierZip: "630681312",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84862536-812928414",
    partNumber: "84862536",
    description: "CASE ASM-TRFER",
    supplierName: "MAGNA POWERTRAIN DE MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84862537-812928414",
    partNumber: "84862537",
    description: "CASE ASM-TRFER",
    supplierName: "MAGNA POWERTRAIN DE MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84863239-529131017",
    partNumber: "84863239",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84863240-529131017",
    partNumber: "84863240",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84863241-529131017",
    partNumber: "84863241",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84863242-529131017",
    partNumber: "84863242",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84863243-529131017",
    partNumber: "84863243",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84863244-529131017",
    partNumber: "84863244",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84863245-529131017",
    partNumber: "84863245",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84863256-529131017",
    partNumber: "84863256",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84863257-529131017",
    partNumber: "84863257",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84863258-529131017",
    partNumber: "84863258",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84863259-529131017",
    partNumber: "84863259",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84863260-529131017",
    partNumber: "84863260",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84863261-529131017",
    partNumber: "84863261",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84863262-529131017",
    partNumber: "84863262",
    description: "APPLIQUE ASM-I/",
    supplierName: "SHANGHAI HUA WEI AUTOMOTIVE PARTS",
    supplierAddress: "SHANGHAI,31",
    supplierStreet: "",
    supplierCity: "SHANGHAI",
    supplierState: "31",
    supplierZip: "200949",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84863281-812888257",
    partNumber: "84863281",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84863282-812888257",
    partNumber: "84863282",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84863283-812888257",
    partNumber: "84863283",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84863284-812888257",
    partNumber: "84863284",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84863286-812888257",
    partNumber: "84863286",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84863287-812888257",
    partNumber: "84863287",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84863288-812888257",
    partNumber: "84863288",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84863289-812888257",
    partNumber: "84863289",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84863291-812888257",
    partNumber: "84863291",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84864984-253988547",
    partNumber: "84864984",
    description: "MOLDING ASM-UPR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84864985-253988547",
    partNumber: "84864985",
    description: "MOLDING ASM-UPR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84864986-253988547",
    partNumber: "84864986",
    description: "MOLDING ASM-UPR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84864987-253988547",
    partNumber: "84864987",
    description: "MOLDING ASM-UPR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84864988-253988547",
    partNumber: "84864988",
    description: "MOLDING ASM-UPR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84864989-253988547",
    partNumber: "84864989",
    description: "MOLDING ASM-UPR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84864990-253988547",
    partNumber: "84864990",
    description: "MOLDING ASM-UPR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84864991-253988547",
    partNumber: "84864991",
    description: "MOLDING ASM-UPR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84865051-000259622",
    partNumber: "84865051",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84865052-000259622",
    partNumber: "84865052",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84865056-000259622",
    partNumber: "84865056",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84865057-000259622",
    partNumber: "84865057",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84865354-137355322",
    partNumber: "84865354",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84865358-137355322",
    partNumber: "84865358",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84865460-137355322",
    partNumber: "84865460",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84865529-137355322",
    partNumber: "84865529",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84865905-812555407",
    partNumber: "84865905",
    description: "ROTOR-FRT BRK (",
    supplierName: "RASSINI FRENOS SA DE CV",
    supplierAddress: "SAN MARTIN TEXMELUCAN,PU",
    supplierStreet: "",
    supplierCity: "SAN MARTIN TEXMELUCAN",
    supplierState: "PU",
    supplierZip: "74129",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84866136-133272877",
    partNumber: "84866136",
    description: "MODULE ASM-PARK",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84866479-816154785",
    partNumber: "84866479",
    description: "PIPE ASM-TRANS",
    supplierName: "AUTOMOTIVE BEND DE MEXICO S DE RL D",
    supplierAddress: "VILLA DE REYES,SL",
    supplierStreet: "",
    supplierCity: "VILLA DE REYES",
    supplierState: "SL",
    supplierZip: "79526",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84867117-607039732",
    partNumber: "84867117",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867118-607039732",
    partNumber: "84867118",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867119-607039732",
    partNumber: "84867119",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867120-607039732",
    partNumber: "84867120",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867121-607039732",
    partNumber: "84867121",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867122-607039732",
    partNumber: "84867122",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867123-607039732",
    partNumber: "84867123",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867318-831831438",
    partNumber: "84867318",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867319-831831438",
    partNumber: "84867319",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867320-831831438",
    partNumber: "84867320",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867321-831831438",
    partNumber: "84867321",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867322-831831438",
    partNumber: "84867322",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867323-831831438",
    partNumber: "84867323",
    description: "COVER-STRG WHL",
    supplierName: "AUTOLIV ASP INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "785215249",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867899-242800241",
    partNumber: "84867899",
    description: "JACK PKG-.",
    supplierName: "VENTRA GROUP INC",
    supplierAddress: "TOTTENHAM,ON",
    supplierStreet: "",
    supplierCity: "TOTTENHAM",
    supplierState: "ON",
    supplierZip: "L0G 1W0",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84867904-827836214",
    partNumber: "84867904",
    description: "MODULE ASM",
    supplierName: "HELLA ELECTRONICS CORP",
    supplierAddress: "FLORA,IL",
    supplierStreet: "",
    supplierCity: "FLORA",
    supplierState: "IL",
    supplierZip: "628393440",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867930-000197855",
    partNumber: "84867930",
    description: "MICROPHONE ASM-",
    supplierName: "HARMAN EMBEDDED AUDIO LLC",
    supplierAddress: "INDIANAPOLIS,IN",
    supplierStreet: "",
    supplierCity: "INDIANAPOLIS",
    supplierState: "IN",
    supplierZip: "46268",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867931-000197855",
    partNumber: "84867931",
    description: "MICROPHONE ASM-",
    supplierName: "HARMAN EMBEDDED AUDIO LLC",
    supplierAddress: "INDIANAPOLIS,IN",
    supplierStreet: "",
    supplierCity: "INDIANAPOLIS",
    supplierState: "IN",
    supplierZip: "46268",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84867932-000197855",
    partNumber: "84867932",
    description: "MICROPHONE ASM-",
    supplierName: "HARMAN EMBEDDED AUDIO LLC",
    supplierAddress: "INDIANAPOLIS,IN",
    supplierStreet: "",
    supplierCity: "INDIANAPOLIS",
    supplierState: "IN",
    supplierZip: "46268",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84868125-969789283",
    partNumber: "84868125",
    description: "BRACKET ASM-RAD",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84868126-969789283",
    partNumber: "84868126",
    description: "BRACKET ASM-RAD",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84868669-078849132",
    partNumber: "84868669",
    description: "BRACKET ASM-BCM",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84869511-812888257",
    partNumber: "84869511",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84869512-812888257",
    partNumber: "84869512",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84869513-812888257",
    partNumber: "84869513",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84869514-812888257",
    partNumber: "84869514",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84869515-812888257",
    partNumber: "84869515",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84869516-812888257",
    partNumber: "84869516",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84870084-813179439",
    partNumber: "84870084",
    description: "TRANSMITTER PKG",
    supplierName: "HUF MEXICO S DE RL DE CV",
    supplierAddress: "CORONANGO,PU",
    supplierStreet: "",
    supplierCity: "CORONANGO",
    supplierState: "PU",
    supplierZip: "72680",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84870085-813179439",
    partNumber: "84870085",
    description: "TRANSMITTER PKG",
    supplierName: "HUF MEXICO S DE RL DE CV",
    supplierAddress: "CORONANGO,PU",
    supplierStreet: "",
    supplierCity: "CORONANGO",
    supplierState: "PU",
    supplierZip: "72680",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84871016-000289025",
    partNumber: "84871016",
    description: "INSULATOR ASM-I",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "SAN ANTONIO,TX",
    supplierStreet: "",
    supplierCity: "SAN ANTONIO",
    supplierState: "TX",
    supplierZip: "782192313",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84871017-000289025",
    partNumber: "84871017",
    description: "INSULATOR ASM-I",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "SAN ANTONIO,TX",
    supplierStreet: "",
    supplierCity: "SAN ANTONIO",
    supplierState: "TX",
    supplierZip: "782192313",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84871018-000289025",
    partNumber: "84871018",
    description: "INSULATOR ASM-I",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "SAN ANTONIO,TX",
    supplierStreet: "",
    supplierCity: "SAN ANTONIO",
    supplierState: "TX",
    supplierZip: "782192313",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84871132-080779273",
    partNumber: "84871132",
    description: "HARNESS ASM-P/S",
    supplierName: "TIANHAI ELECTRIC NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065240",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84871178-080779273",
    partNumber: "84871178",
    description: "STRAP-BAT POS &",
    supplierName: "TIANHAI ELECTRIC NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065240",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84871901-813185634",
    partNumber: "84871901",
    description: "MODULE ASM-SUN",
    supplierName: "WRSI DE MEXICO S DE RL DE CV",
    supplierAddress: "IRAPUATO,GJ",
    supplierStreet: "",
    supplierCity: "IRAPUATO",
    supplierState: "GJ",
    supplierZip: "36810",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84871906-813185634",
    partNumber: "84871906",
    description: "MODULE ASM-SUN",
    supplierName: "WRSI DE MEXICO S DE RL DE CV",
    supplierAddress: "IRAPUATO,GJ",
    supplierStreet: "",
    supplierCity: "IRAPUATO",
    supplierState: "GJ",
    supplierZip: "36810",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84871990-021093638",
    partNumber: "84871990",
    description: "SHIELD-FRT BRK",
    supplierName: "BOPP-BUSCH MANUFACTURING CO INC",
    supplierAddress: "AU GRES,MI",
    supplierStreet: "",
    supplierCity: "AU GRES",
    supplierState: "MI",
    supplierZip: "487039748",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84873085-059187070",
    partNumber: "84873085",
    description: "BRACKET-FRT BPR",
    supplierName: "COSMA INTERNATIONAL OF AMERICA INC",
    supplierAddress: "MONTEZUMA,IA",
    supplierStreet: "",
    supplierCity: "MONTEZUMA",
    supplierState: "IA",
    supplierZip: "501711018",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84873086-059187070",
    partNumber: "84873086",
    description: "BRACKET-FRT BPR",
    supplierName: "COSMA INTERNATIONAL OF AMERICA INC",
    supplierAddress: "MONTEZUMA,IA",
    supplierStreet: "",
    supplierCity: "MONTEZUMA",
    supplierState: "IA",
    supplierZip: "501711018",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84873684-812520286",
    partNumber: "84873684",
    description: "TANK ASM-FUEL",
    supplierName: "PLASTIC OMNIUM AUTO INERGY",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84873685-812520286",
    partNumber: "84873685",
    description: "TANK ASM-FUEL",
    supplierName: "PLASTIC OMNIUM AUTO INERGY",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84873834-188277016",
    partNumber: "84873834",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84873835-188277016",
    partNumber: "84873835",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84873860-020837233",
    partNumber: "84873860",
    description: "BRACKET ASM-FRT",
    supplierName: "DAJACO INDUSTRIES INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512478",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874478-000166991",
    partNumber: "84874478",
    description: "SENSOR ASM",
    supplierName: "CONTI TEMIC MICROELECTRONIC GMBH",
    supplierAddress: "DETROIT,MI",
    supplierStreet: "",
    supplierCity: "DETROIT",
    supplierState: "MI",
    supplierZip: "48234",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874624-079948011",
    partNumber: "84874624",
    description: "LAMP ASM-CARGO",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874625-079948011",
    partNumber: "84874625",
    description: "LAMP ASM-CARGO",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874626-079948011",
    partNumber: "84874626",
    description: "LAMP ASM-CARGO",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874627-079948011",
    partNumber: "84874627",
    description: "LAMP ASM-CARGO",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874628-079948011",
    partNumber: "84874628",
    description: "LAMP ASM-R/SEAT",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874629-079948011",
    partNumber: "84874629",
    description: "LAMP ASM-R/SEAT",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874630-079948011",
    partNumber: "84874630",
    description: "LAMP ASM-R/SEAT",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874631-079948011",
    partNumber: "84874631",
    description: "LAMP ASM-R/SEAT",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874697-814526588",
    partNumber: "84874697",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84874698-814526588",
    partNumber: "84874698",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84874699-814526588",
    partNumber: "84874699",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84874700-814526588",
    partNumber: "84874700",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84874704-814526588",
    partNumber: "84874704",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84874705-814526588",
    partNumber: "84874705",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84874706-814526588",
    partNumber: "84874706",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84874757-607039732",
    partNumber: "84874757",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874758-607039732",
    partNumber: "84874758",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874759-607039732",
    partNumber: "84874759",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874762-607039732",
    partNumber: "84874762",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874763-607039732",
    partNumber: "84874763",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874764-607039732",
    partNumber: "84874764",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874767-607039732",
    partNumber: "84874767",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874768-607039732",
    partNumber: "84874768",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874769-607039732",
    partNumber: "84874769",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874770-607039732",
    partNumber: "84874770",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874771-607039732",
    partNumber: "84874771",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874773-607039732",
    partNumber: "84874773",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874774-607039732",
    partNumber: "84874774",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874776-607039732",
    partNumber: "84874776",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874778-607039732",
    partNumber: "84874778",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874780-607039732",
    partNumber: "84874780",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874781-607039732",
    partNumber: "84874781",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874782-607039732",
    partNumber: "84874782",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874783-607039732",
    partNumber: "84874783",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874784-607039732",
    partNumber: "84874784",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874785-607039732",
    partNumber: "84874785",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874786-607039732",
    partNumber: "84874786",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874787-607039732",
    partNumber: "84874787",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874788-607039732",
    partNumber: "84874788",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874789-607039732",
    partNumber: "84874789",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874791-607039732",
    partNumber: "84874791",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874792-607039732",
    partNumber: "84874792",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874793-607039732",
    partNumber: "84874793",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874794-607039732",
    partNumber: "84874794",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874795-607039732",
    partNumber: "84874795",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874797-607039732",
    partNumber: "84874797",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874798-607039732",
    partNumber: "84874798",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874799-607039732",
    partNumber: "84874799",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874800-607039732",
    partNumber: "84874800",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874801-607039732",
    partNumber: "84874801",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874802-607039732",
    partNumber: "84874802",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874804-607039732",
    partNumber: "84874804",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874805-607039732",
    partNumber: "84874805",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874807-607039732",
    partNumber: "84874807",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874808-607039732",
    partNumber: "84874808",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874809-607039732",
    partNumber: "84874809",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874810-607039732",
    partNumber: "84874810",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874812-607039732",
    partNumber: "84874812",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874813-607039732",
    partNumber: "84874813",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874814-607039732",
    partNumber: "84874814",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874815-607039732",
    partNumber: "84874815",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874816-607039732",
    partNumber: "84874816",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874817-607039732",
    partNumber: "84874817",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874818-607039732",
    partNumber: "84874818",
    description: "HARNESS ASM-BOD",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874912-000202272",
    partNumber: "84874912",
    description: "COVER ASM-R/SEA",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874913-000202272",
    partNumber: "84874913",
    description: "COVER ASM-R/SEA",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874914-000202272",
    partNumber: "84874914",
    description: "COVER ASM-R/SEA",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874915-000202272",
    partNumber: "84874915",
    description: "COVER ASM-R/SEA",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84874916-000202272",
    partNumber: "84874916",
    description: "COVER ASM-R/SEA",
    supplierName: "LEAR CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84875039-627272024",
    partNumber: "84875039",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84875041-627272024",
    partNumber: "84875041",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84875048-627272024",
    partNumber: "84875048",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84875050-627272024",
    partNumber: "84875050",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84875057-627272024",
    partNumber: "84875057",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84875059-627272024",
    partNumber: "84875059",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84875066-627272024",
    partNumber: "84875066",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84875068-627272024",
    partNumber: "84875068",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84875087-627272024",
    partNumber: "84875087",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84875088-627272024",
    partNumber: "84875088",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84875096-627272024",
    partNumber: "84875096",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84875097-627272024",
    partNumber: "84875097",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84875104-627272024",
    partNumber: "84875104",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84875114-627272024",
    partNumber: "84875114",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876024-421251063",
    partNumber: "84876024",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876025-421251063",
    partNumber: "84876025",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876026-421251063",
    partNumber: "84876026",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876027-421251063",
    partNumber: "84876027",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876028-421251063",
    partNumber: "84876028",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876029-421251063",
    partNumber: "84876029",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876031-421251063",
    partNumber: "84876031",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876032-421251063",
    partNumber: "84876032",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876033-421251063",
    partNumber: "84876033",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876034-421251063",
    partNumber: "84876034",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876035-421251063",
    partNumber: "84876035",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876036-421251063",
    partNumber: "84876036",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876037-421251063",
    partNumber: "84876037",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876038-421251063",
    partNumber: "84876038",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876039-421251063",
    partNumber: "84876039",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876040-421251063",
    partNumber: "84876040",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876041-421251063",
    partNumber: "84876041",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876042-421251063",
    partNumber: "84876042",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876043-421251063",
    partNumber: "84876043",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876044-421251063",
    partNumber: "84876044",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876045-421251063",
    partNumber: "84876045",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876046-421251063",
    partNumber: "84876046",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876047-421251063",
    partNumber: "84876047",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876048-421251063",
    partNumber: "84876048",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876049-421251063",
    partNumber: "84876049",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876050-421251063",
    partNumber: "84876050",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876051-421251063",
    partNumber: "84876051",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876052-421251063",
    partNumber: "84876052",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876053-421251063",
    partNumber: "84876053",
    description: "ANTENNA ASM-HIG",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84876602-609623566",
    partNumber: "84876602",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876603-609623566",
    partNumber: "84876603",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876604-609623566",
    partNumber: "84876604",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876605-609623566",
    partNumber: "84876605",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876606-609623566",
    partNumber: "84876606",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876607-609623566",
    partNumber: "84876607",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876608-609623566",
    partNumber: "84876608",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876609-609623566",
    partNumber: "84876609",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876610-609623566",
    partNumber: "84876610",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876611-609623566",
    partNumber: "84876611",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876612-609623566",
    partNumber: "84876612",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876613-609623566",
    partNumber: "84876613",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876614-609623566",
    partNumber: "84876614",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876615-609623566",
    partNumber: "84876615",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876616-609623566",
    partNumber: "84876616",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876617-609623566",
    partNumber: "84876617",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876618-609623566",
    partNumber: "84876618",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876619-609623566",
    partNumber: "84876619",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876620-609623566",
    partNumber: "84876620",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876621-609623566",
    partNumber: "84876621",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876622-609623566",
    partNumber: "84876622",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876623-609623566",
    partNumber: "84876623",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876624-609623566",
    partNumber: "84876624",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876625-609623566",
    partNumber: "84876625",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876626-607039732",
    partNumber: "84876626",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876627-607039732",
    partNumber: "84876627",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876628-607039732",
    partNumber: "84876628",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876629-607039732",
    partNumber: "84876629",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876630-607039732",
    partNumber: "84876630",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84876631-607039732",
    partNumber: "84876631",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84877515-812888257",
    partNumber: "84877515",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84877516-812888257",
    partNumber: "84877516",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84877518-812888257",
    partNumber: "84877518",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84877519-812888257",
    partNumber: "84877519",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84877521-812888257",
    partNumber: "84877521",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84877522-812888257",
    partNumber: "84877522",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84877523-812888257",
    partNumber: "84877523",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84877525-812888257",
    partNumber: "84877525",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84877526-812888257",
    partNumber: "84877526",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84877613-078488161",
    partNumber: "84877613",
    description: "SPEAKER ASM-RDO",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84877614-078488161",
    partNumber: "84877614",
    description: "SPEAKER ASM-RDO",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84877795-253988547",
    partNumber: "84877795",
    description: "APPLIQUE ASM-FR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84877796-253988547",
    partNumber: "84877796",
    description: "APPLIQUE ASM-FR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84877797-253988547",
    partNumber: "84877797",
    description: "APPLIQUE ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84877798-253988547",
    partNumber: "84877798",
    description: "APPLIQUE ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84877799-253988547",
    partNumber: "84877799",
    description: "APPLIQUE ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84877800-253988547",
    partNumber: "84877800",
    description: "APPLIQUE ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84877801-253988547",
    partNumber: "84877801",
    description: "APPLIQUE ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84877802-253988547",
    partNumber: "84877802",
    description: "APPLIQUE ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84878497-627272024",
    partNumber: "84878497",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84878498-627272024",
    partNumber: "84878498",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84878499-627272024",
    partNumber: "84878499",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84878500-627272024",
    partNumber: "84878500",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84878501-627272024",
    partNumber: "84878501",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84878502-627272024",
    partNumber: "84878502",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84878503-627272024",
    partNumber: "84878503",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84878504-627272024",
    partNumber: "84878504",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84878505-627272024",
    partNumber: "84878505",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84878506-627272024",
    partNumber: "84878506",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84878813-122505659",
    partNumber: "84878813",
    description: "CYLINDER ASM-BR",
    supplierName: "ZF ACTIVE SAFETY US INC",
    supplierAddress: "FOWLERVILLE,MI",
    supplierStreet: "",
    supplierCity: "FOWLERVILLE",
    supplierState: "MI",
    supplierZip: "488367908",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84878815-122505659",
    partNumber: "84878815",
    description: "CYLINDER ASM-BR",
    supplierName: "ZF ACTIVE SAFETY US INC",
    supplierAddress: "FOWLERVILLE,MI",
    supplierStreet: "",
    supplierCity: "FOWLERVILLE",
    supplierState: "MI",
    supplierZip: "488367908",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84879441-081348326",
    partNumber: "84879441",
    description: "COLUMN ASM-STRG",
    supplierName: "AUTOMOTIVE STEERING COLUMN LLC",
    supplierAddress: "FLORENCE,KY",
    supplierStreet: "",
    supplierCity: "FLORENCE",
    supplierState: "KY",
    supplierZip: "410420000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84879451-812888257",
    partNumber: "84879451",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84879452-812888257",
    partNumber: "84879452",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84879454-812888257",
    partNumber: "84879454",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84879455-812888257",
    partNumber: "84879455",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84879456-812888257",
    partNumber: "84879456",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84879458-812888257",
    partNumber: "84879458",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84879459-812888257",
    partNumber: "84879459",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84879461-812888257",
    partNumber: "84879461",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84879462-812888257",
    partNumber: "84879462",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84879463-812888257",
    partNumber: "84879463",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84879859-080786860",
    partNumber: "84879859",
    description: "CARRIER ASM-I/P",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880464-812905909",
    partNumber: "84880464",
    description: "BRACKET ASM-F/P",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84880570-080786860",
    partNumber: "84880570",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880571-080786860",
    partNumber: "84880571",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880572-080786860",
    partNumber: "84880572",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880573-080786860",
    partNumber: "84880573",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880575-080786860",
    partNumber: "84880575",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880576-080786860",
    partNumber: "84880576",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880577-080786860",
    partNumber: "84880577",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880578-080786860",
    partNumber: "84880578",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880581-080786860",
    partNumber: "84880581",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880582-080786860",
    partNumber: "84880582",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880583-080786860",
    partNumber: "84880583",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880584-080786860",
    partNumber: "84880584",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880585-080786860",
    partNumber: "84880585",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880586-080786860",
    partNumber: "84880586",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880589-080786860",
    partNumber: "84880589",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880590-080786860",
    partNumber: "84880590",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880593-080786860",
    partNumber: "84880593",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880595-080786860",
    partNumber: "84880595",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880596-080786860",
    partNumber: "84880596",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880598-080786860",
    partNumber: "84880598",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880599-080786860",
    partNumber: "84880599",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880600-080786860",
    partNumber: "84880600",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880601-080786860",
    partNumber: "84880601",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880607-080786860",
    partNumber: "84880607",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880608-080786860",
    partNumber: "84880608",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880610-080786860",
    partNumber: "84880610",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880611-080786860",
    partNumber: "84880611",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880614-080786860",
    partNumber: "84880614",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880616-080786860",
    partNumber: "84880616",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880617-080786860",
    partNumber: "84880617",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880618-080786860",
    partNumber: "84880618",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880619-080786860",
    partNumber: "84880619",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880620-080786860",
    partNumber: "84880620",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880621-080786860",
    partNumber: "84880621",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880625-080786860",
    partNumber: "84880625",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880626-080786860",
    partNumber: "84880626",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880627-080786860",
    partNumber: "84880627",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880628-080786860",
    partNumber: "84880628",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880629-080786860",
    partNumber: "84880629",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880632-080786860",
    partNumber: "84880632",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880633-080786860",
    partNumber: "84880633",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880634-080786860",
    partNumber: "84880634",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880635-080786860",
    partNumber: "84880635",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880636-080786860",
    partNumber: "84880636",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880637-080786860",
    partNumber: "84880637",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880638-080786860",
    partNumber: "84880638",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880639-080786860",
    partNumber: "84880639",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880644-080924263",
    partNumber: "84880644",
    description: "INSULATOR-DA PN",
    supplierName: "MIDWEST ACOUST A FIBER INC",
    supplierAddress: "SAN ANTONIO,TX",
    supplierStreet: "",
    supplierCity: "SAN ANTONIO",
    supplierState: "TX",
    supplierZip: "782192302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880645-789519808",
    partNumber: "84880645",
    description: "INSULATOR-DA PN",
    supplierName: "CTA ACOUSTICS INC",
    supplierAddress: "CORBIN,KY",
    supplierStreet: "",
    supplierCity: "CORBIN",
    supplierState: "KY",
    supplierZip: "407018061",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880881-056894041",
    partNumber: "84880881",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880882-056894041",
    partNumber: "84880882",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880883-056894041",
    partNumber: "84880883",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880884-056894041",
    partNumber: "84880884",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880885-056894041",
    partNumber: "84880885",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880886-056894041",
    partNumber: "84880886",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880887-056894041",
    partNumber: "84880887",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880888-056894041",
    partNumber: "84880888",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880889-056894041",
    partNumber: "84880889",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84880890-056894041",
    partNumber: "84880890",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882628-080786860",
    partNumber: "84882628",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882629-080786860",
    partNumber: "84882629",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882630-080786860",
    partNumber: "84882630",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882631-080786860",
    partNumber: "84882631",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882634-080786860",
    partNumber: "84882634",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882635-080786860",
    partNumber: "84882635",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882636-080786860",
    partNumber: "84882636",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882637-080786860",
    partNumber: "84882637",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882638-080786860",
    partNumber: "84882638",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882639-080786860",
    partNumber: "84882639",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882640-080786860",
    partNumber: "84882640",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882641-080786860",
    partNumber: "84882641",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882642-080786860",
    partNumber: "84882642",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882643-080786860",
    partNumber: "84882643",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882644-080786860",
    partNumber: "84882644",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882647-080786860",
    partNumber: "84882647",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882648-080786860",
    partNumber: "84882648",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882649-080786860",
    partNumber: "84882649",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882650-080786860",
    partNumber: "84882650",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882651-080786860",
    partNumber: "84882651",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882652-080786860",
    partNumber: "84882652",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882653-080786860",
    partNumber: "84882653",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882654-080786860",
    partNumber: "84882654",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882655-080786860",
    partNumber: "84882655",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882656-080786860",
    partNumber: "84882656",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882657-080786860",
    partNumber: "84882657",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882660-080786860",
    partNumber: "84882660",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882661-080786860",
    partNumber: "84882661",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882662-080786860",
    partNumber: "84882662",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882663-080786860",
    partNumber: "84882663",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882664-080786860",
    partNumber: "84882664",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882665-080786860",
    partNumber: "84882665",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882666-080786860",
    partNumber: "84882666",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882667-080786860",
    partNumber: "84882667",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882668-080786860",
    partNumber: "84882668",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882669-080786860",
    partNumber: "84882669",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882670-080786860",
    partNumber: "84882670",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882673-080786860",
    partNumber: "84882673",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882674-080786860",
    partNumber: "84882674",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882675-080786860",
    partNumber: "84882675",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882676-080786860",
    partNumber: "84882676",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882677-080786860",
    partNumber: "84882677",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882678-080786860",
    partNumber: "84882678",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882679-080786860",
    partNumber: "84882679",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882690-839434800",
    partNumber: "84882690",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882691-839434800",
    partNumber: "84882691",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882692-839434800",
    partNumber: "84882692",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882693-839434800",
    partNumber: "84882693",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882694-839434800",
    partNumber: "84882694",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882695-839434800",
    partNumber: "84882695",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882696-839434800",
    partNumber: "84882696",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882697-839434800",
    partNumber: "84882697",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882698-839434800",
    partNumber: "84882698",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84882874-816154785",
    partNumber: "84882874",
    description: "PIPE ASM-TRANS",
    supplierName: "AUTOMOTIVE BEND DE MEXICO S DE RL D",
    supplierAddress: "VILLA DE REYES,SL",
    supplierStreet: "",
    supplierCity: "VILLA DE REYES",
    supplierState: "SL",
    supplierZip: "79526",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84883112-544925969",
    partNumber: "84883112",
    description: "CABLE ASM-HOOD",
    supplierName: "L&C CHANGSHA CABLE INDUSTRIES LTD",
    supplierAddress: "CHANGSHA,43",
    supplierStreet: "",
    supplierCity: "CHANGSHA",
    supplierState: "43",
    supplierZip: "410119",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84883930-812888257",
    partNumber: "84883930",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84883986-000190694",
    partNumber: "84883986",
    description: "TOOL KIT-.",
    supplierName: "VENTRAMEX SA DE C V",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84884145-932662133",
    partNumber: "84884145",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84884146-932662133",
    partNumber: "84884146",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84884147-932662133",
    partNumber: "84884147",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84884148-932662133",
    partNumber: "84884148",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84884149-932662133",
    partNumber: "84884149",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84884150-932662133",
    partNumber: "84884150",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84884151-932662133",
    partNumber: "84884151",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84884155-932662133",
    partNumber: "84884155",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84884796-689605484",
    partNumber: "84884796",
    description: "REFLECTOR ASM-D",
    supplierName: "SUNGWOO CORP",
    supplierAddress: "GIMPO,41",
    supplierStreet: "",
    supplierCity: "GIMPO",
    supplierState: "41",
    supplierZip: "10038",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84885555-080779273",
    partNumber: "84885555",
    description: "CABLE ASM-STRTR",
    supplierName: "TIANHAI ELECTRIC NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065240",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84885582-813201621",
    partNumber: "84885582",
    description: "PUSHBUTTON ASM-",
    supplierName: "GHSP MEXICO SA DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84885583-813201621",
    partNumber: "84885583",
    description: "PUSHBUTTON ASM-",
    supplierName: "GHSP MEXICO SA DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84885880-830049271",
    partNumber: "84885880",
    description: "CONTROL ASM-A/T",
    supplierName: "SL TENNESSEE LLC",
    supplierAddress: "CLINTON,TN",
    supplierStreet: "",
    supplierCity: "CLINTON",
    supplierState: "TN",
    supplierZip: "377166956",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84885992-812888257",
    partNumber: "84885992",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84885994-812888257",
    partNumber: "84885994",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84885995-812888257",
    partNumber: "84885995",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84886006-812888257",
    partNumber: "84886006",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84886007-812888257",
    partNumber: "84886007",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84886008-812888257",
    partNumber: "84886008",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84886319-544652613",
    partNumber: "84886319",
    description: "SWITCH ASM-INFO",
    supplierName: "DALIAN ALPS ELECTRONICS CO LTD",
    supplierAddress: "DALIAN,21",
    supplierStreet: "",
    supplierCity: "DALIAN",
    supplierState: "21",
    supplierZip: "116100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84886560-812520286",
    partNumber: "84886560",
    description: "TANK ASM-FUEL",
    supplierName: "PLASTIC OMNIUM AUTO INERGY",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84886563-812520286",
    partNumber: "84886563",
    description: "TANK ASM-FUEL",
    supplierName: "PLASTIC OMNIUM AUTO INERGY",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84886782-588159637",
    partNumber: "84886782",
    description: "HARNESS ASM-FRT",
    supplierName: "CONTINENTAL AUTOMOTIVE",
    supplierAddress: "GUANAJUATO,GJ",
    supplierStreet: "",
    supplierCity: "GUANAJUATO",
    supplierState: "GJ",
    supplierZip: "36270",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84886905-259986636",
    partNumber: "84886905",
    description: "SHUTTER ASM-FRT",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "BRAMPTON,ON",
    supplierStreet: "",
    supplierCity: "BRAMPTON",
    supplierState: "ON",
    supplierZip: "L6T 5S8",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84887333-050476923",
    partNumber: "84887333",
    description: "AMPLIFIER ASM-R",
    supplierName: "BOSE CORP",
    supplierAddress: "SAN DIEGO,CA",
    supplierStreet: "",
    supplierCity: "SAN DIEGO",
    supplierState: "CA",
    supplierZip: "921546216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84887334-050476923",
    partNumber: "84887334",
    description: "AMPLIFIER ASM-R",
    supplierName: "BOSE CORP",
    supplierAddress: "SAN DIEGO,CA",
    supplierStreet: "",
    supplierCity: "SAN DIEGO",
    supplierState: "CA",
    supplierZip: "921546216",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84887772-080786860",
    partNumber: "84887772",
    description: "TRAY-F/FLR CNSL",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84887773-080786860",
    partNumber: "84887773",
    description: "SPRING-F/FLR CN",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84887774-080786860",
    partNumber: "84887774",
    description: "SPRING-F/FLR CN",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84887776-080786860",
    partNumber: "84887776",
    description: "HOLDER ASM-F/FL",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84887777-080786860",
    partNumber: "84887777",
    description: "HOLDER ASM-F/FL",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84887778-080786860",
    partNumber: "84887778",
    description: "HOLDER ASM-F/FL",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84887779-080786860",
    partNumber: "84887779",
    description: "HOLDER ASM-F/FL",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84887806-078488161",
    partNumber: "84887806",
    description: "SPEAKER ASM-RDO",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84887807-078488161",
    partNumber: "84887807",
    description: "SPEAKER ASM-RDO",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888062-800574258",
    partNumber: "84888062",
    description: "COMPRESSOR PKG-",
    supplierName: "ACTIVE TOOLS USA INC",
    supplierAddress: "TROY,MI",
    supplierStreet: "",
    supplierCity: "TROY",
    supplierState: "MI",
    supplierZip: "480834231",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888384-080786860",
    partNumber: "84888384",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888385-080786860",
    partNumber: "84888385",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888386-080786860",
    partNumber: "84888386",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888388-080786860",
    partNumber: "84888388",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888389-080786860",
    partNumber: "84888389",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888390-080786860",
    partNumber: "84888390",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888391-080786860",
    partNumber: "84888391",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888392-080786860",
    partNumber: "84888392",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888393-080786860",
    partNumber: "84888393",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888394-080786860",
    partNumber: "84888394",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888395-080786860",
    partNumber: "84888395",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888397-080786860",
    partNumber: "84888397",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888398-080786860",
    partNumber: "84888398",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888399-080786860",
    partNumber: "84888399",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888400-080786860",
    partNumber: "84888400",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888401-080786860",
    partNumber: "84888401",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84888743-000195578",
    partNumber: "84888743",
    description: "MODULE PKG-ASST",
    supplierName: "CONTINENTAL AUTOMOTIVE NOGALES SA",
    supplierAddress: "NOGALES,AZ",
    supplierStreet: "",
    supplierCity: "NOGALES",
    supplierState: "AZ",
    supplierZip: "856214505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891270-052507980",
    partNumber: "84891270",
    description: "CONTROL ASM-HTR",
    supplierName: "ALPINE ELECTRONICS OF AMERICA INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037454",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891276-052507980",
    partNumber: "84891276",
    description: "CONTROL ASM-HTR",
    supplierName: "ALPINE ELECTRONICS OF AMERICA INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037454",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891442-607039732",
    partNumber: "84891442",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891444-607039732",
    partNumber: "84891444",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891445-607039732",
    partNumber: "84891445",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891446-607039732",
    partNumber: "84891446",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891447-607039732",
    partNumber: "84891447",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891448-607039732",
    partNumber: "84891448",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891449-607039732",
    partNumber: "84891449",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891450-607039732",
    partNumber: "84891450",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891451-607039732",
    partNumber: "84891451",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891452-607039732",
    partNumber: "84891452",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891453-607039732",
    partNumber: "84891453",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891454-607039732",
    partNumber: "84891454",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891455-607039732",
    partNumber: "84891455",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891456-607039732",
    partNumber: "84891456",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891457-607039732",
    partNumber: "84891457",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891458-607039732",
    partNumber: "84891458",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891459-607039732",
    partNumber: "84891459",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84891638-812833912",
    partNumber: "84891638",
    description: "AIRBAG ASM-FRT",
    supplierName: "TRW OCCUPANT RESTRAINTS DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31137",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84891639-812833912",
    partNumber: "84891639",
    description: "AIRBAG ASM-FRT",
    supplierName: "TRW OCCUPANT RESTRAINTS DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31137",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84893136-062616218",
    partNumber: "84893136",
    description: "HARNESS ASM-CHA",
    supplierName: "AUTOELECTRIC OF AMERICA INC",
    supplierAddress: "EAGLE PASS,TX",
    supplierStreet: "",
    supplierCity: "EAGLE PASS",
    supplierState: "TX",
    supplierZip: "788525749",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84893138-062616218",
    partNumber: "84893138",
    description: "HARNESS ASM-CHA",
    supplierName: "AUTOELECTRIC OF AMERICA INC",
    supplierAddress: "EAGLE PASS,TX",
    supplierStreet: "",
    supplierCity: "EAGLE PASS",
    supplierState: "TX",
    supplierZip: "788525749",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84893372-839434800",
    partNumber: "84893372",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84893373-839434800",
    partNumber: "84893373",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84893376-839434800",
    partNumber: "84893376",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84893378-839434800",
    partNumber: "84893378",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84893381-839434800",
    partNumber: "84893381",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84893382-839434800",
    partNumber: "84893382",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84893385-839434800",
    partNumber: "84893385",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84893390-839434800",
    partNumber: "84893390",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84893403-839434800",
    partNumber: "84893403",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84894914-117409100",
    partNumber: "84894914",
    description: "COVER-L/GATE LW",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84894915-117409100",
    partNumber: "84894915",
    description: "COVER-L/GATE LW",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84894916-117409100",
    partNumber: "84894916",
    description: "COVER-L/GATE LW",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84894917-117409100",
    partNumber: "84894917",
    description: "COVER-L/GATE LW",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84894918-117409100",
    partNumber: "84894918",
    description: "COVER-L/GATE LW",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84895956-814180139",
    partNumber: "84895956",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84896027-812331239",
    partNumber: "84896027",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84896028-812331239",
    partNumber: "84896028",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84896029-812331239",
    partNumber: "84896029",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84896030-812331239",
    partNumber: "84896030",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84896031-812331239",
    partNumber: "84896031",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84896032-812331239",
    partNumber: "84896032",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84896420-812331239",
    partNumber: "84896420",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84896421-812331239",
    partNumber: "84896421",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84896426-812331239",
    partNumber: "84896426",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84896444-807813840",
    partNumber: "84896444",
    description: "MODULE ASM-VIDE",
    supplierName: "VALEO SWITCHES & DETECTION SYSTEMS",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84896477-588159637",
    partNumber: "84896477",
    description: "HARNESS ASM-FRT",
    supplierName: "CONTINENTAL AUTOMOTIVE",
    supplierAddress: "GUANAJUATO,GJ",
    supplierStreet: "",
    supplierCity: "GUANAJUATO",
    supplierState: "GJ",
    supplierZip: "36270",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84896704-133272877",
    partNumber: "84896704",
    description: "MODULE ASM-PARK",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84896705-133272877",
    partNumber: "84896705",
    description: "MODULE ASM-PARK",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84896764-108630731",
    partNumber: "84896764",
    description: "REINFORCEMENT A",
    supplierName: "KIRCHHOFF AUTOMOTIVE DALLAS INC",
    supplierAddress: "GARLAND,TX",
    supplierStreet: "",
    supplierCity: "GARLAND",
    supplierState: "TX",
    supplierZip: "750416051",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84896840-812905909",
    partNumber: "84896840",
    description: "REINFORCEMENT A",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84896841-812905909",
    partNumber: "84896841",
    description: "REINFORCEMENT A",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84897321-809870855",
    partNumber: "84897321",
    description: "BASE ASM-F/FLR",
    supplierName: "BROSE NEW BOSTON INC",
    supplierAddress: "NEW BOSTON,MI",
    supplierStreet: "",
    supplierCity: "NEW BOSTON",
    supplierState: "MI",
    supplierZip: "481649183",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84898068-607039732",
    partNumber: "84898068",
    description: "HARNESS ASM-F/T",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84898699-051713910",
    partNumber: "84898699",
    description: "HEADLAMP ASM-FR",
    supplierName: "NORTH AMERICAN LIGHTING INC",
    supplierAddress: "PARIS,IL",
    supplierStreet: "",
    supplierCity: "PARIS",
    supplierState: "IL",
    supplierZip: "61944",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84898700-051713910",
    partNumber: "84898700",
    description: "HEADLAMP ASM-FR",
    supplierName: "NORTH AMERICAN LIGHTING INC",
    supplierAddress: "PARIS,IL",
    supplierStreet: "",
    supplierCity: "PARIS",
    supplierState: "IL",
    supplierZip: "61944",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84898701-051713910",
    partNumber: "84898701",
    description: "HEADLAMP ASM-FR",
    supplierName: "NORTH AMERICAN LIGHTING INC",
    supplierAddress: "PARIS,IL",
    supplierStreet: "",
    supplierCity: "PARIS",
    supplierState: "IL",
    supplierZip: "61944",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84898702-051713910",
    partNumber: "84898702",
    description: "HEADLAMP ASM-FR",
    supplierName: "NORTH AMERICAN LIGHTING INC",
    supplierAddress: "PARIS,IL",
    supplierStreet: "",
    supplierCity: "PARIS",
    supplierState: "IL",
    supplierZip: "61944",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84898834-080786860",
    partNumber: "84898834",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84898835-080786860",
    partNumber: "84898835",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84898836-080786860",
    partNumber: "84898836",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84898837-080786860",
    partNumber: "84898837",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84899934-000243147",
    partNumber: "84899934",
    description: "SUPPLEMENT-OWNE",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84899944-117409100",
    partNumber: "84899944",
    description: "REINFORCEMENT A",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901011-137355322",
    partNumber: "84901011",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901032-137355322",
    partNumber: "84901032",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901042-137355322",
    partNumber: "84901042",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901045-137355322",
    partNumber: "84901045",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901084-137355322",
    partNumber: "84901084",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901175-137355322",
    partNumber: "84901175",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901201-137355322",
    partNumber: "84901201",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901202-137355322",
    partNumber: "84901202",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901203-137355322",
    partNumber: "84901203",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901368-812888257",
    partNumber: "84901368",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84901370-812888257",
    partNumber: "84901370",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84901371-812888257",
    partNumber: "84901371",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84901373-812888257",
    partNumber: "84901373",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84901374-812888257",
    partNumber: "84901374",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84901375-812888257",
    partNumber: "84901375",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84901376-812888257",
    partNumber: "84901376",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84901480-243459851",
    partNumber: "84901480",
    description: "BRACKET ASM-HTR",
    supplierName: "MARKDOM PLASTIC PRODUCTS LTD",
    supplierAddress: "TORONTO,ON",
    supplierStreet: "",
    supplierCity: "TORONTO",
    supplierState: "ON",
    supplierZip: "M1P 2C3",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84901511-969789283",
    partNumber: "84901511",
    description: "COVER ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901512-969789283",
    partNumber: "84901512",
    description: "COVER ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901513-969789283",
    partNumber: "84901513",
    description: "COVER ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901514-969789283",
    partNumber: "84901514",
    description: "COVER ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901515-969789283",
    partNumber: "84901515",
    description: "COVER ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901517-969789283",
    partNumber: "84901517",
    description: "COVER ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901518-969789283",
    partNumber: "84901518",
    description: "COVER ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901519-969789283",
    partNumber: "84901519",
    description: "COVER ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901520-969789283",
    partNumber: "84901520",
    description: "COVER ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901521-969789283",
    partNumber: "84901521",
    description: "COVER ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901523-969789283",
    partNumber: "84901523",
    description: "COVER ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901524-969789283",
    partNumber: "84901524",
    description: "COVER ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901525-969789283",
    partNumber: "84901525",
    description: "COVER ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901526-969789283",
    partNumber: "84901526",
    description: "COVER ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901527-969789283",
    partNumber: "84901527",
    description: "COVER ASM-R/SEA",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901803-062616218",
    partNumber: "84901803",
    description: "HARNESS ASM-CHA",
    supplierName: "AUTOELECTRIC OF AMERICA INC",
    supplierAddress: "EAGLE PASS,TX",
    supplierStreet: "",
    supplierCity: "EAGLE PASS",
    supplierState: "TX",
    supplierZip: "788525749",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901845-000246553",
    partNumber: "84901845",
    description: "CARD-NAVN DATA",
    supplierName: "TELENAV INC",
    supplierAddress: "FREMONT,CA",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "CA",
    supplierZip: "945397498",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84901856-588159637",
    partNumber: "84901856",
    description: "HARNESS ASM-FRT",
    supplierName: "CONTINENTAL AUTOMOTIVE",
    supplierAddress: "GUANAJUATO,GJ",
    supplierStreet: "",
    supplierCity: "GUANAJUATO",
    supplierState: "GJ",
    supplierZip: "36270",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84902356-042439265",
    partNumber: "84902356",
    description: "BRACKET ASM-EMI",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84902357-042439265",
    partNumber: "84902357",
    description: "BRACKET ASM-EMI",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84903098-607039732",
    partNumber: "84903098",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84903099-607039732",
    partNumber: "84903099",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84903343-812888257",
    partNumber: "84903343",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84903344-812888257",
    partNumber: "84903344",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84903345-812888257",
    partNumber: "84903345",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84903346-812888257",
    partNumber: "84903346",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84903347-812888257",
    partNumber: "84903347",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84903389-812888257",
    partNumber: "84903389",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84903390-812888257",
    partNumber: "84903390",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84903392-812888257",
    partNumber: "84903392",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84903393-812888257",
    partNumber: "84903393",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84903394-812888257",
    partNumber: "84903394",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84903881-812331239",
    partNumber: "84903881",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84903882-812331239",
    partNumber: "84903882",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84903883-812331239",
    partNumber: "84903883",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84903885-812331239",
    partNumber: "84903885",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84903886-812331239",
    partNumber: "84903886",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84903890-812331239",
    partNumber: "84903890",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904248-000202241",
    partNumber: "84904248",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904249-000202241",
    partNumber: "84904249",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904250-000202241",
    partNumber: "84904250",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904251-000202241",
    partNumber: "84904251",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904252-000202241",
    partNumber: "84904252",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904253-000202241",
    partNumber: "84904253",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904254-000202241",
    partNumber: "84904254",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904255-000202241",
    partNumber: "84904255",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904257-000202241",
    partNumber: "84904257",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904258-000202241",
    partNumber: "84904258",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904259-000202241",
    partNumber: "84904259",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904260-000202241",
    partNumber: "84904260",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904261-000202241",
    partNumber: "84904261",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904262-000202241",
    partNumber: "84904262",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904263-000202241",
    partNumber: "84904263",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84904264-000202241",
    partNumber: "84904264",
    description: "APPLIQUE ASM-F/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84905682-000126797",
    partNumber: "84905682",
    description: "BRACKET-I/P COM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "BURLINGTON,NC",
    supplierStreet: "",
    supplierCity: "BURLINGTON",
    supplierState: "NC",
    supplierZip: "27217",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906127-080786860",
    partNumber: "84906127",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906128-080786860",
    partNumber: "84906128",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906129-080786860",
    partNumber: "84906129",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906130-080786860",
    partNumber: "84906130",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906131-080786860",
    partNumber: "84906131",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906132-080786860",
    partNumber: "84906132",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906133-080786860",
    partNumber: "84906133",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906134-080786860",
    partNumber: "84906134",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906197-813016276",
    partNumber: "84906197",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84906576-080786860",
    partNumber: "84906576",
    description: "CARRIER ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906577-080786860",
    partNumber: "84906577",
    description: "CARRIER ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906578-080786860",
    partNumber: "84906578",
    description: "CARRIER ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906579-080786860",
    partNumber: "84906579",
    description: "CARRIER ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906581-080786860",
    partNumber: "84906581",
    description: "CARRIER ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906582-080786860",
    partNumber: "84906582",
    description: "CARRIER ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906583-080786860",
    partNumber: "84906583",
    description: "CARRIER ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906584-080786860",
    partNumber: "84906584",
    description: "CARRIER ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906586-812947059",
    partNumber: "84906586",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84906587-812947059",
    partNumber: "84906587",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84906783-080786860",
    partNumber: "84906783",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84906784-080786860",
    partNumber: "84906784",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84908464-077472228",
    partNumber: "84908464",
    description: "REINFORCEMENT A",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84908465-077472228",
    partNumber: "84908465",
    description: "REINFORCEMENT A",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84908466-077472228",
    partNumber: "84908466",
    description: "REINFORCEMENT A",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84908467-077472228",
    partNumber: "84908467",
    description: "REINFORCEMENT A",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84909099-812895704",
    partNumber: "84909099",
    description: "BAR ASM-I/P TIE",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84909100-812895704",
    partNumber: "84909100",
    description: "BAR ASM-I/P TIE",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84909101-812895704",
    partNumber: "84909101",
    description: "BAR ASM-I/P TIE",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84909102-812895704",
    partNumber: "84909102",
    description: "BAR ASM-I/P TIE",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84909107-932662133",
    partNumber: "84909107",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84909111-544321108",
    partNumber: "84909111",
    description: "APPLIQUE ASM-I/",
    supplierName: "XUANCHENG TOP SUN SURFACE TECHNOLOG",
    supplierAddress: "XUANCHENG,34",
    supplierStreet: "",
    supplierCity: "XUANCHENG",
    supplierState: "34",
    supplierZip: "242100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84909349-000202266",
    partNumber: "84909349",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910716-080786860",
    partNumber: "84910716",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910717-080786860",
    partNumber: "84910717",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910718-080786860",
    partNumber: "84910718",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910719-080786860",
    partNumber: "84910719",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910721-080786860",
    partNumber: "84910721",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910722-080786860",
    partNumber: "84910722",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910723-080786860",
    partNumber: "84910723",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910725-080786860",
    partNumber: "84910725",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910726-080786860",
    partNumber: "84910726",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910727-080786860",
    partNumber: "84910727",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910729-080786860",
    partNumber: "84910729",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910730-080786860",
    partNumber: "84910730",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910731-080786860",
    partNumber: "84910731",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910797-080786860",
    partNumber: "84910797",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910798-080786860",
    partNumber: "84910798",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910799-080786860",
    partNumber: "84910799",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910800-080786860",
    partNumber: "84910800",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910937-785629952",
    partNumber: "84910937",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910938-785629952",
    partNumber: "84910938",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910947-785629952",
    partNumber: "84910947",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84910948-785629952",
    partNumber: "84910948",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84911639-253988547",
    partNumber: "84911639",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84911640-253988547",
    partNumber: "84911640",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84911641-253988547",
    partNumber: "84911641",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84911642-253988547",
    partNumber: "84911642",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84912619-000202266",
    partNumber: "84912619",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84912620-000202266",
    partNumber: "84912620",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84912621-000202266",
    partNumber: "84912621",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84912622-000202266",
    partNumber: "84912622",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84912626-000202266",
    partNumber: "84912626",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84912628-000202266",
    partNumber: "84912628",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84913466-969789283",
    partNumber: "84913466",
    description: "MOLDING ASM-RKR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84913467-969789283",
    partNumber: "84913467",
    description: "MOLDING ASM-RKR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84913468-969789283",
    partNumber: "84913468",
    description: "MOLDING ASM-RKR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84913469-969789283",
    partNumber: "84913469",
    description: "MOLDING ASM-RKR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84913470-969789283",
    partNumber: "84913470",
    description: "MOLDING ASM-RKR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84913471-969789283",
    partNumber: "84913471",
    description: "MOLDING ASM-RKR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84913472-969789283",
    partNumber: "84913472",
    description: "MOLDING ASM-RKR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84913473-969789283",
    partNumber: "84913473",
    description: "MOLDING ASM-RKR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84913474-969789283",
    partNumber: "84913474",
    description: "MOLDING ASM-RKR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84913475-969789283",
    partNumber: "84913475",
    description: "MOLDING ASM-RKR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84913476-969789283",
    partNumber: "84913476",
    description: "MOLDING ASM-RKR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84913477-969789283",
    partNumber: "84913477",
    description: "MOLDING ASM-RKR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84913601-175597137",
    partNumber: "84913601",
    description: "DISPLAY ASM-HD",
    supplierName: "NEW SABINA INDUSTRIES INC",
    supplierAddress: "SABINA,OH",
    supplierStreet: "",
    supplierCity: "SABINA",
    supplierState: "OH",
    supplierZip: "451699463",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84913746-654593565",
    partNumber: "84913746",
    description: "PLATE ASM-FRT S",
    supplierName: "NINGBO SWELL INDUSTRY CO LTD",
    supplierAddress: "CIXI,33",
    supplierStreet: "",
    supplierCity: "CIXI",
    supplierState: "33",
    supplierZip: "315333",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84914286-080316510",
    partNumber: "84914286",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914287-080316510",
    partNumber: "84914287",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914288-080316510",
    partNumber: "84914288",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914289-080316510",
    partNumber: "84914289",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914290-080316510",
    partNumber: "84914290",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914291-080316510",
    partNumber: "84914291",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914292-080316510",
    partNumber: "84914292",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914293-080316510",
    partNumber: "84914293",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914294-080316510",
    partNumber: "84914294",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914296-080316510",
    partNumber: "84914296",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914297-080316510",
    partNumber: "84914297",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914298-080316510",
    partNumber: "84914298",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914299-080316510",
    partNumber: "84914299",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914300-080316510",
    partNumber: "84914300",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914301-080316510",
    partNumber: "84914301",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914302-080316510",
    partNumber: "84914302",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914303-080316510",
    partNumber: "84914303",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914304-080316510",
    partNumber: "84914304",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914306-080316510",
    partNumber: "84914306",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914307-080316510",
    partNumber: "84914307",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914308-080316510",
    partNumber: "84914308",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914309-080316510",
    partNumber: "84914309",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914310-080316510",
    partNumber: "84914310",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914311-080316510",
    partNumber: "84914311",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914312-080316510",
    partNumber: "84914312",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914313-080316510",
    partNumber: "84914313",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914314-080316510",
    partNumber: "84914314",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914316-080316510",
    partNumber: "84914316",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914317-080316510",
    partNumber: "84914317",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914318-080316510",
    partNumber: "84914318",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914319-080316510",
    partNumber: "84914319",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914320-080316510",
    partNumber: "84914320",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914321-080316510",
    partNumber: "84914321",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914322-080316510",
    partNumber: "84914322",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914323-080316510",
    partNumber: "84914323",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914324-080316510",
    partNumber: "84914324",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914326-080316510",
    partNumber: "84914326",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914327-080316510",
    partNumber: "84914327",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914328-080316510",
    partNumber: "84914328",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914329-080316510",
    partNumber: "84914329",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914330-080316510",
    partNumber: "84914330",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914331-080316510",
    partNumber: "84914331",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914332-080316510",
    partNumber: "84914332",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914333-080316510",
    partNumber: "84914333",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914334-080316510",
    partNumber: "84914334",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914336-080316510",
    partNumber: "84914336",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914337-080316510",
    partNumber: "84914337",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914338-080316510",
    partNumber: "84914338",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914339-080316510",
    partNumber: "84914339",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914340-080316510",
    partNumber: "84914340",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914341-080316510",
    partNumber: "84914341",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914342-080316510",
    partNumber: "84914342",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914343-080316510",
    partNumber: "84914343",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914344-080316510",
    partNumber: "84914344",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914346-080316510",
    partNumber: "84914346",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914347-080316510",
    partNumber: "84914347",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914348-080316510",
    partNumber: "84914348",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914349-080316510",
    partNumber: "84914349",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914350-080316510",
    partNumber: "84914350",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914351-080316510",
    partNumber: "84914351",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914352-080316510",
    partNumber: "84914352",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914353-080316510",
    partNumber: "84914353",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914354-080316510",
    partNumber: "84914354",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914356-080316510",
    partNumber: "84914356",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914357-080316510",
    partNumber: "84914357",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914358-080316510",
    partNumber: "84914358",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914359-080316510",
    partNumber: "84914359",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914360-080316510",
    partNumber: "84914360",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914361-080316510",
    partNumber: "84914361",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914362-080316510",
    partNumber: "84914362",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914363-080316510",
    partNumber: "84914363",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914364-080316510",
    partNumber: "84914364",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914366-080316510",
    partNumber: "84914366",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914367-080316510",
    partNumber: "84914367",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914368-080316510",
    partNumber: "84914368",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914369-080316510",
    partNumber: "84914369",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914370-080316510",
    partNumber: "84914370",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914371-080316510",
    partNumber: "84914371",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914372-080316510",
    partNumber: "84914372",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914373-080316510",
    partNumber: "84914373",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914374-080316510",
    partNumber: "84914374",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914376-080316510",
    partNumber: "84914376",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914377-080316510",
    partNumber: "84914377",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914378-080316510",
    partNumber: "84914378",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914379-080316510",
    partNumber: "84914379",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914380-080316510",
    partNumber: "84914380",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914381-080316510",
    partNumber: "84914381",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914382-080316510",
    partNumber: "84914382",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914383-080316510",
    partNumber: "84914383",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914384-080316510",
    partNumber: "84914384",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914385-080316510",
    partNumber: "84914385",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914387-080316510",
    partNumber: "84914387",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914388-080316510",
    partNumber: "84914388",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914389-080316510",
    partNumber: "84914389",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914390-080316510",
    partNumber: "84914390",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914391-080316510",
    partNumber: "84914391",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914392-080316510",
    partNumber: "84914392",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914393-080316510",
    partNumber: "84914393",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914394-080316510",
    partNumber: "84914394",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914395-080316510",
    partNumber: "84914395",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914397-080316510",
    partNumber: "84914397",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914398-080316510",
    partNumber: "84914398",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914399-080316510",
    partNumber: "84914399",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914400-080316510",
    partNumber: "84914400",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914401-080316510",
    partNumber: "84914401",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914402-080316510",
    partNumber: "84914402",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914403-080316510",
    partNumber: "84914403",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914404-080316510",
    partNumber: "84914404",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914405-080316510",
    partNumber: "84914405",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914407-080316510",
    partNumber: "84914407",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914408-080316510",
    partNumber: "84914408",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914409-080316510",
    partNumber: "84914409",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914410-080316510",
    partNumber: "84914410",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914411-080316510",
    partNumber: "84914411",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914412-080316510",
    partNumber: "84914412",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914414-080316510",
    partNumber: "84914414",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914415-080316510",
    partNumber: "84914415",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914416-080316510",
    partNumber: "84914416",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914418-080316510",
    partNumber: "84914418",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914419-080316510",
    partNumber: "84914419",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914420-080316510",
    partNumber: "84914420",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914421-080316510",
    partNumber: "84914421",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914422-080316510",
    partNumber: "84914422",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914423-080316510",
    partNumber: "84914423",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914424-080316510",
    partNumber: "84914424",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914425-080316510",
    partNumber: "84914425",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914426-080316510",
    partNumber: "84914426",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914427-080316510",
    partNumber: "84914427",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914429-080316510",
    partNumber: "84914429",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914430-080316510",
    partNumber: "84914430",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914431-080316510",
    partNumber: "84914431",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914432-080316510",
    partNumber: "84914432",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914433-080316510",
    partNumber: "84914433",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914434-080316510",
    partNumber: "84914434",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914435-080316510",
    partNumber: "84914435",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914436-080316510",
    partNumber: "84914436",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914437-080316510",
    partNumber: "84914437",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914440-080316510",
    partNumber: "84914440",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914441-080316510",
    partNumber: "84914441",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914442-080316510",
    partNumber: "84914442",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914443-080316510",
    partNumber: "84914443",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914444-080316510",
    partNumber: "84914444",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914445-080316510",
    partNumber: "84914445",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914446-080316510",
    partNumber: "84914446",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914447-080316510",
    partNumber: "84914447",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914448-080316510",
    partNumber: "84914448",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914450-080316510",
    partNumber: "84914450",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914451-080316510",
    partNumber: "84914451",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914452-080316510",
    partNumber: "84914452",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914453-080316510",
    partNumber: "84914453",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914454-080316510",
    partNumber: "84914454",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914455-080316510",
    partNumber: "84914455",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914456-080316510",
    partNumber: "84914456",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914457-080316510",
    partNumber: "84914457",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914458-080316510",
    partNumber: "84914458",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914460-080316510",
    partNumber: "84914460",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914461-080316510",
    partNumber: "84914461",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914462-080316510",
    partNumber: "84914462",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914463-080316510",
    partNumber: "84914463",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914464-080316510",
    partNumber: "84914464",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914465-080316510",
    partNumber: "84914465",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914466-080316510",
    partNumber: "84914466",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914467-080316510",
    partNumber: "84914467",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914468-080316510",
    partNumber: "84914468",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914470-080316510",
    partNumber: "84914470",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914471-080316510",
    partNumber: "84914471",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914475-080316510",
    partNumber: "84914475",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914476-080316510",
    partNumber: "84914476",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914477-080316510",
    partNumber: "84914477",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914478-080316510",
    partNumber: "84914478",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914480-080316510",
    partNumber: "84914480",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914982-253988547",
    partNumber: "84914982",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84914983-253988547",
    partNumber: "84914983",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84914984-253988547",
    partNumber: "84914984",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84914985-253988547",
    partNumber: "84914985",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84914988-078488161",
    partNumber: "84914988",
    description: "AMPLIFIER ASM-R",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84914990-078488161",
    partNumber: "84914990",
    description: "AMPLIFIER ASM-R",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84916451-331043633",
    partNumber: "84916451",
    description: "MODULE ASM-RR D",
    supplierName: "ZF FRIEDRICHSHAFEN AG",
    supplierAddress: "GOTHA,TH",
    supplierStreet: "",
    supplierCity: "GOTHA",
    supplierState: "TH",
    supplierZip: "99867",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-84916653-933874026",
    partNumber: "84916653",
    description: "CARRIER ASM-DIF",
    supplierName: "ZF GAINESVILLE LLC",
    supplierAddress: "GAINESVILLE,GA",
    supplierStreet: "",
    supplierCity: "GAINESVILLE",
    supplierState: "GA",
    supplierZip: "305016860",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84917312-814814042",
    partNumber: "84917312",
    description: "HOSE ASM-ENG CO",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84917924-969789283",
    partNumber: "84917924",
    description: "GUIDE ASM-FRT B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84917925-969789283",
    partNumber: "84917925",
    description: "GUIDE ASM-FRT B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84918167-555343750",
    partNumber: "84918167",
    description: "COVER-VIDEO DIS",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84919777-196138536",
    partNumber: "84919777",
    description: "CLUSTER ASM-INS",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84919778-196138536",
    partNumber: "84919778",
    description: "CLUSTER ASM-INS",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84919779-196138536",
    partNumber: "84919779",
    description: "CLUSTER ASM-INS",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84919781-196138536",
    partNumber: "84919781",
    description: "CLUSTER ASM-INS",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84919782-196138536",
    partNumber: "84919782",
    description: "CLUSTER ASM-INS",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84919783-196138536",
    partNumber: "84919783",
    description: "CLUSTER ASM-INS",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84920560-969789283",
    partNumber: "84920560",
    description: "GUTTER ASM-BK B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84921039-009971289",
    partNumber: "84921039",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE IOWA INC",
    supplierAddress: "KEOKUK,IA",
    supplierStreet: "",
    supplierCity: "KEOKUK",
    supplierState: "IA",
    supplierZip: "526322259",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84921048-000782587",
    partNumber: "84921048",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32557",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84921049-000782587",
    partNumber: "84921049",
    description: "WEATHERSTRIP AS",
    supplierName: "HENNIGES AUTOMOTIVE SEALING SYSTEMS",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32557",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84921090-812928414",
    partNumber: "84921090",
    description: "MODULE ASM-TRFE",
    supplierName: "MAGNA POWERTRAIN DE MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84921581-812947059",
    partNumber: "84921581",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84921583-812947059",
    partNumber: "84921583",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84922374-812888257",
    partNumber: "84922374",
    description: "HOOD ASM-I/P IN",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84922375-812888257",
    partNumber: "84922375",
    description: "HOOD ASM-I/P IN",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84922377-812888257",
    partNumber: "84922377",
    description: "HOOD ASM-I/P IN",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84922378-812888257",
    partNumber: "84922378",
    description: "HOOD ASM-I/P IN",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84922379-812888257",
    partNumber: "84922379",
    description: "HOOD ASM-I/P IN",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84922821-969789283",
    partNumber: "84922821",
    description: "GUIDE ASM-FRT B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84922822-969789283",
    partNumber: "84922822",
    description: "GUIDE ASM-FRT B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84922823-969789283",
    partNumber: "84922823",
    description: "GUIDE ASM-FRT B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84922824-969789283",
    partNumber: "84922824",
    description: "GUIDE ASM-FRT B",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84922907-016422106",
    partNumber: "84922907",
    description: "SHAFT ASM-PROP",
    supplierName: "NEAPCO DRIVELINES LLC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481115271",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84922908-016422106",
    partNumber: "84922908",
    description: "SHAFT ASM-PROP",
    supplierName: "NEAPCO DRIVELINES LLC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481115271",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84922922-555343750",
    partNumber: "84922922",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84922926-555343750",
    partNumber: "84922926",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84923047-809149292",
    partNumber: "84923047",
    description: "SHUTTER ASM-FRT",
    supplierName: "MAGNA MODULAR SYSTEMS INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891476",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923101-627272024",
    partNumber: "84923101",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923102-627272024",
    partNumber: "84923102",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923103-627272024",
    partNumber: "84923103",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923104-627272024",
    partNumber: "84923104",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923105-627272024",
    partNumber: "84923105",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923106-627272024",
    partNumber: "84923106",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923107-627272024",
    partNumber: "84923107",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923108-627272024",
    partNumber: "84923108",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923110-627272024",
    partNumber: "84923110",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923111-627272024",
    partNumber: "84923111",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923112-627272024",
    partNumber: "84923112",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923113-627272024",
    partNumber: "84923113",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923114-627272024",
    partNumber: "84923114",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923115-627272024",
    partNumber: "84923115",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923116-627272024",
    partNumber: "84923116",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923117-627272024",
    partNumber: "84923117",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923120-627272024",
    partNumber: "84923120",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923121-627272024",
    partNumber: "84923121",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923122-627272024",
    partNumber: "84923122",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923123-627272024",
    partNumber: "84923123",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923124-627272024",
    partNumber: "84923124",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923125-627272024",
    partNumber: "84923125",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923126-627272024",
    partNumber: "84923126",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923127-627272024",
    partNumber: "84923127",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923129-627272024",
    partNumber: "84923129",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923130-627272024",
    partNumber: "84923130",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923131-627272024",
    partNumber: "84923131",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923132-627272024",
    partNumber: "84923132",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923133-627272024",
    partNumber: "84923133",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923134-627272024",
    partNumber: "84923134",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923135-627272024",
    partNumber: "84923135",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923136-627272024",
    partNumber: "84923136",
    description: "HANDLE ASM-RR S",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923447-588352398",
    partNumber: "84923447",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84923448-588352398",
    partNumber: "84923448",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84923540-812806857",
    partNumber: "84923540",
    description: "CARRIER ASM-DIF",
    supplierName: "AMERICAN AXLE & MFG DE MEX S DE RL",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36100",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84923541-812806857",
    partNumber: "84923541",
    description: "CARRIER ASM-DIF",
    supplierName: "AMERICAN AXLE & MFG DE MEX S DE RL",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36100",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84923750-607039732",
    partNumber: "84923750",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923751-607039732",
    partNumber: "84923751",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923752-607039732",
    partNumber: "84923752",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923753-116973138",
    partNumber: "84923753",
    description: "CAMERA ASM-FV",
    supplierName: "MAGNA ELECTRONICS INC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923754-116973138",
    partNumber: "84923754",
    description: "CAMERA ASM-FV",
    supplierName: "MAGNA ELECTRONICS INC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923872-117409100",
    partNumber: "84923872",
    description: "PANEL ASM-R/END",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923995-117409100",
    partNumber: "84923995",
    description: "BEZEL-R/SEAT CT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923996-117409100",
    partNumber: "84923996",
    description: "BEZEL-R/SEAT CT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923997-117409100",
    partNumber: "84923997",
    description: "BEZEL-R/SEAT CT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84923998-117409100",
    partNumber: "84923998",
    description: "BEZEL-R/SEAT CT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84924000-117409100",
    partNumber: "84924000",
    description: "BEZEL-RR CTR SE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84924001-117409100",
    partNumber: "84924001",
    description: "BEZEL-RR CTR SE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84924002-117409100",
    partNumber: "84924002",
    description: "BEZEL-RR CTR SE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84924003-117409100",
    partNumber: "84924003",
    description: "BEZEL-RR CTR SE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84924005-117409100",
    partNumber: "84924005",
    description: "BEZEL-R/SEAT CT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84924006-117409100",
    partNumber: "84924006",
    description: "BEZEL-R/SEAT CT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84924007-117409100",
    partNumber: "84924007",
    description: "BEZEL-R/SEAT CT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84924008-117409100",
    partNumber: "84924008",
    description: "BEZEL-R/SEAT CT",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84924010-117409100",
    partNumber: "84924010",
    description: "BEZEL-RR CTR SE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84924011-117409100",
    partNumber: "84924011",
    description: "BEZEL-RR CTR SE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84924012-117409100",
    partNumber: "84924012",
    description: "BEZEL-RR CTR SE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84924013-117409100",
    partNumber: "84924013",
    description: "BEZEL-RR CTR SE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84924030-814526588",
    partNumber: "84924030",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84924031-814526588",
    partNumber: "84924031",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84924032-814526588",
    partNumber: "84924032",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84924033-814526588",
    partNumber: "84924033",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84924037-814526588",
    partNumber: "84924037",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84924038-814526588",
    partNumber: "84924038",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84924039-814526588",
    partNumber: "84924039",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84924368-688001481",
    partNumber: "84924368",
    description: "MOUNT ASM-ENG",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84924369-688001481",
    partNumber: "84924369",
    description: "MOUNT ASM-ENG",
    supplierName: "DTR CORP",
    supplierAddress: "YANGSAN,48",
    supplierStreet: "",
    supplierCity: "YANGSAN",
    supplierState: "48",
    supplierZip: "50567",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84925053-609623566",
    partNumber: "84925053",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925054-609623566",
    partNumber: "84925054",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925055-609623566",
    partNumber: "84925055",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925056-609623566",
    partNumber: "84925056",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925057-609623566",
    partNumber: "84925057",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925058-609623566",
    partNumber: "84925058",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925059-609623566",
    partNumber: "84925059",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925060-609623566",
    partNumber: "84925060",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925061-609623566",
    partNumber: "84925061",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925062-609623566",
    partNumber: "84925062",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925063-609623566",
    partNumber: "84925063",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925064-609623566",
    partNumber: "84925064",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925065-609623566",
    partNumber: "84925065",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925066-609623566",
    partNumber: "84925066",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925067-609623566",
    partNumber: "84925067",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925068-609623566",
    partNumber: "84925068",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925069-609623566",
    partNumber: "84925069",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925071-609623566",
    partNumber: "84925071",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925072-609623566",
    partNumber: "84925072",
    description: "HARNESS ASM-HDL",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925077-812888257",
    partNumber: "84925077",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925665-242800241",
    partNumber: "84925665",
    description: "JACK PKG-.",
    supplierName: "VENTRA GROUP INC",
    supplierAddress: "TOTTENHAM,ON",
    supplierStreet: "",
    supplierCity: "TOTTENHAM",
    supplierState: "ON",
    supplierZip: "L0G 1W0",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84925670-812888257",
    partNumber: "84925670",
    description: "PLATE ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925672-812888257",
    partNumber: "84925672",
    description: "PLATE ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925674-813016276",
    partNumber: "84925674",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925675-813016276",
    partNumber: "84925675",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925676-813016276",
    partNumber: "84925676",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925677-813016276",
    partNumber: "84925677",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925679-813016276",
    partNumber: "84925679",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925681-813016276",
    partNumber: "84925681",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925682-813016276",
    partNumber: "84925682",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925683-813016276",
    partNumber: "84925683",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925684-813016276",
    partNumber: "84925684",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925686-813016276",
    partNumber: "84925686",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925687-813016276",
    partNumber: "84925687",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925688-813016276",
    partNumber: "84925688",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925694-813016276",
    partNumber: "84925694",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84925725-809870855",
    partNumber: "84925725",
    description: "BASE ASM-F/FLR",
    supplierName: "BROSE NEW BOSTON INC",
    supplierAddress: "NEW BOSTON,MI",
    supplierStreet: "",
    supplierCity: "NEW BOSTON",
    supplierState: "MI",
    supplierZip: "481649183",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925726-809870855",
    partNumber: "84925726",
    description: "BASE ASM-F/FLR",
    supplierName: "BROSE NEW BOSTON INC",
    supplierAddress: "NEW BOSTON,MI",
    supplierStreet: "",
    supplierCity: "NEW BOSTON",
    supplierState: "MI",
    supplierZip: "481649183",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925730-081099446",
    partNumber: "84925730",
    description: "CLUSTER ASM-INS",
    supplierName: "YAZAKI NORTH AMERICA INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780459526",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925731-081099446",
    partNumber: "84925731",
    description: "CLUSTER ASM-INS",
    supplierName: "YAZAKI NORTH AMERICA INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780459526",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925732-081099446",
    partNumber: "84925732",
    description: "CLUSTER ASM-INS",
    supplierName: "YAZAKI NORTH AMERICA INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780459526",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925733-081099446",
    partNumber: "84925733",
    description: "CLUSTER ASM-INS",
    supplierName: "YAZAKI NORTH AMERICA INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780459526",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925734-081099446",
    partNumber: "84925734",
    description: "CLUSTER ASM-INS",
    supplierName: "YAZAKI NORTH AMERICA INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780459526",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925735-081099446",
    partNumber: "84925735",
    description: "CLUSTER ASM-INS",
    supplierName: "YAZAKI NORTH AMERICA INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780459526",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925829-077472228",
    partNumber: "84925829",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925830-077472228",
    partNumber: "84925830",
    description: "REINFORCEMENT-F",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925835-077472228",
    partNumber: "84925835",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925836-077472228",
    partNumber: "84925836",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925837-077472228",
    partNumber: "84925837",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925838-077472228",
    partNumber: "84925838",
    description: "REINFORCEMENT-R",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925914-800786642",
    partNumber: "84925914",
    description: "REINFORCEMENT-F",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925915-800786642",
    partNumber: "84925915",
    description: "REINFORCEMENT-F",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925945-116973138",
    partNumber: "84925945",
    description: "CAMERA ASM-FV",
    supplierName: "MAGNA ELECTRONICS INC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925946-116973138",
    partNumber: "84925946",
    description: "CAMERA ASM-FV",
    supplierName: "MAGNA ELECTRONICS INC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925970-800786642",
    partNumber: "84925970",
    description: "REINFORCEMENT-R",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925971-800786642",
    partNumber: "84925971",
    description: "REINFORCEMENT-R",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925993-800786642",
    partNumber: "84925993",
    description: "REINFORCEMENT-R",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925994-800786642",
    partNumber: "84925994",
    description: "REINFORCEMENT-R",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925995-800786642",
    partNumber: "84925995",
    description: "REINFORCEMENT-R",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84925996-800786642",
    partNumber: "84925996",
    description: "REINFORCEMENT-R",
    supplierName: "L&L PRODUCTS INC",
    supplierAddress: "ROMEO,MI",
    supplierStreet: "",
    supplierCity: "ROMEO",
    supplierState: "MI",
    supplierZip: "480654919",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84926388-122505659",
    partNumber: "84926388",
    description: "CYLINDER ASM-BR",
    supplierName: "ZF ACTIVE SAFETY US INC",
    supplierAddress: "FOWLERVILLE,MI",
    supplierStreet: "",
    supplierCity: "FOWLERVILLE",
    supplierState: "MI",
    supplierZip: "488367908",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84926389-122505659",
    partNumber: "84926389",
    description: "CYLINDER ASM-BR",
    supplierName: "ZF ACTIVE SAFETY US INC",
    supplierAddress: "FOWLERVILLE,MI",
    supplierStreet: "",
    supplierCity: "FOWLERVILLE",
    supplierState: "MI",
    supplierZip: "488367908",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84926390-122505659",
    partNumber: "84926390",
    description: "CYLINDER ASM-BR",
    supplierName: "ZF ACTIVE SAFETY US INC",
    supplierAddress: "FOWLERVILLE,MI",
    supplierStreet: "",
    supplierCity: "FOWLERVILLE",
    supplierState: "MI",
    supplierZip: "488367908",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84926391-122505659",
    partNumber: "84926391",
    description: "CYLINDER ASM-BR",
    supplierName: "ZF ACTIVE SAFETY US INC",
    supplierAddress: "FOWLERVILLE,MI",
    supplierStreet: "",
    supplierCity: "FOWLERVILLE",
    supplierState: "MI",
    supplierZip: "488367908",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84926407-084668367",
    partNumber: "84926407",
    description: "BLOCK ASM-ENG W",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "BROOKHAVEN,MS",
    supplierStreet: "",
    supplierCity: "BROOKHAVEN",
    supplierState: "MS",
    supplierZip: "396018951",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84926432-829173934",
    partNumber: "84926432",
    description: "SPACER-HDLNG T/",
    supplierName: "DERBY FABRICATING SOLUTIONS LLC",
    supplierAddress: "CADIZ,KY",
    supplierStreet: "",
    supplierCity: "CADIZ",
    supplierState: "KY",
    supplierZip: "422117701",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84926573-201189391",
    partNumber: "84926573",
    description: "MODULE ASM-DIGI",
    supplierName: "VEONEER CANADA INC",
    supplierAddress: "MARKHAM,ON",
    supplierStreet: "",
    supplierCity: "MARKHAM",
    supplierState: "ON",
    supplierZip: "L3R 5C2",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84926751-080786860",
    partNumber: "84926751",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84926752-080786860",
    partNumber: "84926752",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84926755-080786860",
    partNumber: "84926755",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84926922-142199376",
    partNumber: "84926922",
    description: "SHIELD-FRT BRK",
    supplierName: "CHALLENGE MFG CO",
    supplierAddress: "HOLLAND,MI",
    supplierStreet: "",
    supplierCity: "HOLLAND",
    supplierState: "MI",
    supplierZip: "49423",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84927262-812296430",
    partNumber: "84927262",
    description: "BRACKET-F/FLR P",
    supplierName: "VUTEQ INDUSTRIES MEXICO SA DE CV",
    supplierAddress: "VILLA DE REYES,SL",
    supplierStreet: "",
    supplierCity: "VILLA DE REYES",
    supplierState: "SL",
    supplierZip: "79525",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84927359-081348326",
    partNumber: "84927359",
    description: "COLUMN ASM-STRG",
    supplierName: "AUTOMOTIVE STEERING COLUMN LLC",
    supplierAddress: "FLORENCE,KY",
    supplierStreet: "",
    supplierCity: "FLORENCE",
    supplierState: "KY",
    supplierZip: "410420000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84927673-812888257",
    partNumber: "84927673",
    description: "TRAY ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84927674-812888257",
    partNumber: "84927674",
    description: "TRAY ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84927675-812888257",
    partNumber: "84927675",
    description: "TRAY ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84927677-812888257",
    partNumber: "84927677",
    description: "TRAY ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84927678-812888257",
    partNumber: "84927678",
    description: "TRAY ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84927688-080786860",
    partNumber: "84927688",
    description: "CARRIER ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84928146-812760162",
    partNumber: "84928146",
    description: "MODULE ASM-HTR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "TOLUCA,EM",
    supplierStreet: "",
    supplierCity: "TOLUCA",
    supplierState: "EM",
    supplierZip: "50200",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84928147-812760162",
    partNumber: "84928147",
    description: "MODULE ASM-HTR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "TOLUCA,EM",
    supplierStreet: "",
    supplierCity: "TOLUCA",
    supplierState: "EM",
    supplierZip: "50200",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84928902-129136698",
    partNumber: "84928902",
    description: "MOTOR ASM-R/WDO",
    supplierName: "DY AMERICA INC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483143209",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84928920-000195578",
    partNumber: "84928920",
    description: "MODULE PKG-ASST",
    supplierName: "CONTINENTAL AUTOMOTIVE NOGALES SA",
    supplierAddress: "NOGALES,AZ",
    supplierStreet: "",
    supplierCity: "NOGALES",
    supplierState: "AZ",
    supplierZip: "856214505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84928999-812888257",
    partNumber: "84928999",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929002-969789283",
    partNumber: "84929002",
    description: "ATTACHMENT PKG-",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929191-080786860",
    partNumber: "84929191",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929192-080786860",
    partNumber: "84929192",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929193-080786860",
    partNumber: "84929193",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929194-080786860",
    partNumber: "84929194",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929195-080786860",
    partNumber: "84929195",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929196-080786860",
    partNumber: "84929196",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929197-080786860",
    partNumber: "84929197",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929198-080786860",
    partNumber: "84929198",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929199-080786860",
    partNumber: "84929199",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929203-080786860",
    partNumber: "84929203",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929204-080786860",
    partNumber: "84929204",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929205-080786860",
    partNumber: "84929205",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929206-080786860",
    partNumber: "84929206",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929207-080786860",
    partNumber: "84929207",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929208-080786860",
    partNumber: "84929208",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929209-080786860",
    partNumber: "84929209",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929210-080786860",
    partNumber: "84929210",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929211-080786860",
    partNumber: "84929211",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929215-080786860",
    partNumber: "84929215",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929216-080786860",
    partNumber: "84929216",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929217-080786860",
    partNumber: "84929217",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929218-080786860",
    partNumber: "84929218",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929219-080786860",
    partNumber: "84929219",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929220-080786860",
    partNumber: "84929220",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929221-080786860",
    partNumber: "84929221",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929222-080786860",
    partNumber: "84929222",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929223-080786860",
    partNumber: "84929223",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929224-080786860",
    partNumber: "84929224",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929225-080786860",
    partNumber: "84929225",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929226-080786860",
    partNumber: "84929226",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929227-080786860",
    partNumber: "84929227",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929232-080786860",
    partNumber: "84929232",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929233-080786860",
    partNumber: "84929233",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929234-080786860",
    partNumber: "84929234",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929235-080786860",
    partNumber: "84929235",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929236-080786860",
    partNumber: "84929236",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929237-080786860",
    partNumber: "84929237",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929238-080786860",
    partNumber: "84929238",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929239-080786860",
    partNumber: "84929239",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929240-080786860",
    partNumber: "84929240",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929241-080786860",
    partNumber: "84929241",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929242-080786860",
    partNumber: "84929242",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929243-080786860",
    partNumber: "84929243",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929244-080786860",
    partNumber: "84929244",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929249-080786860",
    partNumber: "84929249",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929250-080786860",
    partNumber: "84929250",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929251-080786860",
    partNumber: "84929251",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929252-080786860",
    partNumber: "84929252",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929253-080786860",
    partNumber: "84929253",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929254-080786860",
    partNumber: "84929254",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929255-080786860",
    partNumber: "84929255",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929256-080786860",
    partNumber: "84929256",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929257-080786860",
    partNumber: "84929257",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929258-080786860",
    partNumber: "84929258",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929259-080786860",
    partNumber: "84929259",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929260-080786860",
    partNumber: "84929260",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929261-080786860",
    partNumber: "84929261",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929266-080786860",
    partNumber: "84929266",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929267-080786860",
    partNumber: "84929267",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929268-080786860",
    partNumber: "84929268",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929269-080786860",
    partNumber: "84929269",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929270-080786860",
    partNumber: "84929270",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929271-080786860",
    partNumber: "84929271",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929272-080786860",
    partNumber: "84929272",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929273-080786860",
    partNumber: "84929273",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929274-080786860",
    partNumber: "84929274",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929275-080786860",
    partNumber: "84929275",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929276-080786860",
    partNumber: "84929276",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929277-080786860",
    partNumber: "84929277",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929278-080786860",
    partNumber: "84929278",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929381-607039732",
    partNumber: "84929381",
    description: "BLOCK ASM-I/P W",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929382-607039732",
    partNumber: "84929382",
    description: "BLOCK ASM-I/P W",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929383-006016810",
    partNumber: "84929383",
    description: "LABEL-I/P WRG H",
    supplierName: "GRAND RAPIDS LABEL CO",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "49505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84929396-814180139",
    partNumber: "84929396",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929397-814180139",
    partNumber: "84929397",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929398-814180139",
    partNumber: "84929398",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929399-814180139",
    partNumber: "84929399",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929400-814180139",
    partNumber: "84929400",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929402-814180139",
    partNumber: "84929402",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929403-814180139",
    partNumber: "84929403",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929404-814180139",
    partNumber: "84929404",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929405-814180139",
    partNumber: "84929405",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929406-814180139",
    partNumber: "84929406",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929408-814180139",
    partNumber: "84929408",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929409-814180139",
    partNumber: "84929409",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929410-814180139",
    partNumber: "84929410",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929412-814180139",
    partNumber: "84929412",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929413-814180139",
    partNumber: "84929413",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929414-814180139",
    partNumber: "84929414",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929416-814180139",
    partNumber: "84929416",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929417-814180139",
    partNumber: "84929417",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929418-814180139",
    partNumber: "84929418",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929420-814180139",
    partNumber: "84929420",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929421-814180139",
    partNumber: "84929421",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929422-814180139",
    partNumber: "84929422",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84929585-545313673",
    partNumber: "84929585",
    description: "MODULE ASM-HDLP",
    supplierName: "VITESCO AUTOMOTIVE TIANJIN CO LTD",
    supplierAddress: "TIANJIN,12",
    supplierStreet: "",
    supplierCity: "TIANJIN",
    supplierState: "12",
    supplierZip: "300457",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84929670-175597137",
    partNumber: "84929670",
    description: "DISPLAY ASM-HD",
    supplierName: "NEW SABINA INDUSTRIES INC",
    supplierAddress: "SABINA,OH",
    supplierStreet: "",
    supplierCity: "SABINA",
    supplierState: "OH",
    supplierZip: "451699463",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930029-000246553",
    partNumber: "84930029",
    description: "CARD-NAVN DATA",
    supplierName: "TELENAV INC",
    supplierAddress: "FREMONT,CA",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "CA",
    supplierZip: "945397498",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930210-812947059",
    partNumber: "84930210",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84930211-812947059",
    partNumber: "84930211",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84930212-812947059",
    partNumber: "84930212",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84930213-812947059",
    partNumber: "84930213",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84930697-078849132",
    partNumber: "84930697",
    description: "BRACKET ASM-RDO",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930801-000259622",
    partNumber: "84930801",
    description: "MODULE ASM-AUTO",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930859-000195578",
    partNumber: "84930859",
    description: "MODULE ASM-ACSR",
    supplierName: "CONTINENTAL AUTOMOTIVE NOGALES SA",
    supplierAddress: "NOGALES,AZ",
    supplierStreet: "",
    supplierCity: "NOGALES",
    supplierState: "AZ",
    supplierZip: "856214505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930914-137355322",
    partNumber: "84930914",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930915-137355322",
    partNumber: "84930915",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930916-137355322",
    partNumber: "84930916",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930918-137355322",
    partNumber: "84930918",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930922-137355322",
    partNumber: "84930922",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930923-137355322",
    partNumber: "84930923",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930924-137355322",
    partNumber: "84930924",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930933-137355322",
    partNumber: "84930933",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930934-137355322",
    partNumber: "84930934",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930936-137355322",
    partNumber: "84930936",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930937-137355322",
    partNumber: "84930937",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930938-137355322",
    partNumber: "84930938",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930940-137355322",
    partNumber: "84930940",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930942-137355322",
    partNumber: "84930942",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930943-137355322",
    partNumber: "84930943",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930944-137355322",
    partNumber: "84930944",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930946-137355322",
    partNumber: "84930946",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930947-137355322",
    partNumber: "84930947",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930948-137355322",
    partNumber: "84930948",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930950-137355322",
    partNumber: "84930950",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930951-137355322",
    partNumber: "84930951",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930952-137355322",
    partNumber: "84930952",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930954-137355322",
    partNumber: "84930954",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930955-137355322",
    partNumber: "84930955",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930956-137355322",
    partNumber: "84930956",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930958-137355322",
    partNumber: "84930958",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930960-137355322",
    partNumber: "84930960",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930961-137355322",
    partNumber: "84930961",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930963-137355322",
    partNumber: "84930963",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930964-137355322",
    partNumber: "84930964",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930966-137355322",
    partNumber: "84930966",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930967-137355322",
    partNumber: "84930967",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930969-137355322",
    partNumber: "84930969",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930970-137355322",
    partNumber: "84930970",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930973-137355322",
    partNumber: "84930973",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930975-137355322",
    partNumber: "84930975",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930977-137355322",
    partNumber: "84930977",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930978-137355322",
    partNumber: "84930978",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930979-137355322",
    partNumber: "84930979",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930981-137355322",
    partNumber: "84930981",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930982-137355322",
    partNumber: "84930982",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930984-137355322",
    partNumber: "84930984",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930987-137355322",
    partNumber: "84930987",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930990-137355322",
    partNumber: "84930990",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84930992-137355322",
    partNumber: "84930992",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931000-137355322",
    partNumber: "84931000",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931006-137355322",
    partNumber: "84931006",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931007-137355322",
    partNumber: "84931007",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931009-137355322",
    partNumber: "84931009",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931010-137355322",
    partNumber: "84931010",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931012-137355322",
    partNumber: "84931012",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931014-137355322",
    partNumber: "84931014",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931016-137355322",
    partNumber: "84931016",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931017-137355322",
    partNumber: "84931017",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931019-137355322",
    partNumber: "84931019",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931021-137355322",
    partNumber: "84931021",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931022-137355322",
    partNumber: "84931022",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931024-137355322",
    partNumber: "84931024",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931025-137355322",
    partNumber: "84931025",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931027-137355322",
    partNumber: "84931027",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931028-137355322",
    partNumber: "84931028",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931030-137355322",
    partNumber: "84931030",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931031-137355322",
    partNumber: "84931031",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931033-137355322",
    partNumber: "84931033",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931034-137355322",
    partNumber: "84931034",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931037-137355322",
    partNumber: "84931037",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931039-137355322",
    partNumber: "84931039",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931040-137355322",
    partNumber: "84931040",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931042-137355322",
    partNumber: "84931042",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931044-137355322",
    partNumber: "84931044",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931046-137355322",
    partNumber: "84931046",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931048-137355322",
    partNumber: "84931048",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931050-137355322",
    partNumber: "84931050",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931052-137355322",
    partNumber: "84931052",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931054-137355322",
    partNumber: "84931054",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931056-137355322",
    partNumber: "84931056",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931058-137355322",
    partNumber: "84931058",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931060-137355322",
    partNumber: "84931060",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931061-137355322",
    partNumber: "84931061",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931063-137355322",
    partNumber: "84931063",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931065-137355322",
    partNumber: "84931065",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931066-137355322",
    partNumber: "84931066",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931068-137355322",
    partNumber: "84931068",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931069-137355322",
    partNumber: "84931069",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931070-137355322",
    partNumber: "84931070",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931072-137355322",
    partNumber: "84931072",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931073-137355322",
    partNumber: "84931073",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931075-137355322",
    partNumber: "84931075",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931076-137355322",
    partNumber: "84931076",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931078-137355322",
    partNumber: "84931078",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931079-137355322",
    partNumber: "84931079",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931081-137355322",
    partNumber: "84931081",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931082-137355322",
    partNumber: "84931082",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931084-137355322",
    partNumber: "84931084",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931086-137355322",
    partNumber: "84931086",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931087-137355322",
    partNumber: "84931087",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931089-137355322",
    partNumber: "84931089",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931090-137355322",
    partNumber: "84931090",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931091-137355322",
    partNumber: "84931091",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931093-137355322",
    partNumber: "84931093",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931094-137355322",
    partNumber: "84931094",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931095-137355322",
    partNumber: "84931095",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931097-137355322",
    partNumber: "84931097",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931098-137355322",
    partNumber: "84931098",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931099-137355322",
    partNumber: "84931099",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931101-137355322",
    partNumber: "84931101",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931102-137355322",
    partNumber: "84931102",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931103-137355322",
    partNumber: "84931103",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931105-137355322",
    partNumber: "84931105",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931106-137355322",
    partNumber: "84931106",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931111-137355322",
    partNumber: "84931111",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931112-137355322",
    partNumber: "84931112",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931113-137355322",
    partNumber: "84931113",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931115-137355322",
    partNumber: "84931115",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931116-137355322",
    partNumber: "84931116",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931117-137355322",
    partNumber: "84931117",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931119-137355322",
    partNumber: "84931119",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931120-137355322",
    partNumber: "84931120",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931121-137355322",
    partNumber: "84931121",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931123-137355322",
    partNumber: "84931123",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931124-137355322",
    partNumber: "84931124",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931125-137355322",
    partNumber: "84931125",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931127-137355322",
    partNumber: "84931127",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931131-137355322",
    partNumber: "84931131",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931191-933874026",
    partNumber: "84931191",
    description: "CARRIER ASM-DIF",
    supplierName: "ZF GAINESVILLE LLC",
    supplierAddress: "GAINESVILLE,GA",
    supplierStreet: "",
    supplierCity: "GAINESVILLE",
    supplierState: "GA",
    supplierZip: "305016860",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84931568-816508899",
    partNumber: "84931568",
    description: "TUBE ASM-AUTO L",
    supplierName: "VOSS AUTOMOTIVE MX S DE RL DE CV",
    supplierAddress: "ARTEAGA,CZ",
    supplierStreet: "",
    supplierCity: "ARTEAGA",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84931569-816508899",
    partNumber: "84931569",
    description: "TUBE ASM-AUTO L",
    supplierName: "VOSS AUTOMOTIVE MX S DE RL DE CV",
    supplierAddress: "ARTEAGA,CZ",
    supplierStreet: "",
    supplierCity: "ARTEAGA",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84931570-816508899",
    partNumber: "84931570",
    description: "TUBE ASM-AUTO L",
    supplierName: "VOSS AUTOMOTIVE MX S DE RL DE CV",
    supplierAddress: "ARTEAGA,CZ",
    supplierStreet: "",
    supplierCity: "ARTEAGA",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84931571-816508899",
    partNumber: "84931571",
    description: "TUBE ASM-AUTO L",
    supplierName: "VOSS AUTOMOTIVE MX S DE RL DE CV",
    supplierAddress: "ARTEAGA,CZ",
    supplierStreet: "",
    supplierCity: "ARTEAGA",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84931602-807813840",
    partNumber: "84931602",
    description: "MODULE ASM-VIDE",
    supplierName: "VALEO SWITCHES & DETECTION SYSTEMS",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84932265-000259622",
    partNumber: "84932265",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84932266-000259622",
    partNumber: "84932266",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84932267-000259622",
    partNumber: "84932267",
    description: "SPRING ASM-RR A",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84932435-117409100",
    partNumber: "84932435",
    description: "BEZEL-R/SEAT BE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84932441-117409100",
    partNumber: "84932441",
    description: "BEZEL-R/SEAT BE",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84933964-137355322",
    partNumber: "84933964",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84933965-137355322",
    partNumber: "84933965",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84933966-137355322",
    partNumber: "84933966",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84933972-812888257",
    partNumber: "84933972",
    description: "PLATE ASM-I/P C",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84933973-812888257",
    partNumber: "84933973",
    description: "PLATE ASM-I/P C",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84933979-042439265",
    partNumber: "84933979",
    description: "BRACKET ASM-EMI",
    supplierName: "MOTOR CITY STAMPING INC",
    supplierAddress: "CHESTERFIELD,MI",
    supplierStreet: "",
    supplierCity: "CHESTERFIELD",
    supplierState: "MI",
    supplierZip: "480512721",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84934279-000168013",
    partNumber: "84934279",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84934280-000168013",
    partNumber: "84934280",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84934281-000168013",
    partNumber: "84934281",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84934282-000168013",
    partNumber: "84934282",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84934283-000168013",
    partNumber: "84934283",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84934284-000168013",
    partNumber: "84934284",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84934781-812888257",
    partNumber: "84934781",
    description: "COVER ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934783-812888257",
    partNumber: "84934783",
    description: "COVER ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934785-812888257",
    partNumber: "84934785",
    description: "COVER ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934787-812888257",
    partNumber: "84934787",
    description: "COVER ASM-I/P S",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934950-814180139",
    partNumber: "84934950",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934951-814180139",
    partNumber: "84934951",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934952-814180139",
    partNumber: "84934952",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934954-814180139",
    partNumber: "84934954",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934957-814180139",
    partNumber: "84934957",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934958-814180139",
    partNumber: "84934958",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934959-814180139",
    partNumber: "84934959",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934960-814180139",
    partNumber: "84934960",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934965-814180139",
    partNumber: "84934965",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934968-814180139",
    partNumber: "84934968",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934971-814180139",
    partNumber: "84934971",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934974-814180139",
    partNumber: "84934974",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934979-814180139",
    partNumber: "84934979",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934981-814180139",
    partNumber: "84934981",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934984-814180139",
    partNumber: "84934984",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84934986-814180139",
    partNumber: "84934986",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935019-814180139",
    partNumber: "84935019",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935020-814180139",
    partNumber: "84935020",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935021-814180139",
    partNumber: "84935021",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935022-814180139",
    partNumber: "84935022",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935023-814180139",
    partNumber: "84935023",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935025-814180139",
    partNumber: "84935025",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935026-814180139",
    partNumber: "84935026",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935027-814180139",
    partNumber: "84935027",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935028-814180139",
    partNumber: "84935028",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935029-814180139",
    partNumber: "84935029",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935031-814180139",
    partNumber: "84935031",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935032-814180139",
    partNumber: "84935032",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935033-814180139",
    partNumber: "84935033",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935034-814180139",
    partNumber: "84935034",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935036-814180139",
    partNumber: "84935036",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935037-814180139",
    partNumber: "84935037",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935038-814180139",
    partNumber: "84935038",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935039-814180139",
    partNumber: "84935039",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84935064-201189391",
    partNumber: "84935064",
    description: "MODULE ASM-DIGI",
    supplierName: "VEONEER CANADA INC",
    supplierAddress: "MARKHAM,ON",
    supplierStreet: "",
    supplierCity: "MARKHAM",
    supplierState: "ON",
    supplierZip: "L3R 5C2",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84935462-005356878",
    partNumber: "84935462",
    description: "GEAR ASM-ELEC B",
    supplierName: "NEXTEER AUTOMOTIVE CORP-GENL OFFICE",
    supplierAddress: "SAGINAW,MI",
    supplierStreet: "",
    supplierCity: "SAGINAW",
    supplierState: "MI",
    supplierZip: "486019494",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84935464-005356878",
    partNumber: "84935464",
    description: "GEAR ASM-ELEC B",
    supplierName: "NEXTEER AUTOMOTIVE CORP-GENL OFFICE",
    supplierAddress: "SAGINAW,MI",
    supplierStreet: "",
    supplierCity: "SAGINAW",
    supplierState: "MI",
    supplierZip: "486019494",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84937153-607039732",
    partNumber: "84937153",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84937154-607039732",
    partNumber: "84937154",
    description: "HARNESS ASM-ENG",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84937158-816154785",
    partNumber: "84937158",
    description: "HOSE ASM-ENG OI",
    supplierName: "AUTOMOTIVE BEND DE MEXICO S DE RL D",
    supplierAddress: "VILLA DE REYES,SL",
    supplierStreet: "",
    supplierCity: "VILLA DE REYES",
    supplierState: "SL",
    supplierZip: "79526",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937695-814180139",
    partNumber: "84937695",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937696-814180139",
    partNumber: "84937696",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937697-814180139",
    partNumber: "84937697",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937698-814180139",
    partNumber: "84937698",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937700-814180139",
    partNumber: "84937700",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937701-814180139",
    partNumber: "84937701",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937702-814180139",
    partNumber: "84937702",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937703-814180139",
    partNumber: "84937703",
    description: "TENSIONER ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937715-814180139",
    partNumber: "84937715",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937716-814180139",
    partNumber: "84937716",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937717-814180139",
    partNumber: "84937717",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937719-814180139",
    partNumber: "84937719",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937720-814180139",
    partNumber: "84937720",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937721-814180139",
    partNumber: "84937721",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937798-006016810",
    partNumber: "84937798",
    description: "LABEL-TIRE COMP",
    supplierName: "GRAND RAPIDS LABEL CO",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "49505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84937937-808141266",
    partNumber: "84937937",
    description: "BAR ASM-RR BPR",
    supplierName: "SHAPE CORP",
    supplierAddress: "GRAND HAVEN,MI",
    supplierStreet: "",
    supplierCity: "GRAND HAVEN",
    supplierState: "MI",
    supplierZip: "494179428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84937938-808141266",
    partNumber: "84937938",
    description: "BAR ASM-RR BPR",
    supplierName: "SHAPE CORP",
    supplierAddress: "GRAND HAVEN,MI",
    supplierStreet: "",
    supplierCity: "GRAND HAVEN",
    supplierState: "MI",
    supplierZip: "494179428",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84937942-814180139",
    partNumber: "84937942",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937943-814180139",
    partNumber: "84937943",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937944-814180139",
    partNumber: "84937944",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937946-814180139",
    partNumber: "84937946",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937947-814180139",
    partNumber: "84937947",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84937948-814180139",
    partNumber: "84937948",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84938717-117409100",
    partNumber: "84938717",
    description: "REINFORCEMENT A",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939061-555343750",
    partNumber: "84939061",
    description: "MODULE ASM-I/P",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84939062-555343750",
    partNumber: "84939062",
    description: "MODULE ASM-I/P",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84939063-555343750",
    partNumber: "84939063",
    description: "MODULE ASM-I/P",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84939064-555343750",
    partNumber: "84939064",
    description: "MODULE ASM-I/P",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84939092-050614114",
    partNumber: "84939092",
    description: "HANGER ASM-EXH",
    supplierName: "AIRBOSS FLEXIBLE PRODUCTS CO",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939112-607039732",
    partNumber: "84939112",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939114-607039732",
    partNumber: "84939114",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939115-609623566",
    partNumber: "84939115",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939117-609623566",
    partNumber: "84939117",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939118-609623566",
    partNumber: "84939118",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939119-607039732",
    partNumber: "84939119",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939121-609623566",
    partNumber: "84939121",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939122-609623566",
    partNumber: "84939122",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939124-607039732",
    partNumber: "84939124",
    description: "HARNESS ASM-FRT",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939125-609623566",
    partNumber: "84939125",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939127-609623566",
    partNumber: "84939127",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939129-609623566",
    partNumber: "84939129",
    description: "HARNESS ASM-RR",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780457847",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939422-086663101",
    partNumber: "84939422",
    description: "BRACE-QTR INR P",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939423-086663101",
    partNumber: "84939423",
    description: "BRACE-QTR INR P",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84939654-812833912",
    partNumber: "84939654",
    description: "AIRBAG ASM-FRT",
    supplierName: "TRW OCCUPANT RESTRAINTS DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31137",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84939655-812833912",
    partNumber: "84939655",
    description: "AIRBAG ASM-FRT",
    supplierName: "TRW OCCUPANT RESTRAINTS DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31137",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84939656-812833912",
    partNumber: "84939656",
    description: "AIRBAG ASM-FRT",
    supplierName: "TRW OCCUPANT RESTRAINTS DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31137",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84939657-812833912",
    partNumber: "84939657",
    description: "AIRBAG ASM-FRT",
    supplierName: "TRW OCCUPANT RESTRAINTS DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31137",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84940579-932662133",
    partNumber: "84940579",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940580-932662133",
    partNumber: "84940580",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940581-932662133",
    partNumber: "84940581",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940582-932662133",
    partNumber: "84940582",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940583-932662133",
    partNumber: "84940583",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940584-932662133",
    partNumber: "84940584",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940585-932662133",
    partNumber: "84940585",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940692-188277016",
    partNumber: "84940692",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940693-188277016",
    partNumber: "84940693",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940694-188277016",
    partNumber: "84940694",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940695-188277016",
    partNumber: "84940695",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940696-188277016",
    partNumber: "84940696",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940697-188277016",
    partNumber: "84940697",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940698-188277016",
    partNumber: "84940698",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940699-188277016",
    partNumber: "84940699",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940704-080203455",
    partNumber: "84940704",
    description: "HOSE ASM-SUN RF",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84940705-080203455",
    partNumber: "84940705",
    description: "HOSE ASM-SUN RF",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84941658-000272310",
    partNumber: "84941658",
    description: "PLATE ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483262613",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84941659-000272310",
    partNumber: "84941659",
    description: "PLATE ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483262613",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84941660-000272310",
    partNumber: "84941660",
    description: "PLATE ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483262613",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84941661-000272310",
    partNumber: "84941661",
    description: "PLATE ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483262613",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84941845-812888257",
    partNumber: "84941845",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84941846-812888257",
    partNumber: "84941846",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84941847-812888257",
    partNumber: "84941847",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84941849-812888257",
    partNumber: "84941849",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84941851-812888257",
    partNumber: "84941851",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84941852-812888257",
    partNumber: "84941852",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84941853-812888257",
    partNumber: "84941853",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84942824-965355311",
    partNumber: "84942824",
    description: "HOSE ASM-AUX A/",
    supplierName: "HANON SYSTEMS NETHERLANDS COOP UA",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799360405",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84942825-965355311",
    partNumber: "84942825",
    description: "HOSE ASM-AUX A/",
    supplierName: "HANON SYSTEMS NETHERLANDS COOP UA",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799360405",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84942834-814814042",
    partNumber: "84942834",
    description: "HOSE ASM-RAD IN",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84942955-813016276",
    partNumber: "84942955",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84942956-813016276",
    partNumber: "84942956",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84942957-813016276",
    partNumber: "84942957",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84942958-813016276",
    partNumber: "84942958",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84942960-813016276",
    partNumber: "84942960",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84942961-813016276",
    partNumber: "84942961",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84942962-813016276",
    partNumber: "84942962",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84942964-813016276",
    partNumber: "84942964",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84942965-813016276",
    partNumber: "84942965",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84942966-813016276",
    partNumber: "84942966",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84942967-813016276",
    partNumber: "84942967",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84942969-813016276",
    partNumber: "84942969",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84943024-196138536",
    partNumber: "84943024",
    description: "CLUSTER ASM-INS",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943025-196138536",
    partNumber: "84943025",
    description: "CLUSTER ASM-INS",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943026-196138536",
    partNumber: "84943026",
    description: "CLUSTER ASM-INS",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943027-196138536",
    partNumber: "84943027",
    description: "CLUSTER ASM-INS",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943028-196138536",
    partNumber: "84943028",
    description: "CLUSTER ASM-INS",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943029-196138536",
    partNumber: "84943029",
    description: "CLUSTER ASM-INS",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943030-196138536",
    partNumber: "84943030",
    description: "CLUSTER ASM-INS",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943031-196138536",
    partNumber: "84943031",
    description: "CLUSTER ASM-INS",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "WILLIAMSTON,SC",
    supplierStreet: "",
    supplierCity: "WILLIAMSTON",
    supplierState: "SC",
    supplierZip: "29697",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943062-627272024",
    partNumber: "84943062",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943063-627272024",
    partNumber: "84943063",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943064-627272024",
    partNumber: "84943064",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943065-627272024",
    partNumber: "84943065",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943066-627272024",
    partNumber: "84943066",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943067-627272024",
    partNumber: "84943067",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943068-627272024",
    partNumber: "84943068",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943069-627272024",
    partNumber: "84943069",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943070-627272024",
    partNumber: "84943070",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943071-627272024",
    partNumber: "84943071",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943072-627272024",
    partNumber: "84943072",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943073-627272024",
    partNumber: "84943073",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943074-627272024",
    partNumber: "84943074",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943075-627272024",
    partNumber: "84943075",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943076-627272024",
    partNumber: "84943076",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943077-627272024",
    partNumber: "84943077",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943079-627272024",
    partNumber: "84943079",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943081-627272024",
    partNumber: "84943081",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943084-627272024",
    partNumber: "84943084",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943086-627272024",
    partNumber: "84943086",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943088-627272024",
    partNumber: "84943088",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943090-627272024",
    partNumber: "84943090",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943092-627272024",
    partNumber: "84943092",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943094-627272024",
    partNumber: "84943094",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943096-627272024",
    partNumber: "84943096",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943098-627272024",
    partNumber: "84943098",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943099-627272024",
    partNumber: "84943099",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943100-627272024",
    partNumber: "84943100",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943101-627272024",
    partNumber: "84943101",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943102-627272024",
    partNumber: "84943102",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943103-627272024",
    partNumber: "84943103",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943104-627272024",
    partNumber: "84943104",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943105-627272024",
    partNumber: "84943105",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943106-627272024",
    partNumber: "84943106",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943107-627272024",
    partNumber: "84943107",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943108-627272024",
    partNumber: "84943108",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943109-627272024",
    partNumber: "84943109",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943110-627272024",
    partNumber: "84943110",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943111-627272024",
    partNumber: "84943111",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943112-627272024",
    partNumber: "84943112",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943136-012839643",
    partNumber: "84943136",
    description: "RADIATOR ASM-EN",
    supplierName: "GM COMPONENT HOLDINGS LLC-LKPT PTC",
    supplierAddress: "LOCKPORT,NY",
    supplierStreet: "",
    supplierCity: "LOCKPORT",
    supplierState: "NY",
    supplierZip: "140941819",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943350-966669645",
    partNumber: "84943350",
    description: "DUCT ASM-A/CL O",
    supplierName: "UNDERCAR PRODUCTS GROUP INC",
    supplierAddress: "WYOMING,MI",
    supplierStreet: "",
    supplierCity: "WYOMING",
    supplierState: "MI",
    supplierZip: "49508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943436-000782613",
    partNumber: "84943436",
    description: "COMPARTMENT ASM",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84943778-812879120",
    partNumber: "84943778",
    description: "BRACKET-RDO SPK",
    supplierName: "US FARATHANE SA DE CV",
    supplierAddress: "APODACA,NL",
    supplierStreet: "",
    supplierCity: "APODACA",
    supplierState: "NL",
    supplierZip: "66634",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84944678-000782613",
    partNumber: "84944678",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84944679-000782613",
    partNumber: "84944679",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84944680-000782613",
    partNumber: "84944680",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84944681-000782613",
    partNumber: "84944681",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84944682-000782613",
    partNumber: "84944682",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84944683-000782613",
    partNumber: "84944683",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84944684-000782613",
    partNumber: "84944684",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84944685-000782613",
    partNumber: "84944685",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84944686-000782613",
    partNumber: "84944686",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84944687-000782613",
    partNumber: "84944687",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84944688-000782613",
    partNumber: "84944688",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84944689-000782613",
    partNumber: "84944689",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84944690-000782613",
    partNumber: "84944690",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84944691-000782613",
    partNumber: "84944691",
    description: "PANEL ASM-LOAD",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84944921-588352398",
    partNumber: "84944921",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84944922-588352398",
    partNumber: "84944922",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84944928-588352398",
    partNumber: "84944928",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84944929-588352398",
    partNumber: "84944929",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84944931-588352398",
    partNumber: "84944931",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945002-816657977",
    partNumber: "84945002",
    description: "PIPE ASM-FRT &",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945024-816657977",
    partNumber: "84945024",
    description: "PIPE ASM-FRT BR",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945263-812928414",
    partNumber: "84945263",
    description: "CASE ASM-TRFER",
    supplierName: "MAGNA POWERTRAIN DE MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945264-812928414",
    partNumber: "84945264",
    description: "CASE ASM-TRFER",
    supplierName: "MAGNA POWERTRAIN DE MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945379-000202266",
    partNumber: "84945379",
    description: "RECEPTACLE ASM-",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84945380-000202266",
    partNumber: "84945380",
    description: "RECEPTACLE ASM-",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84945381-000166991",
    partNumber: "84945381",
    description: "SENSOR ASM-FWD",
    supplierName: "CONTI TEMIC MICROELECTRONIC GMBH",
    supplierAddress: "DETROIT,MI",
    supplierStreet: "",
    supplierCity: "DETROIT",
    supplierState: "MI",
    supplierZip: "48234",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84945404-000782613",
    partNumber: "84945404",
    description: "COMPARTMENT ASM",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84945408-000241752",
    partNumber: "84945408",
    description: "CONTROL ASM-HTR",
    supplierName: "PREH INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780416852",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84945409-000241752",
    partNumber: "84945409",
    description: "CONTROL ASM-HTR",
    supplierName: "PREH INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780416852",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84945410-000241752",
    partNumber: "84945410",
    description: "CONTROL ASM-HTR",
    supplierName: "PREH INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780416852",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84945411-000241752",
    partNumber: "84945411",
    description: "CONTROL ASM-HTR",
    supplierName: "PREH INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780416852",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84945412-000241752",
    partNumber: "84945412",
    description: "CONTROL ASM-HTR",
    supplierName: "PREH INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780416852",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84945413-000241752",
    partNumber: "84945413",
    description: "CONTROL ASM-HTR",
    supplierName: "PREH INC",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "780416852",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84945898-814526588",
    partNumber: "84945898",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945899-814526588",
    partNumber: "84945899",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945901-814526588",
    partNumber: "84945901",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945903-814526588",
    partNumber: "84945903",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945904-814526588",
    partNumber: "84945904",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945905-814526588",
    partNumber: "84945905",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945907-814526588",
    partNumber: "84945907",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945910-814526588",
    partNumber: "84945910",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945911-814526588",
    partNumber: "84945911",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945912-814526588",
    partNumber: "84945912",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945913-814526588",
    partNumber: "84945913",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945914-814526588",
    partNumber: "84945914",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945915-814526588",
    partNumber: "84945915",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945917-814526588",
    partNumber: "84945917",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945918-814526588",
    partNumber: "84945918",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945919-814526588",
    partNumber: "84945919",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945920-814526588",
    partNumber: "84945920",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945924-814526588",
    partNumber: "84945924",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84945929-814526588",
    partNumber: "84945929",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84946497-421251063",
    partNumber: "84946497",
    description: "MODULE ASM-RDO",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84946606-183117860",
    partNumber: "84946606",
    description: "GENERATOR ASM-.",
    supplierName: "MITSUBISHI ELECTRIC AUTOMOTIVE AMER",
    supplierAddress: "MASON,OH",
    supplierStreet: "",
    supplierCity: "MASON",
    supplierState: "OH",
    supplierZip: "450408344",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84946959-555343750",
    partNumber: "84946959",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84947791-080786860",
    partNumber: "84947791",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84947792-080786860",
    partNumber: "84947792",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84947793-080786860",
    partNumber: "84947793",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84947794-080786860",
    partNumber: "84947794",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84947815-816660435",
    partNumber: "84947815",
    description: "WHEEL-SPA",
    supplierName: "MAXION WHEELS DE",
    supplierAddress: "SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "78395",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84947816-000782569",
    partNumber: "84947816",
    description: "WHEEL-SPA",
    supplierName: "CITIC DICASTAL CO LTD",
    supplierAddress: "COLOMA,MI",
    supplierStreet: "",
    supplierCity: "COLOMA",
    supplierState: "MI",
    supplierZip: "490389471",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84947958-080316510",
    partNumber: "84947958",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84947969-080316510",
    partNumber: "84947969",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84948289-000166991",
    partNumber: "84948289",
    description: "SENSOR ASM-FWD",
    supplierName: "CONTI TEMIC MICROELECTRONIC GMBH",
    supplierAddress: "DETROIT,MI",
    supplierStreet: "",
    supplierCity: "DETROIT",
    supplierState: "MI",
    supplierZip: "48234",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84948297-739169217",
    partNumber: "84948297",
    description: "MODULE ASM-NIGH",
    supplierName: "VEONEER FRANCE SAS",
    supplierAddress: "ROUVRAY,76",
    supplierStreet: "",
    supplierCity: "ROUVRAY",
    supplierState: "76",
    supplierZip: "76800",
    supplierCountry: "FR",
  },
  {
    _id: "parts-gm-84948502-966669645",
    partNumber: "84948502",
    description: "CLEANER ASM-AIR",
    supplierName: "UNDERCAR PRODUCTS GROUP INC",
    supplierAddress: "WYOMING,MI",
    supplierStreet: "",
    supplierCity: "WYOMING",
    supplierState: "MI",
    supplierZip: "49508",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84948589-079948011",
    partNumber: "84948589",
    description: "LAMP ASM-F/FLR",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84948590-079948011",
    partNumber: "84948590",
    description: "LAMP ASM-F/FLR",
    supplierName: "AGM AUTOMOTIVE LLC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483122618",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84949472-607039732",
    partNumber: "84949472",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84949473-607039732",
    partNumber: "84949473",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84949525-331043633",
    partNumber: "84949525",
    description: "MODULE ASM-RR D",
    supplierName: "ZF FRIEDRICHSHAFEN AG",
    supplierAddress: "GOTHA,TH",
    supplierStreet: "",
    supplierCity: "GOTHA",
    supplierState: "TH",
    supplierZip: "99867",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-84950138-785629952",
    partNumber: "84950138",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950139-785629952",
    partNumber: "84950139",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950140-785629952",
    partNumber: "84950140",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950141-785629952",
    partNumber: "84950141",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950142-785629952",
    partNumber: "84950142",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950143-785629952",
    partNumber: "84950143",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950144-785629952",
    partNumber: "84950144",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950145-785629952",
    partNumber: "84950145",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950148-785629952",
    partNumber: "84950148",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950149-785629952",
    partNumber: "84950149",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950150-785629952",
    partNumber: "84950150",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950151-785629952",
    partNumber: "84950151",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950165-000259622",
    partNumber: "84950165",
    description: "MODULE ASM-AUTO",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950201-116973138",
    partNumber: "84950201",
    description: "CAMERA ASM-FV",
    supplierName: "MAGNA ELECTRONICS INC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950202-116973138",
    partNumber: "84950202",
    description: "CAMERA ASM-FV",
    supplierName: "MAGNA ELECTRONICS INC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950346-807813840",
    partNumber: "84950346",
    description: "MODULE ASM-VIDE",
    supplierName: "VALEO SWITCHES & DETECTION SYSTEMS",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84950879-807813840",
    partNumber: "84950879",
    description: "MODULE ASM-VIDE",
    supplierName: "VALEO SWITCHES & DETECTION SYSTEMS",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951350-812928414",
    partNumber: "84951350",
    description: "MODULE ASM-TRFE",
    supplierName: "MAGNA POWERTRAIN DE MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84951592-253988547",
    partNumber: "84951592",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84951593-253988547",
    partNumber: "84951593",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84951594-253988547",
    partNumber: "84951594",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84951595-253988547",
    partNumber: "84951595",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84951669-969789283",
    partNumber: "84951669",
    description: "BAFFLE-RAD AIR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951754-005356878",
    partNumber: "84951754",
    description: "GEAR ASM-ELEC B",
    supplierName: "NEXTEER AUTOMOTIVE CORP-GENL OFFICE",
    supplierAddress: "SAGINAW,MI",
    supplierStreet: "",
    supplierCity: "SAGINAW",
    supplierState: "MI",
    supplierZip: "486019494",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951755-005356878",
    partNumber: "84951755",
    description: "GEAR ASM-ELEC B",
    supplierName: "NEXTEER AUTOMOTIVE CORP-GENL OFFICE",
    supplierAddress: "SAGINAW,MI",
    supplierStreet: "",
    supplierCity: "SAGINAW",
    supplierState: "MI",
    supplierZip: "486019494",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951832-137355322",
    partNumber: "84951832",
    description: "CONTROL ASM-HTR",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951833-137355322",
    partNumber: "84951833",
    description: "CONTROL ASM-HTR",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951834-137355322",
    partNumber: "84951834",
    description: "CONTROL ASM-HTR",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951835-137355322",
    partNumber: "84951835",
    description: "CONTROL ASM-HTR",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951836-137355322",
    partNumber: "84951836",
    description: "CONTROL ASM-HTR",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951972-056894041",
    partNumber: "84951972",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951973-056894041",
    partNumber: "84951973",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951974-056894041",
    partNumber: "84951974",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951975-056894041",
    partNumber: "84951975",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951976-056894041",
    partNumber: "84951976",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951977-056894041",
    partNumber: "84951977",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951978-056894041",
    partNumber: "84951978",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951979-056894041",
    partNumber: "84951979",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951980-056894041",
    partNumber: "84951980",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84951981-056894041",
    partNumber: "84951981",
    description: "SPOILER ASM-R/E",
    supplierName: "ABC INOAC EXTERIOR SYSTEMS LLC",
    supplierAddress: "FREMONT,OH",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "OH",
    supplierZip: "434201437",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84952392-812815730",
    partNumber: "84952392",
    description: "HANDLE ASM-FRT",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84952393-812815730",
    partNumber: "84952393",
    description: "COVER ASM-RR S/",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84952394-812815730",
    partNumber: "84952394",
    description: "BEZEL ASM-FRT S",
    supplierName: "ADAC-STRATTEC DE MEXICO S DE RL CV",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84953322-080786860",
    partNumber: "84953322",
    description: "CONSOLE ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84953336-000202266",
    partNumber: "84953336",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84953337-000202266",
    partNumber: "84953337",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84953338-000202266",
    partNumber: "84953338",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84953339-000202266",
    partNumber: "84953339",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84953340-000202266",
    partNumber: "84953340",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84953341-000202266",
    partNumber: "84953341",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84953342-000202266",
    partNumber: "84953342",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84953444-812895704",
    partNumber: "84953444",
    description: "BRACKET ASM-I/P",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84953595-969789283",
    partNumber: "84953595",
    description: "BRACKET ASM-RR",
    supplierName: "US FARATHANE HOLDINGS CORP",
    supplierAddress: "AUSTIN,TX",
    supplierStreet: "",
    supplierCity: "AUSTIN",
    supplierState: "TX",
    supplierZip: "787539708",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84954332-005356662",
    partNumber: "84954332",
    description: "REINFORCEMENT-H",
    supplierName: "GM METAL FAB-FLINT",
    supplierAddress: "FLINT,MI",
    supplierStreet: "",
    supplierCity: "FLINT",
    supplierState: "MI",
    supplierZip: "485530001",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84954480-000246553",
    partNumber: "84954480",
    description: "CARD-NAVN DATA",
    supplierName: "TELENAV INC",
    supplierAddress: "FREMONT,CA",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "CA",
    supplierZip: "945397498",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84954481-000246553",
    partNumber: "84954481",
    description: "CARD-NAVN DATA",
    supplierName: "TELENAV INC",
    supplierAddress: "FREMONT,CA",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "CA",
    supplierZip: "945397498",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84954483-000246553",
    partNumber: "84954483",
    description: "CARD-NAVN DATA",
    supplierName: "TELENAV INC",
    supplierAddress: "FREMONT,CA",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "CA",
    supplierZip: "945397498",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84954484-000246553",
    partNumber: "84954484",
    description: "CARD-NAVN DATA",
    supplierName: "TELENAV INC",
    supplierAddress: "FREMONT,CA",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "CA",
    supplierZip: "945397498",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84954496-812888257",
    partNumber: "84954496",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84954498-812888257",
    partNumber: "84954498",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84954499-812888257",
    partNumber: "84954499",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84954501-812888257",
    partNumber: "84954501",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84954502-812888257",
    partNumber: "84954502",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84954503-812888257",
    partNumber: "84954503",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84955078-050150481",
    partNumber: "84955078",
    description: "SHIELD-U/B SPH",
    supplierName: "CHALLENGE MFG COMPANY LLC",
    supplierAddress: "WALKER,MI",
    supplierStreet: "",
    supplierCity: "WALKER",
    supplierState: "MI",
    supplierZip: "495341323",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84955338-813016276",
    partNumber: "84955338",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84955339-813016276",
    partNumber: "84955339",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84955340-813016276",
    partNumber: "84955340",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84955342-813016276",
    partNumber: "84955342",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84955343-813016276",
    partNumber: "84955343",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84955344-813016276",
    partNumber: "84955344",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84955346-813016276",
    partNumber: "84955346",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84955348-812888257",
    partNumber: "84955348",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84955349-812888257",
    partNumber: "84955349",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84955350-812888257",
    partNumber: "84955350",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84955352-812888257",
    partNumber: "84955352",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84955354-812888257",
    partNumber: "84955354",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84955355-812888257",
    partNumber: "84955355",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84955356-812888257",
    partNumber: "84955356",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84955602-809870855",
    partNumber: "84955602",
    description: "BASE ASM-F/FLR",
    supplierName: "BROSE NEW BOSTON INC",
    supplierAddress: "NEW BOSTON,MI",
    supplierStreet: "",
    supplierCity: "NEW BOSTON",
    supplierState: "MI",
    supplierZip: "481649183",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84955603-809870855",
    partNumber: "84955603",
    description: "BASE ASM-F/FLR",
    supplierName: "BROSE NEW BOSTON INC",
    supplierAddress: "NEW BOSTON,MI",
    supplierStreet: "",
    supplierCity: "NEW BOSTON",
    supplierState: "MI",
    supplierZip: "481649183",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84956401-814604781",
    partNumber: "84956401",
    description: "HOSE ASM-FRT BR",
    supplierName: "FLEXITECH AUTOMOTIVE MEXICO S DE RL",
    supplierAddress: "SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "78395",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956403-814604781",
    partNumber: "84956403",
    description: "HOSE ASM-FRT BR",
    supplierName: "FLEXITECH AUTOMOTIVE MEXICO S DE RL",
    supplierAddress: "SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "78395",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956404-814604781",
    partNumber: "84956404",
    description: "HOSE ASM-FRT BR",
    supplierName: "FLEXITECH AUTOMOTIVE MEXICO S DE RL",
    supplierAddress: "SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "78395",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956405-814604781",
    partNumber: "84956405",
    description: "HOSE ASM-FRT BR",
    supplierName: "FLEXITECH AUTOMOTIVE MEXICO S DE RL",
    supplierAddress: "SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "78395",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956406-814604781",
    partNumber: "84956406",
    description: "HOSE ASM-RR BRK",
    supplierName: "FLEXITECH AUTOMOTIVE MEXICO S DE RL",
    supplierAddress: "SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "78395",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956407-814604781",
    partNumber: "84956407",
    description: "HOSE ASM-RR BRK",
    supplierName: "FLEXITECH AUTOMOTIVE MEXICO S DE RL",
    supplierAddress: "SAN LUIS POTOSI,SL",
    supplierStreet: "",
    supplierCity: "SAN LUIS POTOSI",
    supplierState: "SL",
    supplierZip: "78395",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956534-607039732",
    partNumber: "84956534",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84956537-607039732",
    partNumber: "84956537",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84956608-607039732",
    partNumber: "84956608",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84956617-607039732",
    partNumber: "84956617",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84956619-607039732",
    partNumber: "84956619",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84956622-607039732",
    partNumber: "84956622",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84956625-607039732",
    partNumber: "84956625",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84956654-607039732",
    partNumber: "84956654",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84956657-607039732",
    partNumber: "84956657",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84956662-607039732",
    partNumber: "84956662",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84956664-607039732",
    partNumber: "84956664",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84956670-607039732",
    partNumber: "84956670",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84956675-607039732",
    partNumber: "84956675",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84956684-607039732",
    partNumber: "84956684",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84956704-588352398",
    partNumber: "84956704",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956705-588352398",
    partNumber: "84956705",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956706-588352398",
    partNumber: "84956706",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956707-588352398",
    partNumber: "84956707",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956710-588352398",
    partNumber: "84956710",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956711-588352398",
    partNumber: "84956711",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956712-588352398",
    partNumber: "84956712",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956713-588352398",
    partNumber: "84956713",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956771-242059041",
    partNumber: "84956771",
    description: "REINFORCEMENT A",
    supplierName: "ROYAL AUTOMOTIVE GROUP LTD",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84956772-242059041",
    partNumber: "84956772",
    description: "REINFORCEMENT A",
    supplierName: "ROYAL AUTOMOTIVE GROUP LTD",
    supplierAddress: "MISSISSAUGA,ON",
    supplierStreet: "",
    supplierCity: "MISSISSAUGA",
    supplierState: "ON",
    supplierZip: "L4V 1L1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84956854-812331239",
    partNumber: "84956854",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956855-812331239",
    partNumber: "84956855",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956858-812331239",
    partNumber: "84956858",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956859-812331239",
    partNumber: "84956859",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956862-812331239",
    partNumber: "84956862",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956863-812331239",
    partNumber: "84956863",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956866-812331239",
    partNumber: "84956866",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956867-812331239",
    partNumber: "84956867",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956870-812331239",
    partNumber: "84956870",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956871-812331239",
    partNumber: "84956871",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956874-812331239",
    partNumber: "84956874",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956875-812331239",
    partNumber: "84956875",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956878-812331239",
    partNumber: "84956878",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956879-812331239",
    partNumber: "84956879",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956882-812331239",
    partNumber: "84956882",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956883-812331239",
    partNumber: "84956883",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956894-812331239",
    partNumber: "84956894",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956895-812331239",
    partNumber: "84956895",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956898-812331239",
    partNumber: "84956898",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956899-812331239",
    partNumber: "84956899",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956902-812331239",
    partNumber: "84956902",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956903-812331239",
    partNumber: "84956903",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956906-812331239",
    partNumber: "84956906",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956907-812331239",
    partNumber: "84956907",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956910-812331239",
    partNumber: "84956910",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956911-812331239",
    partNumber: "84956911",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956918-812331239",
    partNumber: "84956918",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956919-812331239",
    partNumber: "84956919",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956922-812331239",
    partNumber: "84956922",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956923-812331239",
    partNumber: "84956923",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956926-812331239",
    partNumber: "84956926",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84956927-812331239",
    partNumber: "84956927",
    description: "WINDOW ASM-BODY",
    supplierName: "SAINT GOBAIN MEXICO SA DE CV",
    supplierAddress: "AYALA MORELOS,MR",
    supplierStreet: "",
    supplierCity: "AYALA MORELOS",
    supplierState: "MR",
    supplierZip: "62715",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957509-555343750",
    partNumber: "84957509",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84957794-813016276",
    partNumber: "84957794",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957795-813016276",
    partNumber: "84957795",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957796-813016276",
    partNumber: "84957796",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957797-813016276",
    partNumber: "84957797",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957799-813016276",
    partNumber: "84957799",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957801-813016276",
    partNumber: "84957801",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957802-813016276",
    partNumber: "84957802",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957803-813016276",
    partNumber: "84957803",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957804-813016276",
    partNumber: "84957804",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957807-813016276",
    partNumber: "84957807",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957808-813016276",
    partNumber: "84957808",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957809-813016276",
    partNumber: "84957809",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957849-253988547",
    partNumber: "84957849",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84957850-253988547",
    partNumber: "84957850",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84957851-253988547",
    partNumber: "84957851",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84957852-253988547",
    partNumber: "84957852",
    description: "MOLDING ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84957872-812888257",
    partNumber: "84957872",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957873-812888257",
    partNumber: "84957873",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957874-812888257",
    partNumber: "84957874",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957875-812888257",
    partNumber: "84957875",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957877-812888257",
    partNumber: "84957877",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957878-812888257",
    partNumber: "84957878",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957880-812888257",
    partNumber: "84957880",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957886-812888257",
    partNumber: "84957886",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957887-812888257",
    partNumber: "84957887",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957888-812888257",
    partNumber: "84957888",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957890-812888257",
    partNumber: "84957890",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957891-812888257",
    partNumber: "84957891",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84957892-812888257",
    partNumber: "84957892",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84958557-812806857",
    partNumber: "84958557",
    description: "AXLE ASM-FRT",
    supplierName: "AMERICAN AXLE & MFG DE MEX S DE RL",
    supplierAddress: "SILAO,GJ",
    supplierStreet: "",
    supplierCity: "SILAO",
    supplierState: "GJ",
    supplierZip: "36100",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84958989-812888257",
    partNumber: "84958989",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84958990-812888257",
    partNumber: "84958990",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84958991-812888257",
    partNumber: "84958991",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84958993-812888257",
    partNumber: "84958993",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84958995-812888257",
    partNumber: "84958995",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84959379-000202266",
    partNumber: "84959379",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84959380-000202266",
    partNumber: "84959380",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84959382-000202266",
    partNumber: "84959382",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84959394-084668367",
    partNumber: "84959394",
    description: "COVER ASM-ENG W",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "BROOKHAVEN,MS",
    supplierStreet: "",
    supplierCity: "BROOKHAVEN",
    supplierState: "MS",
    supplierZip: "396018951",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84959515-253988547",
    partNumber: "84959515",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84959516-253988547",
    partNumber: "84959516",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84959517-253988547",
    partNumber: "84959517",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84959518-253988547",
    partNumber: "84959518",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84959519-253988547",
    partNumber: "84959519",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84959520-253988547",
    partNumber: "84959520",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84959751-080779273",
    partNumber: "84959751",
    description: "CABLE ASM-STRTR",
    supplierName: "TIANHAI ELECTRIC NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065240",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960563-005476700",
    partNumber: "84960563",
    description: "MODULE ASM-ACTI",
    supplierName: "ZF ACTIVE SAFETY AND ELECTRONICS US",
    supplierAddress: "MARSHALL,IL",
    supplierStreet: "",
    supplierCity: "MARSHALL",
    supplierState: "IL",
    supplierZip: "624411854",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960805-080786860",
    partNumber: "84960805",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960806-080786860",
    partNumber: "84960806",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960807-080786860",
    partNumber: "84960807",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960808-080786860",
    partNumber: "84960808",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960809-080786860",
    partNumber: "84960809",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960810-080786860",
    partNumber: "84960810",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960811-080786860",
    partNumber: "84960811",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960812-080786860",
    partNumber: "84960812",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960814-080786860",
    partNumber: "84960814",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960816-080786860",
    partNumber: "84960816",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960817-080786860",
    partNumber: "84960817",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960818-080786860",
    partNumber: "84960818",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960819-080786860",
    partNumber: "84960819",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960822-080786860",
    partNumber: "84960822",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960823-080786860",
    partNumber: "84960823",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960824-080786860",
    partNumber: "84960824",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960825-080786860",
    partNumber: "84960825",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960826-080786860",
    partNumber: "84960826",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960827-080786860",
    partNumber: "84960827",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960830-080786860",
    partNumber: "84960830",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960831-080786860",
    partNumber: "84960831",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960833-080786860",
    partNumber: "84960833",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960835-080786860",
    partNumber: "84960835",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960836-080786860",
    partNumber: "84960836",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960838-080786860",
    partNumber: "84960838",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960839-080786860",
    partNumber: "84960839",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960840-080786860",
    partNumber: "84960840",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960841-080786860",
    partNumber: "84960841",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960843-080786860",
    partNumber: "84960843",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960846-080786860",
    partNumber: "84960846",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960847-080786860",
    partNumber: "84960847",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960849-080786860",
    partNumber: "84960849",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960850-080786860",
    partNumber: "84960850",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960852-080786860",
    partNumber: "84960852",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960854-080786860",
    partNumber: "84960854",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960855-080786860",
    partNumber: "84960855",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960856-080786860",
    partNumber: "84960856",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960857-080786860",
    partNumber: "84960857",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960858-080786860",
    partNumber: "84960858",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960859-080786860",
    partNumber: "84960859",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960861-080786860",
    partNumber: "84960861",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960863-080786860",
    partNumber: "84960863",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960864-080786860",
    partNumber: "84960864",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960865-080786860",
    partNumber: "84960865",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960866-080786860",
    partNumber: "84960866",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960867-080786860",
    partNumber: "84960867",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960870-080786860",
    partNumber: "84960870",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960871-080786860",
    partNumber: "84960871",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960872-080786860",
    partNumber: "84960872",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960873-080786860",
    partNumber: "84960873",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84960900-812905909",
    partNumber: "84960900",
    description: "BRACKET ASM-ELE",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84960901-812905909",
    partNumber: "84960901",
    description: "BRACKET ASM-TRL",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84961583-809870855",
    partNumber: "84961583",
    description: "BASE ASM-F/FLR",
    supplierName: "BROSE NEW BOSTON INC",
    supplierAddress: "NEW BOSTON,MI",
    supplierStreet: "",
    supplierCity: "NEW BOSTON",
    supplierState: "MI",
    supplierZip: "481649183",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84961584-809870855",
    partNumber: "84961584",
    description: "BASE ASM-F/FLR",
    supplierName: "BROSE NEW BOSTON INC",
    supplierAddress: "NEW BOSTON,MI",
    supplierStreet: "",
    supplierCity: "NEW BOSTON",
    supplierState: "MI",
    supplierZip: "481649183",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84961717-816657977",
    partNumber: "84961717",
    description: "HOSE ASM-FUEL F",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84961718-816657977",
    partNumber: "84961718",
    description: "HOSE ASM-FUEL F",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84961906-005050406",
    partNumber: "84961906",
    description: "WINDOW ASM-R/CM",
    supplierName: "PILKINGTON NORTH AMERICA INC",
    supplierAddress: "ROSSFORD,OH",
    supplierStreet: "",
    supplierCity: "ROSSFORD",
    supplierState: "OH",
    supplierZip: "434601215",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84961907-005050406",
    partNumber: "84961907",
    description: "WINDOW ASM-R/CM",
    supplierName: "PILKINGTON NORTH AMERICA INC",
    supplierAddress: "ROSSFORD,OH",
    supplierStreet: "",
    supplierCity: "ROSSFORD",
    supplierState: "OH",
    supplierZip: "434601215",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84962046-555343750",
    partNumber: "84962046",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84963072-545324639",
    partNumber: "84963072",
    description: "CORD ASM-ENG CO",
    supplierName: "HEBI THB INTERNATIONAL ELECTRIC CO",
    supplierAddress: "HEBI,41",
    supplierStreet: "",
    supplierCity: "HEBI",
    supplierState: "41",
    supplierZip: "458030",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84963975-812888257",
    partNumber: "84963975",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84963976-812888257",
    partNumber: "84963976",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84963978-812888257",
    partNumber: "84963978",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84963979-812888257",
    partNumber: "84963979",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84963980-812888257",
    partNumber: "84963980",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84964791-812246809",
    partNumber: "84964791",
    description: "HOSE ASM-R/AXL",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84964792-812246809",
    partNumber: "84964792",
    description: "HOSE ASM-R/AXL",
    supplierName: "AKWEL JUAREZ MEXICO SA DE CV",
    supplierAddress: "CD JUAREZ,CI",
    supplierStreet: "",
    supplierCity: "CD JUAREZ",
    supplierState: "CI",
    supplierZip: "32573",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84965149-816508899",
    partNumber: "84965149",
    description: "PIPE ASM-EMIS R",
    supplierName: "VOSS AUTOMOTIVE MX S DE RL DE CV",
    supplierAddress: "ARTEAGA,CZ",
    supplierStreet: "",
    supplierCity: "ARTEAGA",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84965150-816508899",
    partNumber: "84965150",
    description: "PIPE ASM-EMIS R",
    supplierName: "VOSS AUTOMOTIVE MX S DE RL DE CV",
    supplierAddress: "ARTEAGA,CZ",
    supplierStreet: "",
    supplierCity: "ARTEAGA",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84965230-071556752",
    partNumber: "84965230",
    description: "CAMERA-DRVR MON",
    supplierName: "KEY SAFETY RESTRAINT SYSTEMS INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "78251",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84965379-812833912",
    partNumber: "84965379",
    description: "AIRBAG ASM-I/P",
    supplierName: "TRW OCCUPANT RESTRAINTS DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31137",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84965381-812833912",
    partNumber: "84965381",
    description: "AIRBAG ASM-I/P",
    supplierName: "TRW OCCUPANT RESTRAINTS DE",
    supplierAddress: "CHIHUAHUA,CI",
    supplierStreet: "",
    supplierCity: "CHIHUAHUA",
    supplierState: "CI",
    supplierZip: "31137",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84965703-812814079",
    partNumber: "84965703",
    description: "TANK ASM-EMIS R",
    supplierName: "PLASTIC OMNIUM AUTO INERGY",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84965728-795124908",
    partNumber: "84965728",
    description: "CUSHION ASM-BOD",
    supplierName: "VIBRACOUSTIC USA INC",
    supplierAddress: "MORGANFIELD,KY",
    supplierStreet: "",
    supplierCity: "MORGANFIELD",
    supplierState: "KY",
    supplierZip: "424376622",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84965729-795124908",
    partNumber: "84965729",
    description: "CUSHION ASM-BOD",
    supplierName: "VIBRACOUSTIC USA INC",
    supplierAddress: "MORGANFIELD,KY",
    supplierStreet: "",
    supplierCity: "MORGANFIELD",
    supplierState: "KY",
    supplierZip: "424376622",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84965730-795124908",
    partNumber: "84965730",
    description: "CUSHION ASM-BOD",
    supplierName: "VIBRACOUSTIC USA INC",
    supplierAddress: "MORGANFIELD,KY",
    supplierStreet: "",
    supplierCity: "MORGANFIELD",
    supplierState: "KY",
    supplierZip: "424376622",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84965731-795124908",
    partNumber: "84965731",
    description: "CUSHION ASM-BOD",
    supplierName: "VIBRACOUSTIC USA INC",
    supplierAddress: "MORGANFIELD,KY",
    supplierStreet: "",
    supplierCity: "MORGANFIELD",
    supplierState: "KY",
    supplierZip: "424376622",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966027-086663101",
    partNumber: "84966027",
    description: "GUTTER-BODY SI",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966028-086663101",
    partNumber: "84966028",
    description: "GUTTER-BODY SI",
    supplierName: "GM METAL FAB-PARMA PLT",
    supplierAddress: "PARMA,OH",
    supplierStreet: "",
    supplierCity: "PARMA",
    supplierState: "OH",
    supplierZip: "44131",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966239-607039732",
    partNumber: "84966239",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966240-607039732",
    partNumber: "84966240",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966241-607039732",
    partNumber: "84966241",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966242-607039732",
    partNumber: "84966242",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966243-607039732",
    partNumber: "84966243",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966244-607039732",
    partNumber: "84966244",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966245-607039732",
    partNumber: "84966245",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966246-607039732",
    partNumber: "84966246",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966247-607039732",
    partNumber: "84966247",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966248-607039732",
    partNumber: "84966248",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966250-607039732",
    partNumber: "84966250",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966251-607039732",
    partNumber: "84966251",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966252-607039732",
    partNumber: "84966252",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966253-607039732",
    partNumber: "84966253",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966256-607039732",
    partNumber: "84966256",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966257-607039732",
    partNumber: "84966257",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966260-607039732",
    partNumber: "84966260",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966261-607039732",
    partNumber: "84966261",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966262-607039732",
    partNumber: "84966262",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966264-607039732",
    partNumber: "84966264",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966265-607039732",
    partNumber: "84966265",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966267-607039732",
    partNumber: "84966267",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966268-607039732",
    partNumber: "84966268",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966269-607039732",
    partNumber: "84966269",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966273-607039732",
    partNumber: "84966273",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966274-607039732",
    partNumber: "84966274",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966275-607039732",
    partNumber: "84966275",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966318-080779273",
    partNumber: "84966318",
    description: "CABLE ASM-AUX B",
    supplierName: "TIANHAI ELECTRIC NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065240",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966442-081232109",
    partNumber: "84966442",
    description: "MODULE ASM-STRG",
    supplierName: "BCS AUTOMOTIVE INTERFACE SOLUTIONS",
    supplierAddress: "PHARR,TX",
    supplierStreet: "",
    supplierCity: "PHARR",
    supplierState: "TX",
    supplierZip: "785777294",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84966444-081232109",
    partNumber: "84966444",
    description: "MODULE ASM-STRG",
    supplierName: "BCS AUTOMOTIVE INTERFACE SOLUTIONS",
    supplierAddress: "PHARR,TX",
    supplierStreet: "",
    supplierCity: "PHARR",
    supplierState: "TX",
    supplierZip: "785777294",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84967266-080786860",
    partNumber: "84967266",
    description: "CARRIER ASM-F/F",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84967433-812888257",
    partNumber: "84967433",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84967434-812888257",
    partNumber: "84967434",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84967435-812888257",
    partNumber: "84967435",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84967436-812888257",
    partNumber: "84967436",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84967833-421176950",
    partNumber: "84967833",
    description: "MODULE ASM-WIRE",
    supplierName: "HITACHI-LG DATA STORAGE HUIZHOU LTD",
    supplierAddress: "HUIZHOU,44",
    supplierStreet: "",
    supplierCity: "HUIZHOU",
    supplierState: "44",
    supplierZip: "516000",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84969405-080445381",
    partNumber: "84969405",
    description: "ACTUATOR ASM-L/",
    supplierName: "EDSCHA AUTOMOTIVE MICHIGAN INC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483264315",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84969406-080445381",
    partNumber: "84969406",
    description: "STRUT ASM-L/GAT",
    supplierName: "EDSCHA AUTOMOTIVE MICHIGAN INC",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483264315",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84969436-022687246",
    partNumber: "84969436",
    description: "COVER-L/GATE IN",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84969437-022687246",
    partNumber: "84969437",
    description: "COVER-L/GATE IN",
    supplierName: "MAYSER USA INC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481100000",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84969993-965355311",
    partNumber: "84969993",
    description: "HOSE ASM-AUX HT",
    supplierName: "HANON SYSTEMS NETHERLANDS COOP UA",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799360405",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84969994-965355311",
    partNumber: "84969994",
    description: "HOSE ASM-AUX HT",
    supplierName: "HANON SYSTEMS NETHERLANDS COOP UA",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799360405",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971077-079777903",
    partNumber: "84971077",
    description: "SENSOR ASM-VEH",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "SUMMERVILLE,SC",
    supplierStreet: "",
    supplierCity: "SUMMERVILLE",
    supplierState: "SC",
    supplierZip: "294837415",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971078-080786860",
    partNumber: "84971078",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971080-080786860",
    partNumber: "84971080",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971082-080786860",
    partNumber: "84971082",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971083-080786860",
    partNumber: "84971083",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971085-080786860",
    partNumber: "84971085",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971086-080786860",
    partNumber: "84971086",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971087-080786860",
    partNumber: "84971087",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971089-080786860",
    partNumber: "84971089",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971090-080786860",
    partNumber: "84971090",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971092-080786860",
    partNumber: "84971092",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971093-080786860",
    partNumber: "84971093",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971095-080786860",
    partNumber: "84971095",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971096-080786860",
    partNumber: "84971096",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971097-080786860",
    partNumber: "84971097",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971098-080786860",
    partNumber: "84971098",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971100-080786860",
    partNumber: "84971100",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971102-080786860",
    partNumber: "84971102",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971103-080786860",
    partNumber: "84971103",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971105-080786860",
    partNumber: "84971105",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971107-080786860",
    partNumber: "84971107",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971108-080786860",
    partNumber: "84971108",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971109-080786860",
    partNumber: "84971109",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971110-080786860",
    partNumber: "84971110",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971111-080786860",
    partNumber: "84971111",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971112-080786860",
    partNumber: "84971112",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971113-080786860",
    partNumber: "84971113",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971114-080786860",
    partNumber: "84971114",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971115-080786860",
    partNumber: "84971115",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971116-080786860",
    partNumber: "84971116",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971117-080786860",
    partNumber: "84971117",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971118-080786860",
    partNumber: "84971118",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971119-080786860",
    partNumber: "84971119",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971120-080786860",
    partNumber: "84971120",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971121-080786860",
    partNumber: "84971121",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971122-080786860",
    partNumber: "84971122",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971123-080786860",
    partNumber: "84971123",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971124-080786860",
    partNumber: "84971124",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971129-080786860",
    partNumber: "84971129",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971130-080786860",
    partNumber: "84971130",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971131-080786860",
    partNumber: "84971131",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971132-080786860",
    partNumber: "84971132",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971137-080786860",
    partNumber: "84971137",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971138-080786860",
    partNumber: "84971138",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971139-080786860",
    partNumber: "84971139",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971140-080786860",
    partNumber: "84971140",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971141-080786860",
    partNumber: "84971141",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971142-080786860",
    partNumber: "84971142",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971143-080786860",
    partNumber: "84971143",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971144-080786860",
    partNumber: "84971144",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971149-080786860",
    partNumber: "84971149",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971150-080786860",
    partNumber: "84971150",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971151-080786860",
    partNumber: "84971151",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971152-080786860",
    partNumber: "84971152",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971157-080786860",
    partNumber: "84971157",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971158-080786860",
    partNumber: "84971158",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971159-080786860",
    partNumber: "84971159",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971160-080786860",
    partNumber: "84971160",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971161-080786860",
    partNumber: "84971161",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971162-080786860",
    partNumber: "84971162",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971163-080786860",
    partNumber: "84971163",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971164-080786860",
    partNumber: "84971164",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971169-080786860",
    partNumber: "84971169",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971170-080786860",
    partNumber: "84971170",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971171-080786860",
    partNumber: "84971171",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971172-080786860",
    partNumber: "84971172",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971173-080786860",
    partNumber: "84971173",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971174-080786860",
    partNumber: "84971174",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971175-080786860",
    partNumber: "84971175",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971176-080786860",
    partNumber: "84971176",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971177-080786860",
    partNumber: "84971177",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971178-080786860",
    partNumber: "84971178",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971179-080786860",
    partNumber: "84971179",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971180-080786860",
    partNumber: "84971180",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971181-080786860",
    partNumber: "84971181",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971182-080786860",
    partNumber: "84971182",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971183-080786860",
    partNumber: "84971183",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971184-080786860",
    partNumber: "84971184",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971189-080786860",
    partNumber: "84971189",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971190-080786860",
    partNumber: "84971190",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971191-080786860",
    partNumber: "84971191",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971194-080786860",
    partNumber: "84971194",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971195-080786860",
    partNumber: "84971195",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971196-080786860",
    partNumber: "84971196",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971197-080786860",
    partNumber: "84971197",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971199-080786860",
    partNumber: "84971199",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971201-080786860",
    partNumber: "84971201",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971202-080786860",
    partNumber: "84971202",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971205-080786860",
    partNumber: "84971205",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971206-080786860",
    partNumber: "84971206",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971207-080786860",
    partNumber: "84971207",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971208-080786860",
    partNumber: "84971208",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971209-080786860",
    partNumber: "84971209",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971210-080786860",
    partNumber: "84971210",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971212-080786860",
    partNumber: "84971212",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971214-080786860",
    partNumber: "84971214",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971215-080786860",
    partNumber: "84971215",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971217-080786860",
    partNumber: "84971217",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84971218-080786860",
    partNumber: "84971218",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84972368-545337164",
    partNumber: "84972368",
    description: "OUTLET ASM-I/P",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84972374-545337164",
    partNumber: "84972374",
    description: "OUTLET ASM-I/P",
    supplierName: "SUMMIT PLASTICS (NANJING) CO LTD",
    supplierAddress: "NANJING,32",
    supplierStreet: "",
    supplierCity: "NANJING",
    supplierState: "32",
    supplierZip: "211100",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84972393-117409100",
    partNumber: "84972393",
    description: "PANEL ASM-RKR I",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84972394-117409100",
    partNumber: "84972394",
    description: "PANEL ASM-RKR I",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84972395-117409100",
    partNumber: "84972395",
    description: "PANEL ASM-RKR I",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84972396-117409100",
    partNumber: "84972396",
    description: "PANEL ASM-RKR I",
    supplierName: "FLEX-N-GATE TEXAS LLC",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750521601",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84972596-812015766",
    partNumber: "84972596",
    description: "PIPE ASM-F/TNK",
    supplierName: "INDUSTRIAS MARTINREA DE MX SA DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25298",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84972597-812015766",
    partNumber: "84972597",
    description: "PIPE ASM-F/TNK",
    supplierName: "INDUSTRIAS MARTINREA DE MX SA DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25298",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84972598-812015766",
    partNumber: "84972598",
    description: "PIPE ASM-F/TNK",
    supplierName: "INDUSTRIAS MARTINREA DE MX SA DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25298",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84972599-812015766",
    partNumber: "84972599",
    description: "PIPE ASM-F/TNK",
    supplierName: "INDUSTRIAS MARTINREA DE MX SA DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25298",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84973002-816657977",
    partNumber: "84973002",
    description: "PIPE ASM-RR BRK",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84973003-816657977",
    partNumber: "84973003",
    description: "PIPE ASM-RR BRK",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84973004-816657977",
    partNumber: "84973004",
    description: "PIPE ASM-RR BRK",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84973214-812905909",
    partNumber: "84973214",
    description: "BRACKET ASM-FWD",
    supplierName: "MARTINREA DEVELOPMENTS DE",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84973256-807813840",
    partNumber: "84973256",
    description: "MODULE ASM-VIDE",
    supplierName: "VALEO SWITCHES & DETECTION SYSTEMS",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973286-812895704",
    partNumber: "84973286",
    description: "ARM ASM-FRT LWR",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84973287-812895704",
    partNumber: "84973287",
    description: "ARM ASM-FRT LWR",
    supplierName: "INMETMATIC SA DE CV",
    supplierAddress: "SAN JOSE ITURBIDE,GJ",
    supplierStreet: "",
    supplierCity: "SAN JOSE ITURBIDE",
    supplierState: "GJ",
    supplierZip: "37980",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84973408-080786860",
    partNumber: "84973408",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973409-080786860",
    partNumber: "84973409",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973410-080786860",
    partNumber: "84973410",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973411-080786860",
    partNumber: "84973411",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973412-080786860",
    partNumber: "84973412",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973413-080786860",
    partNumber: "84973413",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973414-080786860",
    partNumber: "84973414",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973415-080786860",
    partNumber: "84973415",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973416-080786860",
    partNumber: "84973416",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973417-080786860",
    partNumber: "84973417",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973418-080786860",
    partNumber: "84973418",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973419-080786860",
    partNumber: "84973419",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973420-080786860",
    partNumber: "84973420",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973421-080786860",
    partNumber: "84973421",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973422-080786860",
    partNumber: "84973422",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973423-080786860",
    partNumber: "84973423",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973424-080786860",
    partNumber: "84973424",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973425-080786860",
    partNumber: "84973425",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973426-080786860",
    partNumber: "84973426",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973427-080786860",
    partNumber: "84973427",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973428-080786860",
    partNumber: "84973428",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973429-080786860",
    partNumber: "84973429",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973430-080786860",
    partNumber: "84973430",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973431-080786860",
    partNumber: "84973431",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973432-080786860",
    partNumber: "84973432",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973433-080786860",
    partNumber: "84973433",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973434-080786860",
    partNumber: "84973434",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973435-080786860",
    partNumber: "84973435",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973436-080786860",
    partNumber: "84973436",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973437-080786860",
    partNumber: "84973437",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973438-080786860",
    partNumber: "84973438",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973439-080786860",
    partNumber: "84973439",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973440-080786860",
    partNumber: "84973440",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973441-080786860",
    partNumber: "84973441",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973442-080786860",
    partNumber: "84973442",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973443-080786860",
    partNumber: "84973443",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973444-080786860",
    partNumber: "84973444",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973445-080786860",
    partNumber: "84973445",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973446-080786860",
    partNumber: "84973446",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973447-080786860",
    partNumber: "84973447",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973448-080786860",
    partNumber: "84973448",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973449-080786860",
    partNumber: "84973449",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973450-080786860",
    partNumber: "84973450",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973451-080786860",
    partNumber: "84973451",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973452-080786860",
    partNumber: "84973452",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973453-080786860",
    partNumber: "84973453",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973454-080786860",
    partNumber: "84973454",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973455-080786860",
    partNumber: "84973455",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973456-080786860",
    partNumber: "84973456",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973457-080786860",
    partNumber: "84973457",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973458-080786860",
    partNumber: "84973458",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973459-080786860",
    partNumber: "84973459",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973460-080786860",
    partNumber: "84973460",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973461-080786860",
    partNumber: "84973461",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973462-080786860",
    partNumber: "84973462",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973463-080786860",
    partNumber: "84973463",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973464-080786860",
    partNumber: "84973464",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973465-080786860",
    partNumber: "84973465",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973466-080786860",
    partNumber: "84973466",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973467-080786860",
    partNumber: "84973467",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973468-080786860",
    partNumber: "84973468",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973469-080786860",
    partNumber: "84973469",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973470-080786860",
    partNumber: "84973470",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973471-080786860",
    partNumber: "84973471",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973472-080786860",
    partNumber: "84973472",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973473-080786860",
    partNumber: "84973473",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973474-080786860",
    partNumber: "84973474",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973475-080786860",
    partNumber: "84973475",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973476-080786860",
    partNumber: "84973476",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84973477-080786860",
    partNumber: "84973477",
    description: "TRIM ASM-RR S/D",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84974412-812888257",
    partNumber: "84974412",
    description: "PLATE ASM-I/P C",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84974413-812888257",
    partNumber: "84974413",
    description: "PLATE ASM-I/P C",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84974414-812888257",
    partNumber: "84974414",
    description: "PLATE ASM-I/P C",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84975150-137355322",
    partNumber: "84975150",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84975151-137355322",
    partNumber: "84975151",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84975153-137355322",
    partNumber: "84975153",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84975154-137355322",
    partNumber: "84975154",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84975159-137355322",
    partNumber: "84975159",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84975162-137355322",
    partNumber: "84975162",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84975163-137355322",
    partNumber: "84975163",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84975165-137355322",
    partNumber: "84975165",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84975168-137355322",
    partNumber: "84975168",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84975169-137355322",
    partNumber: "84975169",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84975171-137355322",
    partNumber: "84975171",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84975174-137355322",
    partNumber: "84975174",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84975989-078849132",
    partNumber: "84975989",
    description: "BRACKET ASM-FV",
    supplierName: "LAWRENCE PLASTICS INC",
    supplierAddress: "CLARKSTON,MI",
    supplierStreet: "",
    supplierCity: "CLARKSTON",
    supplierState: "MI",
    supplierZip: "483462260",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84976040-809149292",
    partNumber: "84976040",
    description: "SHUTTER ASM-FRT",
    supplierName: "MAGNA MODULAR SYSTEMS INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891476",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84976041-809149292",
    partNumber: "84976041",
    description: "SHUTTER ASM-FRT",
    supplierName: "MAGNA MODULAR SYSTEMS INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891476",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84976042-809149292",
    partNumber: "84976042",
    description: "SHUTTER ASM-FRT",
    supplierName: "MAGNA MODULAR SYSTEMS INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891476",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84976043-809149292",
    partNumber: "84976043",
    description: "SHUTTER ASM-FRT",
    supplierName: "MAGNA MODULAR SYSTEMS INC",
    supplierAddress: "WARREN,MI",
    supplierStreet: "",
    supplierCity: "WARREN",
    supplierState: "MI",
    supplierZip: "480891476",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84976621-078488161",
    partNumber: "84976621",
    description: "AMPLIFIER ASM-R",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84976622-078488161",
    partNumber: "84976622",
    description: "AMPLIFIER ASM-R",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84976676-050614114",
    partNumber: "84976676",
    description: "HANGER ASM-EXH",
    supplierName: "AIRBOSS FLEXIBLE PRODUCTS CO",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84976677-050614114",
    partNumber: "84976677",
    description: "HANGER ASM-EXH",
    supplierName: "AIRBOSS FLEXIBLE PRODUCTS CO",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "48326",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977204-839434800",
    partNumber: "84977204",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977205-839434800",
    partNumber: "84977205",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977206-839434800",
    partNumber: "84977206",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977207-839434800",
    partNumber: "84977207",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977208-839434800",
    partNumber: "84977208",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977209-839434800",
    partNumber: "84977209",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977210-839434800",
    partNumber: "84977210",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977211-839434800",
    partNumber: "84977211",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977212-839434800",
    partNumber: "84977212",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977213-839434800",
    partNumber: "84977213",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977214-839434800",
    partNumber: "84977214",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977215-839434800",
    partNumber: "84977215",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977216-839434800",
    partNumber: "84977216",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977217-839434800",
    partNumber: "84977217",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977218-839434800",
    partNumber: "84977218",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977219-839434800",
    partNumber: "84977219",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977220-839434800",
    partNumber: "84977220",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977221-839434800",
    partNumber: "84977221",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977222-839434800",
    partNumber: "84977222",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977223-839434800",
    partNumber: "84977223",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977224-839434800",
    partNumber: "84977224",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977225-839434800",
    partNumber: "84977225",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977226-839434800",
    partNumber: "84977226",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977227-839434800",
    partNumber: "84977227",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977228-839434800",
    partNumber: "84977228",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977229-839434800",
    partNumber: "84977229",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977230-839434800",
    partNumber: "84977230",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977238-839434800",
    partNumber: "84977238",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977239-839434800",
    partNumber: "84977239",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977240-839434800",
    partNumber: "84977240",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977241-839434800",
    partNumber: "84977241",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977242-839434800",
    partNumber: "84977242",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977243-839434800",
    partNumber: "84977243",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977244-839434800",
    partNumber: "84977244",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977245-839434800",
    partNumber: "84977245",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977246-839434800",
    partNumber: "84977246",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977247-839434800",
    partNumber: "84977247",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977248-839434800",
    partNumber: "84977248",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977249-839434800",
    partNumber: "84977249",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977250-839434800",
    partNumber: "84977250",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977251-839434800",
    partNumber: "84977251",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977252-839434800",
    partNumber: "84977252",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977253-839434800",
    partNumber: "84977253",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977254-839434800",
    partNumber: "84977254",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977255-839434800",
    partNumber: "84977255",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977286-839434800",
    partNumber: "84977286",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977287-839434800",
    partNumber: "84977287",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977288-839434800",
    partNumber: "84977288",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977289-839434800",
    partNumber: "84977289",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977290-839434800",
    partNumber: "84977290",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977291-839434800",
    partNumber: "84977291",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977296-839434800",
    partNumber: "84977296",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977297-839434800",
    partNumber: "84977297",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977298-839434800",
    partNumber: "84977298",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977299-839434800",
    partNumber: "84977299",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977300-839434800",
    partNumber: "84977300",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977301-839434800",
    partNumber: "84977301",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977302-839434800",
    partNumber: "84977302",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977303-839434800",
    partNumber: "84977303",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977304-839434800",
    partNumber: "84977304",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977305-839434800",
    partNumber: "84977305",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977306-839434800",
    partNumber: "84977306",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977307-839434800",
    partNumber: "84977307",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977308-839434800",
    partNumber: "84977308",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977309-839434800",
    partNumber: "84977309",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977329-839434800",
    partNumber: "84977329",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977330-839434800",
    partNumber: "84977330",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977331-839434800",
    partNumber: "84977331",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977335-839434800",
    partNumber: "84977335",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977336-839434800",
    partNumber: "84977336",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977337-839434800",
    partNumber: "84977337",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977338-839434800",
    partNumber: "84977338",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977339-839434800",
    partNumber: "84977339",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977340-839434800",
    partNumber: "84977340",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977341-839434800",
    partNumber: "84977341",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977342-839434800",
    partNumber: "84977342",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977343-839434800",
    partNumber: "84977343",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977344-839434800",
    partNumber: "84977344",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977345-839434800",
    partNumber: "84977345",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977346-839434800",
    partNumber: "84977346",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977347-839434800",
    partNumber: "84977347",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977348-839434800",
    partNumber: "84977348",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977349-839434800",
    partNumber: "84977349",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977350-839434800",
    partNumber: "84977350",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977351-839434800",
    partNumber: "84977351",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977352-839434800",
    partNumber: "84977352",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977353-839434800",
    partNumber: "84977353",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977354-839434800",
    partNumber: "84977354",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977355-839434800",
    partNumber: "84977355",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977368-839434800",
    partNumber: "84977368",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977369-839434800",
    partNumber: "84977369",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977374-839434800",
    partNumber: "84977374",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977375-839434800",
    partNumber: "84977375",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977377-839434800",
    partNumber: "84977377",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977378-839434800",
    partNumber: "84977378",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977380-839434800",
    partNumber: "84977380",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977381-839434800",
    partNumber: "84977381",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977383-839434800",
    partNumber: "84977383",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977384-839434800",
    partNumber: "84977384",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977386-839434800",
    partNumber: "84977386",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977387-839434800",
    partNumber: "84977387",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977389-839434800",
    partNumber: "84977389",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977390-839434800",
    partNumber: "84977390",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977392-839434800",
    partNumber: "84977392",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977393-839434800",
    partNumber: "84977393",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977404-000272310",
    partNumber: "84977404",
    description: "PLATE ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483262613",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977405-000272310",
    partNumber: "84977405",
    description: "PLATE ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483262613",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977406-000272310",
    partNumber: "84977406",
    description: "PLATE ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483262613",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977407-000272310",
    partNumber: "84977407",
    description: "PLATE ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPNT GRP",
    supplierAddress: "AUBURN HILLS,MI",
    supplierStreet: "",
    supplierCity: "AUBURN HILLS",
    supplierState: "MI",
    supplierZip: "483262613",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977413-839434800",
    partNumber: "84977413",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977414-839434800",
    partNumber: "84977414",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977417-839434800",
    partNumber: "84977417",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977418-839434800",
    partNumber: "84977418",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977419-839434800",
    partNumber: "84977419",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977420-839434800",
    partNumber: "84977420",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977421-839434800",
    partNumber: "84977421",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977422-839434800",
    partNumber: "84977422",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977423-839434800",
    partNumber: "84977423",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977424-839434800",
    partNumber: "84977424",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977425-839434800",
    partNumber: "84977425",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977426-839434800",
    partNumber: "84977426",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977427-839434800",
    partNumber: "84977427",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977428-839434800",
    partNumber: "84977428",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977429-839434800",
    partNumber: "84977429",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977430-839434800",
    partNumber: "84977430",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977490-839434800",
    partNumber: "84977490",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977491-839434800",
    partNumber: "84977491",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977492-839434800",
    partNumber: "84977492",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977493-839434800",
    partNumber: "84977493",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977494-839434800",
    partNumber: "84977494",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977495-839434800",
    partNumber: "84977495",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977496-839434800",
    partNumber: "84977496",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977497-839434800",
    partNumber: "84977497",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977498-839434800",
    partNumber: "84977498",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977549-839434800",
    partNumber: "84977549",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977550-839434800",
    partNumber: "84977550",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977551-839434800",
    partNumber: "84977551",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977552-839434800",
    partNumber: "84977552",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977553-839434800",
    partNumber: "84977553",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977554-839434800",
    partNumber: "84977554",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977555-839434800",
    partNumber: "84977555",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977556-839434800",
    partNumber: "84977556",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977557-839434800",
    partNumber: "84977557",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977558-839434800",
    partNumber: "84977558",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977559-839434800",
    partNumber: "84977559",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977560-839434800",
    partNumber: "84977560",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977561-839434800",
    partNumber: "84977561",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977562-839434800",
    partNumber: "84977562",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977563-839434800",
    partNumber: "84977563",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977564-839434800",
    partNumber: "84977564",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977565-839434800",
    partNumber: "84977565",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977566-839434800",
    partNumber: "84977566",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977567-839434800",
    partNumber: "84977567",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977568-839434800",
    partNumber: "84977568",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977569-839434800",
    partNumber: "84977569",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977570-839434800",
    partNumber: "84977570",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977571-839434800",
    partNumber: "84977571",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977572-839434800",
    partNumber: "84977572",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977573-839434800",
    partNumber: "84977573",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977574-839434800",
    partNumber: "84977574",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977575-839434800",
    partNumber: "84977575",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977576-839434800",
    partNumber: "84977576",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977577-839434800",
    partNumber: "84977577",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977578-839434800",
    partNumber: "84977578",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977579-839434800",
    partNumber: "84977579",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977580-839434800",
    partNumber: "84977580",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977581-839434800",
    partNumber: "84977581",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977584-839434800",
    partNumber: "84977584",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977585-839434800",
    partNumber: "84977585",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977586-839434800",
    partNumber: "84977586",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977587-839434800",
    partNumber: "84977587",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977588-839434800",
    partNumber: "84977588",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977589-839434800",
    partNumber: "84977589",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977590-839434800",
    partNumber: "84977590",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977591-839434800",
    partNumber: "84977591",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977592-839434800",
    partNumber: "84977592",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977593-839434800",
    partNumber: "84977593",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977594-839434800",
    partNumber: "84977594",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977595-839434800",
    partNumber: "84977595",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977596-839434800",
    partNumber: "84977596",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977597-839434800",
    partNumber: "84977597",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977598-839434800",
    partNumber: "84977598",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977599-839434800",
    partNumber: "84977599",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977600-839434800",
    partNumber: "84977600",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977601-839434800",
    partNumber: "84977601",
    description: "MIRROR ASM-O/S",
    supplierName: "MAGNA MIRRORS OF AMERICA INC",
    supplierAddress: "NEWAYGO,MI",
    supplierStreet: "",
    supplierCity: "NEWAYGO",
    supplierState: "MI",
    supplierZip: "49337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977612-932662133",
    partNumber: "84977612",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977613-932662133",
    partNumber: "84977613",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977614-932662133",
    partNumber: "84977614",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977615-932662133",
    partNumber: "84977615",
    description: "APPLIQUE ASM-I/",
    supplierName: "NOVEM CAR INTERIOR DESIGN INC",
    supplierAddress: "AUSTELL,GA",
    supplierStreet: "",
    supplierCity: "AUSTELL",
    supplierState: "GA",
    supplierZip: "301687891",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977626-555343750",
    partNumber: "84977626",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84977627-555343750",
    partNumber: "84977627",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84977628-555343750",
    partNumber: "84977628",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84977658-607039732",
    partNumber: "84977658",
    description: "BLOCK ASM-BAT D",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977659-607039732",
    partNumber: "84977659",
    description: "BLOCK ASM-BAT D",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84977660-607039732",
    partNumber: "84977660",
    description: "BLOCK ASM-BAT D",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84978145-812928414",
    partNumber: "84978145",
    description: "CASE ASM-TRFER",
    supplierName: "MAGNA POWERTRAIN DE MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978146-812928414",
    partNumber: "84978146",
    description: "CASE ASM-TRFER",
    supplierName: "MAGNA POWERTRAIN DE MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978277-080316510",
    partNumber: "84978277",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84978280-080316510",
    partNumber: "84978280",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84978282-080316510",
    partNumber: "84978282",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84978303-080316510",
    partNumber: "84978303",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84978304-080316510",
    partNumber: "84978304",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84978320-080316510",
    partNumber: "84978320",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84978333-080316510",
    partNumber: "84978333",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84978335-080316510",
    partNumber: "84978335",
    description: "APPLIQUE ASM-RR",
    supplierName: "LACKS EXTERIOR SYSTEMS LLC",
    supplierAddress: "GRAND RAPIDS,MI",
    supplierStreet: "",
    supplierCity: "GRAND RAPIDS",
    supplierState: "MI",
    supplierZip: "495129607",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84978365-032020088",
    partNumber: "84978365",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84978367-032020088",
    partNumber: "84978367",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84978368-032020088",
    partNumber: "84978368",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84978378-032020088",
    partNumber: "84978378",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84978380-032020088",
    partNumber: "84978380",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84978384-032020088",
    partNumber: "84978384",
    description: "APPLIQUE ASM-RR",
    supplierName: "SRG GLOBAL INC",
    supplierAddress: "NEWBERN,TN",
    supplierStreet: "",
    supplierCity: "NEWBERN",
    supplierState: "TN",
    supplierZip: "380591307",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84978459-814526588",
    partNumber: "84978459",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978460-814526588",
    partNumber: "84978460",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978461-814526588",
    partNumber: "84978461",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978462-814526588",
    partNumber: "84978462",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978464-814526588",
    partNumber: "84978464",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978465-814526588",
    partNumber: "84978465",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978466-814526588",
    partNumber: "84978466",
    description: "WHEEL ASM-STRG",
    supplierName: "AUTOLIV MEXICO EAST SA DE CV",
    supplierAddress: "MATAMOROS,TM",
    supplierStreet: "",
    supplierCity: "MATAMOROS",
    supplierState: "TM",
    supplierZip: "87316",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978701-814180139",
    partNumber: "84978701",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978702-814180139",
    partNumber: "84978702",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978703-814180139",
    partNumber: "84978703",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978705-814180139",
    partNumber: "84978705",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978706-814180139",
    partNumber: "84978706",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978707-814180139",
    partNumber: "84978707",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978709-814180139",
    partNumber: "84978709",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978710-814180139",
    partNumber: "84978710",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978711-814180139",
    partNumber: "84978711",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978713-814180139",
    partNumber: "84978713",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978714-814180139",
    partNumber: "84978714",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978715-814180139",
    partNumber: "84978715",
    description: "RETRACTOR ASM-R",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978717-814180139",
    partNumber: "84978717",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978718-814180139",
    partNumber: "84978718",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978720-814180139",
    partNumber: "84978720",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978721-814180139",
    partNumber: "84978721",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978723-814180139",
    partNumber: "84978723",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978724-814180139",
    partNumber: "84978724",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978725-814180139",
    partNumber: "84978725",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978727-814180139",
    partNumber: "84978727",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978728-814180139",
    partNumber: "84978728",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978729-814180139",
    partNumber: "84978729",
    description: "RETRACTOR ASM-F",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978739-814180139",
    partNumber: "84978739",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978741-814180139",
    partNumber: "84978741",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978742-814180139",
    partNumber: "84978742",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978744-814180139",
    partNumber: "84978744",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978746-814180139",
    partNumber: "84978746",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978747-814180139",
    partNumber: "84978747",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978749-814180139",
    partNumber: "84978749",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978750-814180139",
    partNumber: "84978750",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978751-814180139",
    partNumber: "84978751",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978752-814180139",
    partNumber: "84978752",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978754-814180139",
    partNumber: "84978754",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978755-814180139",
    partNumber: "84978755",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978756-814180139",
    partNumber: "84978756",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978757-814180139",
    partNumber: "84978757",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978759-814180139",
    partNumber: "84978759",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978760-814180139",
    partNumber: "84978760",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978761-814180139",
    partNumber: "84978761",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978763-814180139",
    partNumber: "84978763",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978764-814180139",
    partNumber: "84978764",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84978765-814180139",
    partNumber: "84978765",
    description: "RETRACTOR ASM-3",
    supplierName: "TRW VEHICLE SAFETY SYSTEMS DE MEXIC",
    supplierAddress: "REYNOSA,TM",
    supplierStreet: "",
    supplierCity: "REYNOSA",
    supplierState: "TM",
    supplierZip: "88787",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84979881-812234458",
    partNumber: "84979881",
    description: "REGULATOR ASM-F",
    supplierName: "BROSE MEXICO SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76120",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84979882-812234458",
    partNumber: "84979882",
    description: "REGULATOR ASM-F",
    supplierName: "BROSE MEXICO SA DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76120",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84980918-966350811",
    partNumber: "84980918",
    description: "CAP-RR STAB SHF",
    supplierName: "RHYTHM NORTH AMERICA CORP",
    supplierAddress: "SPARTA,TN",
    supplierStreet: "",
    supplierCity: "SPARTA",
    supplierState: "TN",
    supplierZip: "385831360",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84980930-079777903",
    partNumber: "84980930",
    description: "SENSOR ASM-VEH",
    supplierName: "ROBERT BOSCH LLC",
    supplierAddress: "SUMMERVILLE,SC",
    supplierStreet: "",
    supplierCity: "SUMMERVILLE",
    supplierState: "SC",
    supplierZip: "294837415",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84981378-000195578",
    partNumber: "84981378",
    description: "MODULE ASM-ACSR",
    supplierName: "CONTINENTAL AUTOMOTIVE NOGALES SA",
    supplierAddress: "NOGALES,AZ",
    supplierStreet: "",
    supplierCity: "NOGALES",
    supplierState: "AZ",
    supplierZip: "856214505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84981380-000195578",
    partNumber: "84981380",
    description: "MODULE PKG-ASST",
    supplierName: "CONTINENTAL AUTOMOTIVE NOGALES SA",
    supplierAddress: "NOGALES,AZ",
    supplierStreet: "",
    supplierCity: "NOGALES",
    supplierState: "AZ",
    supplierZip: "856214505",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84981728-795124908",
    partNumber: "84981728",
    description: "MOUNT PKG-BODY",
    supplierName: "VIBRACOUSTIC USA INC",
    supplierAddress: "MORGANFIELD,KY",
    supplierStreet: "",
    supplierCity: "MORGANFIELD",
    supplierState: "KY",
    supplierZip: "424376622",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84981729-795124908",
    partNumber: "84981729",
    description: "MOUNT PKG-BODY",
    supplierName: "VIBRACOUSTIC USA INC",
    supplierAddress: "MORGANFIELD,KY",
    supplierStreet: "",
    supplierCity: "MORGANFIELD",
    supplierState: "KY",
    supplierZip: "424376622",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84982599-000243147",
    partNumber: "84982599",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84982600-000243147",
    partNumber: "84982600",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84982601-000243147",
    partNumber: "84982601",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84982602-000243147",
    partNumber: "84982602",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84982609-000243147",
    partNumber: "84982609",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84982610-000243147",
    partNumber: "84982610",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84982611-000243147",
    partNumber: "84982611",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84982612-000243147",
    partNumber: "84982612",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84982808-689201478",
    partNumber: "84982808",
    description: "CAP-FRT S/D I/S",
    supplierName: "SAMWON PLASTIC CO LTD",
    supplierAddress: "SHIHEUNG,41",
    supplierStreet: "",
    supplierCity: "SHIHEUNG",
    supplierState: "41",
    supplierZip: "15084",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84982809-689201478",
    partNumber: "84982809",
    description: "CAP-FRT S/D I/S",
    supplierName: "SAMWON PLASTIC CO LTD",
    supplierAddress: "SHIHEUNG,41",
    supplierStreet: "",
    supplierCity: "SHIHEUNG",
    supplierState: "41",
    supplierZip: "15084",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-84983273-000243147",
    partNumber: "84983273",
    description: "BROCHURE-VEH DL",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84983274-000243147",
    partNumber: "84983274",
    description: "BROCHURE-VEH DL",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84983276-000243147",
    partNumber: "84983276",
    description: "BROCHURE-DRVR A",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84983277-000243147",
    partNumber: "84983277",
    description: "BROCHURE-DRVR A",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84983556-816657977",
    partNumber: "84983556",
    description: "HOSE ASM-FUEL F",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84983558-816657977",
    partNumber: "84983558",
    description: "HOSE ASM-FUEL F",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84983926-000259622",
    partNumber: "84983926",
    description: "MODULE ASM-AUTO",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84984131-816657977",
    partNumber: "84984131",
    description: "HOSE ASM-FUEL F",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84984133-816657977",
    partNumber: "84984133",
    description: "HOSE ASM-FUEL F",
    supplierName: "INDUSTRIAS MARTINREA DE MEXICO SA D",
    supplierAddress: "EL LLANO,CZ",
    supplierStreet: "",
    supplierCity: "EL LLANO",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84985787-607039732",
    partNumber: "84985787",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84985788-607039732",
    partNumber: "84985788",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84986850-812928414",
    partNumber: "84986850",
    description: "MODULE ASM-TRFE",
    supplierName: "MAGNA POWERTRAIN DE MEXICO SA DE CV",
    supplierAddress: "RAMOS ARIZPE,CZ",
    supplierStreet: "",
    supplierCity: "RAMOS ARIZPE",
    supplierState: "CZ",
    supplierZip: "25900",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84987011-016422106",
    partNumber: "84987011",
    description: "SHAFT ASM-PROP",
    supplierName: "NEAPCO DRIVELINES LLC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481115271",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84987145-555343750",
    partNumber: "84987145",
    description: "CLUSTER AS",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84987157-555343750",
    partNumber: "84987157",
    description: "MODULE ASM-I/P",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84987158-555343750",
    partNumber: "84987158",
    description: "MODULE ASM-I/P",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84987159-555343750",
    partNumber: "84987159",
    description: "MODULE ASM-I/P",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84987160-555343750",
    partNumber: "84987160",
    description: "MODULE ASM-I/P",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84987356-607039732",
    partNumber: "84987356",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84987360-607039732",
    partNumber: "84987360",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84987364-607039732",
    partNumber: "84987364",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84987368-607039732",
    partNumber: "84987368",
    description: "HARNESS ASM-CHA",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84987405-005476700",
    partNumber: "84987405",
    description: "MODULE ASM-ACTI",
    supplierName: "ZF ACTIVE SAFETY AND ELECTRONICS US",
    supplierAddress: "MARSHALL,IL",
    supplierStreet: "",
    supplierCity: "MARSHALL",
    supplierState: "IL",
    supplierZip: "624411854",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84987407-807813840",
    partNumber: "84987407",
    description: "MODULE ASM-VIDE",
    supplierName: "VALEO SWITCHES & DETECTION SYSTEMS",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84987492-814330007",
    partNumber: "84987492",
    description: "CALIPER ASM-FRT",
    supplierName: "BREMBO MEXICO SA DE CV",
    supplierAddress: "GENERAL ESCOBEDO,NL",
    supplierStreet: "",
    supplierCity: "GENERAL ESCOBEDO",
    supplierState: "NL",
    supplierZip: "66050",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84987494-814330007",
    partNumber: "84987494",
    description: "CALIPER ASM-FRT",
    supplierName: "BREMBO MEXICO SA DE CV",
    supplierAddress: "GENERAL ESCOBEDO,NL",
    supplierStreet: "",
    supplierCity: "GENERAL ESCOBEDO",
    supplierState: "NL",
    supplierZip: "66050",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84987684-201189391",
    partNumber: "84987684",
    description: "MODULE ASM-DIGI",
    supplierName: "VEONEER CANADA INC",
    supplierAddress: "MARKHAM,ON",
    supplierStreet: "",
    supplierCity: "MARKHAM",
    supplierState: "ON",
    supplierZip: "L3R 5C2",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84988281-253988547",
    partNumber: "84988281",
    description: "APPLIQUE ASM-FR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84988282-253988547",
    partNumber: "84988282",
    description: "APPLIQUE ASM-FR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84988283-253988547",
    partNumber: "84988283",
    description: "APPLIQUE ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84988284-253988547",
    partNumber: "84988284",
    description: "APPLIQUE ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84988285-253988547",
    partNumber: "84988285",
    description: "APPLIQUE ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84988286-253988547",
    partNumber: "84988286",
    description: "APPLIQUE ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84988287-253988547",
    partNumber: "84988287",
    description: "APPLIQUE ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84988288-253988547",
    partNumber: "84988288",
    description: "APPLIQUE ASM-RR",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-84988779-545313673",
    partNumber: "84988779",
    description: "MODULE ASM-HDLP",
    supplierName: "VITESCO AUTOMOTIVE TIANJIN CO LTD",
    supplierAddress: "TIANJIN,12",
    supplierStreet: "",
    supplierCity: "TIANJIN",
    supplierState: "12",
    supplierZip: "300457",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84988795-000243147",
    partNumber: "84988795",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84988796-000243147",
    partNumber: "84988796",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84988797-000243147",
    partNumber: "84988797",
    description: "INFORMATION PKG",
    supplierName: "GP STRATEGIES CORP",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483105757",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84988954-588352398",
    partNumber: "84988954",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84988955-588352398",
    partNumber: "84988955",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84988956-588352398",
    partNumber: "84988956",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84988957-588352398",
    partNumber: "84988957",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84988960-588352398",
    partNumber: "84988960",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84988961-588352398",
    partNumber: "84988961",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84988962-588352398",
    partNumber: "84988962",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84988963-588352398",
    partNumber: "84988963",
    description: "RADIO ASM-RCVR",
    supplierName: "HARMAN DE MEXICO S DE RL DE CV",
    supplierAddress: "QUERETARO,QA",
    supplierStreet: "",
    supplierCity: "QUERETARO",
    supplierState: "QA",
    supplierZip: "76220",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989147-812947059",
    partNumber: "84989147",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989148-812947059",
    partNumber: "84989148",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989185-812947059",
    partNumber: "84989185",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989186-812947059",
    partNumber: "84989186",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989187-812947059",
    partNumber: "84989187",
    description: "PANEL ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66360",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989188-961099348",
    partNumber: "84989188",
    description: "COOLER ASM-F/FL",
    supplierName: "DOMETIC CORP",
    supplierAddress: "GRAND PRAIRIE,TX",
    supplierStreet: "",
    supplierCity: "GRAND PRAIRIE",
    supplierState: "TX",
    supplierZip: "750528926",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84989194-812888257",
    partNumber: "84989194",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989195-812888257",
    partNumber: "84989195",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989197-812888257",
    partNumber: "84989197",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989198-812888257",
    partNumber: "84989198",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989200-812888257",
    partNumber: "84989200",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989201-812888257",
    partNumber: "84989201",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989202-812888257",
    partNumber: "84989202",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989204-812888257",
    partNumber: "84989204",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989205-812888257",
    partNumber: "84989205",
    description: "COMPARTMENT ASM",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989487-785629952",
    partNumber: "84989487",
    description: "LAMP ASM-RR BOD",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84989488-785629952",
    partNumber: "84989488",
    description: "LAMP ASM-RR BOD",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84989489-785629952",
    partNumber: "84989489",
    description: "LAMP ASM-RR BOD",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84989490-785629952",
    partNumber: "84989490",
    description: "LAMP ASM-RR BOD",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84989747-812888257",
    partNumber: "84989747",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989748-812888257",
    partNumber: "84989748",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989749-812888257",
    partNumber: "84989749",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989750-812888257",
    partNumber: "84989750",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989752-812888257",
    partNumber: "84989752",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989753-812888257",
    partNumber: "84989753",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989754-812888257",
    partNumber: "84989754",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989755-812888257",
    partNumber: "84989755",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989761-812888257",
    partNumber: "84989761",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989762-812888257",
    partNumber: "84989762",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989763-812888257",
    partNumber: "84989763",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989765-812888257",
    partNumber: "84989765",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989766-812888257",
    partNumber: "84989766",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989767-812888257",
    partNumber: "84989767",
    description: "PLATE ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84989954-555343750",
    partNumber: "84989954",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84990206-627272024",
    partNumber: "84990206",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84990207-627272024",
    partNumber: "84990207",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84990208-627272024",
    partNumber: "84990208",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84990209-627272024",
    partNumber: "84990209",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84990210-627272024",
    partNumber: "84990210",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84990211-627272024",
    partNumber: "84990211",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84990212-627272024",
    partNumber: "84990212",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84990213-627272024",
    partNumber: "84990213",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84990214-627272024",
    partNumber: "84990214",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84990215-627272024",
    partNumber: "84990215",
    description: "APPLIQUE ASM-RR",
    supplierName: "ADAC PLASTICS INC",
    supplierAddress: "MUSKEGON,MI",
    supplierStreet: "",
    supplierCity: "MUSKEGON",
    supplierState: "MI",
    supplierZip: "494426120",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84990350-816154785",
    partNumber: "84990350",
    description: "PIPE ASM-TRANS",
    supplierName: "AUTOMOTIVE BEND DE MEXICO S DE RL D",
    supplierAddress: "VILLA DE REYES,SL",
    supplierStreet: "",
    supplierCity: "VILLA DE REYES",
    supplierState: "SL",
    supplierZip: "79526",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84990546-812537363",
    partNumber: "84990546",
    description: "BUMPER ASM-L/GA",
    supplierName: "AUTOMOTIVE VERITAS DE",
    supplierAddress: "CUAUTLANCINGO,PU",
    supplierStreet: "",
    supplierCity: "CUAUTLANCINGO",
    supplierState: "PU",
    supplierZip: "72730",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84990577-812814079",
    partNumber: "84990577",
    description: "TANK ASM-EMIS R",
    supplierName: "PLASTIC OMNIUM AUTO INERGY",
    supplierAddress: "LEON,GJ",
    supplierStreet: "",
    supplierCity: "LEON",
    supplierState: "GJ",
    supplierZip: "37668",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84990640-807813840",
    partNumber: "84990640",
    description: "MODULE ASM-VIDE",
    supplierName: "VALEO SWITCHES & DETECTION SYSTEMS",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84991438-116973138",
    partNumber: "84991438",
    description: "CAMERA ASM-FV",
    supplierName: "MAGNA ELECTRONICS INC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84991439-116973138",
    partNumber: "84991439",
    description: "CAMERA ASM-FV",
    supplierName: "MAGNA ELECTRONICS INC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84993225-545313673",
    partNumber: "84993225",
    description: "MODULE ASM-HDLP",
    supplierName: "VITESCO AUTOMOTIVE TIANJIN CO LTD",
    supplierAddress: "TIANJIN,12",
    supplierStreet: "",
    supplierCity: "TIANJIN",
    supplierState: "12",
    supplierZip: "300457",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-84993262-071556752",
    partNumber: "84993262",
    description: "MODULE ASM-DRVR",
    supplierName: "KEY SAFETY RESTRAINT SYSTEMS INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "78251",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84993314-137355322",
    partNumber: "84993314",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84993317-137355322",
    partNumber: "84993317",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84993323-137355322",
    partNumber: "84993323",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84993336-137355322",
    partNumber: "84993336",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84993337-137355322",
    partNumber: "84993337",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84993344-137355322",
    partNumber: "84993344",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84993498-137355322",
    partNumber: "84993498",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84993502-137355322",
    partNumber: "84993502",
    description: "CONSOLE ASM-RF",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84994390-555343750",
    partNumber: "84994390",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84994394-555343750",
    partNumber: "84994394",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84994549-812888257",
    partNumber: "84994549",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84994550-812888257",
    partNumber: "84994550",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84994551-812888257",
    partNumber: "84994551",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84994552-812888257",
    partNumber: "84994552",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84994553-812888257",
    partNumber: "84994553",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84994554-812888257",
    partNumber: "84994554",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84994555-812888257",
    partNumber: "84994555",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84994556-812888257",
    partNumber: "84994556",
    description: "COVER ASM-I/P T",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84995151-080786860",
    partNumber: "84995151",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995152-080786860",
    partNumber: "84995152",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995153-080786860",
    partNumber: "84995153",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995154-080786860",
    partNumber: "84995154",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995155-080786860",
    partNumber: "84995155",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995156-080786860",
    partNumber: "84995156",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995157-080786860",
    partNumber: "84995157",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995158-080786860",
    partNumber: "84995158",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995159-080786860",
    partNumber: "84995159",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995160-080786860",
    partNumber: "84995160",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995161-080786860",
    partNumber: "84995161",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995162-080786860",
    partNumber: "84995162",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995163-080786860",
    partNumber: "84995163",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995164-080786860",
    partNumber: "84995164",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995165-080786860",
    partNumber: "84995165",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995166-080786860",
    partNumber: "84995166",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995167-080786860",
    partNumber: "84995167",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84995168-080786860",
    partNumber: "84995168",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996448-607039732",
    partNumber: "84996448",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996817-607039732",
    partNumber: "84996817",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996818-607039732",
    partNumber: "84996818",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996819-607039732",
    partNumber: "84996819",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996820-607039732",
    partNumber: "84996820",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996821-607039732",
    partNumber: "84996821",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996822-607039732",
    partNumber: "84996822",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996823-607039732",
    partNumber: "84996823",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996824-607039732",
    partNumber: "84996824",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996825-607039732",
    partNumber: "84996825",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996826-607039732",
    partNumber: "84996826",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996827-607039732",
    partNumber: "84996827",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996828-607039732",
    partNumber: "84996828",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996829-607039732",
    partNumber: "84996829",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996830-607039732",
    partNumber: "84996830",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996831-607039732",
    partNumber: "84996831",
    description: "HARNESS ASM-I/P",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996858-005346762",
    partNumber: "84996858",
    description: "SEALING STRIP A",
    supplierName: "COOPER STANDARD AUTOMOTIVE INC",
    supplierAddress: "GAYLORD,MI",
    supplierStreet: "",
    supplierCity: "GAYLORD",
    supplierState: "MI",
    supplierZip: "497359531",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84996859-005346762",
    partNumber: "84996859",
    description: "SEALING STRIP A",
    supplierName: "COOPER STANDARD AUTOMOTIVE INC",
    supplierAddress: "GAYLORD,MI",
    supplierStreet: "",
    supplierCity: "GAYLORD",
    supplierState: "MI",
    supplierZip: "497359531",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84997329-814814042",
    partNumber: "84997329",
    description: "HOSE ASM-HTR IN",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997481-812888257",
    partNumber: "84997481",
    description: "HOOD ASM-I/P IN",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997482-812888257",
    partNumber: "84997482",
    description: "HOOD ASM-I/P IN",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997484-812888257",
    partNumber: "84997484",
    description: "HOOD ASM-I/P IN",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997485-812888257",
    partNumber: "84997485",
    description: "HOOD ASM-I/P IN",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997486-812888257",
    partNumber: "84997486",
    description: "HOOD ASM-I/P IN",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997491-812888257",
    partNumber: "84997491",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997493-812888257",
    partNumber: "84997493",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997494-812888257",
    partNumber: "84997494",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997496-812888257",
    partNumber: "84997496",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997497-812888257",
    partNumber: "84997497",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997498-812888257",
    partNumber: "84997498",
    description: "PANEL ASM-I/P U",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997621-812888257",
    partNumber: "84997621",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997622-812888257",
    partNumber: "84997622",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997624-812888257",
    partNumber: "84997624",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997625-812888257",
    partNumber: "84997625",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997626-812888257",
    partNumber: "84997626",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997633-188277016",
    partNumber: "84997633",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84997634-188277016",
    partNumber: "84997634",
    description: "WEATHERSTRIP AS",
    supplierName: "COOPER-STANDARD AUTOMOTIVE INC",
    supplierAddress: "SPARTANBURG,SC",
    supplierStreet: "",
    supplierCity: "SPARTANBURG",
    supplierState: "SC",
    supplierZip: "293164801",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84997709-812888257",
    partNumber: "84997709",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997711-812888257",
    partNumber: "84997711",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997712-812888257",
    partNumber: "84997712",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997714-812888257",
    partNumber: "84997714",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997715-812888257",
    partNumber: "84997715",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997716-812888257",
    partNumber: "84997716",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997717-812888257",
    partNumber: "84997717",
    description: "EXTENSION ASM-I",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997752-812888257",
    partNumber: "84997752",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997753-812888257",
    partNumber: "84997753",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997755-812888257",
    partNumber: "84997755",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997756-812888257",
    partNumber: "84997756",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997757-812888257",
    partNumber: "84997757",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997774-812888257",
    partNumber: "84997774",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997775-812888257",
    partNumber: "84997775",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997777-812888257",
    partNumber: "84997777",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997778-812888257",
    partNumber: "84997778",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84997779-812888257",
    partNumber: "84997779",
    description: "COVER ASM-I/P F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84998312-812416683",
    partNumber: "84998312",
    description: "DUCT-AUX A/C AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84998313-812416683",
    partNumber: "84998313",
    description: "DUCT-AUX A/C AI",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "SAN JUAN DEL RIO,QA",
    supplierStreet: "",
    supplierCity: "SAN JUAN DEL RIO",
    supplierState: "QA",
    supplierZip: "76806",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-84998701-078488161",
    partNumber: "84998701",
    description: "AMPLIFIER ASM-R",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84998702-078488161",
    partNumber: "84998702",
    description: "AMPLIFIER ASM-R",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84999198-607039732",
    partNumber: "84999198",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84999200-607039732",
    partNumber: "84999200",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84999201-607039732",
    partNumber: "84999201",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84999205-607039732",
    partNumber: "84999205",
    description: "HARNESS ASM-F/F",
    supplierName: "APTIV SERVICES US LLC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799065301",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-84999727-555343750",
    partNumber: "84999727",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84999728-555343750",
    partNumber: "84999728",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-84999729-555343750",
    partNumber: "84999729",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-85000908-555343750",
    partNumber: "85000908",
    description: "MODULE ASM-I/P",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-85000910-555343750",
    partNumber: "85000910",
    description: "MODULE ASM-I/P",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-85000987-785629952",
    partNumber: "85000987",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85000988-785629952",
    partNumber: "85000988",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85000993-785629952",
    partNumber: "85000993",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85000994-785629952",
    partNumber: "85000994",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO NORTH AMERICA INC",
    supplierAddress: "SEYMOUR,IN",
    supplierStreet: "",
    supplierCity: "SEYMOUR",
    supplierState: "IN",
    supplierZip: "472743337",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85001506-965355311",
    partNumber: "85001506",
    description: "MODULE ASM-AUX",
    supplierName: "HANON SYSTEMS NETHERLANDS COOP UA",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799360405",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85001657-000202266",
    partNumber: "85001657",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85001674-812888257",
    partNumber: "85001674",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85001675-812888257",
    partNumber: "85001675",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85001676-812888257",
    partNumber: "85001676",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85001677-812888257",
    partNumber: "85001677",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85001679-812888257",
    partNumber: "85001679",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85001680-812888257",
    partNumber: "85001680",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85001681-812888257",
    partNumber: "85001681",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85001682-812888257",
    partNumber: "85001682",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85001684-812888257",
    partNumber: "85001684",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85002014-253988547",
    partNumber: "85002014",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-85002015-253988547",
    partNumber: "85002015",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-85002016-253988547",
    partNumber: "85002016",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-85002017-253988547",
    partNumber: "85002017",
    description: "MOLDING ASM-BOD",
    supplierName: "MAGNA INTERNATIONAL INC",
    supplierAddress: "CONCORD,ON",
    supplierStreet: "",
    supplierCity: "CONCORD",
    supplierState: "ON",
    supplierZip: "L4K 4S1",
    supplierCountry: "CA",
  },
  {
    _id: "parts-gm-85002065-000168013",
    partNumber: "85002065",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85002066-000168013",
    partNumber: "85002066",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85002877-545264020",
    partNumber: "85002877",
    description: "CAP ASM-FRT & R",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-85002878-131002078",
    partNumber: "85002878",
    description: "CAP ASM-FRT & R",
    supplierName: "ZANINI TENNESSEE INC",
    supplierAddress: "WINCHESTER,TN",
    supplierStreet: "",
    supplierCity: "WINCHESTER",
    supplierState: "TN",
    supplierZip: "373981246",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85002879-545264020",
    partNumber: "85002879",
    description: "CAP ASM-FRT & R",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-85002880-545264020",
    partNumber: "85002880",
    description: "CAP ASM-FRT & R",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-85002881-545264020",
    partNumber: "85002881",
    description: "CAP ASM-FRT & R",
    supplierName: "GUANGZHOU JINZHONG AUTO PARTS MFG",
    supplierAddress: "GUANGZHOU,44",
    supplierStreet: "",
    supplierCity: "GUANGZHOU",
    supplierState: "44",
    supplierZip: "510800",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-85003751-943793091",
    partNumber: "85003751",
    description: "PLATE ASM-F/FDR",
    supplierName: "TRIBAR MANUFACTURING LLC",
    supplierAddress: "HOWELL,MI",
    supplierStreet: "",
    supplierCity: "HOWELL",
    supplierState: "MI",
    supplierZip: "488557320",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85003753-943793091",
    partNumber: "85003753",
    description: "PLATE ASM-F/FDR",
    supplierName: "TRIBAR MANUFACTURING LLC",
    supplierAddress: "HOWELL,MI",
    supplierStreet: "",
    supplierCity: "HOWELL",
    supplierState: "MI",
    supplierZip: "488557320",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85003949-129136698",
    partNumber: "85003949",
    description: "MOTOR ASM-R/WDO",
    supplierName: "DY AMERICA INC",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483143209",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85004143-000168013",
    partNumber: "85004143",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85004144-000168013",
    partNumber: "85004144",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85004166-816508899",
    partNumber: "85004166",
    description: "TUBE ASM-AUTO L",
    supplierName: "VOSS AUTOMOTIVE MX S DE RL DE CV",
    supplierAddress: "ARTEAGA,CZ",
    supplierStreet: "",
    supplierCity: "ARTEAGA",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85004167-816508899",
    partNumber: "85004167",
    description: "TUBE ASM-AUTO L",
    supplierName: "VOSS AUTOMOTIVE MX S DE RL DE CV",
    supplierAddress: "ARTEAGA,CZ",
    supplierStreet: "",
    supplierCity: "ARTEAGA",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85004168-816508899",
    partNumber: "85004168",
    description: "TUBE ASM-AUTO L",
    supplierName: "VOSS AUTOMOTIVE MX S DE RL DE CV",
    supplierAddress: "ARTEAGA,CZ",
    supplierStreet: "",
    supplierCity: "ARTEAGA",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85004169-816508899",
    partNumber: "85004169",
    description: "TUBE ASM-AUTO L",
    supplierName: "VOSS AUTOMOTIVE MX S DE RL DE CV",
    supplierAddress: "ARTEAGA,CZ",
    supplierStreet: "",
    supplierCity: "ARTEAGA",
    supplierState: "CZ",
    supplierZip: "25350",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85004331-080203455",
    partNumber: "85004331",
    description: "HOSE ASM-SUN RF",
    supplierName: "DLHBOWLES INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "785037412",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85004549-000166991",
    partNumber: "85004549",
    description: "SENSOR ASM-FWD",
    supplierName: "CONTI TEMIC MICROELECTRONIC GMBH",
    supplierAddress: "DETROIT,MI",
    supplierStreet: "",
    supplierCity: "DETROIT",
    supplierState: "MI",
    supplierZip: "48234",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85111870-809870855",
    partNumber: "85111870",
    description: "BASE ASM-F/FLR",
    supplierName: "BROSE NEW BOSTON INC",
    supplierAddress: "NEW BOSTON,MI",
    supplierStreet: "",
    supplierCity: "NEW BOSTON",
    supplierState: "MI",
    supplierZip: "481649183",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85111871-809870855",
    partNumber: "85111871",
    description: "BASE ASM-F/FLR",
    supplierName: "BROSE NEW BOSTON INC",
    supplierAddress: "NEW BOSTON,MI",
    supplierStreet: "",
    supplierCity: "NEW BOSTON",
    supplierState: "MI",
    supplierZip: "481649183",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85112045-080786860",
    partNumber: "85112045",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85112046-080786860",
    partNumber: "85112046",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85112047-080786860",
    partNumber: "85112047",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85112055-080786860",
    partNumber: "85112055",
    description: "COMPARTMENT ASM",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85112104-812888257",
    partNumber: "85112104",
    description: "TRAY ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85112105-812888257",
    partNumber: "85112105",
    description: "TRAY ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85112106-812888257",
    partNumber: "85112106",
    description: "TRAY ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85112108-812888257",
    partNumber: "85112108",
    description: "TRAY ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85112109-812888257",
    partNumber: "85112109",
    description: "TRAY ASM-F/FLR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85112148-813016276",
    partNumber: "85112148",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85112152-813016276",
    partNumber: "85112152",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85112155-813016276",
    partNumber: "85112155",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SALTILLO,CZ",
    supplierStreet: "",
    supplierCity: "SALTILLO",
    supplierState: "CZ",
    supplierZip: "25017",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85112713-077472228",
    partNumber: "85112713",
    description: "REINFORCEMENT A",
    supplierName: "SOUTHTEC LLC",
    supplierAddress: "LEBANON,TN",
    supplierStreet: "",
    supplierCity: "LEBANON",
    supplierState: "TN",
    supplierZip: "370879517",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85112861-000259622",
    partNumber: "85112861",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85112862-000259622",
    partNumber: "85112862",
    description: "SPRING ASM-FRT",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85112864-000259622",
    partNumber: "85112864",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85112865-000259622",
    partNumber: "85112865",
    description: "SPRING ASM-RR C",
    supplierName: "BWI NORTH AMERICA INC",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "79927",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85113568-812888257",
    partNumber: "85113568",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85113572-812888257",
    partNumber: "85113572",
    description: "ARMREST ASM-F/F",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85113686-000166991",
    partNumber: "85113686",
    description: "SENSOR ASM-FWD",
    supplierName: "CONTI TEMIC MICROELECTRONIC GMBH",
    supplierAddress: "DETROIT,MI",
    supplierStreet: "",
    supplierCity: "DETROIT",
    supplierState: "MI",
    supplierZip: "48234",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85114013-331043633",
    partNumber: "85114013",
    description: "MODULE ASM-RR D",
    supplierName: "ZF FRIEDRICHSHAFEN AG",
    supplierAddress: "GOTHA,TH",
    supplierStreet: "",
    supplierCity: "GOTHA",
    supplierState: "TH",
    supplierZip: "99867",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-85115245-814814042",
    partNumber: "85115245",
    description: "HOSE ASM-RAD IN",
    supplierName: "HUTCHINSON AUTOPARTES MEXICO SA DE",
    supplierAddress: "CELAYA,GJ",
    supplierStreet: "",
    supplierCity: "CELAYA",
    supplierState: "GJ",
    supplierZip: "38110",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85115554-000168013",
    partNumber: "85115554",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85115555-000168013",
    partNumber: "85115555",
    description: "HEADLAMP ASM-FR",
    supplierName: "VALEO SISTEMAS ELECTRICOS SA DE CV",
    supplierAddress: "LAREDO,TX",
    supplierStreet: "",
    supplierCity: "LAREDO",
    supplierState: "TX",
    supplierZip: "78045",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85116795-807813840",
    partNumber: "85116795",
    description: "MODULE ASM-VIDE",
    supplierName: "VALEO SWITCHES & DETECTION SYSTEMS",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85116797-807813840",
    partNumber: "85116797",
    description: "MODULE ASM-VIDE",
    supplierName: "VALEO SWITCHES & DETECTION SYSTEMS",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85116918-116973138",
    partNumber: "85116918",
    description: "CAMERA ASM-FV",
    supplierName: "MAGNA ELECTRONICS INC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117468-555343750",
    partNumber: "85117468",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-85117469-555343750",
    partNumber: "85117469",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-85117470-555343750",
    partNumber: "85117470",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-85117517-080786860",
    partNumber: "85117517",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117518-080786860",
    partNumber: "85117518",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117521-080786860",
    partNumber: "85117521",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117522-080786860",
    partNumber: "85117522",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117523-080786860",
    partNumber: "85117523",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117524-080786860",
    partNumber: "85117524",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117525-080786860",
    partNumber: "85117525",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117526-080786860",
    partNumber: "85117526",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117527-080786860",
    partNumber: "85117527",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117528-080786860",
    partNumber: "85117528",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117529-080786860",
    partNumber: "85117529",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117530-080786860",
    partNumber: "85117530",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117531-080786860",
    partNumber: "85117531",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117532-080786860",
    partNumber: "85117532",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117533-080786860",
    partNumber: "85117533",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117534-080786860",
    partNumber: "85117534",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117535-080786860",
    partNumber: "85117535",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85117536-080786860",
    partNumber: "85117536",
    description: "TRIM ASM-FRT S/",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85118047-555343750",
    partNumber: "85118047",
    description: "MODULE ASM-I/P",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-85118048-555343750",
    partNumber: "85118048",
    description: "MODULE ASM-I/P",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-85118049-555343750",
    partNumber: "85118049",
    description: "MODULE ASM-I/P",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-85118050-555343750",
    partNumber: "85118050",
    description: "MODULE ASM-I/P",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-85118755-116973138",
    partNumber: "85118755",
    description: "CAMERA ASM-FV",
    supplierName: "MAGNA ELECTRONICS INC",
    supplierAddress: "HOLLY,MI",
    supplierStreet: "",
    supplierCity: "HOLLY",
    supplierState: "MI",
    supplierZip: "484429302",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85119583-137355322",
    partNumber: "85119583",
    description: "CONTROL ASM-HTR",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85119584-137355322",
    partNumber: "85119584",
    description: "CONTROL ASM-HTR",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85119585-137355322",
    partNumber: "85119585",
    description: "CONTROL ASM-HTR",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85119586-137355322",
    partNumber: "85119586",
    description: "CONTROL ASM-HTR",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85119587-137355322",
    partNumber: "85119587",
    description: "CONTROL ASM-HTR",
    supplierName: "METHODE ELECTRONICS INC",
    supplierAddress: "MCALLEN,TX",
    supplierStreet: "",
    supplierCity: "MCALLEN",
    supplierState: "TX",
    supplierZip: "78503",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85121135-421251063",
    partNumber: "85121135",
    description: "MODULE ASM-RDO",
    supplierName: "CONTINENTAL AUTOMOTIVE PARTS SUZHOU",
    supplierAddress: "SUZHOU,32",
    supplierStreet: "",
    supplierCity: "SUZHOU",
    supplierState: "32",
    supplierZip: "215121",
    supplierCountry: "CN",
  },
  {
    _id: "parts-gm-85162035-000202266",
    partNumber: "85162035",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85163292-005356878",
    partNumber: "85163292",
    description: "GEAR ASM",
    supplierName: "NEXTEER AUTOMOTIVE CORP-GENL OFFICE",
    supplierAddress: "SAGINAW,MI",
    supplierStreet: "",
    supplierCity: "SAGINAW",
    supplierState: "MI",
    supplierZip: "486019494",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85163293-005356878",
    partNumber: "85163293",
    description: "GEAR ASM",
    supplierName: "NEXTEER AUTOMOTIVE CORP-GENL OFFICE",
    supplierAddress: "SAGINAW,MI",
    supplierStreet: "",
    supplierCity: "SAGINAW",
    supplierState: "MI",
    supplierZip: "486019494",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85512207-812831062",
    partNumber: "85512207",
    description: "SHAFT-FRT STAB",
    supplierName: "MUBEA DE MEXICO S DE RL DE CV",
    supplierAddress: "APASEO EL GRANDE,GJ",
    supplierStreet: "",
    supplierCity: "APASEO EL GRANDE",
    supplierState: "GJ",
    supplierZip: "38191",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-85512302-121584338",
    partNumber: "85512302",
    description: "PANEL-R/FLR RR",
    supplierName: "GM METAL FAB-MARION",
    supplierAddress: "MARION,IN",
    supplierStreet: "",
    supplierCity: "MARION",
    supplierState: "IN",
    supplierZip: "46952",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85513039-000202266",
    partNumber: "85513039",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85513041-000202266",
    partNumber: "85513041",
    description: "MODULE ASM-COMN",
    supplierName: "LG ELECTRONICS (KUNSHAN) CO LTD",
    supplierAddress: "STERLING HEIGHTS,MI",
    supplierStreet: "",
    supplierCity: "STERLING HEIGHTS",
    supplierState: "MI",
    supplierZip: "483124524",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85513881-071556752",
    partNumber: "85513881",
    description: "MODULE ASM-DRVR",
    supplierName: "KEY SAFETY RESTRAINT SYSTEMS INC",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "78251",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-85514474-555343750",
    partNumber: "85514474",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-85514475-555343750",
    partNumber: "85514475",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-85514476-555343750",
    partNumber: "85514476",
    description: "RADIO ASM-RCVR",
    supplierName: "LG ELECTRONICS VIETNAM HAI PHONG CO",
    supplierAddress: "HAIPHONG HAI PHONG,HP",
    supplierStreet: "",
    supplierCity: "HAIPHONG HAI PHONG",
    supplierState: "HP",
    supplierZip: "18000",
    supplierCountry: "VN",
  },
  {
    _id: "parts-gm-86770406-078488161",
    partNumber: "86770406",
    description: "AMPLIFIER ASM-R",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-86770409-078488161",
    partNumber: "86770409",
    description: "AMPLIFIER ASM-R",
    supplierName: "HARMAN BECKER AUTOMOTIVE SYSTEMS IN",
    supplierAddress: "EL PASO,TX",
    supplierStreet: "",
    supplierCity: "EL PASO",
    supplierState: "TX",
    supplierZip: "799367288",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-86779704-000246553",
    partNumber: "86779704",
    description: "CARD-NAVN DATA",
    supplierName: "TELENAV INC",
    supplierAddress: "FREMONT,CA",
    supplierStreet: "",
    supplierCity: "FREMONT",
    supplierState: "CA",
    supplierZip: "945397498",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-86779728-000782613",
    partNumber: "86779728",
    description: "COMPARTMENT ASM",
    supplierName: "GRUPO ABC DE MEXICO SA DE CV",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760144593",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-86780835-080786860",
    partNumber: "86780835",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-86780840-080786860",
    partNumber: "86780840",
    description: "PANEL ASM-F/FLR",
    supplierName: "INTERNATIONAL AUTOMOTIVE COMPONENTS",
    supplierAddress: "ARLINGTON,TX",
    supplierStreet: "",
    supplierCity: "ARLINGTON",
    supplierState: "TX",
    supplierZip: "760116710",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-86780998-812888257",
    partNumber: "86780998",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-86781001-812888257",
    partNumber: "86781001",
    description: "PAD ASM-I/P TR",
    supplierName: "IACNA MEXICO S DE RL DE CV",
    supplierAddress: "SANTA CATARINA,NL",
    supplierStreet: "",
    supplierCity: "SANTA CATARINA",
    supplierState: "NL",
    supplierZip: "66367",
    supplierCountry: "MX",
  },
  {
    _id: "parts-gm-90214255-315034272",
    partNumber: "90214255",
    description: "RETAINER",
    supplierName: "TUCKER GMBH",
    supplierAddress: "GIESSEN,HE",
    supplierStreet: "",
    supplierCity: "GIESSEN",
    supplierState: "HE",
    supplierZip: "35394",
    supplierCountry: "DE",
  },
  {
    _id: "parts-gm-90584197-078852206",
    partNumber: "90584197",
    description: "DEADENER",
    supplierName: "NITTO AUTOMOTIVE INC",
    supplierAddress: "RIVERSIDE,MO",
    supplierStreet: "",
    supplierCity: "RIVERSIDE",
    supplierState: "MO",
    supplierZip: "641509539",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-92264562-840618722",
    partNumber: "92264562",
    description: "RETAINER-CONVEN",
    supplierName: "EXCO AUTOMOTIVE SOLUTIONS LP",
    supplierAddress: "BROWNSVILLE,TX",
    supplierStreet: "",
    supplierCity: "BROWNSVILLE",
    supplierState: "TX",
    supplierZip: "78520",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-93199658-495042660",
    partNumber: "93199658",
    description: "REFLECTOR ASM-D",
    supplierName: "LOGMAN-POVAZAN AKCIOVA SPOLOCNOST-Z",
    supplierAddress: "POVAZSKA BYSTRICA,TC",
    supplierStreet: "",
    supplierCity: "POVAZSKA BYSTRICA",
    supplierState: "TC",
    supplierZip: "017 04",
    supplierCountry: "SK",
  },
  {
    _id: "parts-gm-94761098-829470223",
    partNumber: "94761098",
    description: "LIGHTER ASM-CIG",
    supplierName: "CASCO PRODUCTS CORP",
    supplierAddress: "MORGANTOWN,KY",
    supplierStreet: "",
    supplierCity: "MORGANTOWN",
    supplierState: "KY",
    supplierZip: "422617361",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-95226685-688328004",
    partNumber: "95226685",
    description: "EYE ASM-TOW",
    supplierName: "SHINIL PRECISION IND CO LTD",
    supplierAddress: "ANSAN,41",
    supplierStreet: "",
    supplierCity: "ANSAN",
    supplierState: "41",
    supplierZip: "15609",
    supplierCountry: "KR",
  },
  {
    _id: "parts-gm-96497159-961920811",
    partNumber: "96497159",
    description: "FIXED BUMPER-HO",
    supplierName: "GLOVIS MICHIGAN LLC",
    supplierAddress: "BELLEVILLE,MI",
    supplierStreet: "",
    supplierCity: "BELLEVILLE",
    supplierState: "MI",
    supplierZip: "481115226",
    supplierCountry: "US",
  },
  {
    _id: "parts-gm-96953677-049816044",
    partNumber: "96953677",
    description: "CLIP-TRANS FLUI",
    supplierName: "ILLINOIS TOOL WORKS INC",
    supplierAddress: "FRANKFORT,IL",
    supplierStreet: "",
    supplierCity: "FRANKFORT",
    supplierState: "IL",
    supplierZip: "604236017",
    supplierCountry: "US",
  },
];
