import { connect } from "react-redux";
import { getSolutionId } from "modules/organizations/OrganizationsState";
import InventoryViewDetailsState from "../redux/InventoryViewDetailsState";
import InventoryViewDetails from "./InventoryView.Details.page";
import InventorySearchBarState from "../redux/InventoryViewDetailsSearchBarState";

function mapStateToProps(state) {
  const locationId = state.location.payload.locationId;
  const { getLocation } = InventoryViewDetailsState.selectors;

  const {
    getSearchResults,
    getIsLoading,
    getPage,
    getPageSize,
    getShowAdvancedSearch,
    getSortColumn,
    getReverseSort,
    getTotalEntities,
  } = InventorySearchBarState.selectors;

  return {
    solutionId: getSolutionId(state),
    locationId: locationId,
    location: getLocation(state),
    page: getPage(state),
    pageSize: getPageSize(state),
    sortColumn: getSortColumn(state),
    reverseSort: getReverseSort(state),
    isLoading: getIsLoading(state),
    isOnsiteVinsCountLoading: false,
    searchResults: getSearchResults(state),
    totalCount: getTotalEntities(state),
    showFilters: getShowAdvancedSearch(state),
  };
}

const { fetchLocation } = InventoryViewDetailsState.actionCreators;

const {
  searchEntities,
  setPagination,
  setCurrentLocation,
  resetSearchAndFilters,
  toggleShowFilters,
  setSort,
} = InventorySearchBarState.actionCreators;

const actionCreators = {
  resetSearchAndFilters,
  onsiteVinsResetSearch: (solutionId) => (dispatch) => {
    dispatch(resetSearchAndFilters());
    dispatch(searchEntities(solutionId));
  },
  fetchLocation: (solutionId, locationId) => (dispatch) =>
    dispatch(fetchLocation(solutionId, locationId)),
  setDetailsSearchSort: (solutionId, sortColumn, reverse) => (dispatch) =>
    dispatch(setSort(solutionId, sortColumn, reverse)),
  setDetailsSearchPagination: (solutionId, page, pageSize) => (dispatch) =>
    dispatch(setPagination(solutionId, page, pageSize)),
  fetchVinSearch: (solutionId, locationId) => (dispatch) => {
    dispatch(setCurrentLocation(locationId));
    dispatch(searchEntities(solutionId));
  },
  toggleShowFilters: (showFilters) => (dispatch) => {
    dispatch(toggleShowFilters(showFilters));
  },
  redirectToDashboardView:
    InventoryViewDetailsState.actionCreators.redirectToDashboardView,
};

export default connect(mapStateToProps, actionCreators)(InventoryViewDetails);
