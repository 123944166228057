/** @jsxImportSource @emotion/react */
import { ValidatorInput } from "pages/rtscao/formLibrary/Input";

import { useR2SContext } from "pages/rtscao/service/R2SContext";
import { AsyncPaginate, AsyncPaginateProps } from "react-select-async-paginate";
import Colors from "styles/colors";
import { components } from "react-select";
import { LadChicletCSS as Chiclet } from "components/chiclets";
import axios from "axios";
import apiUrl from "api-url";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

interface LocationValue {
  name: string;
  street: string;
  address: string;
  city: string;
  state: string;
  zip: string;
  postal_code: string;
  oem: boolean;
  supplier: boolean;
  lad?: any;
  country: string;
}

export interface Location {
  value: LocationValue;
  label: string;
}

export const locations: Location[] = [];

export function fetchLocations(query = "", loadedResults: any = []) {
  const url = apiUrl("/location/locations");

  const pageSize = 20;
  const nextPage = loadedResults.length / pageSize;

  const config = {
    params: {
      verbose: false,
      everything: query.length > 0 ? query : undefined,
      pageNumber: nextPage,
      pageSize,
      sortColumn: "name",
    },
  };

  return axios
    .get(url, config)
    .then((response) => {
      const results = response.data;

      return {
        hasMore: results.meta.currentPage < results.meta.totalPages - 1,
        options: results.data.map((location: any) => ({
          label: location.name,
          value: location,
        })),
      };
    })
    .catch((error) => {
      console.error(error);
      return { hasMore: false, options: [] };
    });
}

interface OptProps {
  label: string;
  innerProps: any;
  data: Location;
  selectProps: any;
  isDisabled: boolean;
  isSelected: boolean;
  isFocused: boolean;
}

const Opt: React.FC<OptProps> = ({
  label,
  innerProps,
  data,
  selectProps,
  isDisabled,
  isSelected,
  isFocused,
}) => {
  const street = data?.value?.address;
  const city = data?.value?.city;
  const state = data?.value?.state;
  return (
    <div
      ref={innerProps.ref}
      {...innerProps}
      className={selectProps.className}
      css={{
        fontSize: "12px",
        paddingLeft: "10px",
        paddingBottom: "10px",
        backgroundColor: isDisabled
          ? Colors.background.LIGHT_GRAY
          : isSelected
          ? "#EBF3FF"
          : isFocused
          ? "#DEEBFF"
          : "#fff",
        color: isDisabled
          ? "#aaa"
          : isSelected
          ? "#333"
          : isFocused
          ? "#333"
          : "#6d6d6d",
      }}
    >
      <div css={{ display: "flex", gap: 4 }}>
        <Chiclet
          style={{ minWidth: 20 }}
          lad={data?.value?.lad}
          height={20}
          width={20}
          gradient={undefined}
          showLadLabel={undefined}
          showLobLabel={undefined}
        />
        <strong>{label}</strong> - {street} {city} {state}
      </div>
    </div>
  );
};

const formatOptionLabel = ({ value, label }: Location): JSX.Element => {
  return (
    <div css={{ display: "flex", gap: 4 }}>
      <Chiclet
        style={{ minWidth: 20 }}
        lad={value?.lad}
        height={20}
        width={20}
        gradient={undefined}
        showLadLabel={undefined}
        showLobLabel={undefined}
      />
      <strong>{label}</strong> - {value?.address} {value?.city} {value?.state}
    </div>
  );
};

interface SelectLocationProps
  extends Partial<AsyncPaginateProps<Location, any, any, any>> {
  setSelected: (selected: Location | null) => void;
  selected: Location | null;
  name?: string;
  namePrefix?: string;
  required?: boolean;
  captureCurrentFormData?: () => any;
}

export function SelectLocation(props: SelectLocationProps): JSX.Element {
  const { editMode } = useR2SContext();
  const route = useSelector((state: any) => state.location);

  const captureCurrentFormData = props.captureCurrentFormData ?? (() => {});
  const name = props.name ?? props.namePrefix;

  useEffect(() => {
    if (route?.payload?.name === name) {
      setTimeout(() => {
        props.setSelected({
          label: route?.payload?.location?.name,
          value: {
            ...route?.payload?.location,
            lad: { ...route?.payload?.location.lad },
          },
        });
      }, 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [route]);

  if (editMode === false) {
    return (
      <div css={{ display: "flex", gap: 4 }}>
        <Chiclet
          style={{ minWidth: 20 }}
          lad={props.selected?.value?.lad}
          height={20}
          width={20}
          gradient={undefined}
          showLadLabel={undefined}
          showLobLabel={undefined}
        />
        <strong>{props.selected?.label}</strong>{" "}
        <span>
          {props.selected?.value?.address} {props.selected?.value?.city}{" "}
          {props.selected?.value?.state}
        </span>
      </div>
    );
  }

  const NoOptionsMessage = (props: any) => {
    const dispatch = useDispatch();
    const handleClick = () => {
      captureCurrentFormData();
      dispatch({
        type: "CREATE_LOCATION",
        query: { "create-ready-2-ship-order": name },
      });
    };
    return (
      <components.NoOptionsMessage {...props}>
        <span
          onClick={handleClick}
          css={{
            fontSize: 16,
            color: "black",
            fontWeight: 900,
            cursor: "pointer",
          }}
        >
          Add New Location
        </span>
      </components.NoOptionsMessage>
    );
  };

  return (
    <>
      <AsyncPaginate<Location, any, any>
        defaultOptions
        formatOptionLabel={formatOptionLabel}
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: props.isDisabled ? "#ffffff00" : "white",
            borderRadius: 4,
            border: "2px solid #dedede",
            flex: 1,
            minHeight: 0,
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            padding: "0px 4px",
          }),
          valueContainer: (styles) => ({
            ...styles,
            padding: "0px 8px",
          }),
        }}
        placeholder="Select Location"
        loadOptions={(inp: string, loadedResults) =>
          fetchLocations(inp, loadedResults)
        }
        onChange={props.setSelected}
        value={props.selected}
        components={{
          Option: Opt,
          NoOptionsMessage,
        }}
        {...props}
      />

      <ValidatorInput
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.label`
            : "location"
        }
        required={props.required}
        css={{ display: "none" }}
        value={props.selected?.label ?? ""}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          if ((ev.target as any).resetting) {
            props.setSelected(null);
          }
        }}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix ? `${props.namePrefix}.name` : "name"
        }
        value={props.selected?.value?.name ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.address`
            : "street"
        }
        value={props.selected?.value?.address ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix ? `${props.namePrefix}.city` : "city"
        }
        value={props.selected?.value?.city ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix ? `${props.namePrefix}.state` : "state"
        }
        value={props.selected?.value?.state ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.postal_code`
            : "postal_code"
        }
        value={props.selected?.value?.postal_code ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.country`
            : "country"
        }
        value={props.selected?.value?.country ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.lad.id`
            : "lad.id"
        }
        value={props.selected?.value?.lad?.id ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.lad.name`
            : "lad.name"
        }
        value={
          props.selected?.value?.lad?.name ??
          props.selected?.value?.lad?.default_name ??
          ""
        }
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.lad.canonical_name`
            : "lad.canonical_name"
        }
        value={
          props.selected?.value?.lad?.canonical_name ??
          props.selected?.value?.lad?.lad_name ??
          ""
        }
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.lad.code`
            : "lad.code"
        }
        value={props.selected?.value?.lad?.code ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.lad.description`
            : "lad.description"
        }
        value={props.selected?.value?.lad?.description ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.lad.lob_name`
            : "lad.lob_name"
        }
        value={props.selected?.value?.lad?.lob_name ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix ? `${props.namePrefix}.oem` : "oem"
        }
        value={props.selected?.value?.oem?.toString() ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.supplier`
            : "supplier"
        }
        value={props.selected?.value?.supplier?.toString() ?? ""}
      />
    </>
  );
}
