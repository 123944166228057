import { privateRouteGroupsMap } from "routes";

export const getDealerRedirect = (pathname: string) => {
  const allowedPaths = [
    privateRouteGroupsMap.PART_VIEW.routes.DEALER_PARTVIEW_DASHBOARD.path,
    privateRouteGroupsMap.PART_VIEW.routes.DEALER_PARTVIEW_SEARCH.path,
    privateRouteGroupsMap.PART_VIEW.routes.DEALER_PARTVIEW_DETAILS.path,
    privateRouteGroupsMap.FINISHED_VEHICLE.routes.VINVIEW_DASHBOARD.path,
    privateRouteGroupsMap.FINISHED_VEHICLE.routes.VINVIEW_SEARCH.path,
    "/vinview/details",
    "/driveaway",
    privateRouteGroupsMap.ADMINISTRATION.routes.USER_MANAGEMENT.path,
    privateRouteGroupsMap.USER.routes.PROFILE.path,
    "/documentation",
  ];

  const isPathAllowed = allowedPaths.some((path) => pathname.includes(path));

  // H2-1968: Redirect Dealers to VINView Dashboard if attempting to access non-dealer routes
  if (!isPathAllowed) {
    return privateRouteGroupsMap.FINISHED_VEHICLE.routes.VINVIEW_DASHBOARD.path;
  }
  return null;
};

export const getHealthCareRedirect = (pathname: string) => {
  const healthcareProviderDashboardPath = "/healthcare-container-tracking";
  const healthcareProviderAllowedPaths = [
    healthcareProviderDashboardPath,
    "/container-tracking-management",
    "/manage/users",
    "/profile",
    "/reports",
  ];
  let currentPathAllowed = false;

  healthcareProviderAllowedPaths.forEach((p) => {
    if (pathname?.includes(p)) {
      currentPathAllowed = true;
    }
  });

  // H2-634: Redirect Healthcare Providers to Asset Details if attempting to access non-healthcare routes
  if (!currentPathAllowed) {
    return healthcareProviderDashboardPath;
  }
  return null;
};

export const getPartnerRedirect = (pathname: string) => {
  const partnerDashboardPath = "/partners";
  const partnerAllowedPaths = [
    partnerDashboardPath,
    "/manage/users",
    "/documentation",
    "/container-tracking",
    "/container-tracking/search",
    "/container-tracking/details/:id",
    "/inventoryview",
    "/inventoryview/details",
    "/damageview",
    "/damageview/search",
    "/damageview/details",
    "/compliance",
    "/compliance/reports/:reportId",
  ];
  let currentPathAllowed = false;

  partnerAllowedPaths.forEach((p) => {
    if (pathname?.includes(p)) {
      currentPathAllowed = true;
    }
  });

  // H2-720: Redirect Partners to Partner Dashboard if attempting to access non-partner routes
  if (!currentPathAllowed) {
    return partnerDashboardPath;
  }
  return null;
};
