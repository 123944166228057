/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Colors from "styles/colors";

import { VictoryLabel, VictoryLegend, VictoryPie } from "victory";
import { PanelGroup } from "./PanelGroup.molecule";

export const PieChartWithLegends = ({ widgetData, title, subTitle, x, y }) => {
  const colorScale = widgetData.map((item) => item.fill);

  return (
    <PanelGroup>
      <PanelGroup.Content>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <VictoryLegend
            width={140}
            height={140}
            gutter={20}
            rowGutter={{ top: 0, bottom: 0 }}
            symbolSpacer={7}
            x={10}
            y={10}
            title={[subTitle, title]}
            data={widgetData}
            colorScale={colorScale}
            titleComponent={
              <VictoryLabel
                dx={-10}
                dy={-15}
                style={[
                  {
                    fontSize: 10,
                    fill: Colors.text.TOTALS_LABEL_GRAY,
                  },
                  {
                    fontSize: 14,
                    fontWeight: 600,
                    fill: Colors.text.DARK_GRAY,
                  },
                ]}
                lineHeight={[2, 1]}
              />
            }
            style={{
              labels: { fontSize: 12 },
            }}
          />
          <VictoryPie
            data={widgetData}
            width={140}
            height={140}
            innerRadius={40}
            colorScale={colorScale}
            x={x}
            y={y}
            padAngle={0}
            labels={() => null}
            padding={{ top: 10, right: 10, left: 10 }}
          />
        </div>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

PieChartWithLegends.propTypes = {
  widgetData: PropTypes.array,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
};
