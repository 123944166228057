/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Button, Checkboxes, PlantSelect, SortSelect } from "../Shared";
import { SearchBar } from "./SearchBar";
import { CreateOrderModal } from "../CreateOrder/CreateOrderModal";
import { useState } from "react";
import { Order } from "pages/dynamic-normal/service/orderServices";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  gap: 20px;
`;

const MiddleRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const PlantSelectArea = styled.div`
  flex: 0 0 270px;
`;

const SearchBarArea = styled.div`
  flex: 1;
  display: flex;
`;

const ViewingInfo = styled.div`
  flex: 1;
`;

const ShowFilters = styled.div`
  display: flex;
  align-items: center;
`;

const SortByArea = styled.div`
  width: 270px;
`;

const SearchButton = styled(Button)`
  background-color: #0d6efd;
  color: white;
`;

const CreateNewOrderButton = styled(Button)`
  background-color: #007c4d;
  color: white;
`;

interface PageHeaderProps {
  variant: "oem" | string;
  orders: Order[];
  plant: string | null;
  setPlant:
    | React.Dispatch<React.SetStateAction<string>>
    | React.Dispatch<React.SetStateAction<null>>;
  filterValue?: string;
  filterType?: string;
  setFilterValue: (value: string) => void;
  setFilterType: (type: string) => void;
  activeChecked: boolean;
  setActiveChecked: (checked: boolean) => void;
  archivedChecked: boolean;
  setArchivedChecked: (checked: boolean) => void;
  sort: string;
  setSort: (sort: string) => void;
  opportunities?: Order[];
  children?: React.ReactNode;
}

export function PageHeader(props: PageHeaderProps) {
  const [show, setShow] = useState<boolean>(false);
  const [filterValue, setFilterValue] = useState<string>(
    props.filterValue ?? "",
  );
  const [filterType, setFilterType] = useState<string>(props.filterType ?? "");

  return (
    <>
      <CreateOrderModal
        show={show}
        setShow={setShow}
        plant={props.plant}
        setPlant={props.setPlant}
      />
      <Container>
        <TopRow>
          {props.variant === "oem" && (
            <PlantSelectArea>
              <PlantSelect plant={props.plant} setPlant={props.setPlant} />
            </PlantSelectArea>
          )}
          {props.variant !== "oem" && (
            <ViewingInfo css={{ maxWidth: 135 }}>
              Viewing <strong>{props.orders?.length || 0} Orders</strong>
            </ViewingInfo>
          )}
          <SearchBarArea>
            <SearchBar
              onKeyUp={(ev: React.KeyboardEvent<HTMLInputElement>) => {
                if (ev.key === "Enter") {
                  props.setFilterValue(filterValue);
                  props.setFilterType(filterType);
                }
              }}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              filterType={filterType}
              setFilterType={setFilterType}
            />
          </SearchBarArea>
          <SearchButton
            onClick={() => {
              props.setFilterValue(filterValue);
              props.setFilterType(filterType);
            }}
          >
            Search
          </SearchButton>

          {props.variant === "oem" && (
            <CreateNewOrderButton onClick={() => setShow(true)}>
              New Order
            </CreateNewOrderButton>
          )}
          {props.variant !== "oem" && (
            <>
              <ShowFilters>
                <Checkboxes
                  activeChecked={props.activeChecked}
                  setActiveChecked={props.setActiveChecked}
                  archivedChecked={props.archivedChecked}
                  setArchivedChecked={props.setArchivedChecked}
                />
              </ShowFilters>
              <SortByArea>
                <SortSelect sort={props.sort} setSort={props.setSort} />
              </SortByArea>
            </>
          )}
        </TopRow>
        {props.opportunities && props.opportunities.length > 0 && (
          <div css={{ marginBottom: 20 }}>
            <MiddleRow>
              <ViewingInfo>
                Action Required for{" "}
                <strong>{props.opportunities?.length || 0} Orders</strong>
              </ViewingInfo>
            </MiddleRow>
            {props.children}
          </div>
        )}
        {props.variant === "oem" && (
          <BottomRow>
            <ViewingInfo>
              Viewing <strong>{props.orders?.length || 0} Orders</strong>
            </ViewingInfo>

            <SortByArea>
              <SortSelect sort={props.sort} setSort={props.setSort} />
            </SortByArea>
          </BottomRow>
        )}
      </Container>
    </>
  );
}
