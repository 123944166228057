/** @jsxImportSource @emotion/react */
import React from "react";
import styled from "@emotion/styled";

const StopItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`;

const Icon = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 4px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  margin-right: 10px;
`;

const Content = styled.div`
  flex: 1;
`;

const Title = styled.p`
  font-size: 14px;
  margin: 0;
  padding: 0;
  line-height: 14px;
`;

const Type = styled.strong`
  font-weight: bold;
  font-size: 14px;
  margin: 0;
  padding: 0;
  line-height: 14px;
`;

const Subtitle = styled.p`
  color: #666;
  font-size: 12px;
  margin: 0;
  padding: 0;
  line-height: 12px;
`;

interface Stop {
  icon: string;
  type: string;
  address: string;
  time: string;
}

interface TripScheduleProps {
  order?: {
    stops: Stop[];
  };
}

const TripSchedule: React.FC<TripScheduleProps> = ({ order }) => {
  const getIconColor = (icon: string): string => {
    switch (icon) {
      case "S":
        return "#4a6da7";
      case "D":
        return "#d68000";
      case "A":
        return "#a83232";
      default:
        return "#000000";
    }
  };

  const stops = order?.stops || [];

  return (
    <>
      {stops.map((stop, index) => (
        <StopItem key={index}>
          <Icon style={{ backgroundColor: getIconColor(stop.icon) }}>
            {stop.icon}
          </Icon>
          <Content>
            <Title>
              <Type>{stop.type}</Type> {stop.address}
            </Title>
            <Subtitle>{stop.time}</Subtitle>
          </Content>
        </StopItem>
      ))}
    </>
  );
};

export default TripSchedule;
