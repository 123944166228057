/* eslint-disable curly */
/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import { HideGlobalSpinner, ShowGlobalSpinner } from "components/GlobalSpinner";
import { ValidatorInput } from "pages/dynamic-normal/formLibrary/Input";
import { calculateCost } from "pages/dynamic-normal/service/costService";
import {
  calculateArrivalTime,
  getDrivingDistance,
  getRoute,
  updateDestDate,
  updateOriginDate,
} from "pages/dynamic-normal/service/heremapService";
import React, { useEffect, useState } from "react";
import { SelectDock } from "../CreateOrder/SelectDock";
import {
  Location,
  locations,
  SelectLocation,
} from "../CreateOrder/SelectLocation";
import {
  AddressBlock,
  AddressSection,
  AddressTitle,
  DateTimeInput,
  DockInfo,
  DockTimeStyled,
  DockTitle,
  formatSecondsIntoDaysAndHours,
  getDaysAndHoursBetweenDates,
  MapDot,
  Select,
} from "../Shared";

interface Route {
  departure?: {
    newTime: string;
  };
  arrival?: {
    newTime: string;
  };
  hmOrigin?: {
    position: {
      lat: number;
      lng: number;
    };
  };
  hmDest?: {
    position: {
      lat: number;
      lng: number;
    };
  };
  summary?: {
    duration: number;
    driveTime: string;
    miles: number;
    practicalDeliveryTime: string;
  };
}

interface Order {
  route?: {
    origin?: {
      lat: number;
      long: number;
    };
    destination?: {
      lat: number;
      long: number;
    };
    summary?: {
      duration: number;
      driveTime: string;
      miles: number;
      practicalDeliveryTime: string;
    };
  };
}

async function getAndSetRoute(
  origin: Location | null,
  dest: Location | null,
  setRoute: React.Dispatch<React.SetStateAction<Route | null>>,
  order: Order | null,
) {
  if (!origin || !origin.label || !dest || !dest.label) {
    return;
  }

  if (order) {
    setRoute({
      departure: {
        newTime: (origin as any).lateDate,
      },
      arrival: {
        newTime: (dest as any).lateDate,
      },
      hmOrigin: {
        position: {
          lat: order?.route?.origin?.lat ?? 0,
          lng: order?.route?.origin?.long ?? 0,
        },
      },
      hmDest: {
        position: {
          lat: order?.route?.destination?.lat ?? 0,
          lng: order?.route?.destination?.long ?? 0,
        },
      },
      summary: {
        duration: order?.route?.summary?.duration ?? 0,
        driveTime: order?.route?.summary?.driveTime ?? "",
        miles: order?.route?.summary?.miles ?? 0,
        practicalDeliveryTime:
          order?.route?.summary?.practicalDeliveryTime ?? "",
      },
    });
    return;
  }

  ShowGlobalSpinner("Fetching Route Data...");
  const { routes, coords1, coords2 } = await getRoute(origin.value, dest.value);

  const arrival = routes?.[0]?.sections?.[0]?.arrival;
  const departure = routes?.[0]?.sections?.[0]?.departure;
  const summary = routes?.[0]?.sections?.[0]?.summary;

  if (arrival && departure && summary) {
    summary.driveTime = formatSecondsIntoDaysAndHours(summary.duration);
    departure.newTime = new Date(departure.time);
    departure.newTime.setHours(departure.newTime.getHours() + 24);

    arrival.newTime = calculateArrivalTime(departure.newTime, summary.duration);
    summary.miles = getDrivingDistance(summary.length);
    departure.newTime = departure.newTime.toISOString();
    summary.practicalDeliveryTime = getDaysAndHoursBetweenDates(
      departure.newTime,
      arrival.newTime,
    );
  }

  setRoute({ summary, arrival, departure, hmOrigin: coords1, hmDest: coords2 });

  HideGlobalSpinner();
  return { routes, coords1, coords2 };
}

interface EditOriginDestinationCardProps {
  origin?: any;
  destination?: any;
  variant?: string;
  order?: Order | null;
}

export const EditOriginDestinationCard: React.FC<
  EditOriginDestinationCardProps
> = ({ origin, destination, variant, order }) => {
  const [_origin, setOrigin] = useState<Location | null>(null);
  const [_dest, setDest] = useState<Location | null>(null);
  const [route, setRoute] = useState<Route | null>(null);

  useEffect(() => {
    setOrigin(locations.find((loc) => loc.label === origin?.name) ?? null);
  }, [origin]);

  useEffect(() => {
    setDest(locations.find((loc) => loc.label === destination?.name) ?? null);
  }, [destination]);

  useEffect(() => {
    if (order) {
      getAndSetRoute(origin, destination, setRoute, order);
    } else {
      getAndSetRoute(_origin, _dest, setRoute, null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [`${_origin?.value?.name}-${_dest?.value?.name}`]);

  return (
    <>
      <div css={{ display: "flex" }}>
        <AddressBlock>
          <AddressTitle>{MapDot} Origin</AddressTitle>
          <AddressSection>
            <DockTitle>Origin / Pickup</DockTitle>
            <SelectLocation
              selected={_origin}
              setSelected={setOrigin}
              css={{ fontSize: 14 }}
              required
              namePrefix="origin"
            />

            <DockInfo css={{ gap: 10 }}>
              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Pickup By</DockTitle>
                <DateTimeInput
                  defaultValue={route?.departure?.newTime || origin?.lateDate}
                  onChange={(val) => updateDestDate(val, setRoute)}
                  required={variant !== "oem"}
                  name="origin.lateDate"
                />
              </DockTimeStyled>
              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Pickup Location</DockTitle>
                <SelectDock
                  defaultValue={origin?.location}
                  namePrefix="origin"
                />
              </DockTimeStyled>
              <DockTimeStyled css={{ fontSize: 14, flex: 1 }}>
                <DockTitle>At Dock</DockTitle>
                <Select
                  required
                  defaultValue={{
                    label: origin?.atDock,
                    value: origin?.atDock,
                  }}
                  name="origin.atDock"
                  placeholder=""
                  loadOptions={(inputStr: string) => ({
                    options: [
                      { value: "Yes", label: "Yes" },
                      { value: "No", label: "No" },
                    ].filter((yNo) =>
                      yNo.label.toLowerCase().includes(inputStr.toLowerCase()),
                    ),
                  })}
                />
              </DockTimeStyled>
            </DockInfo>
            <input
              type="hidden"
              name="supplierName"
              value={_origin?.value?.name ?? ""}
            />
            <DockTimeStyled>
              <DockTitle>Notes</DockTitle>
              <ValidatorInput
                defaultValue={origin?.notes}
                css={{ fontSize: 14 }}
                name="origin.notes"
              />
            </DockTimeStyled>
          </AddressSection>
        </AddressBlock>

        <AddressBlock>
          <AddressTitle>{MapDot} Destination</AddressTitle>
          <AddressSection>
            <DockTitle>Destination / Dropoff</DockTitle>

            <SelectLocation
              selected={_dest}
              setSelected={setDest}
              css={{ fontSize: 14 }}
              required
              namePrefix="destination"
            />

            <DockInfo css={{ gap: 10 }}>
              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Delivery By</DockTitle>
                <DateTimeInput
                  defaultValue={
                    route?.arrival?.newTime || destination?.lateDate
                  }
                  onChange={(val) => updateOriginDate(val, setRoute)}
                  required
                  name="destination.lateDate"
                />
              </DockTimeStyled>
              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Deliver Location</DockTitle>
                <SelectDock
                  defaultValue={destination?.location}
                  namePrefix="destination"
                />
              </DockTimeStyled>
            </DockInfo>
            <input
              type="hidden"
              name="companyName"
              value={_dest?.value?.name ?? ""}
            />
            <DockTimeStyled>
              <DockTitle>Notes</DockTitle>
              <ValidatorInput
                defaultValue={destination?.notes}
                css={{ fontSize: 14 }}
                name="destination.notes"
              />
            </DockTimeStyled>
          </AddressSection>
        </AddressBlock>
      </div>
      {variant !== "supplier" && (
        <div>
          {route && route.summary ? (
            <div css={{ display: "flex", gap: 10, margin: "0px 100px" }}>
              {/* <div css={{ flex: 1, fontSize: 14, textAlign: "center" }}> */}
              {/* <DockTitle>Miles</DockTitle> */}
              {/* <p>{route?.summary?.miles}</p> */}
              <input
                type="hidden"
                name="route.summary.miles"
                value={route?.summary?.miles}
              />
              {/* </div> */}

              {/* <div css={{ flex: 1, fontSize: 14, textAlign: "center" }}> */}
              {/* <DockTitle>Estimated Price *</DockTitle> */}
              {/* <p>{formatUSD(calculateCost(route?.summary?.miles))}</p> */}
              <input
                type="hidden"
                name="route.summary.estimatedCost"
                value={calculateCost(route?.summary?.miles)}
              />
              {/* </div> */}

              {/* <div css={{ flex: 1, fontSize: 14, textAlign: "center" }}> */}
              {/* <DockTitle>Drive Time</DockTitle> */}
              {/* <p>{route?.summary?.driveTime}</p> */}
              <input
                type="hidden"
                name="route.summary.driveTime"
                value={route?.summary?.driveTime}
              />
              {/* </div> */}

              {/* <div css={{ flex: 1, fontSize: 14, textAlign: "center" }}> */}
              {/* <DockTitle>Practical Delivery Time</DockTitle> */}
              {/* <p>{route?.summary?.practicalDeliveryTime}</p> */}
              <input
                type="hidden"
                name="route.summary.practicalDeliveryTime"
                value={route?.summary?.practicalDeliveryTime}
              />
              {/* </div> */}
              <input
                type="hidden"
                name="route.summary.duration"
                value={route?.summary?.duration}
              />
              <input
                type="hidden"
                name="route.origin.lat"
                value={route?.hmOrigin?.position?.lat}
              />
              <input
                type="hidden"
                name="route.origin.long"
                value={route?.hmOrigin?.position?.lng}
              />
              <input
                type="hidden"
                name="route.destination.lat"
                value={route?.hmDest?.position?.lat}
              />
              <input
                type="hidden"
                name="route.destination.long"
                value={route?.hmDest?.position?.lng}
              />
            </div>
          ) : (
            <div css={{ display: "flex", gap: 10, margin: "0px 100px" }}>
              {/* <div css={{ flex: 1, fontSize: 14, textAlign: "center" }}>
            <DockTitle>Route Error</DockTitle>
            <p>Could not calculate route</p>
          </div> */}
            </div>
          )}
        </div>
      )}
    </>
  );
};
