import { connect } from "react-redux";

import InventoryViewDashboard from "./InventoryView.Dashboard.page";

import InventoryViewDashboardState from "../redux/InventoryViewDashboardState";
import InventoryViewDetailsState from "../redux/InventoryViewDetailsState";
import InventoryViewDetailsSearchBarState from "../redux/InventoryViewDetailsSearchBarState";
import InventorySearchBarState from "../redux/InventoryViewDetailsSearchBarState";

const { setCurrentLocation } = InventorySearchBarState.actionCreators;

const mapStateToProps = (state) => {
  return {
    watchedLocations:
      InventoryViewDashboardState.selectors.getWatchedLocations(state),
    allWatchedLocations:
      InventoryViewDashboardState.selectors.getAllWatchedLocations(state),
    isWatchedLocationsLoading:
      InventoryViewDashboardState.selectors.getWatchedLocationsLoading(state),
    isAllWatchedLocationsLoading:
      InventoryViewDashboardState.selectors.getAllWatchedLocationsLoading(
        state,
      ),
    capacityMeterWidgetPageIndex:
      InventoryViewDashboardState.selectors.getCapacityMeterWidgetPageIndex(
        state,
      ),
  };
};

const actionCreators = {
  redirectToDashboardView:
    InventoryViewDetailsState.actionCreators.redirectToDashboardView,
  setSelectedTabIndex:
    InventoryViewDetailsState.actionCreators.setSelectedTabIndex,
  fetchWatchedLocations:
    InventoryViewDashboardState.actionCreators.fetchWatchedLocations,
  fetchAllWatchedLocations:
    InventoryViewDashboardState.actionCreators.fetchAllWatchedLocations,
  saveWatchedLocations:
    InventoryViewDashboardState.actionCreators.saveWatchedLocations,
  setCapacityMeterWidgetPageIndex:
    InventoryViewDashboardState.actionCreators.setCapacityMeterWidgetPageIndex,
  setShippableNonShippableCountsWidgetPageIndex:
    InventoryViewDashboardState.actionCreators
      .setShippableNonShippableCountsWidgetPageIndex,
  resetDetailsPageSearchAndFilters: () => (dispatch) => {
    dispatch(
      InventoryViewDetailsSearchBarState.actionCreators.resetSearchAndFilters(),
    );
  },
  redirectToInsightsView: (locationId) => (dispatch) => {
    dispatch(setCurrentLocation(locationId));
    dispatch(
      InventoryViewDetailsState.actionCreators.redirectToInsightsView(
        locationId,
      ),
    );
  },
};

export default connect(mapStateToProps, actionCreators)(InventoryViewDashboard);
