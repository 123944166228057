import moment from "moment";

import apiUrl from "api-url";
import buildSearchBarState from "components/search-bar/SearchBarStateBuilder";
import { SEARCH_CATEGORIES } from "../container-management/components/tag-management/ContainerManagement.searchOptions";
import { getFeatureName } from "modules/organizations/OrganizationsState";

const STORE_MOUNT_POINT = "ctManagementSearch";

const searchUrl = (queryString) => {
  return apiUrl(
    `/containertracking/api/reuse-trip-container-search/container?${queryString}`,
  );
};

const axiosConfig = (featureName) => {
  return {
    headers: {
      "x-time-zone": moment.tz.guess(),
      "x-target-feature": featureName,
    },
  };
};

const fetchSearch = (queryString = "", solutionId, duck, dispatch, state) => {
  const url = searchUrl(queryString, state);
  const featureName = getFeatureName(state);
  const config = axiosConfig(featureName);
  dispatch(duck.fetch(url, config));
};

const SearchBarState = buildSearchBarState(
  STORE_MOUNT_POINT,
  SEARCH_CATEGORIES,
  [],
  fetchSearch,
);

export default SearchBarState;
