/** @jsxImportSource @emotion/react */
import React from "react";
import { useUncontrolled } from "uncontrollable";
import { FormControl, InputGroup } from "react-bootstrap";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faTimes } from "@fortawesome/pro-light-svg-icons";

import Colors from "styles/colors";
import { Icon } from "components/atoms/Icon.atom";
import { InputSize } from "./enums";
import { css } from "@emotion/react";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";

type TextInputProps = {
  /**
   * The value of the input.
   */
  value?: string;
  /**
   * The type of the input.
   */
  type?: "text" | "password" | "email" | "number" | "tel" | "url";
  /**
   * The name of the input.
   */
  name?: string;
  /**
   * FormControl CSS override;
   */
  formControlCss?: React.CSSProperties;
  /**
   * The default value of the input.
   */
  defaultValue?: string;
  /**
   * A handler for the onChange event.
   *
   * This function is passed two parameters; the new value and the event object.
   *
   * e.g. (value, event) => void
   */
  onChange?: (value: string, event?: React.FormEvent<HTMLInputElement>) => void;
  /**
   * A handler for when the user presses enter.
   *
   * This function is passed two parameters; the new value and the event object.
   *
   * e.g. (value, event) => void
   */
  onPressEnter?: (
    value: string,
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  /**
   * A handler for when the user clicks the input.
   *
   * This function is passed wit one parameter; the event object.
   *
   * e.g. (event) => void
   */
  onClick?: (event: React.MouseEvent<HTMLInputElement>) => void;
  /**
   * The placeholder shown when there is no value.
   */
  placeholder?: string | null;
  /**
   * The size of the input (small, default, and large).
   */
  size?: string;
  /**
   * The icon to display infront of the input.
   */
  icon?: IconDefinition;
  /**
   * Disables the input.
   */
  disabled?: boolean;
  /**
   * Makes the input readOnly.
   */
  readOnly?: boolean;
  /**
   * Set the maximum length for this input.
   */
  maxLength?: number;
  /**
   * Show an X button when there is text typed into the input.
   * Clicking it will clear the text.
   */
  showClearButton?: boolean;
  /**
   * An object that will be used as the element styles for the TextInput.
   */
  style?: React.CSSProperties;
  /**
   * A string for defining one or more classes that will be added to the TextInput.
   */
  className?: string;
  /**
   * The data attribute for QA testing.
   */
  "data-qa"?: string;
};

const numberInputStyles = css`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  &:hover::-webkit-inner-spin-button,
  &:hover::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const TextInput = React.forwardRef<
  typeof FormControl<"input">,
  TextInputProps
>((props, textInputRef) => {
  // Controls the `value` prop.
  // This allows it to not be passed in but still maintain state in useUncontrolled.
  const {
    // Need to set a sensible default.
    value = "",
    onChange,
    onPressEnter,
    onClick,
    placeholder,
    size = InputSize.Default,
    icon = null,
    disabled = false,
    maxLength = null,
    showClearButton = false,
    style = {},
    readOnly,
    className,
    "data-qa": dataQa,
  } = useUncontrolled(props, {
    // Since we told useUncontrolled that `onChange` is the handler for the value state,
    // we don't have to null check it before calling it.
    // useUncontrolled gives us a default onChange
    value: "onChange",
  });

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event.currentTarget.value, event);
    }
  };

  const handleOnClick = (event: React.MouseEvent<HTMLInputElement>) => {
    if (onClick) {
      onClick(event);
    }
  };

  const handleOnKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (onPressEnter && event.key === "Enter") {
      onPressEnter(event.currentTarget.value, event);
    }
  };

  const handleOnClear = () => {
    if (onChange) {
      onChange("");
    }
  };

  let inputStyle = {};

  if (showClearButton) {
    // Add padding to the right to account for the x button.
    // Otherwise, the text and x button will overlap.
    //important keyword used so that it applies for small and large input size prop
    inputStyle = {
      ...inputStyle,
      paddingRight: "2em !important",
    };
  }

  if (icon) {
    // Remove the left-border added by InputGroup.Prepend,
    // since we don't want to separate the icon from the input.
    //important keyword used so that it applies for small and large input size prop
    inputStyle = {
      ...inputStyle,
      paddingLeft: `${size === "lg" ? "3.25em" : "2.72em"} !important`,
    };
  }

  return (
    <InputGroup
      size={size as "sm" | "lg" | undefined}
      className={className}
      style={style}
    >
      {/*  Position relative and absolute is used to focus styles around the icon's space as well */}
      {icon ? (
        <InputGroup.Text
          css={{
            backgroundColor: disabled ? "inherit" : Colors.background.WHITE,
            color: Colors.filters.VALUE_CLEAR_BUTTON_COLOR,
            paddingRight: 0,
            position: "absolute",
            top: size === "sm" ? 4 : 5,
            zIndex: 10,
            border: "none",
          }}
        >
          <Icon src={icon} />
        </InputGroup.Text>
      ) : null}
      <FormControl
        // The ref prop doesn't accept React.ForwardedRefs
        // so casting as any to ignore the error.
        // The ref should work the same regardless of the kind.
        ref={textInputRef as any}
        type={props.type ?? "text"}
        value={value}
        readOnly={readOnly}
        onClick={handleOnClick}
        onChange={handleOnChange}
        onKeyDown={handleOnKeyPress}
        placeholder={placeholder ?? undefined}
        disabled={disabled}
        maxLength={maxLength === null ? undefined : maxLength}
        data-qa={dataQa}
        name={props.name}
        css={[
          {
            borderRadius: "0.25rem !important",
            ...inputStyle,
            ...props.formControlCss,
          },
          props.type === "number" && numberInputStyles,
        ]}
      />
      {props.type === "number" && (
        <div
          css={{
            position: "absolute",
            right: "8px",
            top: "50%",
            transform: "translateY(-50%)",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "20px",
            pointerEvents: "none",
          }}
        >
          <div
            css={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              pointerEvents: "auto",
              transform: "translateY(5px)",
            }}
            onClick={() => {
              if (!onChange) {
                return;
              }
              onChange((Number(value) + 1).toString());
            }}
          >
            <Icon
              src={faChevronUp}
              css={{
                fontSize: "12px",
                color: "#707070",
              }}
            />
          </div>
          <div
            css={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              pointerEvents: "auto",
              transform: "translateY(-5px)",
            }}
            onClick={() => {
              if (!onChange) {
                return;
              }
              onChange((Number(value) - 1).toString());
            }}
          >
            <Icon
              src={faChevronDown}
              css={{
                fontSize: "12px",
                color: "#707070",
              }}
            />
          </div>
        </div>
      )}
      {showClearButton && value ? (
        <Icon
          src={faTimes}
          css={{
            position: "absolute",
            right: "1em",
            top: "50%",
            transform: "translateY(-50%)",
            zIndex: 10,
            color: Colors.filters.VALUE_CLEAR_BUTTON_COLOR,
            cursor: "pointer",
            ":hover": {
              color: Colors.filters.VALUE_CLEAR_BUTTON_HOVER_COLOR,
            },
          }}
          onClick={handleOnClear}
        />
      ) : null}
    </InputGroup>
  );
});

export { InputSize };
