/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Container, Select, Title } from "../Shared";
import { FontSize, Icon, IconType } from "components/atoms/Icon.atom";
import { faCubes } from "@fortawesome/pro-solid-svg-icons";
import { ValidatorInput } from "pages/dynamic-normal/formLibrary/Input";
import { EditTrailerSpaceVisualization } from "./EditTrailerSpaceVis";
import { ShipmentDetails as ShipmentDetailsInterface } from "pages/dynamic-normal/service/orderServices";

const Section = styled.div`
  border-radius: 4px;
  flex: 1;
`;

const SectionTitle = styled.h2`
  font-size: 14px;
  font-weight: bold;
  color: #333;
`;

const InfoGrid = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: row;
`;

const InfoItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-size: 14px;
  opacity: 40%;
  font-weight: bold;
`;

const ShipmentIcon = (
  <Icon
    style={{ color: "#0077be" }}
    type={IconType.FontAwesome}
    src={faCubes}
    size={FontSize.size16}
  />
);

interface EditShipmentDetailsProps {
  shipmentDetails?: ShipmentDetailsInterface | null;
}

const EditShipmentDetails: React.FC<EditShipmentDetailsProps> = ({
  shipmentDetails = {},
}) => {
  return (
    <>
      <Title>{ShipmentIcon} Shipment Details</Title>
      <Container>
        <GeneralInfo {...shipmentDetails} />
        <ShipUnitDetails {...shipmentDetails?.shipUnitDimensions} />
      </Container>
      <Container>
        <TrailerSpaceRequirement {...shipmentDetails} />
        <AdditionalTrailerInfo {...shipmentDetails} />
      </Container>
    </>
  );
};

interface GeneralInfoProps {
  confirmedQty?: number;
  grossWt?: number;
  shipUnitQty?: number;
  shipUnit?: string;
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({
  confirmedQty,
  grossWt,
  shipUnitQty,
  shipUnit,
}) => {
  return (
    <Section css={{ flex: 2 }}>
      <SectionTitle>General</SectionTitle>
      <InfoGrid css={{ paddingRight: 10 }}>
        <InfoItem css={{ fontSize: 14 }}>
          <Label>Confirm Item Qty</Label>
          <ValidatorInput
            defaultValue={confirmedQty}
            css={{ fontSize: 14 }}
            required
            type="number"
            name="shipmentDetails.confirmedQty"
          />
        </InfoItem>
        <InfoItem css={{ fontSize: 14 }}>
          <Label>Gross Wt</Label>
          <ValidatorInput
            defaultValue={grossWt}
            css={{ fontSize: 14 }}
            required
            type="number"
            name="shipmentDetails.grossWt"
          />
        </InfoItem>
        <InfoItem css={{ fontSize: 14 }}>
          <Label>Ship Unit Qty</Label>
          <ValidatorInput
            defaultValue={shipUnitQty}
            css={{ fontSize: 14 }}
            required
            type="number"
            name="shipmentDetails.shipUnitQty"
          />
        </InfoItem>
        <InfoItem css={{ fontSize: 14 }}>
          <Label>Ship Unit</Label>
          <Select
            defaultValue={{ label: shipUnit, value: shipUnit }}
            required
            name="shipmentDetails.shipUnit"
            placeholder=""
            loadOptions={(inputStr: string) => ({
              options: [
                { label: "Pallet", value: "Pallet" },
                { label: "Box", value: "Box" },
                { label: "Container", value: "Container" },
              ].filter((yNo) =>
                yNo.label.toLowerCase().includes(inputStr.toLowerCase()),
              ),
            })}
          />
        </InfoItem>
      </InfoGrid>
    </Section>
  );
};

interface ShipUnitDetailsProps {
  length?: number;
  height?: number;
  width?: number;
}

const ShipUnitDetails: React.FC<ShipUnitDetailsProps> = ({
  length,
  height,
  width,
}) => {
  return (
    <Section>
      <SectionTitle>Ship Unit Details</SectionTitle>
      <InfoGrid>
        <InfoItem
          css={{
            borderLeft: "2px solid #e0e0e0",
            paddingLeft: 10,
            fontSize: 14,
          }}
        >
          <Label>Length</Label>
          <ValidatorInput
            defaultValue={length}
            css={{ fontSize: 14 }}
            required
            type="number"
            name="shipmentDetails.shipUnitDimensions.length"
          />
        </InfoItem>
        <InfoItem css={{ fontSize: 14 }}>
          <Label>Height</Label>
          <ValidatorInput
            defaultValue={height}
            css={{ fontSize: 14 }}
            required
            type="number"
            name="shipmentDetails.shipUnitDimensions.height"
          />
        </InfoItem>
        <InfoItem css={{ fontSize: 14 }}>
          <Label>Width</Label>
          <ValidatorInput
            defaultValue={width}
            css={{ fontSize: 14 }}
            required
            type="number"
            name="shipmentDetails.shipUnitDimensions.width"
          />
        </InfoItem>
      </InfoGrid>
    </Section>
  );
};

interface TrailerSpaceRequirementProps {
  trailerSpaceRequirement?: number;
}

const TrailerSpaceRequirement: React.FC<TrailerSpaceRequirementProps> = ({
  trailerSpaceRequirement,
}) => {
  return (
    <Section css={{ maxWidth: 400 }}>
      <SectionTitle>Trailer Space Requirement</SectionTitle>
      <InfoGrid>
        <EditTrailerSpaceVisualization
          occupiedSpace={trailerSpaceRequirement}
        />
      </InfoGrid>
    </Section>
  );
};

interface AdditionalTrailerInfoProps {
  stackable?: string;
  hazmat?: string;
  temp?: string;
}

const AdditionalTrailerInfo: React.FC<AdditionalTrailerInfoProps> = ({
  stackable,
  hazmat,
  temp,
}) => {
  return (
    <Section>
      <InfoGrid css={{ marginTop: 30, paddingLeft: 50 }}>
        <InfoItem css={{ fontSize: 14 }}>
          <Label>Stackable</Label>
          <Select
            defaultValue={{ label: stackable, value: stackable }}
            required
            name="shipmentDetails.stackable"
            placeholder=""
            loadOptions={(inputStr: string) => ({
              options: [
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ].filter((yNo) =>
                yNo.label.toLowerCase().includes(inputStr.toLowerCase()),
              ),
            })}
          />
        </InfoItem>
        <InfoItem css={{ fontSize: 14 }}>
          <Label>Hazmat</Label>
          <Select
            defaultValue={{ label: hazmat, value: hazmat }}
            required
            name="shipmentDetails.hazmat"
            placeholder=""
            loadOptions={(inputStr: string) => ({
              options: [
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ].filter((yNo) =>
                yNo.label.toLowerCase().includes(inputStr.toLowerCase()),
              ),
            })}
          />
        </InfoItem>
        <InfoItem css={{ fontSize: 14 }}>
          <Label>Temp</Label>
          <Select
            defaultValue={{ label: temp, value: temp }}
            css={{ fontSize: 14 }}
            required
            name="shipmentDetails.temp"
            placeholder=""
            loadOptions={(inputStr: string) => ({
              options: [
                { value: "Yes", label: "Yes" },
                { value: "No", label: "No" },
              ].filter((yNo) =>
                yNo.label.toLowerCase().includes(inputStr.toLowerCase()),
              ),
            })}
          />
        </InfoItem>
      </InfoGrid>
    </Section>
  );
};

export default EditShipmentDetails;
