/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { useQuery, useQueryClient, QueryClient } from "@tanstack/react-query";
import { HideGlobalSpinner, ShowGlobalSpinner } from "components/GlobalSpinner";
import { useSetTitle } from "components/hooks/useSetTitle";
import { BaseTable } from "components/organisms/base-table/BaseTable.organism";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownPill } from "./components/DropdownPill";
import { MileRadiusSlider } from "./components/MileRadiusSlider";
import ShipmentSummaryCard from "./components/Opportunites/ShipmentSummaryCard";
import { OpportunityMap } from "./components/Opportunites/OpportunityMap";
import { Button, formatUSD, TruckSvg } from "./components/Shared";
import { useSearchParamState } from "./hooks/useSearchParams";
import { Carrier, Order, orderService } from "./service/orderServices";
import { useSelector } from "react-redux";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import {
  findOpportunitiesV2,
  OpportunityV2,
} from "./service/opportunityServiceV2";
import { calculateCost, calculatePremiumCost } from "./service/costService";

function getTruckColor(
  opportunity: OpportunityV2,
): "openNetwork" | "myCurrentCarrier" {
  if (opportunity.opportunity_type === "open_network") {
    return "openNetwork";
  }

  return "myCurrentCarrier";
}

function getCarrierType(opportunity: OpportunityV2): string {
  if (opportunity.opportunity_type === "open_network") {
    return "Open Opportunity Network";
  }

  return "My Current Carrier";
}

function getShipmentStatus(opportunity: OpportunityV2): string {
  if (opportunity.opportunity_type === "open_network") {
    return "";
  }

  if (opportunity.active_status === "pending" && !opportunity.asset_id) {
    return "Pending Asset";
  }

  if (opportunity.active_status === "pending") {
    return "Scheduled";
  }

  return "In Transit";
}

function getDistanceToPickup(opportunity: OpportunityV2): string {
  if (!opportunity.current_distance_to_pickup) {
    return "";
  }

  if (
    opportunity.current_distance_to_pickup &&
    opportunity.active_status === "pending" &&
    !opportunity.asset_id
  ) {
    return "";
  }

  if (opportunity.active_status === "pending") {
    return "";
  }

  return opportunity.current_distance_to_pickup.toFixed(2);
}

const SelectButton = styled(Button)`
  background-color: #0d6efd;
  color: white;
`;

async function awardToCarrier(
  order: Order,
  carrier: Carrier,
  queryClient: QueryClient,
  navBackToDynamicNormal: () => void,
): Promise<void> {
  ShowGlobalSpinner("Awarding to Carrier...");
  const updatedOrder: Order = { ...order };
  const ops = Object.keys(updatedOrder.opportunities);
  ops.forEach((op) => {
    updatedOrder.opportunities[op].status = "Not Awarded";
  });
  updatedOrder.opportunities[carrier.id] =
    updatedOrder.opportunities[carrier.id] || {};
  updatedOrder.opportunities[carrier.id].status = "Awarded";
  updatedOrder.carrierAwarded = true;
  updatedOrder.statusItems[2].status = "active";
  updatedOrder.statusItems[2].label = "Carrier Awarded";
  updatedOrder.statusItems[2].additionalInfo = carrier.carrierName;
  updatedOrder.carrier = carrier;
  await orderService.updateOrder(updatedOrder);
  queryClient.invalidateQueries({ queryKey: ["orders"] });
  HideGlobalSpinner();
  navBackToDynamicNormal();
}

export function DynamicNormalOpportunies(): JSX.Element {
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const activeOrg = useSelector(getActiveOrganization);
  const isFvOrg = true;

  const navBackToDynamicNormal = () => {
    dispatch({
      type: "ORDERS_OEM",
    });
  };

  useSetTitle(t("dynamicNormal:Dynamic Normal Orders"));
  const urlParams = new URLSearchParams(window.location.search);
  const orderId = urlParams.get("order");

  const [carrierType, setCarrierType] = useSearchParamState<string>(
    "carrier-type",
    "Carrier Type",
  );
  const [_radius, _setRadius] = useSearchParamState<number>("Radius", 75);
  const [radius] = useState<number>(50);
  const carrierTypeOptions = ["Carrier Type", "opt 1", "opt 2"];

  const { data, isFetching } = useQuery<Order>({
    throwOnError: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: [orderId],
    queryFn: () => orderService.getOrder(orderId as string),
  });

  const { data: _opportunitiesMap, isFetching: fetchingOpps } = useQuery({
    throwOnError: true,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    queryKey: [
      "opportunities",
      data?.route?.origin?.lat,
      data?.route?.origin?.long,
      radius,
      data,
    ],
    queryFn: () =>
      findOpportunitiesV2({
        og_lat: data?.route?.origin?.lat,
        og_long: data?.route?.origin?.long,
        dest_lat: data?.route?.destination?.lat,
        dest_long: data?.route?.destination?.long,
        radius: radius,
        order: data,
      }),
  });

  console.log("opportunitiesMap", _opportunitiesMap);

  const truckMarkers: any = {};

  // if FV user is viewing, show everything, otherwise filter by org viewing
  const opportunitiesMap = _opportunitiesMap?.rows?.filter((op) =>
    isFvOrg ? true : op.created_by_org_id === activeOrg?.organization_id,
  );

  const uberPrice = _opportunitiesMap?.uberQuote?.price?.amount ?? 0;

  if (uberPrice > 0) {
    opportunitiesMap?.unshift({
      carrierName: "",
      active_status: "pending",
      asset_id: null,
      origin_city: data?.origin.city ?? "",
      origin_state: data?.origin.state ?? "",
      destination_city: data?.destination.city ?? "",
      destination_state: data?.destination.state ?? "",
      current_distance_to_pickup: null,
      origin_proximity: 0,
      destination_proximity: 0,
      opportunity_type: "open_network",
      origin_actual_arrival: "",
      origin_latest_arrival: "",
      destination_latest_arrival: "",
      rank: 1,
      shipment_origin_lat: data?.route?.origin?.lat,
      shipment_origin_lon: data?.route?.origin?.long,
      shipment_dest_lat: data?.route?.destination?.lat,
      shipment_dest_lon: data?.route?.destination?.long,
      created_by_org_id: 928,
      carrier_organization_id: 928,
      shipment_id: "",
      truck_current_lat: data?.route?.origin?.lat,
      truck_current_lon: data?.route?.origin?.long,
      costOverride: uberPrice ? uberPrice / 100 : 0,
    });
  }

  useEffect(() => {
    if (isFetching || fetchingOpps) {
      ShowGlobalSpinner("Loading Order...");
    } else {
      HideGlobalSpinner();
    }
  }, [isFetching, fetchingOpps]);

  if (!orderId) {
    return <div>No Order ID</div>;
  }

  return (
    <div css={{ display: "flex", height: "100%", flexDirection: "column" }}>
      <div
        css={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 10,
          padding: "0px 10px",
        }}
      >
        <DropdownPill
          options={carrierTypeOptions}
          value={carrierType}
          onChange={setCarrierType}
          css={{ flex: 1 }}
        />

        <MileRadiusSlider
          css={{ flex: 2 }}
          value={_radius}
          setValue={_setRadius}
        />
      </div>

      <div css={{ height: "400px", position: "relative" }}>
        <ShipmentSummaryCard order={data} />
        <OpportunityMap
          center={data?.route?.origin}
          start={data?.route?.origin}
          arrival={data?.route?.destination}
          trucks={opportunitiesMap}
          truckMarkers={truckMarkers}
        />
      </div>

      <div css={{ height: "calc(100% - 475px)", overflow: "scroll" }}>
        <BaseTable
          rowHoverHandler={(row, cell, ev) => {
            if (!truckMarkers.highlightMarker) {
              return;
            }

            const { original } = row;
            const { asset_id } = original;
            const { carrier_organization_id } = original;

            if (ev.type === "mouseover") {
              truckMarkers.highlightMarker(
                `${carrier_organization_id}-${asset_id}`,
                true,
              );
            }
            if (ev.type === "mouseout") {
              truckMarkers.highlightMarker(
                `${carrier_organization_id}-${asset_id}`,
                false,
              );
            }
          }}
          pageSize={1000}
          columns={[
            {
              Header: "CarrierName",
              id: "carrierName",
              width: 200,
              accessor: (data) => data,
              Cell: ({ value }: { value: OpportunityV2 }) => (
                <div>
                  <div>{value.carrierName}</div>
                  <div>
                    <TruckSvg color={getTruckColor(value)} />
                    <span css={{ marginLeft: 5 }}>{getCarrierType(value)}</span>
                  </div>
                </div>
              ),
            },
            // {
            //   Header: "Type",
            //   accessor: "carrierType",
            // },
            {
              Header: "Shipment",
              id: "shipment_id",
              Cell: ({ value }: { value: OpportunityV2 }) => (
                <div>
                  <div>
                    <a
                      href={`https://portal.freightverify.com/shipments/${value.shipment_id}?orgId=${value.created_by_org_id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {value.shipment_id}
                    </a>
                  </div>
                  <div css={{ textTransform: "capitalize" }}>
                    {getShipmentStatus(value)}{" "}
                    {value.asset_id ? `(${value.asset_id})` : ""}
                  </div>
                  <div css={{ textTransform: "capitalize" }}>
                    {value.origin_city.toLowerCase()}{" "}
                    {value.origin_state.toUpperCase()}
                  </div>
                  <div css={{ textTransform: "capitalize" }}>
                    {value.destination_city.toLowerCase()}{" "}
                    {value.destination_state.toUpperCase()}
                  </div>
                </div>
              ),
              accessor: (data) => data,
            },
            {
              Header: "Miles to Supplier",
              width: 100,
              accessor: (data) => getDistanceToPickup(data),
            },
            {
              Header: "Additional Miles",
              id: "additional_miles",
              width: 100,
              accessor: (data) => data,
              Cell: ({ value }: { value: OpportunityV2 }) => {
                const ogProx = value.origin_proximity ?? 0;
                const destProx = value.destination_proximity ?? 0;
                const truckToOrigin = value.current_distance_to_pickup ?? 0;
                const pendingAdditionalMiles = ogProx + destProx;
                const inTransitAdditionalMiles = truckToOrigin + destProx;
                const additionalMiles =
                  value.active_status === "pending"
                    ? pendingAdditionalMiles
                    : inTransitAdditionalMiles;
                return (
                  <div>
                    <div>{additionalMiles.toFixed(2)}</div>
                  </div>
                );
              },
            },
            {
              Header: "Total Miles",
              id: "totalMiles",
              width: 100,
              accessor: (data) => data,
              Cell: ({ value }: { value: OpportunityV2 }) => {
                const ogProx = value.origin_proximity ?? 0;
                const destProx = value.destination_proximity ?? 0;
                const routeMiles = data?.route?.summary?.miles ?? 0;
                const totalMiles = ogProx + destProx + routeMiles;
                return (
                  <div>
                    <div>{totalMiles.toFixed(2)}</div>
                  </div>
                );
              },
            },
            {
              Header: "Offer Status",
              id: "active_status",
              width: 100,
              accessor: (data) => "Waiting",
            },
            {
              Header: "Price",
              id: "price",
              width: 100,
              accessor: (data) => data,
              Cell: ({ value }: { value: OpportunityV2 }) => {
                const ogProx = value.origin_proximity ?? 0;
                const destProx = value.destination_proximity ?? 0;
                const truckToOrigin = value.current_distance_to_pickup ?? 0;
                const pendingAdditionalMiles = ogProx + destProx;
                const inTransitAdditionalMiles = truckToOrigin + destProx;
                const additionalMiles =
                  value.active_status === "pending"
                    ? pendingAdditionalMiles
                    : inTransitAdditionalMiles;
                const routeMiles = data?.route?.summary?.miles ?? 0;
                // const totalMiles = ogProx + destProx + routeMiles;
                const originalCost = Number(calculateCost(routeMiles));
                const premiumCost = Number(calculatePremiumCost(routeMiles));
                const totalCostWithNewRoute = Number(
                  calculateCost(routeMiles + additionalMiles),
                );

                const additionalCostBase = totalCostWithNewRoute - originalCost;

                const stopOffCharge = 100;

                const additionalCarrierIncentive200percent =
                  additionalCostBase * 2;

                const additionalCost =
                  additionalCostBase +
                  stopOffCharge +
                  additionalCarrierIncentive200percent;

                const costOverride = value.costOverride;

                return (
                  <div>
                    <div css={{ textDecoration: "line-through", color: "red" }}>
                      {formatUSD(premiumCost)}
                    </div>
                    <div css={{ textDecoration: "line-through" }}>
                      {formatUSD(originalCost)}
                    </div>
                    <div>{formatUSD(costOverride ?? additionalCost)}</div>
                  </div>
                );
              },
            },
            {
              Header: () => null,
              id: "award-button",
              width: 85,
              Cell: ({ value }) => (
                <SelectButton
                  onClick={() =>
                    awardToCarrier(
                      data as Order,
                      value,
                      queryClient,
                      navBackToDynamicNormal,
                    )
                  }
                >
                  Award
                </SelectButton>
              ),
              accessor: (data) => data,
            },
          ]}
          data={opportunitiesMap ?? []}
          onFilterChange={() => {}}
          onPageChange={() => {}}
          onPageSizeChange={() => {}}
          onPageSort={() => {}}
        />
      </div>
    </div>
  );
}
