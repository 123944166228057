let dieselPriceCache: number | null = null;

interface PricingInput {
  maxMiles: number | null;
  price: number;
}

interface PricingRule {
  maxMiles: number;
  basePrice: number;
  pricePerMile: number;
}

function generatePricingRules(inputs: PricingInput[]): PricingRule[] {
  return inputs.map((input, index, array) => {
    input.maxMiles = input.maxMiles ?? Infinity;

    if (index === 0) {
      return {
        maxMiles: input.maxMiles,
        basePrice: input.price,
        pricePerMile: 0,
      };
    } else {
      const prevInput = array[index - 1];
      prevInput.maxMiles = prevInput.maxMiles ?? 0;
      const pricePerMile =
        (input.price - prevInput.price) / (input.maxMiles - prevInput.maxMiles);
      return {
        maxMiles: input.maxMiles,
        basePrice: input.price,
        pricePerMile: pricePerMile,
      };
    }
  });
}

const roundTripPricing: PricingInput[] = [
  { maxMiles: 50, price: 11.64 },
  { maxMiles: 150, price: 7.18 },
  { maxMiles: 300, price: 3.32 },
  { maxMiles: 500, price: 2.89 },
  { maxMiles: 1000, price: 2.14 },
  { maxMiles: 2000, price: 1.64 },
  { maxMiles: null, price: 1.49 },
];

const oneWayPricing: PricingInput[] = [
  { maxMiles: 50, price: 10.64 },
  { maxMiles: 150, price: 4.18 },
  { maxMiles: 300, price: 2.67 },
  { maxMiles: 500, price: 1.97 },
  { maxMiles: 1000, price: 1.76 },
  { maxMiles: 2000, price: 1.51 },
  { maxMiles: null, price: 1.26 },
];

const roundTripPricingRules = generatePricingRules(roundTripPricing);
const oneWayPricingRules = generatePricingRules(oneWayPricing);

export function calculateCostNumber(
  miles: number,
  isRoundTrip: boolean,
): number {
  const pricingRules = isRoundTrip ? roundTripPricingRules : oneWayPricingRules;
  const rule =
    pricingRules.find((r) => miles <= r.maxMiles) ||
    pricingRules[pricingRules.length - 1];
  const previousRule = pricingRules[pricingRules.indexOf(rule) - 1];

  let baseCost: number;
  if (previousRule) {
    const excessMiles = miles - previousRule.maxMiles;
    baseCost = previousRule.basePrice + excessMiles * rule.pricePerMile;
  } else {
    baseCost = rule.basePrice;
  }

  return baseCost * miles;
}

export function getCachedDieselPriceOrDefault(defaultPrice: number) {
  if (dieselPriceCache === null) {
    return defaultPrice;
  } else {
    return dieselPriceCache;
  }
}

export function calculateCost(miles: number, isRoundTrip: boolean = false) {
  const dieselPrice = getCachedDieselPriceOrDefault(4.0);
  const cost = calculateCostNumber(miles, isRoundTrip);
  const averageSemiTruckFuelEconomy = 6.5; // miles per gallon
  const fuelCost = (miles / averageSemiTruckFuelEconomy) * dieselPrice;
  const totalCost = cost + fuelCost;

  return totalCost.toFixed(2);
}

export function calculatePremiumCost(
  miles: number,
  isRoundTrip: boolean = false,
) {
  const dieselPrice = getCachedDieselPriceOrDefault(4.0);
  const premiumCost = calculateCostNumber(miles, isRoundTrip) * 3;
  const averageSemiTruckFuelEconomy = 6.5; // miles per gallon
  const fuelCost = (miles / averageSemiTruckFuelEconomy) * dieselPrice;
  const totalCost = premiumCost + fuelCost;

  return totalCost.toFixed(2);
}

let fetchingDieselPrice: any = false;
export async function getCurrentDieselPrice(): Promise<any> {
  if (fetchingDieselPrice) {
    return fetchingDieselPrice;
  }
  if (dieselPriceCache) {
    return dieselPriceCache;
  }
  const url = `https://api.eia.gov/v2/petroleum/pri/gnd/data/?api_key=fUlrLoZqSQHUzf7fPwyqGifH2EcOADvGKD5zTj42&frequency=weekly&data[0]=value&facets[series][]=EMD_EPD2D_PTE_NUS_DPG&sort[0][column]=period&sort[0][direction]=desc&offset=0&length=5`;

  try {
    fetchingDieselPrice = fetch(url);
    const response = await fetchingDieselPrice;
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    dieselPriceCache = Number(data?.response?.data?.[0]?.value);
    return Number(data?.response?.data?.[0]?.value);
  } catch (error) {
    return 4.0;
    // console.error("Error fetching diesel prices:", error);
    // throw error;
  }
}
