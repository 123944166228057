/** @jsxImportSource @emotion/react */

import React, { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useHeightExpandAnimation } from "../../hooks/useHeightExpandAnimation";
import { useSearchParamState } from "../../hooks/useSearchParams";
import { Card } from "../Shared";
import { Form } from "pages/rtscao/formLibrary/useForm";
import { Order, orderService } from "pages/rtscao/service/orderServices";
import { HideGlobalSpinner, ShowGlobalSpinner } from "components/GlobalSpinner";
import {
  CardFooter,
  CardHeader,
  CardLeftExpandArea,
  CardMainContentArea,
  ExpandButton,
  SectionLeft,
  SectionRight,
} from "./OrderCardComponents";
import { getLeftSectionVariant } from "./OrderCardLeftSection";
import { getRightSectionVariant } from "./OrderCardRightSection";
import { OemButtonSection } from "./OrderCardButtonSectionOem";
import { DeclinedButtonSection } from "./OrderCardButtonSectionDeclined";
import { CarrierButtonSection } from "./OrderCardButtonSectionCarrier";
import { SupplierButtonSection } from "./OrderCardButtonSectionSupplier";
import { StatusSection } from "./OrderCardStatusSection";
import { ExpandedSection } from "./OrderCardExpandedDetails";

type Variant = "oem" | "declined" | "offers" | "supplier" | "carrier";

interface OrderCardProps {
  order: Order;
  variant: Variant;
  opportunity?: boolean;
}

function getButtonSectionVariant(variant: Variant) {
  switch (variant) {
    case "oem":
      return OemButtonSection;
    case "declined":
      return DeclinedButtonSection;
    case "offers":
    case "carrier":
      return CarrierButtonSection;
    case "supplier":
      return SupplierButtonSection;
    default:
      return OemButtonSection;
  }
}

export const OrderCard: React.FC<OrderCardProps> = ({
  order,
  variant,
  opportunity,
}) => {
  const queryClient = useQueryClient();
  const editOrShow = true; //order.statusItems[0].status === "inactive";
  const [editMode, setEditMode] = useState(editOrShow);

  async function handleOrderCardSubmit(data: Order) {
    if (Object.keys(data).length === 0) {
      return false;
    }

    if (variant === "supplier" || variant === "oem") {
      ShowGlobalSpinner("Updating Order...");
      const newOrder = { ...order, ...data };

      if (variant === "supplier") {
        newOrder.opportunities = newOrder.opportunities || {};
        newOrder.statusItems[0].status = "active";
      }

      newOrder.statusItems[0].status = "active";
      // const resp = await findOpportunitiesV2({
      //   og_lat: newOrder?.route?.origin?.lat,
      //   og_long: newOrder?.route?.origin?.long,
      //   dest_lat: newOrder?.route?.destination?.lat,
      //   dest_long: newOrder?.route?.destination?.long,
      //   order: newOrder,
      // });

      if (newOrder.enteredCost) {
        newOrder.statusItems[2].status = "active";
      } else {
        newOrder.statusItems[2].status = "inactive";
      }

      // newOrder.uberQuote = resp.uberQuote;
      await orderService.updateOrder(newOrder);
      queryClient.invalidateQueries({ queryKey: ["orders"] });
      setEditMode(false);
      HideGlobalSpinner();
      return false;
    }
  }

  const [expanded, setExpanded] = useSearchParamState(
    `${order.id}-expanded`,
    order.newlyCreated ?? false,
  );
  const expandedContentRef = useHeightExpandAnimation(expanded);
  const expandedFooterRef = useHeightExpandAnimation(expanded);

  const declined = order.statusItems[0].status === "declined";
  const inactive = order.statusItems[0].status === "inactive";
  const carrierAccepted = order.statusItems[2].status === "active";

  const lessThanTwoHours =
    new Date(order.origin.lateDate).getTime() - Date.now() < 2 * 60 * 60 * 1000;

  if (declined) {
    order.statusItems[1].status = "inactive";
    order.statusItems[2].status = "inactive";
    order.statusItems[2].additionalInfo = "";
    order.statusItems[2].additionalInfoIcon = "";
    order.statusItems[3].status = "inactive";
    order.statusItems[4].status = "inactive";
  } else {
    order.statusItems[1].status =
      order?.origin?.atDock === "Yes"
        ? "active"
        : lessThanTwoHours
        ? "pending"
        : "inactive";
  }

  if (lessThanTwoHours && !carrierAccepted && !declined) {
    order.statusItems[2].additionalInfo = "Under 2 Hours remaining";
    order.statusItems[2].additionalInfoIcon = "clock";
    order.statusItems[2].status = "pending";
  }

  if ((declined || inactive) && variant === "carrier") {
    return null;
  }

  const ButtonSection = getButtonSectionVariant(
    declined ? "declined" : variant,
  );
  const LeftSection = getLeftSectionVariant(declined ? "declined" : variant);
  const RightSection = getRightSectionVariant(variant);

  return (
    <Form onSubmit={handleOrderCardSubmit}>
      <Card>
        <CardLeftExpandArea>
          <ExpandButton
            type="button"
            expanded={expanded}
            onClick={() => setExpanded(!expanded)}
          >
            {expanded ? "-" : "+"}
          </ExpandButton>
        </CardLeftExpandArea>
        <CardMainContentArea>
          <CardHeader expanded={expanded}>
            <SectionLeft>
              <LeftSection order={order} variant={variant} />
            </SectionLeft>

            <StatusSection order={order} opportunity={opportunity} />
            <SectionRight>
              <RightSection order={order} />
            </SectionRight>
          </CardHeader>

          <ExpandedSection
            fRef={expandedContentRef}
            editMode={editMode}
            variant={variant}
            order={order}
          />
          <input
            type="hidden"
            name="uberQuote.uber_quote_uuid"
            value={order?.uberQuote?.uber_quote_uuid}
          />
          <input
            type="hidden"
            name="uberQuote.price.amount"
            value={order?.uberQuote?.price?.amount}
          />
          <input
            type="hidden"
            name="uberQuote.price.currency_code"
            value={order?.uberQuote?.price?.currency_code}
          />
          <input
            type="hidden"
            name="uberQuote.expiration_time_utc"
            value={order?.uberQuote?.expiration_time_utc}
          />
          <input
            type="hidden"
            name="uberQuote.status"
            value={order?.uberQuote?.status}
          />
          <input
            type="hidden"
            name="uberQuote.notes"
            value={order?.uberQuote?.notes}
          />
          <input
            type="hidden"
            name="uberQuote.uber_quote_id"
            value={order?.uberQuote?.uber_quote_id}
          />
          <CardFooter ref={expandedFooterRef}>
            <ButtonSection
              order={order}
              queryClient={queryClient}
              editMode={false}
              setEditMode={setEditMode}
              variant={variant}
            />
          </CardFooter>
        </CardMainContentArea>
      </Card>
    </Form>
  );
};
