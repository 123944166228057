/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Button, InfoText } from "../Shared";
import { ReactNode } from "react";

export const CardLeftExpandArea = styled.div`
  flex: 1;
  display: flex;
  max-width: 36px;
`;

export const ShowOpportunitiesButton = styled(Button)`
  margin-top: 10px;
  font-size: 12px;
  padding: 5px;
  background-color: #0d6efd;
  color: white;
`;

export const CardMainContentArea = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

interface CardHeaderProps {
  expanded: boolean;
}

export const CardHeader = styled.div<CardHeaderProps>`
  border-bottom: ${(props) => (props.expanded ? "1px solid #F0F0F0" : "")};
  display: flex;
  gap: 10px;
  margin-right: 2px;
`;

export const CardFooter = styled.div`
  transition: all 0.3s;
  height: 0px;
  overflow: hidden;
  border-top: 1px solid #f0f0f0;
  gap: 10px;
  justify-content: end;
  margin-right: 2px;
`;

export const CompanyName = styled.h3`
  margin: 0px 0px 2px;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
`;

export const OrderNumber = styled.h3`
  margin: 0px 0px 2px;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
`;

interface ExpandButtonProps {
  expanded: boolean;
}

export const ExpandButton = styled.button<ExpandButtonProps>`
  background-color: #abb2c2;
  color: #666;
  transition: all 0.3s;
  display: grid;
  max-width: 37px;
  border: none;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  font-size: 20px;
  flex: 1;
  cursor: pointer;
  margin: 0px;
`;

export const ProductInfo = styled.p`
  margin: 0px 0px 2px;
  font-size: 14px;
  color: #666;
  line-height: 18px;
`;

export const StatusBar = styled.div`
  display: flex;
  flex: 6;
  gap: 5px;
  margin: 10px;
  text-align: center;
`;

export const StatusItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
`;

interface StatusDotProps {
  status: "active" | "pending" | "declined" | "opportunity" | "inactive";
}

export const StatusDot = styled.div<StatusDotProps>`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid;
  background-color: ${(props) =>
    props.status === "active"
      ? "#4CAF50"
      : props.status === "pending"
      ? "#FFFFFF"
      : props.status === "declined"
      ? "#CF3E37"
      : "#FFFFFF"};
  border-color: ${(props) =>
    props.status === "active"
      ? "#4CAF50"
      : props.status === "pending"
      ? "#F4AD24"
      : props.status === "declined"
      ? "#CF3E37"
      : props.status === "opportunity"
      ? "#0D6EFD"
      : "#E0E0E0"};
`;

export const StatusLineConnector = styled.div`
  border-top: 1px solid #f0f0f0;
  width: 75%;
  transform: translate(calc(62% + 12px), 5px);
`;

export const StatusLabel = styled.span<StatusDotProps>`
  font-size: 14px;
  font-weight: ${(props) =>
    props.status === "active" ||
    props.status === "pending" ||
    props.status === "declined"
      ? "bold"
      : "normal"};
  color: ${(props) =>
    props.status === "active"
      ? "#666"
      : props.status === "pending"
      ? "#F4AD24"
      : props.status === "declined"
      ? "#CF3E37"
      : "#666"};
`;

export const StatusDescription = styled.span<StatusDotProps>`
  font-size: 14px;
  color: ${(props) =>
    props.status === "active"
      ? "#666"
      : props.status === "pending"
      ? "#F4AD24"
      : "#666"};
`;

export const ExpandedContent = styled.div`
  transition: all 0.3s;
  height: 0px;
  overflow: hidden;
  background-color: #f0f0f0;
  margin-right: 2px;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin: 10px;
`;

export const Section = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 10px;
  & * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const SectionLeft = styled(Section)`
  flex: 12;
  border-right: 1px solid #f0f0f0;
  min-height: 100px;
`;

export const SectionRight = styled(Section)`
  flex: 3;
  border-left: 1px solid #f0f0f0;
  text-align: right;
`;

export const DockTimeStyled = styled.div`
  flex: 2;
`;

export const DockTitle = styled.p`
  font-size: 14px;
  opacity: 40%;
  font-weight: bold;
  margin: 0;
  padding: 0;
`;

interface DeliverByProps {
  children: ReactNode;
}

export const DeliverBy: React.FC<DeliverByProps> = (props) => {
  return (
    <DockTimeStyled css={{ lineHeight: "18px" }}>
      <DockTitle
        css={{
          display: "inline-block",
          opacity: 100,
          fontSize: 14,
          marginRight: 5,
        }}
      >
        Deliver By:
      </DockTitle>
      <InfoText css={{ display: "inline-block" }}>{props.children}</InfoText>
    </DockTimeStyled>
  );
};

export const ClockIcon: JSX.Element = (
  <svg
    style={{ margin: "0px 5px 1px" }}
    width="12"
    height="12"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.2501 1.00492C12.3814 1.65806 13.3224 2.59531 13.98 3.72389C14.6377 4.85247 14.9892 6.13325 14.9998 7.43942C15.0103 8.7456 14.6795 10.0319 14.0402 11.1709C13.4008 12.31 12.4751 13.2623 11.3546 13.9337C10.2341 14.605 8.95764 14.972 7.65167 14.9985C6.3457 15.0249 5.05547 14.7098 3.90871 14.0844C2.76195 13.4589 1.79843 12.5448 1.11351 11.4326C0.428602 10.3203 0.0460575 9.04845 0.00375013 7.74292L0 7.49992L0.00375013 7.25692C0.045753 5.96166 0.422666 4.69939 1.09774 3.59316C1.77282 2.48694 2.72302 1.57451 3.85572 0.944831C4.98841 0.315154 6.26494 -0.0102831 7.56085 0.000247684C8.85676 0.0107785 10.1278 0.356918 11.2501 1.00492ZM7.50009 2.99992C7.31639 2.99994 7.13908 3.06739 7.00181 3.18946C6.86453 3.31152 6.77683 3.47973 6.75533 3.66217L6.75008 3.74992V7.49992L6.75683 7.59817C6.77393 7.72829 6.82488 7.85165 6.90459 7.95592L6.96984 8.03092L9.21986 10.2809L9.29036 10.3424C9.4219 10.4445 9.58364 10.4999 9.75012 10.4999C9.9166 10.4999 10.0783 10.4445 10.2099 10.3424L10.2804 10.2802L10.3426 10.2097C10.4447 10.0781 10.5001 9.91639 10.5001 9.74992C10.5001 9.58344 10.4447 9.4217 10.3426 9.29017L10.2804 9.21967L8.2501 7.18867V3.74992L8.24485 3.66217C8.22336 3.47973 8.13566 3.31152 7.99838 3.18946C7.8611 3.06739 7.68379 2.99994 7.50009 2.99992Z"
      fill="currentColor"
    />
  </svg>
);

export const additionalInfoIconMap: Record<string, JSX.Element> = {
  clock: ClockIcon,
};

export const CarrierPickupDeliverTitle = styled.h3`
  margin: 0px 0px 2px;
  font-size: 15px;
  font-weight: bold;
  line-height: 18px;
`;

export const CarrierPickupDeliverSubTitle = styled.h3`
  margin: 0px 0px 2px 46px;
  font-size: 14px;
  line-height: 18px;
`;
