import _ from "lodash";
import moment from "moment";
import {
  FUZZY_PREFIX,
  SELECT_ALL_OPTION_VALUE,
  SELECT_EMPTY_OPTION_VALUE,
} from "components/molecules/SearchableSelectList.molecule";
import { getLocations } from "pages/administration/location-management/redux/Locations.state";

// query parameter builders
export const getBasicQueryString = (queryParameter, filterValue) => {
  let url = "";
  if (isValidBasicFilter(filterValue)) {
    url += `&${queryParameter}=${encodeURIComponent(filterValue)}`;
  }
  return url;
};

/**
 * This method returns whether or not the `value` from an option is "static".
 *
 * @param {string} value The value from an option.
 * @returns
 */
const getIsStaticOptionValue = (value) => {
  return (
    value === SELECT_EMPTY_OPTION_VALUE ||
    value === SELECT_ALL_OPTION_VALUE ||
    value?.toString().startsWith(FUZZY_PREFIX)
  );
};

/**
 * This method returns the filterValues that are not "static".
 * `filterValues` can be an array of options objects or strings
 * and it must return the same type that is passed in.
 *
 * @param {*} filterValues
 * @returns
 */
export const getNonStaticFilterValues = (filterValues) => {
  return (
    // `filterValues` can be an array of option objects or strings.
    filterValues.filter((optionOrValue) => {
      let value;

      if (typeof optionOrValue === "object") {
        // Sometimes the "value" sent to the API is not what is in the value field.
        // But since static options always use the `value` property,
        // we only need to check that field.
        value = optionOrValue.value;
      } else {
        value = optionOrValue;
      }

      return !getIsStaticOptionValue(value);
    })
  );
};

/**
 * This method returns the filterValues that are "static".
 * These can be array of option objects or strings.  Only
 * the strings are required to be returned here.
 *
 * @param {*} filterValues
 * @returns
 */
export const getStaticFilterValues = (filterValues) => {
  return (
    // `filterValues` can be an array of option objects or strings.
    filterValues.filter((optionOrValue) => {
      let value;

      if (typeof optionOrValue === "object") {
        // Sometimes the "value" sent to the API is not what is in the value field.
        // But since static options always use the `value` property,
        // we only need to check that field.
        value = optionOrValue.value;
      } else {
        value = optionOrValue;
      }

      return getIsStaticOptionValue(value);
    })
  );
};

/**
 * This method builds a piece of the overall query string
 * based on the queryParameter and filterValue.
 *
 * It also handles special cases for "Select all", "Select empty values",
 * and "Fuzzy" searching.
 *
 * @param {*} queryParameter
 * @param {*} filterValue single value OR array of filter values
 * @param {*} queryParameterPrefix
 * @param {*} queryParameterSuffix
 * @returns {string}
 */
export const getBasicWithStaticOptionsQueryString = (
  queryParameter,
  filterValue,
  queryParameterPrefix = "",
  queryParameterSuffix = "",
) => {
  if (!filterValue) {
    return "";
  }

  // some old saved searches may have a single value, convert to array
  const filterValues = !Array.isArray(filterValue)
    ? [filterValue]
    : filterValue;

  if (filterValues.includes(SELECT_ALL_OPTION_VALUE)) {
    // use :isNotNull in query parameter key for "Select All"
    return `&${queryParameter}:isNotNull=true`;
  }

  // these are all non-static filter values, which excludes
  // select empty, select all, and fuzzy search values
  const nonStaticFilterValues = filterValues
    .filter((value) => value !== SELECT_EMPTY_OPTION_VALUE)
    .filter((value) => value !== SELECT_ALL_OPTION_VALUE)
    .filter((value) => !value?.toString().startsWith(FUZZY_PREFIX));

  // these are all the current fuzzy search values
  const fuzzyOptionFilterValues = filterValues
    .filter((value) => value?.toString().startsWith(FUZZY_PREFIX))
    .map((value) => value?.toString().substring(FUZZY_PREFIX.length));

  let url = "";
  if (filterValues.includes(SELECT_EMPTY_OPTION_VALUE)) {
    // use :isNull in query parameter key for "Select empty values"
    url += `&${queryParameter}:isNull=true`;
  }
  if (fuzzyOptionFilterValues.length > 0) {
    // use :contains to handle fuzzy searches
    url += `&${queryParameter}:contains=${fuzzyOptionFilterValues.toString()}`;
  }
  // Only use the prefix and suffix when provided for non-static QSPs
  const fullQueryParameter = `${queryParameterPrefix}${queryParameter}${queryParameterSuffix}`;
  url += getBasicQueryString(fullQueryParameter, nonStaticFilterValues);
  return url;
};

// Some endpoints require params to be JSON strings (uncommon)
export const getJsonQueryString = (queryParameter, filterValue) => {
  let url = "";
  if (isValidBasicFilter(filterValue)) {
    url += `&${queryParameter}=${JSON.stringify(filterValue)}`;
  }
  return url;
};

/**
 * In some cases where filter label and value are different, this query string
 * method will give priority to the value instead of the label.
 */
export const getBasicQueryStringFilterValuePriority = (
  queryParameter,
  filterLabel,
  filterValue,
) => {
  return getBasicWithStaticOptionsQueryString(
    queryParameter,
    filterValue || filterLabel,
  );
};

export const getNQueryStringFilterValuePriority = (
  queryParameters, // For NFilterButton, this is the array of queryKeys.
  filterValues,
) => {
  return _.map(queryParameters, (key) => {
    if (_.isNil(filterValues[key])) {
      return "";
    } else if (Array.isArray(filterValues[key])) {
      return getMultiSelectQueryString(key, filterValues[key]);
    } else {
      return getBasicWithStaticOptionsQueryString(key, [filterValues[key]]);
    }
  }).join("");
};

// is a filterValue a valid array with elements or non-blank string search?
const isValidBasicFilter = (filterValue) => {
  if (
    filterValue != null &&
    ((Array.isArray(filterValue) && filterValue.length > 0) ||
      (filterValue && !/^\s*$/.test(filterValue)))
  ) {
    return true;
  }
  return false;
};

/**
 * Generic query builder for DateRangeFilterButton.
 *
 * @example
 * getDateRangeQueryString(
 *   "delivery",
 *   { from: "...", to: "...", dateType: ["actual"] },
 *   {
 *     fromQueryParamPostfix: "_start",
 *     toQueryParamPostfix: "_end",
 *     dateTypeParamPostfix: "_date_type",
 *     formatDateTypeValue: JSON.stringify,
 *     dateTimeFormat: "X"
 *   }
 * );
 * // returns '&delivery_start=...&delivery_end=...&delivery_date_type=["actual"]'
 *
 * @param {string} queryParameter
 * @param {{ from: string, to: string, dateType: string[] }} filterValue
 * @param {object} config Config for how to handle the resulting query param names and values
 * @param {string} [config.fromQueryParamPostfix=From] Appended to `queryParameter` to get the "from" query param name
 * @param {string} [config.toQueryParamPostfix=To] Appended to `queryParameter` to get the "to" query param name
 * @param {string} [config.dateTypeParamPostfix=Type] Appended to `queryParameter` to get the "date type" query param name
 * @param {(dateTypes: string[]) => string} [config.formatDateTypeValue] Function that formats the `filterValue.dateType` array
 * @param {string} [config.dateTimeFormat="YYYY-MM-DD HH:mm:ss"] The format for datetime values
 * @param {string} [config.convertToUtc=false] Convert values from local time to UTC
 * @returns {string}
 */
export const getDateRangeQueryString = (
  queryParameter,
  filterValue,
  config = {},
) => {
  if (!isValidDateRangeFilter(filterValue)) {
    return "";
  }

  const {
    fromQueryParamPostfix = "From",
    toQueryParamPostfix = "To",
    dateTypeParamPostfix = "Type",
    formatDateTypeValue = (dateTypes = []) => dateTypes.join(","),
    dateTimeFormat = "YYYY-MM-DD HH:mm:ss",
    convertToUtc = false,
  } = config;

  const urlParams = new URLSearchParams();

  // Add From query param
  if (!_.isNil(filterValue.from)) {
    let value = moment(filterValue.from);

    if (convertToUtc) {
      value = value.utc();
    }

    urlParams.set(
      queryParameter + fromQueryParamPostfix,
      value.format(dateTimeFormat),
    );
  }

  // Add To query param
  if (!_.isNil(filterValue.to)) {
    let value = moment(filterValue.to);

    if (convertToUtc) {
      value = value.utc();
    }

    urlParams.set(
      queryParameter + toQueryParamPostfix,
      value.format(dateTimeFormat),
    );
  }

  // Add dateType query param
  if (filterValue?.dateType?.length > 0) {
    urlParams.set(
      queryParameter + dateTypeParamPostfix,
      formatDateTypeValue(filterValue.dateType),
    );
  }

  return `&${urlParams.toString()}`;
};

export const getShipmentDateRangeQueryString = (queryParameter, filterValue) =>
  getDateRangeQueryString(queryParameter, filterValue, {
    fromQueryParamPostfix: "_start",
    toQueryParamPostfix: "_end",
    dateTypeParamPostfix: "_date_type",
    dateTimeFormat: "X", // unix timestamp in seconds
  });

export const getEntityDateRangeQueryString = (queryParameter, filterValue) =>
  getDateRangeQueryString(queryParameter, filterValue, {
    formatDateTypeValue: JSON.stringify,
  });

// Format a date range querystring as "ts=from -> to""
export const getApiLogsDateRangeQueryString = (queryParameter, filterValue) => {
  let url = "";
  if (isValidDateRangeFilter(filterValue)) {
    if (filterValue.from != null && filterValue.to != null) {
      const pattern = "YYYY-MM-DDTHH:mm:ss";
      const from = moment.utc(filterValue.from).format(pattern);
      const to = moment.utc(filterValue.to).format(pattern);
      url += `&${queryParameter}=${from} -> ${to}`;
    }
  }
  return url;
};

export const getEmptyQueryString = () => "";

// is a filterValue a valid date range object?
const isValidDateRangeFilter = (filterValue) => {
  if (
    filterValue != null &&
    typeof filterValue === "object" &&
    ("from" in filterValue || "to" in filterValue)
  ) {
    return true;
  }
  return false;
};

export const getEverythingQueryString = (
  queryParameter,
  filterValue,
  state,
) => {
  // Map locations in the search string to their IDs using state
  const locations = getLocations(state);
  const matchingLocations = getMatchingLocationIDs(locations, filterValue);

  // DEV-1193: append matching location ids to the shipper_location_ids query parameter
  if (matchingLocations.length > 0 && filterValue !== matchingLocations[0]) {
    const queryStr = `${matchingLocations.join()}`;
    return `&${queryParameter}=${encodeURIComponent(
      filterValue,
    )}&shipper_location_ids=${encodeURIComponent(queryStr)}`;
  } else {
    return `&${queryParameter}=${encodeURIComponent(filterValue)}`;
  }
};

// Helper for query builders
const getMatchingLocationIDs = (locations, searchValue) => {
  // If the search value is a number, the search is for a location ID directly
  if (_.isNumber(searchValue)) {
    return [searchValue];
  }
  if (!locations) {
    return [];
  }
  const matchingLocations = locations.filter((l) => {
    return (
      (l.name && l.name.toLowerCase().includes(searchValue.toLowerCase())) ||
      (l.city && l.city.toLowerCase().includes(searchValue.toLowerCase())) ||
      (l.state && l.state.toLowerCase().includes(searchValue.toLowerCase())) ||
      (l.code && l.code.toLowerCase().includes(searchValue.toLowerCase()))
    );
  });
  return matchingLocations.map((l) => l.id);
};

// Is the filterValue valid?
export const isValidFilter = (filterValue) => {
  return isValidBasicFilter(filterValue) || isValidDateRangeFilter(filterValue);
};

export const getMultiSelectQueryString = (queryParameter, filterValue) => {
  if (Array.isArray(filterValue)) {
    let url = "";
    const nonStaticFilterOptions = getNonStaticFilterValues(filterValue);
    const staticFilterOptions = getStaticFilterValues(filterValue);

    // Extract the value properties if present (for simple types like integers, fall back to the raw value)
    const nonStaticValues = nonStaticFilterOptions.map((val) =>
      _.get(val, "value", val),
    );
    const staticValues = staticFilterOptions.map((val) =>
      _.get(val, "value", val),
    );

    url += getBasicWithStaticOptionsQueryString(queryParameter, staticValues);

    const joinedFilterOptions = nonStaticValues.join(",");

    url += getBasicQueryString(queryParameter, joinedFilterOptions);
    return url;
  }
  return getBasicQueryString(queryParameter, filterValue);
};

/**
 * Query builder for location filter lists. Usually async multiselects.
 *
 * @example
 * getLocationQueryString(
 *   "originCode",
 *   [
 *     {label: "Loc1", value: 123, id: 123, code: "LOC-123" },
 *     {label: "Loc2", value: 456, id: 123, code: "LOC-456" },
 *   ],
 *   {
 *     valueKey: "code",
 *     transformValue: code => `"${code}"`
 *   }
 * );
 * // returns '&originCode="LOC-123","LOC-456"'
 *
 * @param {string} queryParameter
 * @param {object[]} filterValue
 * @param {object} config Config for how to handle the resulting query param names and values
 * @param {string} [config.valueKey=value] The property key/field to use as the value for the URL param
 * @param {(value: any) => any} [config.transformValue] Transforms each value before setting as URL param value
 * @returns {string}
 */
export const getLocationQueryString = (
  queryParameter,
  filterValue,
  config = {},
) => {
  if (!Array.isArray(filterValue)) {
    return getBasicQueryString(queryParameter, filterValue);
  }

  const { valueKey = "value", transformValue = (value) => value } = config;

  let url = "";

  // Values sent to the API configured by "valueKey"
  // e.g. Sometimes we want to use the code instead of the id.
  const nonStaticValues = getNonStaticFilterValues(filterValue).map(
    (optionOrValue) => {
      const value = _.get(optionOrValue, valueKey, optionOrValue);
      // Optional transform function. e.g. wrap each value with double quotes.
      return transformValue(value);
    },
  );
  url += getBasicQueryString(queryParameter, nonStaticValues.toString());

  // Static options will only use "value" property.
  const staticValues = getStaticFilterValues(filterValue).map(
    (optionOrValue) => {
      return _.get(optionOrValue, "value", optionOrValue);
    },
  );
  url += getBasicWithStaticOptionsQueryString(queryParameter, staticValues);

  return url;
};

export const getLocationQueryStringOrigin = (
  queryParameter,
  filterValue,
  config = {},
) => {
  if (!Array.isArray(filterValue)) {
    return getBasicQueryString(queryParameter, filterValue);
  }

  const { valueKey = "value", transformValue = (value) => value } = config;

  let url = "";

  // Values sent to the API configured by "valueKey"
  // e.g. Sometimes we want to use the code instead of the id.
  const nonStaticValues = getNonStaticFilterValues(filterValue).map(
    (optionOrValue) => {
      const value = _.get(optionOrValue, valueKey, optionOrValue);
      // Optional transform function. e.g. wrap each value with double quotes.
      return transformValue(value);
    },
  );

  // temporary-fix for sending originName in QSP
  const nonStaticValuesOrigin = getNonStaticFilterValues(filterValue).map(
    (optionOrValue) => {
      const value = _.get(optionOrValue, "name", optionOrValue);
      // Optional transform function. e.g. wrap each value with double quotes.
      return transformValue(value);
    },
  );

  url += getBasicQueryString(queryParameter, nonStaticValues.toString());
  // originName added in QSP for origin filter
  url += getBasicQueryString("originName", nonStaticValuesOrigin.toString());

  // Static options will only use "value" property.
  const staticValues = getStaticFilterValues(filterValue).map(
    (optionOrValue) => {
      return _.get(optionOrValue, "value", optionOrValue);
    },
  );
  url += getBasicWithStaticOptionsQueryString(queryParameter, staticValues);

  return url;
};
export const getStatusEventTimeDateRangeQueryString = (
  queryParameter,
  filterValue,
) =>
  getDateRangeQueryString(queryParameter, filterValue, {
    fromQueryParamPostfix: "_start",
    toQueryParamPostfix: "_end",
    dateTypeParamPostfix: "_date_type",
    dateTimeFormat: "X", // unix timestamp in seconds
  });
