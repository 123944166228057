import {
  getEverythingQueryString,
  getBasicQueryString,
  getDateRangeQueryString,
} from "components/search-bar/search-filter-query-strings";

import {
  DateRangeFilterButton,
  SelectFilterButton,
} from "components/search-bar/FilterButtons";

import { isDateRangeValueValid } from "components/search-bar/search-filter-validators";

export const QUERY_KEY_MAPPING = {
  SHIPPABILITY: "isShippable",
  CURRENT_LOCATION: "atLocation",
  ARRIVAL_DATE: "arrivalDate",
  DEPARTURE_DATE: "deparatureDate",
};

export const INVENTORY_VIEW_DETAILS_SEARCH_CATEGORIES = [
  {
    queryKey: "entityId",
    label: () => null,
    placeholder: (t) => t("inventory-view:Search VIN"),
    queryBuilder: getEverythingQueryString,
    property: null,
  },
];

export const INVENTORY_VIEW_DETAILS_FILTERS = [
  {
    queryKey: QUERY_KEY_MAPPING.SHIPPABILITY,
    label: (t) => t("inventory-view:Shippability"),
    Component: SelectFilterButton,
    optionsGetter: (props, t) => {
      return [
        {
          label: t("inventory-view:Shippable"),
          value: "true",
        },
        {
          label: t("inventory-view:Non-Shippable"),
          value: "false",
        },
      ];
    },
    queryBuilder: (queryParameter, filterValue) =>
      getBasicQueryString(queryParameter, filterValue),
    hideSelectAll: true,
    hideSelectEmpty: true,
  },
  {
    queryKey: QUERY_KEY_MAPPING.ARRIVAL_DATE,
    label: (t) => t("inventory-view:Arrival Date"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    isValueValid: isDateRangeValueValid,
    queryBuilder: (queryParameter, filterValue) =>
      getDateRangeQueryString(queryParameter, filterValue, {
        formatDateTypeValue: JSON.stringify,
        convertToUtc: true,
      }),
  },
  {
    queryKey: QUERY_KEY_MAPPING.DEPARTURE_DATE,
    label: (t) => t("inventory-view:Departure Date"),
    Component: DateRangeFilterButton,
    optionsGetter: () => [],
    isValueValid: isDateRangeValueValid,
    queryBuilder: (queryParameter, filterValue) =>
      getDateRangeQueryString(queryParameter, filterValue, {
        formatDateTypeValue: JSON.stringify,
        convertToUtc: true,
      }),
  },
];
