/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import { HideGlobalSpinner, ShowGlobalSpinner } from "components/GlobalSpinner";
import { ButtonGroup } from "./OrderCardComponents";
import { Order, orderService } from "pages/rtscao/service/orderServices";
import { useState } from "react";
import { YesNoConfirmModal } from "../CreateOrder/YesNoConfirmModal";
import { CancelButton, GreenButton } from "../Shared";
import type { QueryClient } from "@tanstack/react-query";
import { Icon } from "components/atoms/Icon.atom";
import { faTrash } from "@fortawesome/pro-solid-svg-icons";

interface OemButtonProps {
  order: Order;
  queryClient: QueryClient;
  setEditMode: (editMode: boolean) => void;
  editMode: boolean;
}

export function OemButtonSection({
  order,
  queryClient,
  setEditMode,
  editMode,
}: OemButtonProps) {
  const [confirmShow, setConfirmShow] = useState(false);
  const confirmAction = async () => {
    ShowGlobalSpinner("Updating Order...");
    const newOrder = { ...order };
    newOrder.statusItems[0].status = "declined";
    newOrder.statusItems[0].label = "Order Canceled";
    await orderService.updateOrder(newOrder); // persist to db
    queryClient.invalidateQueries({ queryKey: ["orders"] });
    setConfirmShow(false);
    HideGlobalSpinner();
  };
  return (
    <ButtonGroup>
      <YesNoConfirmModal
        show={confirmShow}
        setShow={setConfirmShow}
        confirmAction={confirmAction}
      />{" "}
      {editMode ? (
        <>
          {/* <CancelButton type="button" onClick={() => setEditMode(false)}>
            Cancel
          </CancelButton> */}
          <GreenButton>Continue</GreenButton>
        </>
      ) : (
        <>
          <CancelButton
            css={{ display: "none" }}
            type="button"
            onClick={() => setConfirmShow(true)}
          >
            <Icon src={faTrash} />
          </CancelButton>
          <GreenButton type="submit">Save</GreenButton>
        </>
      )}
    </ButtonGroup>
  );
}
