export interface Plant {
  id: string;
  oem: string;
  oemShort: string;
  plantName: string;
  street: string;
  city: string;
  state: string;
  zip: string;
}

export const plantData = [
  {
    id: "ARLASM",
    oem: "General Motors Company",
    oemShort: "General Motors",
    plantName: "Arlington Assembly",
    street: "2525 E Abram St.",
    city: "Arlington",
    state: "Texas",
    zip: "76010",
  },
  {
    id: "BGASMB",
    oem: "General Motors Company",
    oemShort: "General Motors",
    plantName: "Bowling Green Assembly",
    street: "600 Corvette Drive",
    city: "Bowling Green",
    state: "Kentucky",
    zip: "42101",
  },
  {
    id: "DFASMB",
    oem: "General Motors Company",
    oemShort: "General Motors",
    plantName: "Detroit/Hamtramck Assembly",
    street: "2500 East Grand Blvd.",
    city: "Detroit",
    state: "Michigan",
    zip: "48211",
  },
  {
    id: "FFXASM",
    oem: "General Motors Company",
    oemShort: "General Motors",
    plantName: "Fairfax Assembly",
    street: "3201 Fairfax Trafficway",
    city: "Kansas City",
    state: "Kansas",
    zip: "66115",
  },
  {
    id: "FLNTAS",
    oem: "General Motors Company",
    oemShort: "General Motors",
    plantName: "Flint Truck Assembly",
    street: "3100 Vanslyke Rd.",
    city: "Flint",
    state: "Michigan",
    zip: "48551",
  },
  {
    id: "FTWASB",
    oem: "General Motors Company",
    oemShort: "General Motors",
    plantName: "Fort Wayne Assembly",
    street: "12200 Lafayette Center Rd.",
    city: "Roanoke",
    state: "Indiana",
    zip: "46783",
  },
  {
    id: "LNSDTS",
    oem: "General Motors Company",
    oemShort: "General Motors",
    plantName: "Lansing Delta Township Assembly",
    street: "8175 Millett Hwy.",
    city: "Lansing",
    state: "Michigan",
    zip: "48921",
  },
  {
    id: "LNSGRS",
    oem: "General Motors Company",
    oemShort: "General Motors",
    plantName: "Lansing Grand River Assembly",
    street: "920 Townsend St.",
    city: "Lansing",
    state: "Michigan",
    zip: "48921",
  },
  {
    id: "ORNASM",
    oem: "General Motors Company",
    oemShort: "General Motors",
    plantName: "Orion Assembly",
    street: "4555 Giddings Rd.",
    city: "Lake Orion",
    state: "Michigan",
    zip: "48359",
  },
  {
    id: "RLGMAS",
    oem: "General Motors Company",
    oemShort: "General Motors",
    plantName: "Spring Hill Manufacturing",
    street: "100 Saturn Prky.",
    city: "Spring Hill",
    state: "Tennessee",
    zip: "37174",
  },
  {
    id: "SILVSM",
    oem: "General Motors Company",
    oemShort: "General Motors",
    plantName: "Wentzville Assembly",
    street: "1500 East Route A.",
    city: "Wentzville",
    state: "Missouri",
    zip: "63385",
  },

  {
    id: "FORDFR",
    oem: "Ford Motor Company",
    oemShort: "Ford",
    plantName: "Flat Rock Assembly",
    street: "1 International Dr.",
    city: "Flat Rock",
    state: "Michigan",
    zip: "48134",
  },
  {
    id: "FORDCH",
    oem: "Ford Motor Company",
    oemShort: "Ford",
    plantName: "Chicago Assembly",
    street: "12600 S Torrance Ave.",
    city: "Chicago",
    state: "Illinois",
    zip: "60633",
  },
  {
    id: "FORDDE",
    oem: "Ford Motor Company",
    oemShort: "Ford",
    plantName: "Dearborn Truck",
    street: "3001 Miller Rd.",
    city: "Dearborn",
    state: "Michigan",
    zip: "48120",
  },
  {
    id: "FORDKC",
    oem: "Ford Motor Company",
    oemShort: "Ford",
    plantName: "Kansas City Assembly",
    street: "8121 NE 69th Hwy.",
    city: "Claycomo",
    state: "Missouri",
    zip: "64068",
  },
  {
    id: "FORDKT",
    oem: "Ford Motor Company",
    oemShort: "Ford",
    plantName: "Kentucky Truck",
    street: "3001 Chamberlain Ln.",
    city: "Louisville",
    state: "Kentucky",
    zip: "40241",
  },
  {
    id: "FORDLA",
    oem: "Ford Motor Company",
    oemShort: "Ford",
    plantName: "Louisville Assembly",
    street: "2000 Fern Valley Rd.",
    city: "Louisville",
    state: "Kentucky",
    zip: "40213",
  },
  {
    id: "FORDMA",
    oem: "Ford Motor Company",
    oemShort: "Ford",
    plantName: "Michigan Assembly",
    street: "37625 Michigan Ave.",
    city: "Wayne",
    state: "Michigan",
    zip: "48184",
  },
  {
    id: "FORDOA",
    oem: "Ford Motor Company",
    oemShort: "Ford",
    plantName: "Ohio Assembly",
    street: "650 Miller Rd.",
    city: "Avon Lake",
    state: "Ohio",
    zip: "44012",
  },

  {
    id: "STLJNA",
    oem: "Stellantis",
    oemShort: "Stellantis",
    plantName: "Jefferson North Assembly",
    street: "2101 Conner Avenue",
    city: "Detroit",
    state: "Michigan",
    zip: "48215",
  },
  {
    id: "STLJER",
    oem: "Stellantis",
    oemShort: "Stellantis",
    plantName: "Sterling Heights Assembly",
    street: "38111 Van Dyke",
    city: "Sterling Heights",
    state: "Michigan",
    zip: "48312",
  },
  {
    id: "STLTCM",
    oem: "Stellantis",
    oemShort: "Stellantis",
    plantName: "Toledo Complex",
    street: "4400 Chrysler Dr.",
    city: "Toledo",
    state: "Ohio",
    zip: "43608",
  },
  {
    id: "STLWTK",
    oem: "Stellantis",
    oemShort: "Stellantis",
    plantName: "Warren Truck Assembly",
    street: "21500 Mound Rd.",
    city: "Warren",
    state: "Michigan",
    zip: "48091",
  },
  {
    id: "STLDMK",
    oem: "Stellantis",
    oemShort: "Stellantis",
    plantName: "Detroit Assembly Complex - Mack",
    street: "4000 St. Jean Avenue",
    city: "Detroit",
    state: "Michigan",
    zip: "48214",
  },
  {
    id: "STLBAS",
    oem: "Stellantis",
    oemShort: "Stellantis",
    plantName: "Belvidere Assembly",
    street: "3000 West Chrysler Drive",
    city: "Belvidere",
    state: "Illinois",
    zip: "61008",
  },

  {
    id: "TSLFRM",
    oem: "Tesla",
    oemShort: "Tesla",
    plantName: "Tesla Factory",
    street: "45500 Fremont Blvd.",
    city: "Fremont",
    state: "California",
    zip: "94538",
  },
  {
    id: "TSLGFT",
    oem: "Tesla",
    oemShort: "Tesla",
    plantName: "Gigafactory Texas",
    street: "13101 Tesla Road",
    city: "Austin",
    state: "Texas",
    zip: "78725",
  },
  {
    id: "TSLGFN",
    oem: "Tesla",
    oemShort: "Tesla",
    plantName: "Gigafactory Nevada",
    street: "1 Electric Ave",
    city: "Sparks",
    state: "Nevada",
    zip: "89437",
  },
  {
    id: "RIVNRM",
    oem: "Rivian",
    oemShort: "Rivian",
    plantName: "Rivian Normal Illinois Factory",
    street: "100 Rivian Motorway",
    city: "Normal",
    state: "Illinois",
    zip: "61761",
  },
  {
    id: "LUCAMP",
    oem: "Lucid Motors",
    oemShort: "Lucid",
    plantName: "Lucid AMP-1",
    street: "317 S Thornton Rd",
    city: "Casa Grande",
    state: "Arizona",
    zip: "85193",
  },
  {
    id: "TYTGTN",
    oem: "Toyota Motor Corporation",
    oemShort: "Toyota",
    plantName: "Toyota Motor Manufacturing Kentucky",
    street: "1001 Cherry Blossom Way",
    city: "Georgetown",
    state: "Kentucky",
    zip: "40324",
  },
  {
    id: "TYTIND",
    oem: "Toyota Motor Corporation",
    oemShort: "Toyota",
    plantName: "Toyota Motor Manufacturing Indiana",
    street: "4000 Tulip Tree Drive, P.O. Box 4000",
    city: "Princeton",
    state: "Indiana",
    zip: "47670",
  },
  {
    id: "TYTTX",
    oem: "Toyota Motor Corporation",
    oemShort: "Toyota",
    plantName: "Toyota Motor Manufacturing Texas",
    street: "One Lone Star Pass",
    city: "San Antonio",
    state: "Texas",
    zip: "78264",
  },
  {
    id: "TYTMS",
    oem: "Toyota Motor Corporation",
    oemShort: "Toyota",
    plantName: "Toyota Motor Manufacturing Mississippi",
    street: "1200 Magnolia Dr.",
    city: "Blue Springs",
    state: "Mississippi",
    zip: "38828",
  },
  {
    id: "TYTAL",
    oem: "Toyota Motor Corporation",
    oemShort: "Toyota",
    plantName: "Mazda Toyota Manufacturing USA",
    street: "9000 Greenbrier Pkwy.",
    city: "Madison",
    state: "Alabama",
    zip: "35756",
  },

  {
    id: "HONMRY",
    oem: "Honda Motor Company",
    oemShort: "Honda",
    plantName: "Honda of America Manufacturing, Inc. Marysville Auto",
    street: "24000 Honda Pkwy.",
    city: "Marysville",
    state: "Ohio",
    zip: "43040",
  },
  {
    id: "HONELB",
    oem: "Honda Motor Company",
    oemShort: "Honda",
    plantName: "Honda of America Manufacturing, Inc. East Liberty Auto",
    street: "11000 Ohio 347",
    city: "East Liberty",
    state: "Ohio",
    zip: "43319",
  },
  {
    id: "HONALL",
    oem: "Honda Motor Company",
    oemShort: "Honda",
    plantName: "Honda Manufacturing of Alabama, LLC",
    street: "1800 Honda Dr.",
    city: "Lincoln",
    state: "Alabama",
    zip: "35096",
  },
  {
    id: "HONGRS",
    oem: "Honda Motor Company",
    oemShort: "Honda",
    plantName: "Honda Manufacturing of Indiana, LLC",
    street: "2755 N. Michigan Ave",
    city: "Greensburg",
    state: "Indiana",
    zip: "47240",
  },
  {
    id: "NISSMY",
    oem: "Nissan Motor Company",
    oemShort: "Nissan",
    plantName: "Nissan North America, Inc. Smyrna",
    street: "983 Nissan Dr.",
    city: "Smyrna",
    state: "Tennessee",
    zip: "37167",
  },
  {
    id: "NISCTN",
    oem: "Nissan Motor Company",
    oemShort: "Nissan",
    plantName: "Nissan North America, Inc. Canton",
    street: "300 Nissan Dr.",
    city: "Canton",
    state: "Mississippi",
    zip: "39046",
  },
  {
    id: "MZDTAL",
    oem: "Mazda Motor Corporation",
    oemShort: "Mazda",
    plantName: "Mazda Toyota Manufacturing USA",
    street: "9000 Greenbrier Pkwy.",
    city: "Madison",
    state: "Alabama",
    zip: "35756",
  },
  {
    id: "SUBIND",
    oem: "Subaru Corporation",
    oemShort: "Subaru",
    plantName: "Subaru of Indiana Automotive, Inc.",
    street: "5500 Indiana 38",
    city: "Lafayette",
    state: "Indiana",
    zip: "47905",
  },

  {
    id: "HYUMAL",
    oem: "Hyundai Motor Company",
    oemShort: "Hyundai",
    plantName: "Hyundai Motor Manufacturing Alabama",
    street: "700 Hyundai Blvd.",
    city: "Montgomery",
    state: "Alabama",
    zip: "36105",
  },
  {
    id: "KIAMGA",
    oem: "Kia Motors",
    oemShort: "Kia",
    plantName: "Kia Motors Manufacturing Georgia",
    street: "7777 Kia Pkwy",
    city: "West Point",
    state: "Georgia",
    zip: "31833",
  },
  {
    id: "VWCHAT",
    oem: "Volkswagen Group",
    oemShort: "VW",
    plantName: "Volkswagen Chattanooga Assembly",
    street: "8001 Volkswagen Dr.",
    city: "Chattanooga",
    state: "Tennessee",
    zip: "37416",
  },
  {
    id: "VWPLTN",
    oem: "Volvo Cars",
    oemShort: "Volvo",
    plantName: "Volvo Cars USA LLC",
    street: "3290 Volvo Car Dr.",
    city: "Ridgeville",
    state: "South Carolina",
    zip: "29472",
  },
  {
    id: "BMWGRR",
    oem: "BMW Group",
    oemShort: "BMW",
    plantName: "BMW US Manufacturing Company, LLC",
    street: "1400 Hwy 101 S.",
    city: "Greer",
    state: "South Carolina",
    zip: "29651",
  },

  {
    id: "MBVANC",
    oem: "Mercedes-Benz Group AG",
    oemShort: "Mercedes-Benz",
    plantName: "Mercedes-Benz U.S. International, Inc.",
    street: "1 Mercedes Dr.",
    city: "Vance",
    state: "Alabama",
    zip: "35490",
  },
  {
    id: "MBVLAD",
    oem: "Mercedes-Benz Group AG",
    oemShort: "Mercedes-Benz",
    plantName: "Mercedes-Benz Vans, LLC.",
    street: "8501 Palmetto Commerce Pkwy",
    city: "Ladson",
    state: "South Carolina",
    zip: "29456",
  },
  {
    id: "MBTUSC",
    oem: "Hino Motors",
    oemShort: "Hino",
    plantName: "Hino Motors Manufacturing U.S.A., Inc.",
    street: "1 Hino Way",
    city: "Williamstown",
    state: "West Virginia",
    zip: "26187",
  },
];
