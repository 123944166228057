/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable curly */
/* eslint-disable no-labels */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import { useQueryClient } from "@tanstack/react-query";
import { HideGlobalSpinner, ShowGlobalSpinner } from "components/GlobalSpinner";
import { Modal } from "components/molecules/Modal.molecule";
import { ValidatorInput } from "pages/dynamic-normal/formLibrary/Input";
import { Form } from "pages/dynamic-normal/formLibrary/useForm";
import { setSearchParams } from "pages/dynamic-normal/hooks/useSearchParams";
import { mockPlantHashMap } from "pages/dynamic-normal/mockData";
import { calculateCost } from "pages/dynamic-normal/service/costService";
import {
  calculateArrivalTime,
  getDrivingDistance,
  getRoute,
  updateDestDate,
  updateOriginDate,
} from "pages/dynamic-normal/service/heremapService";
import { orderService } from "pages/dynamic-normal/service/orderServices";
import React, { useEffect, useState } from "react";
import { EditOriginDestinationCard } from "../OriginDestinationCard/EditOriginDestinationCard";
import {
  AddressBlock,
  AddressSection,
  AddressTitle,
  Button,
  DateTimeInput,
  DockInfo,
  DockTimeStyled,
  DockTitle,
  formatSecondsIntoDaysAndHours,
  getDaysAndHoursBetweenDates,
  GreenButton,
  IconBan,
  MapDot,
  PlantSelect,
  SubTitle,
} from "../Shared";
import EditShipmentDetails from "../ShipmentDetailsCard/EditShipmentDetails";
import { SelectDock } from "./SelectDock";
import { locations, SelectLocation } from "./SelectLocation";
import { SelectPart } from "./SelectPart";
import { SegmentedControl } from "./Switch";

interface OrderTypeOption {
  value: string;
  label: string;
  icon: string;
}

const orderTypeOptions: OrderTypeOption[] = [
  { value: "supplier", label: "Supplier Part Order", icon: "📦" },
  { value: "shipping", label: "Shipping Capacity", icon: "🚚" },
];

interface CreateOrderModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  plant: string | null;
  setPlant:
    | React.Dispatch<React.SetStateAction<string>>
    | React.Dispatch<React.SetStateAction<null>>;
}

export function CreateOrderModal(props: CreateOrderModalProps): JSX.Element {
  const queryClient = useQueryClient();

  const [orderType, setOrderType] = useState<string>(orderTypeOptions[0].value);
  const modalWidth = "1200px";

  const createNewOrder = async (data: any) => {
    ShowGlobalSpinner("Saving...");
    const newOrder = orderService.createOrder(data);

    newOrder.orderType = orderType;
    newOrder.offers = [
      {
        originalMiles: data?.route?.summary?.miles,
        newCost: data?.route?.summary?.estimatedCost,
      },
    ];
    await orderService.addOrder(newOrder); // persist to db
    setSearchParams({ [`${newOrder.id}-expanded`]: true });
    queryClient.invalidateQueries({ queryKey: ["orders"] });
    HideGlobalSpinner();
    props.setShow(false);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => props.setShow(false)}
      css={{
        "--bs-modal-width": modalWidth,
        "&>div>div": { width: modalWidth },
      }}
    >
      <Modal.Header
        title="Create Order"
        customCss={{
          backgroundColor: "#282828",
          color: "white",
          borderTopRightRadius: "4px",
          borderTopLeftRadius: "4px",
        }}
        closeVariant="white"
      />
      <Form onSubmit={createNewOrder}>
        <Modal.Body>
          <SegmentedControl
            options={orderTypeOptions}
            setActiveOption={setOrderType}
            activeOption={orderType}
          />
          {orderType === "supplier" ? (
            <SupplierForm plant={props.plant} setPlant={props.setPlant} />
          ) : (
            <ShippingForm />
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
        >
          <p css={{ color: "#CF3E37", width: 950 }}>
            {IconBan}{" "}
            <a href="#" css={{ color: "#CF3E37" }}>
              Exclude Service Provider
            </a>
          </p>
          <Button type="button" onClick={() => props.setShow(false)}>
            Cancel
          </Button>
          <GreenButton type="submit">Create Order</GreenButton>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

const ShippingForm: React.FC = () => {
  return (
    <>
      <div css={{ display: "flex", flexDirection: "column" }}>
        <EditOriginDestinationCard />
      </div>
      <div css={{ display: "flex", flexDirection: "column" }}>
        <EditShipmentDetails />
      </div>
    </>
  );
};

async function getAndSetRoute(
  origin: any,
  dest: any,
  setRoute: React.Dispatch<React.SetStateAction<any>>,
) {
  if (!origin || !origin.value || !dest || !dest.value) {
    return;
  }
  ShowGlobalSpinner("Fetching Route Data...");
  const { routes, coords1, coords2 } = await getRoute(origin.value, dest.value);

  const arrival = routes?.[0]?.sections?.[0]?.arrival;
  const departure = routes?.[0]?.sections?.[0]?.departure;
  const summary = routes?.[0]?.sections?.[0]?.summary;

  if (arrival && departure && summary) {
    summary.driveTime = formatSecondsIntoDaysAndHours(summary.duration);
    departure.newTime = new Date(departure.time);
    departure.newTime.setHours(departure.newTime.getHours() + 24);

    arrival.newTime = calculateArrivalTime(departure.newTime, summary.duration);
    summary.miles = getDrivingDistance(summary.length);
    departure.newTime = departure.newTime.toISOString();
    summary.practicalDeliveryTime = getDaysAndHoursBetweenDates(
      departure.newTime,
      arrival.newTime,
    );
  }

  setRoute({ summary, arrival, departure, hmOrigin: coords1, hmDest: coords2 });

  HideGlobalSpinner();
  return { routes, coords1, coords2 };
}

interface SupplierFormProps {
  plant: string | null;
  setPlant:
    | React.Dispatch<React.SetStateAction<string>>
    | React.Dispatch<React.SetStateAction<null>>;
}

const SupplierForm: React.FC<SupplierFormProps> = (props) => {
  const plant = mockPlantHashMap[props.plant ?? ""];
  const [selectedPart, setSelectedPart] = useState<any>();
  const [origin, setOrigin] = useState<any>(null);
  const [dest, setDest] = useState<any>(null);
  const [route, setRoute] = useState<any>(null);

  useEffect(() => {
    const origin = selectedPart
      ? locations.find((loc) => loc.label === selectedPart.value.supplierName)
      : null;
    setOrigin(origin);
  }, [selectedPart]);

  useEffect(() => {
    if (plant) {
      setDest(locations.find((loc) => loc.label === plant.label));
    }
  }, [props.plant]);

  useEffect(() => {
    getAndSetRoute(origin, dest, setRoute);
  }, [`${origin?.value?.name}-${dest?.value?.name}`]);

  return (
    <>
      <div css={{ display: "flex", gap: 10, margin: "0px 20px" }}>
        <div css={{ flex: 3, fontSize: 14 }}>
          <SubTitle>Part</SubTitle>
          <SelectPart
            required
            selectedPart={selectedPart}
            setSelectedPart={setSelectedPart}
          />
        </div>

        <div css={{ flex: 1, fontSize: 14 }}>
          <SubTitle>Quantity</SubTitle>
          <ValidatorInput
            css={{ fontSize: 14 }}
            required
            type="number"
            name="quantity"
          />
        </div>
      </div>
      <div css={{ display: "flex" }}>
        <AddressBlock>
          <AddressTitle>{MapDot} Origin</AddressTitle>
          <AddressSection>
            <DockTitle>Origin / Pickup</DockTitle>
            <SelectLocation
              placeholder="Select Part Above ..."
              isDisabled={true}
              selected={origin}
              setSelected={setOrigin}
              css={{ fontSize: 14 }}
              namePrefix="origin"
            />

            <DockInfo css={{ gap: 10 }}>
              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Pickup By</DockTitle>
                <DateTimeInput
                  disabled
                  name="origin.lateDate"
                  onChange={(val) => updateDestDate(val, setRoute)}
                  defaultValue={route?.departure?.newTime}
                />
              </DockTimeStyled>
              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Pickup Location</DockTitle>
                <SelectDock namePrefix="origin" disabled />
              </DockTimeStyled>
              <input type="hidden" name="origin.atDock" value="No" />
            </DockInfo>

            <DockTimeStyled>
              <DockTitle>Notes</DockTitle>
              <ValidatorInput
                disabled
                css={{ fontSize: 14 }}
                name="origin.notes"
              />
            </DockTimeStyled>
          </AddressSection>
        </AddressBlock>
        <AddressBlock>
          <AddressTitle>{MapDot} Destination</AddressTitle>
          {plant ? (
            <>
              <AddressSection>
                <DockTitle>Destination / Dropoff</DockTitle>

                <SelectLocation
                  isDisabled={true}
                  selected={dest}
                  setSelected={setDest}
                  css={{ fontSize: 14 }}
                  namePrefix="destination"
                />

                <DockInfo css={{ gap: 10 }}>
                  <DockTimeStyled css={{ fontSize: 14 }}>
                    <DockTitle>Delivery By</DockTitle>
                    <DateTimeInput
                      required
                      onChange={(val) => updateOriginDate(val, setRoute)}
                      name="destination.lateDate"
                      defaultValue={route?.arrival?.newTime}
                    />
                  </DockTimeStyled>
                  <DockTimeStyled css={{ fontSize: 14 }}>
                    <DockTitle>Deliver Location</DockTitle>
                    <SelectDock namePrefix="destination" />
                  </DockTimeStyled>
                </DockInfo>

                <DockTimeStyled>
                  <DockTitle>Notes</DockTitle>
                  <ValidatorInput
                    css={{ fontSize: 14 }}
                    name="destination.notes"
                  />
                </DockTimeStyled>
              </AddressSection>
            </>
          ) : (
            <PlantSelect plant={props.plant} setPlant={props.setPlant} />
          )}
        </AddressBlock>
      </div>
      {route && route.summary ? (
        <div css={{ display: "flex", gap: 10, margin: "0px 100px" }}>
          {/* <div css={{ flex: 1, fontSize: 14, textAlign: "center" }}> */}
          {/* <DockTitle>Miles</DockTitle> */}
          {/* <p>{route?.summary?.miles}</p> */}
          <input
            type="hidden"
            name="route.summary.miles"
            value={route?.summary?.miles}
          />
          {/* </div> */}

          {/* <div css={{ flex: 1, fontSize: 14, textAlign: "center" }}> */}
          {/* <DockTitle>Estimated Price *</DockTitle> */}
          {/* <p>{formatUSD(calculateCost(route?.summary?.miles))}</p> */}
          <input
            type="hidden"
            name="route.summary.estimatedCost"
            value={calculateCost(route?.summary?.miles)}
          />
          {/* </div> */}

          {/* <div css={{ flex: 1, fontSize: 14, textAlign: "center" }}> */}
          {/* <DockTitle>Drive Time</DockTitle> */}
          {/* <p>{route?.summary?.driveTime}</p> */}
          <input
            type="hidden"
            name="route.summary.driveTime"
            value={route?.summary?.driveTime}
          />
          {/* </div> */}

          {/* <div css={{ flex: 1, fontSize: 14, textAlign: "center" }}> */}
          {/* <DockTitle>Practical Delivery Time</DockTitle> */}
          {/* <p>{route?.summary?.practicalDeliveryTime}</p> */}
          <input
            type="hidden"
            name="route.summary.practicalDeliveryTime"
            value={route?.summary?.practicalDeliveryTime}
          />
          {/* </div> */}
          <input
            type="hidden"
            name="route.summary.duration"
            value={route?.summary?.duration}
          />

          <input
            type="hidden"
            name="route.origin.lat"
            value={route?.hmOrigin?.position?.lat}
          />
          <input
            type="hidden"
            name="route.origin.long"
            value={route?.hmOrigin?.position?.lng}
          />
          <input
            type="hidden"
            name="route.destination.lat"
            value={route?.hmDest?.position?.lat}
          />
          <input
            type="hidden"
            name="route.destination.long"
            value={route?.hmDest?.position?.lng}
          />
        </div>
      ) : (
        <div css={{ display: "flex", gap: 10, margin: "0px 100px" }}>
          {/* <div css={{ flex: 1, fontSize: 14, textAlign: "center" }}>
            <DockTitle>Route Error</DockTitle>
            <p>Could not calculate route</p>
          </div> */}
        </div>
      )}
    </>
  );
};
