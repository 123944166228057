/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { TrailerSpaceSlider } from "./TrailerSpaceSlider";
import { useState } from "react";

const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;
`;

const SpaceValue = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #4caf50;
  width: 100px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(13, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 2px;
  width: 100%;
`;

interface GridSquareProps {
  isOccupied: boolean;
}

const GridSquare = styled.div<GridSquareProps>`
  width: 100%;
  aspect-ratio: 1;
  background-color: ${(props) => (props.isOccupied ? "#4caf50" : "#e0e0e0")};
  border-radius: 2px;
`;

interface EditTrailerSpaceVisualizationProps {
  occupiedSpace?: number;
}

export const EditTrailerSpaceVisualization: React.FC<
  EditTrailerSpaceVisualizationProps
> = ({ occupiedSpace = 0 }) => {
  const [sliderVal, setSliderVal] = useState<number>(occupiedSpace);
  const currentOccupiedSpace = sliderVal;

  // Total spaces in the trailer (2 rows x 13 columns)
  const columns = 13;
  const rows = 2;
  const totalSpaces = columns * rows;

  const isOccupied = (index: number): boolean => {
    const column = index % columns;
    const row = Math.floor(index / columns);
    const spaceNumber = column * rows + row;
    return spaceNumber < currentOccupiedSpace;
  };

  return (
    <Column>
      <TrailerSpaceSlider
        value={sliderVal}
        onChange={setSliderVal}
        min={0}
        max={26}
      />
      <input
        type="hidden"
        name="shipmentDetails.trailerSpaceRequirement"
        value={sliderVal}
      />
      <Container>
        <SpaceValue>{currentOccupiedSpace * 16} sq ft</SpaceValue>
        <Grid>
          {[...Array(totalSpaces)].map((_, index) => (
            <GridSquare key={index} isOccupied={isOccupied(index)} />
          ))}
        </Grid>
      </Container>
    </Column>
  );
};
