/** @jsxImportSource @emotion/react */
import React, { useRef, useEffect } from "react";
import styled from "@emotion/styled";

const SliderContainerContainer = styled.div`
  padding: 0px 10px;
  width: 100%;
`;

const SliderContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  position: relative;
`;

const SliderTrack = styled.div`
  width: 100%;
  height: 8px;
  background-color: #e0e0e0;
  border-radius: 4px;
`;

interface SliderThumbProps {
  position: number;
}

const SliderThumb = styled.div<SliderThumbProps>`
  width: 20px;
  height: 20px;
  background-color: #4caf50;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  position: absolute;
  left: ${(props) => props.position}%;
  transform: translateX(-50%);
  cursor: pointer;
  user-select: none;
`;

interface TrailerSpaceSliderProps {
  value: number;
  onChange: (newValue: number) => void;
  min?: number;
  max?: number;
  className?: string;
}

export const TrailerSpaceSlider: React.FC<TrailerSpaceSliderProps> = ({
  value,
  onChange,
  min = 0,
  max = 26,
  className,
}) => {
  const sliderRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef(false);

  const getPosition = (val: number): number =>
    ((val - min) / (max - min)) * 100;

  const handleMouseDown = (): void => {
    isDragging.current = true;
  };

  const handleMouseUp = (): void => {
    isDragging.current = false;
  };

  const handleMouseMove = (clientX: number): void => {
    if (isDragging.current && sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      let newPosition = ((clientX - rect.left) / rect.width) * 100;
      newPosition = Math.max(0, Math.min(newPosition, 100));
      const newValue = Math.round((newPosition / 100) * (max - min) + min);
      onChange(newValue);
    }
  };

  useEffect(() => {
    const handleMouseUpGlobal = (): void => {
      isDragging.current = false;
    };

    const handleMouseMoveGlobal = (e: MouseEvent): void => {
      handleMouseMove(e.clientX);
    };

    document.addEventListener("mouseup", handleMouseUpGlobal);
    document.addEventListener("mousemove", handleMouseMoveGlobal);

    return () => {
      document.removeEventListener("mouseup", handleMouseUpGlobal);
      document.removeEventListener("mousemove", handleMouseMoveGlobal);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [min, max, onChange]);

  return (
    <SliderContainerContainer className={className}>
      <SliderContainer ref={sliderRef}>
        <SliderTrack />
        <SliderThumb
          position={getPosition(value)}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
        >
          <span css={{ fontSize: 12 }}>&#60;&#62;</span>
        </SliderThumb>
      </SliderContainer>
    </SliderContainerContainer>
  );
};
