/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React from "react";

interface Option {
  value: string;
  label: string;
  icon: React.ReactNode;
}

interface SegmentedControlProps {
  options: Option[];
  activeOption: string;
  setActiveOption: (value: string) => void;
}

const segmentedControlStyles = css`
  display: inline-flex;
  background-color: #fff;
  border-radius: 8px;
  padding: 2px;
  margin: 0 auto;
`;

const buttonStyles = css`
  padding: 8px 16px;
  border: none;
  background: none;
  color: #929292;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s ease;

  &:first-of-type {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &:last-of-type {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`;

const activeButtonStyles = css`
  background-color: #e7f0ff;
  color: #0d6efd;
`;

const iconStyles = css`
  margin-right: 8px;
`;

export const SegmentedControl: React.FC<SegmentedControlProps> = ({
  options,
  activeOption,
  setActiveOption,
}) => {
  return (
    <div css={{ display: "flex", justifyContent: "center" }}>
      <div css={segmentedControlStyles}>
        {options.map((option) => (
          <button
            type="button"
            key={option.value}
            css={[
              buttonStyles,
              activeOption === option.value && activeButtonStyles,
            ]}
            onClick={() => setActiveOption(option.value)}
          >
            <span css={iconStyles}>{option.icon}</span>
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
};
