import axios from "axios";
import apiUrl from "api-url";
import { getFeatureName } from "modules/organizations/OrganizationsState";

// URLS
const STORE_MOUNT_POINT = "hsttMyWatchedTotes";

// Actions

const REQUEST_WATCHED_TOTES = `${STORE_MOUNT_POINT}/REQUEST_WATCHED_TOTES`;
const RECEIVE_WATCHED_TOTES = `${STORE_MOUNT_POINT}/RECEIVE_WATCHED_TOTES`;
const RECEIVE_WATCHED_TOTES_ERROR = `${STORE_MOUNT_POINT}/RECEIVE_WATCHED_TOTES_ERROR`;
const REQUEST_WATCHED_TOTES_COUNT = `${STORE_MOUNT_POINT}/REQUEST_WATCHED_TOTES_COUNT`;
const RECEIVE_WATCHED_TOTES_COUNT = `${STORE_MOUNT_POINT}/RECEIVE_WATCHED_TOTES_COUNT`;
const SET_WATCHED_TOTES_PAGE_INDEX = `${STORE_MOUNT_POINT}/SET_WATCHED_TOTES_PAGE_INDEX`;

// Action creators

function fetchWatchedTotes() {
  return async (dispatch, getState) => {
    dispatch({
      type: REQUEST_WATCHED_TOTES,
    });
    dispatch({ type: REQUEST_WATCHED_TOTES_COUNT });
    const state = getState();
    const pageSize = state[STORE_MOUNT_POINT].watchedTotesPageSize;
    const pageNumber = state[STORE_MOUNT_POINT].watchedTotesPageIndex;

    const params = { pageSize, pageNumber };
    const headers = { "x-target-feature": getFeatureName(state) };
    const url = apiUrl(`/containertracking/api/watch`);

    try {
      const response = await axios.get(url, { headers, params });
      dispatch({
        type: RECEIVE_WATCHED_TOTES_COUNT,
        payload: { totalPages: response.data?.meta?.totalPages ?? 1 },
      });
      return dispatch({
        type: RECEIVE_WATCHED_TOTES,
        payload: { watchedTotes: response?.data?.data ?? [] },
      });
    } catch (error) {
      console.error(error);
      return dispatch({ type: RECEIVE_WATCHED_TOTES_ERROR, error });
    }
  };
}

function setWatchedTotesPageIndex(pageIndex) {
  return {
    type: SET_WATCHED_TOTES_PAGE_INDEX,
    payload: { pageIndex },
  };
}

// Selectors

const getWatchedTotes = (state) => state[STORE_MOUNT_POINT].watchedTotes;
const getWatchedTotesLoading = (state) =>
  state[STORE_MOUNT_POINT].watchedTotesLoading ||
  state[STORE_MOUNT_POINT].watchedTotesCountLoading;
const getWatchedTotesPageIndex = (state) =>
  state[STORE_MOUNT_POINT].watchedTotesPageIndex;
const getWatchedTotesPageSize = (state) =>
  state[STORE_MOUNT_POINT].watchedTotesPageSize;
const getWatchedTotesPageCount = (state) =>
  state[STORE_MOUNT_POINT].watchedTotesPageCount;

// Initial state
const initialState = {
  watchedTotes: [],
  watchedTotesLoading: false,
  watchedTotesPageIndex: 0,
  watchedTotesPageSize: 10,
  watchedTotesPageCount: 0,
};

const SurgicalToteTrackingMyWatchedTotesReducer = (
  state = initialState,
  action,
) => {
  switch (action.type) {
    case REQUEST_WATCHED_TOTES:
      return {
        ...state,
        watchedTotesLoading: true,
      };

    case RECEIVE_WATCHED_TOTES:
      return {
        ...state,
        watchedTotes: action.payload.watchedTotes,
        watchedTotesLoading: false,
      };

    case RECEIVE_WATCHED_TOTES_COUNT:
      return {
        ...state,
        watchedTotesPageCount: action.payload.totalPages,
        watchedTotesCountLoading: false,
      };

    case SET_WATCHED_TOTES_PAGE_INDEX:
      return {
        ...state,
        watchedTotesPageIndex: action.payload.pageIndex,
      };

    default:
      return state;
  }
};

// interface
const SurgicalToteTrackingMyWatchedTotesState = {
  mountPoint: STORE_MOUNT_POINT,
  actionCreators: {
    fetchWatchedTotes,
    setWatchedTotesPageIndex,
  },
  selectors: {
    getWatchedTotes,
    getWatchedTotesLoading,
    getWatchedTotesPageIndex,
    getWatchedTotesPageSize,
    getWatchedTotesPageCount,
  },
  reducer: SurgicalToteTrackingMyWatchedTotesReducer,
};
export default SurgicalToteTrackingMyWatchedTotesState;
