/* eslint-disable curly */
import { stateMap } from "../mockData";

const API_KEY = "CBej7Q8rRs-7aIzlE2CXA1MNI_lNpM5TWU3_uQDNoyg";

const routeCache: { [key: string]: any } = {};

export async function getRouteInfo(address1: string, address2: string) {
  if (routeCache[`${address1}-${address2}`]) {
    return routeCache[`${address1}-${address2}`];
  }
  // Step 1: Geocode both addresses
  let coords1 = null;
  let coords2 = null;
  let latLong1 = null;
  let latLong2 = null;
  try {
    coords1 = await geocodeAddress(address1);
    latLong1 = getLatLong(coords1);
    coords2 = await geocodeAddress(address2);
    latLong2 = getLatLong(coords2);
  } catch (e) {
    return { routes: null, coords1, coords2, error: e };
  }

  // Step 2: Calculate route
  const routeUrl = `https://router.hereapi.com/v8/routes?transportMode=truck&origin=${latLong1}&destination=${latLong2}&return=summary&apikey=${API_KEY}`;

  const response = await fetch(routeUrl);
  const data = await response.json();

  routeCache[`${address1}-${address2}`] = {
    routes: data.routes,
    coords1: coords1?.items?.[0],
    coords2: coords2?.items?.[0],
  };
  return routeCache[`${address1}-${address2}`];
}

export function getDrivingDistance(distanceInMeters: number) {
  const distanceInMiles = distanceInMeters / 1609.344; // Convert meters to miles

  return distanceInMiles.toFixed(2);
}

const addressCache: { [key: string]: any } = {};

export async function geocodeAddress(address: string) {
  if (addressCache[address]) {
    return addressCache[address];
  }
  const geocodeUrl = `https://geocode.search.hereapi.com/v1/geocode?q=${encodeURIComponent(
    address,
  )}&apikey=${API_KEY}`;

  const response = await fetch(geocodeUrl);
  const data = await response.json();

  if (!data.items || data.items.length === 0) {
    throw new Error("Address not found");
  }

  addressCache[address] = data;
  return addressCache[address];
}

function getLatLong(data: any) {
  const { lat, lng } = data.items[0].position;
  return `${lat},${lng}`;
}

function calculateAdditionalTime(driveTimeSeconds: number) {
  const maxDriveTimeSeconds = 11 * 3600; // 11 hours in seconds
  const actualDriveTime = Math.min(driveTimeSeconds, maxDriveTimeSeconds);

  const regularBreaks = Math.floor(actualDriveTime / 14400); // 1-hour break every 4 hours
  const requiredRestTime =
    driveTimeSeconds > maxDriveTimeSeconds ? 10 * 3600 : 0; // 10-hour rest if over 11 hours

  const additionalTime = regularBreaks * 3600 + requiredRestTime;
  return additionalTime;
}

function calculatePracticalTripDuration(driveTimeSeconds: number) {
  const maxDriveTimeSeconds = 11 * 3600;
  let totalTime = 0;
  let remainingDriveTime = driveTimeSeconds;

  while (remainingDriveTime > 0) {
    const currentDriveTime = Math.min(remainingDriveTime, maxDriveTimeSeconds);
    const additionalTime = calculateAdditionalTime(currentDriveTime);

    totalTime += currentDriveTime + additionalTime;
    remainingDriveTime -= maxDriveTimeSeconds;

    if (remainingDriveTime > 0) {
      totalTime += 10 * 3600; // Add 10-hour rest period
    }
  }

  return totalTime;
}

export function calculateArrivalTime(
  departureTime: Date,
  driveTimeSeconds: number,
) {
  const practicalDurationSeconds =
    calculatePracticalTripDuration(driveTimeSeconds);
  const arrivalTime = new Date(
    departureTime.getTime() + practicalDurationSeconds * 1000,
  );
  return arrivalTime.toISOString();
}

export function calculateDepartureTime(
  arrivalTime: Date,
  driveTimeSeconds: number,
) {
  const practicalDurationSeconds =
    calculatePracticalTripDuration(driveTimeSeconds);
  const departureTime = new Date(
    arrivalTime.getTime() - practicalDurationSeconds * 1000,
  );
  return departureTime.toISOString();
}

export async function getRoute(origin: any, destination: any) {
  //@ts-ignore
  const ogState = stateMap[origin?.state?.toUpperCase()] || origin.state;
  const destState =
    //@ts-ignore
    stateMap[destination?.state?.toUpperCase()] || destination.state;

  // lets build coords1 and coords2 because the partsData contains sample/fake street addresses
  // because the ford data did not include street addresses, because of this we need to build the coords
  // from the city and state WITHOUT street addresses to increase our chances of heremaps actually returning somethin useful
  // START DEV ONLY CODE THAT SHOULD BE DELETED AND REPLACED WITH SOMEHTING MORE SUITABLE AFTER GOOD SUPPLIER DATA IS OBTAINED
  let coordStr1 = "";
  if (origin.oem) {
    coordStr1 = `${origin.name}, ${origin.address ?? origin.street}, ${
      origin.city
    }, ${destState}`;
  } else {
    coordStr1 = `${origin.name},  ${origin.city}, ${ogState}`;
  }

  let coordStr2 = "";
  if (destination.oem) {
    coordStr2 = `${destination.name}, ${
      destination.address ?? destination.street
    }, ${destination.city}, ${destState}`;
  } else {
    coordStr2 = `${origin.name},  ${origin.city}, ${ogState}`;
  }
  // END DEV ONLY CODE THAT SHOULD BE DELETED AND REPLACED WITH SOMEHTING MORE SUITABLE AFTER GOOD SUPPLIER DATA IS OBTAINED

  const results = await getRouteInfo(coordStr1, coordStr2);

  return results;
}

export function updateOriginDate(val: any, setRoute: any) {
  if (!val) return;
  if (isNaN(new Date(val).getTime())) return;
  setRoute((prev: any) => {
    if (prev === null || prev === undefined) prev = newEmptyRouteObject();
    // check if time has changed before operating
    if (prev?.arrival?.newTime === val) return prev;

    const newTimeBetween =
      new Date(val).getTime() - new Date(prev.departure.newTime).getTime();

    const newRoute = { ...prev };
    newRoute.arrival.newTime = val;
    const newDeparture = calculateDepartureTime(
      new Date(val),
      newRoute.summary.duration,
    );

    const minTimeBetween =
      new Date(val).getTime() - new Date(newDeparture).getTime();

    // if newTimeBetween is greater than minTimeBetween then we allow the adjustment without changing the arrival time
    if (newTimeBetween > minTimeBetween) {
      return prev;
    }

    newRoute.departure.newTime = newDeparture;
    return newRoute;
  });
}

export function updateDestDate(val: any, setRoute: any) {
  if (!val) return;
  if (isNaN(new Date(val).getTime())) return;
  setRoute((prev: any) => {
    if (prev === null || prev === undefined) prev = newEmptyRouteObject();
    // check if time has changed before operating
    if (prev?.departure?.newTime === val) return prev;

    const newTimeBetween =
      new Date(prev?.arrival?.newTime).getTime() - new Date(val).getTime();

    const newRoute = { departure: {}, arrival: {}, ...prev };
    newRoute.departure.newTime = val;
    const newArrival = calculateArrivalTime(
      new Date(val),
      newRoute?.summary?.duration,
    );

    const minTimeBetween =
      new Date(newArrival).getTime() - new Date(val).getTime();

    // if newTimeBetween is greater than minTimeBetween then we allow the adjustment without changing the arrival time
    if (newTimeBetween > minTimeBetween) {
      return prev;
    }

    newRoute.arrival.newTime = newArrival;
    return newRoute;
  });
}

export function newEmptyRouteObject() {
  return {
    summary: {
      duration: null,
      length: null,
      baseDuration: null,
      driveTime: null,
      miles: null,
      practicalDeliveryTime: null,
      conflict: false,
    },
    arrival: {
      time: null,
      place: {
        type: null,
        location: {
          lat: null,
          lng: null,
        },
        originalLocation: {
          lat: null,
          lng: null,
        },
      },
      newTime: null,
    },
    departure: {
      time: null,
      place: {
        type: null,
        location: {
          lat: null,
          lng: null,
        },
        originalLocation: {
          lat: null,
          lng: null,
        },
      },
      newTime: null,
    },
    hmOrigin: {
      title: null,
      id: null,
      resultType: null,
      address: {
        label: null,
        countryCode: null,
        countryName: null,
        stateCode: null,
        state: null,
        county: null,
        city: null,
        district: null,
        street: null,
        postalCode: null,
        houseNumber: null,
      },
      position: {
        lat: null,
        lng: null,
      },
      access: [
        {
          lat: null,
          lng: null,
        },
      ],
      mapView: {
        west: null,
        south: null,
        east: null,
        north: null,
      },
      categories: [],
      scoring: {
        queryScore: null,
        fieldScore: {
          city: null,
          streets: [],
          houseNumber: null,
          placeName: null,
        },
      },
    },
    hmDest: {
      title: null,
      id: null,
      resultType: null,
      address: {
        label: null,
        countryCode: null,
        countryName: null,
        stateCode: null,
        state: null,
        county: null,
        city: null,
        street: null,
        postalCode: null,
        houseNumber: null,
      },
      position: {
        lat: null,
        lng: null,
      },
      access: [
        {
          lat: null,
          lng: null,
        },
      ],
      mapView: {
        west: null,
        south: null,
        east: null,
        north: null,
      },
      categories: [],
      scoring: {
        queryScore: null,
        fieldScore: {
          state: null,
          city: 1,
          streets: [],
          houseNumber: null,
          placeName: null,
        },
      },
    },
  };
}
