/** @jsxImportSource @emotion/react */
import { ValidatorInput } from "pages/dynamic-normal/formLibrary/Input";
import { PartData } from "pages/dynamic-normal/mockData";
//@ts-ignore this is a temporary file anyway with hardcoded part data, eventually partData will be replaced with a DB/API call
import { partData } from "pages/dynamic-normal/service/partData";
import { AsyncPaginate } from "react-select-async-paginate";
import Colors from "styles/colors";

interface PartOption {
  value: PartData;
  label: string;
}

const filterParts = async (
  inputStr: string,
): Promise<{ options: PartOption[] }> => {
  let results: PartOption[] = [];
  try {
    results = partData
      .filter(
        (part: PartData) =>
          part.partNumber?.toLowerCase().includes(inputStr?.toLowerCase()) ||
          part.description?.toLowerCase().includes(inputStr?.toLowerCase()) ||
          part.supplierName?.toLowerCase().includes(inputStr?.toLowerCase()) ||
          part.supplierAddress?.toLowerCase().includes(inputStr?.toLowerCase()),
      )
      .slice(0, 100)
      .map((part: PartData) => ({
        value: part,
        label: part.partNumber,
      }));
  } catch (e) {
    return { options: [] };
  }

  return { options: results };
};

interface OptProps {
  label: string;
  innerProps: any;
  data: PartOption;
  selectProps: any;
  isDisabled: boolean;
  isSelected: boolean;
  isFocused: boolean;
}

const Opt: React.FC<OptProps> = ({
  label,
  innerProps,
  data,
  selectProps,
  isDisabled,
  isSelected,
  isFocused,
}) => {
  return (
    <div
      ref={innerProps.ref}
      {...innerProps}
      className={selectProps.className}
      css={{
        fontSize: "12px",
        paddingLeft: "10px",
        paddingBottom: "10px",
        backgroundColor: isDisabled
          ? Colors.background.LIGHT_GRAY
          : isSelected
          ? "#EBF3FF"
          : isFocused
          ? "#DEEBFF"
          : "#fff",
        color: isDisabled
          ? "#aaa"
          : isSelected
          ? "#333"
          : isFocused
          ? "#333"
          : "#6d6d6d",
      }}
    >
      <div>
        <strong>{label}</strong>
      </div>
      <div>{data.value.description}</div>
      <div>{data.value.supplierName}</div>
      <div>{data.value.supplierAddress}</div>
    </div>
  );
};

interface SelectPartProps {
  setSelectedPart: (part: PartOption | null) => void;
  selectedPart: PartOption | null;
  name?: string;
  required?: boolean;
  [key: string]: any;
}

export function SelectPart(props: SelectPartProps) {
  return (
    <>
      <AsyncPaginate<PartOption, any, any>
        defaultOptions
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: "white",
            borderRadius: 4,
            border: "2px solid #dedede",
            flex: 1,
            minHeight: 0,
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            padding: "0px 4px",
          }),
          valueContainer: (styles) => ({
            ...styles,
            padding: "0px 8px",
          }),
        }}
        placeholder="Select Part"
        loadOptions={(inp: string) => filterParts(inp)}
        onChange={props.setSelectedPart}
        value={props.selectedPart}
        components={{
          Option: Opt,
        }}
        {...props}
      />
      <ValidatorInput
        name={props.name ?? "partNo"}
        required={props.required}
        css={{ display: "none" }}
        value={props.selectedPart?.label ?? ""}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          if ((ev.target as any).resetting) {
            props.setSelectedPart(null);
          }
        }}
      />
      <input
        type="hidden"
        name="supplierName"
        value={props.selectedPart?.value?.supplierName ?? ""}
      />
      <input
        type="hidden"
        name="productInfo"
        value={props.selectedPart?.value?.description ?? ""}
      />
      <input
        type="hidden"
        name="origin.name"
        value={props.selectedPart?.value?.supplierName ?? ""}
      />
      <input
        type="hidden"
        name="origin.address"
        value={props.selectedPart?.value?.supplierStreet ?? ""}
      />
      <input
        type="hidden"
        name="origin.city"
        value={props.selectedPart?.value?.supplierCity ?? ""}
      />
      <input
        type="hidden"
        name="origin.state"
        value={props.selectedPart?.value?.supplierState ?? ""}
      />
    </>
  );
}
