import moment from "moment";
import { useTranslation } from "react-i18next";
import Colors from "styles/colors";

export const dataTypes = {
  NONSHIPPABLE: "nonShippable",
  SHIPPABLE: "shippable",
  FORECASTED: "forecasted",
  REPORTED: "reported",
  TODAY: "today",
  CAPACITY: "capacity",
  PROJECTED_TREND: "projectedTrend",
};

function updateFilterValue(
  datum,
  setSearchFilter,
  queryKey,
  redirectToDetailsView,
) {
  setSearchFilter(queryKey, {
    dateType: [],
    from: moment(datum.name).toISOString(),
    to: moment(datum.name).toISOString(),
  });
  redirectToDetailsView(datum.locationId);
}

export const useInventoryChartBars = (
  setSearchFilter,
  redirectToDetailsView,
  queryKey,
) => {
  const { t } = useTranslation("inventory-view");

  return [
    {
      y: dataTypes.SHIPPABLE,
      legendLabel: t("inventory-view:Shippable"),
      color: Colors.graphs.CHART_BLUE,
      textColor: Colors.text.WHITE,
      symbolType: "circle",
      dy: 20,
      onClick: (datum) => {
        setSearchFilter("isShippable", "true");
        redirectToDetailsView(datum.locationId);
      },
    },
    {
      y: dataTypes.NONSHIPPABLE,
      legendLabel: t("inventory-view:Non-shippable"),
      color: Colors.graphs.CHART_GRAY,
      textColor: Colors.text.DARK_GRAY,
      symbolType: "circle",
      onClick: (datum) => {
        setSearchFilter("isShippable", "false");
        redirectToDetailsView(datum.locationId);
      },
    },
    {
      y: dataTypes.TODAY,
      legendLabel: t("inventory-view:Today"),
      color: Colors.graphs.CHART_CYAN_BLUE,
      textColor: Colors.text.WHITE,
      symbolType: "circle",
      onClick: (datum) => {
        updateFilterValue(
          datum,
          setSearchFilter,
          queryKey,
          redirectToDetailsView,
        );
      },
    },
    {
      y: dataTypes.FORECASTED,
      legendLabel: t("inventory-view:Forecasted"),
      color: Colors.graphs.FORECASTED_GRAY,
      textColor: Colors.text.DARKER_GRAY,
      symbolType: "circle",
      onClick: (datum) => {
        updateFilterValue(
          datum,
          setSearchFilter,
          queryKey,
          redirectToDetailsView,
        );
      },
    },
    {
      y: dataTypes.CAPACITY,
      legendLabel: t("inventory-view:Capacity"),
      color: Colors.graphs.CHART_LINE,
      textColor: Colors.graphs.CHART_LINE,
      symbolType: "minus",
      onClick: (datum) => {
        return;
      },
    },
  ];
};

export const usePipelineChartBars = (
  setSearchFilter,
  redirectToDetailsView,
  queryKey,
) => {
  const { t } = useTranslation("inventory-view");

  return [
    {
      y: dataTypes.SHIPPABLE,
      legendLabel: t("inventory-view:Shippable"),
      color: Colors.graphs.CHART_BLUE,
      textColor: Colors.text.WHITE,
      symbolType: "circle",
      dy: 20,
      onClick: (datum) => {
        updateFilterValue(
          datum,
          setSearchFilter,
          queryKey,
          redirectToDetailsView,
        );
      },
    },
    {
      y: dataTypes.NONSHIPPABLE,
      legendLabel: t("inventory-view:Non-shippable"),
      color: Colors.graphs.CHART_GRAY,
      textColor: Colors.text.DARK_GRAY,
      symbolType: "circle",
      onClick: (datum) => {
        updateFilterValue(
          datum,
          setSearchFilter,
          queryKey,
          redirectToDetailsView,
        );
      },
    },
  ];
};

export const useArrivalsChartBars = (
  setSearchFilter,
  redirectToDetailsView,
  queryKey,
) => {
  const { t } = useTranslation("inventory-view");

  return [
    {
      y: dataTypes.REPORTED,
      legendLabel: t("inventory-view:Reported"),
      color: Colors.graphs.CHART_BLUE,
      textColor: Colors.text.WHITE,
      dy: 20,
      symbolType: "circle",
      onClick: (datum) => {
        updateFilterValue(
          datum,
          setSearchFilter,
          queryKey,
          redirectToDetailsView,
        );
      },
    },
    {
      y: dataTypes.TODAY,
      legendLabel: t("inventory-view:Today"),
      color: Colors.graphs.CHART_CYAN_BLUE,
      textColor: Colors.text.WHITE,
      symbolType: "circle",
      onClick: (datum) => {
        updateFilterValue(
          datum,
          setSearchFilter,
          queryKey,
          redirectToDetailsView,
        );
      },
    },
    {
      y: dataTypes.FORECASTED,
      legendLabel: t("inventory-view:Forecasted"),
      color: Colors.graphs.CHART_GRAY,
      textColor: Colors.text.DARKER_GRAY,
      symbolType: "circle",
      onClick: (datum) => {
        updateFilterValue(
          datum,
          setSearchFilter,
          queryKey,
          redirectToDetailsView,
        );
      },
    },
  ];
};

export const useDeparturesChartAreas = (
  setSearchFilter,
  redirectToDetailsView,
  queryKey,
) => {
  const { t } = useTranslation("inventory-view");

  return [
    {
      y: dataTypes.REPORTED,
      legendLabel: t("inventory-view:Actual"),
      color: Colors.graphs.CHART_BLUE,
      textColor: Colors.text.WHITE,
      dy: 20,
      symbolType: "circle",
      onClick: (datum) => {
        updateFilterValue(
          datum,
          setSearchFilter,
          queryKey,
          redirectToDetailsView,
        );
      },
    },
    {
      y: dataTypes.TODAY,
      legendLabel: t("inventory-view:Target"),
      color: Colors.text.DARK_GRAY,
      textColor: Colors.text.WHITE,
      symbolType: "minus",
      onClick: (datum) => {
        updateFilterValue(
          datum,
          setSearchFilter,
          queryKey,
          redirectToDetailsView,
        );
      },
    },
    {
      y: dataTypes.FORECASTED,
      legendLabel: t("inventory-view:Stretch"),
      color: Colors.graphs.CHART_LINE,
      textColor: Colors.text.DARK_GRAY,
      symbolType: "minus",
      onClick: (datum) => {
        updateFilterValue(
          datum,
          setSearchFilter,
          queryKey,
          redirectToDetailsView,
        );
      },
    },
    {
      y: dataTypes.PROJECTED_TREND,
      legendLabel: t("inventory-view:Projected Trend"),
      color: Colors.inventoryView.ORANGE,
      textColor: Colors.inventoryView.ORANGE,
      symbolType: "minus",
      onClick: (datum) => {
        updateFilterValue(
          datum,
          setSearchFilter,
          queryKey,
          redirectToDetailsView,
        );
      },
    },
  ];
};
