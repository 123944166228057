/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import Link from "redux-first-router-link";
import {
  additionalInfoIconMap,
  ShowOpportunitiesButton,
  StatusBar,
  StatusDescription,
  StatusDot,
  StatusLabel,
  StatusItem,
  StatusLineConnector,
} from "./OrderCardComponents";
import {
  Order,
  StatusItem as StatusItemType,
  StatusType,
} from "pages/dynamic-normal/service/orderServices";

// Type guard function
function isItemWithAdditionalInfo(item: StatusItemType): item is {
  label: "Carrier Awarded";
  status: StatusType;
  additionalInfo?: string;
  additionalInfoIcon?: string;
} {
  return item.label === "Carrier Awarded" && "additionalInfo" in item;
}

export function StatusSection({
  order,
  opportunity,
}: {
  order: Order;
  opportunity?: boolean;
}) {
  return (
    <StatusBar>
      {order.statusItems.map((item, index) => (
        <StatusItem key={index}>
          {index !== order.statusItems.length - 1 && <StatusLineConnector />}
          {index === 2 && opportunity ? (
            <>
              <StatusDot status="opportunity" />
              <Link to={`/dynamic-normal/opportunities?order=${order.id}`}>
                <ShowOpportunitiesButton type="button">
                  Show Opportunities
                </ShowOpportunitiesButton>
              </Link>
            </>
          ) : (
            <>
              <StatusDot status={item.status} />
              <StatusLabel status={item.status}>{item.label}</StatusLabel>
              {isItemWithAdditionalInfo(item) && item.additionalInfo && (
                <StatusDescription status={item.status}>
                  {item.additionalInfoIcon &&
                    additionalInfoIconMap[item.additionalInfoIcon]}
                  {item.additionalInfo}
                </StatusDescription>
              )}
            </>
          )}
        </StatusItem>
      ))}
    </StatusBar>
  );
}
