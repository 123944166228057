/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { useSetTitle } from "components/hooks/useSetTitle";
import { t } from "i18next";
import { OrderCard } from "./components/OrderCard/OrderCard";
import { PageHeader } from "./components/PageHeader/PageHeader";
import { useOrdersData } from "./hooks/useOrdersData";

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export function DynamicNormalOrdersSupplierView() {
  useSetTitle(t("dynamicNormal:Dynamic Normal Orders"));
  const {
    plant,
    setPlant,
    sort,
    setSort,
    filterType,
    setFilterType,
    filterValue,
    setFilterValue,
    activeChecked,
    setActiveChecked,
    archivedChecked,
    setArchivedChecked,
    orders,
  } = useOrdersData();

  return (
    <div css={{ padding: 20 }}>
      <PageHeader
        variant="supplier"
        plant={plant}
        setPlant={setPlant}
        sort={sort}
        setSort={setSort}
        filterType={filterType}
        setFilterType={setFilterType}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        activeChecked={activeChecked}
        setActiveChecked={setActiveChecked}
        archivedChecked={archivedChecked}
        setArchivedChecked={setArchivedChecked}
        orders={orders}
      />
      <CardList>
        {orders.map((order, index) => (
          <OrderCard key={order.id} order={order} variant="supplier" />
        ))}
      </CardList>
    </div>
  );
}
