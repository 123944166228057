/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import Loader from "react-loader";

import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { withSize } from "react-sizeme";

import Colors from "styles/colors";

import { NoChartData } from "components/molecules/NoChartData.molecule";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { FontSize, Text } from "components/atoms/Text.atom";
import {
  barStyle,
  generateTickFormat,
} from "pages/inventoryview/utils/chart.utils";

import {
  VictoryArea,
  VictoryAxis,
  VictoryChart,
  VictoryLabel,
  VictoryLegend,
  VictoryLine,
  VictoryScatter,
  VictoryVoronoiContainer,
} from "victory";

import {
  axisStyle,
  axisTicksLabelStyle,
  crossAxisStyle,
  strokeDasharray,
  strokeLinecap,
  strokeLinejoin,
  strokeWidth,
} from "styles/graph-styles";
import { Alert } from "components/atoms/Alert.atom";

export const DeparturesCountsWidget = ({
  widgetData,
  isLoading = false,
  title,
  bars,
  xAxisTickFormat,
  yAxisTickFormat,
  maxCapacity = 0,
}) => {
  const { t } = useTranslation("inventory-view");
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  const onSize = (size) => {
    setDimensions({
      height: size.height,
      width: size.width,
    });
  };

  const xAxisTickFormatValue = generateTickFormat(xAxisTickFormat);
  const yAxisTickFormatValue = generateTickFormat(yAxisTickFormat);

  return (
    <PanelGroup>
      <PanelGroup.Header title={title} />
      <PanelGroup.Content style={{ position: "relative", minHeight: 300 }}>
        <Alert
          variant="warning"
          style={{ margin: 0, padding: "0.5em", opacity: 0 }}
          show
        >
          {/* {This is a placeholder used as a workaround to display Arrivals and Departures panels of the same height.} */}
          {/* {Placeholder has been set @opacity: 0 to hide it.} */}
          {/* {#TODO: remove placeholder and fix the height in next iteration} */}
          <Text>Placeholder Text</Text>
        </Alert>
        <Loader loaded={!isLoading}>
          {widgetData?.length ? (
            <div
              css={{
                svg: {
                  maxHeight: "23rem",
                },
                display: "flex",
                flexDirection: "row",
              }}
            >
              <ChartComponent
                pagedData={widgetData}
                bars={bars}
                barStyle={barStyle}
                axisStyle={axisStyle}
                crossAxisStyle={crossAxisStyle}
                onSize={onSize}
                dimensions={dimensions}
                maxCapacity={maxCapacity}
                xAxisTickFormatValue={xAxisTickFormatValue}
                yAxisTickFormatValue={yAxisTickFormatValue}
              />
            </div>
          ) : (
            <div
              css={{
                minHeight: 300,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                color: Colors.text.GRAY,
                padding: "2em",
                textAlign: "center",
              }}
            >
              <NoChartData
                size={FontSize.size20}
                label={t(
                  "inventory-view:Unable to fetch details. Please try again later.",
                )}
              />
            </div>
          )}
        </Loader>
      </PanelGroup.Content>
    </PanelGroup>
  );
};

const ChartComponent = withSize()(({
  dimensions,
  pagedData,
  bars,
  axisStyle,
  crossAxisStyle,
  xAxisTickFormatValue,
  yAxisTickFormatValue,
  maxCapacity,
}) => {
  const containerRef = useRef();
  let boundingRect = { width: 0, height: 0 };
  if (containerRef.current) {
    boundingRect = containerRef.current.getBoundingClientRect();
  }

  const legendLabels = bars.map((bar) => ({
    name: bar.legendLabel,
    y: bar.y,
    symbol: { type: bar.symbolType },
  }));
  const colorScale = bars.map((bar) => bar.color);
  const axisLabelPlacement = boundingRect.width / 2 - 20;

  return (
    <div css={{ width: "100%" }} ref={containerRef}>
      <VictoryChart
        domainPadding={{ x: 0, y: 30 }}
        padding={{ top: 20, bottom: 60, left: 30, right: 50 }}
        width={dimensions.width}
        height={400}
        containerComponent={<VictoryVoronoiContainer radius={50} />}
      >
        <VictoryArea
          data={pagedData}
          labels={({ datum }) => datum.y}
          labelComponent={<VictoryLabel dy={-20} />}
          style={{
            data: {
              stroke: Colors.graphs.CHART_BLUE,
              fill: "#d9f0ff",
            },
          }}
        />

        <VictoryLine
          style={{
            data: {
              stroke: Colors.inventoryView.ORANGE,
              strokeDasharray,
              strokeLinecap,
              strokeLinejoin,
              strokeWidth,
            },
          }}
          data={pagedData}
          y={(datum) => datum.projectedTrend}
        />
        <VictoryAxis
          axisValue={92}
          orientation="top"
          style={{
            tickLabels: { fill: "none" },
            axisLabel: { fontSize: FontSize.size12 },
            axis: {
              stroke: "none",
            },
          }}
          label={92}
          axisLabelComponent={
            <VictoryLabel
              dy={30}
              dx={axisLabelPlacement}
              style={{
                fontWeight: 600,
                fill: Colors.inventoryView.ORANGE,
              }}
            />
          }
        />
        <VictoryScatter
          style={{ data: { fill: Colors.graphs.CHART_BLUE } }}
          data={pagedData}
          samples={14}
        />
        <VictoryAxis
          style={axisStyle}
          tickLabelComponent={
            <VictoryLabel
              textAnchor="middle"
              style={{ fontSize: 12, ...axisTicksLabelStyle }}
            />
          }
          tickFormat={xAxisTickFormatValue}
          crossAxis={true}
        />
        <VictoryAxis
          style={crossAxisStyle}
          dependentAxis
          tickFormat={yAxisTickFormatValue}
          crossAxis={true}
        />
        <VictoryAxis
          axisValue={100}
          crossAxis={true}
          style={{
            tickLabels: { fill: "none" },
            axis: {
              stroke: Colors.text.DARK_GRAY,
              strokeWidth,
            },
          }}
        />
        <VictoryAxis
          axisValue={100}
          orientation="top"
          style={{
            tickLabels: { fill: "none" },
            axisLabel: { fontSize: FontSize.size12 },
            axis: {
              stroke: "none",
            },
          }}
          label={100}
          axisLabelComponent={
            <VictoryLabel
              dy={30}
              dx={axisLabelPlacement}
              style={{
                fontWeight: 600,
                fill: Colors.text.DARK_GRAY,
              }}
            />
          }
        />
        <VictoryAxis
          axisValue={115}
          style={{
            tickLabels: { fill: "none" },
            axis: {
              stroke: Colors.graphs.CHART_LINE,
              strokeWidth,
            },
          }}
        />
        <VictoryAxis
          axisValue={115}
          orientation="top"
          style={{
            tickLabels: { fill: "none" },
            axisLabel: { fontSize: FontSize.size12 },
            axis: {
              stroke: "none",
            },
          }}
          label={115}
          axisLabelComponent={
            <VictoryLabel
              dy={30}
              dx={axisLabelPlacement}
              style={{
                fontWeight: 600,
                fill: Colors.graphs.CHART_LINE,
              }}
            />
          }
        />
        <VictoryLegend
          y={dimensions.width / 2}
          x={200}
          style={{ labels: { fontFamily: "inherit" } }}
          colorScale={colorScale}
          data={legendLabels}
          orientation="horizontal"
        />
      </VictoryChart>
    </div>
  );
});

DeparturesCountsWidget.propTypes = {
  isLoading: PropTypes.bool,
  pushView: PropTypes.func,
  widgetData: PropTypes.array,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  title: PropTypes.string,
  maxCapacity: PropTypes.number,
  bars: PropTypes.array,
  yAxisTickFormat: PropTypes.object,
  xAxisTickFormat: PropTypes.object,
};
