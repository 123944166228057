import { generateFakeOrders } from "./service/generateFakeOrders";
import { plantData, Plant } from "./service/plantData";

interface MockPlant {
  label: string;
  value: Plant;
}

export interface PartData {
  _id: string;
  // _rev: string;
  partNumber: string;
  description: string;
  supplierName: string;
  supplierAddress: string;
  supplierStreet: string;
  supplierCity: string;
  supplierState: string;
  supplierZip: string;
  supplierCountry: string;
}

export const mockPlants: MockPlant[] = [];
plantData.forEach((plant: Plant) => {
  if (plant.oemShort !== "General Motors") {
    return;
  }
  mockPlants.push({
    label: plant.plantName,
    value: plant,
  });
});

export const mockPlantHashMap: { [key: string]: MockPlant } = {};
mockPlants.forEach((plant: MockPlant) => {
  mockPlantHashMap[plant.value.id] = plant;
});

interface SortKey {
  value: string;
  label: string;
  sort: Array<string | { [key: string]: string }>;
}

export const sortKeys: SortKey[] = [
  { value: "id", label: "Order Number", sort: ["id"] },
  { value: "companyName", label: "Company Name", sort: ["companyName"] },
  { value: "productInfo", label: "Product Information", sort: ["productInfo"] },
  { value: "quantity", label: "Quantity", sort: [{ quantity: "desc" }] },
  { value: "orderDate", label: "Order Date", sort: [{ orderDate: "desc" }] },
  { value: "origin", label: "Origin", sort: ["origin.name"] },
  { value: "destination", label: "Destination", sort: ["destination.name"] },
];

export const sortKeyHashMap: { [key: string]: SortKey } = {};
sortKeys.forEach((sortKey: SortKey) => {
  sortKeyHashMap[sortKey.value] = sortKey;
});

interface Opportunity {
  carrierName: string;
  carrierType: string;
  milesToSupplier: number;
  totalMiles: number;
  offerStatus: string;
  price: number;
}

export const mockOpportunities: Opportunity[] = [
  {
    carrierName: "Carrier Name 001",
    carrierType: "Open Opportunity Network",
    milesToSupplier: 20,
    totalMiles: 250,
    offerStatus: "Waiting",
    price: 250,
  },
];

export const mockOrders = generateFakeOrders(20);

export const stateMap = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
} as const;
