/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import { Order } from "pages/dynamic-normal/service/orderServices";
import { formatISOToCustom } from "../Shared";
import {
  CarrierPickupDeliverSubTitle,
  CarrierPickupDeliverTitle,
  CompanyName,
  DeliverBy,
  ProductInfo,
} from "./OrderCardComponents";
import TripSchedule from "./TripSchedule";

type Variant = "declined" | "oem" | "offers" | "supplier" | "carrier";

export function getLeftSectionVariant(variant: Variant) {
  // variants switch (Basic, Offers, Supplier, Carrier)
  switch (variant) {
    case "declined":
      return DeclinedOrderInfo;
    case "oem":
      return BasicOrderInfo;
    case "offers":
      return TripSchedule;
    case "supplier":
      return SupplierOrderInfo;
    case "carrier":
      return CarrierOrderInfo;
    default:
      return BasicOrderInfo;
  }
}

function DeclinedOrderInfo({
  order,
  variant,
}: {
  order: Order;
  variant: Variant;
}) {
  const DeclinedOrCanceled =
    order.statusItems[0].label === "Order Canceled" ? "Canceled" : "Declined";
  const OrgName =
    DeclinedOrCanceled === "Canceled" ? order.companyName : order.supplierName;
  return (
    <>
      <CompanyName>
        {variant === "oem" ? order.supplierName : order.companyName}
      </CompanyName>
      <CompanyName css={{ color: "#CF3E37" }}>
        {DeclinedOrCanceled} - {OrgName}
      </CompanyName>
    </>
  );
}

function BasicOrderInfo({ order }: { order: Order }) {
  return (
    <>
      <CompanyName>{order.supplierName || order.origin.name}</CompanyName>
      <DeliverBy>{formatISOToCustom(order.destination.lateDate)}</DeliverBy>
      <ProductInfo>
        <strong>{order.partNo}</strong> {order.productInfo}
      </ProductInfo>
      {order.quantity > 0 ? (
        <ProductInfo>Quantity: {order.quantity}</ProductInfo>
      ) : null}
    </>
  );
}

function SupplierOrderInfo({ order }: { order: Order }) {
  return (
    <>
      <CompanyName>{order.companyName || order.destination.name}</CompanyName>
      <DeliverBy>{formatISOToCustom(order.destination.lateDate)}</DeliverBy>
      <ProductInfo>
        <strong>{order.partNo}</strong> {order.productInfo}
      </ProductInfo>
      {order.quantity > 0 ? (
        <ProductInfo>Quantity: {order.quantity}</ProductInfo>
      ) : null}
    </>
  );
}

function CarrierOrderInfo({ order }: { order: Order }) {
  return (
    <>
      <CarrierPickupDeliverTitle>
        Pickup: {order.origin.city}, {order.origin.state}
      </CarrierPickupDeliverTitle>
      <CarrierPickupDeliverSubTitle css={{ marginBottom: 5 }}>
        {formatISOToCustom(order.origin.lateDate)}
      </CarrierPickupDeliverSubTitle>

      <CarrierPickupDeliverTitle>
        Deliver: {order.destination.city}, {order.destination.state}
      </CarrierPickupDeliverTitle>
      <CarrierPickupDeliverSubTitle>
        {formatISOToCustom(order.destination.lateDate)}
      </CarrierPickupDeliverSubTitle>
    </>
  );
}
