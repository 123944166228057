/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { useSetTitle } from "components/hooks/useSetTitle";
import { t } from "i18next";
import { OrderCard } from "./components/OrderCard/OrderCard";
import { PageHeader } from "./components/PageHeader/PageHeader";
import { useOrdersData } from "./hooks/useOrdersData";

const CardList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export function DynamicNormalOrdersOemView() {
  useSetTitle(t("dynamicNormal:Dynamic Normal Orders"));
  const {
    plant,
    setPlant,
    sort,
    setSort,
    filterType,
    setFilterType,
    filterValue,
    setFilterValue,
    activeChecked,
    setActiveChecked,
    archivedChecked,
    setArchivedChecked,
    orders: _orders,
  } = useOrdersData();

  const opportunities = _orders.filter(
    (order) =>
      order.opportunities &&
      order.statusItems[0].status === "active" &&
      order.statusItems[2].status !== "active",
  );
  const orders = _orders.filter(
    (order) => !order.opportunities || order.statusItems[2].status === "active",
  );

  return (
    <div css={{ padding: 20 }}>
      <PageHeader
        variant="oem"
        plant={plant}
        setPlant={setPlant}
        sort={sort}
        setSort={setSort}
        filterType={filterType}
        setFilterType={setFilterType}
        filterValue={filterValue}
        setFilterValue={setFilterValue}
        activeChecked={activeChecked}
        setActiveChecked={setActiveChecked}
        archivedChecked={archivedChecked}
        setArchivedChecked={setArchivedChecked}
        orders={orders}
        opportunities={opportunities}
      >
        <CardList css={{ flex: 1 }}>
          {opportunities.map((opportunity) => (
            <OrderCard
              key={opportunity.id}
              order={opportunity}
              variant="oem"
              opportunity={true}
            />
          ))}
        </CardList>
      </PageHeader>
      <CardList>
        {orders.map((order) => (
          <OrderCard key={order.id} order={order} variant="oem" />
        ))}
      </CardList>
    </div>
  );
}
