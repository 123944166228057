/** @jsxImportSource @emotion/react */
import { useSetTitleOnMount } from "components/hooks/useSetTitle";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import Colors from "styles/colors";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { Search } from "components/templates/Search.template";
import { Tabs } from "components/molecules/Tabs.molecule";
import { Button } from "components/atoms/Button.atom";
import { LocationSelect } from "../components/organisms/LocationSelect";
import SearchBarContainer from "./components/CriticalParts.SearchBar.container";
import { useColumns } from "../table/CriticalParts.columns";
import { useCriticalPartsListData } from "../hooks/useCriticalPartsListData";
import { Icon } from "components/atoms/Icon.atom";
import { Text } from "components/atoms/Text.atom";
import { SortBySelect } from "../components/organisms/SortBySelect";

const CriticalPartsSearch = ({
  userPreferencesIsLoading,
  userPreferencesIsLoadingError,
  userPreferences,
  setPreferences,
  setSortColumn,
  setSortReverse,
  sortColumn,
  sortReverse,
}) => {
  const { t } = useTranslation("critical-parts");
  useSetTitleOnMount(t("critical-parts:Critical Parts"));

  const dispatch = useDispatch();

  useTrackWithMixpanelOnce("Viewing Page: Critical Parts / Search");

  const [selectedLocationId, setSelectedLocationId] = useState("");
  const [defaultLocationId, setDefaultLocationId] = useState(
    userPreferences.criticalPartsPreference?.defaultLocationId || "",
  );
  const [lastClickedLocationId, setLastClickedLocationId] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchText, setSearchText] = useState("");

  const setPagination = (newPageNumber, newPageSize) => {
    setPageNumber(newPageNumber);
    setPageSize(newPageSize);
  };

  const resetSearch = () => {
    setSearchText("");
    refetch();
  };

  const setDefaultLocation = (locationId) => {
    const preferences = {
      locale: userPreferences.locale,
      dateformat: userPreferences.dateformat,
      timeformat: userPreferences.timeformat,
      timezone: userPreferences.timezone,
      ...(locationId && {
        criticalPartsPreference: { defaultLocationId: locationId },
      }),
    };
    setPreferences(preferences);
    setDefaultLocationId(locationId || "");
  };

  const rowClickHandler = (row, cell) => {
    if (!row || !cell) {
      return;
    }
    dispatch({
      type: "CRITICAL_PARTS_DETAILS",
      payload: {
        location_id: selectedLocationId,
        part_number: row.original?.partNumber,
      },
    });
  };

  const columns = useColumns();

  const { isFetchingList, criticalPartsList, refetch } =
    useCriticalPartsListData(
      selectedLocationId,
      searchText,
      pageNumber,
      pageSize,
      sortColumn,
      sortReverse,
    );

  useEffect(() => {
    if (criticalPartsList) {
      setTotalCount(criticalPartsList.meta.totalCount);
      setTotalPages(criticalPartsList.meta.totalPages);
    }
  }, [criticalPartsList]);

  return (
    <Tabs
      fullWidthTabs={false}
      style={{ marginLeft: "1em", marginRight: "1em" }}
    >
      <Tabs.TabList
        postTabContent={
          <div
            css={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
            }}
          >
            {selectedLocationId !== "" && selectedLocationId !== null && (
              <>
                {userPreferencesIsLoading ? (
                  <Icon
                    src={faSpinner}
                    spin
                    data-qa="default-location-icon-spinner"
                    display="inline-block"
                  />
                ) : userPreferencesIsLoadingError &&
                  selectedLocationId === lastClickedLocationId ? (
                  <Button
                    data-qa="default-location-button-retry"
                    variant="link"
                    css={{
                      color: Colors.text.GRAY,
                      ":hover": {
                        color: Colors.background.DARK_BLUE,
                      },
                      display: "flex",
                      alignItems: "center",
                      marginRight: "1em",
                    }}
                    onClick={() => {
                      setLastClickedLocationId(selectedLocationId);
                      setDefaultLocation(selectedLocationId);
                    }}
                  >
                    {t("critical-parts:Error, Retry")}
                  </Button>
                ) : defaultLocationId === selectedLocationId ? (
                  <Text
                    data-qa="text-default-location"
                    css={{
                      color: Colors.text.GRAY,
                      display: "flex",
                      alignItems: "center",
                      marginRight: "1em",
                    }}
                  >
                    {t("critical-parts:Default")}
                  </Text>
                ) : (
                  <Button
                    data-qa="button-set-default-location"
                    variant="link"
                    css={{
                      color: Colors.text.GRAY,
                      ":hover": {
                        color: Colors.background.DARK_BLUE,
                      },
                      display: "flex",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setLastClickedLocationId(selectedLocationId);
                      setDefaultLocation(selectedLocationId);
                    }}
                  >
                    {t("critical-parts:Set as Default")}
                  </Button>
                )}
              </>
            )}

            <LocationSelect
              data-qa="dropdown-locations"
              setSelectedLocationId={setSelectedLocationId}
              defaultLocationId={defaultLocationId}
              setDefaultLocation={setDefaultLocation}
            />
          </div>
        }
      >
        <div css={{ width: "25%" }}>
          <Tabs.Tab>{t("critical-parts:Part List")}</Tabs.Tab>
        </div>
      </Tabs.TabList>
      <Tabs.TabPanel>
        {selectedLocationId === "" ? (
          <div
            css={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              maxHeight: "100%",
            }}
          >
            {t("critical-parts:Select a location to view parts")}
          </div>
        ) : (
          <Search
            showFilters={false}
            toggleShowFilters={() => {}}
            totalCount={totalCount}
            searchResults={criticalPartsList?.data || []}
            totalCountTitle={t("critical-parts:Active Parts")}
            isLoading={isFetchingList}
            emptyDataOnLoad={true}
            isShowingAdvancedSearchButton={true}
            hideSavedSearch={true}
            disableDownloadData={true}
            sortByDropDownContent={
              <SortBySelect
                sort={sortColumn}
                setSort={setSortColumn}
                direction={sortReverse}
                setDirection={setSortReverse}
              />
            }
            SearchBarContainer={() => (
              <SearchBarContainer
                setSearchText={setSearchText}
                searchText={searchText}
                refetch={refetch}
              />
            )}
            resetSearch={resetSearch}
            tableProps={{
              columns,
              isLoading: isFetchingList,
              showPagination: true,
              fixPaginationToBottom: true,
              isManualPagination: true,
              isManualSorting: true,
              pageIndex: pageNumber,
              pageSize: pageSize,
              pageCount: totalPages,
              rowClickHandler: rowClickHandler,
              onPageChange: (newPageNumber) => {
                setPagination(newPageNumber, pageSize);
              },
              onPageSizeChange: (newPageSize) => {
                setPagination(0, newPageSize);
              },
            }}
          />
        )}
      </Tabs.TabPanel>
    </Tabs>
  );
};

CriticalPartsSearch.propTypes = {
  userPreferences: PropTypes.object.isRequired,
  setPreferences: PropTypes.func.isRequired,
  userPreferencesIsLoading: PropTypes.bool.isRequired,
  userPreferencesIsLoadingError: PropTypes.bool.isRequired,
  setSortColumn: PropTypes.func,
  setSortReverse: PropTypes.func,
  sortColumn: PropTypes.string,
  sortReverse: PropTypes.bool,
};

export default CriticalPartsSearch;
