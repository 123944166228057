import { useEffect, useRef, RefObject } from "react";

export function useHeightExpandAnimation(expanded: boolean): RefObject<any> {
  const ref = useRef<HTMLElement>(null);

  useEffect(() => {
    if (ref.current === null) {
      return;
    }
    if (expanded) {
      ref.current.style.height = `${ref.current.scrollHeight}px`;
      ref.current.style.overflow = "auto";
    } else {
      ref.current.style.overflow = "hidden";
      ref.current.style.height = "0px";
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  return ref;
}
