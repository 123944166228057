/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { faEnvelopeOpenDollar } from "@fortawesome/pro-solid-svg-icons";
import { FontSize, Icon, IconType } from "components/atoms/Icon.atom";
import { getActiveOrganization } from "modules/organizations/OrganizationsState";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  Container,
  DockTimeStyled,
  DockTitle,
  formatUSD,
  InfoText,
  Title,
} from "../Shared";
// import { getCurrentUser } from "modules/users/UsersState";
// import { UserAuthorizationNamespace } from "modules/auth/Authorization";
import { QueryClient, useQueryClient } from "@tanstack/react-query";
import { HideGlobalSpinner, ShowGlobalSpinner } from "components/GlobalSpinner";
import { ValidatorInput } from "pages/dynamic-normal/formLibrary/Input";
import {
  Offer,
  Order,
  orderService,
} from "pages/dynamic-normal/service/orderServices";

const leftBorder = css`
  border-left: 2px solid #e5e5e5;
  padding-left: 10px;
`;

const InterestedButton = styled(Button)`
  padding: 0px 10px;
  margin-top: 21px;
  background-color: #0d6efd;
  color: white;
`;

interface OfferDetailProps {
  title: string;
  children: React.ReactNode;
  border?: boolean;
}

const OfferDetail: React.FC<OfferDetailProps> = (props) => {
  return (
    <DockTimeStyled css={props.border ? leftBorder : { paddingLeft: 10 }}>
      <DockTitle>{props.title}</DockTitle>
      <InfoText>{props.children}</InfoText>
    </DockTimeStyled>
  );
};

const OfferIcon = (
  <Icon
    style={{ color: "#0077be" }}
    type={IconType.FontAwesome}
    src={faEnvelopeOpenDollar}
    size={FontSize.size16}
  />
);

interface Organization {
  fv_id: string;
}

async function expressInterest(
  offer: Offer,
  order: Order,
  activeOrg: Organization,
  queryClient: QueryClient,
): Promise<void> {
  ShowGlobalSpinner("Submitting Interest...");
  const updatedOrder = { ...order };
  updatedOrder.opportunities = updatedOrder.opportunities || {};
  updatedOrder.opportunities[activeOrg.fv_id] = {
    ...activeOrg,
    milesToSupplier: order.offers[0].originalMiles * 0.0345,
    totalMiles: order.offers[0].originalMiles,
    status: "Interested",
    offerPrice: order.offers[0].newCost,
  };
  await orderService.updateOrder(updatedOrder);
  queryClient.invalidateQueries({ queryKey: ["orders"] });
  HideGlobalSpinner();
}

interface OfferCardProps {
  order: Order;
}

export const OfferCard: React.FC<OfferCardProps> = ({ order }) => {
  const queryClient = useQueryClient();
  const activeOrg = useSelector(getActiveOrganization);
  // const auth = useSelector(getCurrentUser);
  // const currentUser = auth[UserAuthorizationNamespace];

  const opportunity = order?.opportunities?.[activeOrg?.fv_id];

  if (!order.offers || order.offers.length === 0) {
    return null;
  }

  const offers = order.offers;

  return (
    <Card css={{ margin: 10, padding: 10, flexDirection: "column" }}>
      <Title>{OfferIcon} Offers</Title>
      {offers.map((offer, index) => (
        <Container key={index}>
          <OfferDetail border title="Total Miles">
            {offer.originalMiles} mi
          </OfferDetail>
          <OfferDetail border title="Miles To Supplier">
            {(offer.originalMiles * 0.0345).toFixed(2)} mi
          </OfferDetail>
          <OfferDetail border title="Offer Price">
            {formatUSD(offer.newCost)}
          </OfferDetail>
          <DockTimeStyled
            css={{
              borderLeft: "2px solid #e5e5e5",
              paddingLeft: 10,
              paddingRight: 10,
            }}
          >
            <DockTitle>Asset ID</DockTitle>
            <ValidatorInput name="offer.assetID" />
          </DockTimeStyled>

          {opportunity ? (
            <OfferDetail border title="Interest">
              Confirmed
            </OfferDetail>
          ) : (
            <InterestedButton
              type="button"
              onClick={() =>
                expressInterest(offer, order, activeOrg, queryClient)
              }
            >
              Interested
            </InterestedButton>
          )}
        </Container>
      ))}

      {/* <div css={{ display: "flex" }}>
        <AddressBlock>
          <AddressTitle>{MapDot} Planned Origin</AddressTitle>
          <AddressSection>
            <AddressTitle2>{origin.name}</AddressTitle2>
            <Address>{origin.address}</Address>
            <Address>
              {origin.city}, {origin.state}
            </Address>
            <hr />
            <DockInfo>
              <DockPickupTime>
                {formatISOToCustom(origin.lateDate)}
              </DockPickupTime>
            </DockInfo>
          </AddressSection>
        </AddressBlock>

        <AddressBlock>
          <AddressTitle>{MapDot} Stop 1 - Order Origin</AddressTitle>
          <AddressSection>
            <AddressTitle2>{stop1.name}</AddressTitle2>
            <Address>{stop1.address}</Address>
            <Address>
              {stop1.city}, {stop1.state}
            </Address>
            <hr />
            <DockInfo>
              <DockPickupTime>
                {formatISOToCustom(stop1.lateDate)}
              </DockPickupTime>
            </DockInfo>
          </AddressSection>
        </AddressBlock>

        <AddressBlock>
          <AddressTitle>{MapDot} Stop 2 - Order Destination</AddressTitle>
          <AddressSection>
            <AddressTitle2>{stop2.name}</AddressTitle2>
            <Address>{stop2.address}</Address>
            <Address>
              {stop2.city}, {stop2.state}
            </Address>
            <hr />
            <DockInfo>
              <DockDeliverTime>
                {formatISOToCustom(stop2.lateDate)}
              </DockDeliverTime>
            </DockInfo>
          </AddressSection>
        </AddressBlock>

        <AddressBlock>
          <AddressTitle>{MapDot} Planned Destination</AddressTitle>
          <AddressSection>
            <AddressTitle2>{dest.name}</AddressTitle2>
            <Address>{dest.address}</Address>
            <Address>
              {dest.city}, {dest.state}
            </Address>
            <hr />
            <DockInfo>
              <DockDeliverTime>
                {formatISOToCustom(dest.lateDate)}
              </DockDeliverTime>
            </DockInfo>
          </AddressSection>
        </AddressBlock>
      </div>

      <Timeline order={order} /> */}
    </Card>
  );
};
