/** @jsxImportSource @emotion/react */
import { ValidatorInput } from "pages/rtscao/formLibrary/Input";
import { AsyncPaginate } from "react-select-async-paginate";
import Colors from "styles/colors";
import { ComponentType, useState } from "react";
import { GroupBase, OptionProps } from "react-select";
import { useR2SContext } from "pages/rtscao/service/R2SContext";

interface ShippingModeOption {
  value: string;
  label: string;
}

const shippingModeOptions: ShippingModeOption[] = [
  {
    value: "LTL",
    label: "LTL - Less Than Truckload",
  },
  {
    value: "FTL",
    label: "FTL - Full Truckload",
  },
];

interface FormatOptionLabelProps {
  value: string;
  label: string;
  customAbbreviation?: string;
}

const formatOptionLabel = ({ label }: FormatOptionLabelProps) => {
  return <div>{label}</div>;
};

const Option: ComponentType<
  OptionProps<FormatOptionLabelProps, false, GroupBase<FormatOptionLabelProps>>
> = ({
  label,
  innerProps,
  data,
  selectProps,
  isDisabled,
  isSelected,
  isFocused,
}) => {
  return (
    <div
      ref={innerProps.ref}
      {...innerProps}
      className={selectProps.className}
      css={{
        fontSize: "12px",
        paddingLeft: "10px",
        paddingBottom: "10px",
        backgroundColor: isDisabled
          ? Colors.background.LIGHT_GRAY
          : isSelected
          ? "#EBF3FF"
          : isFocused
          ? "#DEEBFF"
          : "#fff",
        color: isDisabled
          ? "#aaa"
          : isSelected
          ? "#333"
          : isFocused
          ? "#333"
          : "#6d6d6d",
      }}
    >
      <div>
        <strong>{label}</strong>
      </div>
    </div>
  );
};

interface SelectShippingModeProps {
  defaultValue?: string;
  name?: string;
  namePrefix?: string;
  required?: boolean;
  disabled?: boolean;
}

export function SelectShippingMode(props: SelectShippingModeProps) {
  const { editMode } = useR2SContext();
  const defaultState = props.defaultValue
    ? shippingModeOptions.find((d) =>
        d.value.startsWith(props.defaultValue as string),
      ) ?? shippingModeOptions[1]
    : shippingModeOptions[1];

  const [val, setVal] = useState<ShippingModeOption | null>(defaultState);

  if (editMode === false) {
    return <div>{val?.label}</div>;
  }

  return (
    <>
      <AsyncPaginate
        defaultOptions
        formatOptionLabel={formatOptionLabel}
        isDisabled={props.disabled}
        styles={{
          control: (styles) => ({
            ...styles,
            opacity: props.disabled ? 0.5 : 1,
            backgroundColor: props.disabled ? "#ffffff00" : "white",
            borderRadius: 4,
            border: "2px solid #dedede",
            flex: 1,
            minHeight: 0,
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            padding: "0px 4px",
          }),
          valueContainer: (styles) => ({
            ...styles,
            padding: "0px 8px",
          }),
        }}
        placeholder="Select Shipping Mode"
        loadOptions={(inputStr: string) => ({
          options: shippingModeOptions.filter((plant) =>
            plant.label.toLowerCase().includes(inputStr.toLowerCase()),
          ),
        })}
        onChange={setVal}
        value={val}
        components={{
          Option,
        }}
      />
      <ValidatorInput
        name="shipmentDetails.shippingMode"
        required={true}
        css={{ display: "none" }}
        value={val?.value ?? ""}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          if ((ev.target as any).resetting) {
            setVal(null);
          }
        }}
      />
    </>
  );
}
