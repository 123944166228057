/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import moment from "moment";

import { useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";

import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";

import { useTrackWithMixpanelOnce } from "trackers/mixpanel";

import Colors, { addAlphaToColor } from "styles/colors";
import { ResponsiveSection } from "styles/container-elements";
import { Tabs } from "components/molecules/Tabs.molecule";
import { Search } from "components/templates/Search.template";
import SearchBarContainer from "../details/search/InventoryView.Details.SearchBarContainer";

// TODO: remove temp data
import {
  arrivalsData,
  departuresData,
  inventoryData,
  pipelineData,
  pieChartData1,
  pieChartData2,
  pieChartData3,
  pieChartData4,
} from "../redux/data";

import {
  useInventoryChartBars,
  useArrivalsChartBars,
  usePipelineChartBars,
  useDeparturesChartAreas,
} from "./components/InventoryCharts.Columns";
import { ShippableNonShippableCountsWidget } from "./components/ShippableNonShippableCountsWidget";
import { QUERY_KEY_MAPPING } from "../details/search/InvertoryView.Details.Search.Options";
import {
  defaultTab,
  selectedTab,
  tabsListStyle,
} from "../dashboard/components/InventoryNavigationTabs";

import { faHouse } from "@fortawesome/pro-regular-svg-icons";
import {
  faChartLine,
  faTriangleExclamation,
} from "@fortawesome/pro-solid-svg-icons";
import { FontSize, Icon } from "components/atoms/Icon.atom";
import { Text } from "components/atoms/Text.atom";
import { PanelGroup } from "components/molecules/PanelGroup.molecule";
import { formattedData, getColors } from "../utils/chart.utils";
import { PieChartWithLegends } from "components/molecules/PieChartWithLegends.molecule";
import { DeparturesCountsWidget } from "./components/DeparturesCountsWidget";
import { LocalizedNumber } from "components/atoms/LocalizedNumber";

const InventoryViewInsights = ({
  location,
  locationId,
  solutionId,
  redirectToDashboardView,
  redirectToDetailsView,
  setSearchFilter,
  isLoading,
  searchResults,
  toggleShowFilters,
  onsiteVinsResetSearch,
  fetchLocation,
}) => {
  const { t } = useTranslation("inventory-view");

  useSetTitleOnMount(t("inventory-view:InventoryView"));

  useSetDescriptionOnMount(
    t(
      "inventory-view:All InventoryView Locations, their current capacity and forecasted capacity",
    ),
  );

  useTrackWithMixpanelOnce("Viewed Page: InventoryView / Details");

  useEffect(() => {
    fetchLocation(solutionId, locationId);
  }, [solutionId, locationId, fetchLocation]);

  const inventoryColumns = useInventoryChartBars(
    setSearchFilter,
    redirectToDetailsView,
    QUERY_KEY_MAPPING.SHIPPABILITY,
  );
  const pipelineColumns = usePipelineChartBars(
    setSearchFilter,
    redirectToDetailsView,
    QUERY_KEY_MAPPING.CURRENT_LOCATION,
  );
  const arrivalsColumns = useArrivalsChartBars(
    setSearchFilter,
    redirectToDetailsView,
    QUERY_KEY_MAPPING.ARRIVAL_DATE,
  );
  const departureColumns = useDeparturesChartAreas(
    setSearchFilter,
    redirectToDetailsView,
    QUERY_KEY_MAPPING.DEPARTURE_DATE,
  );

  if (!location) {
    return null;
  }

  const tabIndex = 1;
  const arrivalsDataset = formattedData(arrivalsData.data);
  const todaysArrivals = arrivalsDataset.filter((item) => {
    return item.isTodaysDate;
  });
  const subTitle = t("inventory-view:Shippable VINs");
  const todaysDate = moment(todaysArrivals.name).format("MMM DD, YYYY");
  let fivePercentOfTotalCapacity = 0;
  if (inventoryData.capacity) {
    fivePercentOfTotalCapacity = Math.trunc((inventoryData.capacity / 5) * 100);
  }

  return (
    <ResponsiveSection
      style={{
        justifyContent: "flex-start",
        padding: "1em 2em 2em",
        background: Colors.background.LIGHT_GRAY,
        overflowY: "auto",
        height: "100%",
      }}
    >
      <Tabs selectedIndex={tabIndex}>
        <Tabs.TabList css={{ ...tabsListStyle }}>
          <Tabs.Tab
            css={{ ...defaultTab }}
            onClick={(evt) => redirectToDashboardView()}
          >
            <Icon src={faHouse} />{" "}
            <Text
              css={{
                fontSize: "inherit",
                textUnderlineOffset: "2px",
                textDecoration: "underline",
              }}
            >
              {t("inventory-view:Home")}
            </Text>
          </Tabs.Tab>
          <Tabs.Tab css={{ ...selectedTab }}>
            <Icon src={faChartLine} />{" "}
            <Text
              css={{
                fontSize: "inherit",
                textUnderlineOffset: "2px",
                textDecoration: "underline",
              }}
            >
              {t("inventory-view:Details")}
            </Text>
          </Tabs.Tab>
          <div
            css={{
              marginLeft: "auto",
              minWidth: "50em",
              marginBottom: "10px",
            }}
          >
            <Search
              isLoading={isLoading}
              searchResults={searchResults}
              toggleShowFilters={toggleShowFilters}
              resetSearch={() => onsiteVinsResetSearch(solutionId)}
              showFilters={false}
              hideSavedSearch={true}
              SearchBarContainer={SearchBarContainer}
              hideSearchResults={true}
            />
          </div>
        </Tabs.TabList>
        <Tabs.TabPanel>
          <Text>Empty Tab</Text>
        </Tabs.TabPanel>
        <Tabs.TabPanel
          style={{ border: 0, background: "none", boxShadow: "none" }}
        >
          <Text
            size={FontSize.size32}
            block
            bold
            css={{
              paddingBottom: "0.5em",
              fontWeight: 500,
            }}
          >{`${location.name} (${location.code})`}</Text>
          <div
            css={{
              background: addAlphaToColor(
                Colors.inventoryView.INSIGHTS_BACKGROUND,
                10,
              ),
              padding: "20px 15px",
            }}
          >
            <Text size={FontSize.size12} block>
              {todaysDate}
            </Text>
            <Icon
              src={faTriangleExclamation}
              color={Colors.inventoryView.BLUE}
              style={{ marginRight: 5 }}
            />
            <Text size={FontSize.size20} color={Colors.inventoryView.BLUE} bold>
              {t(`inventory-view:Today's Insights`)}
            </Text>
            <div
              style={{
                display: "grid",
                gridColumnGap: "20px",
                gridRowGap: "20px",
                justifyItems: "stretch",
                gridTemplateColumns: "repeat(auto-fill, minmax(300px, 1fr))",
                marginTop: "15px",
              }}
            >
              <PanelGroup>
                <PanelGroup.Content
                  style={{ lineHeight: "1.8em", padding: "26px 20px" }}
                >
                  <Text
                    color={Colors.text.DARK_GRAY}
                    size={FontSize.size36}
                    bold
                    block
                  >
                    <LocalizedNumber value={inventoryData.totalInventory} />/
                    <LocalizedNumber value={inventoryData.capacity} />
                  </Text>
                  <Text
                    color={getColors(
                      "inventory",
                      inventoryData.totalInventory,
                      inventoryData.capacity,
                    )}
                    size={FontSize.size18}
                    bold
                    block
                  >
                    {t("inventory-view:Current Inventory")}
                  </Text>
                </PanelGroup.Content>
              </PanelGroup>
              <PanelGroup>
                <PanelGroup.Content
                  style={{ lineHeight: "1.8em", padding: "26px 20px" }}
                >
                  <Text
                    color={Colors.text.DARK_GRAY}
                    size={FontSize.size36}
                    bold
                    block
                  >
                    <LocalizedNumber value={departuresData.data[7].y} />
                  </Text>
                  <Text
                    color={getColors(
                      "daysOnSite",
                      departuresData.data[7].y,
                      fivePercentOfTotalCapacity,
                    )}
                    size={FontSize.size18}
                    bold
                    block
                  >
                    {t("inventory-view:10+ Days on Site")}
                  </Text>
                </PanelGroup.Content>
              </PanelGroup>
              <PanelGroup>
                <PanelGroup.Content
                  style={{ lineHeight: "1.8em", padding: "26px 20px" }}
                >
                  <Text
                    color={Colors.text.DARK_GRAY}
                    size={FontSize.size36}
                    bold
                    block
                  >
                    {todaysArrivals[0].forecasted}
                  </Text>
                  <Text
                    color={getColors(
                      "forecastedArrivals",
                      todaysArrivals[0].forecasted,
                    )}
                    size={FontSize.size18}
                    bold
                    block
                  >
                    {t("inventory-view:Todays Forecasted Arrivals")}
                  </Text>
                </PanelGroup.Content>
              </PanelGroup>
              <PanelGroup>
                <PanelGroup.Content
                  style={{ lineHeight: "1.8em", padding: "26px 20px" }}
                >
                  <Text
                    color={Colors.text.DARK_GRAY}
                    size={FontSize.size36}
                    bold
                    block
                  >
                    {departuresData.data[7].y && departuresData.capacity ? (
                      <Fragment>
                        <LocalizedNumber value={departuresData.data[7].y} />/
                        <LocalizedNumber value={departuresData.capacity} />
                      </Fragment>
                    ) : null}
                  </Text>
                  <Text
                    color={getColors(
                      "departures",
                      todaysArrivals[0].forecasted,
                    )}
                    size={FontSize.size18}
                    bold
                    block
                  >
                    {t("inventory-view:Yesterdays Departures")}
                  </Text>
                </PanelGroup.Content>
              </PanelGroup>
            </div>
          </div>
          <div
            style={{
              marginTop: "20px",
              display: "grid",
              gridColumnGap: "20px",
              gridRowGap: "20px",
              justifyItems: "stretch",
              gridTemplateColumns: "repeat(4, 1fr)",
            }}
          >
            <PieChartWithLegends
              widgetData={pieChartData1}
              subTitle={subTitle}
              title={t("inventory-view:Time on Site")}
              x={"type"}
              y={"vins_associated"}
            />

            <PieChartWithLegends
              widgetData={pieChartData2}
              subTitle={subTitle}
              title={t("inventory-view:Initial ETA")}
              x={"type"}
              y={"vins_associated"}
            />

            <PieChartWithLegends
              widgetData={pieChartData3}
              subTitle={subTitle}
              title={t("inventory-view:Type")}
              x={"type"}
              y={"vins_associated"}
            />

            <PieChartWithLegends
              widgetData={pieChartData4}
              subTitle={subTitle}
              title={t("inventory-view:Assigned Carrier")}
              x={"type"}
              y={"vins_associated"}
            />
          </div>
          <div
            css={{
              display: "grid",
              gridColumnGap: "20px",
              gridRowGap: "20px",
              justifyItems: "stretch",
              gridTemplateColumns: "repeat(2, 1fr)",
              marginTop: "2em",
            }}
          >
            <ShippableNonShippableCountsWidget
              widgetData={formattedData(inventoryData.data)}
              threshold={inventoryData.capacity}
              title={t("inventory-view:Inventory")}
              bars={inventoryColumns}
              xAxisTickFormat={{
                difference: 300,
                maxLimit: 1200,
              }}
              showTotals={true}
            />

            <ShippableNonShippableCountsWidget
              widgetData={formattedData(pipelineData.data)}
              title={t("inventory-view:Pipeline")}
              bars={pipelineColumns}
              xAxisTickFormat={{
                difference: 2500,
                maxLimit: 10000,
              }}
              showTotals={true}
            />

            <ShippableNonShippableCountsWidget
              widgetData={arrivalsDataset}
              title={t("inventory-view:Arrivals")}
              bars={arrivalsColumns}
              xAxisTickFormat={{
                difference: 50,
                maxLimit: 200,
              }}
              showTotals={false}
              showAlert={true}
            />

            <DeparturesCountsWidget
              widgetData={departuresData.data}
              showCapactiy={false}
              threshold={departuresData.capacity}
              maxThreshold={departuresData.maxCapacity}
              title={t("inventory-view:Departures")}
              bars={departureColumns}
              xAxisTickFormat={{
                difference: 1,
                maxLimit: 14,
              }}
              yAxisTickFormat={{
                difference: 30,
                maxLimit: 120,
              }}
            />
          </div>
        </Tabs.TabPanel>
      </Tabs>
    </ResponsiveSection>
  );
};

InventoryViewInsights.propTypes = {
  location: PropTypes.object,
  locationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  solutionId: PropTypes.string,
  redirectToDashboardView: PropTypes.func,
  redirectToDetailsView: PropTypes.func,
  setSearchFilter: PropTypes.func,
  isLoading: PropTypes.bool,
  searchResults: PropTypes.array,
  toggleShowFilters: PropTypes.func,
  onsiteVinsResetSearch: PropTypes.func,
  fetchLocation: PropTypes.func.isRequired,
};

export default InventoryViewInsights;
