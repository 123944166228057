/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  fetchCpEnabled,
  fetchLocationById,
  fetchLocations,
} from "../../redux/LocationSelectState";
import { useQueryClient } from "@tanstack/react-query";
import PropTypes from "prop-types";
import Colors from "styles/colors";
import { Text } from "components/atoms/Text.atom";

export const LocationSelect = ({
  setSelectedLocationId,
  defaultLocationId,
  setDefaultLocation,
}) => {
  const [searchText, setSearchText] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isLocationSelected, setIsLocationSelected] = useState(false);

  const { t } = useTranslation("critical-parts");

  const queryClient = useQueryClient();

  useEffect(() => {
    if (isLocationSelected) {
      return;
    }

    const loadDefaultLocation = async () => {
      if (!defaultLocationId) {
        const locations = await fetchLocations("", "", 0, 1);
        if (!locations?.options || locations?.options.length === 0) {
          console.error("No locations available");
          return;
        }
        const firstLocation = locations.options[0];
        setSelectedLocation(firstLocation);
        setSelectedLocationId(firstLocation.value);
        setIsLocationSelected(true);

        return;
      }

      const enabled = await fetchCpEnabled(defaultLocationId);
      if (!enabled) {
        return setDefaultLocation(null);
      }

      const location = await fetchLocationById(defaultLocationId);
      if (location?.data?.is_active === false) {
        return setDefaultLocation(null);
      }

      setSelectedLocation(location);
      setSelectedLocationId(location?.value ?? "");
    };

    loadDefaultLocation();
  }, [
    defaultLocationId,
    setDefaultLocation,
    setSelectedLocationId,
    isLocationSelected,
  ]);

  const loadOptions = async (currentVal, loadedOptions, additionalOptions) => {
    const searchText = currentVal;
    const pageNumber = additionalOptions ? additionalOptions.pageNumber : 0;

    const data = await queryClient.fetchQuery({
      queryKey: ["locations", searchText, pageNumber],
      queryFn: () => fetchLocations(currentVal, searchText, pageNumber),
    });

    return {
      options: data.options,
      hasMore: data.hasMore,
      additional: {
        pageNumber: pageNumber + 1,
        prevValue: currentVal,
      },
    };
  };

  const formatOptionLabel = (data, { context }) => {
    if (context === "value") {
      return (
        <div>
          <Text color={Colors.text.GRAY}>{t("critical-parts:Plant")}: </Text>
          <Text>{data.label}</Text>
        </div>
      );
    }
    return data.label;
  };

  return (
    <AsyncPaginate
      data-qa="critical-parts-select-location"
      debounceTimeout={500}
      isMulti={false}
      cacheOptions={false}
      onInputChange={(val) => {
        setSearchText(val);
      }}
      placeholder={t("critical-parts:Select Location")}
      hideSelectedOptions={false}
      closeMenuOnSelect={true}
      value={selectedLocation}
      loadOptions={loadOptions}
      styles={{
        container: (provided) => ({
          ...provided,
          width: "20em",
          padding: "0.5em",
          zIndex: 3,
        }),
        menuList: (provided) => ({
          ...provided,
          paddingTop: 0,
          paddingBottom: 0,
        }),
      }}
      cacheUniqs={[searchText]}
      formatOptionLabel={formatOptionLabel}
      onChange={(selectedData) => {
        setSelectedLocation(selectedData ?? "");
        setSelectedLocationId(selectedData?.value ?? "");
        setIsLocationSelected(true);
      }}
      additional={{
        pageNumber: 0,
      }}
    />
  );
};

LocationSelect.propTypes = {
  setSelectedLocationId: PropTypes.func.isRequired,
  defaultLocationId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setDefaultLocation: PropTypes.func.isRequired,
};

LocationSelect.defaultProps = {
  defaultLocationId: null,
};
