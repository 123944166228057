import React from "react";
import styled from "@emotion/styled";

interface StepIndicatorProps {
  currentStep: number;
  totalSteps: number;
  title: string;
}

const StepIndicatorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const StepCirclesContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const StepCircle = styled.div<{ active: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${(props) => (props.active ? "#4CAF50" : "#ccc")};
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  margin: 0 5px;
`;

const StepConnector = styled.div`
  width: 30px;
  height: 2px;
  background-color: #ccc;
`;

const StepTitle = styled.h2`
  font-size: 18px;
  margin: 0;
  display: flex;
  align-items: center;
`;

const StepIcon = styled.img`
  width: 32px;
  height: 32px;
  margin-left: 10px;
`;

export const StepIndicator: React.FC<StepIndicatorProps> = ({
  currentStep,
  totalSteps,
  title,
}) => {
  return (
    <StepIndicatorContainer>
      <StepCirclesContainer>
        {Array.from({ length: totalSteps }, (_, index) => (
          <React.Fragment key={index}>
            {index > 0 && <StepConnector />}
            <StepCircle active={index + 1 <= currentStep} />
          </React.Fragment>
        ))}
      </StepCirclesContainer>
      <StepTitle>
        {`Step ${currentStep}: ${title}`}
        <StepIcon src="/r2s.png" alt="R2S Icon" />
      </StepTitle>
    </StepIndicatorContainer>
  );
};
