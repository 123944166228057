import { HideGlobalSpinner, ShowGlobalSpinner } from "components/GlobalSpinner";
import { mockPlantHashMap, sortKeyHashMap } from "../mockData";

interface OrderService {
  getOrders(args: GetOrdersArgs): Promise<OrderResponse>;
  getOrder(id: string): Promise<Order>;
  addOrder(order: Order): Promise<Order>;
  updateOrder(order: Order): Promise<Order>;
  deleteOrder(order: Order): Promise<void>;
  createOrder(order: Order): Order;
}

interface GetOrdersArgs {
  filters?: { [key: string]: any };
  page?: number;
  limit?: number;
  sort?: string;
  fields?: string[];
}

interface OrderResponse {
  meta: Meta;
  data: Order[];
}

interface Meta {
  totalPages: number;
  currentPage: number;
  totalCount: number;
}

export interface Carrier {
  id: string;
  shipment_id?: string;
  carrierName: string;
  carrierType: string;
  milesToSupplier: number;
  totalMiles: number;
  offerStatus: string;
  price: number;
}

export interface Order {
  id: string;
  carrierAwarded: boolean;
  carrier: Carrier;
  newlyCreated?: boolean;
  opportunities?: any; // this is temporary for demo
  route?: any; // this is temporary for demo
  _rev?: string;
  orderType: string;
  supplierName: string;
  companyName: string;
  carrierName: string;
  carrierType: string;
  milesToSupplier: number;
  totalMiles: number;
  offerStatus: string;
  price: number;
  partNo: string;
  productInfo: string;
  quantity: number;
  carrierAccepted: boolean;
  offers: Offer[];
  shipmentDetails?: ShipmentDetails;
  orderDate: string;
  timeAgo: string;
  statusItems: StatusItems;
  origin: Origin;
  destination: Destination;
  stops: Stop[];
}

export interface Offer {
  currentShipment?: string;
  currentAssetId?: string;
  originalMiles: number;
  additionalMiles?: number;
  originalCost?: number;
  newCost: number;
  currentOrigin?: Origin;
  currentDestination?: Destination;
}

export interface ShipmentDetails {
  confirmedQty: number;
  grossWt: number;
  shipUnitQty: number;
  shipUnit: string;
  shipUnitDimensions: ShipUnitDimensions;
  trailerSpaceRequirement: number;
  stackable: string;
  hazmat: string;
  temp: string;
}

export interface ShipUnitDimensions {
  length: number;
  height: number;
  width: number;
}

export type StatusItem =
  | {
      label: "Order Confirmed" | "Order Canceled" | "Order Declined";
      status: StatusType;
      additionalInfo?: string;
      additionalInfoIcon?: string;
    }
  | {
      label: "Ready to Ship";
      status: StatusType;
      additionalInfo?: string;
      additionalInfoIcon?: string;
    }
  | {
      label: "Carrier Awarded";
      status: StatusType;
      additionalInfo?: string;
      additionalInfoIcon?: string;
    }
  | {
      label: "Shipment Assigned";
      status: StatusType;
      additionalInfo?: string;
      additionalInfoIcon?: string;
    }
  | {
      label: "Delivered";
      status: StatusType;
      additionalInfo?: string;
      additionalInfoIcon?: string;
    };

export type StatusItems = StatusItem[];

export type StatusType = "active" | "inactive" | "pending" | "declined";

export interface Origin {
  name: string;
  address: string;
  city: string;
  state: string;
  earlyDate: string;
  lateDate: string;
  location: string;
  threeWords: string;
  atDock: string;
  notes: string;
}

export interface Destination {
  name: string;
  address: string;
  city: string;
  state: string;
  earlyDate: string;
  lateDate: string;
  location: string;
  threeWords: string;
  notes: string;
}

interface Stop {
  type: string;
  icon: string;
  address: string;
  time: string;
}

export const serviceUrl = "https://couchdb1.z-wc.net/dynamic-normal";
const user = "dynamic-normal";
const key = "bHmrS4Kht3bYV5Hg9QqdfuY";
const basicAuth = btoa(`${user}:${key}`);

const fetchWrapper = async (url: string, options: RequestInit) => {
  const response = await fetch(url, {
    ...options,
    headers: {
      ...options.headers,
      "Content-Type": "application/json",
      Authorization: `Basic ${basicAuth}`,
    },
  });
  return response.json();
};

function buildSortAndFilters({
  sort,
  plant,
  activeChecked,
  filterType,
  filterValue,
}: any) {
  const sortParam = (sortKeyHashMap as any)[sort]?.sort;

  const plantData = (mockPlantHashMap as any)[plant];
  const plantFilter = plantData
    ? { "destination.name": plantData.value.plantName }
    : {};

  const activeFilter = activeChecked
    ? { "statusItems.0.status": "active" }
    : {};

  let searchFilter: any = {};
  if (filterType === "everything" && filterValue.length > 0) {
    searchFilter["$or"] = [];
    searchFilter["$or"].push({
      id: { $regex: `(?i)${filterValue.toLowerCase()}` },
    });
    searchFilter["$or"].push({
      companyName: { $regex: `(?i)${filterValue.toLowerCase()}` },
    });
    searchFilter["$or"].push({
      partNo: { $regex: `(?i)${filterValue.toLowerCase()}` },
    });
    searchFilter["$or"].push({
      productInfo: { $regex: `(?i)${filterValue.toLowerCase()}` },
    });
    searchFilter["$or"].push({
      "origin.name": { $regex: `(?i)${filterValue.toLowerCase()}` },
    });
    searchFilter["$or"].push({
      "destination.name": { $regex: `(?i)${filterValue.toLowerCase()}` },
    });
  } else if (filterValue.length > 0) {
    switch (filterType) {
      case "id":
        searchFilter["id"] = { $regex: `(?i)${filterValue.toLowerCase()}` };
        break;
      case "companyName":
        searchFilter["companyName"] = {
          $regex: `(?i)${filterValue.toLowerCase()}`,
        };
        break;
      case "partNo":
        searchFilter["partNo"] = { $regex: `(?i)${filterValue.toLowerCase()}` };
        break;
      case "productInfo":
        searchFilter["productInfo"] = {
          $regex: `(?i)${filterValue.toLowerCase()}`,
        };
        break;
      case "origin":
        searchFilter["origin.name"] = {
          $regex: `(?i)${filterValue.toLowerCase()}`,
        };
        break;
      case "destination":
        searchFilter["destination.name"] = {
          $regex: `(?i)${filterValue.toLowerCase()}`,
        };
        break;
      case "quantity":
        searchFilter["quantity"] = Number(filterValue);
        break;
      default:
        break;
    }
  }

  return {
    sort: sortParam,
    filters: {
      ...plantFilter,
      ...activeFilter,
      ...searchFilter,
    },
  };
}

export const orderService: OrderService = {
  getOrders: async ({ filters, page, limit, sort, fields } = {}) => {
    ShowGlobalSpinner("Loading Orders...");

    filters = filters || {};
    page = page || 0;
    limit = limit || 20;

    const { sort: sortParam, filters: filterParams } = buildSortAndFilters({
      sort,
      ...filters,
    });

    const skip = page * limit;
    const body: any = {
      selector: {
        _id: {
          $gt: "orders",
          $lt: "orders\\ufff0",
        },
        ...filterParams,
      },

      limit,
      skip,
      sort: sortParam,
    };

    if (fields) {
      body.fields = ["_id", "_rev", ...fields];
    }

    const results = await fetchWrapper(`${serviceUrl}/_find`, {
      method: "POST",
      body: JSON.stringify(body),
    }).then((response: any) => {
      return {
        meta: {
          totalPages: 1,
          currentPage: 0,
          totalCount: response.docs.length,
        },
        data: response.docs,
      };
    });

    HideGlobalSpinner();
    return results;
  },

  getOrder: async (id: string) => {
    return fetchWrapper(`${serviceUrl}/orders-${id}`, {
      method: "GET",
    });
  },

  addOrder: async (order: Order) => {
    return fetchWrapper(`${serviceUrl}/orders-${order.id}`, {
      method: "PUT",
      body: JSON.stringify(order),
    });
  },

  updateOrder: async (order: Order) => {
    return fetchWrapper(`${serviceUrl}/orders-${order.id}`, {
      method: "PUT",
      body: JSON.stringify(order),
    });
  },

  deleteOrder: async (order: Order) => {
    return fetchWrapper(`${serviceUrl}/orders-${order.id}?rev=${order._rev}`, {
      method: "DELETE",
    });
  },

  createOrder: (order: Order) => {
    const defaultOrderProperties: Order = {
      id: crypto.randomUUID().slice(0, 8),
      carrierAwarded: false,
      carrier: {
        id: "",
        carrierName: "",
        carrierType: "",
        milesToSupplier: 0,
        totalMiles: 0,
        offerStatus: "",
        price: 0,
      },
      newlyCreated: false,
      orderType: "",
      supplierName: "",
      companyName: "",
      carrierName: "",
      carrierType: "",
      milesToSupplier: 0,
      totalMiles: 0,
      offerStatus: "",
      price: 0,
      partNo: "",
      productInfo: "",
      quantity: 0,
      carrierAccepted: false,
      offers: [],
      orderDate: new Date().toISOString(),
      timeAgo: "",
      statusItems: [
        { label: "Order Confirmed", status: "inactive" },
        { label: "Ready to Ship", status: "inactive" },
        { label: "Carrier Awarded", status: "inactive" },
        { label: "Shipment Assigned", status: "inactive" },
        { label: "Delivered", status: "inactive" },
      ],
      origin: {
        name: "",
        address: "",
        city: "",
        state: "",
        earlyDate: "",
        lateDate: "",
        location: "",
        threeWords: "",
        atDock: "No",
        notes: "",
      },
      destination: {
        name: "",
        address: "",
        city: "",
        state: "",
        earlyDate: "",
        lateDate: "",
        location: "",
        threeWords: "",
        notes: "",
      },
      stops: [],
    };

    return { ...defaultOrderProperties, ...order };
  },
};
