import apiUrl from "api-url";
import axios from "axios";

export const fetchLocations = async (
  currentVal,
  searchText,
  pageNumber,
  pageSize = 20,
) => {
  const url = apiUrl(`/location/locations`);

  const params = {
    verbose: false,
    includeLinkLocations: true,
    active_only: true,
    owner_id: null,
    everything: searchText,
    pageSize: pageSize,
    pageNumber,
    sortColumn: "name",
    reverseSort: 0,
    feature_name: "Critical Parts",
  };

  try {
    const response = await axios.get(url, { params });
    const data = response.data.data.map((val) => ({
      label: `${val.name} - ${val.code}`,
      value: val.code,
      data: val,
    }));

    const metaData = response.data.meta;

    return {
      options: data ?? [],
      hasMore: metaData ? metaData.currentPage < metaData.totalPages : false,
      additional: {
        pageNumber,
        prevValue: currentVal,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
      additional: {
        pageNumber,
        prevValue: currentVal,
      },
    };
  }
};

export const fetchLocationById = async (locationId) => {
  const url = apiUrl(`/location/locations/${locationId}`);

  try {
    const response = await axios.get(url);
    const location = response.data;
    return {
      label: `${location.name} - ${location.code}`,
      value: location.id,
      data: location,
    };
  } catch (error) {
    return null;
  }
};

export const fetchCpEnabled = async (locationId) => {
  const url = apiUrl(`/location/locations/${locationId}/feature/CP`);
  try {
    const response = await axios.get(url);
    const enabled = response.data?.enabled;
    return enabled;
  } catch (error) {
    return null;
  }
};
