/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */

import { HideGlobalSpinner, ShowGlobalSpinner } from "components/GlobalSpinner";
import { Order, orderService } from "pages/rtscao/service/orderServices";
import { useState } from "react";
import { ButtonGroup } from "./OrderCardComponents";
import { YesNoConfirmModal } from "../CreateOrder/YesNoConfirmModal";
import { CancelButton } from "../Shared";
import type { QueryClient } from "@tanstack/react-query";

interface DeclinedProps {
  order: Order;
  queryClient: QueryClient;
}

export function DeclinedButtonSection({ order, queryClient }: DeclinedProps) {
  const [confirmShow, setConfirmShow] = useState(false);
  const del = async () => {
    ShowGlobalSpinner("Deleting Order...");
    await orderService.deleteOrder(order);
    queryClient.invalidateQueries({ queryKey: ["orders"] });
    setConfirmShow(false);
    HideGlobalSpinner();
  };

  return (
    <ButtonGroup>
      <YesNoConfirmModal
        show={confirmShow}
        setShow={setConfirmShow}
        confirmAction={del}
      />
      <CancelButton type="button" onClick={() => setConfirmShow(true)}>
        Archive
      </CancelButton>
    </ButtonGroup>
  );
}
