/* eslint-disable curly */
/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import { HideGlobalSpinner, ShowGlobalSpinner } from "components/GlobalSpinner";
import { ValidatorInput } from "pages/rtscao/formLibrary/Input";
import {
  calculateArrivalTime,
  getDrivingDistance,
  getRoute,
  updateDestDate,
  updateOriginDate,
} from "pages/rtscao/service/heremapService";
import React, { useEffect, useRef, useState } from "react";
import { SelectDock } from "../CreateOrder/SelectDock";
import { Location, SelectLocation } from "../CreateOrder/SelectLocation";
import {
  AddressSection,
  AddressTitle,
  DateTimeInput,
  DockInfo,
  DockTimeStyled,
  DockTitle,
  SectionTitle,
  formatSecondsIntoDaysAndHours,
  getDaysAndHoursBetweenDates,
  MapDot,
} from "../Shared";
import { SelectLoadType } from "../CreateOrder/SelectLoadType";

async function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

interface Route {
  error?: any;
  departure?: {
    newTime: string;
  };
  arrival?: {
    newTime: string;
  };
  hmOrigin?: {
    position: {
      lat: number;
      lng: number;
    };
  };
  hmDest?: {
    position: {
      lat: number;
      lng: number;
    };
  };
  summary?: {
    duration: number;
    driveTime: string;
    miles: number;
    practicalDeliveryTime: string;
    conflict?: boolean;
    minArrivalTime?: string;
  };
}

interface Order {
  route?: {
    origin?: {
      lat: number;
      long: number;
    };
    destination?: {
      lat: number;
      long: number;
    };
    summary?: {
      duration: number;
      driveTime: string;
      miles: number;
      practicalDeliveryTime: string;
    };
  };
}

async function getAndSetRoute(
  origin: Location | null,
  dest: Location | null,
  setRoute: React.Dispatch<React.SetStateAction<Route | null>>,
  order: Order | null,
) {
  if (!origin || !dest) {
    return;
  }

  if (order) {
    setRoute({
      departure: {
        newTime: (origin as any).lateDate,
      },
      arrival: {
        newTime: (dest as any).lateDate,
      },
      hmOrigin: {
        position: {
          lat: order?.route?.origin?.lat ?? 0,
          lng: order?.route?.origin?.long ?? 0,
        },
      },
      hmDest: {
        position: {
          lat: order?.route?.destination?.lat ?? 0,
          lng: order?.route?.destination?.long ?? 0,
        },
      },
      summary: {
        duration: order?.route?.summary?.duration ?? 0,
        driveTime: order?.route?.summary?.driveTime ?? "",
        miles: order?.route?.summary?.miles ?? 0,
        practicalDeliveryTime:
          order?.route?.summary?.practicalDeliveryTime ?? "",
      },
    });
    return;
  }

  ShowGlobalSpinner("Fetching Route Data...");
  const { routes, coords1, coords2, error } = await getRoute(
    origin.value,
    dest.value,
  );

  if (error) {
    setRoute({ error: error } as any);
    console.error("error", error);
    HideGlobalSpinner();
    return;
  }

  setRoute((prev) => {
    const arrival = { ...routes?.[0]?.sections?.[0]?.arrival };
    const departure = { ...routes?.[0]?.sections?.[0]?.departure };
    const summary = { ...routes?.[0]?.sections?.[0]?.summary };

    if (arrival && departure && summary) {
      summary.driveTime = formatSecondsIntoDaysAndHours(summary.duration);
      departure.newTime = new Date(departure.time);
      departure.newTime.setHours(departure.newTime.getHours() + 24);

      arrival.newTime = calculateArrivalTime(
        departure.newTime,
        summary.duration,
      );
      summary.miles = getDrivingDistance(summary.length);
      departure.newTime = departure.newTime.toISOString();
      summary.practicalDeliveryTime = getDaysAndHoursBetweenDates(
        departure.newTime,
        arrival.newTime,
      );

      if (
        prev &&
        prev.departure &&
        prev.departure.newTime &&
        prev.arrival &&
        prev.arrival.newTime
      ) {
        const minArrivalTime = calculateArrivalTime(
          new Date(prev?.departure?.newTime ?? ""),
          summary.duration,
        );
        summary.minArrivalTime = minArrivalTime;
        if (
          new Date(prev.arrival.newTime).getTime() <
          new Date(minArrivalTime).getTime()
        ) {
          summary.conflict = true;
        }
      }
    }

    return { summary, arrival, departure, hmOrigin: coords1, hmDest: coords2 };
  });

  HideGlobalSpinner();
  return { routes, coords1, coords2 };
}

interface EditOriginDestinationCardProps {
  origin?: any;
  destination?: any;
  variant?: string;
  order?: Order | null;
  captureCurrentFormData?: any;
}

export const EditOriginDestinationCard: React.FC<
  EditOriginDestinationCardProps
> = ({ origin, destination, variant, order, captureCurrentFormData }) => {
  const [_origin, setOrigin] = useState<Location | null>(null);
  const [_dest, setDest] = useState<Location | null>(null);
  const [route, setRoute] = useState<Route | null>(null);
  const readyTimeRef = useRef<HTMLInputElement>(null);
  const closeTimeRef = useRef<HTMLInputElement>(null);
  const openTimeRef = useRef<HTMLInputElement>(null);
  const mustDeliverByRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setOrigin(origin ? { label: origin.name, value: origin } : null);
  }, [origin]);

  useEffect(() => {
    setDest(
      destination ? { label: destination.name, value: destination } : null,
    );
  }, [destination]);

  useEffect(() => {
    if (order) {
      getAndSetRoute(origin, destination, setRoute, order);
    } else {
      if (!_origin?.label || !_dest?.label) return;
      getAndSetRoute(_origin, _dest, setRoute, null);

      setTimeout(() => {
        if (readyTimeRef.current && readyTimeRef.current.value !== "") {
          updateDates(readyTimeRef.current.value);
        } else {
          updateDates(new Date().toISOString());
        }
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    `${_origin?.value?.name}${_origin?.value?.address}${_origin?.value?.city}${_origin?.value?.state}-${_dest?.value?.name}${_dest?.value?.address}${_dest?.value?.city}${_dest?.value?.state}`,
  ]);

  if (route?.error) {
    alert(
      "ERROR: " + route?.error?.map((err: any) => err?.title || "").join(", "),
    );
    setOrigin(null);
    setDest(null);
    setRoute(null);
  }

  const updateDates = async (val: any) => {
    updateDestDate(val, setRoute);

    await delay(100);

    if (!val) return;
    if (isNaN(new Date(val).getTime())) return;

    const readyD = new Date(val);
    if (readyD < new Date() && readyTimeRef.current) {
      readyD.setHours(new Date().getHours() + 1);
      readyD.setMinutes(0);
      readyD.setSeconds(0);
      readyTimeRef.current.value = readyD.toISOString();
    }

    if (openTimeRef.current && openTimeRef.current.value === "") {
      openTimeRef.current.value = readyD.toISOString();
    }

    const d = new Date(val);
    d.setHours(d.getHours() + 5);
    d.setMinutes(0);
    d.setSeconds(0);

    if (closeTimeRef.current && closeTimeRef.current.value === "") {
      closeTimeRef.current.value = d.toISOString();
    }

    if (
      route?.summary?.minArrivalTime &&
      mustDeliverByRef.current &&
      mustDeliverByRef.current.value === ""
    ) {
      const minArrivalDate = new Date(route.summary.minArrivalTime);
      minArrivalDate.setHours(minArrivalDate.getHours() + 1);
      minArrivalDate.setMinutes(0);
      minArrivalDate.setSeconds(0);
      // debugger;
      const fourHoursFromNow = new Date();
      fourHoursFromNow.setHours(fourHoursFromNow.getHours() + 4);
      if (minArrivalDate < fourHoursFromNow) {
        minArrivalDate.setHours(minArrivalDate.getHours() + 3);
      }
      mustDeliverByRef.current.value = minArrivalDate.toISOString();
    }
  };

  return (
    <>
      <div
        css={{
          display: "flex",
          flexDirection: "column",
          margin: "15px 10px",
          gap: 20,
        }}
      >
        <AddressSection>
          <AddressTitle>{MapDot} Origin</AddressTitle>

          <DockInfo css={{ gap: 20 }}>
            <DockTimeStyled css={{ fontSize: 14, flex: 4 }}>
              <DockTitle>Location</DockTitle>
              <SelectLocation
                captureCurrentFormData={captureCurrentFormData}
                selected={_origin}
                setSelected={setOrigin}
                css={{ fontSize: 14 }}
                required
                namePrefix="origin"
              />
            </DockTimeStyled>

            <DockInfo css={{ gap: 20, flex: 4 }}>
              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Gate/Dock/Yard</DockTitle>
                <SelectDock
                  disabled={true}
                  defaultValue={origin?.location}
                  namePrefix="origin"
                />
              </DockTimeStyled>

              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Load Type</DockTitle>
                <SelectLoadType
                  defaultValue={origin?.loadType || "LIVE"}
                  namePrefix="origin"
                  required
                />
              </DockTimeStyled>
            </DockInfo>
          </DockInfo>

          <input
            type="hidden"
            name="supplierName"
            value={_origin?.value?.name ?? ""}
          />

          <DockInfo css={{ gap: 20, marginTop: 20 }}>
            <SectionTitle css={{ flex: 1 }}>Pickup Window</SectionTitle>
            <SectionTitle css={{ flex: 1 }}>Origin Contact Info</SectionTitle>
          </DockInfo>

          <DockInfo css={{ gap: 20 }}>
            <DockInfo css={{ gap: 20, flex: 1 }}>
              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Ready Time</DockTitle>
                <DateTimeInput
                  defaultValue={origin?.readyTime}
                  route={route}
                  onChange={updateDates}
                  required
                  name="origin.readyTime"
                  doesNotNeedToBe4HoursFromNow={true}
                  inputRef={readyTimeRef}
                />
              </DockTimeStyled>

              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Close Time</DockTitle>
                <DateTimeInput
                  defaultValue={origin?.closeTime}
                  required
                  name="origin.closeTime"
                  inputRef={closeTimeRef}
                />
              </DockTimeStyled>
            </DockInfo>

            <DockInfo css={{ gap: 20, flex: 1 }}>
              <DockTimeStyled>
                <DockTitle>Contact Name</DockTitle>
                <ValidatorInput
                  defaultValue={origin?.contactName}
                  css={{ fontSize: 14 }}
                  placeholder="Enter Name"
                  name="origin.contactName"
                />
              </DockTimeStyled>

              <DockTimeStyled>
                <DockTitle>Contact Email</DockTitle>
                <ValidatorInput
                  defaultValue={origin?.contactEmail}
                  css={{ fontSize: 14 }}
                  name="origin.contactEmail"
                  placeholder="Enter Email"
                  type="email"
                />
              </DockTimeStyled>

              <DockTimeStyled>
                <DockTitle>Contact Phone</DockTitle>
                <ValidatorInput
                  defaultValue={origin?.contactPhone}
                  css={{ fontSize: 14 }}
                  name="origin.contactPhone"
                  mask="(___) ___-____"
                  maskSlots="_"
                  dataAccept="\d"
                  showFullMaskWhileTyping={true}
                  placeholder="Enter Phone"
                />
              </DockTimeStyled>
            </DockInfo>
          </DockInfo>

          <DockInfo css={{ gap: 20, flex: 1, marginTop: 20 }}>
            <DockTimeStyled>
              <DockTitle>Notes</DockTitle>
              <ValidatorInput
                defaultValue={origin?.notes}
                css={{ fontSize: 14 }}
                name="origin.notes"
              />
            </DockTimeStyled>
          </DockInfo>
        </AddressSection>

        <AddressSection>
          <AddressTitle>{MapDot} Destination</AddressTitle>

          <DockInfo css={{ gap: 20 }}>
            <DockTimeStyled css={{ fontSize: 14, flex: 4 }}>
              <DockTitle>Location</DockTitle>
              <SelectLocation
                captureCurrentFormData={captureCurrentFormData}
                selected={_dest}
                setSelected={setDest}
                css={{ fontSize: 14 }}
                required
                namePrefix="destination"
              />
            </DockTimeStyled>

            <DockInfo css={{ gap: 20, flex: 4 }}>
              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Gate/Dock/Yard</DockTitle>
                <SelectDock
                  disabled={true}
                  defaultValue={destination?.location}
                  namePrefix="destination"
                />
              </DockTimeStyled>

              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Load Type</DockTitle>
                <SelectLoadType
                  defaultValue={destination?.loadType || "LIVE"}
                  namePrefix="destination"
                  required
                />
              </DockTimeStyled>
            </DockInfo>
          </DockInfo>

          <input
            type="hidden"
            name="companyName"
            value={_dest?.value?.name ?? ""}
          />

          <DockInfo css={{ gap: 20, marginTop: 20 }}>
            <SectionTitle css={{ flex: 1 }}>Delivery Window</SectionTitle>
            <SectionTitle css={{ flex: 1 }}>
              Destination Contact Info
            </SectionTitle>
          </DockInfo>

          <DockInfo css={{ gap: 20 }}>
            <DockInfo css={{ gap: 20, flex: 1 }}>
              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Open Time</DockTitle>
                <DateTimeInput
                  defaultValue={destination?.openTime}
                  // required
                  name="destination.openTime"
                  doesNotNeedToBe4HoursFromNow={true}
                  inputRef={openTimeRef}
                />
              </DockTimeStyled>

              <DockTimeStyled css={{ fontSize: 14 }}>
                <DockTitle>Must Deliver By</DockTitle>
                <DateTimeInput
                  defaultValue={destination?.mustDeliverBy}
                  route={route}
                  onChange={(val) => updateOriginDate(val, setRoute)}
                  required
                  name="destination.mustDeliverBy"
                  inputRef={mustDeliverByRef}
                  openTimeRef={openTimeRef}
                />
              </DockTimeStyled>
            </DockInfo>

            <DockInfo css={{ gap: 20, flex: 1 }}>
              <DockTimeStyled>
                <DockTitle>Contact Name</DockTitle>
                <ValidatorInput
                  defaultValue={destination?.contactName}
                  css={{ fontSize: 14 }}
                  placeholder="Enter Name"
                  name="destination.contactName"
                />
              </DockTimeStyled>

              <DockTimeStyled>
                <DockTitle>Contact Email</DockTitle>
                <ValidatorInput
                  defaultValue={destination?.contactEmail}
                  css={{ fontSize: 14 }}
                  name="destination.contactEmail"
                  placeholder="Enter Email"
                  type="email"
                />
              </DockTimeStyled>

              <DockTimeStyled>
                <DockTitle>Contact Phone</DockTitle>
                <ValidatorInput
                  defaultValue={destination?.contactPhone}
                  css={{ fontSize: 14 }}
                  name="destination.contactPhone"
                  mask="(___) ___-____"
                  maskSlots="_"
                  dataAccept="\d"
                  showFullMaskWhileTyping={true}
                  placeholder="Enter Phone"
                />
              </DockTimeStyled>
            </DockInfo>
          </DockInfo>

          <DockInfo css={{ gap: 20, flex: 1, marginTop: 20 }}>
            <DockTimeStyled>
              <DockTitle>Notes</DockTitle>
              <ValidatorInput
                defaultValue={destination?.notes}
                css={{ fontSize: 14 }}
                name="destination.notes"
              />
            </DockTimeStyled>
          </DockInfo>
        </AddressSection>
      </div>
    </>
  );
};
