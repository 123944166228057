/** @jsxImportSource @emotion/react */
import React, { useEffect, useRef, useState } from "react";
import { css, SerializedStyles } from "@emotion/react";

const pillStyles: SerializedStyles = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-radius: 9999px;
  padding: 8px 18px;
  cursor: pointer;
  font-family: Arial, sans-serif;
  font-size: 16px;
  color: #333;
  border: none;
  outline: none;
  width: 100%;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #e0e0e0;
  }
`;

const arrowStyles: SerializedStyles = css`
  border: solid #333;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
`;

const arrowUpStyles: SerializedStyles = css`
  transform: rotate(-135deg);
`;

const dropdownStyles: SerializedStyles = css`
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  overflow-y: auto;
`;

const optionStyles: SerializedStyles = css`
  padding: 12px 20px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

interface DropdownPillProps {
  options: string[];
  value: string;
  onChange: (option: string) => void;
  className?: string;
}

export const DropdownPill: React.FC<DropdownPillProps> = ({
  options,
  value,
  onChange,
  className,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleDropdown = (): void => setIsOpen(!isOpen);

  const handleSelect = (option: string): void => {
    onChange(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div
      css={css`
        position: relative;
        width: 100%;
      `}
      className={className}
      ref={dropdownRef}
    >
      <button css={pillStyles} onClick={toggleDropdown}>
        <span>{value}</span>
        <span css={[arrowStyles, isOpen && arrowUpStyles]} />
      </button>
      {isOpen && (
        <div css={dropdownStyles}>
          {options.map((option) => (
            <div
              key={option}
              css={optionStyles}
              onClick={() => handleSelect(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
