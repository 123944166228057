export enum SourceType {
  SHIPMENT = "ShipmentTelematics",
  CONNECTED_CAR = "EntityTelematics",
  MILESTONE = "MilestoneTelematics",
  TAG = "TagTelematics",
}

export enum EventCode {
  POSITION_UPDATED = "PositionUpdated",
  PLANNED_TRIP_LEG_CREATED = "PlannedTripLegCreated",
  PLANNED_TRIP_LEG_UPDATED = "PlannedTripLegUpdated",
  ACTUAL_TRIP_LEG_CREATED = "ActualTripLegCreated",
  ACTUAL_TRIP_LEG_UPDATED = "ActualTripLegUpdated",
  ACTUAL_TRIP_LEG_COMPLETED = "ActualTripLegCompleted",
  PROGRESS_UPDATED = "ProgressUpdated",
  ENTITY_UPDATED = "EntityUpdated",
  ENTITY_LIFE_CYCLE_UPDATE = "EntityLifeCycleUpdate",
  EXCEPTION_CREATED = "ExceptionCreated",
  EXCEPTION_CLEARED = "ExceptionCleared",
  HOLD_CREATED = "HoldCreated",
  HOLD_CLEARED = "HoldCleared",
  ENTITY_CREATED = "EntityCreated",
  ENTITY_DELETED = "EntityDeleted",
  ACTUAL_TRIP_LEG_DELETED = "ActualTripLegDeleted",
  ACTUAL_TRIP_LEG_ARRIVED_ORIGIN = "ActualTripLegArrivedOrigin",
  ACTUAL_TRIP_LEG_DEPARTED_ORIGIN = "ActualTripLegDepartedOrigin",
  ACTUAL_TRIP_LEG_ARRIVED_DESTINATION = "ActualTripLegArrivedDestination",
  ACTUAL_TRIP_LEG_DEPARTED_DESTINATION = "ActualTripLegDepartedDestination",
  PLANNED_TRIP_LEG_DELETED = "PlannedTripLegDeleted",
  TRIP_PLAN_COMPLETED = "TripPlanCompleted",
  START_TRANSIT_TIMER = "StartTransitTimer",
  END_TRANSIT_TIMER = "EndTransitTimer",
  STATUS_UPDATED = "StatusUpdated",
}

export const CarrierAndDealerTripSummaryEventCodes = [
  EventCode.HOLD_CREATED,
  EventCode.HOLD_CLEARED,
  EventCode.ENTITY_DELETED,
  EventCode.ACTUAL_TRIP_LEG_DELETED,
  EventCode.ACTUAL_TRIP_LEG_ARRIVED_ORIGIN,
  EventCode.ACTUAL_TRIP_LEG_DEPARTED_ORIGIN,
  EventCode.ACTUAL_TRIP_LEG_ARRIVED_DESTINATION,
  EventCode.ACTUAL_TRIP_LEG_DEPARTED_DESTINATION,
  EventCode.PLANNED_TRIP_LEG_DELETED,
  EventCode.START_TRANSIT_TIMER,
  EventCode.END_TRANSIT_TIMER,
  EventCode.STATUS_UPDATED,
];

export const ShipperTripSummaryEventCodes = [
  ...CarrierAndDealerTripSummaryEventCodes,
  EventCode.ENTITY_CREATED,
  EventCode.TRIP_PLAN_COMPLETED,
  EventCode.EXCEPTION_CREATED,
  EventCode.EXCEPTION_CLEARED,
  EventCode.ENTITY_LIFE_CYCLE_UPDATE,
];

export enum ExceptionEventSubcode {
  T = "T",
}

export enum DiagnosticState {
  WARNING = "warning",
  CRITICAL = "critical",
  GOOD = "good",
}

export enum DiagnosticStateColors {
  WARNING = "#dbb800",
  CRITICAL = "#d5473f",
  GOOD = "#46b630",
}
