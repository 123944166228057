/** @jsxImportSource @emotion/react */
import { ValidatorInput } from "pages/rtscao/formLibrary/Input";
import { AsyncPaginate } from "react-select-async-paginate";
import Colors from "styles/colors";
import { ComponentType, useState } from "react";
import { GroupBase, OptionProps } from "react-select";

interface ReferenceNumberQualifier {
  value: string;
  label: string;
}

const refNumQualifierOptions: ReferenceNumberQualifier[] = [
  { value: "TMS", label: "TMS ID / Load Number" },
  { value: "MSI", label: "Shipper's reference" },
  { value: "MPO", label: "Consignee's reference" },
  { value: "SLN", label: "Seal number" },
  { value: "PRO", label: "Carrier PRO" },
  { value: "PUN", label: "Pickup number" },
  { value: "DLN", label: "Delivery number" },
  { value: "GCN", label: "Gate check in number" },
  { value: "AON", label: "Confirmation number" },
  { value: "BOL", label: "Bill of Lading number" },
  { value: "SID", label: "Shipment order number" },
  { value: "PON", label: "Purchase order number" },
];

export function getRefNumLabel(value: string) {
  return refNumQualifierOptions.find((d) => d.value === value)?.label;
}

interface FormatOptionLabelProps {
  value: string;
  label: string;
  customAbbreviation?: string;
}

const formatOptionLabel = ({ label }: FormatOptionLabelProps) => {
  return <div>{label}</div>;
};

const Option: ComponentType<
  OptionProps<FormatOptionLabelProps, false, GroupBase<FormatOptionLabelProps>>
> = ({
  label,
  innerProps,
  data,
  selectProps,
  isDisabled,
  isSelected,
  isFocused,
}) => {
  return (
    <div
      ref={innerProps.ref}
      {...innerProps}
      className={selectProps.className}
      css={{
        fontSize: "12px",
        paddingLeft: "10px",
        paddingBottom: "10px",
        backgroundColor: isDisabled
          ? Colors.background.LIGHT_GRAY
          : isSelected
          ? "#EBF3FF"
          : isFocused
          ? "#DEEBFF"
          : "#fff",
        color: isDisabled
          ? "#aaa"
          : isSelected
          ? "#333"
          : isFocused
          ? "#333"
          : "#6d6d6d",
      }}
    >
      <div>
        <strong>{label}</strong>
      </div>
    </div>
  );
};

interface SelectRefQualifierProps {
  defaultValue?: string;
  name?: string;
  namePrefix?: string;
  required?: boolean;
  disabled?: boolean;
}

export function SelectRefQualifier(props: SelectRefQualifierProps) {
  const defaultState = props.defaultValue
    ? refNumQualifierOptions.find((d) =>
        d.value.startsWith(props.defaultValue as string),
      ) ?? null
    : null;

  const [val, setVal] = useState<ReferenceNumberQualifier | null>(defaultState);

  return (
    <>
      <AsyncPaginate
        defaultOptions
        formatOptionLabel={formatOptionLabel}
        isDisabled={props.disabled}
        styles={{
          control: (styles) => ({
            ...styles,
            backgroundColor: props.disabled ? "#ffffff00" : "white",
            borderRadius: 4,
            border: "2px solid #dedede",
            flex: 1,
            minHeight: 0,
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            padding: "0px 4px",
          }),
          valueContainer: (styles) => ({
            ...styles,
            padding: "0px 8px",
          }),
        }}
        placeholder="Select..."
        loadOptions={(inputStr: string) => ({
          options: refNumQualifierOptions.filter((plant) =>
            plant.label.toLowerCase().includes(inputStr.toLowerCase()),
          ),
        })}
        onChange={setVal}
        value={val}
        components={{
          Option,
        }}
      />
      <ValidatorInput
        name={props.name}
        required={props.required}
        css={{ display: "none" }}
        value={val?.value ?? ""}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          if ((ev.target as any).resetting) {
            setVal(null);
          }
        }}
      />
    </>
  );
}
