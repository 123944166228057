/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";
import { Modal } from "components/molecules/Modal.molecule";
import { useKvState } from "use-kv-state";

export const GlobalSpinnerKV = "GlobalSpinnerKV";
export const GlobalSpinnerMessageKV = "GlobalSpinnerMessageKV";

let setShow;
let setMessage;

const backdropClass = "custom-global-modal-backdrop";

const globalSpinnerStyles = css`
  .custom-global-modal-backdrop {
    z-index: 999999 !important; /* Very high z-index for the GlobalSpinner backdrop */
  }
`;

const modalStyles = css`
  z-index: 1000000 !important; /* Even higher z-index for the modal itself */
`;

export function GlobalSpinner() {
  const [show, _setShow] = useKvState(GlobalSpinnerKV, false);
  const [message, _setMessage] = useKvState(
    GlobalSpinnerMessageKV,
    "Processing, please wait...",
  );

  setShow = _setShow;
  setMessage = _setMessage;

  return (
    <>
      <Global styles={globalSpinnerStyles} />
      <Modal show={show} backdropClassName={backdropClass} css={modalStyles}>
        <Modal.Header
          title="Loading..."
          customCss={{
            backgroundColor: "#282828",
            color: "white",
            borderTopRightRadius: "4px",
            borderTopLeftRadius: "4px",
          }}
          closeVariant="white"
        />
        <Modal.Body>
          <h3>{message}</h3>
        </Modal.Body>
        <Modal.Footer
          style={{
            borderBottomRightRadius: "4px",
            borderBottomLeftRadius: "4px",
          }}
        >
          &nbsp;
        </Modal.Footer>
      </Modal>
    </>
  );
}

let hideTimeout;
let messageQueueInterval;
const messageQueue = [];

export function ShowGlobalSpinner(msg = "Processing, please wait...") {
  if (hideTimeout) {
    clearTimeout(hideTimeout);
    hideTimeout = null;
  }
  if (messageQueueInterval) {
    messageQueue.push(msg);
  } else {
    setMessage(msg);
    setShow(true);
    messageQueueInterval = setInterval(() => {
      if (messageQueue.length > 0) {
        setMessage(messageQueue.shift());
      } else {
        clearInterval(messageQueueInterval);
        messageQueueInterval = null;
      }
    }, 500);
  }
}

export function HideGlobalSpinner() {
  hideTimeout = setTimeout(() => {
    setShow(false);
  }, 500);
}
