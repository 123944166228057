/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Card, formatUSD, GreenButton } from "../Shared";
import { Order } from "pages/rtscao/service/orderServices";
import { R2SProvider, useR2SContext } from "pages/rtscao/service/R2SContext";
import { ValidatorInput } from "pages/rtscao/formLibrary/Input";
import { useState } from "react";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 0px 10px 10px;
`;

interface OpportunityDetailsProps {
  order?: Order;
}

const OpportunityDetails: React.FC<OpportunityDetailsProps> = ({ order }) => {
  const { editMode } = useR2SContext();
  const [selectedQuote, setSelectedQuote] = useState<string | null>(
    order?.selectedQuote ?? "network",
  );

  // if (!opportunity) {
  //   return null;
  // }

  const expeditedCost = order?.enteredCost ?? 0.0;
  const networkCost = order?.uberQuote?.price?.amount / 100;

  const expeditedPerMileCost = expeditedCost / order?.route?.summary?.miles;
  const networkPerMileCost = networkCost / order?.route?.summary?.miles;

  const networkCostSavings = expeditedCost - networkCost;

  const networkCostSavingsPercentage =
    (networkCostSavings / expeditedCost) * 100;

  const networkCostSavingsPercentageFormatted = `${networkCostSavingsPercentage.toFixed(
    2,
  )}%`;

  const networkCostSavingsFormatted = formatUSD(networkCostSavings);

  const expeditedPerMileCostFormatted = formatUSD(expeditedPerMileCost);
  const networkPerMileCostFormatted = formatUSD(networkPerMileCost);

  // const expeditedCostFormatted = formatUSD(expeditedCost);
  const networkCostFormatted = formatUSD(networkCost);

  const requestReceived = new Date(order?.orderDate ?? "").toLocaleString();
  const requestExpires = new Date(
    Number(order?.uberQuote?.expiration_time_utc?.toString() + "000"),
  ).toLocaleString();

  return (
    <Card css={{ margin: 10, padding: 10, flexDirection: "column" }}>
      <Container>
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "3fr 1fr 1fr 1fr 1fr",
            gap: "10px",
            alignItems: "center",
          }}
        >
          <div></div>
          <div css={{ fontWeight: "bold", textAlign: "center" }}>Miles</div>
          <div css={{ fontWeight: "bold", textAlign: "center" }}>
            Cost Per Mile (CPM)
          </div>
          <div css={{ fontWeight: "bold", textAlign: "center" }}>
            Route Savings
          </div>
          <div css={{ fontWeight: "bold", textAlign: "center" }}>
            Total Cost (incl. fuel)
          </div>
          {/*====================================================================================================*/}
          <div
            css={{
              opacity:
                selectedQuote === "expedited" || selectedQuote === null
                  ? 1
                  : 0.25,
            }}
          >
            {/* <div css={{ fontWeight: "bold" }}>
              OEM Deviation Freight (Expedited / Premium)
            </div>
            <div>Request Received {requestReceived}</div>
            <div>Request Expires {requestExpires}</div> */}
          </div>
          <div
            css={{
              textAlign: "center",
              opacity:
                selectedQuote === "expedited" || selectedQuote === null
                  ? 1
                  : 0.25,
            }}
          >
            {/* {order?.route?.summary?.miles} mi */}
          </div>
          <div
            css={{
              textAlign: "center",
              opacity:
                selectedQuote === "expedited" || selectedQuote === null
                  ? 1
                  : 0.25,
            }}
          >
            {/* {expeditedPerMileCostFormatted} */}
          </div>
          <div
            css={{
              textAlign: "center",
              opacity:
                selectedQuote === "expedited" || selectedQuote === null
                  ? 1
                  : 0.25,
            }}
          ></div>
          <div
            css={{
              textAlign: "center",
              opacity:
                selectedQuote === "expedited" || editMode === false ? 1 : 0.25,
            }}
          >
            {editMode ? null : (
              <R2SProvider value={{ editMode: true }}>
                <ValidatorInput
                  defaultValue={order?.enteredCost?.toFixed(2)}
                  type="number"
                  inputProps={{
                    step: "0.01",
                  }}
                  name="enteredCost"
                />
              </R2SProvider>
            )}
          </div>
          {/*====================================================================================================*/}
          <div
            css={{
              opacity:
                selectedQuote === "network" || selectedQuote === null
                  ? 1
                  : 0.25,
            }}
          >
            <div css={{ fontWeight: "bold" }}>OEM Dynamic Normal</div>
            <div>Request Received {requestReceived}</div>
            <div>Request Expires {requestExpires}</div>
          </div>
          <div
            css={{
              textAlign: "center",
              opacity:
                selectedQuote === "network" || selectedQuote === null
                  ? 1
                  : 0.25,
            }}
          >
            {order?.route?.summary?.miles} mi
          </div>
          <div
            css={{
              textAlign: "center",
              opacity:
                selectedQuote === "network" || selectedQuote === null
                  ? 1
                  : 0.25,
            }}
          >
            {networkPerMileCostFormatted}
          </div>
          <div
            css={{
              textAlign: "center",
              color: "green",
              fontWeight: "bold",
              opacity:
                selectedQuote === "network" || selectedQuote === null
                  ? 1
                  : 0.25,
            }}
          >
            {editMode === false && expeditedCost > networkCost ? (
              <>
                {networkCostSavingsFormatted} (
                {networkCostSavingsPercentageFormatted})
              </>
            ) : null}
          </div>
          <div
            css={{
              textAlign: "center",
              opacity: selectedQuote === "network" || editMode ? 1 : 0.25,
            }}
          >
            {editMode ? (
              <GreenButton
                type="button"
                onClick={() => {
                  setSelectedQuote("network");
                }}
                css={{
                  width: "100%",
                  fontWeight: selectedQuote === "network" ? "bold" : "normal",
                  transform:
                    selectedQuote === "network" ? "scale(1.1)" : "none",
                }}
              >
                {networkCostFormatted}
                <br />
                {selectedQuote === "network" ? "Selected" : "Select"}
              </GreenButton>
            ) : (
              <div>{networkCostFormatted}</div>
            )}
          </div>
        </div>
      </Container>
      <ValidatorInput
        required
        customErrorMessages={{
          isRequired: "Please select a quote",
        }}
        css={{ display: "none" }}
        value={selectedQuote ?? ""}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          if ((ev.target as any).resetting) {
            setSelectedQuote(null);
          }
        }}
        name="selectedQuote"
      />
    </Card>
  );
};

export default OpportunityDetails;
