/* eslint-disable curly */
/** @jsxImportSource @emotion/react */
import { ChangeEventHandler, useEffect, useRef } from "react";

import { ValidatorOptions, setupValidatorInput } from "./validator";
import { basedOnLabel } from "./basedOnLabel";
import { css } from "@emotion/react";
import { Icon } from "components/atoms/Icon.atom";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";

interface CustomErrorElm extends HTMLElement {
  errorObj?: Map<string, boolean>;
}

const numberInputStyles = css`
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;

  &:hover::-webkit-inner-spin-button,
  &:hover::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

interface InputProps extends Omit<ValidatorOptions, "inputElm"> {
  // variant?: "primary" | "secondary" | "plain";
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: ChangeEventHandler<HTMLInputElement>;
  readOnly?: boolean;
  disabled?: boolean;
  className?: string;
  type?:
    | "button"
    | "checkbox"
    | "color"
    | "date"
    | "datetime-local"
    | "email"
    | "file"
    | "hidden"
    | "image"
    | "month"
    | "number"
    | "password"
    | "radio"
    | "range"
    | "reset"
    | "search"
    | "submit"
    | "tel"
    | "text"
    | "time"
    | "url"
    | "week"
    | (string & {});
  preText?: string;
  postText?: string;
  label?: string;
  id?: string;
  name?: string;
  step?: number;
  min?: string;
  max?: string;
  autoComplete?: string;
  required?: boolean;
  placeholder?: string;
  defaultValue?: string | number;
  value?: string;
  syncToDisabledInput?: string;
  labelClass?: string;
  labelStyle?: React.CSSProperties;
  errorClass?: string;
  errorStyle?: React.CSSProperties;
  labelProps?: any;
  inputProps?: any;
  errorProps?: any;
  style?: React.CSSProperties;

  errorElm?: CustomErrorElm;
  minLength?: number;
  maxLength?: number;
  exactLength?: number;
  pattern?: RegExp;
  customValidator?: (value: string) => boolean | string;
  mask?: string;
  maskSlots?: string;
  dataAccept?: RegExp | string;
  allowUnacceptedChars?: boolean;
  unmaskInputValueProp?: boolean;
  showFullMaskWhileTyping?: boolean;
  hideDotSlots?: boolean;
  validateUnMaskedValue?: boolean;
  validateOnInput?: boolean;
  isNumeric?: boolean;
  customErrorMessages?: {
    isRequired?: string;
    inputNotLongEnough?: string;
    inputLengthTooLong?: string;
    patternNotValid?: string;
    customNotValid?: string;
  };
}

function ValidatorInput(props: InputProps) {
  const inputRef: any = useRef(null);
  const errorRef: any = useRef(null);

  const setupValidator = () => {
    if (!inputRef.current || !errorRef.current) return;

    inputRef.current.readOnly = props.readOnly || false;
    setupValidatorInput({
      inputElm: inputRef.current,
      errorElm: errorRef.current,
      ...props,
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(setupValidator, [
    props.required,
    props.minLength,
    props.maxLength,
    props.exactLength,
    props.pattern,
    props.mask,
    props.maskSlots,
    props.dataAccept,
    props.allowUnacceptedChars,
    props.unmaskInputValueProp,
    props.showFullMaskWhileTyping,
    props.hideDotSlots,
    props.validateUnMaskedValue,
    props.validateOnInput,
    props.isNumeric,
    // props.customErrorMessages.isRequired,
    // props.customErrorMessages.inputNotLongEnough,
    // props.customErrorMessages.inputLengthTooLong,
    // props.customErrorMessages.patternNotValid,
    // props.customErrorMessages.customNotValid,
  ]);

  useEffect(() => {
    if (!inputRef.current || !errorRef.current) return;
    if (!props.onChange) return;

    const input = inputRef.current;

    input.addEventListener("blur", props.onChange);
    return () => {
      input.removeEventListener("blur", props.onChange);
    };
  }, [props.onChange]);

  return (
    <div css={{ position: "relative" }}>
      {props.label && (
        <label
          htmlFor={props.name}
          className={props.labelClass}
          style={props.labelStyle}
          {...props.labelProps}
        >
          {props.label}
        </label>
      )}
      <input
        defaultValue={
          props.value !== undefined ? undefined : props.defaultValue || ""
        }
        css={[
          {
            display: "block",
            width: "100%",
            padding: "4px",
            margin: "0px",
            fontSize: "1rem",
            border: "2px solid #dedede",
            borderRadius: "4px",
            ...(props.style || {}),
          },
          props.type === "number" && numberInputStyles,
        ]}
        value={props.value}
        disabled={props.disabled}
        onChange={props.onChange || props.readOnly ? () => {} : undefined}
        ref={inputRef}
        id={props.name || basedOnLabel(props.label || "")}
        name={props.name || basedOnLabel(props.label || "")}
        autoComplete={props.autoComplete || basedOnLabel(props.label || "")}
        placeholder={props.placeholder || ""}
        type={props.type || "text"}
        data-sync-to-disabled-input={props.syncToDisabledInput}
        className={props.className}
        {...props.inputProps}
      />
      {props.type === "number" && (
        <div
          css={{
            position: "absolute",
            right: "8px",
            top: "6px",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "10px",
            pointerEvents: "none",
          }}
        >
          <div
            css={{
              flex: 1,
              maxHeight: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              pointerEvents: "auto",
              ":hover": {
                "&>svg": {
                  color: "#707070",
                },
              },
            }}
            onClick={() => {
              if (!inputRef.current) {
                return;
              }
              inputRef.current.value = (
                Number(inputRef.current.value) + 1
              ).toString();
            }}
          >
            <Icon
              src={faChevronUp}
              css={{
                width: 10,
                color: "#dedede",
              }}
            />
          </div>
          <div
            css={{
              flex: 1,
              maxHeight: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              pointerEvents: "auto",
              ":hover": {
                "&>svg": {
                  color: "#707070",
                },
              },
            }}
            onClick={() => {
              if (!inputRef.current) {
                return;
              }
              inputRef.current.value = (
                Number(inputRef.current.value) - 1
              ).toString();
            }}
          >
            <Icon
              src={faChevronDown}
              css={{
                width: 10,
                color: "#dedede",
              }}
            />
          </div>
        </div>
      )}
      <div
        ref={errorRef}
        style={{
          height: 0,
          overflow: "hidden",
          color: "red",
          transition: "all",
          transitionDuration: "300ms",
          ...props.errorStyle,
        }}
        className={props.errorClass}
        {...props.errorProps}
      />
    </div>
  );
}

export { ValidatorInput };
