/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import { CancelButton, GreenButton } from "../Shared";
import { ButtonGroup } from "./OrderCardComponents";

export function CarrierButtonSection() {
  return (
    <ButtonGroup>
      <CancelButton type="button">Decline Offer</CancelButton>
      <GreenButton>Interested</GreenButton>
    </ButtonGroup>
  );
}
