/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import Link from "redux-first-router-link";
import {
  additionalInfoIconMap,
  ShowOpportunitiesButton,
  StatusBar,
  StatusDescription,
  StatusDot,
  StatusLabel,
  StatusItem,
} from "./OrderCardComponents";
import {
  Order,
  StatusItem as StatusItemType,
  StatusType,
} from "pages/rtscao/service/orderServices";

// Type guard function
function isItemWithAdditionalInfo(item: StatusItemType): item is {
  label: "Carrier Awarded";
  status: StatusType;
  additionalInfo?: string;
  additionalInfoIcon?: string;
} {
  return item.label === "Carrier Awarded" && "additionalInfo" in item;
}

export function StatusSection({
  order,
  opportunity,
}: {
  order: Order;
  opportunity?: boolean;
}) {
  return (
    <StatusBar>
      {order.statusItems
        .filter((item) =>
          [
            "Carrier Awarded",
            "Order Confirmed",
            "Order Canceled",
            "Order Declined",
            // "Ready to Ship",
            // "Shipment Assigned",
            // "Delivered",
          ].includes(item.label),
        )
        .map((item, index) => (
          <StatusItem key={index}>
            {index === 2 && opportunity ? (
              <>
                <StatusDot status="opportunity" />
                <Link to={`/rtscao/opportunities?order=${order.id}`}>
                  <ShowOpportunitiesButton type="button">
                    Show Opportunities
                  </ShowOpportunitiesButton>
                </Link>
              </>
            ) : (
              <>
                <StatusDot status={item.status} />
                <StatusLabel status={item.status}>{item.label}</StatusLabel>
                {isItemWithAdditionalInfo(item) && item.additionalInfo && (
                  <StatusDescription status={item.status}>
                    {item.additionalInfoIcon &&
                      additionalInfoIconMap[item.additionalInfoIcon]}
                    {item.additionalInfo}
                  </StatusDescription>
                )}
              </>
            )}
          </StatusItem>
        ))}
    </StatusBar>
  );
}
