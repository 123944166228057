/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Order } from "pages/dynamic-normal/service/orderServices";
import { formatISOToCustom } from "../Shared";

const cardStyles = css`
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.5);
  font-family: Arial, sans-serif;
  max-width: 350px;
  position: absolute;
  z-index: 10;
  top: 20px;
  left: 20px;
`;

const titleStyles = css`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
`;

const sectionStyles = css`
  margin-bottom: 15px;
`;

const labelStyles = css`
  color: #888;
  font-size: 14px;
  margin-bottom: 5px;
`;

const valueStyles = css`
  font-size: 16px;
  font-weight: bold;
`;

const addressStyles = css`
  font-size: 14px;
  margin-top: 5px;
`;

interface ShipmentSummaryCardProps {
  order?: Order | null;
}

export const ShipmentSummaryCard: React.FC<ShipmentSummaryCardProps> = ({
  order,
}) => {
  if (!order) {
    return null;
  }
  return (
    <div css={cardStyles}>
      <h2 css={titleStyles}>Shipment Summary</h2>

      <div css={sectionStyles}>
        <div css={labelStyles}>Origin</div>
        <div css={valueStyles}>{order?.origin?.name}</div>
        <div css={addressStyles}>{order?.origin?.address}</div>
        <div css={addressStyles}>{order?.origin?.city}</div>
      </div>

      <div css={sectionStyles}>
        <div css={labelStyles}>Destination</div>
        <div css={valueStyles}>{order?.destination?.name}</div>
        <div css={addressStyles}>{order?.destination?.address}</div>
        <div css={addressStyles}>{order?.destination?.city}</div>
      </div>

      <div css={sectionStyles}>
        <div css={labelStyles}>Deliver By</div>
        <div css={valueStyles}>
          {formatISOToCustom(order?.destination?.lateDate)}
        </div>
      </div>

      {/* <div css={dividerStyles} />

      <div css={gridStyles}>
        <div>
          <div css={labelStyles}>Space Requirement</div>
          <div css={valueStyles}>
            {order?.shipmentDetails?.trailerSpaceRequirement}
          </div>
        </div>
        <div>
          <div css={labelStyles}>Gross Weight</div>
          <div css={valueStyles}>{order?.shipmentDetails?.grossWt}</div>
        </div>
        <div>
          <div css={labelStyles}>Ship Unit</div>
          <div css={valueStyles}>{order?.shipmentDetails?.shipUnit}</div>
        </div>
        <div>
          <div css={labelStyles}>Ship Unit Qty</div>
          <div css={valueStyles}>{order?.shipmentDetails?.shipUnitQty}</div>
        </div>
      </div>

      <div css={sectionStyles}>
        <div css={labelStyles}>Part</div>
        <div css={valueStyles}>{order?.partNo}</div>
      </div> */}
    </div>
  );
};

export default ShipmentSummaryCard;
