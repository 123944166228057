/** @jsxImportSource @emotion/react */
import { HideGlobalSpinner, ShowGlobalSpinner } from "components/GlobalSpinner";
import { Order, orderService } from "pages/rtscao/service/orderServices";
import { useState } from "react";
import { ButtonGroup } from "./OrderCardComponents";
import { YesNoConfirmModal } from "../CreateOrder/YesNoConfirmModal";
import { CancelButton, GreenButton } from "../Shared";
import type { QueryClient } from "@tanstack/react-query";

interface SupplierButtonProps {
  order: Order;
  queryClient: QueryClient;
  editMode: boolean;
  setEditMode: (editMode: boolean) => void;
  variant: string;
}

/* eslint-disable react/prop-types */
export function SupplierButtonSection({
  order,
  queryClient,
  editMode,
  setEditMode,
  variant,
}: SupplierButtonProps) {
  const [confirmShow, setConfirmShow] = useState(false);

  async function handleOrderCardDecline() {
    ShowGlobalSpinner("Updating Order...");
    const newOrder = { ...order };
    newOrder.statusItems[0].status = "declined";
    newOrder.statusItems[0].label = "Order Declined";
    await orderService.updateOrder(newOrder); // persist to db
    queryClient.invalidateQueries({ queryKey: ["orders"] });
    setConfirmShow(false);
    HideGlobalSpinner();
  }

  const supplierModifyingExistingOrder = variant === "supplier" && editMode;

  const orderNotAcceptedYet =
    order.statusItems[0].status === "inactive" ||
    supplierModifyingExistingOrder;
  const supplierAccepted = !orderNotAcceptedYet;
  const carrierAccepted = order.carrierAccepted;
  // const carrierAndSupplierAccepted = carrierAccepted && orderNotAcceptedYet;

  return (
    <ButtonGroup>
      <YesNoConfirmModal
        show={confirmShow}
        setShow={setConfirmShow}
        confirmAction={handleOrderCardDecline}
      />
      {supplierModifyingExistingOrder ? (
        <CancelButton type="button" onClick={() => setEditMode(false)}>
          Cancel
        </CancelButton>
      ) : (
        <CancelButton type="button" onClick={() => setConfirmShow(true)}>
          Decline Order
        </CancelButton>
      )}
      {orderNotAcceptedYet && (
        <GreenButton>
          {supplierModifyingExistingOrder ? "Update" : "Confirm Order"}
        </GreenButton>
      )}
      {!carrierAccepted && supplierAccepted && (
        <GreenButton type="button" onClick={() => setEditMode(true)}>
          Modify Order
        </GreenButton>
      )}
    </ButtonGroup>
  );
}
