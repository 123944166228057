/* eslint-disable react/prop-types */
/** @jsxImportSource @emotion/react */
import { HideGlobalSpinner, ShowGlobalSpinner } from "components/GlobalSpinner";
import { ButtonGroup } from "./OrderCardComponents";
import {
  Order,
  orderService,
} from "pages/dynamic-normal/service/orderServices";
import { useState } from "react";
import { YesNoConfirmModal } from "../CreateOrder/YesNoConfirmModal";
import { CancelButton, GreenButton } from "../Shared";
import type { QueryClient } from "@tanstack/react-query";

interface OemButtonProps {
  order: Order;
  queryClient: QueryClient;
  setEditMode: (editMode: boolean) => void;
  editMode: boolean;
}

export function OemButtonSection({
  order,
  queryClient,
  setEditMode,
  editMode,
}: OemButtonProps) {
  const showModify = order.statusItems[0].status === "inactive";
  const [confirmShow, setConfirmShow] = useState(false);
  const confirmAction = async () => {
    ShowGlobalSpinner("Updating Order...");
    const newOrder = { ...order };
    newOrder.statusItems[0].status = "declined";
    newOrder.statusItems[0].label = "Order Canceled";
    await orderService.updateOrder(newOrder); // persist to db
    queryClient.invalidateQueries({ queryKey: ["orders"] });
    setConfirmShow(false);
    HideGlobalSpinner();
  };
  return (
    <ButtonGroup>
      <YesNoConfirmModal
        show={confirmShow}
        setShow={setConfirmShow}
        confirmAction={confirmAction}
      />{" "}
      {editMode ? (
        <>
          <CancelButton type="button" onClick={() => setEditMode(false)}>
            Cancel
          </CancelButton>
          <GreenButton>Update</GreenButton>
        </>
      ) : (
        <>
          <CancelButton type="button" onClick={() => setConfirmShow(true)}>
            Cancel Order
          </CancelButton>
          {showModify && (
            <GreenButton type="button" onClick={() => setEditMode(true)}>
              Modify
            </GreenButton>
          )}
        </>
      )}
    </ButtonGroup>
  );
}
