function basedOnLabel(label: string) {
  return label
    .toLowerCase()
    .replaceAll(/[^\w\d\s]/g, "") // remove all non word, non digit, and non whitespace characters
    .replaceAll(/\s{2,}/g, " ") // remove duplicate spaces
    .trim() // remove leading and trailing whitespaces
    .replaceAll(" ", "-"); // replaces spaces with dashes
}

export { basedOnLabel };
