/** @jsxImportSource @emotion/react */
import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { faCommentAlt } from "@fortawesome/pro-solid-svg-icons";
import { useEtaTranslations } from "shared/hooks/useEtaTranslations";

import Colors from "styles/colors";
import { Text, FontSize } from "components/atoms/Text.atom";
import { Icon } from "components/atoms/Icon.atom";
import { DateTime } from "components/atoms/DateTime.atom";
import { DateTimeRange } from "components/atoms/DateTimeRange.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { translateRailStatusCode } from "utils/translation-utils";
import { MadChicletCSS as MadChiclet } from "components/chiclets";
import {
  localizedTimeFormatter,
  localizedDateFormatter,
} from "utils/date-time";
import { parse } from "utils/json-utils";
import { WatchToggle } from "shared/components/molecules/WatchToggle.molecule";
import { getCurrentPositionDetails } from "shared/utils/entity.utils";
import { VinMilestoneCell } from "components/organisms/base-table/Cell/VinMilestoneCell";
import { ShowMoreList } from "components/molecules/ShowMoreList.molecule";
import FinVehicleEntityDetailsState from "pages/finishedvehicle/redux/FinVehicleEntityDetailsState";
import { SourceType } from "api/consts";
import { translateExceptionName } from "pages/finishedvehicle/utils/exceptions.utils";
import { ShippabilityStatusText } from "shared/components/molecules/ShippabilityStatusText.molecule";

// Helpers
const findReference = (references, key) => {
  if (references === undefined) {
    return null;
  }
  const ref = references.find((obj) => obj.qualifier === key);
  return ref ? ref.value : null;
};

const VinCell = (props) => {
  let {
    id,
    description,
    references,
    commentsCount,
    lifeCycleState,
    isShippable,
    displayShippabilityStatus,
  } = props.value;
  const { t } = useTranslation("fv-vin-search");
  const orderType = findReference(references, "OrderType") || "";
  const orderNumber = findReference(references, "OrderNumber") || "";
  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <div
        css={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        {commentsCount && commentsCount > 0 ? (
          <Tooltip
            placement="top"
            tooltipChildren={
              <Text>{t("fv-vin-search:This VIN contains comments")}</Text>
            }
          >
            <Icon
              src={faCommentAlt}
              color={Colors.comments.unreadCommentIcon}
              style={{ marginRight: 8 }}
            />
          </Tooltip>
        ) : null}
        <Text bold>{lifeCycleState === "Prebuilt" ? "TBD" : id}</Text>
      </div>
      {description && description !== "None" ? (
        <Text>{description}</Text>
      ) : null}
      <div css={{ display: "flex", flexDirection: "row" }}>
        {orderType ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              {t("fv-vin-search:Order Type")} -
            </Text>
            <Text>{orderType}</Text>
          </React.Fragment>
        ) : null}
      </div>
      <div css={{ display: "flex", flexDirection: "row" }}>
        {orderNumber ? (
          <React.Fragment>
            <Text bold style={{ marginRight: 3 }}>
              {t("fv-vin-search:Order #")} -
            </Text>
            <Text>{orderNumber}</Text>
          </React.Fragment>
        ) : null}
      </div>
      {displayShippabilityStatus ? (
        <ShippabilityStatusText
          isShippable={isShippable}
        ></ShippabilityStatusText>
      ) : null}
    </div>
  );
};

VinCell.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,
    description: PropTypes.string,
    references: PropTypes.array,
    commentsCount: PropTypes.number,
    lifeCycleState: PropTypes.string,
    isShippable: PropTypes.bool,
    displayShippabilityStatus: PropTypes.bool,
  }),
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */
const LastUpdateCell = (props) => {
  const { mode, lastPosition, lastProgress, currentLocation, routeId, t } =
    props.value;

  let isOnShipment = false;

  if (lastPosition?.currentPositionType !== t("At Location")) {
    isOnShipment = true;
  }

  let city = null;
  let state = null;

  if (lastPosition) {
    city = lastPosition?.city?.trim();
    state = lastPosition?.state?.trim();
  }

  let cityAndState = null;
  if (city || state) {
    if (city && !state) {
      cityAndState = city;
    } else if (state && !city) {
      cityAndState = state;
    } else {
      cityAndState = `${city}, ${state}`;
    }
  }

  let modeType = mode;
  if (lastPosition?.currentPositionType === t("On Rail")) {
    modeType = "Rail";
  } else if (lastPosition?.currentPositionType === t("On Road")) {
    modeType = "Truck";
  } else if (lastPosition?.currentPositionType === t("On Water")) {
    modeType = "Ocean";
  }

  let source = lastPosition?.sourceType;
  if (source === SourceType.SHIPMENT) {
    source = t("Shipment");
  } else if (source === SourceType.CONNECTED_CAR || source === SourceType.TAG) {
    source = t("Connected Car");
  } else if (source === SourceType.MILESTONE) {
    source = t("Milestone");
  }

  const routeIdElement = (
    <div>
      <Text bold>{t("fv-vin-search:Route ID")} - </Text>
      <Text>{routeId ?? t("fv-vin-search:N/A")}</Text>
    </div>
  );

  if (
    isOnShipment &&
    (lastProgress?.eventTs || cityAndState || lastPosition?.sourceType)
  ) {
    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <div className="d-flex align-items-center flex-wrap">
          {modeType ? (
            <MadChiclet
              style={{ marginRight: "0.5em" }}
              shipmentMode={modeType}
              stopMode={modeType}
              activeException={null}
              height={24}
              width={24}
            />
          ) : null}
          <Text>{lastPosition?.currentPositionType ?? t("On Shipment")}</Text>
        </div>

        {/* Departing Location */}
        {source === t("Shipment") || source === t("Milestone") ? (
          <React.Fragment>
            {lastPosition?.currentPositionName ? (
              <Text className="ms-1">{lastPosition?.currentPositionName}</Text>
            ) : null}
          </React.Fragment>
        ) : null}

        {/* City/State */}
        {cityAndState ? <Text className="ms-1">{cityAndState}</Text> : null}

        {/* Last CLM Status (Rail) */}
        {mode === "Rail" &&
          modeType === "Rail" &&
          lastProgress?.position?.eventCode && (
            <div className="d-flex flex-wrap">
              <Text>
                {translateRailStatusCode(lastProgress.position.eventCode, t)}
              </Text>
            </div>
          )}

        {/* Source */}
        {source ? (
          <React.Fragment>
            <Text>
              <Text bold>{t("Source")}</Text> - {source}
            </Text>
          </React.Fragment>
        ) : null}

        {/*Departed Time */}
        {lastPosition?.datetime ? (
          <div className="d-flex flex-wrap">
            <Text className="ms-1">
              {`${localizedTimeFormatter(
                lastPosition?.datetime,
              )} ${localizedDateFormatter(lastPosition?.datetime)}`}
            </Text>
          </div>
        ) : null}
        {routeIdElement}
      </div>
    );
  } else if (
    !isOnShipment &&
    (lastPosition?.datetime || currentLocation?.name || cityAndState)
  ) {
    return (
      <div css={{ display: "flex", flexDirection: "column" }}>
        <Text>{t("fv-vin-search:At Location")}</Text>
        {/* Location Name */}
        {lastPosition?.currentPositionName ? (
          <Text className="ms-1">{lastPosition?.currentPositionName}</Text>
        ) : null}
        {/* City/State */}
        {cityAndState ? <Text className="ms-1">{cityAndState}</Text> : null}
        {/* Source */}
        {source ? (
          <React.Fragment>
            <Text>
              <Text bold>{t("Source")}</Text> - {source}
            </Text>
          </React.Fragment>
        ) : null}
        {/* Arrival Time */}
        {lastPosition?.datetime ? (
          <div className="d-flex flex-wrap">
            {/* <Text>Departed At:</Text> */}
            <Text className="ms-1">
              {`${localizedTimeFormatter(
                lastPosition?.datetime,
              )} ${localizedDateFormatter(lastPosition?.datetime)}`}
            </Text>
          </div>
        ) : null}
        {routeIdElement}
      </div>
    );
  }

  return null;
};

LastUpdateCell.propTypes = {
  value: PropTypes.shape({
    lastPosition: PropTypes.object,
    lastProgress: PropTypes.object,
    state: PropTypes.string,
    mode: PropTypes.string,
    currentLocation: PropTypes.object,
    routeId: PropTypes.string,
    t: PropTypes.func,
  }).isRequired,
};

const ExceptionCell = (props) => {
  const { exceptions, categories, showItssData, showSpotBuyData } = props.value;
  const { t } = useTranslation(["fv-vin-search", "exceptions"]);

  const usualExceptions = exceptions?.filter(
    (exception) =>
      exception.reasonCode !== "ITSS" && exception.reasonCode !== "SB",
  );

  let itssIds = [];
  let spotBuyCodes = [];
  if (categories?.length > 0) {
    itssIds = categories
      .filter((category) => category.group === "In Transit Stop Ship")
      .map((item) => item.category);
    spotBuyCodes = categories
      .filter((category) => category.group === "Spot Buy")
      .map((item) => item.category);
  }

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      {_.uniqBy(usualExceptions, "name")?.map((exception, index) => (
        <Text bold key={index}>
          {translateExceptionName(exception.name, t)}
        </Text>
      ))}
      {/* ITSS IDs */}
      {showItssData && itssIds.length ? (
        <React.Fragment>
          <Text bold>{t("fv-vin-search:In Transit Stop Ship")}</Text>
          <div className="text-nowrap ms-2">
            <ShowMoreList
              title={t("fv-vin-search:In Transit Stop Ship IDs")}
              list={itssIds}
              visibleItemCount={4}
            />
          </div>
        </React.Fragment>
      ) : null}
      {/* Spot Buy Auth Codes */}
      {showSpotBuyData && spotBuyCodes.length ? (
        <React.Fragment>
          <Text bold>{t("fv-vin-search:Spot Buy Authorization Number")}</Text>
          <div className="text-nowrap ms-2">
            <ShowMoreList
              title={t("fv-vin-search:Spot Buy Authorization Number")}
              list={spotBuyCodes}
              visibleItemCount={4}
            />
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

ExceptionCell.propTypes = {
  value: PropTypes.shape({
    exceptions: PropTypes.array,
    categories: PropTypes.array,
    showItssData: PropTypes.bool,
    showSpotBuyData: PropTypes.bool,
  }),
};

/**
 *
 * @param props
 * @return {*}
 * @constructor
 */

const LocationCell = (props) => {
  const {
    location,
    type,
    eta,
    tripPlanCompleteTs,
    manualEtaRangeStart,
    manualEtaRangeEnd,
    showScheduleWindow,
    completedDate = null,
  } = props.value;
  const { t } = useTranslation("fv-vin-search");
  const { getEtaTranslation, isEtaName } = useEtaTranslations();
  if (_.isNil(location)) {
    return <div />;
  }

  const scheduledWindow = parse(location.scheduledArrivalWindow);
  const isPickUpOr = type === "origin" ? t("Pickup") : t("Delivery");

  // eslint-disable-next-line no-undef
  const actualTsBool = Boolean(location.arrived) || Boolean(tripPlanCompleteTs);
  const timeToDisplay = location.arrived
    ? location.arrived
    : tripPlanCompleteTs
    ? tripPlanCompleteTs
    : showScheduleWindow
    ? scheduledWindow
    : null;
  const timeToDisplayLabel = actualTsBool ? t("Actual") : t("Scheduled");

  let etaElement = <DateTime dateTime={eta} plain localize />;
  if (isEtaName(eta)) {
    etaElement = (
      <Text>
        {getEtaTranslation(eta, manualEtaRangeStart, manualEtaRangeEnd)}
      </Text>
    );
  }

  return (
    <div css={{ display: "flex", flexDirection: "column" }}>
      <Text bold>{location.code}</Text>
      <Text>{location.name}</Text>
      {location.city && location.state ? (
        <Text>{`${location.city}, ${location.state}`}</Text>
      ) : null}
      {timeToDisplay ? (
        <React.Fragment>
          <Text bold underline>
            {timeToDisplayLabel} {isPickUpOr}
          </Text>
          {!actualTsBool ? (
            <DateTimeRange
              plain
              localize
              from={timeToDisplay[0]}
              to={timeToDisplay[1]}
            />
          ) : (
            <DateTime plain localize dateTime={timeToDisplay} />
          )}
        </React.Fragment>
      ) : null}
      {eta ? (
        <div css={{ display: "flex", flexDirection: "row" }}>
          <Text bold>{t("fv-vin-search:ETA")}:</Text>
          <Text css={{ marginLeft: 3 }}>{etaElement}</Text>
        </div>
      ) : null}
      {completedDate ? (
        <React.Fragment>
          <Text bold underline>
            {t("Complete Date")}
          </Text>
          <DateTime plain localize dateTime={completedDate} />
        </React.Fragment>
      ) : null}
    </div>
  );
};

LocationCell.propTypes = {
  value: PropTypes.object.isRequired,
};

export const columns = (
  t,
  solutionId,
  showItssData,
  showSpotBuyData,
  showScheduleWindow,
) => {
  const results = [
    {
      Header: t("fv-vin-search:Watch"),
      accessor: "watch",
      width: 50,
      disableSortBy: true,
      disableResizing: true,
      centerAligned: true,
      Cell: (cellInfo) => {
        const dispatch = useDispatch();
        const vin = cellInfo.row.original.id;
        // API returns boolean in a string
        const watched = cellInfo.value === "true";
        return (
          <WatchToggle
            key={vin}
            checked={watched ?? false}
            onChange={(newWatchValue) => {
              dispatch(
                FinVehicleEntityDetailsState.actionCreators.setWatchEntity(
                  solutionId,
                  vin,
                  newWatchValue,
                ),
              );
            }}
            iconSize={FontSize.size24}
            color={Colors.nav.NAVY}
            checkedColor={Colors.highlight.YELLOW}
          />
        );
      },
    },
    {
      Header: t("fv-vin-search:VIN"),
      id: "vin",
      Cell: VinCell,
      accessor: (d) => {
        return {
          id: d.id,
          description: d.description,
          references: d.references,
          commentsCount: d.commentsCount,
          lifeCycleState: d.lifeCycleState,
          isShippable: d.isShippable,
          displayShippabilityStatus:
            d.configurations?.displayShippabilityStatus,
        };
      },
      minWidth: 180,
    },
    {
      Header: t("fv-vin-search:Last Milestone"),
      id: "lastMilestone",
      minWidth: 125,
      Cell: (cellInfo) => {
        return (
          <VinMilestoneCell
            lastMilestone={cellInfo.row.original.lastStatusUpdate}
          />
        );
      },
    },
    {
      Header: t("fv-vin-search:Last Update"),
      id: "activeShipment",
      minWidth: 125,
      Cell: LastUpdateCell,
      accessor: (d) => {
        return {
          mode: d.activeTransportMode,
          lastPosition: getCurrentPositionDetails(d, t),
          lastProgress: d.lastProgressUpdate,
          currentLocation: d.locationData?.current,
          routeId: d.routeNumber,
          t: t,
        };
      },
    },
    {
      Header: t("fv-vin-search:Origin Details"),
      id: "origin",
      minWidth: 135,
      Cell: LocationCell,
      accessor: (d) => {
        return {
          location: d.ultimateOrigin,
          type: "origin",
          showScheduleWindow: showScheduleWindow,
        };
      },
    },
    {
      Header: t("fv-vin-search:Destination Details"),
      id: "destination",
      minWidth: 135,
      Cell: LocationCell,
      accessor: (d) => {
        let eta = null;
        // If the VIN is not delivered, show the ETA.
        if (d?.lifeCycleState !== "Delivered") {
          // `lastEntityProgressUpdate.destinationEta` is the source of the ETA for VINView if an etaDateRange is not avaliable.
          // The API will handle which ETA to show (FV, HERE, Smart, etc) in this field.
          eta = d?.lastEntityProgressUpdate?.destinationEta;
        }

        return {
          location: d.ultimateDestination,
          type: "destination",
          eta: eta,
          t: t,
          tripPlanCompleteTs: d?.tripPlanCompleteTs,
          manualEtaRangeStart: d?.manualEtaRangeStart,
          manualEtaRangeEnd: d?.manualEtaRangeEnd,
          showScheduleWindow: showScheduleWindow,
          completedDate: d?.completedDate,
        };
      },
    },
    {
      Header: t("fv-vin-search:Active Exceptions"),
      id: "shipmentExeptions",
      disableSortBy: true,
      minWidth: 125,
      Cell: ExceptionCell,
      accessor: (d) => {
        return {
          references: d.references,
          exceptions: d.exceptions,
          categories: d.categories,
          showItssData: showItssData,
          showSpotBuyData: showSpotBuyData,
          t: t,
        };
      },
    },
  ];

  return results;
};
