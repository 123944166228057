import { Order } from "./orderServices";

export interface UberQuote {
  uber_quote_uuid: string;
  price: {
    amount: number;
    currency_code: string;
  };
  expiration_time_utc: string;
  status: string;
  notes: string;
  uber_quote_id: string;
}

export interface OpportunityV2 {
  opportunity_type: string;
  rank: number;
  origin_actual_arrival: string | null;
  origin_latest_arrival: string;
  destination_latest_arrival: string;
  current_distance_to_pickup: number | null;
  destination_proximity: number;
  origin_proximity: number;
  shipment_origin_lat: number;
  shipment_origin_lon: number;
  shipment_dest_lat: number;
  shipment_dest_lon: number;
  created_by_org_id: number;
  carrier_organization_id: number;
  carrierName: string;
  shipment_id: string | null;
  truck_current_lat: number | null;
  truck_current_lon: number | null;
  asset_id: string | null;
  active_status: string;
  origin_city: string;
  origin_state: string;
  destination_city: string;
  destination_state: string;
  costOverride?: number | null;
}

export async function findOpportunitiesV2({
  og_lat,
  og_long,
  dest_lat,
  dest_long,
  radius,
  order,
}: {
  og_lat: number;
  og_long: number;
  dest_lat: number;
  dest_long: number;
  radius: number;
  order?: Order;
}): Promise<{ rows: OpportunityV2[] | null; uberQuote: UberQuote | null }> {
  if (
    !og_lat ||
    !og_long ||
    !dest_lat ||
    !dest_long ||
    !radius ||
    !localStorage.getItem("access_token")
  ) {
    return { rows: null, uberQuote: null };
  }

  let headersList = {
    Accept: "*/*",
    Authorization: "Bearer " + localStorage.getItem("access_token"),
    "Content-Type": "application/json",
  };

  let bodyContent = JSON.stringify({
    og_lat,
    og_long,
    dest_lat,
    dest_long,
    radius,
  });

  let response = await fetch(
    "https://data-d2.freightverify.com/dynamic-normal/opportunities/v2",
    {
      method: "POST",
      body: bodyContent,
      headers: headersList,
    },
  );

  let data: { rows: OpportunityV2[]; uberQuote: UberQuote } =
    await response.json();
  // return data;
  return { rows: data.rows, uberQuote: data.uberQuote };
}
