/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { SelectAsync } from "components/atoms/SelectAsync.atom";

interface SortKey {
  value: string;
  label: string;
}

export const sortKeys: SortKey[] = [
  { value: "everything", label: "Everything" },
  { value: "id", label: "Order Number" },
  { value: "companyName", label: "Company Name" },
  { value: "productInfo", label: "Product Information" },
  { value: "quantity", label: "Quantity" },
  { value: "orderDate", label: "Order Date" },
  { value: "origin", label: "Origin" },
  { value: "destination", label: "Destination" },
];

export const sortKeyHashMap: Record<string, SortKey> = {};
sortKeys.forEach((sortKey) => {
  sortKeyHashMap[sortKey.value] = sortKey;
});

const NoSeparator = (): null => null;

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  border-radius: 4px;
  border: 2px solid #dedede;
  padding-left: 16px;
`;

const SearchIcon = styled.span`
  margin-right: 12px;
  color: #757575;
`;

const SearchInput = styled.input`
  flex-grow: 1;
  border: none;
  outline: none;
  font-size: 16px;
  &::placeholder {
    color: #929292;
    font-style: italic;
  }
`;

interface SearchBarProps {
  onKeyUp: React.KeyboardEventHandler<HTMLInputElement>;
  filterType: string;
  filterValue: string;
  setFilterValue: (value: string) => void;
  setFilterType: (value: string) => void;
}

export const SearchBar: React.FC<SearchBarProps> = (props) => {
  return (
    <SearchContainer>
      <SearchIcon>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
      </SearchIcon>
      <SearchInput
        onKeyUp={props.onKeyUp}
        type="text"
        placeholder={`Search ${
          sortKeyHashMap[props.filterType]?.label || "Everything"
        }`}
        value={props.filterValue}
        onChange={(ev) => props.setFilterValue(ev.target.value)}
      />
      <SelectAsync
        isClearable={false}
        isSearchable={false}
        loadOptions={async (inputStr: string) => ({
          options: sortKeys.filter((key) =>
            key.label.toLowerCase().includes(inputStr.toLowerCase()),
          ),
          hasMore: false,
        })}
        onChange={(selected: SortKey | null) =>
          props.setFilterType(selected?.value || "")
        }
        components={{ IndicatorSeparator: NoSeparator }}
        styles={{
          control: (styles: any) => ({
            ...styles,
            backgroundColor: "#F4F4F4",
            border: 0,
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
            borderLeft: "2px solid #dedede",
            width: 150,
          }),
          input: (styles: any) => ({ ...styles, display: "none" }),
          placeholder: (styles: any) => ({ ...styles }),
          singleValue: (styles: any) => ({ ...styles }),
        }}
        value={sortKeyHashMap[props.filterType] || sortKeyHashMap["everything"]}
      />
    </SearchContainer>
  );
};
