import { createContext, useContext } from "react";

export const R2SContext = createContext<any>({});

export const R2SProvider = ({
  children,
  value,
}: {
  children: React.ReactNode;
  value: any;
}) => {
  return <R2SContext.Provider value={value}>{children}</R2SContext.Provider>;
};

export const useR2SContext = () => {
  return useContext(R2SContext);
};
