/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "react-loader";

import {
  useSetDescriptionOnMount,
  useSetTitleOnMount,
} from "components/hooks/useSetTitle";

import { useTrackWithMixpanelOnce } from "trackers/mixpanel";
import Colors from "styles/colors";

import { Button } from "components/atoms/Button.atom";
import { Tabs } from "components/molecules/Tabs.molecule";
import { ResponsiveSection } from "styles/container-elements";
import { AddLocationModal } from "./components/AddLocation.modal";

import { faHouse } from "@fortawesome/pro-regular-svg-icons";
import { Alert, AlertVariant } from "components/atoms/Alert.atom";
import { Icon } from "components/atoms/Icon.atom";
import { Text } from "components/atoms/Text.atom";

import { InventoryLocationPanel } from "./components/InventoryLocationPanel";
import {
  selectedTab,
  tabsListStyle,
} from "./components/InventoryNavigationTabs";
import { faAdd } from "@fortawesome/pro-solid-svg-icons";

const InventoryViewDashboard = ({
  watchedLocations,
  allWatchedLocations,
  redirectToInsightsView,
  fetchWatchedLocations,
  fetchAllWatchedLocations,
  isWatchedLocationsLoading,
  saveWatchedLocations,
}) => {
  const { t } = useTranslation("inventory-view");
  const [showAddLocationModal, setShowAddLocationModal] = useState(false);

  useSetTitleOnMount(t("inventory-view:InventoryView"));

  useSetDescriptionOnMount(
    t(
      "inventory-view:All InventoryView Locations, their current capacity and forecasted capacity",
    ),
  );

  useTrackWithMixpanelOnce("Viewed Page: InventoryView / Dashboard");

  const addLocationModalHideHandler = () => {
    setShowAddLocationModal(false);
  };

  useEffect(() => {
    fetchWatchedLocations();
    fetchAllWatchedLocations();
  }, [fetchWatchedLocations, fetchAllWatchedLocations]);

  return (
    <ResponsiveSection
      style={{
        justifyContent: "flex-start",
        padding: "1em 2em 2em",
        background: Colors.background.LIGHT_GRAY,
        overflowY: "auto",
        height: "100%",
      }}
    >
      <Loader loaded={!isWatchedLocationsLoading}>
        <Tabs>
          <Tabs.TabList css={{ ...tabsListStyle }}>
            <Tabs.Tab css={{ ...selectedTab }}>
              <Icon src={faHouse} />{" "}
              <Text
                css={{
                  fontSize: "inherit",
                  textUnderlineOffset: "2px",
                  textDecoration: "underline",
                }}
              >
                {t("inventory-view:Home")}
              </Text>
            </Tabs.Tab>
            <div css={{ marginLeft: "auto" }}>
              <Button
                variant="success"
                onClick={() => {
                  setShowAddLocationModal(true);
                }}
              >
                <Icon src={faAdd} /> {t("inventory-view:Add Location")}
              </Button>
            </div>
            <AddLocationModal
              show={showAddLocationModal}
              onHide={() => {
                addLocationModalHideHandler();
              }}
              watchedLocations={allWatchedLocations}
              saveWatchedLocations={saveWatchedLocations}
              onSave={() => {
                fetchWatchedLocations();
                fetchAllWatchedLocations();
              }}
            ></AddLocationModal>
          </Tabs.TabList>

          <Tabs.TabPanel
            style={{ border: 0, background: "none", boxShadow: "none" }}
          >
            {watchedLocations.length > 0 ? (
              <div
                style={{
                  display: "grid",
                  gridColumnGap: "20px",
                  gridRowGap: "20px",
                  justifyItems: "stretch",
                  gridTemplateColumns: "repeat(auto-fill, minmax(400px, 1fr))",
                }}
              >
                {watchedLocations.map((location) => {
                  return (
                    <InventoryLocationPanel
                      location={location}
                      key={location?.locationId}
                      redirectToInsightsView={redirectToInsightsView}
                    />
                  );
                })}
              </div>
            ) : (
              <Alert variant={AlertVariant.Primary} show>
                <p>
                  {t("inventory-view:Please select your locations to proceed.")}
                </p>
              </Alert>
            )}
          </Tabs.TabPanel>
        </Tabs>
      </Loader>
    </ResponsiveSection>
  );
};

InventoryViewDashboard.propTypes = {
  watchedLocations: PropTypes.array,
  allWatchedLocations: PropTypes.array,
  redirectToInsightsView: PropTypes.func,
  redirectToDashboardView: PropTypes.func,
  fetchWatchedLocations: PropTypes.func,
  fetchAllWatchedLocations: PropTypes.func,
  isWatchedLocationsLoading: PropTypes.bool,
  isAllWatchedLocationsLoading: PropTypes.bool,
  saveWatchedLocations: PropTypes.func,
};

export default InventoryViewDashboard;
