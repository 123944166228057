/** @jsxImportSource @emotion/react */
import { ValidatorInput } from "pages/rtscao/formLibrary/Input";
import { AsyncPaginate } from "react-select-async-paginate";
import Colors from "styles/colors";
import { ThreeWords } from "../Shared";
import { ComponentType, useState } from "react";
import { GroupBase, OptionProps } from "react-select";
import { useR2SContext } from "pages/rtscao/service/R2SContext";

interface DockOption {
  value: string;
  label: string;
}

const docks: DockOption[] = [
  {
    value: "Dock 1A - ///Pump.carrot.ready",
    label: "Dock 1A - ///Pump.carrot.ready",
  },
  {
    value: "Dock 1B - ///Pump.carrot.ready",
    label: "Dock 1B - ///Pump.carrot.ready",
  },
  {
    value: "Dock 2A - ///Pump.carrot.ready",
    label: "Dock 2A - ///Pump.carrot.ready",
  },
  {
    value: "Dock 2B - ///Pump.carrot.ready",
    label: "Dock 2B - ///Pump.carrot.ready",
  },
  {
    value: "Dock 3A - ///Pump.carrot.ready",
    label: "Dock 3A - ///Pump.carrot.ready",
  },
  {
    value: "Dock 3B - ///Pump.carrot.ready",
    label: "Dock 3B - ///Pump.carrot.ready",
  },
  {
    value: "Dock 4A - ///Pump.carrot.ready",
    label: "Dock 4A - ///Pump.carrot.ready",
  },
  {
    value: "Dock 4B - ///Pump.carrot.ready",
    label: "Dock 4B - ///Pump.carrot.ready",
  },
  {
    value: "Dock 5A - ///Pump.carrot.ready",
    label: "Dock 5A - ///Pump.carrot.ready",
  },
  {
    value: "Dock 5B - ///Pump.carrot.ready",
    label: "Dock 5B - ///Pump.carrot.ready",
  },
  {
    value: "Dock 6A - ///Pump.carrot.ready",
    label: "Dock 6A - ///Pump.carrot.ready",
  },
  {
    value: "Dock 6B - ///Pump.carrot.ready",
    label: "Dock 6B - ///Pump.carrot.ready",
  },
];

interface FormatOptionLabelProps {
  value: string;
  label: string;
  customAbbreviation?: string;
}

const formatOptionLabel = ({
  value,
  label,
  customAbbreviation,
}: FormatOptionLabelProps) => {
  const labelSplit = label.split("///");
  return (
    <div>
      {labelSplit[0]} <ThreeWords>{labelSplit[1]}</ThreeWords>
    </div>
  );
};

const Option: ComponentType<
  OptionProps<FormatOptionLabelProps, false, GroupBase<FormatOptionLabelProps>>
> = ({
  label,
  innerProps,
  data,
  selectProps,
  isDisabled,
  isSelected,
  isFocused,
}) => {
  const labelSplit = label.split("///");
  return (
    <div
      ref={innerProps.ref}
      {...innerProps}
      className={selectProps.className}
      css={{
        fontSize: "12px",
        paddingLeft: "10px",
        paddingBottom: "10px",
        backgroundColor: isDisabled
          ? Colors.background.LIGHT_GRAY
          : isSelected
          ? "#EBF3FF"
          : isFocused
          ? "#DEEBFF"
          : "#fff",
        color: isDisabled
          ? "#aaa"
          : isSelected
          ? "#333"
          : isFocused
          ? "#333"
          : "#6d6d6d",
      }}
    >
      <div>
        <strong>
          {labelSplit[0]} <ThreeWords>{labelSplit[1]}</ThreeWords>
        </strong>
      </div>
    </div>
  );
};

interface SelectDockProps {
  defaultValue?: string;
  name?: string;
  namePrefix?: string;
  required?: boolean;
  disabled?: boolean;
}

export function SelectDock(props: SelectDockProps) {
  const { editMode } = useR2SContext();
  const defaultState = props.defaultValue
    ? docks.find((d) => d.value.startsWith(props.defaultValue as string)) ??
      null
    : null;

  const [val, setVal] = useState<DockOption | null>(defaultState);

  const labelSplit = val?.label?.split(" - ///") || [];

  if (editMode === false) {
    return (
      <div>
        {labelSplit[0]} <ThreeWords>{labelSplit[1]}</ThreeWords>
      </div>
    );
  }

  return (
    <>
      <AsyncPaginate
        defaultOptions
        formatOptionLabel={formatOptionLabel}
        isDisabled={props.disabled}
        styles={{
          control: (styles) => ({
            ...styles,
            opacity: props.disabled ? 0.25 : 1,
            backgroundColor: props.disabled ? "#ffffff00" : "white",
            borderRadius: 4,
            border: "2px solid #dedede",
            flex: 1,
            minHeight: 0,
          }),
          dropdownIndicator: (styles) => ({
            ...styles,
            padding: "0px 4px",
          }),
          valueContainer: (styles) => ({
            ...styles,
            padding: "0px 8px",
          }),
        }}
        placeholder="Select Dock"
        loadOptions={(inputStr: string) => ({
          options: docks.filter((plant) =>
            plant.label.toLowerCase().includes(inputStr.toLowerCase()),
          ),
        })}
        onChange={setVal}
        value={val}
        components={{
          Option,
        }}
      />
      <ValidatorInput
        name={
          props.name ?? props.namePrefix ? `${props.namePrefix}.label` : "dock"
        }
        required={props.required}
        css={{ display: "none" }}
        value={val?.label ?? ""}
        onChange={(ev: React.ChangeEvent<HTMLInputElement>) => {
          if ((ev.target as any).resetting) {
            setVal(null);
          }
        }}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.location`
            : "location"
        }
        value={labelSplit[0] ?? ""}
      />

      <input
        type="hidden"
        name={
          props.name ?? props.namePrefix
            ? `${props.namePrefix}.threeWords`
            : "threeWords"
        }
        value={labelSplit[1] ?? ""}
      />
    </>
  );
}
