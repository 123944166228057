import moment from "moment";
import Colors from "styles/colors";

const todays_date = moment();

export const inventoryData = {
  meta: {
    totalPages: 1,
    currentPage: 0,
    totalCount: 5,
  },
  capacity: 1000,
  totalInventory: 998,
  daysOnSite: {
    "10PlusDaysOnSite": 40,
  },
  data: [
    {
      locationId: 288501,
      name: moment(todays_date).subtract(6, "days").format("YYYY-MM-DD"),
      shippable: 780,
      nonShippable: 20,
      forecasted: 0,
    },
    {
      locationId: 288501,
      name: moment(todays_date).subtract(5, "days").format("YYYY-MM-DD"),
      shippable: 780,
      nonShippable: 20,
      forecasted: 0,
    },
    {
      locationId: 288501,
      name: moment(todays_date).subtract(4, "days").format("YYYY-MM-DD"),
      shippable: 868,
      nonShippable: 2,
      forecasted: 0,
    },
    {
      locationId: 288501,
      name: moment(todays_date).subtract(3, "days").format("YYYY-MM-DD"),
      shippable: 692,
      nonShippable: 20,
      forecasted: 0,
    },
    {
      locationId: 288501,
      name: moment(todays_date).subtract(2, "days").format("YYYY-MM-DD"),
      shippable: 800,
      nonShippable: 20,
      forecasted: 0,
    },
    {
      locationId: 288501,
      name: moment(todays_date).subtract(1, "days").format("YYYY-MM-DD"),
      shippable: 1010,
      nonShippable: 20,
      forecasted: 0,
    },
    {
      locationId: 288501,
      name: moment(todays_date).format("YYYY-MM-DD"),
      shippable: 780,
      nonShippable: 40,
      forecasted: 20,
    },
  ],
};

export const pipelineData = {
  meta: {
    totalPages: 1,
    currentPage: 0,
    totalCount: 5,
  },
  data: [
    {
      locationId: 288501,
      name: "At Plant",
      shippable: 2134,
      nonShippable: 4900,
    },
    {
      name: "At Upfitter",
      shippable: 1460,
      nonShippable: 5900,
    },
    {
      name: "At Port",
      shippable: 5430,
      nonShippable: 2700,
    },
    {
      name: "At Distribution",
      shippable: 3389,
      nonShippable: 1200,
    },
    {
      name: "On Road",
      shippable: 6812,
      nonShippable: 1497,
    },
    {
      name: "On Rail",
      shippable: 5746,
      nonShippable: 2345,
    },
    {
      name: "On Water",
      shippable: 4538,
      nonShippable: 1790,
    },
  ],
};

export const arrivalsData = {
  meta: {
    totalPages: 1,
    currentPage: 0,
    totalCount: 5,
  },
  capacity: 100,
  maxCapacity: 115,
  data: [
    {
      locationId: 288501,
      name: moment(todays_date).subtract(7, "days").format("YYYY-MM-DD"),
      reported: 74,
      forecasted: 0,
    },
    {
      locationId: 288501,
      name: moment(todays_date).subtract(6, "days").format("YYYY-MM-DD"),
      reported: 146,
      forecasted: 0,
    },
    {
      locationId: 288501,
      name: moment(todays_date).subtract(5, "days").format("YYYY-MM-DD"),
      reported: 135,
      forecasted: 0,
    },
    {
      locationId: 288501,
      name: moment(todays_date).subtract(4, "days").format("YYYY-MM-DD"),
      reported: 33,
      forecasted: 0,
    },
    {
      locationId: 288501,
      name: moment(todays_date).subtract(3, "days").format("YYYY-MM-DD"),
      reported: 68,
      forecasted: 0,
    },
    {
      locationId: 288501,
      name: moment(todays_date).subtract(2, "days").format("YYYY-MM-DD"),
      reported: 57,
      forecasted: 0,
    },
    {
      locationId: 288501,
      name: moment(todays_date).subtract(1, "days").format("YYYY-MM-DD"),
      reported: 45,
      forecasted: 0,
    },
    {
      locationId: 288501,
      name: moment(todays_date).format("YYYY-MM-DD"),
      reported: 90,
      forecasted: 30,
    },
    {
      locationId: 288501,
      name: moment(todays_date).add(1, "days").format("YYYY-MM-DD"),
      reported: 0,
      forecasted: 90,
    },
    {
      locationId: 288501,
      name: moment(todays_date).add(2, "days").format("YYYY-MM-DD"),
      reported: 0,
      forecasted: 175,
    },
    {
      locationId: 288501,
      name: moment(todays_date).add(3, "days").format("YYYY-MM-DD"),
      reported: 0,
      forecasted: 55,
    },
    {
      locationId: 288501,
      name: moment(todays_date).add(4, "days").format("YYYY-MM-DD"),
      reported: 0,
      forecasted: 30,
    },
    {
      locationId: 288501,
      name: moment(todays_date).add(5, "days").format("YYYY-MM-DD"),
      reported: 0,
      forecasted: 32,
    },
    {
      locationId: 288501,
      name: moment(todays_date).add(6, "days").format("YYYY-MM-DD"),
      reported: 0,
      forecasted: 103,
    },
    {
      locationId: 288501,
      name: moment(todays_date).add(7, "days").format("YYYY-MM-DD"),
      reported: 0,
      forecasted: 103,
    },
  ],
};

export const departuresData = {
  capacity: 100,
  maxCapacity: 115,
  data: [
    { x: 0, y: 87, projectedTrend: null },
    { x: 1, y: 87, projectedTrend: null },
    { x: 2, y: 89, projectedTrend: null },
    { x: 3, y: 85, projectedTrend: null },
    { x: 4, y: 116, projectedTrend: null },
    { x: 5, y: 94, projectedTrend: null },
    { x: 6, y: 86, projectedTrend: null },
    { x: 7, y: 108, projectedTrend: null },
    { x: 8, y: null, projectedTrend: 92 },
    { x: 9, y: null, projectedTrend: 92 },
    { x: 10, y: null, projectedTrend: 92 },
    { x: 11, y: null, projectedTrend: 92 },
    { x: 12, y: null, projectedTrend: 92 },
    { x: 13, y: null, projectedTrend: 92 },
    { x: 14, y: null, projectedTrend: 92 },
  ],
};

export const pieChartData1 = [
  {
    type: "10_plus_days",
    vins_associated: 107,
    name: "10+ Days",
    fill: Colors.inventoryView.RED,
  },
  {
    type: "6_plus_days",
    vins_associated: 106,
    name: "6-10 Days",
    fill: Colors.inventoryView.ORANGE,
  },
  {
    type: "2_5_days",
    vins_associated: 102,
    name: "2-5 Days",
    fill: Colors.inventoryView.ORANGE_YELLOW,
  },
  {
    type: "0_2_days",
    vins_associated: 202,
    name: "0-2 Days",
    fill: Colors.inventoryView.GREEN,
  },
];

export const pieChartData2 = [
  {
    type: "missed_first_eta",
    vins_associated: 107,
    name: "Missed First ETA",
    fill: Colors.inventoryView.RED,
  },
  {
    type: "0_48",
    vins_associated: 106,
    name: "0-48 Hours Out",
    fill: Colors.inventoryView.ORANGE,
  },
  {
    type: "48_96",
    vins_associated: 102,
    name: "48-96 Hours Out",
    fill: Colors.inventoryView.FLUORESCENT_GREEN,
  },
  {
    type: "96_plus",
    vins_associated: 202,
    name: "96+ Hours Out",
    fill: Colors.inventoryView.GREEN,
  },
];

export const pieChartData3 = [
  {
    type: "fleet",
    vins_associated: 107,
    name: "Fleet",
    fill: Colors.graphs.CHART_BLUE,
  },
  {
    type: "stock",
    vins_associated: 106,
    name: "Stock",
    fill: Colors.text.HIT_GRAY,
  },
  {
    type: "customer",
    vins_associated: 102,
    name: "Customer",
    fill: Colors.graphs.CHART_GRAY,
  },
];

export const pieChartData4 = [
  {
    type: "carrier1",
    vins_associated: 107,
    name: "Carrier 1",
    fill: Colors.graphs.CHART_BLUE,
  },
  {
    type: "carrier2",
    vins_associated: 106,
    name: "Carrier 2",
    fill: Colors.text.HIT_GRAY,
  },
  {
    type: "carrier3",
    vins_associated: 102,
    name: "Carrier 3",
    fill: Colors.graphs.CHART_GRAY,
  },
];
