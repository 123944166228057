/** @jsxImportSource @emotion/react */
import { ReactNode } from "react";
import {
  AsyncPaginate as AsyncPaginateLib,
  LoadOptions,
  WithAsyncPaginateType,
} from "react-select-async-paginate";
import {
  SelectComponentsConfig,
  OptionProps,
  GroupBase,
  OptionsOrGroups,
  OnChangeValue,
  ActionMeta,
} from "react-select";

import { faSquare } from "@fortawesome/pro-regular-svg-icons";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";
import Colors from "styles/colors";

import { Text } from "components/atoms/Text.atom";
import { Icon, FontSize } from "components/atoms/Icon.atom";
import { useTranslation } from "react-i18next";

const shouldLoadMoreDefault = (
  scrollHeight: number,
  clientHeight: number,
  scrollTop: number,
) => {
  const scrollBottom = scrollHeight - clientHeight;
  const currentScrollPixelsFromBottom = scrollBottom - scrollTop;
  const thresholdOfPixelsFromBottomToLoadMore = 500;

  return currentScrollPixelsFromBottom < thresholdOfPixelsFromBottomToLoadMore;
};

/**
 * A wrapper for AsyncPaginate that allows for the display of checkboxes in the dropdown
 */
function SelectAsync<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: SelectAsyncProps<Option, IsMulti, Group>) {
  let {
    loadOptions,
    shouldLoadMore,
    onChange,
    libProps,
    isMulti,
    showCheckbox,
    closeMenuOnSelect,
    hideSelectedOptions,
    value,
    styles,
    placeholder,
    isDisabled,
    isClearable,
    isSearchable,
    cacheUniqs,
    debounceTimeout,
    loadingMessage,
    noOptionsMessage,
    checkboxSelectedColor = Colors.filters.CHECK_SELECTED_COLOR,
    components = {},
    options,
  } = props;
  const { t } = useTranslation("components");

  loadingMessage ??= () => t("components: Loading...");
  noOptionsMessage ??= () => t("components: No Options");

  if (showCheckbox && !isMulti) {
    throw new Error(
      "showCheckbox can only be used when isMulti is set to true",
    );
  }

  if (showCheckbox && isMulti) {
    components.Option = (props) => {
      return CheckboxOption({
        ...props,
        checkboxSelectedColor: checkboxSelectedColor,
      });
    };
  }

  return (
    <AsyncPaginateLib
      {...libProps}
      menuPortalTarget={document.body}
      isMulti={isMulti}
      components={components}
      loadOptions={loadOptions as LoadOptions<Option, any, any>}
      shouldLoadMore={shouldLoadMore ?? shouldLoadMoreDefault}
      closeMenuOnSelect={closeMenuOnSelect}
      hideSelectedOptions={hideSelectedOptions}
      value={value}
      options={options}
      onChange={onChange}
      styles={styles}
      placeholder={placeholder}
      isDisabled={isDisabled}
      isClearable={isClearable}
      isSearchable={isSearchable}
      cacheUniqs={cacheUniqs}
      debounceTimeout={debounceTimeout}
      loadingMessage={loadingMessage}
      noOptionsMessage={noOptionsMessage}
    />
  );
}

const CheckboxOption = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: OptionProps<Option, IsMulti, Group> & {
    checkboxSelectedColor: string;
  },
) => {
  const {
    innerProps,
    label,
    isSelected = false,
    isFocused,
    checkboxSelectedColor,
  } = props;

  return (
    <div
      {...innerProps}
      css={{
        fontSize: "0.8em",
        padding: "0.3em 0.5em;",
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-start",
        cursor: "pointer",
        backgroundColor: isSelected
          ? Colors.border.ALICE_BLUE
          : isFocused
          ? Colors.border.BLUE
          : Colors.tabs.BACKGROUND_SELECTED,
      }}
    >
      <Icon
        css={{ padding: "0.2em 0.3em" }}
        aria-hidden
        src={isSelected ? faCheckSquare : faSquare}
        size={FontSize.size16}
        data-qa="checkbox-icon"
        color={
          isSelected
            ? checkboxSelectedColor
            : Colors.filters.CHECK_DEFAULT_COLOR
        }
      />
      <Text as="label" style={{ cursor: "pointer", marginBottom: 0 }}>
        {label}
      </Text>
    </div>
  );
};

type SelectAsyncProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> = {
  /**
   * Function that returns a promise, which has the set of options to be used once the promise resolves.
   */
  loadOptions?: LoadOptions<Option, Group, any>;
  /**
   * Function that determines whether to load more options
   */
  shouldLoadMore?: (
    scrollHeight: number,
    clientHeight: number,
    scrollTop: number,
  ) => boolean;
  /**
   * Function that handle onChange events
   * For more information please see: https://github.com/JedWatson/react-select/blob/06e34882638d1526b9f5a1238bb567a3e9460ce5/packages/react-select/src/Select.tsx#L229
   */
  onChange?: (
    newValue: OnChangeValue<Option, IsMulti>,
    actionMeta: ActionMeta<Option>,
  ) => void;
  /**
   * Text to display when there are no options
   */
  loadingMessage?: () => ReactNode;
  /**
   * Text to display when there are no options
   */
  noOptionsMessage?: () => ReactNode;
  /**
   * Whether the select should allow multiple selections
   */
  isMulti?: IsMulti;
  /**
   * Whether to show checkboxes in the dropdown, only works when isMulti is true
   */
  showCheckbox?: boolean;
  /**
   * Whether to close the menu when an option is selected
   */
  closeMenuOnSelect?: boolean;
  /**
   * Whether to hide selected options from the dropdown
   */
  hideSelectedOptions?: boolean;
  /**
   * Props to pass to the AsyncPaginate component
   */
  libProps?: WithAsyncPaginateType;
  /**
   * The selected value(s)
   */
  value?: IsMulti extends true ? readonly Option[] | null : Option | null;
  /**
   * Custom styles to apply to the select
   */
  styles?: Object;
  /**
   * Placeholder text to display when no value is selected
   */
  placeholder?: string;
  /**
   * Whether the select is disabled
   */
  isDisabled?: boolean;
  /**
   * Whether the select is clearable
   */
  isClearable?: boolean;
  /**
   * Whether to enable search functionality
   */
  isSearchable?: boolean;
  /**
   * Works as 2nd argument of useEffect hook. When one of items changed, AsyncPaginate cleans all cached options.
   */
  cacheUniqs?: Array<Object>;
  /**
   * Debounce timeout for loadOptions calls. 0 by default.
   */
  debounceTimeout?: number;
  /**
   * This complex object includes all the compositional components that are used in react-select.
   * If you wish to overwrite a component, pass in an object with the appropriate namespace.
   * For more information please see: https://github.com/JedWatson/react-select/blob/06e34882638d1526b9f5a1238bb567a3e9460ce5/packages/react-select/src/Select.tsx#L136
   */
  components?: SelectComponentsConfig<Option, IsMulti, Group>;
  /**
   * The color to use for the checkbox when selected
   */
  checkboxSelectedColor?: string;
  /**
   * A static set of options to display in the dropdown
   * For more information please see: https://github.com/JedWatson/react-select/blob/06e34882638d1526b9f5a1238bb567a3e9460ce5/packages/react-select/src/Select.tsx#L252
   */
  options?: OptionsOrGroups<Option, Group>;
};

export { SelectAsync };
