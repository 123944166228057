/** @jsxImportSource @emotion/react */
import { faAngleLeft, faAngleRight } from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";
import _ from "lodash";
import React, { useState } from "react";
import {
  Tabs as ReactTabs,
  TabList as ReactTabList,
  Tab as ReactTab,
  TabPanel as ReactTabPanel,
} from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Colors from "styles/colors";
import { Icon } from "components/atoms/Icon.atom";
import { Button } from "components/atoms/Button.atom";

// Object that holds the classnames to help with overriding styles elsewhere
const classNamePrefix = ".react-tabs__";
const selectedPostfix = "--selected";
const NO_STATIC_TABS = 0;

export const NO_PAGINATION = -1;

export const TabsClassNames = {
  TabList: `${classNamePrefix}tab-list`,
  Tab: `${classNamePrefix}tab`,
  TabSelected: `${classNamePrefix}tab${selectedPostfix}`,
  TabPanel: `${classNamePrefix}tab-panel`,
  TabPanelSelected: `${classNamePrefix}tab-panel${selectedPostfix}`,
};

export const TabHeight = {
  SHORT: "SHORT",
  DEFAULT: "DEFAULT",
  TALL: "TALL",
};

const getHeight = (tabHeight) => {
  if (tabHeight === TabHeight.TALL) {
    return 52;
  }
  if (tabHeight === TabHeight.SHORT) {
    return 40;
  }
  return 48;
};

export const Tabs = ({
  children,
  selectedIndex = null,
  onSelect = _.noop,
  fullWidthTabs = false,
  tabHeight = TabHeight.DEFAULT,
  borderColor = Colors.tabs.BORDER,
  selectedBackground = Colors.tabs.BACKGROUND_SELECTED,
  selectedTextColor = Colors.text.DARK_GRAY,
  unselectedBackground = Colors.tabs.BACKGROUND,
  unselectedBackgroundHover = Colors.tabs.BACKGROUND_HOVER,
  unselectedTextColor = Colors.text.DARK_GRAY,
  className = undefined,
  style = {},
  ...restProps
}) => {
  return (
    <ReactTabs
      selectedIndex={selectedIndex}
      onSelect={onSelect}
      className={className}
      style={style}
      css={{
        borderColor: borderColor,
        [TabsClassNames.TabList]: {
          display: "flex",
          justifyContent: fullWidthTabs ? "center" : "start",
          alignItems: "flex-end",
          marginBottom: 0,
          paddingTop: "0.5em",
          borderBottomStyle: "none",
          borderColor: "inherit",
        },
        [TabsClassNames.Tab]: {
          flex: fullWidthTabs ? "1 1 0" : null,

          display: "flex",
          justifyContent: "center",
          alignItems: "center",

          textAlign: "center",

          // Border for all tabs.
          border: "none",
          borderColor: "inherit",
          borderBottomWidth: "1px",
          borderBottomStyle: "solid",
          borderTopRightRadius: 5,
          borderTopLeftRadius: 5,

          background: unselectedBackground,
          color: unselectedTextColor,

          // Unset margin (has a marginBottom from bootstrap maybe?).
          margin: "0em 0.5em 0em 0em",

          // fix last tab to not have right-margin
          ":last-of-type": { marginRight: 0 },

          height: `${getHeight(tabHeight)}px`,

          // change background color on hover
          ":hover": { backgroundColor: unselectedBackgroundHover },

          // no boxShadow on tabs by default
          boxShadow: "none",
        },
        [TabsClassNames.TabSelected]: {
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "inherit",
          borderBottomStyle: "none",
          borderTopRightRadius: 5,
          borderTopLeftRadius: 5,

          background: selectedBackground,
          color: selectedTextColor,

          // This makes the tabs a bit taller when selected
          height: `${getHeight(tabHeight) + 6}px`,

          // selected tab has no cursor on mouseover
          cursor: "default",

          // keep background color consistent on hover
          ":hover": { backgroundColor: selectedBackground },
        },
        [TabsClassNames.TabPanelSelected]: {
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "inherit",

          background: selectedBackground,
          color: selectedTextColor,

          boxShadow: "0px 15px 12px 0px rgba(0,0,0,0.05)",
        },
      }}
      {...restProps}
    >
      {children}
    </ReactTabs>
  );
};

Tabs.propTypes = {
  /**
   * The content of Tabs.
   *
   * Use `Tabs.TabList` and `Tabs.TabPanel`.
   */
  children: PropTypes.node,
  /**
   * Specify a selected index to control the selected tab. Be sure to also implement onSelect
   * to update the parent's state.
   */
  selectedIndex: PropTypes.number,
  /**
   * A callback for when the selected tab changes.
   */
  onSelect: PropTypes.func,
  /**
   * Set if the tabs should be full width.
   *
   * This will display the tabs to take up the width of it's container
   */
  fullWidthTabs: PropTypes.bool,
  /**
   * Set if the tabs should have no border.
   */
  tabHeight: PropTypes.string,
  /**
   * Sets the border color of the selected tab and tab panel.
   */
  borderColor: PropTypes.string,
  /**
   * Sets the background of the selected tab and tab panel.
   */
  selectedBackground: PropTypes.string,
  /**
   * Sets the text color for the selected tab and panel.
   */
  selectedTextColor: PropTypes.string,
  /**
   * Sets the background of the unselected tabs.
   */
  unselectedBackground: PropTypes.string,
  /**
   * Changes background color when hovering over unselected tabs.
   */
  unselectedBackgroundHover: PropTypes.string,
  /**
   * Sets the text color for the unselected tab.
   */
  unselectedTextColor: PropTypes.string,
  /**
   * A class name to put on the root element of `Tabs`.
   */
  className: PropTypes.string,
  /**
   * An object containing CSS element styles for the root element of `Tabs`.
   */
  style: PropTypes.object,
};

const TabList = ({
  children,
  maxTabs = NO_PAGINATION,
  staticTabs = NO_STATIC_TABS,
  postTabContent = undefined,
  ...restProps
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const pageIncrement = maxTabs - staticTabs;

  const paginateRight = () => {
    setCurrentTab(currentTab + pageIncrement);
    setCurrentPage(currentPage + 1);
  };

  const paginateLeft = () => {
    setCurrentTab(currentTab - pageIncrement);
    setCurrentPage(currentPage - 1);
  };

  const canPaginate =
    children.length > pageIncrement && maxTabs !== NO_PAGINATION;

  const lowerBoundTab = Math.floor(currentTab / pageIncrement) * pageIncrement;
  const upperBoundTab = Math.min(
    lowerBoundTab + pageIncrement,
    children.length,
  );

  const getStaticTabs = () => {
    return children.slice(0, staticTabs);
  };

  const getDisplayedTabs = () => {
    const nonStaticTabs = children.slice(staticTabs);
    if (!canPaginate) {
      return nonStaticTabs;
    }
    return nonStaticTabs.map((component, index) => {
      if (index >= lowerBoundTab && index < upperBoundTab) {
        return component; // display current page's tabs
      }
      // hide tabs using CSS to trick react-tabs
      return React.cloneElement(component, { style: { display: "none" } });
    });
  };

  const hasRightPage = canPaginate && upperBoundTab < getDisplayedTabs().length;
  const hasLeftPage = canPaginate && lowerBoundTab > 0;

  const paginationCss = {
    color: Colors.text.DARK_GRAY,
    ":hover": { color: Colors.text.GRAY },
    margin: "auto 0",
    paddingBottom: 0,
  };
  return (
    <ReactTabList {...restProps}>
      {getStaticTabs()}
      {hasLeftPage ? (
        <Button onClick={paginateLeft} variant="link" css={paginationCss}>
          <Icon src={faAngleLeft} />
        </Button>
      ) : null}
      {getDisplayedTabs()}
      {hasRightPage ? (
        <Button onClick={paginateRight} variant="link" css={paginationCss}>
          <Icon src={faAngleRight} />
        </Button>
      ) : null}
      {postTabContent ? (
        <div
          css={{
            marginLeft: "auto",
            height: "100%",
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            gap: "1em",
          }}
        >
          {postTabContent}
        </div>
      ) : null}
    </ReactTabList>
  );
};
TabList.propTypes = {
  /**
   * The content of `Tabs.TabList`.
   *
   * Use `Tabs.Tab`.
   */
  children: PropTypes.any,
  /**
   * Set this to limit to how many tabs can be displayed at once.
   * If there are more tabs than the limit, it will paginate them.
   */
  maxTabs: PropTypes.number,
  /**
   * Set this to force this number of tabs to be static. These static
   * tabs will not move when paginating.
   */
  staticTabs: PropTypes.number,
  /**
   * This is content that will display after the tabs.
   */
  postTabContent: PropTypes.any | undefined,
};
TabList.tabsRole = "TabList";
Tabs.TabList = TabList;

const Tab = ({ children, ...restProps }) => {
  return <ReactTab {...restProps}>{children}</ReactTab>;
};
Tab.propTypes = {
  /**
   * The content of the tab.
   */
  children: PropTypes.any,
};
Tab.tabsRole = "Tab";
Tabs.Tab = Tab;

const TabPanel = ({ children, ...restProps }) => {
  return <ReactTabPanel {...restProps}>{children}</ReactTabPanel>;
};
TabPanel.propTypes = {
  /**
   * The content of the tab panel.
   */
  children: PropTypes.any,
};
TabPanel.tabsRole = "TabPanel";
Tabs.TabPanel = TabPanel;
