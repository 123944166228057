/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { Card, Container, Title } from "../Shared";
import TrailerSpaceVisualization from "./TrailerSpaceVis";
import { FontSize, Icon, IconType } from "components/atoms/Icon.atom";
import { faCubes } from "@fortawesome/pro-solid-svg-icons";
import { ShipmentDetails as ShipmentDetailsInterface } from "pages/dynamic-normal/service/orderServices";

const Section = styled.div`
  border-radius: 4px;
  flex: 1;
`;

const SectionTitle = styled.h2`
  font-size: 14px;
  font-weight: bold;
  color: #333;
`;

const InfoGrid = styled.div`
  display: flex;
  flex-direction: row;
`;

const InfoItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-size: 14px;
  opacity: 40%;
  font-weight: bold;
`;

const Value = styled.span`
  font-size: 14px;
  font-weight: bold;
  color: #333;
`;

const ShipmentIcon = (
  <Icon
    style={{ color: "#0077be" }}
    type={IconType.FontAwesome}
    src={faCubes}
    size={FontSize.size16}
  />
);

interface ShipmentDetailsProps {
  shipmentDetails?: ShipmentDetailsInterface;
}

const ShipmentDetails: React.FC<ShipmentDetailsProps> = ({
  shipmentDetails,
}) => {
  if (!shipmentDetails) {
    return null;
  }

  return (
    <Card css={{ margin: 10, padding: 10, flexDirection: "column" }}>
      <Title>{ShipmentIcon} Shipment Details</Title>
      <Container>
        <GeneralInfo {...shipmentDetails} />
        <ShipUnitDetails {...shipmentDetails.shipUnitDimensions} />
      </Container>
      <Container>
        <TrailerSpaceRequirement {...shipmentDetails} />
        <AdditionalTrailerInfo {...shipmentDetails} />
      </Container>
    </Card>
  );
};

interface GeneralInfoProps {
  confirmedQty: number;
  grossWt: number;
  shipUnitQty: number;
  shipUnit: string;
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({
  confirmedQty,
  grossWt,
  shipUnitQty,
  shipUnit,
}) => {
  return (
    <Section css={{ flex: 2 }}>
      <SectionTitle>General</SectionTitle>
      <InfoGrid>
        <InfoItem>
          <Label>Confirm Item Qty</Label>
          <Value>{confirmedQty}</Value>
        </InfoItem>
        <InfoItem>
          <Label>Gross Wt</Label>
          <Value>{grossWt} lbs</Value>
        </InfoItem>
        <InfoItem>
          <Label>Ship Unit Qty</Label>
          <Value>{shipUnitQty}</Value>
        </InfoItem>
        <InfoItem>
          <Label>Ship Unit</Label>
          <Value>{shipUnit}</Value>
        </InfoItem>
      </InfoGrid>
    </Section>
  );
};

interface ShipUnitDetailsProps {
  length: number;
  height: number;
  width: number;
}

const ShipUnitDetails: React.FC<ShipUnitDetailsProps> = ({
  length,
  height,
  width,
}) => {
  return (
    <Section>
      <SectionTitle>Ship Unit Details</SectionTitle>
      <InfoGrid>
        <InfoItem css={{ borderLeft: "2px solid #e0e0e0", paddingLeft: 10 }}>
          <Label>Length</Label>
          <Value>{length} in</Value>
        </InfoItem>
        <InfoItem>
          <Label>Height</Label>
          <Value>{height} in</Value>
        </InfoItem>
        <InfoItem>
          <Label>Width</Label>
          <Value>{width} in</Value>
        </InfoItem>
      </InfoGrid>
    </Section>
  );
};

interface TrailerSpaceRequirementProps {
  trailerSpaceRequirement: number;
}

const TrailerSpaceRequirement: React.FC<TrailerSpaceRequirementProps> = ({
  trailerSpaceRequirement,
}) => {
  return (
    <Section css={{ maxWidth: 400 }}>
      <SectionTitle>Trailer Space Requirement</SectionTitle>
      <InfoGrid>
        <TrailerSpaceVisualization occupiedSpace={trailerSpaceRequirement} />
      </InfoGrid>
    </Section>
  );
};

interface AdditionalTrailerInfoProps {
  stackable: string;
  hazmat: string;
  temp: string;
}

const AdditionalTrailerInfo: React.FC<AdditionalTrailerInfoProps> = ({
  stackable,
  hazmat,
  temp,
}) => {
  return (
    <Section>
      <InfoGrid css={{ marginTop: 30, paddingLeft: 50 }}>
        <InfoItem>
          <Label>Stackable</Label>
          <Value>{stackable}</Value>
        </InfoItem>
        <InfoItem>
          <Label>Hazmat</Label>
          <Value>{hazmat}</Value>
        </InfoItem>
        <InfoItem>
          <Label>Temp</Label>
          <Value>{temp}</Value>
        </InfoItem>
      </InfoGrid>
    </Section>
  );
};

export default ShipmentDetails;
