/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import styled from "@emotion/styled";
import { getRefNumLabel, SelectRefQualifier } from "./SelectRefQualifier";
import { useR2SContext } from "pages/rtscao/service/R2SContext";
import { DockTitle } from "../Shared";

interface QualifierValue {
  qualifier: string;
  value: string;
}

interface QualifierValueListProps {
  //   qualifierOptions: { label: string; value: string }[];
  defaultValue?: QualifierValue[];
  //   onChange: (values: QualifierValue[]) => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Row = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const Input = styled.input`
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Button = styled.button`
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
`;

const RemoveButton = styled(Button)`
  background-color: #d9534f;
`;

const AddButton = styled(Button)`
  background-color: #5cb85c;
`;

const QualifierValueList: React.FC<QualifierValueListProps> = ({
  //   qualifierOptions,
  defaultValue = [{ qualifier: "", value: "" }],
  //   onChange,
}) => {
  const { editMode } = useR2SContext();
  const [values, setValues] = useState<QualifierValue[]>(defaultValue);

  const handleValueChange = (index: number, value: string) => {
    const newValues = [...values];
    newValues[index].value = value;
    setValues(newValues);
  };

  const handleRemove = (index: number) => {
    const newValues = values.filter((_, i) => i !== index);
    setValues(newValues);
  };

  const handleAdd = () => {
    const newValues = [...values, { qualifier: "", value: "" }];
    setValues(newValues);
  };

  if (editMode === false) {
    return (
      <Container>
        <Row css={{ display: "flex", gap: 10, alignItems: "center" }}>
          <div css={{ minWidth: 200 }}>
            <DockTitle>Qualifiers</DockTitle>
          </div>
          <DockTitle>Value</DockTitle>
        </Row>
        {values.map((item, index) => (
          <Row
            key={index}
            css={{ display: "flex", gap: 10, alignItems: "center" }}
          >
            <div css={{ minWidth: 200 }}>
              <div>{getRefNumLabel(item.qualifier)}</div>
            </div>
            <div>{item.value}</div>
          </Row>
        ))}
      </Container>
    );
  }

  return (
    <Container>
      <Row css={{ display: "flex", gap: 10, alignItems: "center" }}>
        <div css={{ minWidth: 200 }}>
          <DockTitle>Qualifiers</DockTitle>
        </div>
        <DockTitle>Value</DockTitle>
      </Row>
      {values.map((item, index) => (
        <Row
          key={index}
          css={{ display: "flex", gap: 10, alignItems: "center" }}
        >
          <div css={{ minWidth: 200 }}>
            <SelectRefQualifier
              defaultValue={item.qualifier}
              name={`shipmentDetails.refQualifierValues.${index}.qualifier`}
            />
          </div>
          <Input
            type="text"
            value={item.value}
            onChange={(e) => handleValueChange(index, e.target.value)}
            name={`shipmentDetails.refQualifierValues.${index}.value`}
            placeholder="Value"
          />
          {values.length > 1 && (
            <RemoveButton type="button" onClick={() => handleRemove(index)}>
              Remove
            </RemoveButton>
          )}
          {index === values.length - 1 && (
            <AddButton type="button" onClick={handleAdd}>
              Add New
            </AddButton>
          )}
        </Row>
      ))}
    </Container>
  );
};

export default QualifierValueList;
