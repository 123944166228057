/** @jsxImportSource @emotion/react */
import { Modal as BootstrapModal } from "react-bootstrap";
import React from "react";
import Loader from "react-loader";
import Colors from "styles/colors";

interface ModalProps {
  children: React.ReactNode;
  onHide: () => void;
  show?: boolean;
  "data-qa"?: string;
  [key: string]: any;
}

export const Modal: React.FC<ModalProps> & {
  Header: typeof ModalHeader;
  Body: typeof ModalBody;
  Footer: typeof ModalFooter;
} = (props) => {
  return <BootstrapModal {...props} />;
};

interface ModalHeaderProps {
  title: React.ReactNode;
  isLoading?: boolean;
  closeButton?: boolean;
  customCss?: React.CSSProperties;
  closeVariant?: string;
  children?: React.ReactNode;
}

const ModalHeader: React.FC<ModalHeaderProps> = ({
  title,
  isLoading = false,
  closeButton = true,
  customCss,
  closeVariant,
}) => {
  return (
    <BootstrapModal.Header
      closeButton={closeButton}
      closeVariant={closeVariant}
      css={{
        backgroundColor: Colors.background.LIGHT_GRAY,
        color: Colors.background.DARK_BLUE,
        padding: "0.5rem 0.75rem 0.5rem 0.5rem",
        ...customCss,
      }}
    >
      <BootstrapModal.Title style={{ fontWeight: "300" }}>
        {title}
        <span
          css={{
            position: "relative",
            ".loadedContent": { display: "inline" },
          }}
        >
          <Loader
            //@ts-ignore
            style={{ display: "inline-block" }}
            loaded={!isLoading}
            left="1em"
            scale={0.5}
          />
        </span>
      </BootstrapModal.Title>
    </BootstrapModal.Header>
  );
};

Modal.Header = ModalHeader;
Modal.Header.displayName = "Modal.Header";

interface ModalBodyProps {
  style?: React.CSSProperties;
  className?: string;
  children?: React.ReactNode;
}

const ModalBody = React.forwardRef<HTMLDivElement, ModalBodyProps>(
  ({ style = {}, className, children }, modalBodyRef) => {
    return (
      <BootstrapModal.Body
        ref={modalBodyRef}
        style={{ backgroundColor: Colors.background.LIGHT_GRAY, ...style }}
        className={className}
      >
        {children}
      </BootstrapModal.Body>
    );
  },
);

Modal.Body = ModalBody;
Modal.Body.displayName = "Modal.Body";

interface ModalFooterProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const ModalFooter: React.FC<ModalFooterProps> = ({ children, style = {} }) => {
  return (
    <BootstrapModal.Footer style={{ backgroundColor: "#e2e2e2", ...style }}>
      {children}
    </BootstrapModal.Footer>
  );
};

Modal.Footer = ModalFooter;
Modal.Footer.displayName = "Modal.Footer";
