/** @jsxImportSource @emotion/react */
import { useState, Fragment } from "react";
import _ from "lodash";
import PropTypes from "prop-types";

import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Text } from "components/atoms/Text.atom";
import { FontSize } from "components/atoms/enums";
import { Icon } from "components/atoms/Icon.atom";
import { Tooltip } from "components/atoms/Tooltip.atom";
import { CommentFeed } from "components/organisms/CommentFeed.organism";
import {
  ArrivalAndDeparturePanelGroup,
  getLocationDataFromShipments,
} from "components/organisms/ArrivalAndDeparturePanelGroup.organism";
import { LadChicletCSS, MadChicletCSS, colorForLad } from "components/chiclets";
import { faQuestionCircle } from "@fortawesome/pro-solid-svg-icons";
import Colors, { shadeColor } from "styles/colors";
import { isShipper } from "shared/utils/organizations.utils";

import { ActionNav } from "./ActionNav";
import ShipmentDetailTabs from "./ShipmentDetailTabs";
import TimeForceCarousel from "./TimeForceCarousel";
import LastUpdateDisplay from "./shipment-detail-styled-components/LastUpdateDisplay";
import ShipmentDetailsCollapsible from "./shipment-detail-styled-components/ShipmentDetailsCollapsible";
import ShipmentWatchToggle from "./shipment-detail-styled-components/ShipmentWatchToggle";

import {
  getAssetId,
  getCurrentModeName,
  getReferenceValue,
} from "./ShipmentUtils";
import { getDistance } from "../map/utils/geo";
import { getStopLatLong } from "../map/utils/RoutingMapUtils";
import { Privileges } from "../auth/Authorization";
import { getAuthorization } from "../auth/AuthorizationSelectors";
import { getDefaultUnclassifiedLad } from "shared/redux/Lads.state";
import { getColorForExceptionFromName } from "pages/shipments/utils/exception.utils";
import { batchCsvShipmentExample } from "components/search-bar/batch-comment-csv-data";
import { useShipmentTranslation } from "shared/hooks/useShipmentTranslation";
import { Mode } from "shared/constants/shipments.const";

const Circle = ({ bgColor, borderColor }) => {
  const circleStyle = {
    padding: 10,
    display: "inline-block",
    backgroundColor: bgColor,
    border: "2px solid " + borderColor,
    borderRadius: "50%",
    width: 30,
    height: 30,
  };
  return <div style={circleStyle} />;
};

Circle.propTypes = {
  bgColor: PropTypes.string.isRequired,
  borderColor: PropTypes.string.isRequired,
};

function computeStopSpacing(stops) {
  let totalDistance = 0.0;
  let stopDistances = [];
  let lastPos = null;

  // Start by finding the distance between
  // each of the stops.  The JSON back from the
  // API does have a distance field, but it
  // looks to be invalid, so recompute it
  // here based on math distance, so not perfect
  // but close enough for our UI display
  stops.forEach((s) => {
    let pos = getStopLatLong(s);

    let d = 0.0;
    if (lastPos !== null) {
      d = getDistance(pos, lastPos);
    }

    lastPos = pos;
    totalDistance += d;
    stopDistances.push(totalDistance);
  });

  // The default stop spacing is just a percentage
  // of the way along the route for that shipment
  let stopSpacing = [];
  stopDistances.forEach((d) => {
    stopSpacing.push((d / totalDistance) * 100.0);
  });

  // This function is used to adjust inner
  // spacing of the stops.  If the percentages
  // are too close, the displayed LADs will overlap
  // so for UI purposes, try to adjust the spacing
  // This will slide things down to left in an
  // accordion fashion.
  function adjustStopSpacing() {
    const minSpacing = 11.0;
    let adjustedSpacing = false;

    for (let i = stopSpacing.length - 2; i > 1; i--) {
      const diff = stopSpacing[i] - stopSpacing[i - 1];

      // If at least the min spacing requirement
      // skip this one
      if (diff >= minSpacing) {
        continue;
      }

      // If the diff is near zero it is likely the same
      // location, let it draw exactly on itself
      if (diff < 0.5) {
        continue;
      }

      adjustedSpacing = true;

      // Let's see if we should move this shipment to the left
      // or to the right
      const leftSpace = stopSpacing[i - 1] - stopSpacing[i - 2];
      const rightSpace = stopSpacing[i + 1] - stopSpacing[i];

      if (rightSpace > leftSpace && rightSpace > minSpacing) {
        stopSpacing[i] = stopSpacing[i - 1] + minSpacing;
      } else {
        // At this point, slide the i-1 stop over
        // a bit, but don't pass the i-2 stop
        stopSpacing[i - 1] = _.max([
          stopSpacing[i - 2] + 1.0,
          stopSpacing[i] - minSpacing,
        ]);
      }
    }
    return !adjustedSpacing;
  }

  // If we have at least three stops, check if
  // any of them need to be adjusted
  if (stopSpacing.length > 2) {
    let validSpacing = false;
    let tryCount = 0;
    while (!validSpacing && tryCount < 5) {
      validSpacing = adjustStopSpacing();
      tryCount += 1;
    }
  }

  // Looks like a rounding error for placement
  // of the last tick mark for the final destination
  // So bring it in a smidge
  if (stopSpacing.length > 1) {
    stopSpacing[stopSpacing.length - 1] = 99.5;
  }

  return stopSpacing;
}

//event bar helper. return an array of spacing percentages between events,
//but make sure to round the last event to 100%
//SH-3717 - Changes
//first event is left aligned - given a value 0 so that event progress starts from the beginning.
//in between events are center aligned - 100 divided by number of events(event spacing)
//and multiplied by its event position, we got the event position and add half width of the event spacing to it,
//so that event progress stops at the event position at the center since it is center aligned.
//and last event is right aligned - given a value 100 so that event progress shown till the end.
const eventBarSpacing = (numEvents) => {
  return Array(numEvents)
    .fill()
    .map((_, i) => {
      const space = 100.0 / numEvents;
      return i < numEvents - 1
        ? i === 0
          ? 0
          : i * space + 100 / numEvents / 2
        : 100;
    });
};

const EventBarScroller = ({
  leftScrollHandler,
  rightScrollHandler,
  numStops,
  index,
  scrollSize,
}) => {
  const { t } = useTranslation("shipment-details");

  const totalPartitions = Math.ceil(numStops / scrollSize);
  const currentPartition = Math.ceil((index + 1) / scrollSize);
  const rightScrollEnabled = index + scrollSize < numStops;
  const leftScrollEnabled = index - scrollSize >= 0;
  return (
    <div
      css={{
        width: "100%",
        textAlign: "center",
      }}
    >
      {totalPartitions > 1 && (
        <button
          onClick={() => {
            leftScrollHandler();
          }}
          style={{ float: "left" }}
          disabled={!leftScrollEnabled}
        >
          {t("shipment-details:Previous")}
        </button>
      )}
      {totalPartitions > 1 && (
        <button
          onClick={() => {
            rightScrollHandler();
          }}
          style={{ float: "right" }}
          disabled={!rightScrollEnabled}
        >
          {t("shipment-details:Next")}
        </button>
      )}
      {`${currentPartition} ${t("shipment-details:of")} ${totalPartitions}`}
    </div>
  );
};

EventBarScroller.propTypes = {
  leftScrollHandler: PropTypes.func.isRequired,
  rightScrollHandler: PropTypes.func.isRequired,
  numStops: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  scrollSize: PropTypes.number.isRequired,
};

// a bar that displays carrier events as updates occur in a sequential order
// spaces between events should be equal so the bar should dynamically recreate recreate
// itself as shipment updates come in

export const EventBar = ({ shipment, scrollSize, mode: originalMode }) => {
  const { t } = useTranslation("shipment-details");

  const [startIndex, setStartIndex] = useState(0);

  const rightScroll = () => {
    setStartIndex(startIndex + scrollSize);
  };

  const leftScroll = () => {
    setStartIndex(startIndex - scrollSize);
  };

  if (shipment.shipment_stops == null) {
    return null;
  }

  const { shipment_stops } = shipment;
  // overkill but a holdover from when we were using lads

  const mode = originalMode?.toLowerCase() ?? null;

  let multimodalOrCrossborderOrOcean =
    mode !== null &&
    (mode === "ocean" ||
      mode === "multimodal" ||
      shipment?.shipment_details?.is_multileg);
  let isLtlOrParcel = mode !== null && (mode === "ltl" || mode === "parcel");

  const eventsData = shipment_stops.map((s, i) => {
    let event_str = "";
    let arrived = false;
    if (i === 0) {
      event_str = multimodalOrCrossborderOrOcean
        ? t("shipment-details:Picked up from")
        : t("shipment-details:Departed Origin");
      // H1-253: Origin should fill-in only after the truck has departed.
      // This does that without changing the has_arrived name, but we set
      // a different value calculated here (for the Origin).
      arrived = s.departed_at !== null;
    } else if (i === shipment_stops.length - 1) {
      event_str = multimodalOrCrossborderOrOcean
        ? t("shipment-details:Delivered to")
        : t("shipment-details:Delivered");
      // For the Destination..
      arrived = s.arrived_at !== null;
    } else {
      event_str = multimodalOrCrossborderOrOcean
        ? t("shipment-details:Arrived at")
        : t("shipment-details:Arrived at Terminal");
      // ..and all other stops, we still fill-in based on arrival.
      arrived = s.arrived_at !== null;
    }
    return {
      address: s.location.name,
      defaultLadName: s.location.lad?.default_name,
      locationCategory: s.location.category,
      eventname: event_str,
      has_arrived: arrived,
    };
  });

  //restrict events data to only show {scrollSize} stops at a time
  const eventsDataSlice = eventsData.slice(startIndex, startIndex + scrollSize);
  const eventsDataSliceLength = eventsDataSlice.length;

  const eventSpacing = eventBarSpacing(eventsDataSliceLength);
  const hasDelivered =
    eventsDataSliceLength > 0
      ? eventsDataSlice[eventsDataSliceLength - 1].has_arrived
      : false;
  // find the last index that has_arrived == true, currently only used on multimodal to avoid crashing on non multimodal pages that use this LTL bar
  const lastArrived = _.findLastIndex(eventsDataSlice, ["has_arrived", true]);
  const events = eventsDataSlice.map((e, i) => {
    const borderColor = e.has_arrived
      ? Colors.highlight.LIGHT_BLUE
      : Colors.background.GRAY;

    // H1-3630: Display a tooltip for the user if this is an unclassified shipper location.
    // Only valid for LTL and Parcel shipments.
    const showTooltipForLocation =
      isLtlOrParcel &&
      e.locationCategory?.toLowerCase() === "shipper" &&
      e.defaultLadName?.toLowerCase() === "unclassified";

    const flexAlign =
      i > 0 && i < eventsDataSliceLength - 1
        ? "center"
        : i !== 0
        ? "flex-end"
        : "flex-start";
    const textAlign =
      i > 0 && i < eventsDataSliceLength - 1
        ? "center"
        : i !== 0
        ? "right"
        : "left";

    return (
      <div
        key={i}
        css={{
          display: "flex",
          flexDirection: "column",
          alignItems: flexAlign,
          width: `${100 / eventsDataSliceLength}%`,
        }}
      >
        <Circle bgColor="white" borderColor={borderColor} />
        <Text
          size={FontSize.size14}
          align={textAlign}
          style={{ paddingTop: 5 }}
        >
          {e.eventname}
        </Text>
        <Text size={FontSize.size12} align={textAlign}>
          {e.address}
          {showTooltipForLocation ? (
            <Tooltip
              tooltipChildren={t(
                "shipment-details:This is an Unclassified Shipper Location",
              )}
            >
              {" "}
              <Icon src={faQuestionCircle} />
            </Tooltip>
          ) : null}
        </Text>
      </div>
    );
  });

  let progress = hasDelivered ? 100 : eventSpacing[eventSpacing.length - 2];
  if (multimodalOrCrossborderOrOcean) {
    progress = eventSpacing[lastArrived];
  }

  return (
    <div
      css={{
        position: "relative",
        display: "flex",
        margin: "1em 1em 6em",
        justifyContent: "space-between",
        height: 90,
      }}
    >
      <EventBarScroller
        t={t}
        leftScrollHandler={leftScroll}
        rightScrollHandler={rightScroll}
        numStops={shipment_stops.length}
        index={startIndex}
        scrollSize={scrollSize}
      />
      <div
        css={{
          position: "absolute",
          width: "100%",
          left: 0,
          display: "flex",
          alignItems: "flex-start",
          zIndex: 1,
        }}
      >
        <div
          css={{
            position: "absolute",
            height: 3,
            backgroundColor: shadeColor(Colors.highlight.LIGHT_BLUE, 0.5),
            left: 20,
            top: 46,
            right: `${100 - progress}%`,
            zIndex: 0,
          }}
        />
        <div
          css={{
            display: "flex",
            justifyContent: "space-between",
            gap: 10,
            position: "absolute",
            top: 30,
            width: "100%",
          }}
        >
          {events}
        </div>
      </div>
      <div
        css={{
          position: "absolute",
          height: 4,
          backgroundColor: Colors.background.GRAY,
          top: 46,
          left: 20,
          right: 20,
          zIndex: 0,
        }}
      />
    </div>
  );
};

EventBar.propTypes = {
  shipment: PropTypes.object,
  scrollSize: PropTypes.number,
  mode: PropTypes.string,
};

export const ShipmentTimeline = ({
  shipment,
  eventHandler,
  organization,
  shipmentModes,
  equalSpacing,
  width,
  hidePercentage,
}) => {
  const authorization = useSelector(getAuthorization);

  if (shipment.shipment_stops == null) {
    return null;
  }

  let stopSpacing;
  let spacingInterval = 0;

  // H1-609/H1-842: Added the equal spacing option
  if (equalSpacing) {
    spacingInterval = Math.round(width / (shipment.shipment_stops.length - 1));
    stopSpacing = shipment.shipment_stops.map((s, i) =>
      Math.round((100 * i * spacingInterval) / width),
    );
  } else {
    // there is a stop distance field in the backend but doesn't look valid
    stopSpacing = computeStopSpacing(shipment.shipment_stops);
  }

  // In order for LAD to clickable, we must be
  // a shipper org and the LAD must be unclassified
  const ladPermissions =
    isShipper(organization) &&
    authorization.hasPrivileges([Privileges.MANAGE_SHIPPER_LOCATIONS]);

  const stops = shipment.shipment_stops.map((s, i) => {
    const lad =
      s.location && s.location.lad
        ? s.location.lad
        : getDefaultUnclassifiedLad();
    const clickable =
      ladPermissions &&
      lad.default_name === "Unclassified" &&
      _.isFunction(eventHandler);
    return (
      <div key={i}>
        <div
          css={{
            position: "absolute",
            left: `${stopSpacing[i]}%`,
            ":hover": clickable ? { cursor: "pointer" } : {},
          }}
          onClick={() => {
            if (clickable) {
              eventHandler(s.location, "TIMELINE_LAD_CLICK");
            }
          }}
        >
          <LadChicletCSS lad={lad} width={40} height={40} showLadLabel />
        </div>
        <div
          css={{
            position: "absolute",
            width: 2,
            height: 33,
            backgroundColor: colorForLad(lad),
            left: `${stopSpacing[i]}%`,
            bottom: -72,
            marginLeft: 19,
            zIndex: 0,
          }}
        />
      </div>
    );
  }); // map

  // Falling back to using the distance progress field
  // have found this to be more accurate
  const progStr = shipment.current_location
    ? shipment.current_location.distance_progress.replace("%", "")
    : "0";

  let progress;
  if (equalSpacing) {
    const numFullLegs = shipment.current_location.active_leg_idx - 1;
    const pixelsProgress =
      (numFullLegs + Number(progStr) / 100) * spacingInterval;
    progress = (pixelsProgress / width) * 100;
  } else {
    progress = Number(progStr);
  }
  progress = _.max([0.0, progress]);

  const currentModeName = getCurrentModeName(shipment, shipmentModes);

  return (
    <div
      css={{
        position: "relative",
        display: "flex",
        margin: "1em",
        marginBottom: "5em",
        justifyContent: "space-between",
        height: 35,
      }}
    >
      <div
        css={{
          zIndex: 1,
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
        }}
      >
        <div
          css={{ position: "absolute", width: "calc(100% - 38px)", left: 0 }}
        >
          <div
            css={{
              position: "absolute",
              height: 25,
              backgroundColor: shadeColor(
                shipment.active_exceptions_ng
                  ? getColorForExceptionFromName(shipment.active_exceptions_ng)
                  : Colors.highlight.GREEN,
                0.6,
              ),
              left: 19,
              top: 47,
              right: `${100 - progress}%`,
              zIndex: 0,
            }}
          />

          <div
            css={{
              position: "absolute",
              left: `${progress}%`,
              top: 42.5,
              zIndex: 1,
            }}
          >
            <MadChicletCSS
              shipmentMode={shipment.mode_name}
              stopMode={currentModeName}
              activeException={shipment.active_exceptions_ng}
              width={35}
              height={35}
            />
            {hidePercentage ? null : (
              <div
                css={{
                  color: Colors.background.DARK_BLUE,
                  textAlign: "center",
                }}
              >
                {`${progress.toFixed(0)}%`}
              </div>
            )}
          </div>
          {stops}
        </div>
      </div>
      <div
        css={{
          position: "absolute",
          height: 27,
          backgroundColor: Colors.background.GRAY,
          marginTop: 46,
          left: 19,
          right: 19,
          zIndex: 0,
          border: "1px solid #999",
        }}
      />
    </div>
  );
};

ShipmentTimeline.propTypes = {
  eventHandler: PropTypes.func,
  ladsList: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      default_id: PropTypes.number,
      default_name: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number.isRequired,
      lad_name: PropTypes.string,
      lob_name: PropTypes.string,
    }),
  ),
  organization: PropTypes.object,
  shipment: PropTypes.object,
  shipmentModes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  ),
  equalSpacing: PropTypes.bool,
  width: PropTypes.number,
  hidePercentage: PropTypes.bool,
};

const ShipmentContentPanel = ({
  shipment,
  ladsList,
  addCoordinate,
  clearCoordinatesByType,
  selectedMapCoordinate,
  eventHandler,
  organization,
  enableActionMenu,
  enableAlertMeAction,
  enableShipmentEventsAction,
  enableCancelShipmentAction,
  enableReportDelayAction,
  enableClearDelayAction,
  enableMobileTrackingAction,
  enableBillOfLadingAction,
  shipmentModes,
  shippers,
  isLoaded,
  toggleWatchShipment,
  tripPlan,
  tripPlanIsLoading,
  showComments,
  commentShareableWithOrgId,
  fetchComments,
  isFetchingComments,
  comments,
  addComment,
  cancelAddComment,
  updateComment,
  cancelUpdateComment,
  markCommentsRead,
  addBatchComments,
  clearBatchComments,
  isBatchCommentInProgress,
  isBatchCommentSuccessful,
  isBatchCommentFailed,
  fetchNotification,
  shipmentSubscription,
  isShipmentSubscriptionLoading,
  showVinsTab = true,
  showEta = true,
}) => {
  const { t } = useTranslation("shipment-details");
  const { getTranslatedStatus } = useShipmentTranslation();

  const status = shipment
    ? `${getTranslatedStatus(shipment.active_status_ng)} ${getTranslatedStatus(
        shipment.current_status_ng,
      )}`
    : "";

  if (!isLoaded) {
    return null;
  }

  const mode = shipment.mode_name ?? "";

  /* H1-337 WD High Value Asset Tracking */
  // Feb 23 2021: I attempted to find a shipment that has this set to true.
  // In the API, this is set to true if the shipment has `event_references`
  // but the DB view does not contain `event_references` anymore.
  // This check and the TimeForceCarousel may be eligible for deleting.
  const is_HVA = shipment.has_event_refs;
  // eslint-disable-next-line
  let progressElem;

  if (is_HVA) {
    progressElem = (
      <Fragment>
        <TimeForceCarousel shipment={shipment} />
      </Fragment>
    );
  } else if (mode === Mode.LTL || mode === Mode.PARCEL) {
    //SH-8188 - mode === "Ocean" removed from above condition to show percentage progress bar(shipmentTimeline) for standalone ocean shipments
    progressElem = (
      <EventBar shipment={shipment} scrollSize={4} t={t} mode={mode} />
    );
  } else if (mode === Mode.AIR) {
    progressElem = null;
  } else {
    progressElem = (
      <Fragment>
        <ShipmentTimeline
          shipment={shipment}
          ladsList={ladsList}
          eventHandler={eventHandler}
          organization={organization}
          shipmentModes={shipmentModes}
        />
        <LastUpdateDisplay shipment={shipment} />
      </Fragment>
    );
  }

  const { origin, destination, calculatedEta, frozenEta, isFvEta } =
    getLocationDataFromShipments([shipment]);

  // Get Asset ID
  const assetId = getAssetId(organization, shipment);

  // Get Trailer and Equipment numbers
  // H1-3859: Only show unique values on the UI.
  //   To see all, the user can look at the References tab.
  const trailerAndEquipmentNumbers = _.uniq(
    shipment.trailer_equipment_number ?? [],
  );

  // Get Service Code
  const serviceCode = getReferenceValue(shipment, "service_code");

  //Get delayed by Carrier or Shipper
  const getDelayedBy = (frozenEta, active_exceptions_ng, current_exception) => {
    if (!_.isNil(frozenEta)) {
      if (
        active_exceptions_ng === "Carrier Delayed" &&
        current_exception === "manual_carrier_delay"
      ) {
        return t(
          "shipment-details:Carrier has indicated a delay. This ETA is provided by the Carrier.",
        );
      } else if (
        active_exceptions_ng === "Carrier Delayed" &&
        current_exception === "manual_shipper_delay"
      ) {
        return t(
          "shipment-details:Shipper has indicated a delay. This ETA is provided by the Shipper.",
        );
      } else {
        return null;
      }
    }
  };

  return (
    <div css={{ flexDirection: "column", overflow: "visible" }}>
      {/* Details Group */}
      <ShipmentDetailsCollapsible
        shipmentId={shipment.creator_shipment_id}
        assetId={assetId}
        mode={mode}
        status={status}
        shipmentType={shipment.shipment_type_ng}
        shipperName={shipment.shipper_name}
        carrierName={shipment.carrier_name}
        carrierScac={shipment.carrier_scac}
        currentRoad={shipment.current_road_name}
        trailerAndEquipmentNumbers={trailerAndEquipmentNumbers}
        serviceCode={serviceCode}
        exception={shipment.active_exceptions_ng}
        isBackOrder={shipment?.shipment_details?.is_back_order}
        isOffRoute={shipment?.shipment_details?.is_off_route}
        railTrainId={shipment.rail_train_id}
        loadedStatus={shipment.loaded_status}
        extraHeaderContent={
          <div css={{ display: "flex", alignItems: "center", gap: "1em" }}>
            {!_.isEmpty(shipment) && toggleWatchShipment ? (
              <ShipmentWatchToggle
                watch={shipment.watched}
                toggleWatchShipment={toggleWatchShipment}
                isShowingComments={showComments}
              />
            ) : null}
            {enableActionMenu ? (
              <ActionNav
                shipment={shipment}
                eventHandler={eventHandler}
                enableAlertMeAction={enableAlertMeAction}
                enableShipmentEventsAction={enableShipmentEventsAction}
                enableCancelShipmentAction={enableCancelShipmentAction}
                enableReportDelayAction={enableReportDelayAction}
                enableClearDelayAction={enableClearDelayAction}
                enableMobileTrackingAction={enableMobileTrackingAction}
                enableBillOfLadingAction={enableBillOfLadingAction}
                assetId={assetId}
                arrived={shipment?.active_status_ng === "Arrived"}
                shipmentSubscription={shipmentSubscription}
                isShipmentSubscriptionLoading={isShipmentSubscriptionLoading}
              />
            ) : null}
          </div>
        }
        etaTimeCategory={shipment.eta_time_category}
      />
      {/* Arrivals and Departures Group */}
      <ArrivalAndDeparturePanelGroup
        shipment={shipment}
        origin={origin}
        destination={destination}
        eta={showEta ? (!_.isNil(frozenEta) ? frozenEta : calculatedEta) : null}
        onlyShowEtaDate={mode === Mode.PARCEL || mode === Mode.LTL}
        hideScheduledTimes={mode === Mode.PARCEL || mode === Mode.LTL}
        etaWarningMessage={getDelayedBy(
          frozenEta,
          shipment.active_exceptions_ng,
          shipment.current_exception,
        )}
        isFvEta={isFvEta}
        visibleFields={{
          availableForUnload: shipment?.mode_name === Mode.RAIL,
        }}
        availableForUnloadTs={shipment?.available_for_unload_ts}
        style={{ margin: "1em" }}
      >
        <ArrivalAndDeparturePanelGroup.SubHeaders>
          <ArrivalAndDeparturePanelGroup.RouteSubHeader
            routeId={shipment?.route_number}
            // ISS-9715: Hiding On Time for Cummins org
            onTimePercentage={
              organization?.fv_id !== "CUMMINS"
                ? shipment?.on_time_percentage
                : null
            }
          />
          <ArrivalAndDeparturePanelGroup.TripPlanIdSubHeader
            tripPlanId={shipment?.trip_plan_number}
          />
        </ArrivalAndDeparturePanelGroup.SubHeaders>
      </ArrivalAndDeparturePanelGroup>
      {showComments ? (
        <CommentFeed
          commentShareableWithOrgId={commentShareableWithOrgId}
          fetchComments={fetchComments}
          isFetchingComments={isFetchingComments}
          comments={comments}
          addComment={addComment}
          cancelAddComment={cancelAddComment}
          updateComment={updateComment}
          cancelUpdateComment={cancelUpdateComment}
          markCommentsRead={markCommentsRead}
          addBatchComments={addBatchComments}
          clearBatchComments={clearBatchComments}
          isBatchCommentInProgress={isBatchCommentInProgress}
          isBatchCommentSuccessful={isBatchCommentSuccessful}
          isBatchCommentFailed={isBatchCommentFailed}
          batchCsvExample={batchCsvShipmentExample}
          batchImportLabel={t("shipment-details:Shipment ID")}
          shipperOrgId={
            isShipper(organization) ? organization.organization_id : null
          }
          shippers={shippers}
          style={{ margin: "1em" }}
          fetchNotification={fetchNotification}
        />
      ) : null}

      {/* DEV-1545 LTL shipments hide Shipment Timeline */}
      {progressElem}
      <div>
        <ShipmentDetailTabs
          shipment={shipment}
          selectedMapCoordinate={selectedMapCoordinate}
          addCoordinate={addCoordinate}
          clearCoordinatesByType={clearCoordinatesByType}
          organization={organization}
          tripPlan={tripPlan}
          tripPlanIsLoading={tripPlanIsLoading}
          showVinsTab={showVinsTab}
        />
      </div>
    </div>
  );
};

ShipmentContentPanel.propTypes = {
  shipment: PropTypes.object,
  ladsList: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      default_id: PropTypes.number,
      default_name: PropTypes.string,
      description: PropTypes.string,
      id: PropTypes.number.isRequired,
      lad_name: PropTypes.string,
      lob_name: PropTypes.string,
    }),
  ),
  organization: PropTypes.object,
  shipmentModes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string,
    }),
  ),
  shippers: PropTypes.arrayOf(
    PropTypes.shape({
      shipper_organization_id: PropTypes.number,
      shipper_name: PropTypes.string,
      shipper_fv_id: PropTypes.string,
    }),
  ),
  addCoordinate: PropTypes.func.isRequired,
  clearCoordinatesByType: PropTypes.func.isRequired,
  selectedMapCoordinate: PropTypes.any,
  eventHandler: PropTypes.func,
  enableActionMenu: PropTypes.bool,
  enableAlertMeAction: PropTypes.bool,
  enableShipmentEventsAction: PropTypes.bool,
  enableCancelShipmentAction: PropTypes.bool,
  enableReportDelayAction: PropTypes.bool,
  enableClearDelayAction: PropTypes.bool,
  enableMobileTrackingAction: PropTypes.bool,
  enableBillOfLadingAction: PropTypes.bool,
  isLoaded: PropTypes.bool,
  toggleWatchShipment: PropTypes.func,
  tripPlan: PropTypes.any,
  tripPlanIsLoading: PropTypes.bool,
  showComments: PropTypes.bool,
  commentShareableWithOrgId: PropTypes.number,
  fetchComments: PropTypes.func,
  isFetchingComments: PropTypes.bool,
  comments: PropTypes.shape({
    currentPage: PropTypes.number,
    data: PropTypes.array,
    totalCount: PropTypes.number,
    totalCountRead: PropTypes.number,
    totalCountUnread: PropTypes.number,
    totalPages: PropTypes.number,
  }),
  addComment: PropTypes.func,
  cancelAddComment: PropTypes.func,
  updateComment: PropTypes.func,
  cancelUpdateComment: PropTypes.func,
  markCommentsRead: PropTypes.func,
  addBatchComments: PropTypes.func.isRequired,
  clearBatchComments: PropTypes.func.isRequired,
  isBatchCommentInProgress: PropTypes.bool.isRequired,
  isBatchCommentSuccessful: PropTypes.bool.isRequired,
  isBatchCommentFailed: PropTypes.bool.isRequired,
  fetchNotification: PropTypes.func,
  shipmentSubscription: PropTypes.object,
  isShipmentSubscriptionLoading: PropTypes.bool,
  showVinsTab: PropTypes.bool,
  showEta: PropTypes.bool,
};

export default ShipmentContentPanel;
