/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { useState, ChangeEvent } from "react";

const CheckboxContainer = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
`;

const HiddenCheckbox = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

interface StyledCheckboxProps {
  checked: boolean;
  isFocused: boolean;
}

const StyledCheckbox = styled.div<StyledCheckboxProps>`
  width: 24px;
  height: 24px;
  border: 2px solid ${(props) => (props.checked ? "#4CAF50" : "#ccc")};
  border-radius: 4px;
  transition: all 150ms;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${(props) =>
    props.isFocused ? "0 0 0 3px rgba(76, 175, 80, 0.3)" : "none"};
`;

const CheckIcon = styled.svg`
  fill: none;
  stroke: #4caf50;
  stroke-width: 3px;
`;

const Label = styled.span`
  margin-left: 8px;
  font-size: 16px;
`;

interface CheckboxProps {
  className?: string;
  checked: boolean;
  label: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox: React.FC<CheckboxProps> = ({
  className,
  checked,
  label,
  onChange,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <CheckboxContainer className={className}>
      <HiddenCheckbox
        type="checkbox"
        checked={checked}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
      />
      <StyledCheckbox checked={checked} isFocused={isFocused}>
        <CheckIcon viewBox="0 0 24 24" css={{ opacity: checked ? "1" : "0" }}>
          <polyline points="20 6 9 17 4 12" />
        </CheckIcon>
      </StyledCheckbox>
      <Label>{label}</Label>
    </CheckboxContainer>
  );
};
