/** @jsxImportSource @emotion/react */
import React from "react";
import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";

const sliderContainerStyles: SerializedStyles = css`
  width: 100%;
  padding: 5px;
  font-family: Arial, sans-serif;
  height: 60px;
  margin-top: 10px;
`;

const labelStyles: SerializedStyles = css`
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0px;
`;

const sliderStyles: SerializedStyles = css`
  -webkit-appearance: none;
  flex: 1;
  max-height: 4px;
  border-radius: 2px;
  background: #e0e0e0;
  outline: none;
  padding: 0;
  margin: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #3366ff;
    cursor: pointer;
    border: 2px solid white;
    box-shadow: 0 0 0 2px #3366ff;
  }

  &::-moz-range-thumb {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #3366ff;
    cursor: pointer;
    border: 2px solid white;
    box-shadow: 0 0 0 2px #3366ff;
  }
`;

const valueContainerStyles: SerializedStyles = css`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 10px;
  align-items: center;
`;

const valueStyles: SerializedStyles = css`
  font-size: 14px;
  color: #666;
  margin-bottom: 32px;
`;

interface CurrentValueProps {
  value: number;
}

const CurrentValue = styled.span<CurrentValueProps>`
  font-size: 14px;
  color: #3366ff;
  font-weight: bold;
  position: relative;
  margin-top: 10px;
  width: 50px;
  text-align: center;
  left: ${(props) => mapScale(props.value)}%;
  transform: translateX(-50%);
`;

function mapScale(inputValue: number): number {
  return ((inputValue - 60) / 140) * 100;
}

interface MileRadiusSliderProps {
  value: number;
  setValue: (value: number) => void;
  className?: string;
}

export const MileRadiusSlider: React.FC<MileRadiusSliderProps> = ({
  value,
  setValue,
  className,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setValue(parseInt(e.target.value, 10));
  };

  return (
    <>
      <label css={labelStyles}>Radius</label>
      <div css={sliderContainerStyles} className={className}>
        <div css={valueContainerStyles}>
          <span css={valueStyles}>60 mi</span>
          <div
            css={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              flex: 1,
            }}
          >
            <input
              type="range"
              min="60"
              max="200"
              value={value}
              onChange={handleChange}
              css={sliderStyles}
            />
            <CurrentValue value={value}>{value} mi</CurrentValue>
          </div>
          <span css={valueStyles}>200 mi</span>
        </div>
      </div>
    </>
  );
};
