/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import {
  Address,
  AddressSection,
  AddressTitle,
  AddressTitle2,
  DockDeliverLocation,
  DockDeliverTime,
  DockInfo,
  DockNotes,
  DockPickupLocation,
  DockPickupTime,
  MapDot,
  ThreeWords,
  formatISOToCustom,
} from "../Shared";
import { Destination, Origin } from "pages/rtscao/service/orderServices";

interface OriginDestinationCardProps {
  origin: Origin;
  destination: Destination;
}

export const OriginDestinationCard: React.FC<OriginDestinationCardProps> = ({
  origin,
  destination,
}) => (
  <div
    css={{
      display: "flex",
      flexDirection: "column",
      margin: "15px 10px",
      gap: 20,
    }}
  >
    <AddressSection>
      <AddressTitle>{MapDot} Origin</AddressTitle>
      <AddressTitle2>{origin.name}</AddressTitle2>
      <Address>{origin.address}</Address>
      <Address>
        {origin.city} {origin.state}
      </Address>
      <hr />
      <DockInfo>
        <DockPickupTime>{formatISOToCustom(origin.lateDate)}</DockPickupTime>
        <DockPickupLocation>
          {origin.location}
          {origin.threeWords ? " - " : " "}
          <ThreeWords>{origin.threeWords}</ThreeWords>
        </DockPickupLocation>
      </DockInfo>
      <hr />
      <DockNotes>{origin.notes}</DockNotes>
    </AddressSection>

    <AddressSection>
      <AddressTitle>{MapDot} Destination</AddressTitle>
      <AddressTitle2>{destination.name}</AddressTitle2>
      <Address>{destination.address}</Address>
      <Address>
        {destination.city} {destination.state}
      </Address>
      <hr />
      <DockInfo>
        <DockDeliverTime>
          {formatISOToCustom(destination.lateDate)}
        </DockDeliverTime>
        <DockDeliverLocation>
          {destination.location}
          {destination.threeWords ? " - " : " "}
          <ThreeWords>{destination.threeWords}</ThreeWords>
        </DockDeliverLocation>
        <div css={css({ flex: 1 })} />
      </DockInfo>
      <hr />
      <DockNotes>{destination.notes}</DockNotes>
    </AddressSection>
  </div>
);
