/** @jsxImportSource @emotion/react */
import styled from "@emotion/styled";
import { SectionTitle, Select, Title } from "../Shared";
import { FontSize, Icon, IconType } from "components/atoms/Icon.atom";
import { faCubes } from "@fortawesome/pro-solid-svg-icons";
import { ValidatorInput } from "pages/rtscao/formLibrary/Input";
import { ShipmentDetails as ShipmentDetailsInterface } from "pages/rtscao/service/orderServices";
import { SelectShippingMode } from "./SelectShippingMode";
import { SelectVehicleType } from "./SelectVehicleType";
import QualifierValueList from "./EditReferenceList";

const Section = styled.div`
  border-radius: 4px;
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  padding: 10px;
`;

const InfoGrid = styled.div`
  display: flex;
  gap: 20px;
  flex-direction: row;
  width: 100%;
`;

const InfoItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  font-size: 14px;
  opacity: 40%;
  font-weight: bold;
`;

const ShipmentIcon = (
  <Icon
    style={{ color: "#666" }}
    type={IconType.FontAwesome}
    src={faCubes}
    size={FontSize.size16}
  />
);

interface EditShipmentDetailsProps {
  shipmentDetails?: ShipmentDetailsInterface | null;
}

const ResponsiveContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
`;

const ResponsiveSection = styled.div`
  flex: 1;
  min-width: 420px;
`;

const EditShipmentDetails: React.FC<EditShipmentDetailsProps> = ({
  shipmentDetails = {},
}) => {
  return (
    <>
      <Title>{ShipmentIcon} Shipment Details</Title>
      <Container>
        <ResponsiveContainer>
          <ResponsiveSection>
            <GeneralInfo {...shipmentDetails} />
          </ResponsiveSection>
        </ResponsiveContainer>
      </Container>
      <Container>
        <ResponsiveContainer>
          <ResponsiveSection>
            <ShipUnitDetails
              {...shipmentDetails?.shipUnitDimensions}
              {...shipmentDetails}
            />
          </ResponsiveSection>
        </ResponsiveContainer>
      </Container>
      <Container>
        <ResponsiveContainer>
          <ResponsiveSection>
            <RefsAndMisc {...shipmentDetails} />
          </ResponsiveSection>
        </ResponsiveContainer>
      </Container>
    </>
  );
};

interface GeneralInfoProps {
  shippingMode?: string;
  vehicleType?: string;
  grossWt?: number;
  shipUnitQty?: number;
  shipUnit?: string;
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({
  shippingMode,
  vehicleType,
  grossWt,
  shipUnitQty,
  shipUnit,
}) => {
  return (
    <Section>
      <InfoGrid css={{ display: "flex" }}>
        <SectionTitle css={{ flex: 1 }}>Equipment</SectionTitle>
        <SectionTitle css={{ flex: 1 }}>Freight Details</SectionTitle>
      </InfoGrid>
      <InfoGrid css={{ display: "flex" }}>
        <InfoGrid css={{ flex: 1 }}>
          <InfoItem css={{ fontSize: 14 }}>
            <Label>Mode</Label>
            <SelectShippingMode disabled={true} defaultValue={shippingMode} />
          </InfoItem>
          <InfoItem css={{ fontSize: 14 }}>
            <Label>Type</Label>
            <SelectVehicleType disabled={true} defaultValue={vehicleType} />
          </InfoItem>
        </InfoGrid>
        <InfoGrid css={{ flex: 1 }}>
          <InfoItem css={{ fontSize: 14 }}>
            <Label>Gross Wt</Label>
            <ValidatorInput
              defaultValue={grossWt}
              css={{ fontSize: 14 }}
              required
              type="number"
              name="shipmentDetails.grossWt"
            />
          </InfoItem>
          <InfoItem css={{ fontSize: 14 }}>
            <Label>Ship Unit Qty</Label>
            <ValidatorInput
              defaultValue={shipUnitQty}
              css={{ fontSize: 14 }}
              required
              type="number"
              name="shipmentDetails.shipUnitQty"
            />
          </InfoItem>
          <InfoItem css={{ fontSize: 14 }}>
            <Label>Ship Unit</Label>
            <Select
              defaultValue={
                shipUnit
                  ? { label: shipUnit, value: shipUnit }
                  : { label: "PALLET", value: "PALLET" }
              }
              required
              name="shipmentDetails.shipUnit"
              placeholder=""
              loadOptions={(inputStr: string) => ({
                options: [
                  { label: "BAG", value: "BAG" },
                  { label: "BAL", value: "BAL" },
                  { label: "BOX", value: "BOX" },
                  { label: "BUNDLE", value: "BUNDLE" },
                  { label: "CASE", value: "CASE" },
                  { label: "CRATE", value: "CRATE" },
                  { label: "CARTON", value: "CARTON" },
                  { label: "DRUM", value: "DRUM" },
                  { label: "FLOOR", value: "FLOOR" },
                  { label: "GAYLORD", value: "GAYLORD" },
                  { label: "LOOSE", value: "LOOSE" },
                  { label: "PALLET", value: "PALLET" },
                  { label: "PIECE", value: "PIECE" },
                  { label: "ROLL", value: "ROLL" },
                  { label: "SKID", value: "SKID" },
                  { label: "SPOOL", value: "SPOOL" },
                  { label: "TANK", value: "TANK" },
                  { label: "TOTE", value: "TOTE" },
                  { label: "WRAPPED", value: "WRAPPED" },
                  { label: "OTHER", value: "OTHER" },
                ].filter((yNo) =>
                  yNo.label.toLowerCase().includes(inputStr.toLowerCase()),
                ),
              })}
            />
          </InfoItem>
        </InfoGrid>
      </InfoGrid>
    </Section>
  );
};

interface ShipUnitDetailsProps {
  length?: number;
  height?: number;
  width?: number;
  stackable?: string;
  hazmat?: string;
  temp?: string;
}

const ShipUnitDetails: React.FC<ShipUnitDetailsProps> = ({
  length,
  height,
  width,
  stackable,
  hazmat,
  temp,
}) => {
  return (
    <Section>
      <InfoGrid css={{ display: "flex" }}>
        <SectionTitle css={{ flex: 1 }}>Ship Unit Dimensions</SectionTitle>
        <SectionTitle css={{ flex: 1 }}>Special Handling</SectionTitle>
      </InfoGrid>
      <InfoGrid css={{ display: "flex" }}>
        <InfoGrid css={{ flex: 1 }}>
          <InfoItem
            css={{
              fontSize: 14,
            }}
          >
            <Label>Length</Label>
            <ValidatorInput
              defaultValue={length}
              css={{ fontSize: 14 }}
              // required
              type="number"
              name="shipmentDetails.shipUnitDimensions.length"
            />
          </InfoItem>
          <InfoItem css={{ fontSize: 14 }}>
            <Label>Width</Label>
            <ValidatorInput
              defaultValue={width}
              css={{ fontSize: 14 }}
              // required
              type="number"
              name="shipmentDetails.shipUnitDimensions.width"
            />
          </InfoItem>
          <InfoItem css={{ fontSize: 14 }}>
            <Label>Height</Label>
            <ValidatorInput
              defaultValue={height}
              css={{ fontSize: 14 }}
              // required
              type="number"
              name="shipmentDetails.shipUnitDimensions.height"
            />
          </InfoItem>
        </InfoGrid>
        <InfoGrid css={{ flex: 1 }}>
          <InfoItem css={{ fontSize: 14, minWidth: 75 }}>
            <Label>Stackable</Label>
            <Select
              defaultValue={
                stackable
                  ? { label: stackable, value: stackable }
                  : { label: "Yes", value: "Yes" }
              }
              // required
              name="shipmentDetails.stackable"
              placeholder=""
              loadOptions={(inputStr: string) => ({
                options: [
                  { value: "Yes", label: "Yes" },
                  { value: "No", label: "No" },
                ].filter((yNo) =>
                  yNo.label.toLowerCase().includes(inputStr.toLowerCase()),
                ),
              })}
            />
          </InfoItem>
          <InfoItem css={{ fontSize: 14, minWidth: 75 }}>
            <Label>Hazmat</Label>
            <Select
              defaultValue={{ label: "No", value: "No" }}
              required
              disabled={true}
              name="shipmentDetails.hazmat"
              placeholder=""
              loadOptions={(inputStr: string) => ({
                options: [
                  { value: "Yes", label: "Yes" },
                  { value: "No", label: "No" },
                ].filter((yNo) =>
                  yNo.label.toLowerCase().includes(inputStr.toLowerCase()),
                ),
              })}
            />
          </InfoItem>
          <InfoItem css={{ fontSize: 14, minWidth: 75 }}>
            <Label>Temp</Label>
            <Select
              defaultValue={{ label: "No", value: "No" }}
              css={{ fontSize: 14 }}
              required
              disabled={true}
              name="shipmentDetails.temp"
              placeholder=""
              loadOptions={(inputStr: string) => ({
                options: [
                  { value: "Yes", label: "Yes" },
                  { value: "No", label: "No" },
                ].filter((yNo) =>
                  yNo.label.toLowerCase().includes(inputStr.toLowerCase()),
                ),
              })}
            />
          </InfoItem>
        </InfoGrid>
      </InfoGrid>
    </Section>
  );
};

const RefsAndMisc: React.FC<any> = ({
  refQualifierValues,
  specialInstructions,
  commodity,
}) => {
  return (
    <Section>
      <InfoGrid css={{ display: "flex" }}>
        <SectionTitle css={{ flex: 1 }}>Reference Numbers</SectionTitle>
        <SectionTitle css={{ flex: 1 }}>Misc</SectionTitle>
      </InfoGrid>
      <InfoGrid css={{ display: "flex" }}>
        <InfoGrid css={{ flex: 1 }}>
          <QualifierValueList defaultValue={refQualifierValues} />
        </InfoGrid>
        <InfoGrid css={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <InfoItem css={{ fontSize: 14 }}>
            <Label>Special Instructions</Label>
            <ValidatorInput
              defaultValue={specialInstructions ?? ""}
              css={{ fontSize: 14 }}
              type="text"
              name="shipmentDetails.specialInstructions"
            />
          </InfoItem>

          <InfoItem css={{ fontSize: 14 }}>
            <Label>Commodity</Label>
            <ValidatorInput
              defaultValue={commodity ?? ""}
              css={{ fontSize: 14 }}
              type="text"
              name="shipmentDetails.commodity"
            />
          </InfoItem>
        </InfoGrid>
      </InfoGrid>
    </Section>
  );
};

export default EditShipmentDetails;
